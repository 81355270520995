<template>
  <div class="custom-add" ref="customAdd" id="customAdd">
    <div class="custom-add-body">
      <div class="body-title" v-show="step == 0">아이템을 선택해주세요.</div>
      <div
        class="content-wrap"
        v-show="step == 0"
        style="justify-content: space-between"
      >
        <div class="item">
          <div
            class="item-wrap"
            v-for="(item, i) in items"
            :key="i"
            @click="item.disabled ? '' : toggleComm('items', i)"
            :class="{ active: item.isActive, disabled: item.disabled }"
          >
            <div class="disabled" v-if="item.disabled"></div>
            <p class="disabled-text" v-if="item.disabled">준비 중</p>
            <img :src="require(`@/assets/img/${item.imgUrl}`)" />
            <p class="item-name">{{ item.name }}</p>
          </div>
        </div>
        <div class="content-bottom">
          <div class="validation" v-if="step == 0">
            <p class="active">(필수)</p>
            <p
              :class="{
                active: items.filter((e) => e.isActive).length > 0,
              }"
            >
              품목
            </p>
          </div>
          <div class="content-bottom-btns">
            <p v-if="isValidation[0] == false" class="content-bottom-btns-btn">
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.checkIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              아이템 선택
            </p>
            <p
              v-else
              class="content-bottom-btns-btn active"
              @click="toggleTab(Number(step * 1 + 1))"
            >
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.checkIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              아이템 선택
            </p>
          </div>
        </div>
      </div>
      <div class="body-title" v-if="step == 1 && !isFabricColor">
        원단을 선택해주세요.
      </div>
      <div class="body-title" v-if="step == 1 && isFabricColor">
        컬러를 선택해주세요.
      </div>
      <div class="content-wrap" v-show="step == 1 && !isFabricColor">
        <div class="content-wrap-tab">
          <div
            class="tab-wrap"
            v-for="(x, i) in fabricTab"
            :key="i"
            :class="{ active: x.cd == fabricCatgCd }"
            @click="toggleFabricTab(x)"
          >
            <p>{{ x.name }}</p>
          </div>
        </div>
        <div class="fabric">
          <div
            class="fabric-wrap"
            v-for="(x, j) in fabricCatgCd > 0
              ? fabric[fabricCatgCd]
              : fabricAll"
            :key="j"
            :class="x.isActive && isFabricColor ? 'active' : ''"
          >
            <div class="badge best" v-if="x.badge == '1'">
              <SvgImg
                :size="12"
                :d="$store.state.meta.svg.starIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>BEST</p>
            </div>
            <div class="badge sale" v-if="x.badge == '2'">
              <SvgImg
                :size="12"
                :d="$store.state.meta.svg.starIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>SALE</p>
            </div>
            <div class="badge new" v-if="x.badge == '3'">
              <SvgImg
                :size="12"
                :d="$store.state.meta.svg.starIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>NEW</p>
            </div>
            <div class="fabric-wrap-info" @click="openColor(x)">
              <div class="fabric-wrap-info-img">
                <img :src="x.thumbnailUrl" />
              </div>
              <div class="fabric-wrap-info-content">
                <div class="fabric-name">
                  <p>
                    {{ x.fabricName }}
                  </p>
                </div>
                <div class="color-weight">
                  <p>컬러 {{ x.colorList.length }}종</p>
                  <span></span>
                  <p>{{ x.fabricWeight }}</p>
                </div>
                <div class="mix-rate">
                  <p
                    v-for="(mix, k) in x.mixRate
                      .split(' ')
                      .filter(
                        (e) => e.indexOf('%') == -1 && e.indexOf('+') == -1
                      )"
                    :key="k"
                  >
                    {{ mix }}
                  </p>
                </div>
              </div>
            </div>
            <div class="tags">
              <p class="tag" v-for="(tag, k) in x.hashTag?.split(',')" :key="k">
                {{ tag }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrap" v-if="step == 1 && isFabricColor">
        <div class="fabric-dtl">
          <div class="fabric-dtl-img">
            <img :src="selectedFabric.thumbnailUrl" />
          </div>
          <div class="fabric-dtl-content">
            <div class="fabric-dtl-content-title">
              {{ selectedFabric.fabricName }}
            </div>
            <div class="fabric-dtl-content-wrap">
              <div class="dtl-wrap">
                <p class="dtl-wrap-name">· 조직</p>
                <p class="dtl-wrap-value">{{ selectedFabric.fabricCatg }}</p>
              </div>
              <div class="dtl-wrap">
                <p class="dtl-wrap-name">· 중량</p>
                <p class="dtl-wrap-value">{{ selectedFabric.fabricWeight }}</p>
              </div>
              <div class="dtl-wrap">
                <p class="dtl-wrap-name">· 혼용률</p>
                <p class="dtl-wrap-value">{{ selectedFabric.mixRate }}</p>
              </div>
            </div>
            <div class="fabric-dtl-content-tags">
              <p
                class="tag"
                v-for="(tag, i) in selectedFabric.hashTag?.split(',')"
                :key="i"
              >
                {{ tag }}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="title-name">
            <p>스왓치 컬러 ({{ selectedFabric.colorList.length }}종)</p>
          </div>
          <div class="fabric-color">
            <div class="color-list">
              <div
                class="color-list-area"
                v-for="(color, i) in selectedFabric.colorList"
                :key="i"
                @click.stop="toggleColor(color, i)"
                :class="color.isActive ? 'active' : ''"
              >
                <div class="color-img">
                  <img :src="color.fileUrl" />
                </div>
                <div class="color-content">
                  <p>{{ color.colorCode }}</p>
                  <p>{{ color.colorName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row-bottom">
            <div class="validation" v-if="step == 1">
              <p class="active">(필수)</p>
              <p :class="{ active: fabricColorList.length > 0 }">컬러</p>
            </div>
            <div class="row-bottom-btn gray" @click="closeColor()">
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.returnIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>돌아가기</p>
            </div>
            <div
              class="row-bottom-btn"
              :class="{
                active: fabricColorList.length > 0,
              }"
              @click="selectColor(selectedFabric, j)"
            >
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.checkIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>컬러 선택</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="title-name">
            <p>원단 소개</p>
          </div>
          <div class="fabric-desc">
            <div v-for="(desc, i) in selectedFabric.descList" :key="i">
              <div class="fabric-desc-title">
                {{ desc.descTitle }}
              </div>
              <div class="fabric-desc-content">
                {{ desc.descContent }}
              </div>
            </div>
          </div>
          <div class="fabric-file">
            <div v-for="(file, i) in selectedFabric.fileList" :key="i">
              <div class="fabric-file-path">
                <img :src="file.fileUrl" />
              </div>
              <div class="fabric-file-name" :class="{ 'mt-12': file.fileName }">
                {{ file.fileName }}
              </div>
            </div>
          </div>
        </div>
        <div class="before-btn" @click="closeColor()">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.returnIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>돌아가기</p>
        </div>
      </div>
      <div class="body-title" v-show="step == 2">사이즈를 선택해주세요.</div>
      <CompCustomBuilderSize
        :step="step"
        :checkSize="checkSize"
        :sizeData="size"
        :isSizeRefresh="isSizeRefresh"
        :isValidation="isValidation"
        :isRecommend="isRecommend"
        @toggleTab="toggleTab"
        @toggleComm="toggleComm"
        @setValidation="setValidation"
        @getSizeSpec="getSizeSpec"
        @refresh="refresh"
        @initsize="initsize"
        @recommendSize="recommendSize"
        @sumUnitCost="sumUnitCost"
        @setSizeData="setSizeData"
        @setSizeInput="setSizeInput"
      />
      <div class="body-title" v-show="step == 3">디테일을 선택해주세요.</div>
      <CompCustomBuilderDtl
        :step="step"
        :isValidation="isValidation"
        @toggleTab="toggleTab"
        @toggleComm="toggleComm"
        @dtlRefresh="dtlRefresh"
      />

      <div class="body-title" v-show="step == 4">
        프린팅 이미지를 첨부해주세요.
      </div>
      <CompCustomBuilderPrt
        :step="step"
        :isValidation="isValidation"
        :printList="printList"
        @toggleTab="toggleTab"
        @toggleComm="toggleComm"
        @openPrint="openPrint"
        @goModify="goModify"
        @goModifyPrint="goModifyPrint"
      />
    </div>
  </div>
  <div class="custom-preview">
    <div class="custom-preview-wrap">
      <div class="preview-wrap-top">Design</div>
      <div class="preview-wrap-body">
        <div class="body-confirm" v-if="step > 0">
          <div class="name">
            <p>· 아이템</p>
            <SvgImg
              @click="this.$emit('clickStep', 0)"
              class="pointer"
              :size="20"
              :d="$store.state.meta.svg.returnIcon"
              :color="$store.state.meta.svg.color.neutral40"
            />
          </div>
          <div class="value">
            <p>{{ items.filter((e) => e.isActive)[0]?.name }}</p>
          </div>
        </div>
        <div class="body-confirm" v-if="step > 1">
          <div class="name">
            <p>· 패브릭</p>
            <SvgImg
              @click="this.$emit('clickStep', 1)"
              class="pointer"
              :size="20"
              :d="$store.state.meta.svg.returnIcon"
              :color="$store.state.meta.svg.color.neutral40"
            />
          </div>
          <div class="value">
            <p>{{ selectedFabric.fabricName }}</p>
          </div>
          <div class="value-wrap">
            <p
              v-for="(color, i) in selectedFabric?.colorList?.filter(
                (e) => e.isActive
              )"
              :key="i"
            >
              {{ color.colorCode }} {{ color.colorName }}
            </p>
          </div>
        </div>
        <div class="body-confirm" v-if="step > 2">
          <div class="name">
            <p>· 사이즈</p>
            <SvgImg
              @click="this.$emit('clickStep', 2)"
              class="pointer"
              :size="20"
              :d="$store.state.meta.svg.returnIcon"
              :color="$store.state.meta.svg.color.neutral40"
            />
          </div>
          <div
            class="value"
            v-if="checkSize.filter((e) => e.isActive)[0]?.cd == 1"
          >
            <SvgImg
              class="pointer"
              :size="20"
              :d="$store.state.meta.svg.selectWindowIcon"
              :color="$store.state.meta.svg.color.primary50"
              @click="isSizePopup = true"
            />
            <p>직접입력</p>
            <p v-if="checkSize.filter((e) => e.isActive)[0]?.cd == 1">
              {{ checkSize.filter((e) => e.isActive)[0]?.cd == 1 ? "" : "" }}
            </p>
          </div>
          <div
            class="value-wrap"
            v-if="checkSize.filter((e) => e.isActive)[0]?.cd == 2"
          >
            <p>{{ gender.filter((e) => e.isActive)[0]?.name }}</p>
            <p>{{ fit.filter((e) => e.isActive)[0]?.name }}</p>
            <p>{{ totalLength.filter((e) => e.isActive)[0]?.name }}</p>
            <p>{{ sleeveLength.filter((e) => e.isActive)[0]?.name }}</p>
            <p>{{ sleeveStyle.filter((e) => e.isActive)[0]?.name }}</p>
          </div>
        </div>
        <div class="body-confirm" v-if="step > 3">
          <div class="name">
            <p>· 디테일</p>
            <SvgImg
              @click="this.$emit('clickStep', 3)"
              class="pointer"
              :size="20"
              :d="$store.state.meta.svg.returnIcon"
              :color="$store.state.meta.svg.color.neutral40"
            />
          </div>
          <div class="value-wrap">
            <p>{{ neckLine.filter((e) => e.isActive)[0]?.name }}</p>
            <p>{{ neckDetail.filter((e) => e.isActive)[0]?.name }}</p>
            <p>
              {{ neckEnd.filter((e) => e.isActive)[0]?.name }}
            </p>
            <p>{{ stitch.filter((e) => e.isActive)[0]?.name }}</p>
            <p>{{ pocket.filter((e) => e.isActive)[0]?.name }}</p>
            <p>{{ sleeve.filter((e) => e.isActive)[0]?.name }}</p>
            <p>{{ sideSlit.filter((e) => e.isActive)[0]?.name }}</p>
          </div>
        </div>
      </div>
      <div class="preview-wrap-bottom">
        <p>
          예상 단가
          <span>{{ comma(minUnitPrice) }}원 ~ {{ comma(maxUnitPrice) }}원</span>
        </p>
      </div>
    </div>
  </div>
  <div v-show="isSizePopup" class="popup-size">
    <div class="popup-background" @click="isSizePopup = false"></div>
    <div class="popup-size-wrap">
      <div class="popup-size-wrap-top">· 사이즈 스펙</div>
      <div class="popup-size-wrap-body">
        <div class="row-flex">
          <div class="row-flex-wrap">
            <SizeTableInput
              v-if="isSizePopup"
              :size="size"
              :sizeVal="sizeValue"
              :disabled="true"
            />
          </div>
          <div class="row-flex-wrap">
            <div class="row-flex-wrap-img">
              <img src="@/assets/img/img-custom-preview-size.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="popup-size-wrap-bottom" @click="isSizePopup = false">
        <SvgImg
          :size="20"
          :d="$store.state.meta.svg.checkIcon"
          :color="$store.state.meta.svg.color.neutral0"
        />
        <p>확인</p>
      </div>
    </div>
  </div>
  <div v-if="isPrintPopup" class="popup-print">
    <div class="popup-background" @click="closePrint()"></div>
    <div class="popup-print-wrap">
      <input
        class="hidden"
        type="file"
        id="imgFile"
        ref="imgFile"
        accept=".gif, .jpg, .jpeg, .png, .ai, .psd"
        @change="fileUpload('81', 1, $event)"
      />
      <div class="popup-print-wrap-body">
        <div class="body-left">
          <div class="body-left-wrap" v-if="printType == 'front'">
            <p>Front</p>
            <!-- <img
              :src="require(`@/assets/img/print-${printType}-img.png`)"
              v-if="items.filter((e) => e.isActive)[0].cd == '1'"
            />
            <img
              :src="require(`@/assets/img/print-${printType}-img-mtm.png`)"
              v-if="items.filter((e) => e.isActive)[0].cd == '2'"
            /> -->
            <div
              class="body-left-wrap-img"
              v-for="(img, i) in printFrontImgs"
              :key="i"
              :class="{
                on: i == printIndex,
                'tshirt-front': items.filter((e) => e.isActive)[0].cd == '1',
                'mtm-front': items.filter((e) => e.isActive)[0].cd == '2',
              }"
            >
              <img
                v-if="items.filter((e) => e.isActive)[0].cd == '1'"
                class="file-area"
                :src="
                  require(`@/assets/img/img-custom-print-${printType}${
                    i + 1
                  }.png`)
                "
              />
              <img
                v-if="items.filter((e) => e.isActive)[0].cd == '2'"
                class="file-area"
                :src="
                  require(`@/assets/img/img-custom-print-mtm-${printType}${
                    i + 1
                  }.png`)
                "
              />
            </div>
          </div>
          <div class="body-left-wrap" v-if="printType == 'back'">
            <p>Back</p>
            <!-- <img
              :src="require(`@/assets/img/print-${printType}-img.png`)"
              v-if="items.filter((e) => e.isActive)[0].cd == '1'"
            />
            <img
              :src="require(`@/assets/img/print-${printType}-img-mtm.png`)"
              v-if="items.filter((e) => e.isActive)[0].cd == '2'"
            /> -->
            <div
              class="body-left-wrap-img"
              v-for="(img, i) in printBackImgs"
              :class="{
                on: i == printIndex,
                'tshirt-back': items.filter((e) => e.isActive)[0].cd == '1',
                'mtm-back': items.filter((e) => e.isActive)[0].cd == '2',
              }"
              :key="i"
            >
              <img
                v-if="items.filter((e) => e.isActive)[0].cd == '1'"
                class="file-area"
                :src="
                  require(`@/assets/img/img-custom-print-${printType}${
                    i + 1
                  }.png`)
                "
              />
              <img
                v-if="items.filter((e) => e.isActive)[0].cd == '2'"
                class="file-area"
                :src="
                  require(`@/assets/img/img-custom-print-mtm-${printType}${
                    i + 1
                  }.png`)
                "
              />
            </div>
          </div>
        </div>
        <div class="body-right">
          <div class="body-right-wrap">
            <div class="title">
              <p>
                · 프린팅 이미지의 ‘가로’와 ‘세로’ 중 긴축을 선택해주세요.
                <span>*</span>
              </p>
              <div class="tooltip">
                <div class="tooltip-wrap">
                  <div class="tooltip-wrap-dtl">
                    <div class="tooltip-wrap-dtl-text">
                      <img src="@/assets/img/img-print-info.png" />
                      <p><span>긴축은</span> 가로</p>
                      <p><span>긴축의 길이는</span> 6cm</p>
                    </div>
                  </div>
                </div>
                <SvgImg
                  :size="20"
                  :d="$store.state.meta.svg.questMarkIcon"
                  :color="$store.state.meta.svg.color.primary50"
                />
              </div>
            </div>
            <div class="line-type">
              <SelectBoxRadius
                class="select-box"
                :data="lineType"
                :filterType="'lineType'"
                @selectData="selectData"
                :selectData="lineType.filter((e) => e.isActive)[0].name"
              ></SelectBoxRadius>
            </div>
            <div class="column mt-12">
              <p class="title">
                · 프린팅 이미지의 긴축의 길이를 입력해주세요.<span>*</span>
              </p>
              <div class="line-type">
                <input
                  type="number"
                  v-model="longLength"
                  placeholder="긴축의 길이를 입력해주세요."
                  @keyup="checkPrint"
                />
                cm
              </div>
            </div>
          </div>
          <div class="body-right-wrap" v-if="printType == 'front'">
            <div
              class="column"
              v-for="(data, i) in this.$store.state.customPrint.frontDesc[
                printIndex
              ].name"
              :key="i"
              :class="{ 'mt-12': i > 0 }"
            >
              <p class="title">
                <span class="num">{{ i + 1 }}</span> {{ data }}
              </p>
              <div class="line-type">
                <input
                  type="number"
                  v-model="printLength[i]"
                  placeholder="이격 길이를 입력해주세요."
                  @keyup="checkPrint"
                />
                cm
              </div>
            </div>
          </div>
          <div class="body-right-wrap" v-if="printType == 'back'">
            <div
              class="column"
              v-for="(data, i) in this.$store.state.customPrint.backDesc[
                printIndex
              ].name"
              :key="i"
              :class="{ 'mt-12': i > 0 }"
            >
              <p class="title">
                <span class="num">{{ i + 1 }}</span> {{ data }}
              </p>
              <div class="line-type">
                <input
                  type="number"
                  v-model="printLength[i]"
                  placeholder="이격 길이를 입력해주세요."
                  @keyup="checkPrint"
                />
                cm
              </div>
            </div>
          </div>
          <div class="tooltip">
            <div class="tooltip-wrap file" v-if="isMobile == 'false'">
              <div class="tooltip-wrap-dtl">
                <div class="tooltip-wrap-dtl-text">
                  <p>
                    업로드 가능한 이미지 파일은 고화질의 JPEG, PNG 파일 또는 AI
                    파일입니다. (최대 10MB 이하)
                  </p>
                  <p>
                    제품의 색생과 이미지 색상은 모니터 사양이나 컬러모드 (CMYK,
                    RGB 등)에 따라 차이가 발생할 수 있습니다.
                  </p>
                  <p>
                    작은 이미지를 임의로 크게 확대하여 인쇄할 경우, 화질이 깨질
                    수 있습니다.
                  </p>
                </div>
              </div>
            </div>
            <div class="imgs" v-if="img.fileNo != null">
              <div class="imgs-img view">
                <!-- <img :src="img.fileUrl" /> -->

                <img
                  v-if="
                    ['.psd', '.ai', '.pdf', '.xlsx'].some(
                      (e) => img.sourceFileName.indexOf(e) != -1
                    )
                  "
                  :src="
                    require(`@/assets/img/thumbnail-${
                      img.sourceFileName.split('.')[1]
                    }.png`)
                  "
                />
                <img v-else :src="img.fileUrl" />
              </div>
              <p>{{ img.sourceFileName }}</p>
              <div class="imgs-pop flex" @click.stop="removeImg(img.fileNo)">
                <img src="@/assets/icon/ic-x-square.svg" />
              </div>
            </div>
            <label
              v-else
              class="body-right-file drop-area mb-0"
              for="imgFile"
              @dragover.prevent="handleDragOver"
              @drop.prevent="handleDrop"
            >
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.fileupload"
                :color="$store.state.meta.svg.color.neutral20"
              />
              <p>파일 추가 또는 파일을 여기로 드래그</p>
            </label>
          </div>
        </div>
      </div>
      <div class="popup-print-wrap-bottom">
        <div class="bottom-wrap">
          <div class="column gap8">
            <CheckBox
              :name="'‘자수’ 요청'"
              :checked="agree[0]"
              :hoverOff="true"
              @click="agree[0] = !agree[0]"
            />
            <div class="check-desc">
              검토 후 작업 가능 여부를 알려드리며, 금액은 별도로 청구됩니다.
            </div>
          </div>
          <div class="column gap8 mt-24">
            <CheckBox
              :name="'상품에 어울리는 위치로 세부 조정해드립니다.'"
              :checked="agree[1]"
              :hoverOff="true"
              @click="agree[1] = !agree[1]"
            />
            <div class="check-desc">
              위치 세부 조정 요청 시, 이미지의 크기는 수정하지 않고, 위치를 세부
              조정하며, 그로 인한 교환 및 환불, 반품은 불가합니다.
            </div>
          </div>
        </div>
        <div class="validation">
          <p class="active">(필수)</p>
          <p :class="{ active: lineType.filter((e) => e.isActive).length > 0 }">
            긴축 선택
          </p>
          <p :class="{ active: longLength }">긴축 길이</p>
          <p :class="{ active: printLength[0] }">① 이격 길이</p>
          <p
            :class="{ active: printLength[1] }"
            v-if="
              (printType == 'front' &&
                this.$store.state.customPrint.frontDesc[printIndex].name
                  .length == 2) ||
              (printType == 'back' &&
                this.$store.state.customPrint.backDesc[printIndex].name
                  .length == 2)
            "
          >
            ② 이격 길이
          </p>
          <p :class="{ active: img.fileUrl }">이미지</p>
        </div>
        <div class="popup-print-wrap-bottom-btn pointer">
          <p class="" @click="closePrint()">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closePopup"
              :color="$store.state.meta.svg.color.neutral0"
            />확인
          </p>
          <p
            class=""
            @click="isPrint ? addPrint() : ''"
            :class="isPrint ? 'active' : 'disable'"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.checkIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            이미지 선택
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-refresh" v-if="isPrintRefreshPopup">
    <div class="popup-background" @click="isPrintRefreshPopup = false"></div>
    <div class="popup-refresh-wrap">
      <div class="popup-refresh-wrap-body">
        <div class="title">
          <p>입력하신 내용을 초기화 하시겠습니까?</p>
        </div>
        <div class="content">
          프린팅 Step에 입력하신 모든 내용이 삭제됩니다.
        </div>
      </div>

      <div class="popup-refresh-wrap-bottom">
        <div class="popup-refresh-wrap-bottom-btn pointer">
          <p class="" @click="isPrintRefreshPopup = false">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closePopup"
              :color="$store.state.meta.svg.color.neutral0"
            />
            취소
          </p>
          <p class="active" @click="printRefresh()">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.refreshIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            초기화
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-gender-fit" v-if="isLength">
    <div class="popup-background" @click="isLength = false"></div>
    <div class="popup-gender-fit-wrap">
      <div class="popup-gender-fit-wrap-body">
        <div class="title">
          <p>· 총 기장 / 소매 기장 수정</p>
          <div class="desc mt-12">
            <p>※</p>
            <p>
              대표적인 1개 사이즈만 입력해주세요. 그 외 사이즈는 편차에 따라
              자동으로 변경됩니다.
            </p>
          </div>
          <div class="desc mt-4">
            <p>※</p>
            <p>총 기장 : 00 ~ 00cm 이내만 가능합니다.</p>
          </div>
          <div class="desc mt-4">
            <p>※</p>
            <p>소매 기장 : 5 ~ 85cm 이내만 가능합니다.</p>
          </div>
        </div>
        <div class="refresh" @click="isRefreshPopup = true">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.refreshIcon"
            :color="$store.state.meta.svg.color.neutral40"
          />
          <p>초기화</p>
        </div>
        <div class="size">
          <div class="size-row">
            <p>단위:cm</p>
            <p>XS</p>
            <p>S</p>
            <p>M</p>
            <p>L</p>
            <p>XL</p>
            <p>2XL</p>
            <p>3XL</p>
          </div>
          <div class="size-row">
            <p>총 기장</p>
            <input type="text" v-model="totalSize[0]" placeholder="00.0" />
            <input type="text" v-model="totalSize[1]" placeholder="00.0" />
            <input type="text" v-model="totalSize[2]" placeholder="00.0" />
            <input type="text" v-model="totalSize[3]" placeholder="00.0" />
            <input type="text" v-model="totalSize[4]" placeholder="00.0" />
            <input type="text" v-model="totalSize[5]" placeholder="00.0" />
            <input type="text" v-model="totalSize[6]" placeholder="00.0" />
          </div>
          <div class="size-row">
            <p>소매 기장</p>
            <input type="text" v-model="sleeveSize[0]" placeholder="00.0" />
            <input type="text" v-model="sleeveSize[1]" placeholder="00.0" />
            <input type="text" v-model="sleeveSize[2]" placeholder="00.0" />
            <input type="text" v-model="sleeveSize[3]" placeholder="00.0" />
            <input type="text" v-model="sleeveSize[4]" placeholder="00.0" />
            <input type="text" v-model="sleeveSize[5]" placeholder="00.0" />
            <input type="text" v-model="sleeveSize[6]" placeholder="00.0" />
          </div>
        </div>
      </div>

      <div class="popup-gender-fit-wrap-bottom">
        <div class="popup-gender-fit-wrap-bottom-btn pointer">
          <p class="gray" @click="isLength = false">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closePopup"
              :color="$store.state.meta.svg.color.neutral0"
            />
            취소
          </p>
          <p
            @click="
              gender.filter((e) => e.isActive).length > 0 &&
              fit.filter((e) => e.isActive).length > 0
                ? recommendSize()
                : ''
            "
            :class="{
              active:
                gender.filter((e) => e.isActive).length > 0 &&
                fit.filter((e) => e.isActive).length > 0,
            }"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.checkIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            완료
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgImg from "../SvgImg.vue";

export default {
  name: "CustomAdd",
  props: {
    moveStep: {
      type: String,
      description: "btn text",
    },
    fabricColor: {
      type: Object,
      description: "fabricColor List",
    },
  },
  data() {
    return {
      unitPrice: 0,
      minUnitPrice: 0,
      maxUnitPrice: 0,
      isLength: false,
      isRecommend: false,
      isGenderFit: false,
      isRefreshPopup: false,
      isPrintRefreshPopup: false,
      isDtlRefreshPopup: false,
      isSizeRefresh: false,
      isSizeSpec: false,
      isPrint: false,
      isFabricColor: false,
      isSizeDisable: true,
      isValidation: [false, false, false, false],
      isPrintPopup: false,
      isSizePopup: false,
      step: 0,
      agree: [false, false],
      checkSize: [],
      totalSize: ["", "", "", "", "", "", ""],
      sleeveSize: ["", "", "", "", "", "", ""],
      sizeValue: [],
      sizeSpeclist: [],
      fabricTab: [{ index: 0, isActive: true, cd: "0", name: "전체" }],
      fabric: [],
      fabricAll: [],
      fabricCatgCd: "0",
      items: [],
      lineType: [],
      size: [],
      color: [],
      checkPrintImg: [],
      gender: [],
      fit: [],
      totalLength: [],
      sleeveLength: [],
      sleeveStyle: [],
      sleeveEnd: [],
      hemEnd: [],
      neckLine: [],
      neckDetail: [],
      neckEnd: [],
      stitch: [],
      pocket: [],
      sleeve: [],
      sideSlit: [],
      printFrontImgs: [],
      printBackImgs: [],
      selectedColor: [],
      selectedFabric: {},
      careLabelList: [],
      mainLabelList: [],
      descList: [],
      printList: [],
      frontList: [],
      backList: [],
      imgList: [],
      img: {},
      apiData: {},
      customData: {},
      errorData: {},
      sampleFlag: "",
      styleName: "",
      customNo: "",
      printType: "back",
      printIndex: "",
      longLength: "",
      printLength: [],
      userId: sessionStorage.getItem("userId"),
      fabricColorList: [],
      fabricCatg: [],
      fabricCost: 0,
      marking: 1,
      files: {},
      tolSize: "",
      chsSize: "",
      hesSize: "",
      sllSize: "",
      shsSize: "",
      whsSize: "",
      ahlSize: "",
      scsSize: "",
      sbsSize: "",
      newSize: "",
      nedSize: "",
      srwSize: "",
      hrwSize: "",
      sizeObj: {},
      vgusset: {},
      xstitch: {},
      kgrPocket: {},
    };
  },
  watch: {
    moveStep(newVal) {
      this.step = newVal;
      if (newVal == 1) {
        this.isFabricColor = false;
      }
    },
  },
  mounted() {
    this.customNo = this.$route.params.customNo;
    this.step = Number(this.$route.query.step);
    if (this.$route.query.isFabricColor) {
      this.isFabricColor = Boolean(this.$route.query.isFabricColor);
    }
    //data set
    this.items = this.$store.state.customPrint.items;
    this.lineType = this.$store.state.customPrint.lineType;
    this.size = [
      { index: 0, isActive: true, cd: "01", name: "XS" },
      { index: 0, isActive: true, cd: "02", name: "S" },
      { index: 1, isActive: true, cd: "03", name: "M" },
      { index: 2, isActive: true, cd: "04", name: "L" },
      { index: 3, isActive: true, cd: "05", name: "XL" },
      { index: 4, isActive: true, cd: "06", name: "2XL" },
      { index: 4, isActive: true, cd: "07", name: "3XL" },
    ];
    this.color = this.$store.state.customPrint.color;
    this.checkSize = this.$store.state.customPrint.checkSize;

    this.neckLine = this.$store.state.customPrint.neckLine;
    this.neckDetail = this.$store.state.customPrint.neckDetail;
    this.neckEnd = this.$store.state.customPrint.neckEnd;
    this.stitch = this.$store.state.customPrint.stitch;
    this.pocket = this.$store.state.customPrint.pocket;
    this.sleeve = this.$store.state.customPrint.sleeve;
    this.sleeveEnd = this.$store.state.customPrint.sleeveEnd;
    this.hemEnd = this.$store.state.customPrint.hemEnd;
    this.sideSlit = this.$store.state.customPrint.sideSlit;

    this.vgusset = this.$store.state.customPrint.vgusset;
    this.xstitch = this.$store.state.customPrint.xstitch;
    this.kgrPocket = this.$store.state.customPrint.kgrPocket;

    this.checkPrintImg = this.$store.state.customPrint.checkPrintImg;
    this.printFrontImgs = this.$store.state.customPrint.printFrontImgs;
    this.printBackImgs = this.$store.state.customPrint.printBackImgs;
    this.gender = this.$store.state.customPrint.gender;
    this.fit = this.$store.state.customPrint.fit;
    this.totalLength = this.$store.state.customPrint.totalLength;
    this.sleeveLength = this.$store.state.customPrint.sleeveLength;
    this.sleeveStyle = this.$store.state.customPrint.sleeveStyle;

    this.$API.custom.customDetail(this.userId, this.customNo).then((res) => {
      this.apiData = res.data.body;
      console.log("apiData::::::");
      console.log(this.apiData);
    });

    if (this.step > 0 && !(this.items.filter((e) => e.isActive).length > 0)) {
      let getUrl = "/custom/builder/" + this.customNo + "?step=0";
      // this.gotoUrl(getUrl);
      this.gotoUrl(getUrl);
    }
    window.addEventListener("popstate", this.handlePopState);
    window.addEventListener("beforeunload", this.handleLeavePage);
  },
  unmounted() {
    this.initCustomPrint();
    window.removeEventListener("popstate", this.handlePopState);
    window.removeEventListener("beforeunload", this.handleLeavePage);
  },
  methods: {
    isPageRefresh() {
      // 새로고침 감지 로직
      // 예: sessionStorage를 사용하여 새로고침 여부 확인
      return true;
    },
    handleLeavePage(event) {
      event.preventDefault();
      event.returnValue = "";
      if (!this.isPageRefresh()) {
        // 새로고침을 제외한 페이지 이탈 시 실행할 코드
      } else {
        console.log("새로고침");
      }
    },
    loadFabric() {
      this.$API.custom
        .customFabric(this.items.filter((e) => e.isActive)[0]?.cd)
        .then((res) => {
          this.fabricTab = [
            { index: 0, isActive: true, cd: "0", name: "전체" },
          ];
          this.fabricCatg = [];
          this.fabricAll = [];
          this.fabric = [];
          const fabric = res.data.body;
          const grouped = {};
          fabric.forEach((item, i) => {
            const groupKey = item.fabricCatgCd;
            if (!grouped[groupKey]) {
              grouped[groupKey] = [];
              this.fabricCatg.push(groupKey);
              this.fabricTab.push({
                index: i + 1,
                isActive: false,
                cd: groupKey,
                name: item.fabricCatg,
              });
              fabric
                .filter((e) => e.fabricCatgCd == groupKey)
                .forEach((e) => {
                  this.fabricAll.push(e);
                });
            }
            grouped[groupKey].push(item);
          });
          this.fabric = grouped;
        });
    },
    handlePopState(event) {
      if (this.isFabricColor) {
        // 뒤로가기를 막기 위해 현재 상태를 다시 히스토리에 추가
        this.isFabricColor = false;
        this.$emit("clickStep", 1);
      }
    },
    setSizeData(sizeObj) {
      // tolSize,
      // chsSize,
      // hesSize,
      // sllSize,
      // shsSize,
      // whsSize,
      // ahlSize,
      // scsSize,
      // sbsSize,
      // newSize,
      // nedSize
      console.log("setSizeData");
      console.log(sizeObj);
      this.tolSize = sizeObj.tolSize; //총기장
      this.chsSize = sizeObj.chsSize; //가슴 단면
      this.hesSize = sizeObj.hesSize; //밑단 단면
      this.sllSize = sizeObj.sllSize; //소매 기장
      this.shsSize = sizeObj.shsSize; //어깨 단면
      this.whsSize = sizeObj.whsSize; //허리 둘레
      this.ahlSize = sizeObj.ahlSize; //암홀
      this.scsSize = sizeObj.scsSize; //소매단 단면
      this.sbsSize = sizeObj.sbsSize; //소매통 단면
      this.newSize = sizeObj.newSize; //목 너비
      this.nedSize = sizeObj.nedSize; //목 파임
      this.srwSize = sizeObj.srwSize; //소매립 폭
      this.hrwSize = sizeObj.hrwSize; //밑단립 폭
      if (this.items.filter((e) => e.isActive)[0].cd == "1") {
        this.sizeValue = [
          this.tolSize,
          this.chsSize,
          this.hesSize,
          this.sllSize,
          this.shsSize,
          this.whsSize,
          this.ahlSize,
          this.scsSize,
          this.sbsSize,
          this.newSize,
          this.nedSize,
        ];
      }
      if (this.items.filter((e) => e.isActive)[0].cd == "2") {
        this.sizeValue = [
          this.tolSize,
          this.chsSize,
          this.shsSize,
          this.hesSize,
          this.sllSize,
          this.ahlSize,
          this.scsSize,
          this.sbsSize,
          this.srwSize,
          this.hrwSize,
          this.newSize,
          this.nedSize,
        ];
      }
    },
    setSizeInput(index, size, value) {
      if (value > 0) {
        this.sizeInput[index] = index;
      } else {
        this.sizeInput.splice(index, 1);
      }
      if (value > 0 && this.sizeInput.indexOf(size) == -1) {
        this.sizeInput[0] = "";
        this.sizeInput[0] = size;
      }
    },
    closeColor() {
      this.isFabricColor = false;
      this.$emit("clickStep", 1);
    },
    openColor(datas) {
      this.isFabricColor = true;
      this.selectedFabric = datas;
      const addHeight = document.querySelector(".custom-add");
      addHeight.scrollTop = 0;
    },
    selectColor(fabricData, index) {
      const fabricNo = fabricData.fabricNo;
      this.fabricCost = fabricData.unitCost;
      this.sumUnitCost();

      if (this.fabricColorList.length > 0) {
        this.isValidation[1] = true;
      } else {
        this.isValidation[1] = false;
      }
      this.$emit("clickStep", 2);
    },
    toggleColor(colorData, index) {
      const catgCd = this.selectedFabric.fabricCatgCd;

      this.fabric[catgCd].filter(
        (e) => e.fabricNo == colorData.fabricNo
      )[0].colorList[index].isActive = !this.fabric[catgCd].filter(
        (e) => e.fabricNo == colorData.fabricNo
      )[0].colorList[index].isActive;

      let fabricColorList = [];
      this.fabric[catgCd]
        .filter((e) => e.fabricNo == colorData.fabricNo)[0]
        .colorList.forEach((e) => {
          if (e.isActive) {
            fabricColorList.push(e);
          }
        });
      this.fabricColorList = fabricColorList;
    },
    toggleFabricTab(fabricTab) {
      this.fabricCatgCd = fabricTab.cd;
    },
    toggleFabric(catgCd, j) {
      this.fabricCatgCd = catgCd;
      this.fabricCatg.forEach((e) => {
        this.fabric[e].forEach((e2) => {
          e2.isActive = false;
        });
      });
      this.fabric[catgCd][j].isActive = !this.fabric[catgCd][j].isActive;
      this.isFabricColor = !this.isFabricColor;
      this.$emit(
        "openDesc",
        "",
        this.fabric[catgCd][j].descList,
        this.fabric[catgCd][j].fileList
      );
      this.$emit("setFabricName", this.fabric[catgCd][j]);
      this.$emit(
        "addUnitPrice",
        Math.ceil(this.fabric[catgCd][j].unitCost / 10) * 10
      );
    },
    setValidation(index, isValue) {
      this.isValidation[index] = isValue;
    },
    recommendSize() {
      this.isValidation[2] = true;
      this.isToast("사이즈 스펙 생성이 완료되었습니다.");
    },
    getSizeSpec() {
      //성별 + 핏 + 총 기장 + 소매 기장 + 네크라인 + 네크라인 디테일
      if (this.items.filter((e) => e.isActive)[0]?.cd == "2") {
        this.gender.map((e) =>
          e.cd == "2" ? (e.isActive = true) : (e.isActive = false)
        );
      }
      this.$API.custom
        .customSizeSepc(
          this.gender.filter((e) => e.isActive)[0].cd,
          this.fit.filter((e) => e.isActive)[0].cd,
          this.totalLength.filter((e) => e.isActive)[0].cd,
          this.sleeveLength.filter((e) => e.isActive)[0]?.cd,
          this.sleeveStyle.filter((e) => e.isActive)[0]?.cd,
          this.neckLine.filter((e) => e.isActive)[0]?.cd,
          this.neckDetail.filter((e) => e.isActive)[0]?.cd,
          this.sleeveEnd.filter((e) => e.isActive)[0]?.cd,
          this.hemEnd.filter((e) => e.isActive)[0]?.cd,
          this.items.filter((e) => e.isActive)[0]?.cd
        )
        .then((res) => {
          this.sizeSpeclist = res.data.body;
          this.size = [
            {
              index: 0,
              isActive: true,
              cd: "01",
              name: "XS",
              spec: this.sizeSpeclist[0],
            },
            {
              index: 0,
              isActive: true,
              cd: "02",
              name: "S",
              spec: this.sizeSpeclist[1],
            },
            {
              index: 1,
              isActive: true,
              cd: "03",
              name: "M",
              spec: this.sizeSpeclist[2],
            },
            {
              index: 2,
              isActive: true,
              cd: "04",
              name: "L",
              spec: this.sizeSpeclist[3],
            },
            {
              index: 3,
              isActive: true,
              cd: "05",
              name: "XL",
              spec: this.sizeSpeclist[4],
            },
            {
              index: 4,
              isActive: true,
              cd: "06",
              name: "2XL",
              spec: this.sizeSpeclist[5],
            },
            {
              index: 4,
              isActive: true,
              cd: "07",
              name: "3XL",
              spec: this.sizeSpeclist[6],
            },
          ];
          //요척 계산 총기장+소매기장 yd + 0.1
          if (this.sizeSpeclist.length > 0) {
            const marking =
              parseFloat(
                parseFloat(this.sizeSpeclist[4].tolSize) +
                  parseFloat(this.sizeSpeclist[4].sllSize)
              ) /
                91 +
              0.1;
            this.marking = Math.ceil(marking * 100) / 100;
          }
          //단가 계산
          this.sumUnitCost();
          setTimeout(() => {
            const container = this.$refs.customAdd;
            container.scrollTo({
              top: container.scrollHeight,
              behavior: "smooth", // 스무스 스크롤 옵션
            });
          }, 100);
          this.isRecommend = true;
        });
    },
    onSizeSpec(isActive) {
      this.isSizeSpec = isActive;
    },
    openPrint(type, i) {
      this.printType = type;
      this.printIndex = i;
      this.isPrintPopup = true;
      let printData = {};
      if (
        this.printType == "front" &&
        this.printFrontImgs[this.printIndex].img.fileNo != null
      ) {
        printData = this.printFrontImgs[this.printIndex];
      }
      if (
        this.printType == "back" &&
        this.printBackImgs[this.printIndex].img.fileNo != null
      ) {
        this.printBackImgs[this.printIndex].img;
        printData = this.printBackImgs[this.printIndex];
      }
      if (printData.img?.fileNo != null) {
        this.selectData(
          this.lineType.filter((e) => e.cd == printData.lineType)[0]
        );
        this.longLength = printData.longLength;
        this.printLength[0] = printData.printLength[0];
        if (printData.printLength.length > 1)
          this.printLength[1] = printData.printLength[1];
        this.img = printData.img;
        this.agree[0] = printData.jasuFlag;
        this.agree[1] = printData.positionFlag;
      } else {
        this.initPrint();
      }
      this.checkPrint();
    },
    checkPrint() {
      if (this.printLength.length == 1) {
        if (this.img?.fileNo > 0 && this.longLength && this.printLength[0]) {
          this.isPrint = true;
        } else {
          this.isPrint = false;
        }
      } else if (this.printLength.length == 2) {
        if (this.printLength.length == 2) {
          if (
            this.img?.fileNo > 0 &&
            this.longLength &&
            this.printLength[0] &&
            this.printLength[1]
          ) {
            this.isPrint = true;
          } else {
            this.isPrint = false;
          }
        }
      }
    },
    addPrint() {
      let printCdoe = "";
      if (this.printType == "front") {
        printCdoe = Number(this.printIndex + 1);
        if (printCdoe < 10) {
          printCdoe = "0" + printCdoe;
        }
      }
      if (this.printType == "back") {
        printCdoe = "1" + Number(this.printIndex + 1);
      }
      const lineType = this.lineType.filter((e) => e.isActive)[0].cd;
      const length1 = this.printLength[0];
      const length2 = this.printLength[1];
      const datas = {
        userId: this.userId,
        customNo: this.customNo,
        fileNo: this.img.fileNo,
        printCode: printCdoe,
        lineType: lineType,
        longLength: this.longLength,
        length1: length1,
        length2: length2,
        jasuFlag: this.agree[0] ? "1" : "0",
        positionFlag: this.agree[1] ? "1" : "0",
      };
      this.printList.push(datas);
      this.$API.custom.printAdd(datas).then((res) => {
        if (this.printType == "front") {
          this.printFrontImgs[this.printIndex].img = this.img;
          this.printFrontImgs[this.printIndex].lineType = lineType;
          this.printFrontImgs[this.printIndex].longLength = this.longLength;
          this.printFrontImgs[this.printIndex].printLength = this.printLength;
          this.printFrontImgs[this.printIndex].jasuFlag = this.agree[0];
          this.printFrontImgs[this.printIndex].positionFlag = this.agree[1];
        }
        if (this.printType == "back") {
          this.printBackImgs[this.printIndex].img = this.img;
          this.printBackImgs[this.printIndex].lineType = lineType;
          this.printBackImgs[this.printIndex].longLength = this.longLength;
          this.printBackImgs[this.printIndex].printLength = this.printLength;
          this.printBackImgs[this.printIndex].jasuFlag = this.agree[0];
          this.printBackImgs[this.printIndex].positionFlag = this.agree[1];
        }
        this.initPrint();
        this.sumUnitCost();
        this.isPrintPopup = false;
        this.isPrint = false;
      });
    },
    closePrint() {
      this.initPrint();
      this.isPrintPopup = false;
    },
    initsize() {
      this.gender.map((e) => (e.isActive = false));
      this.fit.map((e) => (e.isActive = false));
      this.totalLength.map((e) => (e.isActive = false));
      this.sleeveLength.map((e) => (e.isActive = false));
      this.sleeveStyle.map((e) => (e.isActive = false));
      this.sizeSpeclist = [];
      this.sizeValue = [];
      this.isSizeSpec = false;
      this.marking = "";
      this.sizeInput = [];
      this.size = [
        { index: 0, isActive: true, cd: "01", name: "XS" },
        { index: 0, isActive: true, cd: "02", name: "S" },
        { index: 1, isActive: true, cd: "03", name: "M" },
        { index: 2, isActive: true, cd: "04", name: "L" },
        { index: 3, isActive: true, cd: "05", name: "XL" },
        { index: 4, isActive: true, cd: "06", name: "2XL" },
        { index: 4, isActive: true, cd: "07", name: "3XL" },
      ];
      this.sumUnitCost();
    },
    initPrint() {
      this.lineType[0].isActive = true;
      this.lineType[1].isActive = false;
      this.longLength = "";
      this.printLength = [];
      this.img = {};
      this.agree = [false, false];
      this.isPrint = false;
    },
    goModifyPrint() {
      if (this.printList.length > 0) {
        this.printRefresh();
      }
      this.goModify();
    },
    goModify() {
      this.customType = this.items.filter((e) => e.isActive)[0].cd;
      this.apiData.styleName = "";
      this.apiData.customType = this.customType;
      this.apiData.unitPrice = this.unitPrice;
      this.apiData.minUnitPrice = this.minUnitPrice;
      this.apiData.maxUnitPrice = this.maxUnitPrice;
      this.apiData.fabricNo = this.selectedFabric.fabricNo;
      this.apiData.fabricColorNo = this.fabricColorList.map(function (item) {
        return item.fabricColorNo;
      });
      const neckLine = this.neckLine.filter((e) => e.isActive)[0];
      const neckDetail = this.neckDetail.filter((e) => e.isActive)[0];
      const neckEnd = this.neckEnd.filter((e) => e.isActive)[0];
      const stitch = this.stitch.filter((e) => e.isActive)[0];
      const pocket = this.pocket.filter((e) => e.isActive)[0];
      const sleeve = this.sleeve.filter((e) => e.isActive)[0];
      const sideSlit = this.sideSlit.filter((e) => e.isActive)[0];
      const gender = this.gender.filter((e) => e.isActive)[0];
      const fit = this.fit.filter((e) => e.isActive)[0];
      const totalLength = this.totalLength.filter((e) => e.isActive)[0];
      const sleeveLength = this.sleeveLength.filter((e) => e.isActive)[0];
      const sleeveStyle = this.sleeveStyle.filter((e) => e.isActive)[0];
      const sleeveEnd = this.sleeveEnd.filter((e) => e.isActive)[0];
      const hemEnd = this.hemEnd.filter((e) => e.isActive)[0];
      const vgussetType = this.vgusset.button.filter((e) => e.isActive)[0];
      const vgussetSize = this.vgusset.value;
      const xstitchSize = this.xstitch.value;
      const kgrPocketSize = this.kgrPocket.value;
      console.log(this.kgrPocket);
      console.log(kgrPocketSize);

      this.apiData.neckLine = neckLine?.cd;
      this.apiData.neckDetail = neckDetail?.cd;
      this.apiData.neckEnd = neckEnd?.cd;
      this.apiData.stitch = stitch?.cd;
      this.apiData.pocket = pocket?.cd;
      this.apiData.sleeve = sleeve?.cd;
      this.apiData.sideSlit = sideSlit?.cd;
      this.apiData.vgussetType = vgussetType?.cd;
      if (neckDetail?.cd == 3) {
        this.apiData.vgussetSize1 = vgussetSize[0].size;
        this.apiData.vgussetSize2 = vgussetSize[1].size;
      }
      if (neckDetail?.cd == 4) {
        this.apiData.xstitchSize1 = xstitchSize[0].size;
        this.apiData.xstitchSize2 = xstitchSize[1].size;
        this.apiData.xstitchSize3 = xstitchSize[2].size;
      }
      console.log(pocket?.cd);
      if (pocket?.cd == 5) {
        this.apiData.kgrPocketSize1 = kgrPocketSize[0].size;
        this.apiData.kgrPocketSize2 = kgrPocketSize[1].size;
        this.apiData.kgrPocketSize3 = kgrPocketSize[2].size;
        this.apiData.kgrPocketSize4 = kgrPocketSize[3].size;
        this.apiData.kgrPocketSize5 = kgrPocketSize[4].size;
      }

      this.apiData.gender = gender?.cd;
      this.apiData.fit = fit?.cd;
      this.apiData.totalLength = totalLength?.cd;
      this.apiData.sleeveLength = sleeveLength?.cd;
      this.apiData.sleeveStyle = sleeveStyle?.cd;
      this.apiData.sleeveEnd = sleeveEnd?.cd;
      this.apiData.hemEnd = hemEnd?.cd;
      this.apiData.sizeList = [];
      this.apiData.colorList = [];
      this.apiData.sizeInput = this.sizeInput;

      this.size.forEach((e) => {
        if (e.isActive) {
          this.apiData.sizeList.push(e.cd);
        }
      });
      this.color.forEach((e) => {
        if (e.isActive) {
          this.apiData.colorList.push(e.cd);
        }
      });

      if (this.tolSize) {
        this.$API.custom
          .customSizeSepc(
            gender?.cd,
            fit?.cd,
            "",
            "",
            sleeveStyle?.cd,
            neckLine?.cd,
            neckDetail?.cd,
            sleeveEnd?.cd,
            hemEnd?.cd,
            this.customType
          )
          .then((res) => {
            const sizeSpecList = res.data.body;
            let hesSize = [];
            let scsSize = [];
            let newSize = [];
            let nedSize = [];
            if (this.customType == "1") {
              sizeSpecList.forEach((e) => {
                if (!this.newSize.filter((e) => e > 0).length > 0) {
                  newSize.push(e.newSize);
                }
                if (!(this.nedSize.filter((e) => e > 0).length > 0)) {
                  nedSize.push(e.nedSize);
                }
              });
              this.apiData.tolSize = this.tolSize;
              this.apiData.shsSize = this.shsSize;
              this.apiData.hesSize = this.hesSize;
              this.apiData.sllSize = this.sllSize;
              this.apiData.chsSize = this.chsSize;
              this.apiData.whsSize = this.whsSize;
              this.apiData.ahlSize = this.ahlSize;
              this.apiData.sbsSize = this.sbsSize;
              this.apiData.scsSize = this.scsSize;
              this.apiData.srwSize = this.srwSize;
              this.apiData.hrwSize = this.hrwSize;

              this.apiData.hesSize =
                hesSize.length > 0 ? hesSize : this.hesSize;
              this.apiData.scsSize =
                scsSize.length > 0 ? scsSize : this.scsSize;
              this.apiData.newSize =
                newSize.length > 0 ? newSize : this.newSize;
              this.apiData.nedSize =
                nedSize.length > 0 ? nedSize : this.nedSize;
            } else if (this.customType == "2") {
              sizeSpecList.forEach((e) => {
                console.log("e");
                console.log(e);
                console.log(this.hesSize);
                console.log(this.scsSize);
                console.log(e.hesSize);
                console.log(e.scsSize);
                console.log(this.newSize);
                console.log(this.nedSize);
                if (!this.hesSize.filter((e) => e > 0).length > 0) {
                  hesSize.push(e.hesSize);
                }
                if (!this.scsSize.filter((e) => e > 0).length > 0) {
                  scsSize.push(e.scsSize);
                }
                if (!this.newSize.filter((e) => e > 0).length > 0) {
                  newSize.push(e.newSize);
                }
                if (!this.nedSize.filter((e) => e > 0).length > 0) {
                  nedSize.push(e.nedSize);
                }
              });
              this.apiData.tolSize = this.tolSize;
              this.apiData.shsSize = this.shsSize;
              this.apiData.sllSize = this.sllSize;
              this.apiData.chsSize = this.chsSize;
              this.apiData.whsSize = this.whsSize;
              this.apiData.ahlSize = this.ahlSize;
              this.apiData.sbsSize = this.sbsSize;
              this.apiData.srwSize = this.srwSize;
              this.apiData.hrwSize = this.hrwSize;
              this.apiData.hesSize = this.hesSize;
              this.apiData.scsSize = this.scsSize;

              this.apiData.hesSize =
                hesSize.length > 0 ? hesSize : this.hesSize;
              this.apiData.scsSize =
                scsSize.length > 0 ? scsSize : this.scsSize;
              this.apiData.newSize =
                newSize.length > 0 ? newSize : this.newSize;
              this.apiData.nedSize =
                nedSize.length > 0 ? nedSize : this.nedSize;
            }

            this.$API.custom.customModify(this.apiData).then((res) => {
              this.$emit("goConfirm");
            });
          });
      } else {
        this.$API.custom.customModify(this.apiData).then((res) => {
          this.$emit("goConfirm");
        });
      }
    },
    toggleSize(index) {
      this.size[index].isActive = !this.size[index].isActive;
    },
    toggleColorHover(index, listIndex) {
      if (listIndex >= 0) {
        this.color[index].list[listIndex].isHover = true;
      } else if (index >= 0) {
        this.color[index].isHover = true;
      } else {
        this.color.forEach((e) => (e.isHover = false));
        this.color.forEach((e) => e.list.forEach((e2) => (e2.isHover = false)));
      }
    },
    toggleTab(index) {
      this.$emit("clickStep", index);
    },
    sumUnitCost(marking) {
      const customType = this.items.filter((e) => e.isActive)[0].cd;
      if (marking) this.marking = marking;
      const fabricCost = this.fabricCost;
      const neckLine = this.neckLine.filter((e) => e.isActive)[0];
      const neckDetail = this.neckDetail.filter((e) => e.isActive)[0];
      const neckEnd = this.neckEnd.filter((e) => e.isActive)[0];
      const stitch = this.stitch.filter((e) => e.isActive)[0];
      const pocket = this.pocket.filter((e) => e.isActive)[0];
      const sleeve = this.sleeve.filter((e) => e.isActive)[0];
      const sideSlit = this.sideSlit.filter((e) => e.isActive)[0];
      const sleeveEnd = this.sleeveEnd.filter((e) => e.isActive)[0];
      const hemEnd = this.hemEnd.filter((e) => e.isActive)[0];
      let unitCost = 0;
      let minUnitCost = 0;
      let maxUnitCost = 0;
      if (customType == 1) {
        minUnitCost = 2000;
        maxUnitCost = 8000;
      } else if (customType == 2) {
        minUnitCost = 4000;
        maxUnitCost = 10000;
      }
      unitCost += Number(fabricCost);
      minUnitCost += Number(fabricCost);
      maxUnitCost += Number(fabricCost);
      let optionCost = 0;
      if (neckLine) optionCost += neckLine.addAmount;
      if (neckDetail) {
        if (customType == 1) {
          optionCost +=
            neckDetail.addAmount > 0
              ? neckDetail.addAmount * 1
              : fabricCost * neckDetail.addRate;
        } else if (customType == 2) {
          //티셔츠와 맨투맨 차이 50
          optionCost +=
            neckDetail.addAmount > 0
              ? neckDetail.addAmount * 1 + 50
              : fabricCost * neckDetail.addRate;
        }
      }
      if (neckEnd) {
        if (customType == 1) {
          optionCost +=
            neckEnd.addAmount != "-" ? fabricCost * neckEnd.addRate : 0;
        } else if (customType == 2) {
          optionCost += neckEnd.addAmount != "-" ? neckEnd.addAmount : 0;
        }
      }
      if (stitch)
        optionCost += stitch.addAmount * 1 > 0 ? stitch.addAmount * 1 : 0;
      if (pocket) {
        if (pocket.index > 0 && pocket.index < 4) {
          optionCost += pocket.addAmount * 1;
        } else if (pocket.index == 4) {
          optionCost += fabricCost * pocket.addRate + pocket.addAmount * 1;
        }
      }
      if (sleeve) {
        optionCost +=
          sleeve.addAmount == 0
            ? fabricCost * sleeve.addRate
            : sleeve.addAmount > 0
            ? sleeve.addAmount * 1
            : 0;
      }
      if (sleeveEnd) {
        optionCost +=
          sleeveEnd.addAmount == 0
            ? fabricCost * sleeveEnd.addRate
            : sleeveEnd.addAmount > 0
            ? sleeveEnd.addAmount * 1
            : 0;
      }
      if (hemEnd) {
        optionCost +=
          hemEnd.addAmount == 0
            ? fabricCost * hemEnd.addRate
            : hemEnd.addAmount > 0
            ? hemEnd.addAmount * 1
            : 0;
      }
      if (sideSlit) {
        optionCost += sideSlit.addAmount > 0 ? sideSlit.addAmount * 1 : 0;
      }

      unitCost += optionCost;
      minUnitCost += optionCost;
      maxUnitCost += optionCost;
      if (this.marking) {
        //원단 금액을 요척을 곱하여 계산한 값
        unitCost += this.fabricCost * (this.marking - 1);
        minUnitCost += fabricCost * (this.marking - 1);
        maxUnitCost += fabricCost * (this.marking - 1);
      }
      if (this.printList.length > 0) {
        this.printList.forEach((e) => {
          unitCost += 2000 + e.longLength * e.longLength * 10;
          minUnitCost += 2000 + e.longLength * e.longLength * 10;
          maxUnitCost += 2000 + e.longLength * e.longLength * 10;
        });
      }
      this.unitPrice = Math.ceil(unitCost / 10) * 10;
      this.minUnitPrice = Math.ceil(minUnitCost / 10) * 10;
      this.maxUnitPrice = Math.ceil(maxUnitCost / 10) * 10;
      // this.$emit("addUnitPrice", this.comma(this.unitPrice));
    },
    toggleComm(type, index) {
      if (type == "vgusset") {
        this[type].button.forEach((e) => (e.isActive = false));
        this[type].button[index].isActive = true;
        this.apiData[type] = this[type].button[index].cd;
      } else {
        this[type].forEach((e) => (e.isActive = false));
        this[type][index].isActive = true;
        this.apiData[type] = this[type][index].cd;
      }

      if (type == "checkSize") {
        this.checkSize.map((e) => (e.isDisabled = true));
        this.checkSize.filter((e) => e.isActive)[0].isDisabled = false;
      } else if (type == "items") {
        this.loadFabric();
      }
      if (this.step == 0 && this.items.filter((e) => e.isActive).length > 0) {
        this.customType = this.items.filter((e) => e.isActive)[0]?.cd;
        this.isValidation[0] = true;
      }
      if (this.step == 3) {
        this.customType = this.items.filter((e) => e.isActive)[0]?.cd;
        console.log(this.customType);
        if (
          this.customType == "1" &&
          this.neckLine.filter((e) => e.isActive).length > 0 &&
          this.neckDetail.filter((e) => e.isActive).length > 0 &&
          ((this.neckDetail.filter((e) => e.isActive).length > 0 &&
            this.neckDetail.filter((e) => e.isActive)[0].cd == "2") ||
            this.neckEnd.filter((e) => e.isActive).length > 0) &&
          this.stitch.filter((e) => e.isActive).length > 0 &&
          this.pocket.filter((e) => e.isActive).length > 0 &&
          this.sleeve.filter((e) => e.isActive).length > 0 &&
          this.sideSlit.filter((e) => e.isActive).length > 0
        ) {
          this.isValidation[3] = true;
        } else if (
          this.customType == "2" &&
          this.neckLine.filter((e) => e.isActive).length > 0 &&
          this.neckDetail.filter((e) => e.isActive).length > 0 &&
          this.neckEnd.filter((e) => e.isActive).length > 0 &&
          this.stitch.filter((e) => e.isActive).length > 0 &&
          this.pocket.filter((e) => e.isActive).length > 0 &&
          this.sleeveEnd.filter((e) => e.isActive).length > 0 &&
          this.hemEnd.filter((e) => e.isActive).length > 0
        ) {
          this.isValidation[3] = true;
        }
        setTimeout(() => {
          const container = this.$refs.customAdd;
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth", // 스무스 스크롤 옵션
          });
        }, 100);
      }
      this.sumUnitCost();
    },
    handleDrop(event) {
      this.fileUpload("81", 1, event);
    },
    fileUpload(fileStatus, maxLength, event) {
      this.errorData.imgList = false;
      let files = [];
      let c = maxLength - this.imgList.length;

      let msg = "";

      let fileList;
      if (event.dataTransfer?.files.length > 0) {
        fileList = event.dataTransfer.files;
      } else if (event.target) {
        fileList = event.target.files;
      }
      this.files = fileList;
      Array.from(fileList).forEach((e) => {
        if (
          (e.name.indexOf(".ai") == -1 && e.size >= 1024000 * 10) ||
          (e.name.indexOf(".ai") != -1 && e.size >= 1024000 * 100)
        ) {
          // 파일 용량 제한
          msg = "파일 용량이 초과되었습니다.";
          if (msg) this.showToast(msg);
          return;
        }
        files.push(e);
        c = c - 1;
      });

      this.$API.comm
        .fileUploadMultiple(
          sessionStorage.getItem("userId"),
          this.customNo,
          fileStatus,
          "8",
          files
        )
        .then((res) => {
          res.data.body.forEach((e, i) => {
            let d = e["imgFile" + (i + 1)]; // api 수정시 변경
            this.imgList.push({
              fileNo: d.fileNo,
              fileSize: d.fileSize,
              fileStatus: d.fileStatus,
              fileType: d.fileType,
              fileUrl: d.previewUrl,
              sourceFileName: d.sourceFileName,
            });
            this.img = {
              fileNo: d.fileNo,
              fileSize: d.fileSize,
              fileStatus: d.fileStatus,
              fileType: d.fileType,
              fileUrl: d.previewUrl,
              sourceFileName: d.sourceFileName,
            };
            this.checkPrint();
          });
        });
    },
    removeImg(fileNo) {
      this.imgList = this.imgList.filter((e) => e.fileNo != fileNo);
      this.img = {};
      // input element에 접근
      const imgFile = this.$refs.imgFile;
      // 초기화
      imgFile.value = "";
      this.checkPrint();
    },
    refresh(isRefresh) {
      this.isRecommend = false;
      this.isValidation[2] = false;
      setTimeout(() => {
        this.isSizeRefresh = false;
      }, 100);
      this.isRefreshPopup = false;
      this.checkSize.map((e) => (e.isDisabled = false));
      this.initsize();
      if (isRefresh) {
        this.isToast("사이즈 Step에 입력하신 모든 내용이 초기화되었습니다.");
      }
    },
    dtlRefresh() {
      this.neckLine.map((e) => (e.isActive = false));
      this.neckDetail.map((e) => (e.isActive = false));
      this.neckEnd.map((e) => (e.isActive = false));
      this.stitch.map((e) => (e.isActive = false));
      this.pocket.map((e) => (e.isActive = false));
      this.sleeve.map((e) => (e.isActive = false));
      this.sideSlit.map((e) => (e.isActive = false));
      this.sleeveEnd.map((e) => (e.isActive = false));
      this.hemEnd.map((e) => (e.isActive = false));
      this.isDtlRefreshPopup = false;
      this.isToast("디테일 Step에 입력하신 모든 내용이 초기화되었습니다.");
      const addHeight = document.querySelector(".custom-add");
      addHeight.scrollTop = 0;
    },
    printRefresh() {
      this.$API.custom.printRemove(this.userId, this.customNo).then((res) => {
        this.printList = [];
        this.printFrontImgs.forEach((e) => {
          e.img = "";
          e.lineType = "lineType";
          e.longLength = "";
          e.printLength = "";
          e.jasuFlag = "";
          e.positionFlag = "";
        });
        this.printBackImgs.forEach((e) => {
          e.img = "";
          e.lineType = "lineType";
          e.longLength = "";
          e.printLength = "";
          e.jasuFlag = "";
          e.positionFlag = "";
        });
        this.initPrint();
        this.sumUnitCost();

        this.isPrintRefreshPopup = false;
        this.isToast("프린팅 Step에 입력하신 모든 내용이 초기화되었습니다.");
        const addHeight = document.querySelector(".custom-add");
        addHeight.scrollTop = 0;
      });
    },
    selectData(data, type) {
      this.lineType.forEach((e) => {
        e.isActive = false;
        if (e.cd == data.cd) e.isActive = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/custom.scss";
</style>
