<template>
  <div class="order">
    <div class="bg">
      <div class="bg-gray"></div>
    </div>
    <div class="order-confirm">
      <div class="order-confirm-title">
        <SvgImg
          :size="20"
          :d="$store.state.meta.svg.orderIcon"
          :color="$store.state.meta.svg.color.primary50"
        />주문 관리
      </div>
      <div class="order-confirm-section">
        <div class="title-name"><p>주문 정보</p></div>
        <div class="row">
          <div class="row-name">· 스타일/상품 명</div>
          <div class="row-value">{{ apiData.styleName }}</div>
        </div>
        <div class="row">
          <div class="row-name">· 1pcs 당, 기준 단가</div>
          <div class="row-value">
            {{ comma(apiData.minUnitPrice) }}원 ~
            {{ comma(Number(apiData.maxUnitPrice)) }}원
          </div>
        </div>
      </div>
      <div class="order-confirm-section">
        <div class="title">
          <div class="title-name"><p>주문제작 수량</p></div>
        </div>
        <div class="size-table">
          <SizeColorTable
            :inputType="'text'"
            :color="apiData.fabricColorList"
            @setOrder="setOrder"
          />
        </div>
      </div>

      <div class="order-confirm-section" v-if="isMobile == 'true'">
        <div class="title">
          <div class="title-name"><p>결제 금액</p></div>
          <SvgImg
            class="pointer"
            @click="isSalePopup = true"
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
        <div class="row">
          <div class="row-name">· 상품 금액</div>
          <div class="row-value">
            <!-- <span v-if="totalCount < 20 && totalCount > 0 && add > 0"
              >{{ add }}원</span
            >
            <span v-else-if="totalCount > 0 && rate > 0">{{ rate }}%</span> -->
            <span v-if="totalCount > 9">
              -
              {{
                comma(
                  apiData.maxUnitPrice * totalCount - salePrice * totalCount
                )
              }}원</span
            >
            <p class="price">{{ comma(salePrice * totalCount) }}원</p>
            <p class="cancel" v-if="totalCount > 0">
              <!-- [{{ unitPrice }}]  -->
              {{ comma(apiData.maxUnitPrice * totalCount) }}원
            </p>
          </div>
        </div>
        <div class="row">
          <div class="row-name">· 배송</div>
          <div class="row-value">
            <p>{{ comma(deliveryPrice * boxCount) }}원</p>
          </div>
        </div>
        <!-- <div class="row" v-if="tab.filter((e) => e.cd == 1)[0].isActive">
          <div class="row-name">· 검수/검품</div>
          <div class="row-value">
            <p>{{ comma(inspectPrice * totalCount) }}원</p>
          </div>
        </div> -->
        <div class="row">
          <div class="row-name">· 부가세 (VAT)</div>
          <div class="row-value">
            <p>{{ comma(vat) }}원</p>
          </div>
        </div>
        <div class="row">
          <div class="row-name">· 결제 예정 금액</div>
          <div class="row-value">{{ comma(totalPrice) }}원</div>
        </div>
      </div>
      <div class="order-confirm-section">
        <div class="title">
          <div class="title-name"><p>제작 요청사항</p></div>
        </div>
        <div>
          <textarea
            v-model="requestDesc"
            placeholder="제작 요청사항이 있으면, 작성해주세요."
          ></textarea>
        </div>
      </div>
      <div class="order-confirm-section" v-if="isMobile == 'false'">
        <div class="title">
          <div class="title-name"><p>배송지 정보</p></div>
          <div
            class="valid"
            v-if="
              !userInfo.userName || !userInfo.telNum || !userInfo.compAddress
            "
          >
            수량을 입력해주세요.
          </div>
        </div>

        <div class="delivery-info">
          <div class="row">
            <div class="row-wrap">
              <div class="row-column">
                <p class="row-column-name">· 받는 사람 <span>*</span></p>
                <p
                  class="row-column-input"
                  :class="{ empty: !userInfo.userName }"
                >
                  <input
                    type="text"
                    v-model="userInfo.userName"
                    placeholder="받는 사람을 입력해주세요."
                  />
                </p>
              </div>
              <div class="row-column">
                <p class="row-column-name">· 연락처 <span>*</span></p>
                <p
                  class="row-column-input"
                  :class="{ empty: !userInfo.telNum }"
                >
                  <input
                    type="text"
                    v-model="userInfo.telNum"
                    placeholder="연락처를 입력해주세요."
                  />
                </p>
              </div>
            </div>
            <div class="row-wrap">
              <div class="row-column">
                <p class="row-column-name">· 배송 주소 <span>*</span></p>
                <p
                  class="row-column-input"
                  :class="{ empty: !userInfo.compAddress }"
                >
                  <input
                    type="text"
                    v-model="userInfo.compAddress"
                    readonly
                    @click="getAddress('compAddress')"
                    placeholder="주소를 입력해주세요."
                  />
                </p>
              </div>
              <div class="row-column">
                <p class="row-column-name">· 상세 주소 <span>*</span></p>
                <p class="row-column-input">
                  <input
                    type="text"
                    v-model="userInfo.compAddressDetail"
                    placeholder="상세 주소를 입력해주세요."
                  />
                </p>
              </div>
            </div>
          </div>
          <div class="colomn mt-20">
            <p class="colomn-name">· 배송 메모</p>
            <p class="colomn-input">
              <input
                type="text"
                v-model="userInfo.deliveryMemo"
                placeholder="배송 시, 요청 사항을 입력해주세요."
              />
            </p>
          </div>
        </div>
      </div>
      <div class="order-confirm-section" v-else>
        <div class="title">
          <div class="title-name"><p>배송지 정보</p></div>
          <div
            class="valid"
            v-if="
              !userInfo.userName || !userInfo.telNum || !userInfo.compAddress
            "
          >
            수량을 입력해주세요.
          </div>
        </div>
        <div class="delivery-info">
          <div class="colomn mt-20">
            <p class="row-column-name">· 받는 사람 <span>*</span></p>
            <p class="row-column-input" :class="{ empty: !userInfo.userName }">
              <input
                type="text"
                v-model="userInfo.userName"
                placeholder="받는 사람을 입력해주세요."
              />
            </p>
          </div>
          <div class="colomn mt-20">
            <p class="row-column-name">· 연락처 <span>*</span></p>
            <p class="row-column-input" :class="{ empty: !userInfo.telNum }">
              <input
                type="text"
                v-model="userInfo.telNum"
                placeholder="연락처를 입력해주세요."
              />
            </p>
          </div>
          <div class="colomn mt-20">
            <p class="row-column-name">· 배송 주소 <span>*</span></p>
            <p
              class="row-column-input"
              :class="{ empty: !userInfo.compAddress }"
            >
              <input
                type="text"
                v-model="userInfo.compAddress"
                readonly
                @click="getAddress('compAddress')"
                placeholder="주소를 입력해주세요."
              />
            </p>
          </div>
          <div class="colomn mt-20">
            <p class="row-column-name">· 상세 주소 <span>*</span></p>
            <p class="row-column-input">
              <input
                type="text"
                v-model="userInfo.compAddressDetail"
                placeholder="상세 주소를 입력해주세요."
              />
            </p>
          </div>
          <div class="colomn mt-20">
            <p class="colomn-name">· 배송 메모</p>
            <p class="colomn-input">
              <input
                type="text"
                v-model="userInfo.deliveryMemo"
                placeholder="배송 시, 요청 사항을 입력해주세요."
              />
            </p>
          </div>
        </div>
      </div>
      <div class="order-confirm-section">
        <div class="title-name"><p>결제 방법</p></div>
        <div class="paymethod">
          <div
            v-for="(x, i) in isChkPayment"
            :key="i"
            @click="checkPayment(x.cd)"
            class="select-btn-box"
            :class="x.isActive ? 'active' : ''"
          >
            {{ x.name }}
          </div>
        </div>
      </div>
      <div class="order-confirm-section">
        <div class="title-name"><p>안내 사항</p></div>
        <div class="info">
          <div class="info-top">
            <div class="info-top-wrap">
              <p>※</p>
              <p>
                제작 소요기간은 10일 이내이며, 원단 재고현황 및 주문량 등에 따라
                변동될 수 있으며, 이에 경우 고객센터에서 연락드립니다.
              </p>
            </div>
            <div class="info-top-wrap">
              <p>※</p>
              <p>
                오슬의 커스텀 몰은 고객 주문에 따라 100% 개별 제작되는 방식으로
                단순 변심을 포함, 아래의 경우에는 교환 및 환불이 불가합니다.
              </p>
            </div>
          </div>
          <div class="info-bottom">
            <p>
              · 사이즈 스펙의 경우, 측정 방법과 위치에 따라 1~3cm의 오차가
              발생할 수 있습니다.
            </p>
            <p>
              · 인쇄의 경우, 모니터 또는 종이 출력물과 약간의 색상 차이가 발생할
              수 있습니다.
            </p>
            <p>
              · 인쇄의 경우, 대부분 수작업으로 이루어지기 때문에 실제 상품의
              인쇄 위치 및 크기에 따라 1~3mm의 오차가 발생할 수 있습니다.
            </p>
            <p>
              · 원단의 경우, 롯트 단위로 새롭게 염색하기 때문에 출시 때마다
              생지의 색상, 습도 등의 환경 차이에 의해 탕 차이가 발생할 수
              있습니다.
            </p>
            <p>
              · 실밥 제거 작업 후에 검수 후, 배송되지만 대부분 수작업으로
              이루어지기 때문에 일부 미흡한 상품이 발생할 수 있습니다.
            </p>
            <p>
              · 아이롱 작업 후에 상품의 반듯한 상태를 유지하기 위하여 박스로
              발송되지만, 배송 시 상황에 따라 일부 구김이 발생할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
      <div class="order-confirm-section">
        <div class="info-agree-check">
          <div class="check-all">
            <CheckBox
              :name="'모두 동의합니다.'"
              :checked="isPaymentCheck[0]"
              :type="'radio'"
              :hoverOff="true"
              @click="allCheck()"
            />
          </div>
          <div class="check-list">
            <div class="check-list-wrap">
              <CheckBox
                :name="'안내 사항을 모두 확인하였습니다. (필수)'"
                :checked="isPaymentCheck[1]"
                :type="'radio'"
                :color="'light'"
                :hoverOff="true"
                @click="isPaymentCheck[1] = !isPaymentCheck[1]"
              />
            </div>
            <div class="check-list-wrap">
              <CheckBox
                :name="'이용 약관에 동의합니다. (필수)'"
                :checked="isPaymentCheck[2]"
                :type="'radio'"
                :color="'light'"
                :hoverOff="true"
                @click="isPaymentCheck[2] = !isPaymentCheck[2]"
              />
              <span @click="gotoUrl('/terms/termsofservice', 1)">[보기]</span>
            </div>
            <div class="check-list-wrap">
              <CheckBox
                :name="'개인정보 수집 및 이용에 동의합니다. (필수)'"
                :checked="isPaymentCheck[3]"
                :type="'radio'"
                :color="'light'"
                :hoverOff="true"
                @click="isPaymentCheck[3] = !isPaymentCheck[3]"
              />
              <span @click="gotoUrl('/terms/privacypolicy', 1)">[보기]</span>
            </div>
            <div class="check-list-wrap">
              <CheckBox
                :name="'마케팅 수신과 홍보 목적의 개인정보 수집 및 이용에 동의합니다. (선택)'"
                :checked="isPaymentCheck[4]"
                :type="'radio'"
                :color="'light'"
                :hoverOff="true"
                @click="isPaymentCheck[4] = !isPaymentCheck[4]"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="order-confirm-btn">
        <p class="active" @click="paymentPayple()">
          주문하기<SvgImg
            :size="20"
            :d="$store.state.meta.svg.rightArrowBig"
            :color="$store.state.meta.svg.color.neutral0"
          />
        </p>
      </div>
    </div>
    <div class="order-bill" v-if="isMobile == 'false'">
      <div class="order-bill-info">
        <div class="title">
          <div class="title-name"><p>총 결제 금액</p></div>
          <SvgImg
            class="pointer"
            @click="isSalePopup = true"
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
        <div class="row">
          <div class="row-name">· 상품 금액</div>
          <div class="row-value">
            <!-- <span v-if="totalCount < 20 && totalCount > 0 && add > 0"
              >{{ add }}원</span
            >
            <span v-else-if="totalCount > 0 && rate > 0">{{ rate }}%</span> -->
            <span v-if="totalCount > 9">
              -
              {{
                comma(
                  apiData.maxUnitPrice * totalCount - salePrice * totalCount
                )
              }}원</span
            >
            <p class="price">{{ comma(salePrice * totalCount) }}원</p>
            <p class="cancel" v-if="totalCount > 0">
              <!-- [{{ unitPrice }}]  -->
              {{ comma(apiData.maxUnitPrice * totalCount) }}원
            </p>
          </div>
        </div>
        <div class="row">
          <div class="row-name">· 배송</div>
          <div class="row-value">
            <p>{{ comma(deliveryPrice * boxCount) }}원</p>
          </div>
        </div>
        <!-- <div class="row" v-if="tab.filter((e) => e.cd == 1)[0].isActive">
          <div class="row-name">· 검수/검품</div>
          <div class="row-value">
            <p>{{ comma(inspectPrice * totalCount) }}원</p>
          </div>
        </div> -->
        <div class="row">
          <div class="row-name">· 부가세 (VAT)</div>
          <div class="row-value">
            <p>{{ comma(vat) }}원</p>
          </div>
        </div>
        <div class="row">
          <div class="row-name">· 결제 예정 금액</div>
          <div class="row-value">{{ comma(totalPrice) }}원</div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-sale" v-if="isSalePopup">
    <div class="popup-sale-background"></div>
    <div class="popup-sale-wrap">
      <div class="top">
        <p class="title">· 주문 수량 별, 금액 변동</p>
      </div>
      <div class="info">
        <!-- <div class="info-tab">
          <div
            class="info-tab-btn"
            v-for="(x, i) in saleTab"
            :key="i"
            :class="{ active: x.isActive }"
            @click="clickSaleTab(i)"
          >
            {{ x.name }}
          </div>
        </div> -->
        <div class="info-wrap">
          <div
            class="info-wrap-row"
            v-for="(priceData, i) in unitPriceTable"
            :key="i"
          >
            <div class="row-top">
              <p v-if="priceData.name == '1000'">1,000 이상</p>
              <p v-else>{{ priceData.name }}</p>
            </div>
            <div
              class="row-body"
              :class="{
                active: priceData.name.split('~')[0] == 1000,
              }"
            >
              <p v-if="priceData.btnPath" @click="gotoPate(priceData.btnPath)">
                ‘1:1 문의’ 또는 ‘고객센터’로 문의 주세요 ✨
              </p>
              <p v-else-if="apiData.customType == '1'">
                {{ comma(priceData.tshirt * 1 + unitPrice * 1) }}
              </p>
              <p v-else-if="apiData.customType == '2'">
                {{ comma(priceData.mtm * 1 + unitPrice * 1) }}
              </p>
              <p v-else>{{ comma(priceData.add * 1 + unitPrice * 1) }}</p>
              <!-- <p v-else-if="priceData.rate > 0">-{{ priceData.rate }}%</p>
              <p v-else>0</p> -->
            </div>
            <div class="row-bottom">
              <div class="row-bottom-arrow" v-if="i % 5 == 0">
                <img src="@/assets/img/img-custom-order-sale-arrow.svg" />
              </div>
              <div class="row-bottom-state">
                <span class="row-bottom-left" v-if="i % 5 == 0"></span>
                <span class="row-bottom-right" v-if="!priceData.btnPath"></span>
              </div>
              <div class="row-bottom-text">
                <p
                  class="row-bottom-left"
                  :class="{
                    two: priceData.name.split('~')[0].trim().length == 2,
                    three: priceData.name.split('~')[0].trim().length == 3,
                    four: priceData.name.split('~')[0].trim().length == 4,
                    five: priceData.name.split('~')[0].trim().length == 5,
                  }"
                >
                  {{ comma(priceData.name.split("~")[0]) }}
                </p>
                <p
                  v-if="i % 5 == 4 && priceData.value <= 999"
                  class="row-bottom-right"
                  :class="{
                    two: priceData.value > 9 && priceData.value < 100,
                    three: priceData.value > 99 && priceData.value < 1000,
                    four: priceData.value > 999,
                  }"
                >
                  {{ comma(priceData.value + 1) }}
                </p>
                <p
                  v-else-if="i % 5 == 4 && priceData.value > 999"
                  class="row-bottom-right"
                >
                  ∞
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sale-bottom">
        <div class="sale-bottom-btns">
          <p class="sale-bottom-btns-btn active" @click="isSalePopup = false">
            확인
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-direct" v-if="isPopup">
    <div class="popup-direct-background"></div>
    <div class="popup-direct-wrap">
      <div class="close" @click="isPopup = false">
        <img src="@/assets/icon/ic-popup-close.svg" />
      </div>
      <div class="top">
        <p class="square"></p>
        <p class="title">계좌이체</p>
      </div>
      <div class="info">
        <div class="info-wrap">
          <div class="input">
            <div class="space-between">
              <p class="input-item">계좌번호</p>
            </div>
            <div class="input-bank">
              <input
                type="text"
                class="txt"
                value="기업은행   213-104908-04-010"
                readonly="true"
              />
            </div>
          </div>
          <div class="input">
            <p class="input-item">입금 예금주 명 <span>*</span></p>
            <div class="input-wrap">
              <input
                type="text"
                v-model="userInfo.bankUserName"
                placeholder="예금주 명을 입력해주세요."
              />
            </div>
          </div>
        </div>
      </div>
      <div class="calc-bottom">
        <div class="calc-bottom-btns">
          <p class="calc-bottom-btns-btn active" @click="paymentDirect()">
            주문하기
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadTossPayments } from "@tosspayments/payment-sdk";

export default {
  name: "CustomOrder",
  data() {
    return {
      isSalePopup: false,
      isPopup: false,
      tab: [
        { cd: 1, name: "메인 제작", isActive: true },
        { cd: 2, name: "샘플 제작", isActive: false },
      ],
      saleTab: [
        { cd: 1, name: "100 pcs 미만", isActive: true },
        { cd: 2, name: "100 pcs 이상", isActive: false },
      ],
      size: [
        { index: 0, isActive: true, cd: "01", name: "XS" },
        { index: 1, isActive: true, cd: "02", name: "S" },
        { index: 2, isActive: true, cd: "03", name: "M" },
        { index: 3, isActive: true, cd: "04", name: "L" },
        { index: 4, isActive: true, cd: "05", name: "XL" },
        { index: 5, isActive: true, cd: "06", name: "2XL" },
        { index: 6, isActive: true, cd: "07", name: "3XL" },
      ],
      unitPriceTable: [
        {
          cd: 1,
          groupNo: "1",
          value: 9,
          min: 1,
          max: 9,
          name: "1 ~ 9",
          add: 15200,
          tshirt: 8000,
          mtm: 10000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 2,
          groupNo: "1",
          value: 19,
          min: 10,
          max: 19,
          name: "10 ~ 19",
          add: 11880,
          tshirt: 7000,
          mtm: 9000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 3,
          groupNo: "1",
          value: 29,
          min: 20,
          max: 29,
          name: "20 ~ 29",
          add: 9520,
          tshirt: 6000,
          mtm: 8000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 4,
          groupNo: "1",
          value: 39,
          min: 30,
          max: 39,
          name: "30 ~ 39",
          add: 9520,
          tshirt: 4000,
          mtm: 6000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 5,
          groupNo: "1",
          value: 49,
          min: 40,
          max: 49,
          name: "40 ~ 49",
          add: 8120,
          tshirt: 4000,
          mtm: 6000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 6,
          groupNo: "1",
          value: 59,
          min: 50,
          max: 59,
          name: "50 ~ 59",
          add: 6440,
          tshirt: 2500,
          mtm: 4500,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 7,
          groupNo: "1",
          value: 69,
          min: 60,
          max: 69,
          name: "60 ~ 69",
          add: 6210,
          tshirt: 2500,
          mtm: 4500,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 8,
          groupNo: "1",
          value: 79,
          min: 70,
          max: 79,
          name: "70 ~ 79",
          add: 5980,
          tshirt: 2500,
          mtm: 4500,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 9,
          groupNo: "1",
          value: 89,
          min: 80,
          max: 89,
          name: "80 ~ 89",
          add: 5130,
          tshirt: 2500,
          mtm: 4500,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 10,
          groupNo: "1",
          value: 99,
          min: 90,
          max: 99,
          name: "90 ~ 99",
          add: 4920,
          tshirt: 2500,
          mtm: 4500,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 11,
          groupNo: "1",
          value: 149,
          min: 100,
          max: 149,
          name: "100 ~ 149",
          add: 4110,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 12,
          groupNo: "1",
          value: 199,
          min: 150,
          max: 199,
          name: "150 ~ 199",
          add: 4090,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 13,
          groupNo: "1",
          value: 249,
          min: 200,
          max: 249,
          name: "200 ~ 249",
          add: 4070,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 14,
          groupNo: "1",
          value: 299,
          min: 250,
          max: 299,
          name: "250 ~ 299",
          add: 4050,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 15,
          groupNo: "1",
          value: 349,
          min: 300,
          max: 349,
          name: "300 ~ 349",
          add: 3480,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 16,
          groupNo: "2",
          value: 399,
          min: 350,
          max: 399,
          name: "350 ~ 399",
          add: 3460,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 17,
          groupNo: "2",
          value: 459,
          min: 400,
          max: 459,
          name: "400 ~ 449",
          add: 3450,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 18,
          groupNo: "2",
          value: 499,
          min: 450,
          max: 499,
          name: "450 ~ 499",
          add: 3430,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 19,
          groupNo: "2",
          value: 549,
          min: 500,
          max: 549,
          name: "500 ~ 549",
          add: 3410,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 20,
          groupNo: "2",
          value: 599,
          min: 550,
          max: 599,
          name: "550 ~ 599",
          add: 3400,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 21,
          groupNo: "2",
          value: 699,
          min: 600,
          max: 699,
          name: "600 ~ 649",
          add: 3380,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 21,
          groupNo: "2",
          value: 699,
          min: 650,
          max: 699,
          name: "650 ~ 699",
          add: 3370,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 22,
          groupNo: "2",
          value: 799,
          min: 700,
          max: 799,
          name: "700 ~ 749",
          add: 3350,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 22,
          groupNo: "2",
          value: 799,
          min: 750,
          max: 799,
          name: "750 ~ 799",
          add: 3340,
          tshirt: 2300,
          mtm: 4300,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 23,
          groupNo: "2",
          value: 899,
          min: 800,
          max: 899,
          name: "800 ~ 849",
          add: 3320,
          tshirt: 2000,
          mtm: 4000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 23,
          groupNo: "2",
          value: 899,
          min: 850,
          max: 899,
          name: "850 ~ 899",
          add: 3310,
          tshirt: 2000,
          mtm: 4000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 24,
          groupNo: "2",
          value: 999,
          min: 900,
          max: 999,
          name: "900 ~ 949",
          add: 3290,
          tshirt: 2000,
          mtm: 4000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 24,
          groupNo: "2",
          value: 999,
          min: 950,
          max: 999,
          name: "950 ~ 999",
          add: 3280,
          tshirt: 2000,
          mtm: 4000,
          rate: 0,
          btnPath: "",
        },
        {
          cd: 25,
          groupNo: "2",
          value: 1000,
          min: 1000,
          max: 1000,
          name: "1000",
          add: 0,
          tshirt: 0,
          mtm: 0,
          rate: 0,
          btnPath: "/mypage/inquire",
        },
      ],
      apiData: {},
      step: 0,
      // apiData: {},
      userId: sessionStorage.getItem("userId"),
      compType: sessionStorage.getItem("compType"),
      isMobile: sessionStorage.getItem("isMobile"),
      customNo: "",
      selectedFront: {},
      selectedBack: {},
      patternPrice: 0,
      gradingPrice: 0,
      unitPrice: 0,
      salePrice: 0,
      totalCount: 0,
      samplePrice: 0,
      sampleCount: 0,
      deliveryPrice: 4000,
      inspectPrice: 0,
      boxCount: 0,
      vat: 0,
      totalPrice: 0,
      add: 0,
      rate: 0,
      userInfo: {},
      order: [],
      sample: [],
      paymentType: "",
      direct: "",
      requestDesc: "",
      isChkPayment: [
        { cd: "2", value: "DIRECT", name: "계좌이체", isActive: false },
        // { cd: "1", value: "TOSSPAY", name: "토스페이", isActive: false },
        { cd: "3", value: "CARD", name: "신용카드", isActive: false },
      ],
      isPaymentCheck: [true, true, true, true, true],
      agree: [true, true, true, true, true],
      paymentData: {},
    };
  },
  mounted() {
    let paypleLoaded = false;
    // 페이플 스크립트가 로드된 후 호출 가능한지 확인
    if (typeof window.PaypleCpayAuthCheck === "function") {
      paypleLoaded = true;
    } else {
      // 스크립트가 로드된 후 실행하도록 이벤트 리스너 추가
      window.addEventListener("load", () => {
        paypleLoaded = true;
      });
    }
    this.customNo = this.$route.params.customNo;
    // this.$API.custom.customOrder(this.userId, this.customNo).then((res) => {
    //   this.apiData = res.data.body;
    // });
    console.log(this.apiData);
    // printFrontImgs

    this.$API.custom.customOrder(this.userId, this.customNo).then((res) => {
      this.apiData = res.data.body;

      this.unitPrice = this.apiData.unitPrice;
    });

    if (this.compType == "1") {
      this.$API.mypage.getDesignerInfo(this.userId).then((res) => {
        this.userInfo = res.data.body;
      });
    }
    if (this.compType == "2") {
      this.$API.mypage.getFactoryInfo(this.userId).then((res) => {
        this.userInfo = res.data.body;
      });
    }
    this.loadPaypleScript();
    window.addEventListener("popstate", this.handlePopState);
  },
  unmounted() {
    window.removeEventListener("popstate", this.handlePopState);
  },
  methods: {
    clickSaleTab(i) {
      this.saleTab.map((e) => (e.isActive = false));
      this.saleTab[i].isActive = true;
    },
    handlePopState(event) {
      // 뒤로가기 이벤트를 감지하면 실행될 로직을 작성
      console.log("뒤로가기 이벤트 감지");
      // event.state를 통해 브라우저 이력 상태에 접근할 수 있음
      console.log("새로운 상태:", event.state);
      this.isDetail = this.$route.query.isDetail
        ? this.$route.query.isDetail
        : false;
    },
    toggleTab(cd) {
      this.tab.forEach((e) => (e.isActive = false));
      this.tab.filter((e) => e.cd == cd)[0].isActive = true;
      this.initBilling(cd);
      this.order = [];
      this.sample = [];
    },
    initBilling(cd) {
      if (cd == 1) {
        this.totalCount = 0;
        this.salePrice = Number(this.unitPrice);
      } else {
        this.sampleCount = 0;
        this.salePrice = Number(this.samplePrice) + 25000;
      }
      this.boxCount = 0;
      this.totalPrice = 0;
      this.vat = 0;
    },
    setOrder(obj, type) {
      console.log(this.order);
      console.log(this.sample);
      this[type] = obj;

      this.totalPrice = 0;
      // this.sampleCount = this.sample.reduce(
      //   (acc, curr) => Number(acc) + Number(curr.quantity),
      //   0
      // );
      this.totalCount = this.order.reduce(
        (acc, curr) => Number(acc) + Number(curr.quantity),
        0
      );
      this.boxCount = Math.ceil(this.totalCount / 50);
      // if (this.tab.filter((e) => e.cd == 2)[0].isActive) {
      //   this.boxCount = Math.ceil(this.sampleCount / 50);
      // } else {
      //   this.boxCount = Math.ceil(this.totalCount / 50);
      // }
      const addPrice = this.unitPriceTable.filter(
        (e) => e.min <= this.totalCount && e.max >= this.totalCount
      )[0];
      console.log(this.unitPrice);
      console.log(this.salePrice);
      console.log(addPrice);
      let minUnitPrice = 0;
      let addCost = 0;
      if (this.apiData.customType == "1") {
        minUnitPrice = 2000;
        addCost = Number(addPrice.tshirt ? addPrice.tshirt : minUnitPrice);
      } else if (this.apiData.customType == "2") {
        minUnitPrice = 4000;
        addCost = Number(addPrice.mtm ? addPrice.mtm : minUnitPrice);
      }
      if (addPrice) {
        console.log(addPrice);
        console.log(Number(this.unitPrice) + addCost);

        this.salePrice = Number(this.unitPrice) + addCost;
        console.log("this.salePrice" + this.salePrice);
      }
      this.billing();
    },
    billing() {
      // if (this.tab.filter((e) => e.cd == 2)[0].isActive) {
      //   this.samplePrice = Number(this.unitPrice);
      //   this.salePrice = Number(this.samplePrice) + 25000;
      //   this.totalPrice = this.salePrice * this.sampleCount;
      // } else {
      if (this.totalCount > 0) {
        // this.add = 0;
        // this.rate = 0;
        // this.unitPriceTable.forEach((e, i) => {
        //   if (i == 0 && this.totalCount <= e.value) {
        //     this.salePrice = Number(this.unitPrice) + 8000;
        //     this.add = e.add;
        //   } else if (
        //     i > 0 &&
        //     this.totalCount > this.unitPriceTable[i - 1].value &&
        //     this.totalCount <= e.value
        //   ) {
        //     if (i == 1) {
        //       this.salePrice = Number(this.unitPrice) + 5000;
        //       this.add = e.add;
        //     } else {
        //       if (e.rate > 0) {
        //         this.salePrice = Number(
        //           Math.ceil((this.unitPrice * (100 - e.rate)) / 100 / 10) * 10
        //         );
        //       } else {
        //         this.salePrice = Number(Math.ceil(this.unitPrice / 10) * 10);
        //       }
        //       this.rate = e.rate;
        //     }
        //   }
        // });

        this.totalPrice = this.salePrice * this.totalCount;
        this.totalPrice += this.deliveryPrice * this.boxCount;
        this.vat = Math.ceil(this.totalPrice * 0.1);
        this.totalPrice += this.vat;
        // this.totalPrice += this.inspectPrice * this.totalCount;
      } else {
        this.vat = 0;
      }
      // }
      // if (
      //   this.tab.filter((e) => e.cd == 2)[0].isActive ||
      //   this.totalCount >= 30
      // ) {
      //   this.totalPrice += this.deliveryPrice * this.boxCount;
      //   console.log(this.totalPrice);
      //   this.vat = Math.ceil(this.totalPrice * 0.1);
      //   console.log(this.vat);
      //   this.totalPrice += this.vat;
      // } else {
      //   this.vat = 0;
      // }
    },
    allCheck() {
      this.isPaymentCheck[0] = !this.isPaymentCheck[0];
      this.isPaymentCheck.forEach((e, i) => {
        this.isPaymentCheck[i] = this.isPaymentCheck[0];
      });
    },
    checkPayment(cd) {
      this.isChkPayment.forEach((e) => {
        e.isActive = false;
        if (e.cd == cd) {
          e.isActive = true;
        }
      });
    },
    clickAgree(i) {
      if (i === 0) {
        this.agree[1] = this.agree[0];
        this.agree[2] = this.agree[0];
        this.agree[3] = this.agree[0];
      } else {
        this.agree[0] = this.agree[1] && this.agree[2] && this.agree[3];
      }
    },
    payment() {
      //gotoPage('/custom/order')
      // this.paymentAmount = 1000;
      if (
        this.tab.filter((e) => e.cd == 1)[0].isActive &&
        !(this.totalCount > 0)
      ) {
        alert("수량을 입력해주세요.");
        return;
      } else if (
        this.userInfo.compAddress == null ||
        this.userInfo.compAddress == ""
      ) {
        alert("배송 주소를 입력해주세요.");
        return;
      } else {
        if (this.isChkPayment.filter((e) => e.isActive).length > 0) {
          this.paymentType = this.isChkPayment.filter(
            (e) => e.isActive
          )[0].value;
          if (
            this.isPaymentCheck[1] &&
            this.isPaymentCheck[2] &&
            this.isPaymentCheck[3]
          ) {
            if (this.paymentType == "DIRECT") {
              this.isPopup = true;
            } else {
              let datas = {
                customNo: this.customNo,
                paymentType: this.paymentType,
                orderJson: JSON.stringify(this.order),
                sampleJson: JSON.stringify(this.sample),
                unitPrice: this.unitPrice,
                unitCount: this.totalCount,
                samplePrice: this.samplePrice,
                sampleCount: this.sampleCount,
                deliveryPrice: this.deliveryPrice,
                boxCount: this.boxCount,
                salePrice: this.salePrice,
                totalPrice: this.totalPrice,
                requestDesc: this.requestDesc,
              };
              this.$API.custom
                .customOrderProc(this.userInfo, datas)
                .then((res) => {
                  console.log(res);
                  if (res.data.resultCode == "10000") {
                    let orderDatas = res.data.body;
                    console.log(this.paymentType);
                    console.log(this.apiData.styleName);
                    console.log(this.totalPrice);
                    console.log(this.paymentType);
                    this.$API.payment
                      .createTossCustom(
                        this.userId,
                        orderDatas.orderNo,
                        this.apiData.styleName,
                        this.totalPrice,
                        this.paymentType
                      )
                      .then((res) => {
                        console.log(res);
                        if (res.data.resultCode == "10000") {
                          let d = res.data.body;
                          console.log(d);
                          loadTossPayments(d.clientKey).then((tossPayments) => {
                            tossPayments.requestPayment(this.paymentType, {
                              // 결제 수단 파라미터
                              amount: d.paymentAmount,
                              orderId: d.orderId,
                              orderName: d.itemName,
                              customerName: this.userInfo.userName,
                              successUrl: d.successUrl,
                              failUrl: d.failUrl,
                            });
                            // this.gotoPage("/custom/order/complete/success");
                          });
                        } else {
                          this.setMsgPopup(
                            true,
                            "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                          );
                        }
                      });
                  }
                });
            }
          } else {
            alert("필수 동의를 선택해주세요.");
            return;
          }
        } else {
          alert("결제 방법을 선택해주세요.");
        }
      }
    },
    paymentPayple() {
      if (
        this.tab.filter((e) => e.cd == 1)[0].isActive &&
        !(this.totalCount > 0)
      ) {
        alert("수량을 입력해주세요.");
        return;
      } else if (
        this.userInfo.compAddress == null ||
        this.userInfo.compAddress == ""
      ) {
        alert("배송 주소를 입력해주세요.");
        return;
      } else {
        if (this.isChkPayment.filter((e) => e.isActive).length > 0) {
          this.paymentType = this.isChkPayment.filter(
            (e) => e.isActive
          )[0].value;
          if (
            this.isPaymentCheck[1] &&
            this.isPaymentCheck[2] &&
            this.isPaymentCheck[3]
          ) {
            if (this.paymentType == "DIRECT") {
              this.isPopup = true;
            } else {
              let datas = {
                customNo: this.customNo,
                paymentType: this.paymentType,
                orderJson: JSON.stringify(this.order),
                sampleJson: JSON.stringify(this.sample),
                unitPrice: this.unitPrice,
                unitCount: this.totalCount,
                samplePrice: this.samplePrice,
                sampleCount: this.sampleCount,
                deliveryPrice: this.deliveryPrice,
                boxCount: this.boxCount,
                salePrice: this.salePrice,
                totalPrice: this.totalPrice,
                requestDesc: this.requestDesc,
              };
              this.$API.custom
                .customOrderProc(this.userInfo, datas)
                .then((res) => {
                  console.log(res);
                  if (res.data.resultCode == "10000") {
                    let orderDatas = res.data.body;
                    this.$API.payment
                      .createPaypleCustom(
                        this.userId,
                        orderDatas.orderNo,
                        this.apiData.styleName,
                        this.totalPrice,
                        this.paymentType
                      )
                      .then((res) => {
                        console.log(res);
                        this.paymentData = res.data.body;
                        if (res.data.resultCode == "10000") {
                          let obj = {};
                          // obj.clientKey = "bXFwRzgxVzJiTUdVeHViYnNId2xrUT09";
                          console.log(this.userInfo.userName);
                          obj.PCD_BUYER_NAME = this.userInfo.userName;
                          obj.PCD_PAY_TYPE = "card";
                          obj.PCD_PAY_WORK = "PAY";
                          obj.PCD_CARD_VER = "02";
                          obj.PCD_PAY_GOODS = this.apiData.styleName;
                          // obj.PCD_PAY_TOTAL = 100;
                          obj.PCD_PAY_TOTAL = this.paymentData.paymentAmount;
                          obj.PCD_PAY_URL = this.paymentData.returnUrl;
                          obj.PCD_AUTH_KEY = this.paymentData.authKey;

                          // obj.PCD_RST_URL =
                          //   "/custom/orderResult/" + paymentData.orderNum;
                          // obj.callbackFunction = this.gotoPage(
                          //   "/custom/orderResult/" + paymentData.successUrl
                          // );

                          obj.callbackFunction = this.getResult;

                          window.PaypleCpayAuthCheck(obj);
                        } else {
                          this.setMsgPopup(
                            true,
                            "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                          );
                        }
                      });
                  }
                });
            }
          } else {
            alert("필수 동의를 선택해주세요.");
            return;
          }
        } else {
          alert("결제 방법을 선택해주세요.");
        }
      }
    },
    getResult(res) {
      console.log(res);
      console.log(this.paymentData);
      if (res.PCD_PAY_RST === "success") {
        const payResult = res;

        // 전달받은 결제 파라미터값을 state에 저장 후  '/react/order_result'로 이동
        this.gotoPage("/custom/orderResult/" + this.paymentData.orderId);
        // history.push({
        //   pathname: "/custom/orderResult",
        //   state: { payResult: payResult },
        // });
      } else {
        // 결제 실패일 경우 알림 메시지
        window.alert(res.PCD_PAY_MSG);
      }
    },
    paymentDirect() {
      let datas = {
        customNo: this.customNo,
        paymentType: this.paymentType,
        orderJson: JSON.stringify(this.order),
        sampleJson: JSON.stringify(this.sample),
        unitPrice: this.unitPrice,
        unitCount: this.totalCount,
        samplePrice: this.samplePrice,
        sampleCount: this.sampleCount,
        deliveryPrice: this.deliveryPrice,
        boxCount: this.boxCount,
        salePrice: this.salePrice,
        totalPrice: this.totalPrice,
        requestDesc: this.requestDesc,
      };
      this.$API.custom.customOrderProc(this.userInfo, datas).then((res) => {
        console.log(res);
        let orderDatas = res.data.body;
        this.isPopup = false;
        this.$API.payment
          .createTossCustomDirect(
            orderDatas.orderNo,
            this.apiData.styleName,
            this.totalPrice,
            this.paymentType,
            this.userInfo
          )
          .then((res) => {
            if (res.data.resultCode == "10000") {
              let d = res.data.body;
              this.gotoPage(
                "/custom/order/complete/success/" + orderDatas.orderNo
              );
            } else {
              this.setMsgPopup(
                true,
                "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
              );
            }
          });
      });
    },
    getAddress() {
      new window.daum.Postcode({
        oncomplete: (res) => {
          this.userInfo.compAddress = res.roadAddress;
          // this.areaCd = res.sigunguCode;
          // this.area = res.sigungu;
        },
      }).open();
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  justify-content: center;
  gap: 40px;
  @media (max-width: 900px) {
    width: 100%;
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: var(
      --Angular_5,
      conic-gradient(
        from 180deg at 49.66% 51.85%,
        #f9f9f9 0deg,
        #fffbf2 72.0000010728836deg,
        #f9f9f9 144.0000021457672deg,
        #fffbf2 216.00000858306885deg,
        #f9f9f9 288.0000042915344deg
      )
    );
  }
  .title {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;

    &-name {
      display: flex;
      padding: 2px 0px;
      align-items: center;
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        border-bottom: 1px solid $primary-50;
      }
    }
    &-desc {
      color: $neutral-60;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  &-bill {
    position: relative;
    width: 360px;
    &-info {
      position: fixed;
      top: 175px;
      width: 360px;
      height: 238px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px;
      border-radius: 8px;
      background: $neutral-0;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
      .row {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-name {
          font-size: 14px;
          font-weight: 500;
        }
        &-value {
          display: flex;
          align-items: center;
          gap: 8px;
          left: 140px;
          font-size: 14px;
          font-weight: 400;
          .cancel {
            position: absolute;
            top: -18px;
            right: 0;
            color: $neutral-40;
            text-decoration: line-through;
          }
          .price {
            display: flex;
            gap: 20px;
          }
          span {
            color: $primary-60;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
  &-confirm {
    width: 840px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 900px) {
      width: 100%;
    }
    &-title {
      padding: 40px 0 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 85px;
      font-size: 18px;
      font-weight: 500;
    }
    &-tabtitle {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
    }
    &-tab {
      display: flex;
      gap: 40px;
      border-bottom: 1px solid $primary-50;
      .tab-wrap {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 430px;
        height: 40px;
        border-radius: 4px 4px 0px 0px;
        background: $neutral-0;
        color: $neutral-40;
        font-size: 14px;
        font-weight: 500;
        &.active {
          background: $primary-50;
          color: $neutral-0;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    &-section {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      border-radius: 8px;
      background: $neutral-0;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
      @media (max-width: 580px) {
        margin: 0 20px;
      }
      .size-table {
        width: 100%;
        overflow: scroll;
      }
      .row {
        position: relative;
        display: flex;
        align-items: center;
        &-name {
          font-size: 14px;
          font-weight: 500;
        }
        &-value {
          display: flex;
          align-items: center;
          gap: 20px;
          position: absolute;
          left: 140px;
          font-size: 14px;
          font-weight: 400;
          .price {
            display: flex;
            gap: 20px;
            .sale {
              display: flex;
              padding: 8px 12px;
              height: 25px;
              justify-content: center;
              align-items: center;
              color: $neutral-0;
              font-size: 12px;
              font-weight: 500;
              border-radius: 33px;
              background: var(--Primary_50, #ffb800);
            }
          }
        }
        &-size {
          position: relative;
          display: flex;
          &-name {
            position: absolute;
            font-size: 14px;
            font-weight: 500;
          }
          &-value {
            position: relative;
            margin-left: 140px;
          }
        }
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 12px;
          background-color: $primary-50;
          color: $neutral-0;

          span {
            font-size: 16px;
          }
        }
        .input-info {
          display: flex;
          gap: 20px;
          position: absolute;
          left: 140px;
          color: $neutral-20;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .unit-price {
        display: flex;
        gap: 12px;
        .column {
          flex: 1;
          position: relative;
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          // border: 1px solid $primary-50;
          background: $neutral-0;
          font-size: 13px;
          font-weight: 500;
          &.gray {
            border: 0px solid $neutral-10;
          }
          &-name {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            color: $neutral-0;
            border-radius: 4px 4px 0px 0px;
            border-bottom: 1px solid $primary-60;
            background: $primary-50;
            font-size: 13px;
            font-weight: 500;
            &.gray {
              border-radius: 4px 4px 0px 0px;
              border-bottom: 1px solid $neutral-60;
              background: $neutral-50;
              border: 1px solid $neutral-10;
            }
          }
          &-value {
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 0px 4px 4px;
            border-right: 1px solid $neutral-10;
            border-bottom: 1px solid $neutral-10;
            border-left: 1px solid $neutral-10;
            &.inquire {
              border-radius: 0px 0px 4px 4px;
              border-right: 1px solid $primary-20;
              border-bottom: 1px solid $primary-20;
              border-left: 1px solid $primary-20;
            }
            &.btn {
              cursor: pointer;
              color: $primary-60;
            }
            p {
              text-align: center;
              color: $neutral-60;
            }
          }
        }
      }

      textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid $neutral-20;
        border-radius: 4px;
        width: 792px;
        height: 216px;
        resize: none;
        font-size: 14px;
        @media (max-width: 900px) {
          width: 100%;
        }
        &::placeholder {
          color: $neutral-30;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        &-top {
          display: flex;
          flex-direction: column;
          gap: 12px;
          &-wrap {
            display: flex;
            gap: 8px;
          }
        }
        &-bottom {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-left: 22px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
      .info-agree {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-check {
          border-radius: 4px;
          .check-box {
            height: 20px;
          }
          .check-all {
            height: 40px;
            border-radius: 4px 4px 0px 0px;
            border-bottom: 1px solid $neutral-10;
            font-weight: 500;
          }
          .check-list {
            padding: 20px 0 0;
            display: flex;
            flex-direction: column;
            gap: 12px;
            border-radius: 0px 0px 4px 4px;
            &-wrap {
              display: flex;
              gap: 8px;
              align-items: flex-end;
              span {
                color: $primary-60;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
        &-select {
          .selector {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 600px;
            padding: 10px 12px;
            border-radius: 4px;
            border: 1px solid $neutral-10;
            background: $neutral-3;
            font-size: 14px;
            font-weight: 500;
          }
          .desc {
            position: absolute;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 600px;
            height: 208px;
            border-radius: 0px 0px 4px 4px;
            border-right: 1px solid $neutral-10;
            border-bottom: 1px solid $neutral-10;
            border-left: 1px solid $neutral-10;
            background: $neutral-0;
            &-info {
              display: flex;
              gap: 8px;
              color: $neutral-60;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
      .paymethod {
        display: flex;
        gap: 20px;
        width: 100%;
        .select-btn-box {
          flex: 1;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 40px;
          font-weight: 400;
          font-size: 15px;
          line-height: 140%;
          color: $neutral-80;
          border: 1px solid $neutral-20;
          border-radius: 4px;
          span {
            font-weight: 400;
            color: $neutral-60;
          }
          &:hover {
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
          }
          &.active {
            color: $primary-50;
            border: 1px solid $primary-50;
            span {
              color: $primary-50;
            }
          }
        }
      }
      .delivery-info {
        display: flex;
        flex-direction: column;
        .colomn {
          display: flex;
          align-items: center;
          position: relative;
          height: 33px;
          &-name {
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
            span {
              color: $primary-60;
            }
          }
          &-input {
            position: absolute;
            left: 116px;
            width: calc(100% - 116px);
            padding: 6px 12px;
            border-radius: 4px;
            border-bottom: 1px solid $neutral-20;
            border-left: 1px solid $neutral-20;
            input {
              width: 100%;
            }
          }
        }
        .row {
          display: flex;
          gap: 24px;
          &-wrap {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            &:last-child {
              padding-left: 24px;
              // border-left: 1px solid $primary-20;
              .row-column-input {
                left: 140px;
              }
            }
          }
          &-column {
            display: flex;
            align-items: center;
            height: 33px;
            &-name {
              font-size: 14px;
              font-weight: 500;
              line-height: 140%;
              span {
                color: $primary-60;
              }
            }
            &-input {
              position: absolute;
              left: 116px;
              width: calc(100% - 140px);
              // width: 415px;
              padding: 6px 12px;
              border-radius: 4px;
              border-bottom: 1px solid $neutral-20;
              border-left: 1px solid $neutral-20;
              &.empty {
                border-color: $secondary-60;
              }
              input {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &-btn {
      padding: 20px 0 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 360px;
        height: 40px;
        padding: 9px 0px;
        background: $neutral-40;
        color: $neutral-0;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}
.popup {
  position: relative;
  &-direct {
    &-background {
      background: rgba(0, 0, 0, 0.35);
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 500;
    }
    .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .top {
      padding: 40px 20px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .square {
        width: 8px;
        height: 8px;
        background-color: $primary-50;
      }
      .title {
        margin-left: 8px;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: $primary-80;
      }
    }
    &-wrap {
      position: fixed;
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      max-height: calc(100vh - 170px);
      overflow-y: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      background-color: $neutral-0;
      background: $neutral-0;
      z-index: 530;
      border-radius: 8px;
      .info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;
        padding: 0 20px;
        .load {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: $neutral-30;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
        &-tab {
          margin-top: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 180px;
            height: 36px;
            border-style: solid;
            border-color: $neutral-20;
            &:first-child {
              border-radius: 4px 0 0 4px;
              border-width: 1px 0 1px 1px;
            }
            &:last-child {
              border-radius: 0 4px 4px 0;
              border-width: 1px 1px 1px 0;
            }
            &.active {
              border-radius: 4px;
              color: $neutral-0;
              border-width: 20px;
              border-style: solid;
              background-color: $primary-50;
              border-color: $primary-50;
            }
          }
        }
        &-wrap {
          .add-info {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $primary-20;
            font-size: 15px;
            font-weight: 500;
            line-height: 140%;
          }
          .input {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            input {
              width: 100%;
            }
            &-item {
              font-size: 15px;
              font-weight: 500;
              line-height: 140%;
              span {
                color: $primary-60;
              }
            }
            &-reset {
              padding: 1px 12px;
              color: $neutral-60;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              border-radius: 4px;
              border: 1px solid $neutral-20;
            }
            &-bank {
              padding: 6px 12px;
              border-radius: 4px;
              border: 1px solid $neutral-20;
              font-size: 15px;
              font-weight: 400;
              line-height: 140%;
            }
            &-wrap {
              display: flex;
              align-items: center;
              padding: 4px 8px;
              background: $neutral-0;
              border-width: 0px 0px 1px 1px;
              border-style: solid;
              border-color: $neutral-20;
              border-radius: 4px;
              height: 32px;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              &.disabled {
                background-color: $neutral-3;
              }
              input {
                width: 100%;
                height: 100%;
                background: $neutral-0;
                font-size: 15px;
                font-weight: 400;
                line-height: 23px;
                &:-webkit-autofill {
                  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
                }
                &:hover {
                  background: #fffbf2;
                }
                &:disabled {
                  background-color: $neutral-3;
                }
              }
            }
          }
        }
      }
      .back-gary {
        background: $neutral-3;
      }
      .calc-bottom {
        position: sticky;
        bottom: 0;
        .terms {
          margin: 0 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 40px;
          padding: 20px 0 40px;
        }
        &-btns {
          display: flex;
          align-items: center;
          background-color: $neutral-50;
          border-radius: 0px 0px 8px 8px;
          color: $neutral-0;
          width: 100%;
          &-btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 40px;
            cursor: pointer;
            &:not(:first-child) {
              margin-left: rem(8px);
            }
            &.active {
              background-color: $primary-50;
              border-radius: 0px 0px 8px 8px;
              &:not(:first-child) {
                border-radius: 0px 0px 8px 0px;
              }
            }
            &.deactive {
              background: $neutral-30;
            }
          }
        }
      }
    }
  }
  &-sale {
    &-background {
      background: rgba(0, 0, 0, 0.35);
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 500;
    }
    .top {
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
    }
    &-wrap {
      position: fixed;
      display: flex;
      flex-direction: column;
      max-width: 900px;
      width: 100%;
      max-height: 705px;
      overflow-y: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      background-color: $neutral-0;
      background: $neutral-0;
      z-index: 530;
      border-radius: 8px;
      .info {
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding: 0 40px 60px;
        height: 100%;
        &-tab {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          width: 100%;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          &-btn {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $neutral-40;
            padding-bottom: 12px;
            border-bottom: 1px solid $neutral-20;
            &.active {
              color: $primary-60;
              border-bottom: 1px solid $primary-50;
            }
          }
        }
        &-wrap {
          display: flex;
          flex-wrap: wrap;
          gap: 69px 8px;
          margin-left: 8px;
          &-row {
            position: relative;
            height: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:last-child {
              .row-top {
                width: 264px;
              }
            }
            .row {
              &-top {
                position: absolute;
                display: flex;
                width: 116px;
                height: 26px;
                padding: 8px 0px;
                justify-content: center;
                align-items: center;
                border-radius: 34px;
                border: 1px solid $primary-20;
                background-color: $neutral-0;
                font-size: 13px;
                font-weight: 400;
              }
              &-body {
                margin-top: 13px;
                display: flex;
                width: 148px;
                height: 47px;
                padding: 25px 0px 12px 0px;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                background: $primary-5;
                color: $primary-60;
                font-size: 13px;
                font-weight: 400;
                &.active {
                  width: 304px;
                  font-weight: 400;
                }
              }
              &-bottom {
                span {
                  width: 8px;
                  height: 8px;
                  background-color: $primary-50;
                  border-radius: 4px;
                }
                &-arrow {
                  position: absolute;
                  left: 0;
                  z-index: -1;
                  img {
                    width: 820px;
                  }
                }
                &-text {
                  position: absolute;
                  bottom: -8px;
                  left: -8px;
                  width: 164px;
                  font-size: 12px;
                  font-weight: 500;
                }
                &-state {
                  position: absolute;
                  bottom: 5px;
                  left: -8px;
                  width: 164px;
                }
                &-left {
                  position: absolute;
                  left: 0;
                  &.two {
                    left: -3px;
                  }
                  &.three {
                    left: -6px;
                  }
                  &.four {
                    left: -9px;
                  }
                  &.five {
                    left: -12px;
                  }
                }
                &-right {
                  position: absolute;
                  right: 0;
                  &.two {
                    right: -3px;
                  }
                  &.three {
                    right: -6px;
                  }
                  &.four {
                    right: -9px;
                  }
                }
              }
            }
          }
        }
      }
      .sale-bottom {
        position: sticky;
        bottom: 0;
        .terms {
          margin: 0 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 40px;
          padding: 20px 0 40px;
        }
        &-btns {
          display: flex;
          align-items: center;
          background-color: $neutral-50;
          border-radius: 0px 0px 8px 8px;
          color: $neutral-0;
          width: 100%;
          &-btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 40px;
            cursor: pointer;
            &:not(:first-child) {
              margin-left: rem(8px);
            }
            &.active {
              background-color: $primary-50;
              border-radius: 0px 0px 8px 8px;
              &:not(:first-child) {
                border-radius: 0px 0px 8px 0px;
              }
            }
            &.deactive {
              background: $neutral-30;
            }
          }
        }
      }
    }
  }
}
</style>
