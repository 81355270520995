<template>
  <div class="premium">
    <div class="card-list" :class="isMobile == 'true' ? 'mb' : ''">
      <div
        class="card pointer"
        v-for="(x, i) in premiumFactoryList[premiumPageIndex - 1]"
        :key="i"
        @click="goFactory(x.factoryNo)"
        :class="isMobile == 'true' ? 'mb' : ''"
      >
        <div class="card-top">
          <div class="card-top-top">
            <div class="img-area" :class="userId ? 'login' : ''">
              <img v-if="x.imgFileUrl1" :src="x.imgFileUrl1" />
              <img
                v-else
                :src="require('@/assets/img/img-factory-empty.png')"
              />
            </div>
            <div class="info">
              <div class="row space-between">
                <p class="work-process">{{ x.workProcess }}</p>
                <div class="row-info-right" v-if="compType == '1'">
                  <SvgImg
                    :size="20"
                    :d="$store.state.meta.svg.share"
                    :color="
                      isShareHover
                        ? $store.state.meta.svg.color.primary50
                        : $store.state.meta.svg.color.neutral40
                    "
                    class="pointer"
                    @mouseenter="isShareHover = true"
                    @mouseout="isShareHover = false"
                    @click.stop="clickShare(x)"
                  />

                  <SvgImg
                    :size="20"
                    :d="`${
                      x.isLike
                        ? $store.state.meta.svg.favorite
                        : $store.state.meta.svg.favoriteFill
                    }`"
                    :color="`${
                      x.isLike
                        ? $store.state.meta.svg.color.primary50
                        : isLikeHover
                        ? $store.state.meta.svg.color.primary50
                        : $store.state.meta.svg.color.neutral40
                    }`"
                    class="pointer"
                    @mouseenter="isLikeHover = true"
                    @mouseout="isLikeHover = false"
                    @click.stop="clickLike(x)"
                  />

                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    @click.stop="clickShare(x)"
                  >
                    <path
                      :d="$store.state.meta.svg.share"
                      :fill="`${$store.state.meta.svg.color.neutral40}`"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    @click.stop="clickLike(x)"
                  >
                    <path
                      :d="`${
                        x.isLike
                          ? $store.state.meta.svg.favorite
                          : $store.state.meta.svg.favoriteFill
                      }`"
                      :fill="`${x.isLike ? $store.state.meta.svg.color.primary50 : $store.state.meta.svg.color.neutral40}`"
                    />
                  </svg> -->
                </div>
              </div>
              <div class="row gap8 mt-4">
                <div class="info-title">
                  {{ x.factoryName }}
                  <!-- <img
                    v-if="x.calculateFlag == '2'"
                    :src="require(`@/assets/icon/ic-credit-card-orange.svg`)"
                  /> -->
                </div>
                <div class="tooltip" v-if="x.calculateFlag == '2'">
                  <span class="tooltip-text">카드 결제 가능</span>
                  <img
                    :src="require(`@/assets/icon/ic-credit-card-orange.svg`)"
                  />
                </div>
              </div>
              <div class="content">
                <div class="content-info gap2">
                  <p class="area">
                    {{ x.address ? x.address : x.area }}
                  </p>
                  <p
                    class="flex gap2"
                    v-if="x.workProcessCd == '01031' && x.minOrderCount > 0"
                  >
                    <span>│</span>최소 {{ comma(x.minOrderCount) }}벌 이상
                  </p>
                </div>
                <div
                  class="content-info gray"
                  v-if="
                    x.workProcessCd == '01031' || x.workProcessCd == '01033'
                  "
                >
                  <div class="fabric">
                    <p
                      v-for="(x, i) in x.newFabricList ? x.newFabricList : ''"
                      :key="i"
                    >
                      {{ x }}
                    </p>
                    <p
                      v-for="(x, i) in !x.newFabricList ? x.fabricList : ''"
                      :key="i"
                    >
                      {{ x.commName }}
                    </p>
                  </div>
                  <span
                    v-if="
                      (x.workProcessCd == '01031' &&
                        (x.processFlag == '1' || x.buyFlag == '1')) ||
                      (x.workProcessCd == '01033' &&
                        (x.sampleType == '1' ||
                          x.sampleType == '2' ||
                          x.sampleType == '3'))
                    "
                    >│</span
                  >
                  <div
                    class="row-content flex-start gap8 max86"
                    v-if="x.workProcessCd == '01031'"
                  >
                    <p v-if="x.processFlag == '1'">임가공</p>
                    <p v-if="x.buyFlag == '1'">완사입</p>
                  </div>
                  <div
                    class="row-content flex-start gap8 max86"
                    v-if="x.workProcessCd == '01033'"
                  >
                    <p v-if="x.sampleType == '1' || x.sampleType == '3'">
                      패턴
                    </p>
                    <p v-if="x.sampleType == '2' || x.sampleType == '3'">
                      샘플
                    </p>
                  </div>
                </div>
                <div
                  class="content-info work-item gray"
                  v-else-if="x.workProcessCd == '01034'"
                >
                  <p
                    class="work-item-list"
                    v-if="x.jasu?.length > 0"
                    v-html="x.jasu"
                  ></p>
                </div>
                <div
                  class="content-info work-item gray"
                  v-else-if="x.workProcessCd == '01035'"
                >
                  <p
                    class="work-item-list"
                    v-if="x.print?.length > 0"
                    v-html="x.print"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <p v-html="x.compDesc"></p>
        </div>
        <div class="card-bottom">
          <div class="category" v-if="x.tagList?.length > 0">
            <p v-for="(y, i) in x.tagList" :key="i">
              {{ y.commName.replace("#", "") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="banner">
    <!-- <img src="@/assets/img/banner-long-img.png" /> -->
    <swiper
      id="bannerSwiper"
      :slidesPerView="1"
      :loop="true"
      :rewind="true"
      :autoplay="{ delay: 5500, disableOnInteraction: false }"
      :modules="modules"
      class="banner-wrap-swiper"
    >
      <!-- <swiper-slide class="search-banner-slider" v-if="isMobile == 'false'">
        <img
          v-if="isMobile == 'true'"
          @click="
            gotoBanner(
              'https://ipsi.scau.ac.kr/Default.aspx?src=image&kw=001C9C',
              '서울문화예술대학'
            )
          "
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-mobile-school.png"
        />
        <img
          v-else
          @click="
            gotoBanner(
              'https://ipsi.scau.ac.kr/Default.aspx?src=image&kw=001C9C',
              '서울문화예술대학'
            )
          "
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-pc-school.png"
        />
      </swiper-slide> -->
      <swiper-slide class="search-banner-slider" v-if="isMobile == 'false'">
        <img
          v-if="isMobile == 'true'"
          @click="gotoPage('/mall/materiallist')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-mobile-mall.png"
        />
        <img
          v-else
          @click="gotoPage('/mall/materiallist')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-pc-mall.png"
        />
      </swiper-slide>
      <swiper-slide class="search-banner-slider" v-if="isMobile == 'false'">
        <img
          v-if="isMobile == 'true'"
          @click="gotoPage('/search/factorydetail/1424')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-mobile-how2png.png"
        />
        <img
          v-else
          @click="gotoPage('/search/factorydetail/1424')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-pc-how2png.png"
        />
      </swiper-slide>
      <swiper-slide class="search-banner-slider" v-if="isMobile == 'false'">
        <img
          v-if="isMobile == 'true'"
          @click="gotoPage('/search/factorydetail/5943')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-mobile-daeyung.png"
        />
        <img
          v-else
          @click="gotoPage('/search/factorydetail/5943')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-pc-daeyung.png"
        />
      </swiper-slide>
      <swiper-slide class="search-banner-slider" v-if="isMobile == 'false'">
        <img
          v-if="isMobile == 'true'"
          @click="gotoBanner('https://uttu.me/uttucad/trial', 'uttu')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-mobile-uttu.png"
        />
        <img
          v-else
          @click="gotoBanner('https://uttu.me/uttucad/trial', 'uttu')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-pc-uttu.png"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

export default {
  name: "cardView",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Autoplay],
    };
  },
  props: {
    premiumFactoryList: {
      type: Object,
      description: "Card data",
    },
    premiumPageIndex: {
      type: Object,
      description: "Card data",
    },
  },
  data() {
    return {
      d: {},
      isLikeHover: false,
      isShareHover: false,
      get isMobile() {
        return localStorage.getItem("isMobile");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get userId() {
        return sessionStorage.getItem("userId");
      },
    };
  },
  mounted() {
    console.log(this.premiumFactoryList.length);
    console.log(this.premiumPageIndex);
    this.d = this.premiumFactoryList;
    this.d.isLike = this.d.like;
    // if (this.premiumFactoryList?.length > 0) {
    // }

    console.log(this.jasu);
    console.log(this.print);
  },
  methods: {
    clickLike(data) {
      this.$emit("clickLike", data);
    },
    clickShare(data) {
      this.$emit("clickShare", data);
    },
    goFactory(factoryNo) {
      this.gotoPage(`/search/factorydetail/${factoryNo}`);
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  &:hover {
    path {
      fill: $primary-50;
    }
  }
}
.premium {
  margin: 20px;
  // padding-bottom: 40px;
  // padding-top: 154px;
  // border-bottom: 1px solid $neutral-10;
  @include desktop {
    margin: 0;
  }
  &-top {
    padding: 0 16px;
    @include tablet {
      padding: 0 24px;
    }
  }
  &-title {
    justify-content: flex-start;
    margin-bottom: rem(3px);
    img {
      width: rem(24px);
      height: rem(24px);
    }
    p {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      color: $primary-50;
      margin-left: rem(8px);
    }
  }
}
.banner {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 520px;
  display: flex;
  flex-direction: column;
  margin: 40px 0 20px;
  @include desktop {
    margin-top: 40px;
    max-width: 100%;
  }
  &-wrap {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    &-swiper {
      width: 100%;
      position: initial;
      .swiper-slide-duplicate-next {
        background-color: gray !important;
      }
      img {
        width: 100%;
      }
    }

    :deep(.swiper) {
      .swiper-button-next {
        right: 0;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        background-color: $neutral-0;
        &::after {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
          width: 20px;
          height: 20px;
          color: $neutral-30;
        }
      }
      .swiper-button-prev {
        left: 0;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        background-color: $neutral-0;
        &::after {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
          width: 20px;
          height: 20px;
          color: $neutral-30;
        }
      }
    }
  }
}
.card-list {
  display: grid;
  gap: 16px 20px;
  justify-content: flex-start;
  grid-template-columns: 1fr 1fr;

  @include mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 0;
    grid-template-columns: 1fr;
  }
  &.mb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    grid-template-columns: 1fr;
  }
}
.card {
  padding: 16px;
  border-radius: 8px;
  max-width: 470px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $neutral-0;
  border: 1px solid $neutral-0;
  &:hover {
    border: 1px solid $primary-20;
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.04));
  }
  &.mb {
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.04));
  }
  .tooltip {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    &-text {
      width: 100px;
      visibility: hidden;
      background-color: $primary-50;
      color: $neutral-0;
      text-align: center;
      border-radius: 4px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      padding: 4px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 125%;
      margin-left: -62px;
      opacity: 0;
      transition: opacity 0.3s;
      font-weight: 350;
      font-size: 12px;
      line-height: 20px;

      &.on {
        color: $neutral-60;
      }
      &.left {
        margin-left: -88px;
        &::after {
          left: 72%;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $primary-50 transparent transparent transparent;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      }
    }
    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }
  &-top {
    &.normal {
      padding-left: 12px;
      padding-right: 12px;
      height: 100px;
    }

    &-top {
      display: flex;
      gap: 16px;
      .img-area {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        max-width: 100px;
        height: 100px;
        border: 1px solid $neutral-10;
        border-radius: 8px;
        overflow: hidden;
        @media (max-width: 520px) {
          min-width: 48px;
          max-width: 48px;
          height: 48px;
        }
        img {
          // max-width: 100%;
          max-height: 100%;
        }
      }
      .info {
        max-width: 100%;
        min-width: 180px;
        width: 100%;
        .like-flag {
          width: 16px;
          height: 16px;
        }
        &-title {
          @include ellipse();
          display: flex;
          gap: 8px;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
        }
        .work-process {
          color: $neutral-80;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }
        .row {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-80;
          &-info {
            display: flex;
            gap: 8px;
            white-space: normal;
            -webkit-line-clamp: 1;
            overflow: hidden;
            word-break: break-all;
            &:not(:first-child) {
              margin-left: 12px;
              padding-left: 12px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 13px;
                background-color: $neutral-30;
              }
            }
            &-right {
              display: flex;
              gap: 8px;
              .icon {
                display: flex;
                gap: 4px;
                img {
                  width: 16px;
                }
                p {
                  color: $primary-60;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 18px;
                }
              }
            }
          }
        }
        .content {
          margin-top: 4px;
          display: flex;
          flex-direction: column;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-80;
          &-info {
            display: flex;
            gap: 8px;
            overflow: hidden;

            .area {
              position: relative;
              &.bf::before {
                content: "";
                position: absolute;
                top: 50%;
                right: -8px;
                transform: translateY(-50%);
                width: 1px;
                height: 12px;
                background-color: $primary-60;
              }
            }
            span {
              color: $primary-60;
              font-size: 14px;
              line-height: 140%; /* 19.6px */
            }
            .fabric {
              display: flex;
              gap: 8px;
              margin-top: 2px;
              color: $neutral-60;
              max-width: 220px;
              display: -webkit-box;
              overflow: hidden;
              // :nth-child(n + 3) {
              //   display: none;
              // }
            }
            .work-item {
              margin-top: 2px;
              color: $neutral-60;
              &-list {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .max86 {
              max-width: 86px;
            }
          }
        }
      }
      :deep(.rating) {
        img {
          width: 14px;
          height: 14px;
        }
      }
      .rating-val {
        font-weight: 700;
        font-size: 18px;
        color: $neutral-80;
        margin-left: rem(12px);
      }
    }
    .line {
      height: 1px;
      background-color: $neutral-5;
      margin-top: rem(20px);
      margin-bottom: rem(15px);
    }
    .category {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      font-size: 14px;
      color: $neutral-80;
    }
  }
  &-body {
    max-height: 100px;
    padding: 12px 0 8px;
    color: $neutral-60;
    font-size: 13px;
    font-weight: 400;
    line-height: 150%; /* 19.5px */
    p {
      max-height: 80px;
      @include ellipse(4);
    }
  }
  &-bottom {
    margin-top: 8px;
    flex: 1;
    .represent {
      font-size: 14px;
      color: $neutral-80;
      margin-bottom: rem(12px);
    }
    .category {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 12px;
      color: $neutral-50;
      gap: 4px;
      max-height: 107px;
      overflow: hidden;
      p {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 26px;
        background: $primary-10;
        color: $primary-60;
      }
    }
  }
}
</style>
