<template>
  <div class="basket">
    <div class="basket-top">
      <div class="top">
        <p class="square"></p>
        <p class="title">주문 정보</p>
      </div>
      <!-- <div class="check-all"><input type="checkbox" />전체 선택 ( 0/ 0 )</div> -->
      <div class="check-content" v-for="(x, i) in basketItem" :key="i">
        <div class="check-content-left">
          <div class="item-name">
            <img />
            <p>{{ x.itemName }}</p>
            <img
              class="pointer"
              src="@/assets/icon/ic-x-space.svg"
              @click="removeItem(i)"
            />
          </div>
          <p class="color-name">{{ x.itemOptionName }}</p>
        </div>
        <div class="check-content-right">
          <div class="updown">
            <div class="updown-btn gray" @click="downItem(i)">
              <img src="@/assets/icon/ic-minus-white.svg" />
            </div>
            <div class="updown-num">
              <input type="number" v-model="x.count" />
            </div>
            <div class="updown-btn gray" @click="upItem(i)">
              <img src="@/assets/icon/ic-plus-white.svg" />
            </div>
          </div>
          <p class="price">
            {{
              comma(
                x.count * (detailData.itemUnitCost * 1 + x.addUnitCost * 1)
              )
            }}원
          </p>
        </div>
      </div>
      <div class="item-info" v-if="sumPrice > 0">
        <div class="row">
          <p class="row-name">상품 금액</p>
          <p class="row-content">{{ comma(sumPrice) }}원</p>
        </div>
        <div class="row">
          <p class="row-name">배송 금액</p>
          <p class="row-content">0원</p>
        </div>
        <div class="row">
          <p class="row-name">총 주문 금액</p>
          <p class="row-content">{{ comma(totalPrice) }}원</p>
        </div>
      </div>
      <div class="no-item" v-if="sumPrice == 0">
        <p>주문 상품이 없습니다.</p>
      </div>
    </div>
    <div class="basket-body">
      <div class="top">
        <p class="square"></p>
        <p class="title">배송 정보</p>
      </div>
      <div class="delivery-info">
        <div class="colomn">
          <p class="colomn-name">수령인 <span>*</span></p>
          <p class="colomn-input">
            <input type="text" v-model="userInfo.userName" />
          </p>
        </div>
        <div class="colomn">
          <p class="colomn-name">연락처 <span>*</span></p>
          <p class="colomn-input">
            <input type="text" v-model="userInfo.telNum" />
          </p>
        </div>
        <div class="colomn">
          <p class="colomn-name">배송지 <span>*</span></p>
          <p class="colomn-input">
            <input
              type="text"
              v-model="userInfo.compAddress"
              readonly
              @click="getAddress('address')"
            />
          </p>
          <p class="colomn-input">
            <input type="text" v-model="userInfo.compAddressDetail" />
          </p>
        </div>
        <div class="colomn">
          <p class="colomn-name">배송 메모</p>
          <p class="colomn-input">
            <input type="text" v-model="userInfo.deliveryMemo" />
          </p>
        </div>
      </div>
    </div>
    <div class="basket-bottom">
      <div class="top">
        <p class="square"></p>
        <p class="title">주문 정보</p>
      </div>
      <div class="paymethod">
        <!-- <div
          @click="paymentType = 'CARD'"
          class="select-btn-box"
          :class="paymentType == 'CARD' ? 'active' : ''"
        >
          신용카드
        </div> -->
        <div
          @click="paymentType = 'TRANSFER'"
          class="select-btn-box"
          :class="paymentType == 'TRANSFER' ? 'active' : ''"
        >
          계좌 이체
        </div>
        <!-- <div
          @click="paymentType = 'TOSSPAY'"
          class="select-btn-box"
          :class="paymentType == 'TOSSPAY' ? 'active' : ''"
        >
          토스 페이
        </div> -->
      </div>
      <div class="basket-bottom-btn" @click="payment()">결제하기</div>
    </div>
  </div>
</template>
<script>
import { loadTossPayments } from "@tosspayments/payment-sdk";
export default {
  name: "CompDesignmaterialOrder",
  props: {
    basketItem: {
      type: Object,
    },
  },
  data() {
    return {
      d: {},
      i: [],
      mallNo: "",
      regImgUrl: "",
      regImgName: "",
      detailData: {},
      addItem: [],
      sumPrice: 0,
      sumCount: 0,
      deliveryPrice: 0,
      totalPrice: 0,
      paymentType: "",
      userInfo: {},
      itemDetailInfo: [],
      color: [
        {
          index: 0,
          cd: "",
          name: "[필수] 1절 이상 주문 가능합니다.",
          isActive: true,
        },
      ],
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
    };
  },
  which: {
    basketItem(newVal) {
      console.log(newVal);
    },
    addItem(newVal) {
      console.log(newVal);
    },
  },
  mounted() {
    this.mallNo = this.$route.params.mallNo;

    this.$API.mall.getMaterialDetail(this.mallNo).then((res) => {
      console.log(res);
      this.detailData = res.data.body;
    });

    if (this.compType == "1") {
      this.$API.mypage.getDesignerInfo(this.userId).then((res) => {
        console.log(res.data.body);
        this.userInfo = res.data.body;
      });
    }
    if (this.compType == "2") {
      this.$API.mypage.getFactoryInfo(this.userId).then((res) => {
        this.userInfo = res.data.body;
      });
    }
  },
  methods: {
    getAddress() {
      new window.daum.Postcode({
        oncomplete: (res) => {
          this.userInfo.compAddress = res.roadAddress;
        },
      }).open();
    },
    upItem(index) {
      console.log(this.basketItem);
      this.addItem = this.basketItem;
      this.addItem[index].count = this.addItem[index].count + 1;
      this.sum();
      this.$emit("updateBasket", this.addItem);
    },
    downItem(index) {
      console.log(this.basketItem);
      this.addItem = this.basketItem;
      this.addItem[index].count = this.addItem[index].count - 1;
      if (this.addItem[index].count == 0) {
        this.removeItem(index);
      }
      this.sum();
      this.$emit("updateBasket", this.addItem);
    },
    removeItem(index) {
      this.addItem = this.basketItem;
      this.addItem.splice(index, 1);
      this.sum();
      this.$emit("updateBasket", this.addItem);
    },
    sum() {
      let sumPrice = 0;
      this.addItem.forEach((e) => {
        sumPrice +=
          e.count *
          (Number(this.detailData.itemUnitCost) + Number(e.addUnitCost));
      });
      this.sumPrice = sumPrice;
      this.totalPrice = sumPrice + this.deliveryPrice;
    },
    payment() {
      if (this.totalPrice > 0) {
        let itemDetail = "";
        let itemDetailInfo = [];
        this.addItem.forEach((e, i) => {
          if (i > 0) itemDetail += " / ";
          itemDetail += e.itemOptionName + ":" + e.count + "개";

          let itemInfo = {
            itemOptionName: e.itemOptionName,
            itemColorCode: e.cd,
            quantity: e.count,
            addUnitCost: e.addUnitCost,
            paymentAmount:
              Number(this.detailData.itemUnitCost) + Number(e.addUnitCost),
          };
          itemDetailInfo.push(itemInfo);
        });
        this.itemDetailInfo = itemDetailInfo;
        this.$API.payment
          .createTossMall(
            this.userId,
            this.mallNo,
            this.detailData.itemName,
            this.totalPrice,
            this.paymentType,
            itemDetail,
            this.userInfo.userName,
            this.userInfo.telNum,
            this.userInfo.compAddress,
            this.userInfo.compAddressDetail,
            this.userInfo.deliveryMemo,
            this.itemDetailInfo
          )
          .then((res) => {
            console.log(res);
            if (res.data.resultCode == "10000") {
              let d = res.data.body;
              console.log(d);
              // loadTossPayments(d.clientKey).then((tossPayments) => {
              //   tossPayments.requestPayment(this.paymentType, {
              //     // 결제 수단 파라미터
              //     amount: d.paymentAmount,
              //     orderId: d.orderId,
              //     orderName: d.itemName,
              //     customerName: sessionStorage.getItem("userName"),
              //     successUrl: d.successUrl,
              //     failUrl: d.failUrl,
              //   });
              // });
            } else {
              this.setMsgPopup(
                true,
                "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
              );
            }
          });
      } else {
        this.showToast("구매하실 상품을 선택해주세요.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .material {
//   border-right: 1px solid $neutral-10;
//   border-left: 1px solid $neutral-10;
//   padding: 40px 24px 24px;
//   @media (max-width: 520px) {
//     padding: 40px 20px;
// }
.basket {
  padding: 40px 20px;
  width: 100%;
  border-right: 1px solid $neutral-10;
  border-left: 1px solid $neutral-10;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .square {
      width: 8px;
      height: 8px;
      background-color: $primary-50;
    }
    .title {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: $primary-80;
    }
  }
  &-top {
    .check-all {
      padding-bottom: 20px;
      display: flex;
      gap: 8px;
      border-bottom: 1px solid $neutral-10;
      color: $neutral-60;
      font-size: 15px;
      font-weight: 400;
      line-height: 140%;
    }
    .check-content {
      padding: 20px 0;
      border-bottom: 1px solid $neutral-10;
      display: flex;
      &-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 4px;
        .item-name {
          display: flex;
          gap: 8px;
          color: $primary-60;
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;
        }
        .color-name {
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
        }
      }
      &-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 12px;
        .price {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }
    .item-info {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .row {
        display: flex;
        &-name {
          flex: 1;
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
        }
        &-content {
          flex: 2;
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
        }
      }
    }
    .no-item {
      padding: 40px 0 0;
      color: $neutral-60;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  &-body {
    .delivery-info {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 28px;
      .colomn {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &-name {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          span {
            color: $primary-60;
          }
        }
        &-input {
          padding: 6px 12px;
          border-radius: 4px;
          border-bottom: 1px solid $neutral-20;
          border-left: 1px solid $neutral-20;
        }
      }
    }
  }
  &-bottom {
    .paymethod {
      margin: 20px 0 40px;
      display: grid;
      // flex-wrap: wrap;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .select-btn-box {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 60px;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: $neutral-80;
        border: 1px solid $neutral-20;
        border-radius: 4px;
        span {
          font-weight: 400;
          color: $neutral-60;
        }
        &.active {
          color: $primary-50;
          border: 1px solid $primary-50;
          span {
            color: $primary-50;
          }
        }
      }
    }
    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $neutral-0;
      font-size: 15px;
      font-weight: 500;
      line-height: 140%;
      height: 40px;
      border-radius: 4px;
      background-color: $primary-50;
    }
  }
}
</style>
