<template>
  <div id="navigation-bar">
    <div id="navigation-bar-wrap">
      <div
        class="btn pointer"
        v-for="(nav, i) in compType == '1'
          ? designerNavigationList
          : compType == '2'
          ? factoryNavigationList
          : nomalNavigationList"
        :key="i"
        @click="navClick(nav.path, nav.btnEventType, nav.callFunc)"
      >
        <!-- navClick(
            nav.path.indexOf('mypage') >= 0 && compType != '1'
              ? '/account/login'
              : nav.path,
            nav.btnEventType,
            nav.callFunc
          ) -->
        <img
          :src="
            require('@/assets/' +
              ($route.path === nav.path ||
              (nav.activePath.some((e) => $route.path.indexOf(e) !== -1) &&
                $route.path !== nav.deactivePath)
                ? 'yellow'
                : 'grey') +
              '/' +
              nav.iconName +
              '.svg')
          "
        />
        <!-- <router-link><p>{{ nav.txt }}</p></router-link> -->
        <p
          :class="
            $route.path === nav.path ||
            (nav.activePath.some((e) => $route.path.indexOf(e) !== -1) &&
              $route.path !== nav.deactivePath)
              ? 'yellow'
              : 'grey'
          "
        >
          {{ nav.txt }}
        </p>
      </div>
      <!-- <div v-outside="closeMenu" class="btn pointer" @click="toggleMenuShow()">
        <img
          :src="
            require(`@/assets/${
              $store.state.meta.showMoreMenu ? 'black' : 'grey'
            }/li_more-vertical.svg`)
          "
        />
        <p>더보기</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "navigation-bar",
  data() {
    return {
      nomalNavigationList: [
        {
          path: "/search/factorylist",
          iconName: "ic-search",
          txt: "공장찾기",
          activePath: ["/search/factoryMap", "/search/factorydetail"],
          btnEventType: "5",
          callFunc: this.factoryFilterReset,
        },
        {
          path: "/designorder/list",
          iconName: "ic-matching",
          txt: "매칭찾기",
          activePath: ["/designorder"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        {
          path: "/custom/list",
          iconName: "ic-custom",
          txt: "커스텀몰",
          activePath: ["/custom/list"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        {
          path: "/mall/materiallist",
          iconName: "ic-mall",
          txt: "오슬상사",
          activePath: ["/mall/materiallist"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        // {
        //   path: "login",
        //   iconName: "li_log-in",
        //   txt: "로그인",
        //   activePath: ["/account"],
        //   deactivePath: "",
        //   btnEventType: "1",
        //   callFunc: "",
        // },
        // { path: '/mateestimate', iconName: 'li_mate', txt: '오슬메이트', activePath: [], btnEventType: '', callFunc: '' },
        // { path: 'c', iconName: 'li_award', txt: '프라임+' },
        // { path: 'osleintro', iconName: 'li_more-vertical', txt: '더보기' }
      ],
      designerNavigationList: [
        {
          path: "/designorder/add",
          iconName: "ic-regist",
          txt: "견적요청",
          activePath: ["/designorder/add"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        {
          path: "/search/factorylist",
          iconName: "li_search",
          txt: "공장찾기",
          activePath: ["/search/factoryMap", "/search/factorydetail"],
          btnEventType: "5",
          callFunc: this.factoryFilterReset,
        },
        // <!-- @click="gotoPage('/designorder/add')" -->
        // <!-- @click="this.$emit('matchingPopup', true)" -->
        {
          path: "/designorder/list",
          iconName: "ic-matching",
          txt: "매칭찾기",
          activePath: ["/designorder"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        {
          path: "/custom/list",
          iconName: "ic-custom",
          txt: "커스텀몰",
          activePath: ["/custom/list"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        {
          path: "/mall/materiallist",
          iconName: "ic-mall",
          txt: "오슬상사",
          activePath: ["/mall/materiallist"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        {
          path: "/mate/main",
          iconName: "ic-mate",
          txt: "메이트+",
          activePath: ["/mall/main", "/mall/regist"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        // { path: '/mateestimate', iconName: 'li_mate', txt: '오슬메이트', activePath: [], btnEventType: '', callFunc: '' },
        // { path: 'c', iconName: 'li_award', txt: '프라임+' },
        // { path: 'osleintro', iconName: 'li_more-vertical', txt: '더보기' }
      ],
      factoryNavigationList: [
        {
          path: "/search/factorylist",
          iconName: "li_search",
          txt: "공장찾기",
          activePath: ["/search/factoryMap", "/search/factorydetail"],
          btnEventType: "5",
          callFunc: this.factoryFilterReset,
        },
        {
          path: "/designorder/list",
          iconName: "ic-matching",
          txt: "매칭찾기",
          activePath: ["/designorder"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        {
          path: "/manage/list",
          iconName: "ic-won",
          txt: "정산",
          activePath: ["/manage"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        {
          path: "/mall/materiallist",
          iconName: "ic-mall",
          txt: "오슬상사",
          activePath: ["/mall/materiallist"],
          deactivePath: "",
          btnEventType: "1",
          callFunc: "",
        },
        // { path: '/mateestimate', iconName: 'li_mate', txt: '오슬메이트', activePath: [] },
        // { path: 'c', iconName: 'li_award', txt: '프라임+' },
        // { path: 'osleintro', iconName: 'li_more-vertical', txt: '더보기' }
      ],
      get compType() {
        return sessionStorage.getItem("compType");
      },
    };
  },
  methods: {
    goTop() {
      window.scrollTo(0, 0);
    },
    navClick(path, btnEventType, callFunc) {
      if (path == "login") {
        this.$emit("openLogin");
      } else if (path == "/designorder/add") {
        this.$emit("matchingPopup", true);
      } else {
        if (callFunc) callFunc();
        this.gotoPage(path);
        if (btnEventType) this.sendBtnEvent(btnEventType);
      }
    },
    // updateList (compType) {
    //   this.navigationList[3].path = compType !== '2' ? '/mateestimate' : '/matchinglist'
    //   this.navigationList[3].txt = compType !== '2' ? '오슬메이트' : '매칭리스트'
    //   this.navigationList[3].iconName = compType !== '2' ? 'li_mate' : 'li_file-search'
    //   this.navigationList[3].activePath = compType !== '2' ? ['/mate'] : []
    //   this.navigationList[1].deactivePath = compType !== '2' ? '' : '/matchinglist'
    // },
    toggleMenuShow() {
      this.$store.state.meta.showMoreMenu =
        !this.$store.state.meta.showMoreMenu;
    },
    closeMenu() {
      this.$store.state.meta.showMoreMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#navigation-bar {
  position: fixed;
  z-index: 500;
  bottom: 0;
  width: 100%;
  background: $neutral-0;
  border-top: 1px solid $neutral-10;
  text-align: center;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  &-wrap {
    display: flex;
    justify-content: space-around;
    padding: 8px 0;
    letter-spacing: -0.408px;
    line-height: 13px;
  }
}
.btn {
  flex: 1;
  font-size: 12px;
  line-height: 16px;
  color: $neutral-30;

  .yellow {
    color: $primary-50;
  }
  .gray {
    color: $neutral-30;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
</style>
