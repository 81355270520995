<template>
  <div
    class="check-box"
    :class="[{ active: checked }, { 'hover-off': hoverOff }]"
  >
    <p class="svg">
      <SvgImg
        v-if="checked"
        :size="16"
        :d="
          type == 'radio'
            ? $store.state.meta.svg.radioOn
            : $store.state.meta.svg.checkOn
        "
        :color="`${$store.state.meta.svg.color.primary50}`"
      />
      <SvgImg
        v-else
        :size="16"
        :d="
          type == 'radio'
            ? $store.state.meta.svg.radioOff
            : $store.state.meta.svg.checkOff
        "
        :color="`${$store.state.meta.svg.color.neutral40}`"
      />
    </p>
    <p :class="color" :style="fontSize ? `font-size:${fontSize}px` : ''">
      {{ name }}
    </p>
  </div>
</template>
<script>
export default {
  name: "CheckBox",
  props: {
    name: {
      type: String,
      description: "btn text",
    },
    type: {
      type: String,
      description: "btn type ",
    },
    checked: {
      type: Boolean,
      description: "btn disabled",
    },
    color: {
      type: String,
      description: "btn type ",
    },
    hoverOff: {
      type: Boolean,
      description: "btn disabled ",
    },
    fontSize: {
      type: Number,
      description: "btn type ",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.check-box {
  cursor: pointer;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid transparent;
  height: 26px;
  padding: 4px 0;

  &:hover {
    border-bottom: 1px solid $primary-20;
    &.active {
      border-bottom: 1px solid $neutral-0;
    }
  }
  &.hover-off {
    &:hover {
      border-bottom: 1px solid transparent;
      &.active {
        border-bottom: 1px solid transparent;
      }
    }
  }
  &.active {
    border-bottom: 1px solid transparent;
    color: $primary-60;
  }
  p {
    font-size: 13px;
    font-weight: 500;
    color: $neutral-80;
    line-height: 20px;
    &.on {
      color: $neutral-80;
    }
    &-off {
      color: $neutral-80;
    }
    &.light {
      color: $neutral-60;
    }
    &.disabled {
      color: $neutral-20;
    }
  }
}
</style>
