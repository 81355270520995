<template>
  <div class="bar-chart">
    <canvas id="myChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "BarChart",
  props: {
    labels: {
      type: Object,
    },
    datas: {
      type: Object,
    },
    isMobile: {
      type: Object,
    },
  },
  data() {
    return {
      chartData: {
        labels:
          this.isMobile == "false" ? this.labels : this.labels.slice(2, 7),
        datasets: [
          {
            barThickness: 20,
            backgroundColor: "#FFB800",
            data:
              this.isMobile == "false" ? this.datas : this.datas.slice(2, 7),
          },
        ],
      },
      chartOptions: {
        aspectRatio: this.isMobile == "true" ? 0.9 : 2,
        scales: {
          x: {
            border: {
              display: false, // 축제거
            },
            grid: {
              display: false, // X축 그리드 라인 숨기기
            },
          },
          y: {
            beginAtZero: true,
            max: 100, // 여기에 y축의 최대 값을 설정합니다.
            border: {
              display: false, // 축제거
            },
            grid: {
              color: "#FFF4DB",
            },
            ticks: {
              padding: 20,
              maxTicksLimit: 6, // y축에 최대 5개의 눈금만 표시
              callback: function (value) {
                return value + "%"; // 각 눈금 레이블에 '%' 추가
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false, // 범례 숨기기
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
      },
    };
  },
  mounted() {
    console.log([this.labels].reverse());
    const ctx = document.getElementById("myChart").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "bar", // 차트 타입: 'line', 'bar', 'pie' 등
      data: this.chartData,
      options: this.chartOptions,
    });
  },
};
</script>
<style lang="scss" scoped>
.bar-chart {
  // width: 692px;
}
</style>
