<template>
  <div class="content-wrap" v-show="step == 3">
    <div class="row-gray">
      <div class="content-title">
        <p>
          네크라인 (Neckline)
          <span>*</span>
        </p>
        <div class="tooltip">
          <div class="tooltip-wrap side">
            <div class="tooltip-wrap-neck">
              <div>
                <img src="@/assets/img/img-custom-tooltip-neck1.png" />
                <p>라운드 네크</p>
              </div>
              <div>
                <img src="@/assets/img/img-custom-tooltip-neck2.png" />
                <p>V 네크</p>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
      </div>
      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in neckLine.filter((e) =>
              e.customType.some(
                (s) => s == this.items.filter((e) => e.isActive)[0]?.cd
              )
            )"
            :key="i"
            @click="toggleComm('neckLine', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="imgs-img design">
              <img :src="require(`@/assets/img/${x.fileUrl}`)" />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row-gray mt-20"
      v-if="neckLine.filter((e) => e.isActive).length > 0"
    >
      <div class="content-title">
        <p>
          네크라인 디테일 (Neckline Detail)
          <span>*</span>
        </p>

        <div
          class="tooltip"
          v-if="this.items.filter((e) => e.isActive)[0]?.cd == '1'"
        >
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-dtl">
              <div class="tooltip-wrap-dtl-text">
                <span>립 마감</span>은 신축성 있는 골지 원단인 ‘립’으로 마감하는
                것을 말하며, 핏한 느낌을 제공하고, 기본 티셔츠부터 캐주얼,
                스포츠 등 많은 티셔츠 디자인에 활용합니다.
              </div>
              <div class="tooltip-wrap-dtl-text">
                <span>바인딩 마감</span>은 원단을 덮어 마감하는 것을 말하며,
                세탁으로 인한 늘어남을 방지하고, 내구성이 높고 깔끔한 느낌의
                마감입니다.
              </div>
              <div class="tooltip-wrap-dtl-img">
                <div>
                  <img src="@/assets/img/img-custom-tooltip-dtl1.png" />
                  <p class="mt-8">립</p>
                </div>
                <div>
                  <img src="@/assets/img/img-custom-tooltip-dtl2.png" />
                  <p class="mt-8">바인딩</p>
                </div>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
        <div
          class="tooltip"
          v-else-if="this.items.filter((e) => e.isActive)[0]?.cd == '2'"
        >
          <div class="tooltip-wrap side">
            <div class="tooltip-wrap-neck">
              <div>
                <img src="@/assets/img/img-custom-tooltip-dtl1.png" />
                <p>네크 립</p>
              </div>
              <div>
                <img src="@/assets/img/img-custom-tooltip-dtl3.png" />
                <p>V형 가젯 (거셋)</p>
              </div>
              <div>
                <img src="@/assets/img/img-custom-tooltip-dtl4.png" />
                <p>X자 스티치</p>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
      </div>

      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in this.items.filter((e) => e.isActive)[0]?.cd == 1
              ? neckDetail.filter((e) =>
                  e.nlCd?.some(
                    (s) => s == this.neckLine.filter((e) => e.isActive)[0]?.cd
                  )
                )
              : neckDetail.filter((e) =>
                  e.customType.some(
                    (s) => s == this.items.filter((e) => e.isActive)[0]?.cd
                  )
                )"
            :key="i"
            @click="toggleComm('neckDetail', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="imgs-img design">
              <img
                :src="
                  require(`@/assets/img/${
                    items.filter((e) => e.isActive)[0]?.cd == 1 ? '' : 'mtm-'
                  }${x.fileUrl}`)
                "
              />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
              <p class="sub">{{ x.subName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row-gray mt-12"
      v-if="neckDetail.filter((e) => e.isActive)[0]?.cd == '3'"
    >
      <div class="add-info">
        <SvgImg
          class="arrow1"
          :size="12"
          :d="$store.state.meta.svg.arrowTriUp"
          :color="$store.state.meta.svg.color.primary50"
        />
        <div class="add-info-title">
          <p>V형 가젯 (거셋)</p>
          <span class="bar"></span>
          <p>위치</p>
        </div>
        <div class="add-info-btns">
          <div
            class="add-info-btns-btn"
            v-for="(btn, i) in vgusset.button"
            :key="i"
            @click="toggleComm('vgusset', btn.index)"
            :class="{ active: btn.isActive }"
          >
            <p>{{ btn.name }}</p>
          </div>
        </div>
        <div class="add-info-title">
          <p>V형 가젯 (거셋)</p>
          <span class="bar"></span>
          <p>사이즈</p>
        </div>
        <div class="add-info-body">
          <div class="add-info-body-img">
            <img :src="require(`@/assets/img/custom-v-gusset-img.png`)" />
          </div>
          <div class="add-info-body-content">
            <div class="add-info-body-content-wrap">
              <div class="title">· 기본 (추천)</div>
              <div class="content">
                <div
                  class="length"
                  v-for="(value, i) in vgusset.value"
                  :key="i"
                >
                  <div class="length-name">
                    <p class="no">{{ value.cd }}</p>
                    <!-- <p>{{ value.name }}</p> -->
                  </div>
                  <div class="length-value">
                    <input
                      class="long"
                      type="text"
                      v-model="vgussetSize[i]"
                      @keyup="inputSize('vgusset', i, vgussetSize[i])"
                    />
                    <p>cm</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-info-refresh" @click="refreshSize('vgusset')">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.refreshIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          초기화 (추천)
        </div>
      </div>
    </div>
    <div
      class="row-gray mt-12"
      v-if="neckDetail.filter((e) => e.isActive)[0]?.cd == '4'"
    >
      <div class="add-info">
        <SvgImg
          class="arrow3"
          :size="12"
          :d="$store.state.meta.svg.arrowTriUp"
          :color="$store.state.meta.svg.color.primary50"
        />
        <div class="add-info-title">
          <p>X자 스티치</p>
          <span class="bar"></span>
          <p>위치</p>
        </div>
        <div class="add-info-body">
          <div class="add-info-body-img">
            <img :src="require(`@/assets/img/custom-x-gusset-img.png`)" />
          </div>
          <div class="add-info-body-content">
            <div class="add-info-body-content-wrap">
              <div class="title">· 기본 (추천)</div>
              <div class="content">
                <div
                  class="length"
                  v-for="(value, i) in xstitch.value"
                  :key="i"
                >
                  <div class="length-name">
                    <p class="no">{{ value.cd }}</p>
                    <!-- <p>{{ value.name }}</p> -->
                  </div>
                  <div class="length-value">
                    <input
                      class="long"
                      type="text"
                      v-model="xstitchSize[i]"
                      @keyup="inputSize('xstitch', i, xstitchSize[i])"
                    />
                    <p>cm</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="add-info-body-content-refresh"
          @click="refreshSize('xstitch')"
        >
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.refreshIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          초기화 (추천)
        </div>
      </div>
    </div>
    <div
      class="row-gray mt-12"
      v-if="
        (neckDetail.filter((e) => e.isActive).length > 0 &&
          neckDetail.filter((e) => e.isActive)[0].cd == '1') ||
        (neckDetail.filter((e) => e.isActive).length > 0 &&
          neckDetail.filter((e) => e.isActive)[0].cd == '3' &&
          vgusset.button.filter((e) => e.isActive).length > 0) ||
        (neckDetail.filter((e) => e.isActive).length > 0 &&
          neckDetail.filter((e) => e.isActive)[0].cd == '4')
      "
    >
      <div class="content-title">
        <p>
          네크라인 마감
          <span>*</span>
        </p>
        <div class="tooltip">
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-dtl">
              <div class="tooltip-wrap-dtl-text">
                <span>해리 작업</span>은 뒤 안쪽 네크라인의 시접 부분을 제
                원단이나 헤링본 테이프 등으로 덧박다주는 작업으로써, 시접 실밥이
                보이지 않아, 깔끔하고 기능적으로 목늘어짐을 방지합니다.
              </div>
              <div class="tooltip-wrap-dtl-img">
                <div>
                  <img src="@/assets/img/img-custom-tooltip-end1.png" />
                  <p class="mt-8">오바로크</p>
                </div>
                <div>
                  <img src="@/assets/img/img-custom-tooltip-end2.png" />
                  <p class="mt-8">해리</p>
                </div>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
        <div class="content-desc">
          ※ 해리 소재는 제 원단 (몸판 원단 / 컬러)를 기본으로 합니다.
        </div>
      </div>
      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in neckEnd.filter((e) =>
              e.customType.some(
                (s) => s == this.items.filter((e) => e.isActive)[0]?.cd
              )
            )"
            :key="i"
            @click="toggleComm('neckEnd', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="badge" v-if="x.isBadge">
              <SvgImg
                :size="12"
                :d="$store.state.meta.svg.starIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>추천</p>
            </div>
            <div class="imgs-img design">
              <img :src="require(`@/assets/img/${x.fileUrl}`)" />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
              <p class="sub">{{ x.subName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row-gray mt-12"
      v-if="
        (neckDetail.filter((e) => e.isActive).length > 0 &&
          neckDetail.filter((e) => e.isActive)[0].cd == '2') ||
        neckEnd.filter((e) => e.isActive).length > 0
      "
    >
      <div class="content-title">
        <p>
          스티치 (Stitch)
          <span>*</span>
        </p>

        <div class="tooltip">
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-dtl">
              <div class="tooltip-wrap-dtl-text">
                <span>본봉</span>은 가장 기본적인 봉제 방식으로 깔끔하고
                정교하게 표현되며, 대부분의 봉제에 사용됩니다.
              </div>
              <div class="tooltip-wrap-dtl-text">
                <span>갈라삼봉</span>은 시접을 중심에 두고 양 쪽으로 갈라서
                박아주는 봉제 기법으로 내부 봉제선이 평평하여 착용감이 좋고,
                보다 튼튼하게 박음질을 유지할 수 있습니다.
              </div>
              <div
                class="tooltip-wrap-dtl-text"
                v-if="items.filter((e) => e.isActive)[0].cd == '1'"
              >
                <span>체인 스티치</span>는 체인형으로 엮어 신축성이 뛰어나,
                움직임에 따라 좋은 착용감을 제공하며, 장식성으로 사용됩니다.
                (본봉이나 갈라삼봉에 비해 내구성이 다소 떨어질 수 있습니다.)
              </div>
              <div
                class="tooltip-wrap-dtl-text"
                v-if="items.filter((e) => e.isActive)[0].cd == '2'"
              >
                <span>가이룹바</span>는 유연성과 내구성이 매우 뛰어나며,
                봉제선이 평평하여 피부에 자극이 적어 착용감이 좋습니다. 또한,
                독특한 외관은 디자인적 요소로 사용됩니다.
              </div>
            </div>
            <div
              class="tooltip-wrap-img3"
              v-if="items.filter((e) => e.isActive)[0].cd == '2'"
            >
              <div>
                <img src="@/assets/img/preview-stitch-img1.png" />
              </div>
              <div>
                <img src="@/assets/img/preview-stitch-img2.png" />
              </div>
              <div>
                <img src="@/assets/img/preview-stitch-img3.png" />
              </div>
              <div>
                <img src="@/assets/img/preview-stitch-img4.png" />
                <p class="mt-8">본봉</p>
              </div>
              <div>
                <img src="@/assets/img/preview-stitch-img5.png" />
                <p class="mt-8">갈라삼봉</p>
              </div>
              <div>
                <img src="@/assets/img/preview-stitch-img6.png" />
                <p class="mt-8">가이룹바</p>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
        <div class="content-desc">
          ※ 스티치의 경우, 네크라인과 어깨선을 기본으로 합니다.
        </div>
      </div>
      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in stitch.filter((e) =>
              e.ndCd.some(
                (s) => s == neckDetail.filter((f) => f.isActive)[0].cd
              )
            )"
            :key="i"
            @click="toggleComm('stitch', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="badge" v-if="x.isBadge">
              <SvgImg
                :size="12"
                :d="$store.state.meta.svg.starIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>추천</p>
            </div>
            <div class="imgs-img design">
              <img :src="require(`@/assets/img/${x.fileUrl}`)" />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
              <p class="sub">{{ x.subName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row-gray mt-12"
      v-if="
        stitch.filter((e) => e.isActive).length > 0 &&
        this.items.filter((e) => e.isActive)[0]?.cd == '2'
      "
    >
      <div class="content-title">
        <p>
          소매 마감
          <span>*</span>
        </p>
        <div class="tooltip">
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-img">
              <div>
                <img src="@/assets/img/preview-sleeve-end-img1.png" />
                <p class="mt-8">소매 2줄 삼봉</p>
              </div>
              <div>
                <img src="@/assets/img/preview-sleeve-end-img2.png" />
                <p class="mt-8">소매 시보리</p>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
      </div>
      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in sleeveEnd"
            :key="i"
            @click="toggleComm('sleeveEnd', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="imgs-img design">
              <img :src="require(`@/assets/img/${x.fileUrl}`)" />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
              <p class="sub">{{ x.subName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row-gray mt-12"
      v-if="
        sleeveEnd.filter((e) => e.isActive).length > 0 &&
        this.items.filter((e) => e.isActive)[0]?.cd == '2'
      "
    >
      <div class="content-title">
        <p>
          밑단 마감
          <span>*</span>
        </p>
        <div class="tooltip">
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-img">
              <div>
                <img src="@/assets/img/preview-hem-end-img1.png" />
                <p class="mt-8">밑단 2줄 삼봉</p>
              </div>
              <div>
                <img src="@/assets/img/preview-hem-end-img2.png" />
                <p class="mt-8">밑단 시보리</p>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
      </div>
      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in hemEnd"
            :key="i"
            @click="toggleComm('hemEnd', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="imgs-img design">
              <img :src="require(`@/assets/img/${x.fileUrl}`)" />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
              <p class="sub">{{ x.subName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row-gray mt-12"
      v-if="
        (stitch.filter((e) => e.isActive).length > 0 &&
          this.items.filter((e) => e.isActive)[0]?.cd == '1') ||
        (hemEnd.filter((e) => e.isActive).length > 0 &&
          this.items.filter((e) => e.isActive)[0]?.cd == '2')
      "
    >
      <div class="content-title">
        <p>
          포켓 (Pocket)
          <span>*</span>
        </p>
        <div
          class="tooltip"
          v-if="this.items.filter((e) => e.isActive)[0]?.cd == '1'"
        >
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-img">
              <div><img src="@/assets/img/pocket-size-img1.png" /></div>
              <div><img src="@/assets/img/pocket-size-img2.png" /></div>
              <div><img src="@/assets/img/pocket-size-img3.png" /></div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
        <div
          class="tooltip"
          v-else-if="this.items.filter((e) => e.isActive)[0]?.cd == '2'"
        >
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-img">
              <div>
                <img src="@/assets/img/pocket-size-img0.png" />
                <p class="mt-8">포켓 없음</p>
              </div>
              <div>
                <img src="@/assets/img/pocket-size-img4.png" />
                <p class="mt-8">켕거루 포켓</p>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
        <div class="content-desc">
          ※ 포켓의 경우, 왼쪽 가슴에 10cm × 12cm 사이즈를 기본으로 합니다.
        </div>
      </div>
      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in pocket.filter((e) =>
              e.customType.some(
                (s) => s == this.items.filter((e) => e.isActive)[0]?.cd
              )
            )"
            :key="i"
            @click="toggleComm('pocket', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="imgs-img design">
              <img
                v-if="x.fileUrl"
                :src="require(`@/assets/img/${x.fileUrl}`)"
              />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row-gray mt-12"
      v-if="pocket.filter((e) => e.isActive)[0]?.cd == '5'"
    >
      <div class="add-info">
        <SvgImg
          class="arrow3"
          :size="12"
          :d="$store.state.meta.svg.arrowTriUp"
          :color="$store.state.meta.svg.color.primary50"
        />
        <div class="add-info-title">
          <p>캥거루 포켓</p>
          <span class="bar"></span>
          <p>사이즈</p>
          <div class="tooltip">
            <div class="tooltip-wrap">
              <div class="tooltip-wrap-dtl"></div>
            </div>
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.questMarkIcon"
              :color="$store.state.meta.svg.color.primary50"
            />
          </div>
        </div>
        <div class="add-info-body">
          <div class="add-info-body-img">
            <img :src="require(`@/assets/img/custom-kgr-pocket-img.png`)" />
          </div>
          <div class="add-info-body-content">
            <div class="add-info-body-content-wrap">
              <div class="title">· 기본 (추천)</div>
              <div class="content">
                <div
                  class="length"
                  v-for="(value, i) in kgrPocket.value"
                  :key="i"
                >
                  <div class="length-name">
                    <p class="no">{{ value.cd }}</p>
                    <!-- <p>{{ value.name }}</p> -->
                  </div>
                  <div class="length-value">
                    <input
                      class="long"
                      type="text"
                      v-model="kgrPocketSize[i]"
                      @keyup="inputSize('kgrPocket', i, kgrPocketSize[i])"
                    />
                    <p>cm</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="add-info-body-content-refresh"
              @click="refreshSize('kgrPocket')"
            >
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.refreshIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              초기화 (추천)
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row-gray mt-12"
      v-if="
        pocket.filter((e) => e.isActive).length > 0 &&
        this.items.filter((e) => e.isActive)[0]?.cd == '1'
      "
    >
      <div class="content-title">
        <p>
          소매 밑단
          <span>*</span>
        </p>
        <div class="tooltip">
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-img">
              <div>
                <img src="@/assets/img/preview-sleeve-img1.png" />
                <p class="mt-8">소매 삼봉</p>
              </div>
              <div>
                <img src="@/assets/img/preview-sleeve-img2.png" />
                <p class="mt-8">소매 립</p>
              </div>
              <div>
                <img src="@/assets/img/preview-sleeve-img3.png" />
                <p class="mt-8">소매 바인딩</p>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
      </div>
      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in sleeve"
            :key="i"
            @click="toggleComm('sleeve', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="imgs-img design">
              <img :src="require(`@/assets/img/${x.fileUrl}`)" />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
              <p class="sub">{{ x.subName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row-gray mt-12"
      v-if="
        sleeve.filter((e) => e.isActive).length > 0 &&
        this.items.filter((e) => e.isActive)[0]?.cd == '1'
      "
    >
      <div class="content-title">
        <p>옆 트임 <span>*</span></p>
        <div class="tooltip">
          <div class="tooltip-wrap">
            <div class="tooltip-wrap-img">
              <div>
                <img src="@/assets/img/preview-sideslit-img1.png" />
                <p class="mt-8">옆 트임 <span class="deactive">없음</span></p>
              </div>
              <div>
                <img src="@/assets/img/preview-sideslit-img2.png" />
                <p class="mt-8">옆 트임 <span class="active">있음</span></p>
              </div>
            </div>
          </div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.questMarkIcon"
            :color="$store.state.meta.svg.color.primary50"
          />
        </div>
      </div>
      <div class="imgs-wrap">
        <div class="imgs">
          <div
            v-for="(x, i) in sideSlit"
            :key="i"
            @click="toggleComm('sideSlit', x.index)"
            class="imgs-btn"
            :class="x.isActive ? 'active' : ''"
          >
            <div class="imgs-img design">
              <img :src="require(`@/assets/img/${x.fileUrl}`)" />
            </div>
            <div class="imgs-title" :class="x.isActive ? 'active' : ''">
              <p>{{ x.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-bottom">
      <div
        class="validation mt-11"
        v-if="this.items.filter((e) => e.isActive)[0]?.cd == '1'"
      >
        <p class="active">(필수)</p>

        <p :class="{ active: neckLine.filter((e) => e.isActive).length > 0 }">
          네크라인
        </p>
        <p
          :class="{
            active: neckDetail.filter((e) => e.isActive).length > 0,
          }"
        >
          네크라인 디테일
        </p>
        <p
          v-if="
            neckDetail.filter((e) => e.isActive).length > 0 &&
            neckDetail.filter((e) => e.isActive)[0].cd == '1'
          "
          :class="{ active: neckEnd.filter((e) => e.isActive).length > 0 }"
        >
          네크라인 마감
        </p>
        <p :class="{ active: stitch.filter((e) => e.isActive).length > 0 }">
          스티치
        </p>
        <p :class="{ active: pocket.filter((e) => e.isActive).length > 0 }">
          포켓
        </p>
        <p :class="{ active: sleeve.filter((e) => e.isActive).length > 0 }">
          소매 밑단
        </p>
        <p :class="{ active: sideSlit.filter((e) => e.isActive).length > 0 }">
          옆 트임
        </p>
      </div>
      <div
        class="validation mt-11"
        v-else-if="this.items.filter((e) => e.isActive)[0]?.cd == '2'"
      >
        <p class="active">(필수)</p>

        <p :class="{ active: neckLine.filter((e) => e.isActive).length > 0 }">
          네크라인
        </p>
        <p
          :class="{
            active: neckDetail.filter((e) => e.isActive).length > 0,
          }"
        >
          네크라인 디테일
        </p>
        <p
          v-if="neckDetail.filter((e) => e.isActive).length > 0"
          :class="{ active: neckEnd.filter((e) => e.isActive).length > 0 }"
        >
          네크라인 마감
        </p>
        <p :class="{ active: stitch.filter((e) => e.isActive).length > 0 }">
          스티치
        </p>
        <p :class="{ active: sleeveEnd.filter((e) => e.isActive).length > 0 }">
          소매단 마감
        </p>
        <p :class="{ active: hemEnd.filter((e) => e.isActive).length > 0 }">
          밑단 마감
        </p>
        <p :class="{ active: pocket.filter((e) => e.isActive).length > 0 }">
          포켓
        </p>
      </div>
      <div class="content-bottom-btns">
        <p
          class="content-bottom-btns-btn gray"
          @click="isDtlRefreshPopup = true"
        >
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.refreshIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          초기화
        </p>
        <p
          class="content-bottom-btns-btn"
          :class="{ active: isValidation[3] }"
          @click="isValidation[3] ? toggleTab(Number(step * 1 + 1)) : ''"
        >
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.checkIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          디테일 선택
        </p>
      </div>
    </div>
  </div>
  <div class="popup-refresh" v-if="isDtlRefreshPopup">
    <div class="popup-background" @click="isDtlRefreshPopup = false"></div>
    <div class="popup-refresh-wrap">
      <div class="popup-refresh-wrap-body">
        <div class="title">
          <p>입력하신 내용을 초기화 하시겠습니까?</p>
        </div>
        <div class="content">
          필수<span>*</span> 내용을 제외한 모든 내용이 삭제됩니다.
        </div>
      </div>
      <div class="popup-refresh-wrap-bottom">
        <div class="popup-refresh-wrap-bottom-btn pointer">
          <p class="" @click="isDtlRefreshPopup = false">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closePopup"
              :color="$store.state.meta.svg.color.neutral0"
            />
            취소
          </p>
          <p class="active" @click="dtlRefresh()">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.refreshIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            초기화
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgImg from "../SvgImg.vue";

export default {
  name: "CustomAdd",
  props: {
    step: {
      type: String,
    },
    isValidation: {
      type: Object,
    },
  },
  data() {
    return {
      items: [],
      neckLine: [],
      neckDetail: [],
      neckEnd: [],
      stitch: [],
      sleeveEnd: [],
      hemEnd: [],
      pocket: [],
      sleeve: [],
      sideSlit: [],
      vgussetSize: [6.5, 8.0],
      xstitchSize: [7.5, 9.0, 2.0],
      kgrPocketSize: [26.0, 21.0, 36.0, 34.0, 7.5],
      vgusset: {},
      xstitch: {},
      kgrPocket: {},
      isDtlRefreshPopup: false,
    };
  },
  mounted() {
    this.items = this.$store.state.customPrint.items;
    this.neckLine = this.$store.state.customPrint.neckLine;
    this.neckDetail = this.$store.state.customPrint.neckDetail;
    this.neckEnd = this.$store.state.customPrint.neckEnd;
    this.stitch = this.$store.state.customPrint.stitch;
    this.pocket = this.$store.state.customPrint.pocket;
    this.sleeve = this.$store.state.customPrint.sleeve;
    this.sleeveEnd = this.$store.state.customPrint.sleeveEnd;
    this.hemEnd = this.$store.state.customPrint.hemEnd;
    this.sideSlit = this.$store.state.customPrint.sideSlit;

    this.vgusset = this.$store.state.customPrint.vgusset;
    this.xstitch = this.$store.state.customPrint.xstitch;
    this.kgrPocket = this.$store.state.customPrint.kgrPocket;
  },
  methods: {
    toggleTab(index) {
      this.$emit("toggleTab", index);
    },
    toggleComm(type, index) {
      const customType = this.items.filter((e) => e.isActive)[0]?.cd;
      console.log(customType);
      if (type == "neckDetail") {
        this.neckEnd.map((e) => (e.isActive = false));
        this.stitch.map((e) => (e.isActive = false));
        this.pocket.map((e) => (e.isActive = false));
        this.sleeve.map((e) => (e.isActive = false));
        this.sideSlit.map((e) => (e.isActive = false));
        this.sleeveEnd.map((e) => (e.isActive = false));
        this.hemEnd.map((e) => (e.isActive = false));
        this.vgusset.button.map((e) => (e.isActive = false));
        this.vgusset.value = [
          { index: 0, cd: "A", name: "가로", size: 6.5 },
          { index: 1, cd: "B", name: "세로", size: 8.0 },
        ];
        this.xstitch.value = [
          { index: 0, cd: "A", name: "내경 가로", size: 7.5 },
          { index: 1, cd: "B", name: "내경 세로", size: 9.0 },
          { index: 2, cd: "C", name: "아래 길이", size: 2.0 },
        ];
        this.kgrPocket.value = [
          { index: 0, cd: "A", name: "", size: 26.0 },
          { index: 1, cd: "B", name: "", size: 21.0 },
          { index: 2, cd: "C", name: "", size: 36.0 },
          { index: 3, cd: "D", name: "", size: 34.0 },
          { index: 4, cd: "E", name: "", size: 7.5 },
        ];
      }
      this.$emit("toggleComm", type, index);
    },
    inputSize(type, index, value) {
      this[type].value[index].size = value;
      console.log(this.vgusset);
      console.log(this.xstitch);
      console.log(this.kgrPocket);
    },
    refreshSize(type) {
      console.log(type);
      console.log(this.vgussetSize);
      if (type == "vgusset") {
        this.vgussetSize = [6.5, 8.0];
      }
      if (type == "xstitch") {
        this.xstitchSize = [7.5, 9.0, 2.0];
      }
      if (type == "kgrPocket") {
        this.kgrPocketSize = [26.0, 21.0, 36.0, 34.0, 7.5];
      }
      console.log(this.vgussetSize);
    },
    dtlRefresh() {
      this.isDtlRefreshPopup = false;
      this.$emit("dtlRefresh");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/custom.scss";
</style>
