<template>
  <div class="factory-list" v-if="d" :class="isMobile == 'true' ? 'mb' : ''">
    <div class="factory-list-top">
      <p class="participation">매칭에 참여한 공장</p>
      <p class="count">
        참여 공장
        <span>{{ d ? d.length : "0" }}</span>
      </p>
      <!-- <p class="status" v-if="detailData.recruitStatus == '1'">진행 중</p>
    <p class="status" v-if="detailData.recruitStatus == '2'">발주 중</p>
    <p class="status" v-if="detailData.recruitStatus == '3'">발주</p>
    <p class="status" v-if="detailData.recruitStatus == '4'">마감</p> -->
    </div>
    <div class="factory-list-wrap">
      <div class="factory-list-card" v-for="(x, i) in d" :key="i">
        <div class="part-date">
          <p>참여일: {{ x.createdDate }}</p>
        </div>
        <div class="factory-list-card-wrap">
          <div class="factory-list-card-wrap-top">
            <div class="profile">
              <div class="process">
                <div class="process-head">
                  <p>{{ x.workProcess }}</p>
                  <div class="process-head-icons">
                    <SvgImg
                      class="pointer"
                      :size="20"
                      :d="$store.state.meta.svg.share"
                      :color="$store.state.meta.svg.color.neutral40"
                      @click="clickShare(x)"
                    />
                    <SvgImg
                      class="pointer"
                      :size="20"
                      :d="$store.state.meta.svg.favoriteFill"
                      :color="
                        x.likeFlag == '1'
                          ? $store.state.meta.svg.color.primary50
                          : $store.state.meta.svg.color.neutral40
                      "
                      @click="clickLike(i, x)"
                    />
                  </div>
                </div>
                <div class="name">
                  <p>{{ x.factoryName }}</p>
                  <div class="icon" v-if="x.calculateFlag == '2'">
                    <img
                      :src="require(`@/assets/icon/ic-credit-card-orange.svg`)"
                    />
                  </div>
                </div>
                <div class="content">
                  <div class="content-info">
                    <p class="area" :class="x.minOrderCount > 0 ? 'bf' : ''">
                      {{ x.area }}
                    </p>
                    <div
                      class="row-content flex-start gap8"
                      v-if="x.minOrderCount > 0"
                    >
                      <p>{{ comma(x.minOrderCount) }}벌 이상</p>
                    </div>
                  </div>
                  <div
                    class="content-info fabric"
                    v-if="x.workProcessCd == '01033'"
                  >
                    <p v-if="x.sampleType == '1' || x.sampleType == '3'">
                      패턴
                    </p>
                    <p v-if="x.sampleType == '2' || x.sampleType == '3'">
                      샘플
                    </p>
                  </div>
                  <div class="content-info fabric">
                    <p
                      class="area"
                      :class="
                        x.workProcessCd == '01031' &&
                        (x.processFlag == '1' || x.buyFlag == '1')
                          ? 'bf'
                          : ''
                      "
                    >
                      {{ x.fabricList.map((e) => e.commName).join(" ") }}
                    </p>
                    <div
                      class="row-content flex-start gap8"
                      v-if="x.workProcessCd == '01031'"
                    >
                      <p v-if="x.processFlag == '1'">임가공</p>
                      <p v-if="x.buyFlag == '1'">완사입</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="factory-list-card-wrap-body">
            <div class="space-between">
              <p class="sub-title">제안 견적</p>
              <div
                class="price"
                v-if="!(x.minUnitCost > 0 || x.maxUnitCost > 0)"
              >
                <p class="num">{{ comma(x.unitCost) }} 원 내외 (VAT 별도)</p>
              </div>
            </div>
          </div>
          <div
            class="factory-list-card-wrap-msg"
            v-if="x.factoryWorkDesc && x.factoryWorkDesc !== 'null'"
          >
            <div class="msg-wrap" @click="isShowMsg[i] = !isShowMsg[i]">
              <p>메시지 보기</p>
              <SvgImg
                :size="20"
                :d="
                  isShowMsg[i]
                    ? $store.state.meta.svg.dropDown
                    : $store.state.meta.svg.dropUp
                "
                :color="$store.state.meta.svg.color.neutral60"
              />
            </div>
            <div class="msg-content" v-show="isShowMsg[i]">
              <p
                :class="isShowMsg[i] ? 'add' : ''"
                v-html="
                  x.factoryWorkDesc == 'null'
                    ? ''
                    : x.factoryWorkDesc.replace(/\n/g, '<br>')
                "
              ></p>
            </div>
          </div>
        </div>
        <div class="bottom-btns">
          <div class="btns-wrap">
            <!-- <div
            @click.stop="openContract(x.factoryNo, x.factoryName)"
            class="bottom-btn pointer"
          >
            <img class="mr-6" src="@/assets/icon/ic-edit-white.svg" />
            <p>발주 하기</p>
          </div> -->
            <div
              @click="this.gotoPage(`/search/factorydetail/${x.factoryNo}`)"
              class="bottom-btn pointer"
            >
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.factoryIcon"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>프로필</p>
            </div>
            <div
              @click="callFactory(x.factoryNo, x.telNum)"
              class="bottom-btn pointer"
            >
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.phone"
                :color="$store.state.meta.svg.color.neutral0"
              />
              <p>통화하기</p>
            </div>
            <!-- <div
            @click="goToChatRoom(x.factoryUserId)"
            class="bottom-btn pointer"
          >
            <img src="@/assets/icon/ic-message-circle-white.svg" />
            <p>대화 하기</p>
          </div> -->
          </div>
        </div>

        <div
          class="detail-bottom"
          v-if="isDetail[i]"
          @click="isDetail[i] = !isDetail[i]"
        >
          <p v-if="isDetail[i]">접기</p>
          <img
            :src="
              require(`@/assets/icon/ic-selectbox-gray${
                isDetail[i] ? '-up' : '-down'
              }.svg`)
            "
          />
        </div>
      </div>
    </div>
  </div>

  <div v-else class="no-factory-list">
    <p>공장 참여를 기다리는 중입니다.</p>
  </div>
  <div class="popup-share" v-if="isShare">
    <div class="popup-background" @click="isShare = false"></div>
    <div class="popup-share-wrap">
      <img
        class="close"
        @click="isShare = false"
        src="@/assets/icon/ic-x.svg"
      />
      <div class="popup-share-wrap-top">
        <div class="title">
          <p>공유하기</p>
        </div>
      </div>

      <div class="popup-share-wrap-body">
        <p class="title">공장 프로필을 공유해보세요.</p>
        <div class="content">
          <div class="logo">
            <img src="@/assets/logo/kakao-logo.svg" />
            <p>카카오톡</p>
          </div>
          <div class="logo">
            <img src="@/assets/logo/line-logo.svg" />
            <p>라인</p>
          </div>
          <div class="logo">
            <img src="@/assets/logo/instagram-logo.svg" />
            <p>인스타그램</p>
          </div>
          <div class="logo">
            <img src="@/assets/logo/facebook-logo.svg" />
            <p>페이스북</p>
          </div>
        </div>
      </div>

      <div class="popup-share-wrap-bottom">
        <div
          class="popup-share-wrap-bottom-btn pointer"
          :class="isPopupClick ? 'relative' : ''"
        >
          <p @click="isShare = false">닫기</p>
          <p class="active" @click="copyUrl()">URL 복사하기</p>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-comm" v-if="isCopy">
    <div class="popup-background" @click="isCopy = false"></div>
    <div class="popup-comm-wrap">
      <img class="close" @click="isCopy = false" src="@/assets/icon/ic-x.svg" />
      <div class="popup-comm-wrap-top">
        <!-- <div class="title">
            <p>공유하기</p>
          </div> -->
      </div>

      <div class="popup-comm-wrap-body">
        <p class="title">URL 링크가 복사되었습니다.</p>
      </div>

      <div class="popup-comm-wrap-bottom">
        <div
          class="popup-comm-wrap-bottom-btn pointer"
          :class="isPopupClick ? 'relative' : ''"
        >
          <p class="active" @click="isCopy = false">확인</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DesignOrderFactoryList",
  props: {
    detailData: {
      type: Object,
      description: "Card data",
    },
  },
  data() {
    return {
      d: [],
      isShowMsg: [],
      isShowMsgBottom: {},
      isDetail: {},
      factoryDetails: {},
      isShowContract: false,
      isLoading: false,
      isShare: false,
      isCopy: false,
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  mounted() {
    this.detailData.factoryList.forEach((e, i) => {
      this.isShowMsg[i] = false;
    });
    this.d = this.detailData.factoryList;
  },
  methods: {
    clickShare(d) {
      this.shareUrl =
        document.location.host + "/search/factorydetail/" + d.factoryNo;
      this.isShare = true;
    },
    copyUrl() {
      // 텍스트를 클립보드에 복사
      navigator.clipboard
        .writeText(this.shareUrl)
        .then(() => {
          // 복사 성공 시 로직 추가 가능
          console.log("Text copied to clipboard:", this.shareUrl);
        })
        .catch((err) => {
          // 복사 실패 시 로직 추가 가능
          console.error("Unable to copy to clipboard:", err);
        });
      this.isShare = false;
      this.isCopy = true;
    },
    clickLike(i, datas) {
      console.log(datas);
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = datas.likeFlag == "1" ? "0" : "1";
      this.d[i].likeFlag = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        datas.factoryNo,
        updateVal
      );
    },
    factoryBookmark(index, designOrderNo, factoryNo) {
      console.log(index, designOrderNo, factoryNo);
      this.$emit("factoryBookmark", index, designOrderNo, factoryNo);
    },
    clickFactoryDetail(i, factoryNo) {
      this.isDetail[i] = !this.isDetail[i];
      this.$API.search
        .factoryDetail(sessionStorage.getItem("userId"), factoryNo)
        .then((res) => {
          let d = res.data.body;
          console.log(d);
          this.isShowMap = true;

          //지도 init
          // this.$nextTick(() => {
          //   this.initMap(d.latitude, d.longitude);
          //   this.isShowMap = false;
          // });

          this.fabricList = [];
          d.fabricList.forEach((e) => {
            this.fabricList.push(e.commName);
          });

          this.factoryFileList = d.factoryFileList;
          // this.imgList = []
          // for (let i=1;i<=6;i++) {
          //   if (d['imgFileUrl'+i]) {
          //     this.imgList.push({fileUrl: d['imgFileUrl'+i], type: 'factory'})
          //   }
          // }
          // for (let i=1;i<=6;i++) {
          //   if (d['sampleFileUrl'+i]) {
          //     this.imgList.push({fileUrl: d['sampleFileUrl'+i], type: 'sample'})
          //   }
          // }

          d.compDesc = d.compDesc?.replace(/\n/g, "<br>");

          d.autoCount = 0;
          if (d.compNum || d.factoryCompNum) d.autoCount += 1;
          if (d.certVisit === "1") d.autoCount += 1;
          if (d.contractFlag === "1") d.autoCount += 1;

          d.isLike = d.likeFlag === "1" ? 1 : 0;
          d.likeCount = Number(d.likeCount) + Number(d.isLike ? -1 : 0);

          this.cntMax = d.cnt1 + d.cnt2 + d.cnt3 + d.cnt4 + d.cnt5;

          //소개글 URL 하이퍼링크
          d.compDesc = this.replaceURLWithHTMLLinks(d.compDesc);

          this.factoryDetails = d;
        });

      //
      window.addEventListener("scroll", this.handleScroll);
    },
  },
  beforeRouteLeave(to, from, next) {
    // Save the current scroll position
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
};
</script>

<style lang="scss" scoped>
.factory-list {
  padding: 40px 20px;
  background-color: $neutral-0;
  height: 100%;
  overflow: auto;
  &.mb {
    background-color: $neutral-3;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid $primary-20;
    .status {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $neutral-80;
    }
    .date {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-40;
    }
    .participation {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    .count {
      color: $neutral-60;
      font-size: 14px;
      font-weight: 400;
      span {
        color: $primary-60;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  &-wrap {
    max-width: 520px;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &-card {
    .part-date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      color: $neutral-30;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    &-wrap {
      margin-top: 8px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      border-radius: 4px 4px 0px 0px;
      border-top: 1px solid $neutral-10;
      border-right: 1px solid $neutral-10;
      border-left: 1px solid $neutral-10;
      background-color: $neutral-0;
      &-top {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .profile {
          display: flex;
          gap: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          .process {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            &-head {
              display: flex;
              align-items: center;
              justify-content: space-between;
              &-icons {
                display: flex;
                gap: 12px;
              }
            }
          }
          .name {
            margin-top: 6px;
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $neutral-80;
            .icon {
              display: flex;
              align-items: center;
            }
          }
          .content {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $neutral-80;
            &-info {
              display: flex;
              gap: 16px;
              .area {
                position: relative;
                &.bf::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  right: -8px;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 12px;
                  background-color: $neutral-30;
                }
              }
              &.fabric {
                color: $neutral-60;
              }
            }
          }
        }
      }
      &-body {
        position: relative;
        margin-top: 16px;
        border-radius: 4px;
        padding: 16px;
        background-color: $neutral-3;
        .sub-title {
          color: $primary-60;
          font-weight: 500;
          font-size: 14px;
          span {
            font-size: 12px;
            font-weight: 400;
          }
        }
        .price {
          position: absolute;
          left: 91px;
          font-weight: 400;
          font-size: 14px;
          color: $neutral-60;
          .num {
            color: $neutral-80;
            font-weight: 500;
          }
        }
      }
      &-msg {
        margin-top: 16px;
        .msg-wrap {
          cursor: pointer;
          // padding: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-size: 14px;
          font-weight: 500;
        }
        .msg-content {
          border-radius: 4px;
          max-height: 224px;
          margin-top: 16px;
          padding: 12px 16px;
          background-color: $neutral-3;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          overflow: auto;
        }
      }
    }
    .bottom-btns {
      background-color: $neutral-0;
      z-index: 2;
      .btns-wrap {
        display: flex;
      }
      .bottom-btn {
        flex: 1;
        gap: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: $neutral-0;
        padding: 9px;
        &:first-child {
          border-color: $neutral-60;
          background-color: $neutral-60;
          border-radius: 0 0 0 4px;
        }
        &:last-child {
          background-color: $primary-50;
          border-radius: 0 0 4px 0;
        }

        img {
          width: 16px;
        }
      }
    }
  }
  .line {
    margin: 16px 0;
    border-bottom: 1px solid $primary-20;
  }

  .detail-bottom {
    height: 40px;
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    max-width: 520px;
    width: 100%;
    border: 1px solid $neutral-20;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-30;
    }
    img {
      height: 6px;
    }
    // position: absolute;
    // img {
    //   width: 24px;
    //   height: 24px;
    // }
  }
}
.no-factory-list {
  margin-top: 100px;
  width: 500px;
  text-align: center;
  color: $neutral-60;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.modal {
  &-contract {
    background-color: $neutral-3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: fixed;
    z-index: 1001;
    left: 50%;
    top: 146px;
    width: calc(100% - 48px);
    max-width: 320px;
    transform: translate(-50%, 0);
    text-align: center;
    color: $neutral-100;
    &-body {
      padding: 28px 34px;
      display: flex;
      flex-direction: column;
      .title {
        @include ellipse();
        color: $neutral-100;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      .catg {
        margin-top: 6px;
        color: $neutral-80;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
      .quest {
        margin-top: 8px;
        color: $neutral-60;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        span {
          color: $primary-60;
        }
      }
    }
    .btns {
      display: flex;
      height: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $neutral-0;
      &-left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $neutral-60;
        border-bottom-left-radius: 8px;
      }
      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.popup-share {
  .popup-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
  }
  &-wrap {
    position: fixed;
    z-index: 530;
    width: 100%;
    // height: calc(100% - 56px);
    overflow-y: auto;
    // padding-bottom: 70px;
    background-color: $neutral-0;
    // height: calc(100vh - 98px);
    max-width: 460px;
    max-height: 321px;
    height: calc(100vh - 150px);
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    @include hidden-scroll();
    .close {
      cursor: pointer;
      position: absolute;
      top: 28px;
      right: 20px;
    }
    &-top {
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .title {
        display: flex;
        gap: 20px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    &-body {
      margin: 0 24px;
      .title {
        font-size: 16px;
        font-weight: 400;
      }
      .content {
        margin-top: 24px;
        width: 412px;
        height: 126px;
        border-radius: 4px;
        background: $neutral-3;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .logo {
          width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          img {
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }

    &-bottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1;
      &-btn {
        @media (min-width: 1290px) {
          background-color: $neutral-60;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          cursor: pointer;
        }
        background-color: $neutral-60;
        text-align: center;
        color: $neutral-0;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        cursor: pointer;
        p {
          padding: 9px;
          flex: 1;
          &.active {
            background-color: $primary-50;
          }
        }
        &.relative {
          position: relative;
        }
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}
.popup-comm {
  .popup-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
  }
  &-wrap {
    position: fixed;
    z-index: 530;
    width: 100%;
    // height: calc(100% - 56px);
    overflow-y: auto;
    // padding-bottom: 70px;
    background-color: $neutral-0;
    // height: calc(100vh - 98px);
    max-width: 360px;
    max-height: 202px;
    height: calc(100vh - 150px);
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    @include hidden-scroll();
    .close {
      cursor: pointer;
      position: absolute;
      top: 28px;
      right: 20px;
    }
    &-body {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 162px;
      .title {
        font-size: 16px;
        font-weight: 500;
      }
      .content {
        margin-top: 24px;
        width: 412px;
        height: 126px;
        border-radius: 4px;
        background: $neutral-3;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .logo {
          width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          img {
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }

    &-bottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1;
      &-btn {
        @media (min-width: 1290px) {
          background-color: $neutral-60;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          cursor: pointer;
        }
        background-color: $neutral-60;
        text-align: center;
        color: $neutral-0;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        cursor: pointer;
        p {
          padding: 9px;
          flex: 1;
          &.active {
            background-color: $primary-50;
          }
        }
        &.relative {
          position: relative;
        }
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}
</style>
