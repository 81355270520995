<template>
  <div class="popup-add">
    <div class="popup-background" @click="closePopup()"></div>
    <div class="popup-add-wrap">
      <div class="popup-add-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.customAdd"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>New 제작 의뢰</p>
        </div>
        <SvgImg
          class="pointer"
          :size="20"
          :d="$store.state.meta.svg.closePopup"
          :color="$store.state.meta.svg.color.neutral0"
          @click="closePopup()"
        />
      </div>

      <div class="popup-add-wrap-body">
        <div class="style-name">
          <p>· 스타일/상품 명 <span>*</span></p>
          <p class="valid" v-if="!styleName">
            스타일 또는 상품 명을 입력해주세요.
          </p>
        </div>

        <input
          type="text"
          v-model="styleName"
          @keyup="convStyleName()"
          @keyup.enter="styleName ? openAdd() : ''"
          placeholder="스타일 또는 상품 명을 입력해주세요."
          :class="{ empty: !styleName }"
        />
      </div>

      <div class="popup-add-wrap-bottom">
        <div class="popup-add-wrap-bottom-btn pointer">
          <p class="" @click="openAdd()" :class="{ active: styleName }">
            다음
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.pagingNext"
              :color="$store.state.meta.svg.color.neutral0"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "compCustomAddPopup",
  data() {
    return {
      isAdd: false,
      styleName: "",
      userId: sessionStorage.getItem("userId"),
    };
  },
  mounted() {},
  methods: {
    convStyleName() {
      this.styleName = this.styleName.replace("#", "");
    },
    closePopup() {
      this.$emit("customAddPopup", false);
    },
    openAdd() {
      this.isAdd = true;
      const customType = "1";
      this.$API.custom
        .customRegist(this.userId, this.styleName, customType)
        .then((res) => {
          let getUrl = "/custom/add/" + res.data.body + "?";
          let getParams = "styleName=" + this.styleName + "&step=0";
          this.gotoPage(getUrl + encodeURI(getParams));
          this.sendBtnEvent("8", "SU");
          this.closePopup();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-add {
  .popup-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
  }
  &-wrap {
    position: fixed;
    z-index: 530;
    width: 100%;
    // height: calc(100% - 56px);
    overflow-y: auto;
    // padding-bottom: 70px;
    background-color: $neutral-0;
    // height: calc(100vh - 98px);
    max-width: 520px;
    max-height: 246px;
    height: calc(100vh - 150px);
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    border-radius: 8px;
    &-top {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      color: $neutral-0;
      background-color: $primary-50;
      font-size: 16px;
      font-weight: 400;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .close {
        cursor: pointer;
      }
    }
    &-body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      width: 100%;
      height: 162px;
      .style-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 43px;
        font-size: 14px;
        font-weight: 500;
        span {
          color: $primary-60;
        }
        .valid {
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          border: 1px solid $primary-20;
          background: $primary-5;
          color: $primary-60;
          font-size: 12px;
          font-weight: 500;
          &::before {
            content: "";
            position: absolute;
            bottom: 116px;
            right: 116px;
            border-width: 4px;
            border-style: solid;
            border-color: $primary-20 transparent transparent transparent;
          }
        }
      }
      input {
        // max-width: 265px;
        width: 100%;
        // height: 30px;
        height: 36px;
        padding: 6px 12px;
        background: $neutral-0;
        border-width: 0px 0px 1px 1px;
        border-style: solid;
        border-color: $neutral-20;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        ::placeholder {
          color: $neutral-20;
        }
        &:focus {
          border-color: $primary-50;
        }
        &:disabled {
          background-color: $primary-5;
        }
        &.empty {
          border-color: $secondary-60;
        }
      }
      .content {
        margin-top: 24px;
        width: 412px;
        height: 126px;
        border-radius: 4px;
        background: $neutral-3;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .logo {
          width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          img {
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }

    &-bottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1;
      &-btn {
        @media (min-width: 1290px) {
          background-color: $neutral-60;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          cursor: pointer;
        }
        background-color: $neutral-60;
        text-align: center;
        color: $neutral-0;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        cursor: pointer;
        p {
          padding: 9px;
          flex: 1;
          display: flex;
          justify-content: center;
          gap: 8px;
          background-color: $neutral-40;
          &.active {
            background-color: $primary-50;
          }
        }
        &.relative {
          position: relative;
        }
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}
</style>
