<template>
  <div
    class="payment-card"
    v-for="(cardData, i) in paymentList"
    :key="i"
    :class="paymentNo === cardData.paymentNo ? 'selected' : ''"
  >
    <div class="payment-card-wrap">
      <div class="title">
        <p>{{ cardData.compName ? cardData.compName : cardData.userName }}</p>
        <p v-if="cardData.paymentStatus == '2'" class="complete">정산 예정</p>
        <p v-if="cardData.paymentStatus == '3'" class="deactive">취소 요청</p>
        <p v-if="cardData.paymentStatus == '4'" class="cancel">취소</p>
        <p v-if="cardData.paymentStatus == '5'" class="complete">정산 예정</p>
        <p v-if="cardData.paymentStatus == '6'" class="active">완료</p>
      </div>
      <div class="info-wrap">
        <div class="info">
          <p class="name">결제일</p>
          <p class="value">
            {{ cardData.paymentDate }}
          </p>
        </div>
        <div class="info">
          <p class="name">정산(예정)일</p>
          <p class="value">
            {{
              cardData.calculateDate
                ? cardData.calculateDate
                : cardData.calculateDay
            }}
          </p>
        </div>
        <div class="info">
          <p class="name">결제 금액</p>
          <p class="value">
            {{ cardData.paymentAmount }}
          </p>
        </div>
        <div class="info">
          <p class="name">정산 금액</p>
          <p class="value" v-if="cardData.paymentStatus == '4'">0원</p>
          <p class="value" v-else>
            {{ comma(Math.round(cardData.paymentAmount * 0.962)) }}원
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentListCom",
  props: {
    paymentList: {
      type: String,
      description: "Contract Data",
    },
  },
  mounted() {},
  data() {
    return {
      compType: sessionStorage.getItem("compType"),
      isMobile: sessionStorage.getItem("isMobile"),
      paymentNo: "",
    };
  },
  methods: {
    paymentDetail(paymentNo) {
      this.paymentNo = paymentNo;
      this.$emit("paymentDetail", paymentNo);
    },
    moveService(isProgress) {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로
        this.loginCheck();
      } else if (this.$route.query.type === "load") {
        this.moveConfirm();
      } else if (isProgress) {
        this.gotoPage(`/paymentconfirm`);
        this.showToast("의뢰내역을 불러왔습니다.");
      } else if (this.compType == "1") {
        this.gotoPage(`/paymentdetails/${this.cardData.paymentNo}`);
      } else {
        this.gotoPage(`/paymentproposal/${this.cardData.paymentNo}`);
      }
    },
    moveConfirm() {
      this.$API.payment
        .paymentStyleCopy(
          sessionStorage.getItem("userId"),
          this.cardData.paymentNo
        )
        .then(() => {
          this.gotoPage(`/paymentconfirm`);
          this.showToast("의뢰내역을 불러왔습니다.");
        });
    },
    remainingTime(date) {
      try {
        // let dayOfWeek = new Date(date).getDay()
        // let alpha = 2
        // if (dayOfWeek == 5 || dayOfWeek == 6) alpha = 4
        // if (dayOfWeek == 0) alpha = 3
        // let remainingMin = (24*60*alpha) - ((new Date() - new Date(date))/1000/60)
        // return `${Math.floor(remainingMin/60)}시간 ${Math.floor(remainingMin%60)}분`
        return `${date.split(":")[0]}시간 ${date.split(":")[1]}분`;
      } catch {
        return "0분";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-card {
  position: relative;
  margin: 4px 20px 0;
  border-bottom: 1px solid $neutral-10;
  &.selected {
    background-color: $neutral-3;
  }
  &-wrap {
    cursor: pointer;
    padding: 16px 0;
    gap: 8px;
    color: $neutral-60;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      font-weight: 500;
      line-height: 23px;
      margin-bottom: 12px;
      color: $neutral-80;
      .complete {
        font-weight: 400;
        color: $neutral-60;
      }
      .active {
        font-weight: 400;
        color: $primary-60;
      }
      .cancel {
        font-weight: 400;
        color: $neutral-30;
      }
      .deactive {
        font-weight: 400;
        color: $secondary-60;
      }
    }
    .price {
      color: $primary-60;
      font-size: 15px;
      font-weight: 500;
      line-height: 23px;
    }
    .info-wrap {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .info {
      display: flex;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $neutral-80;
      .name {
        color: $neutral-60;
        font-size: 15px;
        font-weight: 500;
        line-height: 23px;
      }
      .value {
        position: absolute;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        width: 100%;
        left: 120px;
      }
    }
  }
}
.bottom {
  background-color: $neutral-3;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $neutral-80;
}
</style>
