<template>
  <div class="custom-detail">
    <div class="custom-detail-body">
      <div class="content-wrap">
        <p>주문 수량</p>

        <div class="col">
          <div class="input-wrap">
            <div class="input-wrap-flex">
              <p class="unit"></p>
              <p class="unit">S</p>
              <p class="unit">M</p>
              <p class="unit">L</p>
              <p class="unit">XL</p>
              <p class="unit"></p>
              <p class="unit"></p>
              <p class="unit">계</p>
            </div>
            <div class="input-wrap-flex">
              <div class="unit">
                <p class="color" style="background-color: #ffffff"></p>
              </div>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size11"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size12"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size13"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size14"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit"></p>
              <p class="unit"></p>
              <p class="unit red">
                {{ size11 * 1 + size12 * 1 + size13 * 1 + size14 * 1 }}
              </p>
            </div>
            <div class="input-wrap-flex">
              <div class="unit">
                <p class="color" style="background-color: #999999"></p>
              </div>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size21"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size22"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size23"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size24"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit"></p>
              <p class="unit"></p>
              <p class="unit red">
                {{ size21 * 1 + size22 * 1 + size23 * 1 + size24 * 1 }}
              </p>
            </div>
            <div class="input-wrap-flex">
              <div class="unit">
                <p class="color" style="background-color: #000000"></p>
              </div>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size31"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size32"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size33"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit white">
                <input
                  class="txt"
                  v-model="size34"
                  @change="changeCount"
                  @keyup="changeCount"
                />
              </p>
              <p class="unit"></p>
              <p class="unit"></p>
              <p class="unit red">
                {{ size31 * 1 + size32 * 1 + size33 * 1 + size34 * 1 }}
              </p>
            </div>
            <div class="input-wrap-flex">
              <p class="unit">계</p>
              <p class="unit red">{{ size11 * 1 + size21 * 1 + size31 * 1 }}</p>
              <p class="unit red">{{ size12 * 1 + size22 * 1 + size32 * 1 }}</p>
              <p class="unit red">{{ size13 * 1 + size23 * 1 + size33 * 1 }}</p>
              <p class="unit red">{{ size14 * 1 + size24 * 1 + size34 * 1 }}</p>
              <p class="unit red"></p>
              <p class="unit red"></p>
              <p class="unit red">
                {{
                  size11 * 1 +
                  size21 * 1 +
                  size31 * 1 +
                  size14 * 1 +
                  size12 * 1 +
                  size22 * 1 +
                  size32 * 1 +
                  size13 * 1 +
                  size23 * 1 +
                  size33 * 1 +
                  size24 * 1 +
                  size34 * 1
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="content-wrap">
        <p>주문 정보</p>
        <div class="row flex-start">
          <div class="content-title-div">
            <p class="content-title">수령인</p>
          </div>
          <div class="input-wrap gap0">
            <input v-model="userName" placeholder="수령인을 입력해주세요." />
          </div>
        </div>
        <div class="row flex-start">
          <div class="content-title-div">
            <p class="content-title">연락처</p>
          </div>
          <div class="input-wrap gap0">
            <input v-model="telNum" placeholder="연락처를 입력해주세요." />
          </div>
        </div>
        <div class="row flex-start">
          <div class="content-title-div">
            <p class="content-title">배송지</p>
          </div>
          <div class="input-wrap gap0">
            <input
              v-model="address"
              @click="getAddress('address')"
              placeholder="주소를 검색해주세요."
            />
            <input
              v-model="addressDetail"
              class="mt-8"
              placeholder="연락처를 입력해주세요."
            />
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="content-wrap">
        <p>결제 정보</p>
        <div class="row-between">
          <p class="left">금액</p>
          <p class="right">10,000 원</p>
        </div>
        <div class="row-between">
          <p class="left">총 수량</p>
          <p class="right">{{ totalCount }} 장</p>
        </div>
        <div class="row-between">
          <p class="left">배송비</p>
          <p class="right">8,000 원</p>
        </div>
        <div class="row-between">
          <p class="left red">결제 금액</p>
          <p class="right">{{ comma(totalCount * 10000 + 8000) }} 원</p>
        </div>
      </div>

      <div class="line"></div>
      <div class="content-wrap">
        <div class="row">
          <div class="content-title-div">
            <p class="content-title">결제방법 선택</p>
          </div>
        </div>
        <div class="row">
          <div class="btns-wrap">
            <div
              @click="nextStep('CARD')"
              class="select-btn-box"
              :class="paymentType == 'CARD' ? 'active' : ''"
            >
              신용카드
            </div>
            <div
              @click="nextStep('TRANSFER')"
              class="select-btn-box"
              :class="paymentType == 'TRANSFER' ? 'active' : ''"
            >
              계좌 이체
            </div>
            <div
              @click="nextStep('MOBILE_PHONE')"
              class="select-btn-box"
              :class="paymentType == 'MOBILE_PHONE' ? 'active' : ''"
            >
              휴대폰 결제
            </div>
            <div
              @click="nextStep('TOSSPAY')"
              class="select-btn-box"
              :class="paymentType == 'TOSSPAY' ? 'active' : ''"
            >
              토스 페이
            </div>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="content-wrap">
        <div class="payment-final-bottom">
          <div class="terms">
            <div class="agree">
              <div class="agree-row">
                <input
                  @change="clickAgree(0)"
                  type="checkbox"
                  id="agree1"
                  class="pointer"
                  v-model="agree[0]"
                />
                <label class="pointer" for="agree1"></label>
                <p
                  class="all"
                  @click="
                    agree[0] = !agree[0];
                    clickAgree(0);
                  "
                >
                  전체 동의
                </p>
              </div>

              <div class="agree-row">
                <input
                  @change="clickAgree(2)"
                  type="checkbox"
                  id="agree3"
                  class="pointer"
                  v-model="agree[2]"
                />
                <label class="pointer" for="agree3"></label>
                <p
                  class="pointer wd100 space-between"
                  @click="agree[2] = !agree[2]"
                >
                  개인정보 수집 및 이용에 대한 동의
                  <span @click="gotoPage('/terms/privacypolicy')">[보기]</span>
                </p>
              </div>
              <div class="agree-row">
                <input
                  @change="clickAgree(1)"
                  type="checkbox"
                  id="agree2"
                  class="pointer"
                  v-model="agree[1]"
                />
                <label class="pointer" for="agree2"></label>
                <p
                  class="pointer wd100 space-between"
                  @click="agree[1] = !agree[1]"
                >
                  이용 약관 동의
                  <span @click="gotoPage('/terms/termsofservice')">[보기]</span>
                </p>
              </div>
              <div class="agree-row">
                <input
                  @change="clickAgree(3)"
                  type="checkbox"
                  id="agree3"
                  class="pointer"
                  v-model="agree[3]"
                />
                <label class="pointer" for="agree3"></label>
                <p class="pointer wd100" @click="agree[3] = !agree[3]">
                  구매 동의
                </p>
              </div>
            </div>
            <div class="agree-content">
              <p>
                100% 주문제작 상품으로써, 취소 및 환불은 제작 준비 중일때만
                가능하며, 배송 완료 후 단순 변심에 의한 교환/반품은 불가합니다.
              </p>
              <p>
                상표 및 디자인 도용, 저작권이 있는 디자인의 제작 의뢰를 절대
                금지하며, 고객님이 판매 목적으로 이를 어길 시, 저작권 위반으로
                발생되는 모든 사항에 대하여 오슬에서는 책임지지 않습니다.
              </p>
              <p>결제 전, 주문내용을 반드시 확인해주시기 바랍니다.</p>
              <p>구매에 동의하시겠습니까? (전자상거래법 제8조 2항)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-detail-bottom">
      <p
        class="custom-detail-bottom-btn"
        :class="agree[1] && agree[2] && agree[3] ? 'active' : ''"
        @click="payment()"
      >
        결제 하기
      </p>
    </div>
  </div>
</template>

<script>
import { loadTossPayments } from "@tosspayments/payment-sdk";
export default {
  name: "CustomAdd",
  props: {
    step: {
      type: String,
      description: "step text",
    },
  },
  data() {
    return {
      selectedColor: [],
      careLabelList: [],
      mainLabelList: [],
      descList: [],

      printList: [],
      apiData: {},
      errorData: {},
      paymentType: "",
      sampleFlag: "",
      userName: "",
      telNum: "",
      email: "",
      agree: [true, true, true, true],
      price: 110000,
      charge: "",
      paymentAmount: "",
      paytype: "",
      depositFlag: "",
      isMobile: sessionStorage.getItem("isMobile"),
      address: "",
      areaCd: "",
      area: "",
      size11: "",
      size12: "",
      size13: "",
      size14: "",
      size21: "",
      size22: "",
      size23: "",
      size24: "",
      size31: "",
      size32: "",
      size33: "",
      size34: "",
      totalCount: 0,
    };
  },
  mounted() {},
  methods: {
    changeCount() {
      this.totalCount =
        this.size11 * 1 +
        this.size21 * 1 +
        this.size31 * 1 +
        this.size14 * 1 +
        this.size12 * 1 +
        this.size22 * 1 +
        this.size32 * 1 +
        this.size13 * 1 +
        this.size23 * 1 +
        this.size33 * 1 +
        this.size24 * 1 +
        this.size34 * 1;
    },
    clickAgree(i) {
      if (i === 0) {
        this.agree[1] = this.agree[0];
        this.agree[2] = this.agree[0];
        this.agree[3] = this.agree[0];
      } else {
        this.agree[0] = this.agree[1] && this.agree[2] && this.agree[3];
      }
    },
    nextStep(val) {
      this.paymentType = val;
    },
    getAddress() {
      new window.daum.Postcode({
        oncomplete: (res) => {
          this.address = res.roadAddress;
          this.areaCd = res.sigunguCode;
          this.area = res.sigungu;
        },
      }).open();
    },
    payment() {
      this.paymentAmount = this.totalCount * 10000 + 8000;
      if (
        this.agree[1] &&
        this.agree[2] &&
        this.agree[3] &&
        this.paymentAmount
      ) {
        if (this.paytype == "2") {
          this.$API.payment
            .createTossMatching(
              sessionStorage.getItem("userId"),
              this.itemId,
              this.paymentType
            )
            .then((res) => {
              console.log(res);
              if (res.data.resultCode == "10000") {
                let d = res.data.body;
                loadTossPayments(d.clientKey).then((tossPayments) => {
                  tossPayments.requestPayment(this.paymentType, {
                    // 결제 수단 파라미터
                    contractNo: this.contractNo,
                    amount: d.paymentAmount,
                    orderId: d.orderId,
                    orderName: d.itemName,
                    customerName: sessionStorage.getItem("userName"),
                    successUrl: d.successUrl,
                    failUrl: d.failUrl,
                  });
                });
              } else {
                this.setMsgPopup(
                  true,
                  "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                );
              }
            });
        } else {
          console.log(this.paymentType);
          this.$API.payment
            .createToss(
              sessionStorage.getItem("userId"),
              "스타일명",
              this.paymentAmount,
              this.paymentType
            )
            .then((res) => {
              console.log(res);
              if (res.data.resultCode == "10000") {
                let d = res.data.body;
                console.log(d);
                loadTossPayments(d.clientKey).then((tossPayments) => {
                  tossPayments.requestPayment(this.paymentType, {
                    // 결제 수단 파라미터
                    amount: d.paymentAmount,
                    orderId: d.orderId,
                    orderName: d.itemName,
                    customerName: sessionStorage.getItem("userName"),
                    successUrl: d.successUrl,
                    failUrl: d.failUrl,
                  });
                });
              } else {
                this.setMsgPopup(
                  true,
                  "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                );
              }
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-detail {
  @include set-width(false, 520px);
  @media (min-width: 501px) {
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: $neutral-10;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  }
  position: relative;
  max-width: 400px;
  padding: 0 20px;
  &-body {
    margin-top: 60px;
    .line {
      margin: 20px 0;
      border-bottom: 1px solid $primary-20;
    }
    .content-title-div {
      flex: 1;
      display: flex;
    }
    .content-title {
      font-weight: 500;
      font-size: 14px;
      color: $neutral-variation-80;
      span {
        color: $primary-60;
      }
    }
    .input-wrap {
      margin-top: 12px;
      position: relative;
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      &-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 8px;
        .unit {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          width: 72px;
          height: 38px;
          background: $neutral-3;
          border: 1px solid $neutral-20;
          &-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border: 1px solid $neutral-10;
            &-color {
              width: 24px;
              height: 24px;
            }
          }
          .color {
            width: 20px;
            height: 20px;
            border: 1px solid $neutral-10;
            border-radius: 10px;
          }
          &.red {
            background-color: $neutral-0;
            border: 1px solid $primary-50;
          }
          &.white {
            background-color: $neutral-0;
          }
          .txt {
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            background: $neutral-0;
            border: 0;
          }
        }
        .deviation {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          width: 40px;
          height: 38px;
          background: $neutral-3;
          border: 1px solid $neutral-20;
          border-radius: 4px;
        }
        .size {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 8px;
          input {
            background: $neutral-0;
          }
        }
        .input {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 38px;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: $neutral-30;
          background: $neutral-3;
          border: 1px solid $neutral-20;
          border-radius: 4px;
        }
      }
      input {
        padding: 8px;
        background: #ffffff;
        border-width: 0px 0px 1px 1px;
        border-style: solid;
        border-color: $neutral-30;
        border-radius: 4px;
        width: 100%;
        height: 32px;

        &:focus {
          border-color: $primary-50;
        }
        &:hover {
          background: #fffbf2;
        }
      }
      &.row {
        flex-direction: row !important;
      }
      .input-explain {
        font-size: 14px;
        color: $neutral-60;
      }
      &.error input {
        border-color: $secondary-60;
      }

      textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid $neutral-20;
        border-radius: 4px;
        height: 120px;
        resize: none;
        font-size: 14px;
        &::placeholder {
          color: $neutral-30;
        }
      }
    }
    .content-wrap {
      // margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .row {
        display: flex;
      }
      .row-between {
        display: flex;
        justify-content: space-between;
        .left {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          &.red {
            color: $primary-60;
          }
        }
        .right {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    .btns-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      .select-btn-box {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 46px;
        border: 1px solid $neutral-20;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        &.active {
          color: $primary-60;
          border: 1px solid $primary-50;
        }
      }
    }
    .terms {
      .agree {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-row {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: left;
          font-size: 14px;
          color: $neutral-80;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          .all {
            cursor: pointer;
            font-weight: 500;
            color: $neutral-80;
          }
          input[type="checkbox"] {
            display: none;
          }
          label {
            margin-right: 12px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url("@/assets/icon/ic-check-off.svg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          input[type="checkbox"]:checked + label {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url("@/assets/icon/ic-check-on-yellow.svg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          span {
            color: $primary-60;
          }
        }
      }
      .agree-content {
        margin-top: 12px;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $neutral-60;
      }
    }
  }
  &-bottom {
    z-index: 1;
    position: absolute;
    max-width: 400px;
    width: 100%;
    left: 0;
    @include set-bottom();
    &-btns {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-btn {
      flex: 1;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      padding: rem(12.5px);
      color: $neutral-0;
      background-color: $neutral-30;
      cursor: pointer;
      &.active {
        background-color: $primary-50;
      }
    }
  }
}
</style>
