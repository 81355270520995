<!-- <template>
  <div class="paging-wrap" :class="totalCount > perPage ? 'pb-40' : ''">
    <div class="paging" v-if="totalCount > perPage">
      <p class="before pointer" @click="clickPage('before')">이전</p>
      <p class="pointer" @click="clickPage(x)" v-for="(x, i) in btnList" :key="i" :class="x === selectPageIndex ? 'now' : ''">{{ x }}</p>
      <p class="next pointer" @click="clickPage('next')">다음</p>
    </div>
  </div>
</template> -->
<!-- 2023.04.27 변경 -->
<template>
  <div class="paging-wrap" :class="totalCount > perPage ? 'pb-40' : ''">
    <div class="paging" v-if="totalCount > perPage">
      <p
        class="before pointer"
        @click="clickPage('before')"
        :class="selectPageIndex == 1 ? '' : 'active'"
      >
        <SvgImg
          :size="20"
          :d="$store.state.meta.svg.pagingBefore"
          :color="
            selectPageIndex == 1
              ? $store.state.meta.svg.color.neutral20
              : $store.state.meta.svg.color.neutral40
          "
        />
      </p>
      <p
        class="number pointer"
        @click="clickPage(x)"
        v-for="(x, i) in btnList"
        :key="i"
        :class="x == selectPageIndex ? 'now' : ''"
      >
        {{ x }}
      </p>
      <p
        class="next pointer"
        @click="clickPage('next')"
        :class="electPageIndex == Math.ceil(totalCount / 10) ? '' : 'active'"
      >
        <SvgImg
          :size="20"
          :d="$store.state.meta.svg.pagingNext"
          :color="
            selectPageIndex == Math.ceil(totalCount / 10)
              ? $store.state.meta.svg.color.neutral20
              : $store.state.meta.svg.color.neutral40
          "
        />
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "pagingView",
  props: {
    pageType: {
      type: String,
      description: "Card data",
    },
    totalCount: {
      type: Number,
      description: "Card data",
    },
    perPage: {
      type: Number,
      description: "Card data",
    },
    pageNow: {
      type: Number,
      description: "Card data",
    },
  },
  watch: {
    totalCount(nv) {
      this.btnUpdate(nv);
    },
    pageNow(nv) {
      console.log(nv);
      this.selectPageIndex = nv;
      this.clickPage(nv);
    },
  },
  data() {
    return {
      selectPageIndex: 1,
      btnList: [],
    };
  },
  mounted() {
    this.clickPage(this.pageNow);
    this.btnUpdate();
  },
  methods: {
    clickPage(i) {
      console.log("aaaa");
      console.log(i);
      if (this.totalCount === 0) return;
      if (i === "before") {
        this.selectPageIndex -= 5;
        this.selectPageIndex =
          this.selectPageIndex > 0 ? this.selectPageIndex : 1;
      } else if (i === "next") {
        this.selectPageIndex += 5;
        let totalPage =
          Math.floor(this.totalCount / this.perPage) +
          (this.totalCount % this.perPage !== 0 ? 1 : 0);
        this.selectPageIndex =
          this.selectPageIndex > totalPage ? totalPage : this.selectPageIndex;
      } else {
        this.selectPageIndex = i;
      }

      this.btnUpdate();

      this.$emit("changePage", this.selectPageIndex, this.pageType);
    },
    btnUpdate(c) {
      let totalCount = c ? c : this.totalCount;
      let totalPage =
        Math.floor(totalCount / this.perPage) +
        (totalCount % this.perPage !== 0 ? 1 : 0);
      Math.floor(this.selectPageIndex / this.perPage) +
        (totalCount % this.perPage !== 0 ? 1 : 0);
      let startPage = Math.floor((this.selectPageIndex - 1) / 5) * 5 + 1;
      let endPage = startPage + 4;
      if (endPage >= totalPage) endPage = totalPage;

      this.btnList = [];
      for (let i = startPage; i <= endPage; i++) {
        this.btnList.push(i);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paging-wrap {
  padding-top: rem(32px);
}
.paging {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 16px;
  font-weight: 400;
  color: $neutral-50;
  p {
    width: 20px;
    padding: 2px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $neutral-0;
  }
  .now {
    color: $primary-50;
    font-weight: 500;
    // color: $neutral-0;
    // background-color: $primary-50;
    // border-radius: 16px;
    &:hover {
      border-bottom: 1px solid $neutral-0 !important;
    }
  }
  .number {
    &:hover {
      // text-decoration: underline;
      border-bottom: 1px solid $primary-20;
    }
  }
  .before {
    color: $neutral-40;
    &.active {
      &:hover {
        border-bottom: 1px solid $primary-20;
      }
    }
  }
  .next {
    color: $neutral-40;
    &.active {
      &:hover {
        border-bottom: 1px solid $primary-20;
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
