<template>
  <div class="alarm-detail" v-if="!alarmDetail">
    <div class="alarm-detail-top">
      <div class="flex space-between">
        <p class="type">{{ alarmDetail.alarmType == "1" ? "공지사항" : "" }}</p>
        <p class="date">{{ alarmDetail.createdDate }}</p>
      </div>
      <p class="title mt-20">{{ alarmDetail.title }}</p>
    </div>
    <div class="alarm-detail-body">
      <p>{{ alarmDetail.content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlarmListComp",
  props: {
    alarmDetail: {
      type: Object,
      description: "Contract Data",
    },
  },
  data() {
    return {
      filters: {
        read: [
          { name: "전체", cd: "" },
          { name: "읽지않음", cd: "0" },
        ],
        state: [
          { name: "전체", cd: "" },
          { name: "매칭", cd: "1" },
          { name: "메시지", cd: "2" },
          { name: "발주", cd: "3" },
        ],
      },
      sortOrder: 0,
      readingFlag: "",
      noticeStatus: "",
      searchText: "",
      alarmData: [],
      noticeDetail: {},
      pageNow: 0,
      pageList: 20,
      isLoading: true,
      totalCount: 0,
    };
  },
  mounted() {
    this.updateNoticeList(true);
    this.setPopup(false);
    document.addEventListener("scroll", this.infinityCheck);
  },
  methods: {
    readAll() {
      this.$API.mypage
        .alarmReadAll(sessionStorage.getItem("userId"))
        .then((res) => {
          this.updateNoticeList(true);
        });
    },
    updateNoticeList(isReset) {
      console.log(this.searchText);
      this.isLoading = true;
      if (isReset) {
        this.pageNow = 0;
      }
      this.pageNow++;
      this.$API.mypage
        .getAlarmList(
          sessionStorage.getItem("userId"),
          this.noticeStatus,
          this.readingFlag,
          this.searchText,
          this.pageNow,
          this.pageList
        )
        .then((res) => {
          console.log(res);
          if (res.data.resultCode == "10000") {
            if (isReset) {
              this.alarmData = res.data.body.resAlarmList;
              this.totalCount = res.data.body.totalCount;
            } else {
              res.data.body.resNoticeList.forEach((e) => {
                this.alarmData.push(e);
              });
            }
          } else {
            if (isReset) {
              this.alarmData = [];
              this.totalCount = 0;
            }
          }
          this.isLoading = false;
        });
    },
    alarmRead(i) {
      if (this.alarmData[i].readingFlag != "1") {
        this.alarmData[i].readingFlag = "1";
        this.$API.mypage
          .postAlarmRead(
            sessionStorage.getItem("userId"),
            this.alarmData[i].alarmNo
          )
          .then(() => {
            this.$API.mypage
              .alarmCheck(sessionStorage.getItem("userId"))
              .then((res) => {
                if (this.alarmData[i].alarmType == "1") {
                  this.$emit(
                    "goAlarmeDetail",
                    this.alarmData[i].alarmType,
                    this.alarmData[i].title,
                    this.alarmData[i].content,
                    this.alarmData[i].createdDate
                  );
                } else {
                  this.gotoPage(this.alarmData[i].alarmUrl);
                }
              });
          });
      }
    },
    goNoticeDetail(i, noticeNo, inquireNo) {
      if (inquireNo) {
        sessionStorage.setItem("inquireNo", inquireNo);
        this.gotoPage("/mypage/inquirelist");
      } else {
        this.$API.mypage
          .getNoticeDetail(sessionStorage.getItem("userId"), noticeNo)
          .then((res) => {
            let d = res.data.body;
            d.noticeContents = d.noticeContents?.replace(/\n/g, "<br>");
            this.noticeDetail = d;
            this.setPopup(true, "noticeDetail", "알림 상세내용");
          });
      }
      if (this.alarmData[i].readingFlag != "1") {
        this.alarmData[i].readingFlag = "1";
        this.$API.mypage
          .postNoticeRead(sessionStorage.getItem("userId"), noticeNo)
          .then(() => {
            this.$API.mypage
              .noticeCheck(sessionStorage.getItem("userId"))
              .then((res) => {
                if (res.data.body == 1)
                  this.$store.state.meta.showNotiIcon = true;
                else this.$store.state.meta.showNotiIcon = false;
              });
          });
      }
    },
    selectData(data, type) {
      if (type === "state") {
        this.noticeStatus = data.cd;
      } else if (type === "reading") {
        this.readingFlag = data.cd;
      }
      this.updateNoticeList(true);
    },
    infinityCheck() {
      if (this.isLoading) return;
      if (Math.ceil(this.totalCount / this.pageList) <= this.pageNow) return;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.updateNoticeList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alarm-detail {
  width: 100%;
  max-width: 520px;
  // @include set-height();
  display: flex;
  flex-direction: column;
  &-top {
    border-bottom: 1px solid $primary-20;
    margin: 60px 20px 0;
    padding-bottom: 20px;
    .type {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $neutral-80;
    }
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: $neutral-80;
    }
    .date {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-60;
    }
  }
  &-body {
    padding: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $neutral-80;
  }
}
</style>
