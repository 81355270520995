<template>
  <div class="point-variation"
    :style="`width:${width};height:${height}`"
  >
    <div v-for="(x, i) in pointData" :key="i" class="box"
      :style="`background-color:${x.color}` + (i !== 0 ? `;margin-left:${ml}px` : '')"/>
  </div>
</template>
<script>
export default {
  name: 'PointVariation',
  props: {
    width: {
      type: String,
      description: "width"
    },
    height: {
      type: String,
      description: "height"
    }
  },
  data () {
    return {
      pointData: [
        { color: '#48DBFB' },
        { color: '#359CEF' },
        { color: '#6940CC' },
        { color: '#8C29CC' },
        { color: '#A740E2' },
      ],
      ml: 0
    }
  },
  mounted () {
    this.ml = (this.width.split('px')[0] / this.pointData.length) * 0.09
  }
}
</script>

<style lang="scss" scoped>
  .point-variation {
    display: flex;
    .box {
      flex: 1;
      height: 100%;
    }
  }
</style>