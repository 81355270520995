<template>
  <div class="select-box" id="selectBox">
    <div
      class="select-box-value pointer"
      :class="showSelectBoxList ? 'active' : ''"
      @click="toggleShowList()"
    >
      <p
        v-if="selectDataTxt"
        class="select-box-value-txt"
        :class="placeholderTxt ? 'placeholder' : ''"
      >
        {{ selectDataTxt }}
      </p>
      <img
        :src="
          require(`@/assets/icon/ic-arrowdrop${
            showSelectBoxList ? '-up' : '-down'
          }.svg`)
        "
      />
    </div>
    <ul class="select-box-list" :class="showSelectBoxList ? '' : 'notshow'">
      <li
        v-for="(x, i) in data"
        :key="i"
        class="select-box-list-item pointer"
        @click="selectDataUpdate(x, i)"
        :class="
          selectDataTxt == x.name ||
          selectDataTxt == x.commName ||
          selectDataTxt == x.catgName
            ? 'active'
            : ''
        "
      >
        {{ x.name ? x.name : "" }}
        {{ x.commName ? x.commName : "" }}
        {{ x.catgName ? x.catgName : "" }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SelectBoxMall",
  props: {
    data: {
      type: Object,
      description: "select box data",
    },
    selectData: {
      type: String,
      description: "selected data",
    },
    placeholderTxt: {
      type: String,
      description: "placeholder txt",
    },
    filterType: {
      type: String,
      description: "type txt",
    },
    index: {
      type: Number,
      description: "type int",
    },
  },
  watch: {
    selectData(newVal) {
      console.log(newVal);
      this.selectDataTxt = newVal;
    },
  },
  mounted() {
    this.selectDataTxt = this.selectData;
    // document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  data() {
    return {
      showSelectBoxList: false,
      selectDataTxt: "",
    };
  },
  methods: {
    handleClickOutside(event) {
      let myElement = document.getElementById("selectBox");
      if (!myElement.contains(event.target)) {
        console.log("컴포넌트 외부가 클릭되었습니다.");
        // 원하는 작업을 수행하세요.
        this.showSelectBoxList = false;
      }
    },
    toggleShowList() {
      this.showSelectBoxList = !this.showSelectBoxList;
    },
    selectDataUpdate(d, i) {
      if (d.name) {
        this.selectDataTxt = d.name;
      }
      if (d.commName) {
        this.selectDataTxt = d.commName;
      }
      if (d.catgName) {
        this.selectDataTxt = d.catgName;
      }
      console.log(this.selectDataTxt);
      this.showSelectBoxList = false;
      let data = d;
      d["i"] = i;
      this.$emit("selectData", data, this.filterType, this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  position: relative;
  border-bottom: 1px solid $neutral-10;
  width: 100%;
  background-color: $neutral-0;
  &:hover {
    border-bottom: 1px solid $primary-20;
  }
  &-value {
    width: 100%;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    &-txt {
      width: 100%;
      color: $neutral-80;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      flex: 1;
      &.placeholder {
        color: $neutral-30;
      }
    }
    img {
      width: 16px;
    }
    &.active {
    }
  }
  &-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 400px;
    z-index: 1;
    background-color: $neutral-0;
    overflow: hidden;
    border-top: 1px solid $primary-50;
    border-left: 1px solid $neutral-10;
    border-right: 1px solid $neutral-10;
    border-bottom: 1px solid $neutral-10;
    border-radius: 0px 0px 4px 4px;

    &-item {
      height: auto;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      color: $neutral-60;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      &:hover,
      &.active {
        background-color: $primary-5;
      }
    }
    &.notshow {
      max-height: 0;
      display: none;
    }
  }
}
</style>
