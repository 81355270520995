<template>
  <div class="custom-preview">
    <div class="custom-preview-body">
      <div class="content-wrap" v-if="step == 0">
        <div class="select-color">
          <div class="select-color-title active" v-if="fabricColorList">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.checkIcon"
              :color="$store.state.meta.svg.color.primary50"
            />
            <p>선택 원단</p>
          </div>
          <div class="select-color-title" v-else>
            <SvgImg
              :size="16"
              :d="$store.state.meta.svg.radioOn"
              :color="$store.state.meta.svg.color.primary50"
            />
            <p>선택 원단</p>
          </div>
          <div class="select-color-wrap" v-if="fabric.fabricName">
            <div class="select-color-imgs">
              <div class="select-color-imgs-title">{{ fabric.fabricName }}</div>
              <div class="select-color-area">
                <!-- @click="toggleColor(i)" -->
                <div
                  v-for="(color, i) in fabricColorList"
                  :key="i"
                  class="select-color-imgs-wrap"
                >
                  <div class="select-color-imgs-img">
                    <img :src="color.fileUrl" />
                  </div>
                  <div class="select-color-imgs-content">
                    <p>{{ color.colorCode }}</p>
                    <p>{{ color.colorName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="select-color" v-if="colorList?.length > 0">
          <div class="select-color-btn" @click="isColorPopup = true">
            컬러 선택
          </div>
          <div class="select-color-imgs">
            <div
              class="select-color-imgs-wrap"
              v-for="(color, i) in fabricColorList.filter((e) => e.isActive)"
              :key="i"
              @click="toggleColor(i)"
            >
              <div class="select-color-imgs-img">
                <img :src="color.fileUrl" />
              </div>
              <div class="select-color-imgs-content">
                <p>{{ color.colorCode }}</p>
                <p>{{ color.colorName }}</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="select-desc">
          <div class="row" v-for="(desc, i) in descList" :key="i">
            <div class="content-title">
              <p class="content-title-div">
                <img src="@/assets/icon/ic-help-circle.svg" />
                {{ desc.descTitle }}
              </p>
              <p class="fabric-desc">
                {{ desc.descContent }}
              </p>
            </div>
          </div>
          <div class="desc-imgs">
            <div class="desc-imgs-wrap" v-for="(file, i) in fileList" :key="i">
              <div class="desc-imgs-img">
                <img :src="file.fileUrl" />
              </div>
              <div class="desc-imgs-name" v-if="file?.fileName">
                {{ file?.fileName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrap" v-if="step == 1">
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />Size Info
            </p>
          </div>
          <div class="">
            <div class="imgs">
              <div>
                <div class="imgs-img view pointer">
                  <img src="@/assets/img/img-custom-preview-size.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />‘가슴 단면’ & ‘소매
              기장’ 사이즈
            </p>
          </div>
          <div class="img-wrap">
            <img src="@/assets/img/sleeve-length-img.png" />
          </div>
        </div>
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />‘어깨 단면’ & ‘총
              기장’ 사이즈
            </p>
          </div>
          <div class="img-wrap">
            <img src="@/assets/img/total-length-img.png" />
          </div>
        </div>
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />‘포켓’ 사이즈
            </p>
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div v-for="(x, i) in pocketSizeImg" :key="i">
                <div class="imgs-img view pointer">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />사이즈 편차 /
              그레이딩이란 !?
            </p>
            <p class="fabric-desc">
              사이즈 편차 / 그레이딩은 사이즈 간의 부위 별 치수나 핏의 차이 등을
              말하며, 브랜드나 원단 사양, 봉제 기술, 디테일, 스타일 등 다양한
              변수에 따라 다르지만 옷이 전체적으로 균형감 있도록 각 부위 별,
              치수를 조정하여 다양한 사이즈를 품질의 일관성이 유지될 수 있도록
              생성하는 것을 말합니다.
            </p>
          </div>
        </div> -->
      </div>

      <div class="content-wrap" v-if="step == 2">
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />립, 바인딩의 특징은
              !?
            </p>
            <p class="etc-desc">
              <span>립 마감</span>은 신축성 있는 골지 원단인 립으로 마감하는
              것을 말하며, 핏한 느낌을 제공하고, 캐주얼 및 기본 티셔츠 디자인에
              많이 활용됩니다.
            </p>
            <p class="etc-desc">
              <span>바인딩 마감</span>은 원단을 덮어 마감하는 것을 말하며,
              세탁으로 인한 늘어남을 방지하고, 내구성이 높고 깔끔한 느낌의
              마감입니다.
            </p>
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div v-for="(x, i) in bindingImg" :key="i">
                <div class="imgs-img view pointer">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <p class="imgs-title" v-html="x.fileName"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />해리 작업이란 !?
            </p>
            <p class="etc-desc">
              <span>해리 작업</span>은 뒤 안쪽 네크라인의 시접 부분을 제
              원단이나 테이프로 덧박아주는 작업으로써 시접 실밥이 보이지 않아,
              깔끔하고 기능적으로 목늘어짐을 방지합니다.
            </p>
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div v-for="(x, i) in harryImg" :key="i">
                <div class="imgs-img view pointer">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <div class="imgs-title">
                  <p
                    v-for="(y, j) in x.fileName"
                    :key="j"
                    :class="{ active: j == 1, deactive: j == 0 }"
                  >
                    {{ y }}&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />각 스티치의 특징은!?
            </p>
            <p class="etc-desc">
              <span>본봉</span>은 가장 일반적인 봉제 기법으로 한 쪽으로 박아주는
              스티치이며, 값이 저렴합니다.
            </p>
            <p class="etc-desc">
              <span>갈라삼봉</span>은 시접을 중심에 두고 양 쪽으로 갈라서
              박아주는 봉제 기법으로 보다 튼튼하게 박음질을 유지할 수 있습니다.
            </p>
            <p class="etc-desc">
              <span>체인 스티치</span>는 체인형으로 엮어 장식성이나 강도를
              보강한 봉제 기업입니다.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />소매단 !?
            </p>
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div v-for="(x, i) in sleeve" :key="i">
                <div class="imgs-img view pointer">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <p class="imgs-title" v-html="x.fileName"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />포켓’ 사이즈
            </p>
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div v-for="(x, i) in pocketSizeImg" :key="i">
                <div class="imgs-img view pointer">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <p class="imgs-title" v-html="x.fileName"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="content-title">
            <p class="content-title-div">
              <img src="@/assets/icon/ic-help-circle.svg" />옆 트임은!?
            </p>
            <p class="etc-desc">
              <span>옆 트임</span>은 이너 티셔츠 착용으로 레이어드 시,
              스타일링에 포인트를 줄 수 있습니다.
            </p>
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div v-for="(x, i) in sideSlitImg" :key="i">
                <div class="imgs-img view pointer">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <div class="imgs-title">
                  <p
                    v-for="(y, j) in x.fileName"
                    :key="j"
                    :class="{ active: j == 1, deactive: j == 0 }"
                  >
                    {{ y }}&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-wrap" v-if="step == 3"></div>
    </div>
    <div class="custom-preview-bottom">
      <div class="custom-preview-bottom-btns">
        <p class="custom-preview-bottom-btn">개별 공급단가</p>
        <p class="custom-preview-bottom-btn">{{ unitPrice }} 원</p>
      </div>
    </div>
  </div>

  <div v-if="isColorPopup" class="popup-color">
    <div class="popup-background" @click="isColorPopup = false"></div>
    <div class="popup-color-wrap">
      <div class="popup-color-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.colorIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>컬러 선택</p>
        </div>
        <SvgImg
          :size="20"
          :d="$store.state.meta.svg.closePopup"
          :color="$store.state.meta.svg.color.neutral0"
          @click="isColorPopup = false"
        />
      </div>
      <div class="popup-color-wrap-body">
        <div class="color-imgs">
          <div
            class="color-imgs-wrap"
            v-for="(color, i) in fabricColorList"
            :key="i"
            :class="{ active: fabricColorList[i].isActive }"
          >
            <div class="color-imgs-img">
              <img :src="color.fileUrl" />
            </div>
            <div class="color-imgs-content">
              <p>{{ color.colorCode }}</p>
              <p>{{ color.colorName }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-color-wrap-bottom">
        <div class="popup-color-wrap-bottom-btn pointer">
          <p class="active" @click="isColorPopup = false">확인</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomAdd",
  props: {
    moveStep: {
      type: String,
      description: "btn text",
    },
    fabric: {
      type: Object,
      description: "list data",
    },
    colorList: {
      type: Object,
      description: "list data",
    },
    descList: {
      type: Object,
      description: "list data",
    },
    fileList: {
      type: Object,
      description: "list data",
    },
    unitPrice: {
      type: Object,
      description: "list data",
    },
  },
  data() {
    return {
      isColorPopup: false,
      sleeve: [
        {
          index: 0,
          isActive: false,
          fileName: "삼봉",
          fileUrl: "preview-sleeve-img1.png",
        },
        {
          index: 1,
          isActive: false,
          fileName: "립",
          fileUrl: "preview-sleeve-img2.png",
        },
        {
          index: 2,
          isActive: false,
          fileName: "바인딩",
          fileUrl: "preview-sleeve-img3.png",
        },
      ],
      bindingImg: [
        {
          index: 0,
          isActive: false,
          fileUrl: "binding-img1.png",
          fileName: "립",
        },
        {
          index: 1,
          isActive: false,
          fileUrl: "binding-img2.png",
          fileName: "바인딩",
        },
      ],
      harryImg: [
        {
          index: 0,
          isActive: false,
          fileUrl: "harry-img1.png",
          fileName: ["해리", "없음"],
          active: false,
        },
        {
          index: 1,
          isActive: false,
          fileUrl: "harry-img2.png",
          fileName: ["해리", "있음"],
          active: true,
        },
      ],
      sideSlitImg: [
        {
          index: 0,
          isActive: false,
          fileUrl: "sideslit-info-img1.png",
          fileName: ["옆 트임", "없음"],
          active: false,
        },
        {
          index: 1,
          isActive: false,
          fileUrl: "sideslit-info-img2.png",
          fileName: ["옆 트임", "있음"],
          active: true,
        },
      ],

      pocketSizeImg: [
        {
          index: 0,
          isActive: false,
          // fileName: "벌크 포장",
          fileUrl: "pocket-size-img1.png",
        },
        {
          index: 1,
          isActive: false,
          // fileName: "개별 포장",
          fileUrl: "pocket-size-img2.png",
        },
        {
          index: 2,
          isActive: false,
          // fileName: "개별 포장",
          fileUrl: "pocket-size-img3.png",
        },
      ],
      step: 0,
      apiData: {},
      errorData: {},
      fabricColorList: [],
    };
  },
  watch: {
    moveStep(newVal) {
      console.log(newVal);
      this.step = newVal;
    },
    colorList(newVal) {
      this.initColor(newVal);
    },
  },
  mounted() {
    if (this.colorList) {
      this.initColor(this.colorList);
    }
  },
  methods: {
    initColor(colorList) {
      this.fabricColorList = colorList;
      // this.fabricColorList.forEach((e) => {
      //   e.isActive = false;
      // });
    },
    toggleColor(index) {
      this.fabricColorList[index].isActive =
        !this.fabricColorList[index].isActive;
      this.$emit(
        "selectFabricColor",
        this.fabricColorList.filter((e) => e.isActive)
      );
    },
    toggleTab(index) {
      this.headerTab.forEach((e) => (e.isActive = false));
      this.headerTab[index].isActive = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-preview {
  position: relative;
  // padding: 40px 0 80px;
  max-width: 400px;
  background-color: $neutral-0;
  overflow: auto;
  &-body {
    padding: 40px 20px 80px;
    width: 400px;
    .content-wrap {
      // margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 60px;
      .row {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      .select-color {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &-title {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          font-weight: 500;
          &.active {
            color: $primary-60;
          }
        }
        &-wrap {
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          border-radius: 4px;
          border: 1px solid $neutral-5;
          box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
        }
        &-area {
          margin-top: 12px;
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
        }
        &-imgs {
          &-title {
            font-size: 14px;
            font-weight: 500;
          }
          &-wrap {
            // cursor: pointer;
            // padding: 0;
            // display: flex;
            // flex-direction: column;
            // gap: 4px;
            display: flex;
            gap: 8px;
            align-items: flex-end;
          }
          &-img {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border: 1px solid $neutral-10;
            background: $neutral-20;
            overflow: hidden;
            img {
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
            }
          }
          &-content {
            display: flex;
            gap: 4px;
            font-size: 12px;
            font-weight: 500;
            line-height: 140%; /* 16.8px */
            text-align: center;
          }
        }
      }

      .select-desc {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
      .fabric-desc {
        margin: 12px 0 0 24px;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
      }
      .desc-imgs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-wrap {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
        &-img {
          width: 360px;
          height: 200px;
          overflow: hidden;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }
        &-name {
          color: $neutral-60;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
        }
      }

      .etc-desc {
        margin: 12px 0 0 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $neutral-60;
      }
      .content-title-div {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .content-title {
        font-weight: 500;
        font-size: 14px;
        color: $neutral-80;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          color: $neutral-80;
        }
      }
      .tag {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $primary-60;
      }
      .img-wrap {
        display: flex;
        justify-content: center;
      }
      .imgs-wrap {
        .imgs {
          display: flex;
          gap: 20px;
          &-img {
            flex: 1;
            display: flex;
            // width: 112px;
            // height: 112px;
            border: 1px dashed $neutral-10;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
            .plus {
              width: 20px;
              height: 20px;
            }
            &.active {
              border: 1px dashed $neutral-10;
              // background-color: $neutral-10;
              border-radius: 4px;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: $neutral-30;
            }
            &.error {
              color: $secondary-60;
              border-color: $secondary-60 !important;
            }
            &.view {
              border: 1px solid $neutral-10;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              .img-etc {
                width: 40px;
                height: 40px;
              }
            }
            &-pop {
              position: absolute;
              top: 1px;
              right: 1px;
              width: rem(12px);
              height: rem(12px);
            }
            &-filename {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              min-height: 40px;
              padding: rem(6px);
              background-color: rgba(0, 0, 0, 0.4);
              p {
                @include ellipse(2);
                font-size: 12px;
                color: $neutral-0;
                text-align: center;
                word-break: break-all;
              }
            }
          }
          &-title {
            margin-top: 8px;
            display: flex;
            justify-content: center;
            font-weight: 400;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            p {
              &.deactive {
                color: $neutral-40;
              }
              &.active {
                color: $primary-60;
              }
            }
          }
        }
      }
      .color {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 4px;
        &-wrap {
          margin-top: 12px;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
          border: 1px solid $neutral-10;
          border-radius: 4px;
          &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 16px;
          }
        }
        &-img {
          display: flex;
          justify-content: center;
          img {
            width: 20px;
          }
        }
        &-select {
          width: 100%;
          height: 72px;
          border: 1px solid $neutral-10;
          border-radius: 4px;
        }
      }
      .size-wrap {
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        &-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 38px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          border: 1px solid $neutral-20;
          border-radius: 4px;
        }
      }
      .btns-wrap {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        &-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 38px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          border: 1px solid $neutral-20;
          border-radius: 4px;
        }
      }
      .input-wrap {
        margin-top: 12px;
        position: relative;
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        &-flex {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 8px;
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            width: 72px;
            height: 38px;
            background: $neutral-3;
            border: 1px solid $neutral-20;
            border-radius: 4px;
          }
          input {
            height: 38px;
          }
        }
        input {
          padding: 8px;
          background: #ffffff;
          border-width: 0px 0px 1px 1px;
          border-style: solid;
          border-color: $neutral-30;
          border-radius: 4px;
          width: 100%;
          height: 32px;

          &:focus {
            border-color: $primary-50;
          }
        }
        &.row {
          flex-direction: row !important;
        }
        .input-explain {
          font-size: 14px;
          color: $neutral-60;
        }
        &.error input {
          border-color: $secondary-60;
        }

        textarea {
          width: 100%;
          padding: 12px;
          border: 1px solid $neutral-20;
          border-radius: 4px;
          height: 120px;
          resize: none;
          font-size: 14px;
          &::placeholder {
            color: $neutral-30;
          }
        }
      }
    }
  }
  &-bottom {
    z-index: 1;
    position: fixed;
    width: 400px;
    bottom: 0;
    &-btns {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-btn {
      flex: 1;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: $neutral-0;
      text-align: center;
      padding: 10px;
      background-color: $neutral-60;
      cursor: pointer;
      &.active {
        background-color: $primary-50;
      }
    }
  }
}
.popup-color {
  .popup-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
  }
  &-wrap {
    position: fixed;
    z-index: 530;
    width: 900px;
    height: 716px;
    background-color: $neutral-0;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    border-radius: 4px;
    @include hidden-scroll();
    &-top {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      color: $neutral-0;
      background-color: $primary-50;
      font-size: 18px;
      font-weight: 500;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .close {
        cursor: pointer;
      }
    }
    &-body {
      padding: 40px 24px;
      display: flex;
      justify-content: center;
      gap: 16px;
      width: 100%;
      .color-imgs {
        display: flex;
        gap: 12px;
        width: 100%;
        &-wrap {
          cursor: pointer;
          padding: 4px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          border: 1px solid $neutral-0;
          &:hover {
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
          }
          &.active {
            border-radius: 4px;
            border: 1px solid $primary-50;
          }
        }
        &-img {
          width: 80px;
          height: 80px;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }
        &-content {
          font-size: 12px;
          font-weight: 500;
          line-height: 140%; /* 16.8px */
          text-align: center;
        }
      }
    }

    &-bottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1;
      &-btn {
        background-color: $neutral-60;
        text-align: center;
        color: $neutral-0;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        cursor: pointer;
        p {
          padding: 9px;
          flex: 1;
          &.active {
            background-color: $primary-50;
          }
        }
        &.relative {
          position: relative;
        }
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}
</style>
