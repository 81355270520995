<template>
  <div class="order">
    <div class="bg">
      <div class="bg-gray"></div>
    </div>
    <div class="order-confirm">
      <div class="order-confirm-title">
        <div>
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.iconBulb"
            :color="$store.state.meta.svg.color.primary50"
          />
          {{
            $route.path.indexOf("custom/detail") > -1
              ? "스타일 관리"
              : "디자인 확인"
          }}
        </div>
        <div class="date" v-if="$route.path.indexOf('custom/detail') > -1">
          {{ apiData.initDate }} 등록
          <SvgImg
            @click="isRemove = true"
            class="pointer"
            :size="20"
            :d="$store.state.meta.svg.deleteIcon"
            :color="$store.state.meta.svg.color.neutral40"
          />
        </div>
      </div>
      <div class="order-confirm-section">
        <div
          class="modify-btn"
          v-if="!($route.path.indexOf('custom/detail') > -1)"
          @click="toggleTab(0)"
        >
          <SvgImg
            :size="16"
            :d="$store.state.meta.svg.refreshSIcon"
            :color="$store.state.meta.svg.color.neutral40"
          />
          <p>수정</p>
        </div>
        <div
          class="list-btn"
          v-else-if="isMobile == 'false'"
          @click="gotoPage(`/custom/order/list/${customNo}`)"
        >
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.list"
            :color="$store.state.meta.svg.color.neutral60"
          />
          <p>주문 내역</p>
        </div>

        <div class="order-confirm-section-wrap">
          <div class="style">
            <div class="row">
              <div class="row-name">· 스타일/상품 명 <span>*</span></div>
              <div
                class="row-value"
                v-if="$route.path.indexOf('custom/detail') > -1"
              >
                {{ styleName }}
                <SvgImg
                  class="pointer"
                  @click="isStyleName = true"
                  :size="20"
                  :d="$store.state.meta.svg.editIcon"
                  :color="$store.state.meta.svg.color.neutral40"
                />
              </div>
              <div class="row-value" v-else>
                <input
                  type="text"
                  v-model="styleName"
                  @keyup.enter="styleName ? saveStyle() : ''"
                  @focusout="styleName ? saveStyle() : ''"
                  placeholder="스타일 또는 상품 명을 입력해주세요."
                  :class="{ empty: !styleName }"
                  style="width: 367px"
                />
              </div>
            </div>
            <div class="row" v-if="$route.path.indexOf('custom/detail') > -1">
              <div class="row-name">· 금액</div>
              <div class="row-value" v-if="apiData.maxUnitPrice > 0">
                {{ comma(apiData.minUnitPrice) }}원 ~
                {{ comma(apiData.maxUnitPrice) }}원
              </div>
              <div class="row-value" v-else>{{ comma(apiData.unitPrice) }}</div>
            </div>
            <div class="row">
              <div class="row-name">· 원단</div>
              <div class="row-value">
                <p v-for="(x, i) in apiData.fabricColorList" :key="i">
                  {{ i == 0 ? x.fabricName : "" }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="row-name">· 컬러</div>
              <div class="row-color">
                <!-- <p v-for="(x, i) in apiData.fabricColorList" :key="i">
                  {{ x.colorName }}[{{ x }}]
                </p> -->
                <div class="select-color-area">
                  <!-- @click="toggleColor(i)" -->
                  <div
                    v-for="(color, i) in apiData.fabricColorList"
                    :key="i"
                    class="select-color-imgs-wrap"
                  >
                    <div class="select-color-imgs-img">
                      <img :src="color.fileUrl" />
                    </div>
                    <div class="select-color-imgs-content">
                      <p>{{ color.colorCode }}</p>
                      <p>{{ color.colorName }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order-confirm-section">
        <div
          class="modify-btn"
          v-if="!($route.path.indexOf('custom/detail') > -1)"
          @click="toggleTab(3)"
        >
          <SvgImg
            :size="16"
            :d="$store.state.meta.svg.refreshSIcon"
            :color="$store.state.meta.svg.color.neutral40"
          />
          <p>수정</p>
        </div>
        <div class="row-dtl">
          <div class="row-dtl-name">· 디테일</div>
          <div class="row-dtl-column">
            <div class="row-dtl-column-content">
              <p class="row-dtl-column-content-name">네크라인 (Neckline)</p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ neckLineName }}
                </p>
              </div>
            </div>
            <div class="row-dtl-column-content">
              <p class="row-dtl-column-content-name">
                네크라인 디테일 (Neckline Detail)
              </p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ neckDtlName }}
                </p>

                <p class="desc" v-if="apiData.neckDetailCd == 3">
                  <span>{{ vgussetType }}</span>
                  <span>A : {{ apiData.vgussetSize1 }}cm</span>
                  <span>B : {{ apiData.vgussetSize2 }}cm</span>
                </p>
                <p class="desc" v-else-if="this.apiData.neckDetailCd == 4">
                  <span>A : {{ apiData.xstitchSize1 }}cm</span>
                  <span>B : {{ apiData.xstitchSize2 }}cm</span>
                  <span>C : {{ apiData.xstitchSize3 }}cm</span>
                </p>
                <p class="desc" v-else-if="neckDtlSubName">
                  {{ neckDtlSubName }}
                </p>
              </div>
            </div>
            <div class="row-dtl-column-content">
              <p class="row-dtl-column-content-name">네크라인 마감</p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ neckEndName }}
                </p>
                <p class="desc" v-if="neckEndSubName">{{ neckEndSubName }}</p>
              </div>
            </div>
            <div class="row-dtl-column-content">
              <p class="row-dtl-column-content-name">스티치 (Stitch)</p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ stitchName }}
                </p>
                <p class="desc" v-if="stitchSubName">{{ stitchSubName }}</p>
              </div>
            </div>
            <div
              class="row-dtl-column-content"
              v-if="apiData.customType == '2'"
            >
              <p class="row-dtl-column-content-name">소매단 마감</p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ sleeveEndName }}
                </p>
                <p class="desc" v-if="sleeveEndSubName">
                  {{ sleeveEndSubName }}
                </p>
              </div>
            </div>
            <div
              class="row-dtl-column-content"
              v-if="apiData.customType == '2'"
            >
              <p class="row-dtl-column-content-name">밑단 마감</p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ hemEndName }}
                </p>
                <p class="desc" v-if="hemEndSubName">{{ hemEndSubName }}</p>
              </div>
            </div>
            <div class="row-dtl-column-content">
              <p class="row-dtl-column-content-name">포켓 (Pocket)</p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ pocketName }}
                </p>
                <p class="desc" v-if="this.apiData.pocketCd == 5">
                  <span>A : {{ apiData.kgrPocketSize1 }}cm</span>
                  <span>B : {{ apiData.kgrPocketSize2 }}cm</span>
                  <span>C : {{ apiData.kgrPocketSize3 }}cm</span>
                  <span>D : {{ apiData.kgrPocketSize4 }}cm</span>
                  <span>E : {{ apiData.kgrPocketSize5 }}cm</span>
                </p>
                <p class="desc" v-if="pocketSubName">{{ hemEndSubName }}</p>
              </div>
            </div>
            <div
              class="row-dtl-column-content"
              v-if="apiData.customType == '1'"
            >
              <p class="row-dtl-column-content-name">소매 밑단</p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ sleeveName }}
                </p>
                <p class="desc" v-if="sleeveSubName">{{ sleeveSubName }}</p>
              </div>
            </div>
            <div
              class="row-dtl-column-content"
              v-if="apiData.customType == '1'"
            >
              <p class="row-dtl-column-content-name">옆 트임</p>
              <div class="row-dtl-column-content-value">
                <p class="value">
                  {{ sideSlitName }}
                </p>
                <p class="desc" v-if="sideSlitSubName">{{ sideSlitSubName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order-confirm-section">
        <div
          class="modify-btn"
          v-if="!($route.path.indexOf('custom/detail') > -1)"
          @click="toggleTab(2)"
        >
          <SvgImg
            :size="16"
            :d="$store.state.meta.svg.refreshSIcon"
            :color="$store.state.meta.svg.color.neutral40"
          />
          <p>수정</p>
        </div>
        <!-- <div class="row">
          <div class="row-name">· 사이즈 기준</div>
          <div class="row-value">
            <p>{{ apiData.gender }}</p>
            <p>{{ apiData.fit }}</p>
            <p>{{ apiData.totalLength }}</p>
            <p>{{ apiData.sleeveLength }}</p>
          </div>
        </div> -->
        <div class="row-size">
          <div class="row-size-name">· 사이즈 스펙</div>
          <div class="row-size-value">
            <SizeTable class="gap12" :size="size" :disabled="false" />
          </div>
        </div>
      </div>

      <div class="order-confirm-section">
        <div
          class="modify-btn"
          v-if="!($route.path.indexOf('custom/detail') > -1)"
          @click="toggleTab(4)"
        >
          <SvgImg
            :size="16"
            :d="$store.state.meta.svg.refreshSIcon"
            :color="$store.state.meta.svg.color.neutral40"
          />
          <p>수정</p>
        </div>
        <div class="column">
          <div class="column-name">· 인쇄 이미지</div>
          <div class="column-wrap">
            <div
              class="print-wrap"
              v-if="
                (isMobile == 'true' &&
                  checkPrintBtn.filter((e) => e.isActive)[0].cd == '1') ||
                isMobile == 'false'
              "
            >
              <p v-html="isMobile == 'false' ? 'Front' : ''"></p>
              <img
                :src="require(`@/assets/img/print-front-img.png`)"
                v-if="apiData.customType == '1'"
              />
              <img
                :src="require(`@/assets/img/print-front-img-mtm.png`)"
                v-if="apiData.customType == '2'"
              />
              <div
                class="print-wrap-img on active"
                v-for="(printData, i) in printFrontImgs"
                :key="i"
                :class="{
                  active: printData.img.fileUrl,
                  on: printData.isActive,
                  'tshirt-front': apiData.customType == '1',
                  'mtm-front': apiData.customType == '2',
                }"
              >
                <div
                  class="file-area"
                  @click="openPrint('front', i)"
                  v-if="printData.img.fileUrl"
                >
                  <img :src="printData.img.fileUrl" />
                </div>
                <div
                  class="file-area"
                  v-html="printData.name.replace(' ', '<br/>')"
                  v-else
                ></div>
              </div>
            </div>
            <div
              class="print-wrap"
              v-if="
                (isMobile == 'true' &&
                  checkPrintBtn.filter((e) => e.isActive)[0].cd == '2') ||
                isMobile == 'false'
              "
            >
              <p v-html="isMobile == 'false' ? 'Back' : ''"></p>
              <img
                :src="require(`@/assets/img/print-back-img.png`)"
                v-if="apiData.customType == '1'"
              />
              <img
                :src="require(`@/assets/img/print-back-img-mtm.png`)"
                v-if="apiData.customType == '2'"
              />
              <div
                class="print-wrap-img on active"
                v-for="(printData, i) in printBackImgs"
                :key="i"
                :class="{
                  active: printData.img.fileUrl,
                  on: printData.isActive,
                  'tshirt-back': apiData.customType == '1',
                  'mtm-back': apiData.customType == '2',
                }"
              >
                <div
                  class="file-area"
                  @click="openPrint('back', i)"
                  v-if="printData.img.fileUrl"
                >
                  <img :src="printData.img.fileUrl" />
                </div>
                <div
                  class="file-area"
                  v-html="printData.name.replace(' ', '<br/>')"
                  v-else
                ></div>
              </div>
            </div>
          </div>
          <div class="column-btns" v-if="isMobile == 'true'">
            <div
              class="column-btns-btn"
              v-for="(check, i) in checkPrintBtn"
              :key="i"
              :class="{ active: check.isActive }"
              @click="toggleCeck('checkPrintBtn', i)"
            >
              <p>{{ check.name }}</p>
            </div>
          </div>
        </div>
        <div class="validation">
          <p class="active">(필수)</p>
          <p :class="{ active: styleName }">스타일 /상품 명</p>
        </div>
      </div>
      <div class="order-confirm-btn" @click="styleName ? goOrder() : ''">
        <p :class="{ active: styleName }">
          디자인 등록 / 주문하기<SvgImg
            :size="20"
            :d="$store.state.meta.svg.rightArrowBig"
            :color="$store.state.meta.svg.color.neutral0"
          />
        </p>
      </div>
    </div>
  </div>

  <div class="popup-add" v-if="isStyleName">
    <div class="popup-background" @click="isStyleName = false"></div>
    <div class="popup-add-wrap">
      <div class="popup-add-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.editIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>수정</p>
        </div>
        <SvgImg
          class="pointer"
          :size="20"
          :d="$store.state.meta.svg.closePopup"
          :color="$store.state.meta.svg.color.neutral0"
          @click="isStyleName = false"
        />
      </div>

      <div class="popup-add-wrap-body">
        <div class="style-name">
          <p>· 스타일/상품 명 <span>*</span></p>
          <p class="valid" v-if="!styleName">
            스타일 또는 상품 명을 입력해주세요.
          </p>
        </div>
        <input
          type="text"
          v-model="styleName"
          @keyup="convStyleName()"
          @keyup.enter="styleName ? saveStyle() : ''"
          placeholder="스타일 또는 상품 명을 입력해주세요."
          :class="{ empty: !styleName }"
        />
      </div>

      <div class="popup-add-wrap-bottom">
        <div class="popup-add-wrap-bottom-btn pointer">
          <p class="" @click="isStyleName = false">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closeIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            닫기
          </p>
          <p class="" @click="saveStyle()" :class="{ active: styleName }">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.checkIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            수정완료
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-remove" v-if="isRemove">
    <div class="popup-background" @click="isRemove = false"></div>
    <div class="popup-remove-wrap">
      <div class="popup-remove-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.editIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>스타일 삭제</p>
        </div>
        <SvgImg
          class="pointer"
          :size="20"
          :d="$store.state.meta.svg.closePopup"
          :color="$store.state.meta.svg.color.neutral0"
          @click="isRemove = false"
        />
      </div>

      <div class="popup-remove-wrap-body">
        <div class="body-title">{{ styleName }}</div>
        <div class="body-content">
          <p>해당 스타일을 삭제하시겠습니까?</p>
          <p>삭제 시, 복구가 불가능합니다.</p>
        </div>
      </div>

      <div class="popup-remove-wrap-bottom">
        <div class="popup-remove-wrap-bottom-btn pointer">
          <p class="" @click="isRemove = false">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closeIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            닫기
          </p>
          <p class="" @click="removeStyle()" :class="{ active: styleName }">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.deleteIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            삭제하기
          </p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isPrintPopup" class="popup-print">
    <div class="popup-background" @click="closePrint()"></div>
    <div class="popup-print-wrap">
      <input
        class="hidden"
        type="file"
        id="imgFile"
        ref="imgFile"
        accept=".gif, .jpg, .jpeg, .png, .ai, .psd"
        @change="fileUpload('81', 1, $event)"
      />
      <div class="popup-print-wrap-body">
        <div class="body-left">
          <div class="body-left-wrap" v-if="printType == 'front'">
            <p>Front</p>
            <img :src="require(`@/assets/img/print-${printType}-img.png`)" />
            <div
              class="body-left-wrap-img front"
              v-for="(img, i) in printFrontImgs"
              :key="i"
              :class="i == printIndex ? 'on' : ''"
            >
              <img
                class="file-area"
                :src="
                  require(`@/assets/img/img-custom-print-${printType}${
                    i + 1
                  }.png`)
                "
              />
            </div>
          </div>
          <div class="body-left-wrap" v-if="printType == 'back'">
            <p>Back</p>
            <img :src="require(`@/assets/img/print-${printType}-img.png`)" />
            <div
              class="body-left-wrap-img back"
              v-for="(img, i) in printBackImgs"
              :class="i == printIndex ? 'on' : ''"
              :key="i"
            >
              <img
                class="file-area"
                :src="
                  require(`@/assets/img/img-custom-print-${printType}${
                    i + 1
                  }.png`)
                "
              />
            </div>
          </div>
        </div>
        <div class="body-right">
          <div class="body-right-wrap">
            <div class="title">
              <p>
                · 프린팅 이미지의 ‘가로’와 ‘세로’ 중 긴축을 선택해주세요.
                <span>*</span>
              </p>
              <!-- <div class="tooltip">
                <div class="tooltip-wrap">
                  <div class="tooltip-wrap-dtl">
                    <div class="tooltip-wrap-dtl-text">
                      <img src="@/assets/img/img-print-info.png" />
                      <p><span>긴축은</span> 가로</p>
                      <p><span>긴축의 길이는</span> 6cm</p>
                    </div>
                  </div>
                </div>
                <SvgImg
                  :size="20"
                  :d="$store.state.meta.svg.questMarkIcon"
                  :color="$store.state.meta.svg.color.primary50"
                />
              </div> -->
            </div>
            <div class="line-type">
              <SelectBoxRadius
                class="select-box"
                :data="lineType"
                :filterType="'lineType'"
                @selectData="selectData"
                :selectData="lineType.filter((e) => e.isActive)[0].name"
              ></SelectBoxRadius>
            </div>
            <div class="column mt-12">
              <p class="title">
                · 프린팅 이미지의 긴축의 길이를 입력해주세요.<span>*</span>
              </p>
              <div class="line-type">
                <input
                  type="number"
                  v-model="longLength"
                  placeholder="긴축의 길이를 입력해주세요."
                  @keyup="checkPrint"
                />
                cm
              </div>
            </div>
          </div>
          <div class="body-right-wrap" v-if="printType == 'front'">
            <div
              class="column"
              v-for="(data, i) in this.$store.state.customPrint.frontDesc[
                printIndex
              ].name"
              :key="i"
              :class="{ 'mt-12': i > 0 }"
            >
              <p class="title">
                <span class="num">{{ i + 1 }}</span> {{ data }}
              </p>
              <div class="line-type">
                <input
                  type="number"
                  v-model="printLength[i]"
                  placeholder="이격 길이를 입력해주세요."
                  @keyup="checkPrint"
                />
                cm
              </div>
            </div>
          </div>
          <div class="body-right-wrap" v-if="printType == 'back'">
            <div
              class="column"
              v-for="(data, i) in this.$store.state.customPrint.backDesc[
                printIndex
              ].name"
              :key="i"
              :class="{ 'mt-12': i > 0 }"
            >
              <p class="title">
                <span class="num">{{ i + 1 }}</span> {{ data }}
              </p>
              <div class="line-type">
                <input
                  type="number"
                  v-model="printLength[i]"
                  placeholder="이격 길이를 입력해주세요."
                  @keyup="checkPrint"
                />
                cm
              </div>
            </div>
          </div>
          <div class="imgs" v-if="img.fileNo != null">
            <div class="imgs-img view">
              <img
                v-if="
                  ['.psd', '.ai', '.pdf', '.xlsx'].some(
                    (e) => img.sourceFileName.indexOf(e) != -1
                  )
                "
                :src="
                  require(`@/assets/img/thumbnail-${
                    img.sourceFileName.split('.')[1]
                  }.png`)
                "
              />
              <img v-else :src="img.fileUrl" />
            </div>
            <p>{{ img.sourceFileName }}</p>
            <div class="imgs-pop flex" @click.stop="removeImg(img.fileNo)">
              <img src="@/assets/icon/ic-x-square.svg" />
            </div>
          </div>
        </div>
      </div>

      <div class="popup-print-wrap-bottom">
        <div class="bottom-wrap">
          <div class="column gap8">
            <CheckBox
              :name="'‘자수’ 요청'"
              :checked="agree[0]"
              :hoverOff="true"
              @click="agree[0] = !agree[0]"
            />
            <div class="check-desc">
              검토 후 작업 가능 여부를 알려드리며, 금액은 별도로 청구됩니다.
            </div>
          </div>
          <div class="column gap8 mt-24">
            <CheckBox
              :name="'상품에 어울리는 위치로 세부 조정해드립니다.'"
              :checked="agree[1]"
              :hoverOff="true"
              @click="agree[1] = !agree[1]"
            />
            <div class="check-desc">
              위치 세부 조정 요청 시, 이미지의 크기는 수정하지 않고, 위치를 세부
              조정하며, 그로 인한 교환 및 환불, 반품은 불가합니다.
            </div>
          </div>
        </div>
        <div class="popup-print-wrap-bottom-btn pointer">
          <p class="active" @click="closePrint()">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.checkIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />확인
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomDetail",
  props: {
    apiData: {
      type: Object,
      description: "Order Info",
    },
    moveStep: {
      type: String,
      description: "btn text",
    },
  },
  data() {
    return {
      isPrintPopup: false,
      isRemove: false,
      isStyleName: false,
      step: 0,
      // apiData: {},
      userId: sessionStorage.getItem("userId"),
      isMobile: sessionStorage.getItem("isMobile"),
      checkPrintBtn: [
        { index: 0, cd: 1, name: "Front", isActive: true },
        { index: 1, cd: 2, name: "Back", isActive: false },
      ],
      customNo: "",
      selectedFront: {},
      selectedBack: {},
      size: [
        { index: 0, isActive: true, cd: "01", name: "XS" },
        { index: 1, isActive: true, cd: "02", name: "S" },
        { index: 2, isActive: true, cd: "03", name: "M" },
        { index: 3, isActive: true, cd: "04", name: "L" },
        { index: 4, isActive: true, cd: "05", name: "XL" },
        { index: 5, isActive: true, cd: "06", name: "2XL" },
        { index: 6, isActive: true, cd: "07", name: "3XL" },
      ],
      printFrontImgs: [],
      printBackImgs: [],
      neckLine: [],
      neckDetail: [],
      neckEnd: [],
      stitch: [],
      pocket: [],
      sleeve: [],
      sideSlit: [],
      sleeveEnd: [],
      hemEnd: [],
      lineType: [
        { cd: "1", name: "가로", isActive: false },
        { cd: "2", name: "세로", isActive: false },
      ],
      longLength: "",
      printLength: [],
      isPrint: false,
      positionFlag: "",
      imgList: [],
      img: {},
      agree: [false, false],
      printType: "back",
      neckLineImg: "",
      neckLineName: "",
      neckLineSubName: "",
      neckDtlImg: "",
      neckDtlName: "",
      neckDtlSubName: "",
      neckEndImg: "",
      neckEndName: "",
      neckEndSubName: "",
      stitchImg: "",
      stitchName: "",
      stitchSubName: "",
      pockethImg: "",
      pockethName: "",
      pockethSubName: "",
      sleeveImg: "",
      sleeveName: "",
      sleeveSubName: "",
      sideSlitImg: "",
      sideSlitName: "",
      sideSlitSubName: "",
      sleeveEndImg: "",
      sleeveEndName: "",
      sleeveEndSubName: "",
      hemEndImg: "",
      hemEndName: "",
      hemEndSubName: "",
      styleName: "",
      vgussetType: "",
      vgusset: {},
      xstitch: {},
      kgrPocket: {},
    };
  },
  watch: {
    moveStep(newVal) {
      console.log(newVal);
      this.step = newVal;
    },
    apiData(newVal) {
      console.log(newVal);
      this.init();
    },
  },
  mounted() {
    this.customNo = this.$route.params.customNo;
    this.step = Number(this.$route.query.step);

    this.customType = this.apiData.customType;
    this.printFrontImgs = this.$store.state.customPrint.printFrontImgs;
    this.printBackImgs = this.$store.state.customPrint.printBackImgs;
    this.neckLine = this.$store.state.customPrint.neckLine;
    this.neckDetail = this.$store.state.customPrint.neckDetail;
    this.neckEnd = this.$store.state.customPrint.neckEnd;
    this.stitch = this.$store.state.customPrint.stitch;
    this.pocket = this.$store.state.customPrint.pocket;
    this.sleeve = this.$store.state.customPrint.sleeve;
    this.sleeveEnd = this.$store.state.customPrint.sleeveEnd;
    this.hemEnd = this.$store.state.customPrint.hemEnd;
    this.sideSlit = this.$store.state.customPrint.sideSlit;

    this.vgusset = this.$store.state.customPrint.vgusset;
    this.xstitch = this.$store.state.customPrint.xstitch;
    this.kgrPocket = this.$store.state.customPrint.kgrPocket;

    // this.customNo = this.$route.params.customNo;
    // this.$API.custom.customOrder(this.userId, this.customNo).then((res) => {
    //   this.apiData = res.data.body;
    // });

    if (this.apiData?.printList != null) {
      this.init();
    }
    // window.addEventListener("popstate", this.handlePopState);
  },
  unmounted() {
    // window.removeEventListener("popstate", this.handlePopState);
  },
  methods: {
    toggleCeck(type, index) {
      this[type].map((e) => (e.isActive = false));
      this[type][index].isActive = true;
    },
    convStyleName() {
      this.styleName = this.styleName.replace("#", "");
    },
    saveStyle() {
      let datas = {
        customNo: this.customNo,
        userId: this.userId,
        styleName: this.styleName,
      };
      this.$API.custom.customModifyStyle(datas).then((res) => {
        console.log(res.data.body);
        this.isStyleName = false;
      });
    },
    removeStyle() {
      let datas = {
        customNo: this.customNo,
        userId: this.userId,
      };
      this.$API.custom.customRemoveStyle(datas).then((res) => {
        console.log(res.data.body);
        this.isRemove = true;
        this.isToast("스타일 삭제가 완료되었습니다.");
        this.gotoPage("/custom/list");
      });
    },
    handlePopState(event) {
      // alert("aa");
      // // 뒤로가기 이벤트를 감지하면 실행될 로직을 작성
      // console.log("뒤로가기 이벤트 감지");
      // // event.state를 통해 브라우저 이력 상태에 접근할 수 있음
      // console.log("새로운 상태:", event.state);
      // this.isDetail = this.$route.query.isDetail
      //   ? this.$route.query.isDetail
      //   : false;
    },
    init() {
      console.log("this.apiData");
      console.log(this.apiData);
      this.styleName = this.apiData.styleName;
      // printFrontImgs
      this.apiData.printList?.forEach((item) => {
        this.printFrontImgs.forEach((e) => {
          if (e.cd == item.printCode) {
            e.img = item;
            e.printCode = item.printCode;
            e.lineType = item.lineType;
            e.longLength = item.longLength;
            e.length1 = item.length1;
            e.length2 = item.length2;
            e.jasuFlag = item.jasuFlag;
            e.positionFlag = item.positionFlag;
          }
        });
        this.printBackImgs.forEach((e) => {
          if (e.cd == item.printCode) {
            e.img = item;
            e.printCode = item.printCode;
            e.lineType = item.lineType;
            e.longLength = item.longLength;
            e.length1 = item.length1;
            e.length2 = item.length2;
            e.jasuFlag = item.jasuFlag;
            e.positionFlag = item.positionFlag;
          }
        });
      });
      //성별 + 핏 + 총 기장 + 소매 기장 + 네크라인 + 네크라인 디테일
      this.$API.custom
        .customSizeSepc(
          this.apiData.genderCd,
          this.apiData.fitCd,
          this.apiData.totalLengthCd,
          this.apiData.sleeveLengthCd,
          this.apiData.sleeveStyleCd,
          this.apiData.neckLineCd,
          this.apiData.neckDetailCd,
          this.apiData.sleeveEndCd,
          this.apiData.hemEndCd,
          this.apiData.customType
        )
        .then((res) => {
          let sizeSpeclist;
          if (this.apiData.sizeList.length > 0) {
            sizeSpeclist = this.apiData.sizeList;
          } else {
            sizeSpeclist = res.data.body;
          }
          console.log(sizeSpeclist);
          this.size = [
            {
              index: 0,
              isActive: true,
              cd: "01",
              name: "XS",
              spec: sizeSpeclist[0],
            },
            {
              index: 0,
              isActive: true,
              cd: "02",
              name: "S",
              spec: sizeSpeclist[1],
            },
            {
              index: 1,
              isActive: true,
              cd: "03",
              name: "M",
              spec: sizeSpeclist[2],
            },
            {
              index: 2,
              isActive: true,
              cd: "04",
              name: "L",
              spec: sizeSpeclist[3],
            },
            {
              index: 3,
              isActive: true,
              cd: "05",
              name: "XL",
              spec: sizeSpeclist[4],
            },
            {
              index: 4,
              isActive: true,
              cd: "06",
              name: "2XL",
              spec: sizeSpeclist[5],
            },
            {
              index: 4,
              isActive: true,
              cd: "07",
              name: "3XL",
              spec: sizeSpeclist[6],
            },
          ];

          if (this.apiData.vgussetType == 1) {
            this.vgussetType = "앞";
          }
          if (this.apiData.vgussetType == 2) {
            this.vgussetType = "뒤";
          }
          if (this.apiData.vgussetType == 3) {
            this.vgussetType = "앞 + 뒤";
          }
          this.neckLineName = this.neckLine.filter(
            (e) => e.cd == this.apiData.neckLineCd
          )[0]?.name;
          this.neckLineSubName = this.neckLine.filter(
            (e) => e.cd == this.apiData.neckLineCd
          )[0]?.subName;
          this.neckDtlName = this.neckDetail.filter(
            (e) => e.cd == this.apiData.neckDetailCd
          )[0]?.name;
          this.neckDtlSubName = this.neckDetail.filter(
            (e) => e.cd == this.apiData.neckDetailCd
          )[0]?.subName;
          this.neckEndName = this.neckEnd.filter(
            (e) => e.cd == this.apiData.neckEndCd
          )[0]?.name;
          if (this.apiData.customType == "1")
            this.neckEndSubName = "10mm 제 원단";
          if (this.apiData.customType == "2")
            this.neckEndSubName = "10mm 헤링본 테이프";
          this.stitchName = this.stitch.filter(
            (e) => e.cd == this.apiData.stitchCd
          )[0]?.name;
          this.stitchSubName = this.stitch.filter(
            (e) => e.cd == this.apiData.stitchCd
          )[0]?.subName;
          this.pocketName = this.pocket.filter(
            (e) => e.cd == this.apiData.pocketCd
          )[0]?.name;
          this.pocketSubName = this.pocket.filter(
            (e) => e.cd == this.apiData.pocketCd
          )[0]?.subName;
          this.sleeveName = this.sleeve.filter(
            (e) => e.cd == this.apiData.sleeveCd
          )[0]?.name;
          this.sleeveSubName = this.sleeve.filter(
            (e) => e.cd == this.apiData.sleeveCd
          )[0]?.subName;
          this.sideSlitName = this.sideSlit.filter(
            (e) => e.cd == this.apiData.sideSlitCd
          )[0]?.name;
          this.sideSlitSubName = this.sideSlit.filter(
            (e) => e.cd == this.apiData.sideSlitCd
          )[0]?.subName;
          this.sleeveEndName = this.sleeveEnd.filter(
            (e) => e.cd == this.apiData.sleeveEndCd
          )[0]?.name;
          this.sleeveEndSubName = this.sleeveEnd.filter(
            (e) => e.cd == this.apiData.sleeveEndCd
          )[0]?.subName;
          this.hemEndName = this.hemEnd.filter(
            (e) => e.cd == this.apiData.hemEndCd
          )[0]?.name;
          this.hemEndSubName = this.hemEnd.filter(
            (e) => e.cd == this.apiData.hemEndCd
          )[0]?.subName;
        });
    },
    goOrder() {
      if (!(this.$route.path.indexOf("custom/detail") > -1)) {
        const datas = { userId: this.userId, customNo: this.customNo };
        this.$API.custom.customRegistFin(datas).then((res) => {
          console.log(res.data.body);
          this.sendBtnEvent(13, "SU");
        });
      }

      // const initCustomPrint = this.$store.state.customPrintInit;
      // this.$store.state.customPrint = initCustomPrint;
      // this.initCustomPrint();
      this.gotoPage("/custom/order/" + this.customNo);
    },
    toggleTab(index) {
      console.log(index);
      this.$emit("clickStep", index);

      // let getUrl = "/custom/add/" + this.customNo + "?";
      // let getParams = "styleName=" + this.apiData.styleName + "&step=" + index;
      // this.gotoPage(getUrl + getParams);
    },
    // openPrint(type, i) {
    //   console.log(type);
    //   console.log(i);

    //   if (type == "front") {
    //     this.printFrontImgs.forEach((e) => {
    //       e.isActive = false;
    //     });
    //     this.printFrontImgs[i].isActive = true;
    //     this.selectedFront = this.printFrontImgs.filter((e) => e.isActive)[0];
    //   }
    //   if (type == "back") {
    //     this.printBackImgs.forEach((e) => {
    //       e.isActive = false;
    //     });
    //     this.printBackImgs[i].isActive = true;
    //     this.selectedBack = this.printBackImgs.filter((e) => e.isActive)[0];
    //   }
    // },
    initPrint() {
      this.lineType[0].isActive = true;
      this.lineType[1].isActive = false;
      this.longLength = "";
      this.printLength = [];
      this.img = {};
      this.agree = [false, false];
      this.isPrint = false;
    },
    closePrint() {
      this.initPrint();
      this.isPrintPopup = false;
    },
    openPrint(type, i) {
      this.printType = type;
      this.printIndex = i;
      this.isPrintPopup = true;

      let printData = {};
      if (
        this.printType == "front" &&
        this.printFrontImgs[this.printIndex].img.fileNo != null
      ) {
        printData = this.printFrontImgs[this.printIndex];
      }
      if (
        this.printType == "back" &&
        this.printBackImgs[this.printIndex].img.fileNo != null
      ) {
        this.printBackImgs[this.printIndex].img;
        printData = this.printBackImgs[this.printIndex];
      }

      if (printData.img?.fileNo != null) {
        this.selectData(
          this.lineType.filter((e) => e.cd == printData.lineType)[0]
        );
        this.longLength = printData.longLength;
        this.printLength[0] = printData.length1;
        if (printData.length2) this.printLength[1] = printData.length2;
        this.img = printData.img;
        this.agree[0] = printData.jasuFlag == "1" ? true : false;
        this.agree[1] = printData.positionFlag == "1" ? true : false;
      } else {
        this.initPrint();
      }
      this.checkPrint();
    },

    checkPrint() {
      console.log();
      if (this.printLength.length == 1) {
        if (this.img?.fileNo > 0 && this.longLength && this.printLength[0]) {
          this.isPrint = true;
        } else {
          this.isPrint = false;
        }
      } else if (this.printLength.length == 2) {
        if (this.printLength.length == 2) {
          if (
            this.img?.fileNo > 0 &&
            this.longLength &&
            this.printLength[0] &&
            this.printLength[1]
          ) {
            this.isPrint = true;
          } else {
            this.isPrint = false;
          }
        }
      }
      // if (this.img?.fileNo > 0 && this.longLength && this.printLength[0]) {
      //   if (this.printLength.length == 1) {
      //     this.isPrint = true;
      //   } else if (this.printLength.length == 2 && this.printLength[1]) {
      //     this.isPrint = true;
      //   } else {
      //     this.isPrint = false;
      //   }
      // } else {
      //   this.isPrint = false;
      // }
    },
    selectData(data, type) {
      this.lineType.forEach((e) => {
        e.isActive = false;
        if (e.cd == data.cd) e.isActive = true;
      });
    },
    fileUpload(fileStatus, maxLength, event) {
      console.log(fileStatus);
      console.log(maxLength);
      console.log(event);
      let files = [];
      let c = maxLength - this.imgList.length;

      let msg = "";

      let fileList;
      if (event.dataTransfer?.files.length > 0) {
        fileList = event.dataTransfer.files;
      } else if (event.target) {
        fileList = event.target.files;
      }
      Array.from(fileList).forEach((e) => {
        if (
          (e.name.indexOf(".ai") == -1 && e.size >= 1024000 * 10) ||
          (e.name.indexOf(".ai") != -1 && e.size >= 1024000 * 100)
        ) {
          // 파일 용량 제한
          msg = "파일 용량이 초과되었습니다.";
          if (msg) this.showToast(msg);
          return;
        }
        files.push(e);
        c = c - 1;
      });

      this.$API.comm
        .fileUploadMultiple(
          sessionStorage.getItem("userId"),
          this.customNo,
          fileStatus,
          "8",
          files
        )
        .then((res) => {
          res.data.body.forEach((e, i) => {
            console.log(e);
            let d = e["imgFile" + (i + 1)]; // api 수정시 변경
            this.imgList.push({
              fileNo: d.fileNo,
              fileSize: d.fileSize,
              fileStatus: d.fileStatus,
              fileType: d.fileType,
              fileUrl: d.previewUrl,
              sourceFileName: d.sourceFileName,
            });
            this.img = {
              fileNo: d.fileNo,
              fileSize: d.fileSize,
              fileStatus: d.fileStatus,
              fileType: d.fileType,
              fileUrl: d.previewUrl,
              sourceFileName: d.sourceFileName,
              customNo: this.customNo,
              userId: this.userId,
            };

            this.$API.custom.customModifyThumb(this.img).then((res) => {
              console.log(res);
              location.reload();
            });
          });
        });
    },
    clickAgree(i) {
      if (i === 0) {
        this.agree[1] = this.agree[0];
        this.agree[2] = this.agree[0];
        this.agree[3] = this.agree[0];
      } else {
        this.agree[0] = this.agree[1] && this.agree[2] && this.agree[3];
      }
    },
    nextStep(val) {
      this.paymentType = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0 20px;
  }
  .bg {
    position: fixed;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    &-white {
      flex: 1;
      background: $neutral-0;
    }
    &-gray {
      flex: 2;
      background: $neutral-3;
    }
  }
  .preview {
    width: 400px;
    overflow: auto;
  }
  &-confirm {
    width: 900px;
    background: $neutral-3;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 900px) {
      width: 100%;
    }
    &-title {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 62px 0 20px;
      display: flex;
      gap: 8px;
      font-size: 18px;
      font-weight: 500;
      .date {
        display: flex;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &-section {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px;
      border-radius: 8px;
      background: $neutral-0;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
      &-wrap {
        display: flex;
        gap: 40px;
        .thumbnail {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 180px;
          height: 180px;
          border-radius: 4px;
          overflow: hidden;
          background-color: $neutral-20;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
          .upload-img {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 70px;
            left: 20px;
            width: 140px;
            height: 40px;
            padding: 12px 0px;
            border-radius: 4px;
            border: 1px dashed var(--Neutral_20, #d1d1d1);
            opacity: 0.4;
            background: var(--Neutral_00, #fff);
          }
        }
        .style {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 20px;
          input {
            // max-width: 265px;
            width: 100%;
            // height: 30px;
            height: 36px;
            padding: 6px 12px;
            background: $neutral-0;
            border-width: 0px 0px 1px 1px;
            border-style: solid;
            border-color: $neutral-20;
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            ::placeholder {
              color: $neutral-20;
            }
            &:focus {
              border-color: $primary-50;
            }
            &:disabled {
              background-color: $primary-5;
            }
            &.empty {
              border-color: $secondary-60;
            }
          }
        }
      }
      .modify-btn {
        cursor: pointer;
        position: absolute;
        right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        padding: 4px 8px;
        border-radius: 4px;
        border: 1px solid $neutral-10;
        background: $neutral-0;
        z-index: 1;
        p {
          color: $neutral-60;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
        }
        &:hover {
          border: 1px solid $primary-20;
        }
      }
      .list-btn {
        cursor: pointer;
        position: absolute;
        right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        width: 103px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid $neutral-10;
        background: $neutral-0;
        z-index: 1;
        p {
          color: $neutral-60;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .row {
        position: relative;
        display: flex;
        // align-items: center;
        @media (max-width: 900px) {
          flex-direction: column;
          gap: 12px;
        }
        &-name {
          position: absolute;
          font-size: 14px;
          font-weight: 500;
          @media (max-width: 900px) {
            position: relative;
          }
          span {
            color: $primary-50;
          }
        }
        &-value {
          display: flex;
          gap: 20px;
          margin-left: 136px;
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          @media (max-width: 900px) {
            margin-left: 0;
          }
        }
        &-color {
          top: 0;
          display: flex;
          gap: 8px;
          margin-left: 136px;
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          @media (max-width: 900px) {
            margin-left: 0;
          }
          .select-color {
            display: flex;
            flex-direction: column;
            gap: 12px;
            &-title {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              font-weight: 500;
              &.active {
                color: $primary-60;
              }
            }
            &-area {
              display: flex;
              flex-wrap: wrap;
              gap: 12px;
            }
            &-imgs {
              &-title {
                font-size: 14px;
                font-weight: 500;
              }
              &-wrap {
                // cursor: pointer;
                // padding: 0;
                // display: flex;
                // flex-direction: column;
                // gap: 4px;
                display: flex;
                gap: 8px;
                align-items: flex-end;
              }
              &-img {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid $neutral-10;
                background: $neutral-20;
                overflow: hidden;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  width: auto;
                  height: auto;
                }
              }
              &-content {
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
              }
            }
          }
        }
        &-size {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 20px;
          overflow: scroll;
          &-name {
            font-size: 14px;
            font-weight: 500;
          }
          &-value {
            width: 100%;
            position: relative;
            @media (max-width: 900px) {
              width: 392px;
            }
          }
        }
        &-dtl {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 20px;
          &-name {
            font-size: 14px;
            font-weight: 500;
          }
          &-column {
            display: flex;
            flex-direction: column;
            gap: 20px;
            &-content {
              display: flex;
              gap: 40px;
              @media (max-width: 900px) {
                flex-direction: column;
                gap: 4px;
              }
              &-name {
                font-size: 14px;
                font-weight: 500;
              }
              &-value {
                display: flex;
                gap: 20px;
                align-items: center;
                .value {
                  color: $primary-60;
                  font-size: 14px;
                  font-weight: 500;
                }
                .desc {
                  display: flex;
                  gap: 12px;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
          &-wrap {
            display: flex;
            flex-wrap: wrap;
            gap: 16px 8px;
            &-value {
              padding: 8px;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              gap: 4px;
              width: 164px;
              height: 226px;
              border-radius: 8px;
              border: 1px solid $neutral-3;
              background: $neutral-0;
              box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
              .dtl-img {
                width: 148px;
                height: 148px;
                border-radius: 4px;
                border: 1px solid var(--Neutral_05, #f2f2f2);
                background: var(--Neutral_00, #fff);
              }
              p {
                &:nth-child(2) {
                  margin-top: 8px;
                  font-size: 14px;
                  font-weight: 500;
                }
                &:nth-child(3) {
                  text-align: center;
                  color: $neutral-60;
                  font-size: 13px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
      .column {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-name {
          font-size: 14px;
          font-weight: 500;
        }
        &-value {
          display: flex;
          justify-content: space-between;
          p {
            text-align: center;
            color: $neutral-60;
            font-size: 14px;
            font-weight: 400;
          }
        }
        &-btns {
          display: flex;
          gap: 20px;
          &-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            flex: 1;
            border-radius: 8px;
            border: 1px solid $neutral-5;
            /* Text_Button_4480_4 */
            box-shadow: 4px 4px 8px 0px rgba(244, 147, 1, 0.04);
            &.active {
              border: 1px solid $primary-50;
              color: $primary-60;
            }
          }
        }
        &-wrap {
          display: flex;
          justify-content: space-between;
          @media (max-width: 900px) {
            flex-direction: column;
          }
          .print-wrap {
            margin-left: 24px;
            // display: flex;
            // justify-content: center;
            @media (max-width: 900px) {
              margin-left: 0;
            }
            position: relative;
            img {
              @media (max-width: 900px) {
                width: 100%;
                height: auto;
              }
            }
            .print-img {
              margin-top: 20px;
              width: 392px;
              height: 392px;
            }
            &-img {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              width: 60px;
              height: 140px;
              border-radius: 4px;
              border: 1px dashed $neutral-10;
              background: $neutral-0;
              font-size: 12px;
              font-weight: 400;
              line-height: 140%; /* 16.8px */
              color: $neutral-40;
              @media (max-width: 900px) {
                max-width: 60px;
                max-height: 140px;
              }
              @media (max-width: 520px) {
                max-width: 60px;
                max-height: 140px;
                // width: calc(100% - 282px);
                width: 54px;
                height: calc(100% - 230px);
              }
              @media (max-width: 490px) {
                height: calc(100% - 260px);
              }
              @media (max-width: 460px) {
                height: calc(100% - 240px);
              }
              @media (max-width: 430px) {
                width: 15%;
                height: calc(100% - 220px);
              }
              @media (max-width: 400px) {
                width: 14%;
                height: calc(100% - 210px);
              }
              &.tshirt-front {
                display: none;
                &.active {
                  display: block !important;
                }
                &.on {
                  border-color: $primary-50;
                }
                &:nth-child(3) {
                  display: none;
                  top: 65px;
                  left: 39px;
                  width: 40px;
                  height: 40px;
                  @media (min-width: 461px) {
                    top: 45px;
                    left: calc(50% - 170px);
                  }
                  @media (max-width: 460px) {
                    top: 25px;
                    left: calc(50% - 140px);
                  }
                  @media (max-width: 400px) {
                    top: 25px;
                    left: calc(50% - 120px);
                  }
                }
                &:nth-child(4) {
                  display: none;
                  top: 100px;
                  left: 96px;
                  @media (max-width: 900px) {
                    left: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    left: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    left: calc(50% - 76px);
                  }
                }
                &:nth-child(5) {
                  display: none;
                  top: 100px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &:nth-child(6) {
                  display: none;
                  top: 100px;
                  right: 96px;
                  @media (max-width: 900px) {
                    right: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    right: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    right: calc(50% - 76px);
                  }
                }
                &:nth-child(7) {
                  display: none;
                  top: 65px;
                  right: 39px;
                  width: 40px;
                  height: 40px;
                  @media (min-width: 461px) {
                    top: 45px;
                    right: calc(50% - 170px);
                  }
                  @media (max-width: 460px) {
                    top: 25px;
                    right: calc(50% - 140px);
                  }
                  @media (max-width: 400px) {
                    top: 25px;
                    right: calc(50% - 120px);
                  }
                }
                &:nth-child(8) {
                  display: none;
                  top: 103px;
                  left: 0px;
                  width: 40px;
                  height: 40px;
                  @media (max-width: 900px) {
                    top: 90px;
                    left: calc(50% - 210px);
                  }
                  @media (max-width: 460px) {
                    top: 70px;
                    left: calc(50% - 180px);
                  }
                  @media (max-width: 400px) {
                    top: 70px;
                    left: calc(50% - 150px);
                  }
                }
                &:nth-child(9) {
                  display: none;
                  bottom: 25px;
                  left: 96px;
                  @media (max-width: 900px) {
                    left: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    left: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    left: calc(50% - 76px);
                  }
                }
                &:nth-child(10) {
                  display: none;
                  bottom: 25px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &:nth-child(11) {
                  display: none;
                  bottom: 25px;
                  right: 96px;
                  @media (max-width: 900px) {
                    right: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    right: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    right: calc(50% - 76px);
                  }
                }
                &:nth-child(12) {
                  display: none;
                  top: 103px;
                  right: 0px;
                  width: 40px;
                  height: 40px;
                  @media (max-width: 900px) {
                    top: 90px;
                    right: calc(50% - 210px);
                  }
                  @media (max-width: 460px) {
                    top: 70px;
                    right: calc(50% - 180px);
                  }
                  @media (max-width: 400px) {
                    top: 70px;
                    right: calc(50% - 150px);
                  }
                }
              }

              &.mtm-front {
                display: none;
                &.active {
                  display: block !important;
                }
                &.on {
                  border-color: $primary-50;
                }
                &:nth-child(3) {
                  display: none;
                  top: 105px;
                  left: 39px;
                  width: 40px;
                  height: 40px;
                  @media (min-width: 461px) {
                    left: calc(50% - 170px);
                  }
                  @media (max-width: 460px) {
                    top: 95px;
                    left: calc(50% - 150px);
                  }
                  @media (max-width: 400px) {
                    top: 85px;
                    left: calc(50% - 130px);
                  }
                }
                &:nth-child(4) {
                  display: none;
                  top: 85px;
                  left: 102px;
                  @media (max-width: 900px) {
                    left: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    left: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    left: calc(50% - 76px);
                  }
                }
                &:nth-child(5) {
                  display: none;
                  top: 85px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &:nth-child(6) {
                  display: none;
                  top: 85px;
                  right: 102px;
                  @media (max-width: 900px) {
                    right: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    right: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    right: calc(50% - 76px);
                  }
                }
                &:nth-child(7) {
                  display: none;
                  top: 105px;
                  right: 39px;
                  width: 40px;
                  height: 40px;
                  @media (min-width: 461px) {
                    right: calc(50% - 170px);
                  }
                  @media (max-width: 460px) {
                    top: 95px;
                    right: calc(50% - 150px);
                  }
                  @media (max-width: 400px) {
                    top: 85px;
                    right: calc(50% - 130px);
                  }
                }
                &:nth-child(8) {
                  display: none;
                  bottom: 65px;
                  left: 0px;
                  width: 40px;
                  height: 40px;
                }
                &:nth-child(9) {
                  display: none;
                  bottom: 46px;
                  left: 102px;
                  @media (max-width: 900px) {
                    left: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    left: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    left: calc(50% - 76px);
                  }
                }
                &:nth-child(10) {
                  display: none;
                  bottom: 46px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &:nth-child(11) {
                  display: none;
                  bottom: 46px;
                  right: 102px;
                  @media (max-width: 900px) {
                    right: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    right: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    right: calc(50% - 76px);
                  }
                }
                &:nth-child(12) {
                  display: none;
                  bottom: 65px;
                  right: 0px;
                  width: 40px;
                  height: 40px;
                }
              }
              &.tshirt-back {
                display: none;
                &.active {
                  display: block !important;
                }
                &.on {
                  border-color: $primary-50;
                }
                &:nth-child(3) {
                  display: none;
                  top: 66px;
                  left: 96px;
                  @media (max-width: 900px) {
                    left: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    left: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    left: calc(50% - 76px);
                  }
                }
                &:nth-child(4) {
                  display: none;
                  top: 66px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &:nth-child(5) {
                  display: none;
                  top: 66px;
                  right: 96px;
                  @media (max-width: 900px) {
                    right: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    right: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    right: calc(50% - 76px);
                  }
                }
                &:nth-child(6) {
                  display: none;
                  bottom: 25px;
                  left: 96px;
                  @media (max-width: 900px) {
                    left: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    left: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    left: calc(50% - 76px);
                  }
                }
                &:nth-child(7) {
                  display: none;
                  bottom: 25px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &:nth-child(8) {
                  display: none;
                  bottom: 25px;
                  right: 96px;
                  @media (max-width: 900px) {
                    right: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    right: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    right: calc(50% - 76px);
                  }
                }
              }
              &.mtm-back {
                display: none;
                &.active {
                  display: block !important;
                }
                &.on {
                  border-color: $primary-50;
                }
                &:nth-child(3) {
                  display: none;
                  top: 46px;
                  left: 103px;
                  @media (max-width: 900px) {
                    left: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    left: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    left: calc(50% - 76px);
                  }
                }
                &:nth-child(4) {
                  display: none;
                  top: 46px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &:nth-child(5) {
                  display: none;
                  top: 46px;
                  right: 103px;
                  @media (max-width: 900px) {
                    right: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    right: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    right: calc(50% - 76px);
                  }
                }
                &:nth-child(6) {
                  display: none;
                  bottom: 46px;
                  left: 103px;
                  @media (max-width: 900px) {
                    left: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    left: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    left: calc(50% - 76px);
                  }
                }
                &:nth-child(7) {
                  display: none;
                  bottom: 46px;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &:nth-child(8) {
                  display: none;
                  bottom: 46px;
                  right: 103px;
                  @media (max-width: 900px) {
                    right: calc(50% - 96px);
                  }
                  @media (max-width: 430px) {
                    right: calc(50% - 86px);
                  }
                  @media (max-width: 400px) {
                    right: calc(50% - 76px);
                  }
                }
              }
              .file-area {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                height: 100%;
                background-color: $neutral-0;
                img {
                  width: auto;
                  height: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }
      }
      .validation {
        position: absolute;
        bottom: -51px;
        left: 0;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        color: $neutral-20;
        font-size: 12px;
        font-weight: 400;
        .active {
          color: $primary-60;
        }
      }
    }
    &-btn {
      cursor: pointer;
      padding: 40px 0 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 100%;
        height: 40px;
        padding: 9px 0px;
        background: $neutral-20;
        color: $neutral-0;
        font-size: 15px;
        font-weight: 500;
        border-radius: 4px;
        &.active {
          background: $primary-50;
        }
      }
    }
  }
}
.popup-add {
  .popup-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
  }
  &-wrap {
    position: fixed;
    z-index: 530;
    width: 100%;
    // height: calc(100% - 56px);
    overflow-y: auto;
    // padding-bottom: 70px;
    background-color: $neutral-0;
    // height: calc(100vh - 98px);
    max-width: 520px;
    max-height: 246px;
    height: calc(100vh - 150px);
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    border-radius: 8px;
    &-top {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      color: $neutral-0;
      background-color: $primary-50;
      font-size: 16px;
      font-weight: 400;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .close {
        cursor: pointer;
      }
    }
    &-body {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      width: 100%;
      height: 162px;
      .style-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 43px;
        font-size: 14px;
        font-weight: 500;
        span {
          color: $primary-60;
        }
        .valid {
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          border: 1px solid $primary-20;
          background: $primary-5;
          color: $primary-60;
          font-size: 12px;
          font-weight: 500;
          &::before {
            content: "";
            position: absolute;
            bottom: 116px;
            right: 116px;
            border-width: 4px;
            border-style: solid;
            border-color: $primary-20 transparent transparent transparent;
          }
        }
      }
      input {
        // max-width: 265px;
        width: 100%;
        // height: 30px;
        height: 36px;
        padding: 6px 12px;
        background: $neutral-0;
        border-width: 0px 0px 1px 1px;
        border-style: solid;
        border-color: $neutral-20;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        ::placeholder {
          color: $neutral-20;
        }
        &:focus {
          border-color: $primary-50;
        }
        &:disabled {
          background-color: $primary-5;
        }
        &.empty {
          border-color: $secondary-60;
        }
      }
      .content {
        margin-top: 24px;
        width: 412px;
        height: 126px;
        border-radius: 4px;
        background: $neutral-3;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .logo {
          width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          img {
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }

    &-bottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1;
      &-btn {
        @media (min-width: 1290px) {
          background-color: $neutral-60;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          cursor: pointer;
        }
        background-color: $neutral-60;
        text-align: center;
        color: $neutral-0;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        cursor: pointer;
        p {
          padding: 9px;
          flex: 1;
          display: flex;
          justify-content: center;
          gap: 8px;
          background-color: $neutral-40;
          &.active {
            background-color: $primary-50;
          }
        }
        &.relative {
          position: relative;
        }
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}

.popup-remove {
  .popup-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
  }
  &-wrap {
    position: fixed;
    z-index: 530;
    width: 100%;
    // height: calc(100% - 56px);
    overflow-y: auto;
    // padding-bottom: 70px;
    background-color: $neutral-0;
    // height: calc(100vh - 98px);
    max-width: 360px;
    max-height: 261px;
    height: calc(100vh - 150px);
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    border-radius: 8px;
    &-top {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      color: $neutral-0;
      background-color: $primary-50;
      font-size: 16px;
      font-weight: 400;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .close {
        cursor: pointer;
      }
    }
    &-body {
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      width: 100%;
      height: 175px;
      .body-title {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
      }
      .body-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }
    }

    &-bottom {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1;
      &-btn {
        @media (min-width: 1290px) {
          background-color: $neutral-60;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          cursor: pointer;
        }
        background-color: $neutral-60;
        text-align: center;
        color: $neutral-0;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        cursor: pointer;
        p {
          padding: 9px;
          flex: 1;
          display: flex;
          justify-content: center;
          gap: 8px;
          background-color: $neutral-40;
          &.active {
            background-color: $primary-50;
          }
        }
        &.relative {
          position: relative;
        }
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}
.popup-print {
  .popup-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
  }
  &-wrap {
    position: fixed;
    z-index: 530;
    width: 948px;
    max-height: 878px;
    background-color: $neutral-0;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    border-radius: 4px;
    &-top {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      color: $neutral-0;
      background-color: $primary-50;
      font-size: 16px;
      font-weight: 500;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .close {
        cursor: pointer;
      }
    }
    &-body {
      padding: 40px 24px 0;
      display: flex;
      justify-content: center;
      gap: 20px;
      width: 100%;
      .body-left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-wrap {
          padding: 24px;
          width: 440px;
          height: 516px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 24px;
          border-radius: 4px;
          border: 1px solid $neutral-5;
          background: $neutral-0;
          box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
          font-size: 16px;
          font-weight: 500;
          img {
            width: 392px;
            height: 392px;
          }
          &-img {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            input {
              display: none;
            }
            &.on {
              display: block !important;
            }
            &.front {
              &:nth-child(3) {
                display: none;
                top: 102px;
                left: 66px;
              }
              &:nth-child(4) {
                display: none;
                top: 94px;
                left: 118px;
              }
              &:nth-child(5) {
                display: none;
                top: 146px;
                left: 50%;
                transform: translate(-50%, 0);
              }
              &:nth-child(6) {
                display: none;
                top: 94px;
                right: 118px;
              }
              &:nth-child(7) {
                display: none;
                top: 102px;
                right: 66px;
              }
              &:nth-child(8) {
                display: none;
                top: 163px;
                left: 6px;
              }
              &:nth-child(9) {
                display: none;
                bottom: 31px;
                left: 100px;
              }
              &:nth-child(10) {
                display: none;
                bottom: 34px;
                left: 50%;
                transform: translate(-50%, 0);
              }
              &:nth-child(11) {
                display: none;
                bottom: 31px;
                right: 100px;
              }
              &:nth-child(12) {
                display: none;
                top: 163px;
                right: 6px;
              }
            }
            &.back {
              &:nth-child(3) {
                display: none;
                top: 95px;
                left: 118px;
              }
              &:nth-child(4) {
                display: none;
                top: 113px;
                left: 50%;
                transform: translate(-50%, 0);
              }
              &:nth-child(5) {
                display: none;
                top: 95px;
                right: 118px;
              }
              &:nth-child(6) {
                display: none;
                bottom: 32px;
                left: 101px;
              }
              &:nth-child(7) {
                display: none;
                bottom: 34px;
                left: 50%;
                transform: translate(-50%, 0);
              }
              &:nth-child(8) {
                display: none;
                bottom: 31px;
                right: 101px;
              }
            }
            .file-area {
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .body-right {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &-wrap {
          padding: 20px;
          width: 448px;
          height: 516px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          border-radius: 8px;
          border: 1px solid $neutral-5;
          background: $neutral-0;
          box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
          font-size: 13px;
          font-weight: 500;
          &:nth-child(1) {
            height: 204px;
          }
          &:nth-child(2) {
            height: 204px;
          }
          .title {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .num {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            padding: 4px;
            border-radius: 22px;
            border: 1px solid $secondary-60;
          }
          span {
            color: $primary-60;
          }
          .line-type {
            display: flex;
            align-items: center;
            gap: 12px;
            .select-box {
              width: 100%;
            }
          }
        }
        &-file {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 440px;
          height: 68px;
          color: $neutral-20;
          font-size: 13px;
          font-weight: 400;
          border-radius: 4px;
          border: 1px dashed $neutral-20;
          background: $neutral-0;
        }
        .imgs {
          padding: 4px 20px;
          display: flex;
          align-items: center;
          gap: 12px;
          width: 440px;
          height: 68px;
          border-radius: 4px;
          border: 1px dashed $neutral-20;
          background: $neutral-0;
          font-size: 14px;
          font-weight: 400;

          &-pop {
            cursor: pointer;
            width: 16px;
            height: 16px;
            img {
              width: 100%;
            }
          }
          &-img {
            padding: 4px;
            display: flex;
            flex-direction: row;
            gap: 4px;
            width: 60px;
            height: 60px;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            &.active {
              border: 1px dashed $neutral-10;
              // background-color: $neutral-10;
              border-radius: 4px;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: $neutral-30;
            }
            &.error {
              color: $secondary-60;
              border-color: $secondary-60 !important;
            }
            &.view {
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              .img-etc {
                width: 40px;
                height: 40px;
              }
            }
            &-filename {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              min-height: 40px;
              padding: rem(6px);
              background-color: rgba(0, 0, 0, 0.4);
              p {
                @include ellipse(2);
                font-size: 12px;
                color: $neutral-0;
                text-align: center;
                word-break: break-all;
              }
            }
          }
        }
      }
      .tooltip {
        position: relative;
        display: flex;
        cursor: pointer;
        align-items: center;
        &-wrap {
          min-width: 360px;
          visibility: hidden;
          text-align: center;
          padding: 16px;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 30px;
          opacity: 0;
          transition: opacity 0.3s;
          border-radius: 8px;
          background-color: $primary-5;
          border: 1px solid $primary-20;
          font-size: 14px;
          font-weight: 500;
          &.file {
            top: 75px;
            left: 25px;
          }
          &-dtl {
            display: flex;
            flex-direction: column;
            gap: 12px;
            font-size: 14px;
            font-weight: 500;
            &-text {
              display: flex;
              flex-direction: column;
              gap: 12px;
              font-size: 13px;
              font-weight: 500;
              text-align: left;
              color: $neutral-80;
              span {
                color: $neutral-60;
                font-weight: 400;
              }
            }
          }
        }
        &:hover .tooltip-wrap {
          visibility: visible;
          opacity: 1;
        }
      }
      input {
        margin-top: 12px;
        width: 100%;
        height: 36px;
        padding: 6px 12px;
        background: $neutral-0;
        border-width: 0px 0px 1px 1px;
        border-style: solid;
        border-color: $neutral-20;
        border-radius: 4px;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        ::placeholder {
          color: $neutral-30;
        }
        &:focus {
          border-color: $primary-50;
        }
        &:disabled {
          background-color: $primary-5;
        }
      }
    }
    &-bottom {
      .validation {
        position: absolute;
        bottom: 50px;
        right: 20px;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        color: $neutral-20;
        font-size: 12px;
        font-weight: 400;
        .active {
          color: $primary-60;
        }
      }
      .bottom-wrap {
        margin: 20px 20px 60px;
        padding: 24px;
        border-radius: 4px;
        border: 1px solid $neutral-5;
        background: $neutral-0;
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
        font-size: 14px;
        font-weight: 500;
        .column {
          display: flex;
          flex-direction: column;
          gap: 12px;
          &:nth-child(2) {
            margin-top: 20px;
          }
        }
        .check-desc {
          color: $neutral-60;
          font-weight: 400;
          margin-left: 24px;
        }
      }
      &-btn {
        background-color: $neutral-60;
        text-align: center;
        color: $neutral-0;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        cursor: pointer;
        p {
          padding: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          height: 40px;
          flex: 1;
          &.active {
            background-color: $primary-50;
          }
          &.disable {
            background-color: $neutral-30;
          }
        }
        &.relative {
          position: relative;
        }
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}
</style>
