<template>
  <div class="order">
    <div class="bg">
      <div class="bg-gray"></div>
    </div>
    <div class="order-hist">
      <div class="order-hist-title">
        <SvgImg
          :size="20"
          :d="$store.state.meta.svg.orderIcon"
          :color="$store.state.meta.svg.color.primary50"
        />주문 내역
      </div>
      <div class="order-hist-section">
        <div class="order-hist-section-wrap">
          <div class="thumbnail" v-if="apiData.fileUrl">
            <!-- <img :src="apiData.fileUrl" /> -->
            <img v-if="apiData.fileUrl?.length > 31" :src="apiData.fileUrl" />
            <img
              v-else-if="apiData.sleeveLength == '4'"
              class="pd-10"
              src="@/assets/img/img-custom-list-long-sleeve.png"
            />
            <img
              v-else
              class="pd-10"
              src="@/assets/img/img-custom-list-short-sleeve.png"
            />
          </div>
          <div class="style">
            <div class="row">
              <div class="row-name">· 스타일/상품 명</div>
              <div class="row-value">{{ apiData.styleName }}</div>
            </div>
            <div class="row" v-if="$route.path.indexOf('custom/detail') > -1">
              <div class="row-name">· 금액</div>
              <div class="row-value">{{ apiData.styleName }}</div>
            </div>
            <div class="row">
              <div class="row-name">· 원단</div>
              <div class="row-value">
                <p v-for="(x, i) in apiData.fabricColorList" :key="i">
                  {{ i == 0 ? x.fabricName : "" }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="row-name">· 컬러</div>
              <div class="row-color">
                <div
                  class="img-info"
                  v-for="(x, i) in apiData.fabricColorList"
                  :key="i"
                >
                  <img :src="x.fileUrl" />
                  <p class="ml-4">{{ x.colorCode }}</p>
                  <p>{{ x.colorName }}</p>
                </div>
                <!-- <p v-for="(x, i) in apiData.fabricColorList" :key="i">
                  {{ x.colorName }}[{{ x }}]
                </p> -->
              </div>
              <!-- <div class="row-value">{{ apiData.fabricColor }}</div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="order-hist-dashboard">
        <div class="column">
          <div class="column-name">· 최초 등록일</div>
          <div class="column-value">
            <p>{{ apiData.initDate }}</p>
          </div>
        </div>
        <div class="column">
          <div class="column-name">· 최근 주문일</div>
          <div class="column-value">
            <p>{{ apiData.createdDate }}</p>
          </div>
        </div>
        <div class="column">
          <div class="column-name">· 총 주문 수량</div>
          <div class="column-value">
            <p>{{ comma(totalCount) }}</p>
          </div>
        </div>
        <div class="column">
          <div class="column-name">· 총 주문 횟수</div>
          <div class="column-value">
            <p>{{ comma(this.orderList.length) }}</p>
          </div>
        </div>
      </div>

      <div class="order-hist-list">
        <div class="list-head">
          <p>NO.</p>
          <p>상태</p>
          <p>주문 일자</p>
          <p>배송 일자</p>
          <p>제작 소요기간</p>
          <p>수량</p>
          <p>단가</p>
          <p>금액</p>
        </div>
        <div class="list-body">
          <div
            class="list-body-row"
            v-for="(orderList, i) in orderList"
            :key="i"
          >
            <p>{{ i + 1 }}</p>
            <p>{{ orderList.orderStatus }}</p>
            <p class="pointer" @click="openSize(orderList.orderNo)">
              {{ orderList.createdDate }}
            </p>
            <p>{{ orderList.deliveryDate ? orderList.deliveryDate : "-" }}</p>
            <p>{{ orderList.requiredDay ? orderList.requiredDay : "-" }}</p>
            <p v-if="orderList.sampleCount > 0">
              {{ comma(orderList.sampleCount) }}
            </p>
            <p v-else>{{ comma(orderList.unitCount) }}</p>
            <p>{{ comma(orderList.salePrice) }}</p>
            <p>{{ comma(orderList.totalPrice) }}</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
  <div class="popup-size" v-if="isSize">
    <div class="popup-background" @click="isSize = false"></div>
    <div class="popup-size-wrap">
      <div class="popup-size-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.list"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>주문 세부 내역</p>
        </div>
        <SvgImg
          class="pointer"
          :size="20"
          :d="$store.state.meta.svg.closePopup"
          :color="$store.state.meta.svg.color.neutral0"
          @click="isSize = false"
        />
      </div>

      <div class="popup-size-wrap-body">
        <SizeColorTable
          :inputType="'none'"
          :color="this.apiData.fabricColorList"
          :value="this.sizeData"
        />
      </div>

      <div class="popup-size-wrap-bottom">
        <div class="popup-size-wrap-bottom-btn pointer">
          <p class="active" @click="isSize = false">확인</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomOrderList",
  data() {
    return {
      isSize: false,
      apiData: {},
      orderList: [],
      sizeData: [],
      step: 0,
      // apiData: {},
      userId: sessionStorage.getItem("userId"),
      compType: sessionStorage.getItem("compType"),
      orderSizeNo: "",
      totalCount: 0,
    };
  },
  mounted() {
    this.customNo = this.$route.params.customNo;
    // this.$API.custom.customOrder(this.userId, this.customNo).then((res) => {
    //   this.apiData = res.data.body;
    // });
    console.log(this.apiData);
    // printFrontImgs

    this.$API.custom.customOrder(this.userId, this.customNo).then((res) => {
      this.apiData = res.data.body;
    });
    this.$API.custom.customOrderList(this.userId, this.customNo).then((res) => {
      this.orderList = res.data.body;
      this.orderList.forEach((e) => {
        this.totalCount += Number(e.unitCount);
      });
    });
  },
  methods: {
    openSize(orderNo) {
      this.$API.custom.customOrderSize(this.userId, orderNo).then((res) => {
        console.log(res.data.body);
        this.sizeData = res.data.body;

        // :inputType="'text'"
        //   :color="apiData.colorList"
        //   :size="apiData.sizeList"
        this.isSize = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  justify-content: center;
  height: calc(100vh - 70px);
  overflow: auto;
  .bg {
    position: fixed;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    &-white {
      flex: 1;
      background: $neutral-0;
    }
    &-gray {
      flex: 2;
      background: $neutral-3;
    }
  }

  &-hist {
    width: 900px;
    background: $neutral-3;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-title {
      padding: 40px 0 20px;
      display: flex;
      gap: 8px;
      font-size: 18px;
      font-weight: 500;
    }
    &-section {
      display: flex;
      flex-direction: column;
      gap: 28px;
      padding: 24px;
      border-radius: 8px;
      background: $neutral-0;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
      &-wrap {
        display: flex;
        gap: 40px;
        .thumbnail {
          width: 180px;
          height: 180px;
          border-radius: 4px;
          overflow: hidden;
          background-color: $neutral-20;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }
        .style {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
      .title {
        display: flex;
        align-items: flex-end;
        gap: 20px;
        &-name {
          display: flex;
          padding: 2px 0px;
          align-items: center;
          p {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            border-bottom: 1px solid $primary-50;
          }
        }
        &-desc {
          color: $neutral-60;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
      .row {
        position: relative;
        display: flex;
        align-items: center;
        &-name {
          position: absolute;
          top: 0;
          left: 20px;
          font-size: 14px;
          font-weight: 500;
        }
        &-value {
          display: flex;
          gap: 20px;
          margin-left: 180px;
          font-size: 14px;
          font-weight: 400;
          width: 100%;
        }
        &-color {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          margin-left: 180px;
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          max-width: 516px;
          .img-info {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 14px;
            font-weight: 400;
            img {
              width: 20px;
              height: 20px;
              max-width: 100%;
              max-height: 100%;
              border-radius: 4px;
              border: 1px solid $neutral-10;
            }
          }
        }
      }
    }
    &-dashboard {
      display: flex;
      gap: 20px;
      .column {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        width: 210px;
        height: 100px;
        border-radius: 4px;
        background: $neutral-0;
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
        &-name {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          font-size: 14px;
          font-weight: 500;
        }
        &-value {
          display: flex;
          justify-content: center;
          width: 100%;
          gap: 20px;
          left: 140px;
          font-size: 16px;
          font-weight: 500;
          color: $primary-60;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-radius: 8px;
      background: $neutral-0;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
      .list-head {
        display: flex;
        gap: 8px;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          color: $neutral-0;
          font-size: 14px;
          font-weight: 500;
          border-radius: 4px;
          background: $neutral-60;
          &:nth-child(1) {
            width: 60px;
          }
          &:nth-child(2) {
            width: 96px;
          }
          &:nth-child(3) {
            width: 110px;
          }
          &:nth-child(4) {
            width: 110px;
          }
          &:nth-child(5) {
            width: 100px;
          }
          &:nth-child(6) {
            width: 100px;
          }
          &:nth-child(7) {
            width: 100px;
          }
          &:nth-child(8) {
            width: 120px;
          }
        }
      }
      .list-body {
        &-row {
          padding: 20px 0;
          display: flex;
          gap: 8px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          &:not(:last-child) {
            border-bottom: 1px solid $neutral-10;
          }
          p {
            padding: 8px;
            text-align: center;
            &:nth-child(1) {
              width: 60px;
            }
            &:nth-child(2) {
              width: 96px;
            }
            &:nth-child(3) {
              width: 110px;
            }
            &:nth-child(4) {
              width: 110px;
            }
            &:nth-child(5) {
              width: 100px;
            }
            &:nth-child(6) {
              width: 100px;
              text-align: right;
            }
            &:nth-child(7) {
              width: 100px;
              text-align: right;
            }
            &:nth-child(8) {
              width: 120px;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
.popup-size {
  .popup-background {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 500;
  }
  &-wrap {
    position: fixed;
    z-index: 530;
    width: 100%;
    // height: calc(100% - 56px);
    overflow-y: auto;
    // padding-bottom: 70px;
    background-color: $neutral-0;
    // height: calc(100vh - 98px);
    max-width: 900px;
    max-height: calc(100vh - 150px);
    // height: calc(100vh - 150px);
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    border-radius: 4px;
    @include hidden-scroll();
    &-top {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
      color: $neutral-0;
      background-color: $primary-50;
      font-size: 18px;
      font-weight: 500;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .close {
        cursor: pointer;
      }
    }
    &-body {
      padding: 40px 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-bottom {
      bottom: 0;
      width: 100%;
      z-index: 1;
      &-btn {
        @media (min-width: 1290px) {
          background-color: $neutral-60;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          cursor: pointer;
        }
        background-color: $neutral-60;
        text-align: center;
        color: $neutral-0;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        cursor: pointer;
        p {
          padding: 9px;
          flex: 1;
          display: flex;
          justify-content: center;
          gap: 8px;
          &.active {
            background-color: $primary-50;
          }
        }
        &.relative {
          position: relative;
        }
        &.active {
          background-color: $primary-50;
        }
      }
    }
  }
}
</style>
