<template>
  <div class="detail">
    <div class="detail-top">
      <CompMaterialBuyCard @openLogin="openLogin" :listData="listData" />
    </div>
    <div class="detail-body" :class="isMobile == 'true' ? 'flex-column' : ''">
      <div class="detail-body-wrap">
        <p class="subtitle">배송 정보</p>
        <div class="info">
          <p class="name">받는 사람</p>
          <p class="content">{{ detailData.userName }}</p>
        </div>
        <div class="info">
          <p class="name">연락처</p>
          <p class="content">{{ detailData.telNum }}</p>
        </div>
        <div class="info">
          <p class="name">받는 주소</p>
          <p class="content">
            {{ detailData.address }} {{ detailData.addressDetail }}
          </p>
        </div>
        <div class="info" v-if="detailData.deliveryMemo">
          <p class="name">배송 메모</p>
          <p class="content">{{ detailData.deliveryMemo }}</p>
        </div>
      </div>
      <div :class="isMobile == 'true' ? 'line-d10' : 'line-h10 mr-20'"></div>
      <div class="detail-body-wrap">
        <p class="subtitle">결제 정보</p>
        <div class="info">
          <p class="name">결제 수단</p>
          <p class="content">{{ detailData.paymethod }}</p>
        </div>
        <div class="info">
          <p class="name">상품 가격</p>
          <p class="content">
            {{ comma(detailData.paymentAmount - detailData.deliveryPrice) }} 원
          </p>
        </div>
        <div class="info">
          <p class="name">배송비</p>
          <p class="content">{{ comma(detailData.deliveryPrice) }} 원</p>
        </div>
        <div class="info">
          <p class="name">주문 금액</p>
          <p class="content">{{ comma(detailData.paymentAmount) }} 원</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompMaterialBuyDetail",
  props: {
    // basketItem: {
    //   type: Object,
    // },
  },
  activated() {
    console.log("bbb");
  },
  data() {
    return {
      d: {},
      i: [],
      paymentNo: "",
      orderNum: "",
      regImgUrl: "",
      regImgName: "",
      detailData: {},
      listData: [],
      addItem: [],
      sumPrice: 0,
      sumCount: 0,
      deliveryPrice: 0,
      color: [
        {
          index: 0,
          cd: "",
          name: "[필수] 1절 이상 주문 가능합니다.",
          isActive: true,
        },
      ],
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  unmounted() {
    this.$store.state.meta.detailTitle = "";
  },
  mounted() {
    console.log(this.$store.state.meta.detailTitle);

    this.orderNum = this.$route.params.orderNum;

    const filters = { userId: this.userId, orderNum: this.orderNum };
    this.$API.mall.getBuyList(filters).then((res) => {
      console.log(res);
      this.listData = res.data.body;
      console.log(this.listData);
    });
    this.$API.mall.getBuyDetail(this.orderNum).then((res) => {
      console.log(res);
      this.detailData = res.data.body;
      this.detailData.deliveryMemo = this.detailData.deliveryMemo?.replace(
        /\n/g,
        "<br>"
      );
      console.log(this.detailData);
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.detail {
  padding: 40px;
  @media (max-width: 520px) {
    padding: 40px 0 0;
  }
  &-title {
    display: flex;
    flex-direction: column;
    @media (max-width: 520px) {
      margin: 0 20px;
    }
    &-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .select-box {
        border: 1px solid $neutral-10;
      }
      .row {
        display: flex;
        align-items: center;
        &-name {
          flex: 1;
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          position: absolute;
        }
        &-content {
          flex: 3;
          color: $neutral-60;
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
          margin-left: 134px;
          @media (max-width: 520px) {
            margin-left: 130px;
          }
        }
      }
    }
    &-btns {
      display: flex;
      justify-content: center;
      &-btn {
        cursor: pointer;
        flex: 1;
        height: 40px;
        color: $neutral-0;
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $neutral-60;
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px 4px 4px 0px;
          background-color: $primary-50;
        }
        &.one {
          border-radius: 4px;
        }
      }
    }
  }
  &-top {
    // margin-top: 20px;
  }
  &-body {
    @media (max-width: 520px) {
      margin: 40px 20px;
    }
    margin-top: 40px;
    display: flex;
    gap: 20px;
    &-wrap {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1;
      .subtitle {
        margin-bottom: 8px;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
      }
      .info {
        display: flex;
        position: relative;
        .name {
          position: absolute;
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          color: $neutral-60;
        }
        .content {
          margin-left: 130px;
          color: $neutral-60;
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
          max-width: 320px;
          overflow-wrap: break-word;
        }
      }
    }
  }
}
</style>
