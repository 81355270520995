<template>
  <div id="header">
    <div id="header-wrap">
      <div v-if="isMobile == false || isMobile == 'false'" class="top-menu">
        <div v-if="isMobile !== 'true' || isMobile == false" class="right">
          <div class="flex gap20">
            <router-link to="/">
              <div class="logo osle mr-40">
                <img
                  :src="
                    require(`@/assets/logo/osle-logo${
                      isMobile == false || isMobile == 'false' ? '-pc' : ''
                    }.svg`)
                  "
                />
              </div>
            </router-link>
            <p
              v-if="compType == '1'"
              :class="this.$route.path === '/ticket/list' ? 'active' : ''"
              @click="$emit('matchingPopup', true)"
            >
              견적 요청
            </p>
            <p
              :class="
                $route.path.split('/')[1].length > 0 &&
                'search'.includes($route.path.split('/')[1])
                  ? 'active'
                  : ''
              "
              @click="gotoPage('/search/factorylist')"
            >
              공장 찾기
            </p>
            <p
              :class="
                $route.path.split('/')[1].length > 0 &&
                'designorder'.includes($route.path.split('/')[1])
                  ? 'active'
                  : ''
              "
              @click="gotoPage('/designorder/list')"
            >
              매칭 찾기
            </p>
            <p
              v-if="!userId || compType == '1'"
              :class="
                $route.path.split('/')[1].length > 0 &&
                'custom'.includes($route.path.split('/')[1])
                  ? 'active'
                  : ''
              "
              @click="gotoPage('/custom/list')"
            >
              커스텀 몰
            </p>
            <p
              :class="
                $route.path.split('/')[1].length > 0 &&
                'mall'.includes($route.path.split('/')[1])
                  ? 'active'
                  : ''
              "
              @click="gotoPage('/mall/materiallist')"
            >
              오슬상사
            </p>
            <p
              v-if="userId && compType == '1'"
              :class="
                $route.path.split('/')[1].length > 0 &&
                'custom'.includes($route.path.split('/')[1])
                  ? 'active'
                  : ''
              "
              @click="gotoPage('/mate/main')"
            >
              오슬 메이트 +
            </p>
            <p
              v-if="compType == '2'"
              :class="this.$route.path === '/ticket/list' ? 'active' : ''"
              @click="gotoPage('/ticket/list')"
            >
              이용권
            </p>
          </div>
          <div class="flex gap20">
            <p
              v-if="!userId"
              @click="this.$emit('openLogin')"
              class="flex gap8"
            >
              <SvgImg
                :size="16"
                :d="$store.state.meta.svg.loginIcon"
                :color="$store.state.meta.svg.color.primary50"
              />
              로그인
            </p>
            <p
              v-if="!userId"
              class="flex gap8"
              @click="gotoPage('/account/register/type')"
            >
              <SvgImg
                :size="16"
                :d="$store.state.meta.svg.userAddSmallIcon"
                :color="$store.state.meta.svg.color.primary50"
              />
              회원가입
            </p>
            <p
              v-if="userId"
              class="flex gap8"
              @click="gotoPage('/mypage/menu')"
            >
              <SvgImg
                :size="16"
                :d="$store.state.meta.svg.userProfileIcon"
                :color="$store.state.meta.svg.color.primary50"
              />
              마이페이지
            </p>
            <p class="inquire-btn" @click="gotoPage('/mypage/inquire')">
              1:1 문의
            </p>
            <!-- <img
              v-else
              :src="
                require(`@/assets/icon/ic-profile${
                  $route.path.indexOf('/mypage/') !== -1 ? '-yellow' : ''
                }.svg`)
              "
              class="pointer ml-20"
              @click="gotoPage('/mypage/menu')"
            /> -->
          </div>
        </div>
      </div>
      <div class="header-mobile" v-if="isMobile == true || isMobile == 'true'">
        <div v-if="isMobile == true || isMobile == 'true'" class="logo">
          <div class="back pointer" @click="backClick()">
            <SvgImg
              :size="16"
              :d="$store.state.meta.svg.backIcon"
              :color="$store.state.meta.svg.color.neutral20"
            />
          </div>
          <div class="mobile-logo" @click="gotoPage('/')">
            <img src="@/assets/logo/osle-logo.svg" />
          </div>
        </div>
        <div
          v-if="
            $store.state.meta.popupInfo.isShow ||
            $store.state.meta.headerTitle[$route.path]
          "
          class="title"
        >
          <!-- '/' + $route.path.split('/')[0] + '/' + $route.path.split('/')[1] -->
          <p v-if="$store.state.meta.popupInfo.specialTitle" class="drag-block">
            {{ $store.state.meta.popupInfo.specialTitle }}
          </p>
          <p v-else-if="$store.state.meta.popupInfo.isShow" class="drag-block">
            {{ $store.state.meta.popupInfo.title }}
          </p>
          <p v-else class="drag-block">
            {{ $store.state.meta.headerTitle[$route.path] }}
          </p>
        </div>
        <div v-else-if="$store.state.meta.detailTitle" class="title">
          <p class="drag-block">{{ $store.state.meta.detailTitle }}</p>
        </div>
        <div
          v-if="
            (isMobile == true || isMobile == 'true') &&
            (userId || $route.path != '/')
          "
          class="menu pointer"
          @click="gotoPage('/mypage/menu')"
        >
          <img src="@/assets/icon/ic-mobile-menu.svg" />
        </div>
        <div v-else class="menu pointer">
          <p v-if="!userId" @click="this.$emit('openLogin')" class="flex gap8">
            <SvgImg
              :size="16"
              :d="$store.state.meta.svg.loginIcon"
              :color="$store.state.meta.svg.color.primary50"
            />
            로그인
          </p>
          <p
            v-if="!userId"
            class="flex gap8"
            @click="gotoPage('/account/register/type')"
          >
            <SvgImg
              :size="16"
              :d="$store.state.meta.svg.userAddSmallIcon"
              :color="$store.state.meta.svg.color.primary50"
            />
            회원가입
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-show="isMenu">
    <div class="modal-background" @click="isMenu = false"></div>
    <div class="modal-menu">
      <!-- <div class="close" @click="isMenu = false">
        <img src="@/assets/icon/ic-popup-close.svg" />
      </div> -->
      <!-- <div class="modal-menu-top" v-if="!userId">
        <div class="title"><p>로그인 해주세요.</p></div>
        <div class="top-btns">
          <div
            class="top-btns-btn"
            @click="
              isMenu = false;
              this.$emit('openLogin');
            "
          >
            <img src="@/assets/icon/ic-log-in.svg" />
            <p>로그인</p>
          </div>
          <div
            class="top-btns-btn"
            @click="
              isMenu = false;
              gotoPage('/account/register/type');
            "
          >
            <img src="@/assets/icon/ic-user-plus.svg" />
            <p>회원가입</p>
          </div>
        </div>
      </div> -->
      <div class="modal-menu-top">
        <div class="profile">
          <div class="profile-top signup">
            <p v-if="userId">
              {{
                userInfo.mailId
                  ? userInfo.mailId + "@" + userInfo.mailName
                  : userInfo.userName
              }}
            </p>
            <p v-else>비 회원</p>
          </div>
          <div class="profile-bottom" v-if="userId">
            <div
              class="profile-bottom-btn"
              @click="
                gotoPage('/mypage/menu');
                isMenu = false;
              "
            >
              <img src="@/assets/icon/ic-user.svg" />
              <p>마이페이지</p>
            </div>
          </div>
          <!-- <div
            v-if="compType == '1'"
            class="profile-bottom"
            @click="
              gotoPage('/designorder/add');
              isMenu = false;
            "
          >
            <div class="profile-bottom-btn">
              <img src="@/assets/icon/ic-plus-square.svg" />
              <p>매칭 등록</p>
            </div>
          </div> -->
          <div v-if="compType == '2'" class="profile-bottom">
            <div
              class="profile-bottom-btn"
              @click="
                copyToClipboard(userInfo.factoryNo);
                isMenu = false;
              "
            >
              <p>
                결제 URL
                <input ref="inputText" class="hidden" v-model="factoryPayUrl" />
              </p>
              <img src="@/assets/icon/ic-copy.svg" />
            </div>
          </div>
          <div
            v-else-if="!userId"
            class="profile-bottom signup"
            @click="
              gotoPage('/account/register/type');
              isMenu = false;
            "
          >
            <!-- this.$emit('openLogin');
              isMenu = false; -->

            <div class="profile-bottom-btn">
              <span class="material-symbols-outlined"> login </span>
              <p class="login">회원가입</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal-menu-body"
        :class="{ login: userId, comp: compType == '1' }"
      >
        <div class="dashboard" v-if="userId">
          <!-- <p>활동 정보</p> -->

          <!-- <div class="dashboard-info">
          <p>활동 정보</p>
        </div> -->
          <div class="wd100" @click="clickList('isAlarm')">
            <div class="dashboard-menu">
              <div class="flex gap8">
                <img src="@/assets/icon/ic-notifications.svg" />
                <p>알림 ({{ dashboardData.alarmCount }})</p>
              </div>
              <img
                :src="
                  require(`@/assets/icon/ic-arrowdrop${
                    isAlarm ? '-up' : '-down'
                  }.svg`)
                "
              />
            </div>
            <div v-if="isAlarm" class="info-list">
              <div
                class="info-list-item pointer"
                v-for="(alarmList, i) in dashboardData.alarmList"
                :key="i"
              >
                <div @click="alarmRead(i)">
                  <p class="title">
                    [{{ alarmList.alarmTypeName }}]&nbsp;&nbsp;
                  </p>
                  <p class="content">
                    {{ alarmList.title }}
                  </p>
                </div>
                <p>{{ alarmList.timer }}</p>
              </div>
              <div v-if="dashboardData.alarmCount == '0'" class="no-list"></div>
            </div>
            <div
              v-if="isAlarm"
              class="view"
              @click="
                gotoPage('/mypage/alarmlist');
                isMenu = false;
              "
            >
              <img src="@/assets/icon/ic-list.svg" />
              <p>전체 보기</p>
            </div>
          </div>
          <div
            v-if="compType == '1'"
            class="wd100"
            @click="clickList('isDesignOrder')"
          >
            <div class="dashboard-menu">
              <div class="flex gap8">
                <img src="@/assets/icon/ic-list-black.svg" />
                <p>매칭 ({{ dashboardData.designOrderCount }})</p>
              </div>
              <img
                :src="
                  require(`@/assets/icon/ic-arrowdrop${
                    isDesignOrder ? '-up' : '-down'
                  }.svg`)
                "
              />
            </div>
            <div v-if="isDesignOrder" class="info-list">
              <div
                class="info-list-item pointer"
                v-for="(designOrderList, i) in dashboardData.designOrderList"
                :key="i"
                @click="goDesignOrderDetail(designOrderList.designOrderNo)"
              >
                <div>
                  <p class="content">{{ designOrderList.styleName }}</p>
                </div>
                <p>{{ designOrderList.timer }}</p>
              </div>
              <div
                v-if="dashboardData.designOrderCount == '0'"
                class="no-list"
              ></div>
            </div>
            <div
              v-if="isDesignOrder"
              class="view"
              @click="
                gotoPage('/designorder/list');
                isMenu = false;
              "
            >
              <img src="@/assets/icon/ic-list.svg" />
              <p>전체 보기</p>
            </div>
          </div>
          <div
            v-if="compType == '2'"
            class="wd100"
            @click="clickList('isCalculate')"
          >
            <div class="dashboard-menu">
              <div class="flex gap8">
                <img src="@/assets/icon/ic-bill.svg" />
                <p>
                  계산서
                  {{
                    `${
                      dashboardData.calculateCount > 0
                        ? "(" + dashboardData.calculateCount + ")"
                        : ""
                    }`
                  }}
                </p>
              </div>
              <img
                :src="
                  require(`@/assets/icon/ic-arrowdrop${
                    isCalculate ? '-up' : '-down'
                  }.svg`)
                "
              />
            </div>
            <div v-if="isCalculate" class="info-list">
              <div
                class="info-list-item pointer"
                v-for="(calc, i) in dashboardData.calculateList"
                :key="i"
                @click="gotoPage(`/calculate/list/${calc.paymentDtlNo}`)"
              >
                <div>
                  <p class="content">
                    {{
                      calc.compName
                        ? calc.compName
                        : calc.userName
                        ? calc.userName
                        : "비회원 결제"
                    }}
                  </p>
                </div>
                <p>{{ calc.timer }}</p>
              </div>
              <div
                v-if="dashboardData.calculateCount == '0'"
                class="no-list"
              ></div>
            </div>
            <div
              v-if="isCalculate"
              class="view"
              @click="
                gotoPage('/calculate/list');
                isMenu = false;
              "
            >
              <img src="@/assets/icon/ic-list.svg" />
              <p>전체 보기</p>
            </div>
          </div>
          <div
            v-if="compType == '2'"
            class="wd100"
            @click="clickList('isManage')"
          >
            <div class="dashboard-menu">
              <div class="flex gap8">
                <img src="@/assets/icon/ic-payment.svg" />
                <p>
                  정산
                  {{
                    `${
                      dashboardData.manageCount > 0
                        ? "(" + dashboardData.manageCount + ")"
                        : ""
                    }`
                  }}
                </p>
              </div>
              <img
                :src="
                  require(`@/assets/icon/ic-arrowdrop${
                    isManage ? '-up' : '-down'
                  }.svg`)
                "
              />
            </div>
            <div v-if="isManage" class="info-list">
              <!-- @click="goDesignOrderDetail(payment.designOrderNo)" -->
              <div
                class="info-list-item pointer"
                v-for="(calc, i) in dashboardData.manageList"
                :key="i"
              >
                <div>
                  <p class="content">
                    {{
                      calc.compName
                        ? calc.compName
                        : calc.userName
                        ? calc.userName
                        : "비회원 결제"
                    }}
                  </p>
                </div>
                <p>{{ calc.timer }}</p>
              </div>
              <div
                v-if="dashboardData.manageCount == '0'"
                class="no-list"
              ></div>
            </div>
            <div
              v-if="isManage"
              @click="
                gotoPage('/manage/list');
                isMenu = false;
              "
              class="view"
            >
              <img src="@/assets/icon/ic-list.svg" />
              <p>전체 보기</p>
            </div>
          </div>
          <div
            v-if="compType == '1'"
            class="wd100"
            @click="clickList('isPayment')"
          >
            <div class="dashboard-menu">
              <div class="flex gap8">
                <img src="@/assets/icon/ic-payment.svg" />
                <p>
                  결제
                  {{
                    `${
                      dashboardData.paymentCount > 0
                        ? "(" + dashboardData.paymentCount + ")"
                        : ""
                    }`
                  }}
                </p>
              </div>
              <img
                :src="
                  require(`@/assets/icon/ic-arrowdrop${
                    isPayment ? '-up' : '-down'
                  }.svg`)
                "
              />
            </div>
            <div v-if="isPayment" class="info-list">
              <div
                class="info-list-item pointer"
                v-for="(payment, i) in dashboardData.paymentList"
                :key="i"
                @click="goPaymentDetail(payment.paymentNo)"
              >
                <div>
                  <p class="content">{{ payment.factoryName }}</p>
                </div>
                <p>{{ payment.timer }}</p>
              </div>
              <div
                v-if="dashboardData.paymentCount == '0'"
                class="no-list"
              ></div>
            </div>
            <div
              v-if="isPayment"
              class="view"
              @click="
                gotoPage('/payment/list');
                isMenu = false;
              "
            >
              <img src="@/assets/icon/ic-list.svg" />
              <p>전체 보기</p>
            </div>
          </div>
          <!-- <div class="wd100" @click="clickList('isContract')">
          <div class="dashboard-menu">
            <div class="flex gap8">
              <img src="@/assets/icon/ic-clipboard-list.svg" />
              <p>발주 ({{ dashboardData.contractCount }})</p>
            </div>
            <img
              :src="
                require(`@/assets/icon/ic-arrowdrop${
                  isContract ? '-up' : '-down'
                }.svg`)
              "
            />
          </div>
          <div v-if="isContract" class="info-list">
            <div
              class="info-list-item pointer"
              v-for="(contractList, i) in dashboardData.contractList"
              :key="i"
              @click="goContractDetail(contractList.contractNo)"
            >
              <div>
                <p v-if="compType == '1'">{{ contractList.factoryCompName }}</p>
                <p v-if="compType == '2'">
                  {{ contractList.designerCompName }}
                </p>
              </div>
              <p>{{ contractList.timer }}</p>
            </div>
            <div
              v-if="dashboardData.contractCount == '0'"
              class="no-list"
            ></div>
          </div>
          <div
            v-if="isContract"
            class="view"
            @click="gotoPage('/contract/list')"
          >
            <img src="@/assets/icon/ic-list.svg" />
            <p>전체 보기</p>
          </div>
        </div> -->
          <!-- <div class="dashboard-menu" @click="gotoPage('/webchats')">
          <div class="flex gap8">
            <img src="@/assets/icon/ic-message-circle-black.svg" />
            <p>메시지</p>
          </div>
          <img
            :src="
              require(`@/assets/icon/ic-arrowdrop${
                isMessage ? '-up' : '-down'
              }.svg`)
            "
          />
        </div> -->
        </div>
      </div>
      <div class="modal-menu-bottom">
        <div
          class="inquire"
          @click="
            gotoPage('/mypage/inquire');
            isMenu = false;
          "
        >
          <div class="inquire-wrap">
            <p>문의하기</p>
          </div>
        </div>
        <div class="logout" v-if="userId">
          <div class="logout-wrap">
            <div class="logout-wrap-btn pointer" @click="showLogout = true">
              <!-- <img src="@/assets/icon/ic-logout.svg" /> -->
              <span class="material-symbols-outlined"> logout </span>
              <p>로그아웃</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showLogout" class="modal">
    <div class="modal-background bg-1001" @click="showLogout = false"></div>
    <div class="modal-logout">
      <p>로그아웃 하시겠습니까 !?</p>
      <div class="modal-logout-btns">
        <p class="btns-btn" @click="showLogout = false">아니오</p>
        <p class="btns-btn active" @click="logout()">예</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  emits: ["openLogin"],
  data() {
    return {
      showFirstName: "",
      isModalShow: false,
      isMenu: false,
      sortOrder: "",
      alarmReadCount: 0,
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get socialType() {
        return sessionStorage.getItem("socialType");
      },
      get mailId() {
        return sessionStorage.getItem("mailId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        if (sessionStorage.getItem("isMobile")) {
          return sessionStorage.getItem("isMobile");
        } else {
          return (
            navigator.userAgent.indexOf("iPhone") > -1 ||
            navigator.userAgent.indexOf("Android") > -1
          );
        }
      },
      dashboardData: {},
      userInfo: {},
      profileImg: "",
      isAlarm: false,
      isDesignOrder: false,
      isCalculate: false,
      isPayment: false,
      isContract: false,
      isMessage: false,
      isManage: false,
      factoryPayUrl: "",
      showLogout: false,
    };
  },
  mounted() {
    this.$API.mypage.alarmCheck(this.userId).then((res) => {
      this.alarmReadCount = res.data.body;
    });
    if (this.compType == "1") {
      this.$API.mypage
        .getDesignerInfo(sessionStorage.getItem("userId"))
        .then((res) => {
          this.userInfo = res.data.body;
          this.profileImg = this.userInfo.profileFileUrl;
        });
    }
    if (this.compType == "2") {
      this.$API.mypage
        .getFactoryInfo(sessionStorage.getItem("userId"))
        .then((res) => {
          this.userInfo = res.data.body;
          this.profileImg = this.userInfo.profileFileUrl;
          console.log(this.userInfo);
        });
    }
    if (this.compType && sessionStorage.getItem("userId")) {
      this.$API.comm
        .commData(sessionStorage.getItem("userId"), this.compType)
        .then((res) => {
          this.dashboardData = res.data.body;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.meta.windowWidth;
    },
  },
  methods: {
    clickList(btnName) {
      const isBtn = this[btnName];
      this.isAlarm = false;
      this.isDesignOrder = false;
      this.isCalculate = false;
      this.isPayment = false;
      this.isContract = false;
      this.isManage = false;
      this[btnName] = !isBtn;
    },
    openMenu() {
      this.isMenu = true;
    },
    backClick() {
      if (this.$store.state.meta.popupInfo.isShow) {
        this.setPopup(false);
      } else {
        // this.$store.state.meta.returnLoginPath = ''
        this.$router.go(-1);
      }
    },
    toggleMenuShow() {
      this.$store.state.meta.showMoreMenu =
        !this.$store.state.meta.showMoreMenu;
    },
    closeMenu() {
      this.$store.state.meta.showMoreMenu = false;
    },
    gotoChatRoom(url) {
      // this.$router.push(url);
      this.$router.push(url).then(() => {
        location.reload();
      });
    },
    goDesignOrderDetail(designOrderNo) {
      if (this.compType == "1") {
        location.href = "/designorder/detail/factory/" + designOrderNo;
        // this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
      } else {
        location.href = "/designorder/detail/order/" + designOrderNo;
        // this.gotoPage(`/designorder/detail/order/${designOrderNo}`);
      }
    },
    goPaymentDetail(paymentNo) {
      if (this.compType == "1") {
        location.href = "/payment/list/" + paymentNo;
        // this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
      } else {
        location.href = "/payment/list/" + paymentNo;
        // this.gotoPage(`/designorder/detail/order/${designOrderNo}`);
      }
    },
    goContractDetail(contractNo) {
      if (this.compType == "1") {
        location.href = "/contract/list/" + contractNo;
        // this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
      } else {
        location.href = "/contract/list/" + contractNo;
        // this.gotoPage(`/designorder/detail/order/${designOrderNo}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  // @include set-width(true);
  position: fixed;
  z-index: 550;
  top: 0;
  width: 100%;
  background: $neutral-0;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  &-wrap {
    margin: auto;
    max-width: 1290px;
    position: relative;
    width: 100%;
    height: rem(48px);
    @include tablet {
      height: rem(70px);
    }
    @media (max-width: 800px) {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .header-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .logo {
        display: flex;
        align-items: center;
        gap: 20px;
        // position: absolute;
        margin-right: 20px;
        height: 16px;
      }
      .mobile-logo {
        height: 24px;
        img {
          width: 60px;
          height: 24px;
        }
      }
      .menu {
        // position: absolute;
        margin-left: 20px;
        display: flex;
        gap: 20px;
        top: 16px;
        right: 20px;
        font-size: 12px;
        font-weight: 500;
        img {
          width: 16px;
          height: 16px;
        }
        // margin: 11px 20px 0 0;
      }
      .back {
        top: 16px;
        left: 20px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .osle {
      top: 17px;
      left: 0;
      // @include tablet {
      //   top: rem(12px);
      //   left: rem(40px);
      // }
      img {
        height: 36px;
        // @include tablet {
        //   height: rem(46px);
        // }
      }
    }
    .title {
      width: 100%;
      height: 100%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 32px;
      }
      p {
        font-size: 16px;
        letter-spacing: -0.5px;
      }
    }
    .top-menu {
      @include set-width-pc();
      // padding: 0 80px;
      width: 100%;
      height: 100%;
      justify-content: space-evenly;
      .right {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        font-weight: 500;
        font-size: 16px;
        // line-height: 24px;
        color: $neutral-80;
        p {
          padding: 8px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 100px;
          // height: 40px;
          position: relative;
          cursor: pointer;
          &:hover {
            // color: $neutral-60;
            // background-color: $neutral-3;
            background-color: #fffbf2;
            border-radius: 4px;
          }
          &.active {
            color: $primary-60;
            // &::after {
            //   content: "";
            //   width: 100%;
            //   height: 2px;
            //   background-color: $neutral-100;
            //   position: absolute;
            //   left: 0;
            //   bottom: -4px;
            // }
          }

          &.free-signup {
            width: 116px;
            background-color: $primary-50;
            color: $neutral-0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            border-radius: 4px;
            &:hover {
              background-color: $primary-60;
              box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
            }
          }
          &.signup-btn {
            color: $neutral-0;
            border-radius: 38px;
            background: $primary-50;
          }
          &.inquire-btn {
            margin-left: 20px;
            width: 67px;
            height: 33px;
            color: $neutral-0;
            font-size: 12px;
            border-radius: 4px;
            background: $primary-50;
          }
        }
      }
    }
    .user {
      position: absolute;
      top: 13px;
      right: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-80;
      @include tablet {
        top: rem(20px);
        right: rem(40px);
      }
      img {
        width: rem(30px);
        height: rem(30px);
      }
    }
    .search {
      position: absolute;
      top: rem(11px);
      right: rem(20px);
      @include tablet {
        top: rem(20px);
        right: rem(40px);
      }
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .icon {
      position: absolute;
      top: rem(8px);
      right: rem(17px);
      width: rem(30px);
      height: rem(30px);
      border-radius: 15px;
      background-color: $neutral-variation-70;
      display: flex;
      align-items: center;
      justify-content: center;
      @include tablet {
        top: rem(20px);
        right: rem(40px);
      }
      p {
        color: $neutral-0;
        font-size: 21px;
      }
    }
    .noti-icon {
      position: absolute;
      top: rem(9px);
      right: rem(17px);
      width: rem(7px);
      height: rem(7px);
      border-radius: 4px;
      background-color: $primary-50;
      @include tablet {
        top: rem(21px);
        right: rem(40px);
      }
    }
    .alert {
      position: absolute;
      width: 12px !important;
      height: 12px !important;
      transform: translate(12px, -12px);
    }
  }
}
.modal {
  &-menu {
    background-color: $neutral-0;
    border-radius: 8px 0px 0px 8px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    position: fixed;
    z-index: 1001;
    right: 0;
    top: 50%;
    width: calc(100% - 40px);
    height: calc(100% - 80px);
    max-width: 320px;
    transform: translate(0, -50%);
    text-align: center;
    padding: 40px 20px;
    transition: all 0.3s ease-in-out;
    .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      img {
        width: 16px;
        height: 16px;
      }
    }
    &-top {
      .title {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $neutral-80;
      }
      .top-btns {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 40px 0;
        &-btn {
          display: flex;
          gap: 8px;
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
          color: $neutral-80;
        }
      }

      .profile {
        &-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid $primary-20;
          gap: 20px;
          &.signup {
            padding-bottom: 20px;
          }
          .img-area {
            display: flex;
            align-items: center;
            width: 100px;
            height: 100px;
            overflow: hidden;
            border: 1px solid $neutral-10;
            border-radius: 80px;
            img {
              width: 100%;
              max-height: 100%;
              // object-fit: cover;
              // clip-path: circle(50%);
            }
          }
        }
        &-bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px 20px 0;
          cursor: pointer;
          &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            width: 100%;
            height: 38px;
            border: 1px solid $neutral-20;
            background-color: $neutral-0;
            border-radius: 4px;
            &.active {
              background-color: $primary-50;
            }
            span {
              font-size: 16px;
              color: $neutral-60;
            }
          }
        }
      }
    }
    &-body {
      height: calc(100% - 200px);
      margin-top: 20px;
      overflow: auto;
      &.login {
        height: calc(100% - 280px);
        &.comp {
          height: calc(100% - 220px);
        }
      }
      .dashboard {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        gap: 20px;
        &-info {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
        }
        &-menu {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          padding: 8px 16px;
          font-weight: 500;
          font-size: 15px;
          line-height: 140%;
          color: $neutral-80;
          img {
            width: 16px;
          }
          &:hover {
            border-bottom: 1px solid $primary-20;
          }
        }
        .info-list {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          height: 100%;
          max-height: 160px;
          padding: 12px 12px;
          border: 1px solid $neutral-10;
          border-top: 1px solid $primary-50;
          overflow: hidden;
          .no-list {
            height: 20px;
          }
          &-item {
            display: flex;
            justify-content: space-between;
            div {
              display: flex;
              max-width: 200px;
              .title {
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;
                color: $neutral-80;
              }
              .content {
                @include ellipse(1);
              }
            }
          }
        }
        .view {
          border: 1px solid $neutral-10;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          height: 32px;
          gap: 8px;
          border-width: 0 1px 1px 1px;
          border-color: $neutral-10;
          border-style: solid;
          border-radius: 0px 0px 4px 4px;
          color: $neutral-60;
          cursor: pointer;
          img {
            width: 16px;
          }
          &:hover {
            background: $neutral-3;
          }
        }
      }
    }
    &-bottom {
      margin-top: 20px;
      .inquire {
        padding: 0 20px;
        &-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $neutral-20;
          border-radius: 4px;
          padding: 8px 12px;
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
        }
      }
      .logout {
        padding: 20px;
        &-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &-btn {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-bottom: 2px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: $neutral-30;
            span {
              font-size: 16px;
            }
            img {
              width: 16px;
              height: 16px;
            }
            &:hover {
              border-bottom: 1px solid $primary-20;
            }
          }
        }
      }
    }
  }
  &-logout {
    position: fixed;
    z-index: 1001;
    left: 50%;
    top: 300px;
    width: calc(100% - 48px);
    max-width: 320px;
    transform: translate(-50%, 0);
    background-color: $neutral-0;
    border-radius: 8px;
    padding-top: 48px;
    text-align: center;
    color: $neutral-100;
    & > p:first-child:not(.title) {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
    }
    .subtitle {
      font-weight: 500;
      color: $neutral-60;
    }
    .modal-logout-btns {
      margin-top: 46px;
      display: flex;
      align-items: center;
      background-color: $neutral-50;
      border-radius: 0px 0px 8px 8px;
      color: $neutral-0;
      .btns-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: rem(12px) 0;
        cursor: pointer;
        &:not(:first-child) {
          margin-left: rem(8px);
        }
        &.active {
          background-color: $primary-50;
          border-radius: 0px 0px 8px 8px;
          &:not(:first-child) {
            border-radius: 0px 0px 8px 0px;
          }
        }
      }
    }
  }
}
</style>
