<template>
  <div class="content-wrap" v-show="step == 2">
    <div class="spec-wrap">
      <p
        class="spec-wrap-btn"
        v-for="(x, i) in checkSize"
        :key="i"
        @click="toggleComm('checkSize', i)"
        :class="{ active: x.isActive }"
      >
        {{ x.name }}
      </p>
    </div>
    <div
      class="row-gray mt-40"
      v-if="
        checkSize.filter((e) => e.isActive).length > 0 &&
        checkSize.filter((e) => e.isActive)[0].cd == 1
      "
    >
      <div class="content-title">사이즈 스펙을 입력해주세요.</div>
      <div class="list-wrap">
        <div class="content">
          <p>※</p>
          <p>
            대표적인 1개 사이즈만 입력해주세요. 그 외 사이즈는 편차에 따라
            자동으로 변경됩니다.
          </p>
        </div>
        <div class="content">
          <p>※</p>
          <p>
            필수<span>*</span> 스펙만 입력해주시면, 그 외 스펙은 비율에 맞춰
            추천해드립니다.
          </p>
        </div>
      </div>
      <div class="row-flex mt-20">
        <div class="row-flex-wrap">
          <div class="row-flex-wrap-img">
            <img
              src="@/assets/img/img-custom-preview-size.png"
              v-if="items.filter((e) => e.isActive)[0].cd == '1'"
            />
            <img
              src="@/assets/img/img-custom-preview-size-mtm.png"
              v-if="items.filter((e) => e.isActive)[0].cd == '2'"
            />
          </div>
        </div>
        <div class="row-flex-wrap">
          <SizeTableInput
            v-if="
              !isSizeRefresh && items.filter((e) => e.isActive)[0].cd == '1'
            "
            :size="size"
            :gender="sizeGender"
            :fit="sizeFit"
            @onSizeSpec="onSizeSpec"
            @setSizeValeu="setSizeValeu"
            @setSizeInput="setSizeInput"
          />
          <SizeTableInputMtm
            v-if="
              !isSizeRefresh && items.filter((e) => e.isActive)[0].cd == '2'
            "
            :size="size"
            :gender="sizeGender"
            :fit="sizeFit"
            :totalLength="sizeTotalLength"
            :sleeveStyle="sizeSleeveStyle"
            @onSizeSpec="onSizeSpec"
            @setSizeValeu="setSizeValeu"
            @setSizeInput="setSizeInput"
          />
          <p
            class="content-bottom-btns-btn mt-20"
            v-if="!isValidation[2]"
            @click="isSizeSpec ? (isGenderFit = true) : ''"
            :class="{ active: isSizeSpec }"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.recommendIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            나머지 사이즈 스펙을 추천해주세요.
          </p>
        </div>
      </div>
      <div class="content-bottom mt-31">
        <div class="validation">
          <p class="active">(필수)</p>
          <p
            :class="{
              active: isSizeSpec,
            }"
          >
            사이즈 스펙
          </p>
        </div>
        <div class="content-bottom-btns">
          <p
            class="content-bottom-btns-btn gray"
            @click="isRefreshPopup = true"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.refreshIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            초기화
          </p>
          <p
            class="content-bottom-btns-btn"
            :class="{ active: isValidation[2] }"
            @click="isValidation[2] ? toggleTab(Number(step * 1 + 1)) : ''"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.checkIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            사이즈 선택
          </p>
        </div>
      </div>
    </div>
    <div
      class="row-gray"
      v-if="
        checkSize.filter((e) => e.isActive).length > 0 &&
        checkSize.filter((e) => e.isActive)[0].cd == 2
      "
    >
      <div class="body-title mt-40 mb-28">
        선택 기준으로 기본 사이즈 스펙이 생성됩니다.
      </div>

      <div
        class="content-title-div"
        v-if="items.filter((e) => e.isActive)[0]?.cd == '1'"
      >
        <p class="content-title">✔ 성별 <span>*</span></p>
      </div>
      <div
        class="btns-wrap"
        v-if="items.filter((e) => e.isActive)[0]?.cd == '1'"
      >
        <p
          class="btns-wrap-btn"
          v-for="(x, i) in gender.filter((e) =>
            e.customType.some((s) => s == items.filter((f) => f.isActive)[0].cd)
          )"
          :key="i"
          @click="!isRecommend ? toggleComm('gender', x.index) : ''"
          :class="x.isActive ? 'active' : ''"
        >
          {{ x.name }}
        </p>
      </div>
      <div class="content-title-div mt-32">
        <p class="content-title">✔ &nbsp;핏 / 실루엣<span>*</span></p>
      </div>
      <div class="btns-wrap">
        <p
          class="btns-wrap-btn"
          v-for="(x, i) in fit.filter((e) =>
            e.customType.some((s) => s == items.filter((f) => f.isActive)[0].cd)
          )"
          :key="i"
          @click="!isRecommend ? toggleComm('fit', x.index) : ''"
          :class="x.isActive ? 'active' : ''"
        >
          {{ x.name }}
        </p>
      </div>
      <div class="content-title-div mt-32">
        <p class="content-title">✔ &nbsp;총 기장 <span>*</span></p>
      </div>
      <div class="btns-wrap">
        <p
          class="btns-wrap-btn"
          v-for="(x, i) in totalLength.filter((e) =>
            e.customType.some((s) => s == items.filter((f) => f.isActive)[0].cd)
          )"
          :key="i"
          @click="!isRecommend ? toggleComm('totalLength', x.index) : ''"
          :class="x.isActive ? 'active' : ''"
        >
          {{ x.name }}
        </p>
      </div>
      <div
        class="content-title-div mt-32"
        v-if="items.filter((e) => e.isActive)[0].cd == '1'"
      >
        <p class="content-title">✔ &nbsp;소매 기장 <span>*</span></p>
      </div>
      <div
        class="btns-wrap"
        v-if="items.filter((e) => e.isActive)[0].cd == '1'"
      >
        <p
          class="btns-wrap-btn"
          v-for="(x, i) in sleeveLength"
          :key="i"
          @click="!isRecommend ? toggleComm('sleeveLength', x.index) : ''"
          :class="x.isActive ? 'active' : ''"
        >
          {{ x.name }}
        </p>
      </div>
      <div
        class="content-title-div mt-32"
        v-if="items.filter((e) => e.isActive)[0].cd == '2'"
      >
        <p class="content-title">✔ &nbsp;소매 스타일 <span>*</span></p>
      </div>
      <div
        class="btns-wrap"
        v-if="items.filter((e) => e.isActive)[0].cd == '2'"
      >
        <p
          class="btns-wrap-btn"
          v-for="(x, i) in sleeveStyle"
          :key="i"
          @click="!isRecommend ? toggleComm('sleeveStyle', x.index) : ''"
          :class="x.isActive ? 'active' : ''"
        >
          {{ x.name }}
        </p>
      </div>
      <div class="content-bottom mt-31">
        <div class="validation">
          <p class="active">(필수)</p>
          <p
            :class="{
              active: gender.filter((e) => e.isActive).length > 0,
            }"
            v-if="items.filter((e) => e.isActive)[0]?.cd == '1'"
          >
            성별
          </p>
          <p
            :class="{
              active: fit.filter((e) => e.isActive).length > 0,
            }"
          >
            핏 / 실루엣
          </p>
          <p
            :class="{
              active: totalLength.filter((e) => e.isActive).length > 0,
            }"
          >
            총 기장
          </p>
          <p
            :class="{
              active: sleeveLength.filter((e) => e.isActive).length > 0,
            }"
            v-if="items.filter((e) => e.isActive)[0].cd == '1'"
          >
            소매 기장
          </p>
          <p
            :class="{
              active: sleeveStyle.filter((e) => e.isActive).length > 0,
            }"
            v-if="items.filter((e) => e.isActive)[0].cd == '2'"
          >
            소매 스타일
          </p>
        </div>
        <div class="content-bottom-btns">
          <p
            class="content-bottom-btns-btn"
            @click="isSizeSpec ? getSizeSpec() : ''"
            :class="{ active: isSizeSpec }"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.recommendIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            사이즈 스펙 추천
          </p>
        </div>
      </div>
      <div class="content-title mt-60 mb-28" v-if="isRecommend">
        사이즈 스펙을 확인해보세요.
      </div>
      <div class="row-flex" v-if="isRecommend">
        <div class="row-flex-wrap">
          <div class="row-flex-wrap-img">
            <img
              src="@/assets/img/img-custom-preview-size.png"
              v-if="items.filter((e) => e.isActive)[0].cd == '1'"
            />
            <img
              src="@/assets/img/img-custom-preview-size-mtm.png"
              v-if="items.filter((e) => e.isActive)[0].cd == '2'"
            />
          </div>
        </div>
        <div class="row-flex-wrap">
          <SizeTable
            v-if="items.filter((e) => e.isActive)[0]?.cd == '1'"
            :size="size"
            :disabled="isSizeDisable"
          />
          <SizeTableMtm
            v-if="items.filter((e) => e.isActive)[0]?.cd == '2'"
            :size="size"
            :disabled="isSizeDisable"
          />
        </div>
        <!-- <div class="row-flex-wrap">
          <div class="row-flex-wrap-img">
            <img src="@/assets/img/img-custom-preview-size.png" />
          </div>
        </div> -->
      </div>
      <div class="content-bottom mt-31" v-if="isRecommend">
        <div class="validation">
          <p class="active">(필수)</p>
          <p
            :class="{
              active: isSizeSpec,
            }"
          >
            사이즈 스펙
          </p>
        </div>
        <div class="content-bottom-btns">
          <p
            class="content-bottom-btns-btn gray"
            @click="isRefreshPopup = true"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.refreshIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            초기화
          </p>
          <p
            class="content-bottom-btns-btn"
            :class="{ active: isRecommend }"
            @click="isRecommend ? toggleTab(Number(step * 1 + 1)) : ''"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.checkIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            사이즈 선택
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-gender-fit" v-if="isGenderFit">
    <div class="popup-background" @click="isGenderFit = false"></div>
    <div class="popup-gender-fit-wrap">
      <div class="popup-gender-fit-wrap-body">
        <div class="title">
          <p>· 사이즈 스펙의 추천을 위한 기준을 선택해주세요.</p>
        </div>
        <div class="content">
          <div
            class="content-title-div"
            v-if="items.filter((e) => e.isActive)[0]?.cd == '1'"
          >
            <p class="content-title">✔ &nbsp;성별 <span>*</span></p>
          </div>
          <div
            class="btns-wrap"
            v-if="items.filter((e) => e.isActive)[0]?.cd == '1'"
          >
            <p
              class="btns-wrap-btn"
              v-for="(x, i) in gender.filter((e) =>
                e.customType.some(
                  (s) => s == items.filter((f) => f.isActive)[0]?.cd
                )
              )"
              :key="i"
              @click="toggleComm('gender', x.index)"
              :class="x.isActive ? 'active' : ''"
            >
              {{ x.name }}
            </p>
          </div>
          <div class="content-title-div mt-32">
            <p class="content-title">✔ &nbsp;핏 / 실루엣<span>*</span></p>
          </div>
          <div class="btns-wrap">
            <p
              class="btns-wrap-btn"
              v-for="(x, i) in fit.filter((e) =>
                e.customType.some(
                  (s) => s == items.filter((f) => f.isActive)[0]?.cd
                )
              )"
              :key="i"
              @click="toggleComm('fit', x.index)"
              :class="x.isActive ? 'active' : ''"
            >
              {{ x.name }}
            </p>
          </div>
          <div
            class="content-title-div mt-32"
            v-if="items.filter((e) => e.isActive)[0]?.cd == '2'"
          >
            <p class="content-title">✔ &nbsp;총 기장<span>*</span></p>
          </div>
          <div
            class="btns-wrap"
            v-if="items.filter((e) => e.isActive)[0]?.cd == '2'"
          >
            <p
              class="btns-wrap-btn"
              v-for="(x, i) in totalLength.filter((e) =>
                e.customType.some(
                  (s) => s == items.filter((f) => f.isActive)[0]?.cd
                )
              )"
              :key="i"
              @click="toggleComm('totalLength', x.index)"
              :class="x.isActive ? 'active' : ''"
            >
              {{ x.name }}
            </p>
          </div>
          <div
            class="content-title-div mt-32"
            v-if="items.filter((e) => e.isActive)[0]?.cd == '2'"
          >
            <p class="content-title">✔ &nbsp;소매 스타일<span>*</span></p>
          </div>
          <div
            class="btns-wrap"
            v-if="items.filter((e) => e.isActive)[0]?.cd == '2'"
          >
            <p
              class="btns-wrap-btn"
              v-for="(x, i) in sleeveStyle"
              :key="i"
              @click="toggleComm('sleeveStyle', x.index)"
              :class="x.isActive ? 'active' : ''"
            >
              {{ x.name }}
            </p>
          </div>
        </div>
      </div>

      <div class="popup-gender-fit-wrap-bottom">
        <div class="validation">
          <p class="active">(필수)</p>
          <p
            :class="{
              active: gender.filter((e) => e.isActive).length > 0,
            }"
            v-if="items.filter((e) => e.isActive)[0]?.cd == '1'"
          >
            성별
          </p>
          <p
            :class="{
              active: fit.filter((e) => e.isActive).length > 0,
            }"
          >
            핏 / 실루엣
          </p>
          <p
            v-if="items.filter((e) => e.isActive)[0]?.cd == '2'"
            :class="{
              active: totalLength.filter((e) => e.isActive).length > 0,
            }"
          >
            총 기장
          </p>
          <p
            v-if="items.filter((e) => e.isActive)[0]?.cd == '2'"
            :class="{
              active: sleeveStyle.filter((e) => e.isActive).length > 0,
            }"
          >
            소매 스타일
          </p>
        </div>
        <div class="popup-gender-fit-wrap-bottom-btn pointer">
          <p class="gray" @click="isGenderFit = false">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closePopup"
              :color="$store.state.meta.svg.color.neutral0"
            />
            닫기
          </p>
          <p
            @click="
              items.filter((e) => e.isActive)[0]?.cd == '1' &&
              gender.filter((e) => e.isActive).length > 0 &&
              fit.filter((e) => e.isActive).length > 0
                ? recommendSize()
                : items.filter((e) => e.isActive)[0]?.cd == '2' &&
                  totalLength.filter((e) => e.isActive).length > 0 &&
                  sleeveStyle.filter((e) => e.isActive).length > 0 &&
                  fit.filter((e) => e.isActive).length > 0
                ? recommendSize()
                : ''
            "
            :class="{
              active:
                (items.filter((e) => e.isActive)[0]?.cd == '1' &&
                  gender.filter((e) => e.isActive).length > 0 &&
                  fit.filter((e) => e.isActive).length > 0) ||
                (items.filter((e) => e.isActive)[0]?.cd == '2' &&
                  totalLength.filter((e) => e.isActive).length > 0 &&
                  sleeveStyle.filter((e) => e.isActive).length > 0 &&
                  fit.filter((e) => e.isActive).length > 0),
            }"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.recommendIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            사이즈 스펙 추천
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-refresh" v-if="isRefreshPopup">
    <div class="popup-background" @click="isRefreshPopup = false"></div>
    <div class="popup-refresh-wrap">
      <div class="popup-refresh-wrap-body">
        <div class="title">
          <p>입력하신 내용을 초기화 하시겠습니까?</p>
        </div>
        <div class="content">
          필수<span>*</span> 내용을 제외한 모든 내용이 삭제됩니다.
        </div>
      </div>
      <div class="popup-refresh-wrap-bottom">
        <div class="popup-refresh-wrap-bottom-btn pointer">
          <p class="" @click="isRefreshPopup = false">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closePopup"
              :color="$store.state.meta.svg.color.neutral0"
            />
            취소
          </p>
          <p class="active" @click="refresh(true)">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.refreshIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            초기화
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgImg from "../SvgImg.vue";

export default {
  name: "CustomAdd",
  props: {
    step: {
      type: String,
    },
    checkSize: {
      type: Object,
    },
    isValidation: {
      type: Object,
    },
    isSizeRefresh: {
      type: Boolean,
    },
    isRecommend: {
      type: Boolean,
    },
    sizeData: { type: Object },
  },
  data() {
    return {
      items: [],
      size: [],
      gender: [],
      fit: [],
      sizeGender: [],
      sizeFit: [],
      sizeTotalLength: [],
      sizeSleeveStyle: [],
      totalLength: [],
      sleeveLength: [],
      sleeveStyle: [],
      tolSize: [],
      shsSize: [],
      hesSize: [],
      sllSize: [],
      chsSize: [],
      whsSize: [],
      ahlSize: [],
      sbsSize: [],
      scsSize: [],
      newSize: [],
      nedSize: [],
      srwSize: [],
      hrwSize: [],
      sizeInput: [],
      sizeValue: [],
      isGenderFit: false,
      isSizeSpec: false,
      isRefreshPopup: false,
    };
  },
  watch: {
    sizeData(newVal) {
      this.size = newVal;
    },
  },
  mounted() {
    this.size = this.sizeData;
    this.items = this.$store.state.customPrint.items;
    this.gender = this.$store.state.customPrint.gender;
    this.fit = this.$store.state.customPrint.fit;
    this.totalLength = this.$store.state.customPrint.totalLength;
    this.sleeveLength = this.$store.state.customPrint.sleeveLength;
    this.sleeveStyle = this.$store.state.customPrint.sleeveStyle;
    this.neckLine = this.$store.state.customPrint.neckLine;
    this.neckDetail = this.$store.state.customPrint.neckDetail;
  },
  methods: {
    toggleTab(index) {
      this.$emit("toggleTab", index);
      const sizeObj = {
        tolSize: this.tolSize,
        chsSize: this.chsSize,
        hesSize: this.hesSize,
        sllSize: this.sllSize,
        shsSize: this.shsSize,
        whsSize: this.whsSize,
        ahlSize: this.ahlSize,
        scsSize: this.scsSize,
        sbsSize: this.sbsSize,
        newSize: this.newSize,
        nedSize: this.nedSize,
        srwSize: this.srwSize,
        hrwSize: this.hrwSize,
      };
      this.$emit("setSizeData", sizeObj);
    },
    toggleComm(type, index) {
      if (type == "checkSize") {
        this.refresh(false);
      }
      this.$emit("toggleComm", type, index);
      if (
        this.step == 2 &&
        ((this.items.filter((e) => e.isActive)[0]?.cd == "1" &&
          this.gender.filter((e) => e.isActive).length > 0 &&
          this.fit.filter((e) => e.isActive).length &&
          this.totalLength.filter((e) => e.isActive).length > 0 &&
          this.sleeveLength.filter((e) => e.isActive).length > 0) ||
          (this.items.filter((e) => e.isActive)[0]?.cd == "2" &&
            this.fit.filter((e) => e.isActive).length &&
            this.totalLength.filter((e) => e.isActive).length > 0 &&
            this.sleeveStyle.filter((e) => e.isActive).length > 0))
      ) {
        this.size = [
          { index: 0, isActive: true, cd: "01", name: "XS" },
          { index: 0, isActive: true, cd: "02", name: "S" },
          { index: 1, isActive: true, cd: "03", name: "M" },
          { index: 2, isActive: true, cd: "04", name: "L" },
          { index: 3, isActive: true, cd: "05", name: "XL" },
          { index: 4, isActive: true, cd: "06", name: "2XL" },
          { index: 4, isActive: true, cd: "07", name: "3XL" },
        ];
        this.isSizeSpec = true;
      }
    },
    setSizeValeu(sizeValue, i) {
      console.log("sizeValue:::::" + sizeValue);
      // this.tolSize  //총기장
      // this.chsSize  //가슴 단면
      // this.hesSize  //밑단 단면
      // this.sllSize  //소매 기장
      // this.shsSize  //어깨 단면
      // this.whsSize  //허리 둘레
      // this.ahlSize  //암홀
      // this.scsSize  //소매단 단면
      // this.sbsSize  //소매통 단면
      // this.newSize  //목 너비
      // this.nedSize  //목 파임
      // this.srwSize  //소매립 폭
      // this.hrwSize  //밑단립 폭
      if (this.items.filter((e) => e.isActive)[0]?.cd == "1") {
        if (i == 0) this.tolSize = sizeValue;
        if (i == 1) this.chsSize = sizeValue;
        if (i == 2) this.hesSize = sizeValue;
        if (i == 3) this.sllSize = sizeValue;
        if (i == 4) this.shsSize = sizeValue;
        if (i == 5) this.whsSize = sizeValue;
        if (i == 6) this.ahlSize = sizeValue;
        if (i == 7) this.scsSize = sizeValue;
        if (i == 8) this.sbsSize = sizeValue;
        if (i == 9) this.newSize = sizeValue;
        if (i == 10) this.nedSize = sizeValue;
      } else if (this.items.filter((e) => e.isActive)[0]?.cd == "2") {
        if (i == 0) this.tolSize = sizeValue;
        if (i == 1) this.chsSize = sizeValue;
        if (i == 2) this.shsSize = sizeValue;
        if (i == 3) this.hesSize = sizeValue;
        if (i == 4) this.sllSize = sizeValue;
        if (i == 5) this.ahlSize = sizeValue;
        if (i == 6) this.sbsSize = sizeValue;
        if (i == 7) this.scsSize = sizeValue;
        if (i == 8) this.newSize = sizeValue;
        if (i == 9) this.nedSize = sizeValue;
      }
      this.sizeValue[i] = sizeValue;
    },
    setSizeInput(index, size, value) {
      this.$emit("setSizeInput", index, size, value);
      // if (value > 0) {
      //   this.sizeInput[index] = index;
      // } else {
      //   this.sizeInput.splice(index, 1);
      // }
      // if (value > 0 && this.sizeInput.indexOf(size) == -1) {
      //   this.sizeInput[0] = "";
      //   this.sizeInput[0] = size;
      // }
    },
    recommendSize() {
      if (this.items.filter((e) => e.isActive)[0].cd == "2") {
        this.gender.filter((e) => e.cd == "2")[0].isActive = true;
      }
      this.isGenderFit = false;
      this.sizeGender = this.gender.filter((e) => e.isActive)[0];
      this.sizeFit = this.fit.filter((e) => e.isActive)[0];
      this.sizeTotalLength = this.totalLength.filter((e) => e.isActive)[0];
      this.sizeSleeveStyle = this.sleeveStyle.filter((e) => e.isActive)[0];

      this.$emit("recommendSize");
      setTimeout(() => {
        if (this.sizeValue.length > 0) {
          let marking = 1;
          if (this.items.filter((e) => e.isActive)[0].cd == "1") {
            marking = parseFloat(
              parseFloat(this.sizeValue[0][4]) +
                parseFloat(this.sizeValue[3][4])
            );
          } else if (this.items.filter((e) => e.isActive)[0].cd == "2") {
            marking = parseFloat(
              parseFloat(this.sizeValue[0][4]) +
                parseFloat(this.sizeValue[4][4])
            );
          }
          marking = marking / 91 + 0.1;
          console.log(marking);
          marking = Math.ceil(marking * 100) / 100;
          console.log(marking);
          //단가 계산
          this.$emit("sumUnitCost", marking);
        }
      }, 100);
    },
    getSizeSpec() {
      //성별 + 핏 + 총 기장 + 소매 기장 + 네크라인 + 네크라인 디테일
      this.$emit("getSizeSpec");
    },
    refresh(isRefresh) {
      this.isSizeSpec = false;
      this.isRefreshPopup = false;
      this.checkSize.map((e) => (e.isDisabled = false));
      this.sizeValue = [];
      this.initsize();
      this.$emit("refresh", isRefresh);
    },
    onSizeSpec(isActive) {
      console.log(isActive);
      // console.log(this.isSizeSpec);
      // this.$emit("onSizeSpec", isActive);
      // console.log(this.isSizeSpec);
      this.isSizeSpec = isActive;
    },
    initsize() {
      this.size = [];
      this.sizeGender = {};
      this.sizeFit = {};
      this.sizeTotalLength = {};
      this.sizeSleeveStyle = {};
      this.$emit("initsize", false);
    },
    toggleSize(index) {
      this.size[index].isActive = !this.size[index].isActive;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/custom.scss";
</style>
