<template>
  <div class="select-box">
    <div
      class="select-box-value"
      :class="showSelectBoxList ? 'active' : ''"
      @click="toggleShowList()"
    >
      <p v-if="selectDataTxt" class="select-box-value-txt">
        {{ selectDataTxt }}
      </p>
      <p
        v-else
        class="select-box-value-txt placeholder"
        :class="isActive ? 'pointer' : ''"
      >
        {{ placeholderTxt }}
      </p>
      <img v-if="isActive" src="@/assets/icon/ic-selectbox.svg" />
    </div>
    <ul class="select-box-list" :class="showSelectBoxList ? '' : 'notshow'">
      <li
        v-for="(x, i) in data"
        :key="i"
        class="select-box-list-item pointer"
        @click="selectDataUpdate(x)"
      >
        {{ x }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "selectBox",
  props: {
    data: {
      type: Object,
      description: "select box data",
    },
    selectData: {
      type: String,
      description: "selected data",
    },
    placeholderTxt: {
      type: String,
      description: "placeholder txt",
    },
    isBlock: {
      type: Boolean,
      description: "block true/false",
    },
  },
  watch: {
    selectData(newVal) {
      this.selectDataTxt = newVal;
    },
    isBlock(newVal) {
      this.isActive = !newVal;
    },
  },
  mounted() {
    this.selectDataTxt = this.selectData;
    this.isActive = !this.isBlock;
  },
  data() {
    return {
      showSelectBoxList: false,
      selectDataTxt: "",
      isActive: true,
    };
  },
  methods: {
    toggleShowList() {
      if (!this.isActive) return;
      this.showSelectBoxList = !this.showSelectBoxList;
    },
    selectDataUpdate(txt) {
      this.selectDataTxt = txt;
      this.showSelectBoxList = false;
      this.$emit("selectData", this.selectDataTxt);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  position: relative;
  &-value {
    border: solid #e6e6e6;
    border-width: 0 0 rem(1px) 0;
    padding-bottom: rem(2px);
    display: flex;
    align-items: center;
    &-txt {
      width: 100%;
      color: $neutral-95;
      font-size: 16px;
      line-height: 22px;
      flex: 1;
      &.placeholder {
        color: $neutral-30;
      }
    }
    img {
      padding: 0 rem(10px);
    }
    &.active {
      border-color: $primary-50;
    }
  }
  &-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: rem(15px) 0 rem(17px);
    z-index: 1;
    background-color: $neutral-0;
    overflow: scroll;
    border: solid $neutral-10;
    border-width: 0 rem(1px) rem(1px) rem(1px);
    &-item {
      font-size: 16px;
      line-height: 24px;
      padding: rem(15px);
      &:hover {
        background-color: $neutral-variation-20;
      }
    }
    &.notshow {
      max-height: 0;
      display: none;
    }
  }
}
</style>
