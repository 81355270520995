<template>
  <div class="alarm-list">
    <p class="title">알림</p>
    <div class="alarm-list-filter">
      <div class="search">
        <img src="@/assets/icon/ic-search-grey.svg" />
        <input type="text" placeholder="검색" />
      </div>
      <div class="sort-btn">
        <div class="sort-btn-tab">
          <p
            v-for="(x, i) in filters.state"
            :key="i"
            :class="x.isActive ? 'active' : ''"
            @click="selectData(x, 'state')"
          >
            {{ x.name }}
          </p>
        </div>
      </div>
    </div>
    <div class="alarm-list-view">
      <div v-if="alarmData.length !== 0">
        <div
          v-for="(x, i) in alarmData"
          :key="i"
          class="alarm-list-view-item pointer"
          :class="x.readingFlag == 0 ? '' : 'deactive'"
          @click="alarmRead(i)"
        >
          <div class="alarm-list-view-item-wrap">
            <p class="alarm-tag" :class="x.alarmType == '1' ? 'yellow' : ''">
              {{ x.alarmTypeName }}
            </p>
            <div class="flex1">
              <p class="item-title" v-html="x.title"></p>
            </div>
          </div>
          <div class="item-bottom">
            <div class="flex1">
              <p class="item-title" v-html="x.content"></p>
            </div>
            <!-- <p class="date">{{ x.timer }}</p> -->
          </div>
        </div>
        <div class="loader" v-if="isLoading">
          <FadeLoader :loading="true" height="10px" width="10px"></FadeLoader>
        </div>
      </div>
      <div v-else class="flex1 no-alarm flex mt-52">
        <p>받은 알림이 없습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
export default {
  name: "AlarmListWeb",
  components: { FadeLoader },
  data() {
    return {
      filters: {
        read: [
          { name: "전체", cd: "" },
          { name: "읽지않음", cd: "0" },
        ],
        state: [
          { name: "전체", cd: "", isActive: true, index: 0 },
          { name: "공지", cd: "1", isActive: false, index: 1 },
          { name: "알림", cd: "2", isActive: false, index: 2 },
        ],
      },
      sortOrder: 0,
      readingFlag: "",
      noticeStatus: "",
      searchText: "",
      alarmData: [],
      noticeDetail: {},
      pageNow: 0,
      pageList: 20,
      isLoading: true,
      totalCount: 0,
    };
  },
  mounted() {
    this.updateNoticeList(true);
    this.setPopup(false);
    document.addEventListener("scroll", this.infinityCheck);
  },
  methods: {
    readAll() {
      this.$API.mypage
        .alarmReadAll(sessionStorage.getItem("userId"))
        .then((res) => {
          this.updateNoticeList(true);
        });
    },
    updateNoticeList(isReset) {
      console.log(this.searchText);
      this.isLoading = true;
      if (isReset) {
        this.pageNow = 0;
      }
      this.pageNow++;
      this.$API.mypage
        .getAlarmList(
          sessionStorage.getItem("userId"),
          this.alarmStatus,
          this.readingFlag,
          this.searchText,
          this.pageNow,
          this.pageList
        )
        .then((res) => {
          console.log(res);
          if (res.data.resultCode == "10000") {
            if (isReset) {
              this.alarmData = res.data.body.resAlarmList;
              this.totalCount = res.data.body.totalCount;
            } else {
              res.data.body.resNoticeList.forEach((e) => {
                this.alarmData.push(e);
              });
            }
          } else {
            if (isReset) {
              this.alarmData = [];
              this.totalCount = 0;
            }
          }
          this.isLoading = false;
        });
    },
    alarmRead(i) {
      if (this.alarmData[i].readingFlag != "1") {
        this.alarmData[i].readingFlag = "1";
        this.$API.mypage
          .postAlarmRead(
            sessionStorage.getItem("userId"),
            this.alarmData[i].alarmNo
          )
          .then(() => {
            this.$API.mypage
              .alarmCheck(sessionStorage.getItem("userId"))
              .then((res) => {
                console.log(res);
              });
          });
      }
      if (this.alarmData[i].alarmType == "1") {
        this.$emit(
          "goAlarmDetail",
          this.alarmData[i].alarmType,
          this.alarmData[i].title,
          this.alarmData[i].content,
          this.alarmData[i].createdDate
        );
      } else {
        this.gotoPage(this.alarmData[i].alarmUrl);
      }
    },
    goNoticeDetail(i, noticeNo, inquireNo) {
      if (inquireNo) {
        sessionStorage.setItem("inquireNo", inquireNo);
        this.gotoPage("/mypage/inquirelist");
      } else {
        this.$API.mypage
          .getNoticeDetail(sessionStorage.getItem("userId"), noticeNo)
          .then((res) => {
            let d = res.data.body;
            d.noticeContents = d.noticeContents?.replace(/\n/g, "<br>");
            this.noticeDetail = d;
            this.setPopup(true, "noticeDetail", "알림 상세내용");
          });
      }
      if (this.alarmData[i].readingFlag != "1") {
        this.alarmData[i].readingFlag = "1";
        this.$API.mypage
          .postNoticeRead(sessionStorage.getItem("userId"), noticeNo)
          .then(() => {
            this.$API.mypage
              .noticeCheck(sessionStorage.getItem("userId"))
              .then((res) => {
                if (res.data.body == 1)
                  this.$store.state.meta.showNotiIcon = true;
                else this.$store.state.meta.showNotiIcon = false;
              });
          });
      }
    },
    selectData(data, type) {
      if (type === "state") {
        this.alarmStatus = data.cd;
      } else if (type === "reading") {
        this.readingFlag = data.cd;
      }
      this.filters[type].filter((e) => (e.isActive = false));
      this.filters[type]
        .filter((e) => e.index === data.index)
        .map((e) => (e.isActive = true));
      this.updateNoticeList(true);
    },
    infinityCheck() {
      if (this.isLoading) return;
      if (Math.ceil(this.totalCount / this.pageList) <= this.pageNow) return;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.updateNoticeList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alarm-list {
  width: 100%;
  max-width: 500px;
  // @include set-height();
  display: flex;
  flex-direction: column;
  .title {
    margin: 40px 20px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
  &-filter {
    .search {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      width: 100%;
      height: 32px;
      border: 1px solid $neutral-20;
      border-radius: 22px;
      input {
        width: 100%;
      }
      img {
        width: 16px;
      }
    }
    .sort-btn {
      margin-top: 32px;
      &-tab {
        display: flex;
        justify-content: flex-start;
        gap: 14px;
        p {
          cursor: pointer;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-30;
          &.active {
            color: $neutral-80;
            border-bottom: 1px solid $neutral-60;
          }
        }
      }
    }
    .read-all {
      display: flex;
      gap: 4px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $neutral-30;
      }
    }
  }
  &-view {
    background-color: $neutral-0;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .line {
      height: rem(8px);
      background-color: $neutral-3;
    }
    &-item {
      // padding: 0 rem(16px);
      align-items: flex-start;
      justify-content: center;
      border-bottom: 1px solid $neutral-10;
      padding: rem(16px) rem(20px);
      &:first-child {
        border-top: 1px solid $neutral-10;
      }
      &-wrap {
        display: flex;
        gap: 20px;
        .notice-tag {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 24px;
          padding: 6px 18px;
          border-radius: 12px;
          color: $neutral-0;
          background-color: $primary-50;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
        .item-title {
          @include ellipse();
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $neutral-80;
        }
      }
      .item-bottom {
        display: flex;
        margin-top: rem(12px);
        .item-title {
          @include ellipse();
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-60;
        }
        .date {
          margin: 0 20px 2px 0;
          font-size: 10px;
        }
      }
      &.deactive {
        color: $neutral-50;
        .notice-tag {
          background-color: $neutral-30;
        }
        .item-title {
          color: $neutral-50;
        }
      }
    }
    .no-alarm {
      font-weight: 500;
      color: $neutral-40;
    }
  }
  .info-content {
    &-title {
      font-weight: 700;
      font-size: 18px;
      color: $neutral-80;
    }
    &-date {
      font-weight: 400;
      font-size: 14px;
      color: $neutral-80;
      padding-bottom: rem(16px);
      border-bottom: 1px solid $neutral-10;
    }
    &-img {
      margin: 24px auto 0;
      text-align: center;
      img {
        max-width: 500px;
      }
    }
    &-link {
      font-weight: 700;
      font-size: 18px;
      color: $neutral-80;
      margin-top: rem(50px);
      cursor: pointer;
    }
  }
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 0;
  }
  .yellow {
    color: $primary-60;
  }
}
</style>
