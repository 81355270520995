<template>
  <div class="select-biz">
    <div
      class="select-biz-value"
      :class="[{ active: showSelectBoxList }, { error: isError }]"
    >
      <input
        v-outside="hide"
        id="inputtop"
        v-model="searchTextValue"
        class="select-biz-value-txt"
        :placeholder="placeholderTxt"
        @keyup="inputChange()"
        @focusout="inputChange()"
        @keyup.enter="getSearchData()"
        @click="showSelectBoxList = false"
        @mouseover="isHover = true"
        @mouseout="isHover = false"
      />
      <span class="material-symbols-outlined" @click="getSearchData()">
        search
      </span>
      <!-- <img
        :src="
          require(`@/assets/icon/ic-chevron-right${
            isHover ? '-yellow' : '-grey'
          }.svg`)
        "
        @click="getSearchData()"
      /> -->
    </div>
    <p v-if="isError" class="error-msg">사업자 명을 입력해주세요.</p>
    <ul class="select-biz-list" :class="showSelectBoxList ? '' : 'notshow'">
      <li
        v-for="(x, i) in bizList"
        :key="i"
        class="select-biz-list-item"
        @click="selectDataUpdate(x)"
      >
        <p>{{ x.company }}</p>
        <p>{{ x.compNum }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "selectBiz",
  props: {
    compName: {
      type: String,
      description: "search txt",
    },
    placeholderTxt: {
      type: String,
      description: "placeholder txt",
    },
    isCompNameError: {
      type: String,
      description: "placeholder txt",
    },
  },
  watch: {
    compName(newVal) {
      this.searchTextValue = newVal;
    },
  },
  mounted() {
    this.searchTextValue = this.compName;
    this.isError = this.isCompNameError;
  },
  data() {
    return {
      searchTextValue: "",
      showSelectBoxList: false,
      bizList: [],
      isError: false,
      isHover: false,
    };
  },
  methods: {
    selectDataUpdate(data) {
      data.compNum = data.compNum.replace(/-/gi, "");
      this.searchTextValue = data.company;
      this.showSelectBoxList = false;
      this.$emit("selectData", data);
      window.scroll({
        top:
          document.documentElement.scrollTop +
          document.getElementById("inputtop").getBoundingClientRect().top -
          80,
        left: 0,
        behavior: "smooth",
      });
    },
    getSearchData() {
      this.$API.company.getBizData(this.searchTextValue).then((res) => {
        this.bizList = res.data.body.bizList;
        this.showSelectBoxList = true;
      });
    },
    inputChange() {
      this.$emit("updateData", this.searchTextValue);
      if (this.searchTextValue === "") {
        this.showSelectBoxList = false;
        this.isError = true;
      } else {
        this.isError = false;
      }
    },
    hide() {
      this.isError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-biz {
  position: relative;
  &-value {
    display: flex;
    // border: solid #e6e6e6;
    // border-width: 0 0 rem(1px) 0;
    // margin-top: rem(9px);
    // padding-bottom: rem(2px);
    // display: flex;
    // align-items: center;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    background: $neutral-0;
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: $neutral-20;
    border-radius: 4px;
    width: 100%;
    height: 35px;
    img {
      width: 20px;
    }
    span {
      cursor: pointer;
      font-size: 16px;
      color: $neutral-60;
      &:hover {
        color: $primary-20;
      }
    }
    &:hover {
      border-color: $primary-20;
    }
    input {
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      height: 100%;
      width: 100%;
    }

    &-txt {
      // width: 100%;
      // color: $neutral-95;
      // font-size: 16px;
      // line-height: 22px;
      // flex: 1;

      &.placeholder {
        color: $neutral-30;
      }
    }
    &.active {
      border-color: $primary-50;
    }
    &.error {
      border-color: $secondary-60;
    }
  }
  &-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: $neutral-0;
    padding: 0 rem(16px);
    overflow: hidden;
    border: solid $neutral-10;
    border-width: 0 rem(1px) rem(1px) rem(1px);
    max-height: 160px;
    overflow: scroll;
    &-item {
      display: flex;
      justify-content: space-between;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-60;
      padding: 4px 12px 6px;
      &:hover {
        background-color: $neutral-variation-20;
      }
    }
    &.notshow {
      max-height: 0;
      display: none;
    }
  }
  .error-msg {
    position: absolute;
    right: 0;
    bottom: -22px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $secondary-60;
  }
}
</style>
