<template>
  <div class="factory-details-wrap">
    <div class="bg"></div>
    <div class="factory-details">
      <div class="factory-details-head">
        <div class="head-img" v-if="this.$store.state.meta.windowWidth >= 900">
          <img :src="mainImgUrl" />
        </div>
        <div class="head-content">
          <div class="head-content-work" v-if="isMobile == 'false'">
            <div class="txts">
              <p class="process">{{ factoryDetails.workProcess }}</p>
              <div class="name">
                <p>{{ factoryDetails.factoryName }}</p>
                <div
                  class="payment-btn"
                  v-if="compType != '2' && factoryDetails.calculateFlag == '2'"
                >
                  <SvgImg
                    :size="20"
                    :d="$store.state.meta.svg.creditCard"
                    :color="$store.state.meta.svg.color.primary50"
                  />
                  <p>카드 결제 가능</p>
                </div>
              </div>
            </div>
            <div class="icons">
              <div
                class="icons-wrap like"
                v-if="compType == '1'"
                @click.stop="clickLike()"
                @mouseover="isIconHover[0] = true"
                @mouseleave="isIconHover[0] = false"
                :class="factoryDetails.isLike ? 'active' : ''"
              >
                <SvgImg
                  :size="20"
                  :d="
                    factoryDetails.isLike
                      ? $store.state.meta.svg.like
                      : $store.state.meta.svg.likeOutline
                  "
                  :color="
                    factoryDetails.isLike
                      ? $store.state.meta.svg.color.primary50
                      : $store.state.meta.svg.color.neutral40
                  "
                />{{ factoryDetails.likeCount }}
              </div>
              <div
                v-if="compType != '2' && factoryDetails.calculateFlag == '2'"
                class="icons-wrap"
                @mouseover="isIconHover[1] = true"
                @mouseleave="isIconHover[1] = false"
                @click="isPayment = true"
              >
                <SvgImg
                  :size="20"
                  :d="$store.state.meta.svg.payment"
                  :color="$store.state.meta.svg.color.neutral40"
                />
              </div>
              <div
                class="icons-wrap"
                @mouseover="isIconHover[2] = true"
                @mouseleave="isIconHover[2] = false"
                @click="
                  openCall(factoryDetails.factoryNo, factoryDetails.telNum)
                "
              >
                <SvgImg
                  :size="20"
                  :d="$store.state.meta.svg.phone"
                  :color="$store.state.meta.svg.color.neutral40"
                />
              </div>
              <div
                class="icons-wrap"
                @mouseover="isIconHover[3] = true"
                @mouseleave="isIconHover[3] = false"
                @click="clickShare(factoryDetails)"
              >
                <SvgImg
                  :size="20"
                  :d="$store.state.meta.svg.share"
                  :color="$store.state.meta.svg.color.neutral40"
                />
              </div>
            </div>
          </div>
          <div class="head-content-work" v-else>
            <div class="process-mobile">
              <p>{{ factoryDetails.workProcess }}</p>
              <div class="icons">
                <div
                  class="icons-wrap like"
                  v-if="compType == '1'"
                  @click.stop="clickLike()"
                  @mouseover="isIconHover[0] = true"
                  @mouseleave="isIconHover[0] = false"
                  :class="factoryDetails.isLike ? 'active' : ''"
                >
                  <SvgImg
                    :size="20"
                    :d="
                      factoryDetails.isLike
                        ? $store.state.meta.svg.like
                        : $store.state.meta.svg.likeOutline
                    "
                    :color="
                      factoryDetails.isLike
                        ? $store.state.meta.svg.color.primary50
                        : $store.state.meta.svg.color.neutral40
                    "
                  />{{ factoryDetails.likeCount }}
                </div>
                <div
                  v-if="compType != '2' && factoryDetails.calculateFlag == '2'"
                  class="icons-wrap"
                  @mouseover="isIconHover[1] = true"
                  @mouseleave="isIconHover[1] = false"
                  @click="isPayment = true"
                >
                  <SvgImg
                    :size="20"
                    :d="$store.state.meta.svg.payment"
                    :color="
                      isIconHover[1]
                        ? $store.state.meta.svg.color.neutral0
                        : $store.state.meta.svg.color.neutral40
                    "
                  />
                </div>
                <div
                  class="icons-wrap"
                  @mouseover="isIconHover[2] = true"
                  @mouseleave="isIconHover[2] = false"
                  @click="
                    openCall(factoryDetails.factoryNo, factoryDetails.telNum)
                  "
                >
                  <SvgImg
                    :size="20"
                    :d="$store.state.meta.svg.phone"
                    :color="
                      isIconHover[2]
                        ? $store.state.meta.svg.color.neutral0
                        : $store.state.meta.svg.color.neutral40
                    "
                  />
                </div>
                <div
                  class="icons-wrap"
                  @mouseover="isIconHover[3] = true"
                  @mouseleave="isIconHover[3] = false"
                  @click="clickShare(factoryDetails)"
                >
                  <SvgImg
                    :size="20"
                    :d="$store.state.meta.svg.share"
                    :color="
                      isIconHover[3]
                        ? $store.state.meta.svg.color.neutral0
                        : $store.state.meta.svg.color.neutral40
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="head-content-work mt-12" v-if="isMobile == 'true'">
            <div class="txts">
              <div class="name">
                <p>{{ factoryDetails.factoryName }}</p>
                <div
                  class="payment-btn"
                  v-if="compType != '2' && factoryDetails.calculateFlag == '2'"
                >
                  <SvgImg
                    :size="20"
                    :d="$store.state.meta.svg.creditCard"
                    :color="$store.state.meta.svg.color.primary50"
                  />
                  <p>카드 결제 가능</p>
                </div>
              </div>
            </div>
          </div>
          <div class="head-content-name">
            <div class="txts">
              <p class="address">
                {{ factoryDetails.address }} {{ factoryDetails.addressDetail }}
              </p>
            </div>
          </div>
          <div class="head-content-tag">
            <p
              class="tag-list-item pointer"
              v-for="(x, i) in factoryDetails.tagList"
              :key="i"
              @click="searchKeyword(x.commName.replace('#', ''))"
            >
              {{ x.commName }}
            </p>
          </div>
        </div>
      </div>
      <div class="factory-details-body">
        <div class="body-info">
          <div class="body-title">
            <p>기업 정보</p>
          </div>
          <div
            class="body-info-row"
            v-if="factoryDetails.workProcessCd == '01031'"
          >
            <p class="name">· 작업 형태</p>
            <div class="body-info-row-list">
              <p
                class="body-info-row-list-item"
                v-if="factoryDetails.processFlag === '1'"
              >
                임가공
              </p>
              <p
                class="body-info-row-list-item"
                v-if="factoryDetails.buyFlag === '1'"
              >
                완사입
              </p>
            </div>
            <div
              class="body-info-row-list"
              v-if="factoryDetails.workProcessCd == '01033'"
            >
              <p
                class="body-info-row-list-item"
                v-if="factoryDetails.sampleType === '1'"
              >
                패턴
              </p>
              <p
                class="body-info-row-list-item"
                v-if="factoryDetails.sampleType === '2'"
              >
                샘플
              </p>
              <p
                class="body-info-row-list-item"
                v-if="factoryDetails.sampleType === '3'"
              >
                패턴/샘플
              </p>
            </div>
          </div>

          <div
            class="body-info-row"
            v-if="
              (factoryDetails.workProcessCd == '01031' ||
                factoryDetails.workProcessCd == '01033') &&
              factoryDetails.newFabricList?.length > 0
            "
          >
            <p class="name">· 작업 가능 원단</p>
            <div class="body-info-row-list">
              <p
                class="body-info-row-list-item"
                v-for="(x, i) in factoryDetails.newFabricList"
                :key="i"
              >
                {{ x }}
              </p>
            </div>
          </div>
          <div
            class="body-info-row"
            v-else-if="
              (factoryDetails.workProcessCd == '01031' ||
                factoryDetails.workProcessCd == '01033') &&
              factoryDetails.fabricList?.length > 0
            "
          >
            <p class="name">· 작업 가능 원단</p>
            <div class="body-info-row-list">
              <p
                class="body-info-row-list-item"
                v-for="(x, i) in factoryDetails.fabricList"
                :key="i"
              >
                {{ x.commName }}
              </p>
            </div>
          </div>

          <div class="body-info-row">
            <p class="name">· 최소 발주 수량</p>
            <div class="body-info-row-list">
              <p class="body-info-row-list-item">
                {{
                  Number(factoryDetails.minOrderCount).toLocaleString("ko-KR")
                }}벌 이상
              </p>
            </div>
          </div>
          <div class="body-info-row">
            <p class="name">· 상시 근로자 수</p>
            <div class="body-info-row-list">
              <p class="body-info-row-list-item">
                {{
                  Number(factoryDetails.workerCount).toLocaleString("ko-KR")
                }}명
              </p>
            </div>
          </div>
          <div class="body-info-row">
            <p class="name">· 월 평균 생산량</p>
            <div class="body-info-row-list">
              <p class="body-info-row-list-item">
                {{
                  Number(factoryDetails.monProductCount).toLocaleString(
                    "ko-KR"
                  )
                }}벌 이상
              </p>
            </div>
          </div>
          <div class="body-info-row" v-if="factoryDetails.factorySize > 0">
            <p class="name">· 공장 규모</p>
            <div class="body-info-row-list">
              <p class="body-info-row-list-item">
                {{
                  Number(factoryDetails.factorySize).toLocaleString("ko-KR")
                }}평 이상
              </p>
            </div>
          </div>
          <div class="body-info-row">
            <p class="name">· 주요 거래 채널</p>
            <div class="body-info-row-list">
              <p
                class="body-info-row-list-item"
                v-if="factoryDetails.majorDealer1"
              >
                {{ factoryDetails.majorDealer1 }}
              </p>
              <p
                class="body-info-row-list-item"
                v-if="factoryDetails.majorDealer2"
              >
                {{ factoryDetails.majorDealer2 }}
              </p>
              <p
                class="body-info-row-list-item"
                v-if="factoryDetails.majorDealer3"
              >
                {{ factoryDetails.majorDealer3 }}
              </p>
            </div>
          </div>
          <div
            class="body-info-row"
            v-if="factoryDetails.siteUrl || factoryDetails.siteUrl1"
          >
            <p class="name">· 홈페이지 / SNS 등</p>
            <div class="body-info-row-url">
              <p class="body-info-row-url-item" v-if="factoryDetails.siteUrl">
                {{ factoryDetails.siteUrl }}
              </p>
              <p class="body-info-row-url-item" v-if="factoryDetails.siteUrl1">
                {{ factoryDetails.siteUrl1 }}
              </p>
              <p class="body-info-row-url-item" v-if="factoryDetails.siteUrl2">
                {{ factoryDetails.siteUrl2 }}
              </p>
              <p class="body-info-row-url-item" v-if="factoryDetails.siteUrl3">
                {{ factoryDetails.siteUrl3 }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="body-item"
          v-if="
            factoryDetails.workProcessCd &&
            (factoryDetails.item9List.length > 0 ||
              factoryDetails.item11List.length > 0 ||
              factoryDetails.selectCatgCdList.length > 0 ||
              Object.keys(bfCatgList).length > 0)
          "
        >
          <div class="body-title">
            <p>주요 품목</p>
          </div>
          <div
            class="body-item-wrap"
            v-if="factoryDetails.workProcessCd == '01034'"
          >
            <p class="name">· 주요 작업</p>
            <div class="content">
              <p v-for="(x, i) in factoryDetails.item9List" :key="i">
                {{ x.commName }}
              </p>
            </div>
          </div>
          <div
            class="body-item-wrap"
            v-if="factoryDetails.workProcessCd == '01035'"
          >
            <p class="name">· 주요 작업</p>
            <div class="content">
              <p v-for="(x, i) in factoryDetails.item11List" :key="i">
                {{ x.commName }}
              </p>
            </div>
          </div>

          <div
            class="body-item-wrap"
            v-else-if="
              (factoryDetails.workProcessCd == '01031' ||
                factoryDetails.workProcessCd == '01033') &&
              factoryDetails.selectCatgCdList.length > 0
            "
            v-for="(x, i) in factoryDetails.selectCatgCdList"
            :key="i"
          >
            <div class="name" v-if="x.catgName1">
              <p>· {{ x.catgName1 }}</p>
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.rightArrowBig"
                :color="$store.state.meta.svg.color.neutral40"
              />
              <p class="sub">
                {{ x.catgName2 }}
              </p>
            </div>
            <div class="content">
              <div
                v-for="(y, j) in x.catgList.filter((e) => e.catgName != '기타')"
                :key="j"
              >
                <p class="pointer">{{ y.catgName }}</p>
              </div>
              <div
                v-if="x.catgList.filter((e) => e.catgName == '기타').length > 0"
              >
                <p class="pointer">기타</p>
              </div>
            </div>
          </div>
          <div
            class="body-item-wrap"
            v-else-if="
              (factoryDetails.workProcessCd == '01031' ||
                factoryDetails.workProcessCd == '01033') &&
              Object.keys(bfCatgList).length > 0
            "
            v-for="(x, j) in Object.keys(bfCatgList)"
            :key="j"
          >
            <div class="name" v-if="x">
              <p>· {{ bfCatgList[x][0].catgName1 }}</p>
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.rightArrowBig"
                :color="$store.state.meta.svg.color.neutral40"
              />
              <p class="sub">
                {{ bfCatgList[x][0].catgName2 }}
              </p>
            </div>
            <div class="content">
              <div v-for="(y, k) in bfCatgList[x]" :key="k">
                <p class="pointer">{{ y.catgName }}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="body-facility"
          v-if="
            factoryDetails.facility1List != '' ||
            factoryDetails.facility2List != '' ||
            factoryDetails.facility3List != '' ||
            factoryDetails.facility5List != '' ||
            factoryDetails.facility6List != ''
          "
        >
          <div class="body-title">
            <p>보유 설비</p>
          </div>
          <div
            class="body-facility-wrap"
            v-if="factoryDetails.facility1List != ''"
          >
            <div class="name">재봉기</div>
            <div class="content">
              <p v-for="(x, i) in factoryDetails.facility1List" :key="i">
                {{ x.commName }}
              </p>
            </div>
          </div>
          <div
            class="body-facility-wrap"
            v-if="factoryDetails.facility2List != ''"
          >
            <div class="name">특수</div>
            <div class="content">
              <p v-for="(x, i) in factoryDetails.facility2List" :key="i">
                {{ x.commName }}
              </p>
            </div>
          </div>
          <div
            class="body-facility-wrap"
            v-if="factoryDetails.facility3List != ''"
          >
            <div class="name">패턴/재단/완성</div>
            <div class="content">
              <p v-for="(x, i) in factoryDetails.facility3List" :key="i">
                {{ x.commName }}
              </p>
            </div>
          </div>
          <div
            class="body-facility-wrap"
            v-if="factoryDetails.facility5List != ''"
          >
            <div class="name">자수/나염/전사</div>
            <div class="content">
              <p v-for="(x, i) in factoryDetails.facility5List" :key="i">
                {{ x.commName }}
              </p>
            </div>
          </div>
          <div
            class="body-facility-wrap"
            v-if="factoryDetails.facility6List != ''"
          >
            <div class="name">기타 설비</div>
            <div class="content">
              <p v-for="(x, i) in factoryDetails.facility6List" :key="i">
                {{ x.commName }}
              </p>
            </div>
          </div>
        </div>
        <div class="body-img" id="body-img">
          <div class="body-title">
            <p>사진</p>
          </div>
          <div class="body-img-wrap">
            <div
              v-for="(y, j) in factoryFileList"
              :key="j"
              class="body-img-wrap-list"
              :class="y.sourceFileName.split('.')[1]"
              @click="moveSlide(j)"
            >
              <img
                v-if="
                  ['.psd', '.ai', '.pdf', '.xlsx'].some(
                    (e) => y.sourceFileName.indexOf(e) != -1
                  )
                "
                :src="
                  require(`@/assets/img/thumbnail-${
                    y.sourceFileName.split('.')[1]
                  }.png`)
                "
              />
              <img v-else :src="y.fileUrl" />
            </div>
          </div>
        </div>
        <div class="body-desc">
          <div class="body-title">
            <p>소개글</p>
          </div>
          <p class="textarea" v-html="factoryDetails.compDesc"></p>
        </div>
      </div>
    </div>

    <div
      class="bottom-btns"
      v-if="$route.path.indexOf('search/factorydetail') !== -1"
    >
      <div class="btns-wrap">
        <div
          @click.stop="gotoPage('/search/factorylist')"
          class="bottom-btn pointer one"
        >
          <!-- <img class="mr-6" src="@/assets/icon/ic-list-white.svg" /> -->
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.returnIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>돌아가기</p>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-share" v-if="isShare">
    <div class="popup-background" @click="isShare = false"></div>
    <div class="popup-share-wrap">
      <div class="popup-share-wrap-body">
        <p class="title">공장 프로필을 공유해보세요.</p>
        <div class="content">
          <div class="logo">
            <img src="@/assets/logo/kakao-logo.svg" />
            <p>카카오톡</p>
          </div>
          <div class="logo">
            <img src="@/assets/logo/line-logo.svg" />
            <p>라인</p>
          </div>
          <div class="logo">
            <img src="@/assets/logo/instagram-logo.svg" />
            <p>인스타그램</p>
          </div>
          <div class="logo">
            <img src="@/assets/logo/facebook-logo.svg" />
            <p>페이스북</p>
          </div>
        </div>
      </div>
      <div class="popup-share-wrap-bottom">
        <div class="popup-share-wrap-bottom-btn pointer">
          <p @click="isShare = false">닫기</p>
          <p class="active" @click="copyUrl()">URL 복사하기</p>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-call" v-if="isCall">
    <div class="popup-background" @click="isCall = false"></div>
    <div class="popup-call-wrap">
      <div class="popup-call-wrap-body">
        <div class="content">
          <p class="content-name">{{ factoryDetails.factoryName }}</p>
          <p class="content-telnum">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.phone"
              :color="$store.state.meta.svg.color.primary50"
            />{{ factoryDetails.telNum }}
          </p>
        </div>
      </div>
      <div class="popup-call-wrap-bottom">
        <div class="popup-call-wrap-bottom-btn pointer">
          <p @click="isCall = false">
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.closePopup"
              :color="$store.state.meta.svg.color.neutral0"
            />닫기
          </p>
          <p
            class="active"
            @click="
              callFactory(factoryDetails.factoryNo, factoryDetails.telNum);
              isCall = false;
            "
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.copyIcon"
              :color="$store.state.meta.svg.color.neutral0"
            />
            복사하기
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-comm" v-if="isCopy">
    <div class="popup-background" @click="isCopy = false"></div>
    <div class="popup-comm-wrap">
      <img class="close" @click="isCopy = false" src="@/assets/icon/ic-x.svg" />
      <div class="popup-comm-wrap-top">
        <!-- <div class="title">
            <p>공유하기</p>
          </div> -->
      </div>

      <div class="popup-comm-wrap-body">
        <p class="title">URL 링크가 복사되었습니다.</p>
      </div>

      <div class="popup-comm-wrap-bottom">
        <div class="popup-comm-wrap-bottom-btn pointer">
          <p class="active" @click="isCopy = false">확인</p>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-img" v-if="isImgPopup">
    <div class="popup-background" @click="isImgPopup = false"></div>
    <div class="popup-img-wrap">
      <SvgImg
        class="close"
        :size="20"
        :d="$store.state.meta.svg.closePopup"
        :color="$store.state.meta.svg.color.neutral60"
        @click="isImgPopup = false"
      />
      <div class="popup-img-wrap-content">
        <div class="fileupload-wrap">
          <div class="preview">
            <p class="preview-page">
              {{ swiperIndex + 1 }} / {{ factoryFileList.length }}
            </p>
            <p class="preview-left" @click="moveSlide(swiperIndex - 1)">
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.swiperLeftArrow"
                :color="$store.state.meta.svg.color.neutral60"
              />
            </p>
            <p class="preview-right" @click="moveSlide(swiperIndex + 1)">
              <SvgImg
                :size="20"
                :d="$store.state.meta.svg.swiperRightArrow"
                :color="$store.state.meta.svg.color.neutral60"
              />
            </p>
            <img v-if="regImgUrl" :src="regImgUrl" />
            <img
              v-else-if="factoryFileList"
              :src="factoryFileList[0]?.fileUrl"
            />
          </div>
          <div class="thumbnails">
            <div class="thumbnails-list">
              <Swiper
                id="mainSwiper"
                :slidesPerView="isMobile == 'true' ? 4 : 6"
                :spaceBetween="12"
              >
                <swiper-slide
                  v-for="(y, j) in factoryFileList"
                  :key="j"
                  class="thumbnails-list-area pointer"
                  :class="y.sourceFileName.split('.')[1]"
                  @click="clickThumb(y.fileUrl, y.sourceFileName, j)"
                >
                  <img
                    v-if="
                      ['.psd', '.ai', '.pdf', '.xlsx'].some(
                        (e) => y.sourceFileName.indexOf(e) != -1
                      )
                    "
                    :src="
                      require(`@/assets/img/thumbnail-${
                        y.sourceFileName.split('.')[1]
                      }.png`)
                    "
                  />
                  <img v-else :src="y.fileUrl" />
                </swiper-slide>
                <!-- v-for="(y, j) in factoryFileList"
                  :key="j"
                  class="thumbnails-list-area pointer"
                  :class="y.sourceFileName.split('.')[1]"
                  @click="clickThumb(y.fileUrl, y.sourceFileName, j)" -->
                <swiper-slide
                  v-for="(y, j) in isMobile == 'true'
                    ? factoryFileList.length < 4
                      ? 4 - factoryFileList.length
                      : 0
                    : factoryFileList.length < 6
                    ? 6 - factoryFileList.length
                    : 0"
                  :key="j"
                >
                </swiper-slide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="popup-payment" v-if="isPayment">
    <div class="popup-background" @click="isPayment = false"></div>
    <div
      class="popup-payment-wrap"
      v-if="this.$store.state.meta.windowWidth >= 900"
    >
      <!-- <img
        class="close"
        @click="isPayment = false"
        src="@/assets/icon/ic-x.svg"
      /> -->
      <SvgImg
        class="close"
        :size="20"
        :d="$store.state.meta.svg.closePopup"
        :color="$store.state.meta.svg.color.neutral0"
        @click="isPayment = false"
      />
      <div class="popup-payment-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.payment"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>간편 결제</p>
        </div>
      </div>
      <div class="popup-payment-wrap-body">
        <div class="popup-payment-wrap-body-left">
          <div class="step">
            <p class="step-circle" :class="[{ active: paymentStep == 1 }]">1</p>
            <p class="step-name" :class="[{ active: paymentStep == 1 }]">
              결제 수단
            </p>
          </div>
          <div class="step">
            <p
              class="step-circle"
              :class="[
                { disabled: calculateType == '2' },
                { active: paymentStep == 2 },
              ]"
            >
              2
            </p>
            <p
              class="step-name"
              :class="[
                { disabled: calculateType == '2' },
                { active: paymentStep == 2 },
              ]"
            >
              세금계산서 정보
            </p>
          </div>
          <div class="step">
            <p class="step-circle" :class="[{ active: paymentStep == 3 }]">3</p>
            <p class="step-name" :class="[{ active: paymentStep == 3 }]">
              결제 금액
            </p>
          </div>
        </div>
        <div class="popup-payment-wrap-body-right">
          <div class="step1" v-if="paymentStep == 1">
            <div class="factory-name">
              <span>{{ factoryDetails.factoryName }}</span> 에게 결제
            </div>
            <div class="selector">
              <div class="selector-name">· 결제 수단을 선택해주세요.</div>
              <div class="selector-items">
                <!-- paymentType -->
                <p
                  class="item"
                  :class="[{ active: paymentType == 'CARD' }]"
                  @click="paymentType = 'CARD'"
                >
                  신용카드
                </p>
                <!-- <p
                  class="item"
                  :class="[{ active: paymentType == 'TRANSFER' }]"
                  @click="paymentType = 'TRANSFER'"
                >
                  계좌이체
                </p> -->
                <!-- <p
                  class="item"
                  :class="[{ active: paymentType == 'TOSSPAY' }]"
                  @click="paymentType = 'TOSSPAY'"
                >
                  토스페이
                </p> -->
              </div>
            </div>
            <div class="selector">
              <div class="selector-name">
                · 세금계산서 발행을 요청하시겠습니까?
              </div>
              <div class="selector-items">
                <!-- calculateType 사업자-1 개인-2 -->
                <p
                  class="item"
                  :class="[{ active: calculateType == '1' }]"
                  @click="calculateType = '1'"
                >
                  예
                </p>
                <p
                  class="item"
                  :class="[{ active: calculateType == '2' }]"
                  @click="calculateType = '2'"
                >
                  아니오
                </p>
              </div>
            </div>
          </div>
          <div class="step2" v-else-if="paymentStep == 2">
            <div class="input-row">
              <p class="name">· 사업자 명</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.compName"
                placeholder="사업자 명을 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 사업자등록번호</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.compNum"
                placeholder="사업자등록번호를 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 대표자 명</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.representName"
                placeholder="대표자 명을 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 업태</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.bizType"
                placeholder="업태를 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 종목</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.bizClass"
                placeholder="종목을 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 사업장 주소</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.compAddress"
                placeholder="사업장 주소를 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 계산서 이메일</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.taxEmail"
                placeholder="계산서 받으실 이메일을 입력해주세요."
              />
            </div>
          </div>
          <div class="step3" v-else-if="paymentStep == 3">
            <div class="input-wrap">
              <div class="input-row">
                <p class="name">· 결제 금액</p>
                <input
                  class="input"
                  type="text"
                  v-model="paymentData.paymentAmount"
                  placeholder="결제 금액 (원)"
                />
              </div>
              <div class="input-row">
                <p class="name">· 담당자 연락처</p>
                <input
                  class="input"
                  type="text"
                  v-model="paymentData.calcTelNum"
                  placeholder="결제 정보를 받으실 연락처를 입력해주세요."
                />
              </div>
            </div>
            <div class="info-agree">
              <div class="info-agree-select">
                <div class="selector" @click="isPaymentInfo = !isPaymentInfo">
                  <p>안내 사항</p>
                  <SvgImg
                    :size="16"
                    :d="
                      isPaymentInfo
                        ? $store.state.meta.svg.upArrow
                        : $store.state.meta.svg.downArrow
                    "
                    :color="$store.state.meta.svg.color.neutral60"
                  />
                </div>
                <div class="desc" v-if="isPaymentInfo">
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      결제가 완료되고, 영업일 기준 14일 후, 공급자에게
                      정산됩니다.
                    </p>
                  </div>
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      양 사간 거래를 정상적으로 완료될 수 없다고 판단될 경우,
                      당사자 간 합의에 따라 결제 후 7일 이내에 취소 신청이
                      가능합니다.
                    </p>
                  </div>
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      세금계산서는 거래 주체인 공급자가 발주자에게 발행합니다.
                    </p>
                  </div>
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      카드 및 결제 영수증은 결제가 완료되었음을 증명하는
                      용도로만 활용 가능하며, 세무 상의 부가가치세
                      매입세액공제용으로써 지출증빙 효력이 없습니다.
                    </p>
                  </div>
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      증빙 발행은 선택하신 증빙 유형으로 공급자(공장)에서 결제
                      금액 수령을 위해서 필수 발행되며, 계산서의 공급 일자는
                      결제일을 기준으로 합니다.
                    </p>
                  </div>
                </div>
              </div>
              <div class="info-agree-check">
                <div class="check-all">
                  <CheckBox
                    :name="'모두 동의합니다.'"
                    :checked="isPaymentCheck[0]"
                    :type="'radio'"
                    :color="'light'"
                    :hoverOff="true"
                    @click="allCheck()"
                  />
                </div>
                <div class="check-list">
                  <div class="check-list-wrap">
                    <CheckBox
                      :name="'안내 사항을 모두 확인하였습니다. (필수)'"
                      :checked="isPaymentCheck[1]"
                      :type="'radio'"
                      :color="'light'"
                      :hoverOff="true"
                      @click="isPaymentCheck[1] = !isPaymentCheck[1]"
                    />
                  </div>
                  <div class="check-list-wrap">
                    <CheckBox
                      :name="'이용 약관에 동의합니다. (필수)'"
                      :checked="isPaymentCheck[2]"
                      :type="'radio'"
                      :color="'light'"
                      :hoverOff="true"
                      @click="isPaymentCheck[2] = !isPaymentCheck[2]"
                    />
                    <span @click="gotoUrl('/terms/termsofservice', 1)"
                      >[보기]</span
                    >
                  </div>
                  <div class="check-list-wrap">
                    <CheckBox
                      :name="'개인정보 수집 및 이용에 동의합니다. (필수)'"
                      :checked="isPaymentCheck[3]"
                      :type="'radio'"
                      :color="'light'"
                      :hoverOff="true"
                      @click="isPaymentCheck[3] = !isPaymentCheck[3]"
                    />
                    <span @click="gotoUrl('/terms/privacypolicy', 1)"
                      >[보기]</span
                    >
                  </div>
                  <div class="check-list-wrap">
                    <CheckBox
                      :name="'마케팅 수신과 홍보 목적의 개인정보 수집 및 이용에 동의합니다. (선택)'"
                      :checked="isPaymentCheck[4]"
                      :type="'radio'"
                      :color="'light'"
                      :hoverOff="true"
                      @click="isPaymentCheck[4] = !isPaymentCheck[4]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-payment-wrap-bottom">
        <div class="popup-payment-wrap-bottom-btns">
          <p
            v-if="paymentStep > 1"
            class="payment-btn before"
            @click="goBefore()"
          >
            이전
          </p>
          <p v-if="paymentStep < 3" class="payment-btn next" @click="goNext()">
            다음
          </p>
          <p
            v-if="paymentStep == 3"
            class="payment-btn next"
            @click="goPayment()"
          >
            결제하기
          </p>
        </div>
      </div>
    </div>
    <div class="popup-payment-wrap" v-else>
      <SvgImg
        class="close"
        :size="20"
        :d="$store.state.meta.svg.closePopup"
        :color="$store.state.meta.svg.color.neutral0"
        @click="isPayment = false"
      />
      <div class="popup-payment-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.payment"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>간편 결제</p>
        </div>
      </div>
      <div class="popup-payment-wrap-body">
        <div class="popup-payment-wrap-body-up">
          <div class="status">
            <p
              :class="[
                { active: paymentStep >= 1 },
                { last: paymentStep == 1 },
              ]"
            ></p>
            <p
              :class="[
                { active: paymentStep >= 2 },
                { last: paymentStep == 2 },
              ]"
            ></p>
            <p
              :class="[
                { active: paymentStep >= 3 },
                { last: paymentStep == 3 },
              ]"
            ></p>
          </div>
          <div class="step" v-if="paymentStep == 1">
            <p class="step-circle active">1</p>
            <p class="step-name active">결제 수단</p>
          </div>
          <div class="step" v-if="paymentStep == 2">
            <p
              class="step-circle active"
              :class="[{ disabled: calculateType == '2' }]"
            >
              2
            </p>
            <p
              class="step-name active"
              :class="[{ disabled: calculateType == '2' }]"
            >
              세금계산서 정보
            </p>
          </div>
          <div class="step" v-if="paymentStep == 3">
            <p class="step-circle active">3</p>
            <p class="step-name active">결제 금액</p>
          </div>
        </div>
        <div class="popup-payment-wrap-body-down">
          <div class="step1" v-if="paymentStep == 1">
            <div class="factory-name">
              <span>{{ factoryDetails.factoryName }}</span> 에게 결제
            </div>
            <div class="selector">
              <div class="selector-name">· 결제 수단을 선택해주세요.</div>
              <div class="selector-items">
                <!-- paymentType -->
                <p
                  class="item"
                  :class="[{ active: paymentType == 'CARD' }]"
                  @click="paymentType = 'CARD'"
                >
                  신용카드
                </p>
                <!-- <p
                  class="item"
                  :class="[{ active: paymentType == 'TRANSFER' }]"
                  @click="paymentType = 'TRANSFER'"
                >
                  계좌이체
                </p> -->
                <!-- <p
                  class="item"
                  :class="[{ active: paymentType == 'TOSSPAY' }]"
                  @click="paymentType = 'TOSSPAY'"
                >
                  토스페이
                </p> -->
              </div>
            </div>
            <div class="selector">
              <div class="selector-name">
                · 세금계산서 발행을 요청하시겠습니까?
              </div>
              <div class="selector-items">
                <!-- calculateType 사업자-1 개인-2 -->
                <p
                  class="item"
                  :class="[{ active: calculateType == '1' }]"
                  @click="calculateType = '1'"
                >
                  예
                </p>
                <p
                  class="item"
                  :class="[{ active: calculateType == '2' }]"
                  @click="calculateType = '2'"
                >
                  아니오
                </p>
              </div>
            </div>
          </div>
          <div class="step2" v-else-if="paymentStep == 2">
            <div class="input-row">
              <p class="name">· 사업자 명</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.compName"
                placeholder="사업자 명을 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 사업자등록번호</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.compNum"
                placeholder="사업자등록번호를 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 대표자 명</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.representName"
                placeholder="대표자 명을 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 업태</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.bizType"
                placeholder="업태를 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 종목</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.bizClass"
                placeholder="종목을 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 사업장 주소</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.compAddress"
                placeholder="사업장 주소를 입력해주세요."
              />
            </div>
            <div class="input-row">
              <p class="name">· 계산서 이메일</p>
              <input
                class="input"
                type="text"
                v-model="paymentData.taxEmail"
                placeholder="계산서 받으실 이메일을 입력해주세요."
              />
            </div>
          </div>
          <div class="step3" v-else-if="paymentStep == 3">
            <div class="input-wrap">
              <div class="input-row">
                <p class="name">· 결제 금액</p>
                <input
                  class="input"
                  type="text"
                  v-model="paymentData.paymentAmount"
                  placeholder="결제 금액 (원)"
                />
              </div>
              <div class="input-row">
                <p class="name">· 담당자 연락처</p>
                <input
                  class="input"
                  type="text"
                  v-model="paymentData.calcTelNum"
                  placeholder="결제 정보를 받으실 연락처를 입력해주세요."
                />
              </div>
            </div>
            <div class="info-agree">
              <div class="info-agree-select">
                <div class="selector" @click="isPaymentInfo = !isPaymentInfo">
                  <p>안내 사항</p>
                  <SvgImg
                    :size="16"
                    :d="
                      isPaymentInfo
                        ? $store.state.meta.svg.upArrow
                        : $store.state.meta.svg.downArrow
                    "
                    :color="$store.state.meta.svg.color.neutral60"
                  />
                </div>
                <div class="desc" v-if="isPaymentInfo">
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      결제가 완료되고, 영업일 기준 14일 후, 공급자에게
                      정산됩니다.
                    </p>
                  </div>
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      양 사간 거래를 정상적으로 완료될 수 없다고 판단될 경우,
                      당사자 간 합의에 따라 결제 후 7일 이내에 취소 신청이
                      가능합니다.
                    </p>
                  </div>
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      세금계산서는 거래 주체인 공급자가 발주자에게 발행합니다.
                    </p>
                  </div>
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      카드 및 결제 영수증은 결제가 완료되었음을 증명하는
                      용도로만 활용 가능하며, 세무 상의 부가가치세
                      매입세액공제용으로써 지출증빙 효력이 없습니다.
                    </p>
                  </div>
                  <div class="desc-info">
                    <p>※</p>
                    <p>
                      증빙 발행은 선택하신 증빙 유형으로 공급자(공장)에서 결제
                      금액 수령을 위해서 필수 발행되며, 계산서의 공급 일자는
                      결제일을 기준으로 합니다.
                    </p>
                  </div>
                </div>
              </div>
              <div class="info-agree-check">
                <div class="check-all">
                  <CheckBox
                    :name="'모두 동의합니다.'"
                    :checked="isPaymentCheck[0]"
                    :type="'radio'"
                    :color="'light'"
                    :hoverOff="true"
                    @click="allCheck()"
                  />
                </div>
                <div class="check-list">
                  <div class="check-list-wrap">
                    <CheckBox
                      :name="'안내 사항을 모두 확인하였습니다. (필수)'"
                      :checked="isPaymentCheck[1]"
                      :type="'radio'"
                      :color="'light'"
                      :hoverOff="true"
                      @click="isPaymentCheck[1] = !isPaymentCheck[1]"
                    />
                  </div>
                  <div class="check-list-wrap">
                    <CheckBox
                      :name="'이용 약관에 동의합니다. (필수)'"
                      :checked="isPaymentCheck[2]"
                      :type="'radio'"
                      :color="'light'"
                      :hoverOff="true"
                      @click="isPaymentCheck[2] = !isPaymentCheck[2]"
                    />
                    <span @click="gotoUrl('/terms/termsofservice', 1)"
                      >[보기]</span
                    >
                  </div>
                  <div class="check-list-wrap">
                    <CheckBox
                      :name="'개인정보 수집 및 이용에 동의합니다. (필수)'"
                      :checked="isPaymentCheck[3]"
                      :type="'radio'"
                      :color="'light'"
                      :hoverOff="true"
                      @click="isPaymentCheck[3] = !isPaymentCheck[3]"
                    />
                    <span @click="gotoUrl('/terms/privacypolicy', 1)"
                      >[보기]</span
                    >
                  </div>
                  <div class="check-list-wrap">
                    <CheckBox
                      :name="'마케팅 수신과 홍보 목적의 개인정보 수집 및 이용에 동의합니다. (선택)'"
                      :checked="isPaymentCheck[4]"
                      :type="'radio'"
                      :color="'light'"
                      :hoverOff="true"
                      @click="isPaymentCheck[4] = !isPaymentCheck[4]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-payment-wrap-bottom">
        <div class="popup-payment-wrap-bottom-btns">
          <p
            v-if="paymentStep > 1"
            class="payment-btn before"
            @click="goBefore()"
          >
            이전
          </p>
          <p v-if="paymentStep < 3" class="payment-btn next" @click="goNext()">
            다음
          </p>
          <p
            v-if="paymentStep == 3"
            class="payment-btn next"
            @click="goPayment()"
          >
            결제하기
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadTossPayments } from "@tosspayments/payment-sdk";
import SvgImg from "@/components/SvgImg.vue";
import CheckBox from "@/components/CheckBox.vue";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "PcFactoryDetail",
  emits: ["openLogin"],
  props: {
    factoryNo: {
      type: String,
      description: "Factory No",
    },
  },
  components: {
    SvgImg,
    CheckBox,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {};
  },
  watch: {
    factoryNo(val) {
      // this.factoryNo = val;
      this.initDetail(val);
    },
  },
  data() {
    return {
      regImgUrl: "",
      regImgName: "",
      designerId: "",
      factoryId: "",
      swiperIndex: 0,
      // imgList: [{fileUrl: '', type: ''}],
      factoryFileList: [],
      factoryDetails: [],
      fabricList: [],
      cntMax: 0,
      showNoti: localStorage.getItem("balloon-factoryDetails") ? false : true,
      tabIndex: 0,
      isFixed: false,
      fixedPosition: 300, // 스크롤 위치가 300px 이하일 때 고정
      catgPosition: 300, // 스크롤 위치가 300px 이하일 때 고정
      imgPosition: 300, // 스크롤 위치가 300px 이하일 때 고정
      descPosition: 300, // 스크롤 위치가 300px 이하일 때 고정
      myFactoryNo: "",
      mainImgUrl: "",
      isIconHover: [false, false, false, false],
      isShare: false,
      isCall: false,
      isCopy: false,
      isImgPopup: false,
      isPayment: false,
      paymentStep: 1,
      isPaymentInfo: false,
      isPaymentCheck: [true, true, true, true, true],
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      paymentData: {
        compName: "",
        compNum: "",
        representName: "",
        bizType: "",
        bizClass: "",
        compAddress: "",
        compAddressDetail: "",
        taxEmail: "",
        calcUserName: "",
        calcTelNum: "",
        paymentAmount: "",
        receiptTelNum: "",
        receiptCompNum: "",
      },
      paypleData: {},
      paymentType: "",
      calculateType: "",
      identityNum: "",
      bfCatgList: [],
    };
  },
  mounted() {
    this.initDetail(this.factoryNo);
    this.$API.mypage.getFactoryInfo(this.userId).then((res) => {
      let data = res.data.body;
      this.myFactoryNo = data.factoryNo;
    });

    if (this.compType == "1") {
      this.$API.mypage.getDesignerInfo(this.userId).then((res) => {
        const designerInfo = res.data.body;
        console.log(designerInfo);
        this.paymentData.compName = designerInfo.compName;
        this.paymentData.compNum = designerInfo.compNum;
        this.paymentData.representName = designerInfo.representName;
        this.paymentData.bizType = designerInfo.bizType;
        this.paymentData.bizClass = designerInfo.bizClass;
        this.paymentData.compAddress =
          designerInfo.compAddress + " " + designerInfo.compAddressDetail;
        this.paymentData.taxEmail = designerInfo.taxEmail;
        this.paymentData.calcUserName = designerInfo.calcUserName;
        this.paymentData.calcTelNum = designerInfo.calcTelNum
          ? designerInfo.calcTelNum
          : designerInfo.telNum;
        this.paymentData.receiptTelNum = designerInfo.receiptTelNum;
        this.paymentData.receiptCompNum = designerInfo.receiptCompNum;
      });
    }

    // Vue 인스턴스가 마운트된 후, MutationObserver를 사용하여 변화를 감지
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((addedNode) => {
          // 추가된 노드가 div인 경우 처리
          if (addedNode.nodeName.toLowerCase() === "div") {
            console.log("새로운 div 태그가 생성되었습니다.");
          }
        });
      });

      if (this.$store.state.meta.windowWidth <= 480) {
        var square = document.querySelectorAll(".body-img-wrap");
        console.log(square);
        console.log(square.offsetWidth);
        square.forEach((e) => {
          e.style.height = e.offsetWidth + "px";
        });
      }

      //
      window.addEventListener("scroll", this.handleScroll);

      // 브라우저의 resize 이벤트에 대한 이벤트 리스너 추가
      window.addEventListener("resize", this.handleResize);
    });
    // MutationObserver를 대상 노드에 연결하고, 하위 요소 추가를 감시
    observer.observe(document.getElementById("body-img"), {
      childList: true,
    });
    this.loadPaypleScript();
  },
  beforeRouteLeave(to, from, next) {
    // Save the current scroll position
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
  methods: {
    groupedByUprCommCd(data) {
      this.bfCatgList = data.newCatgList.reduce((acc, obj) => {
        const key = obj.uprCommCd;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },
    searchKeyword(searchTxt) {
      // this.$store.state.meta.searchFactoryKeyword = searchTxt.replace("#", "");
      // this.gotoPage("/search/factorylist");

      const selectedList = [
        {
          cd: 1,
          name: searchTxt,
          listName: "keyword",
        },
      ];
      let getUrl = "/search/factorylist?";
      const getParams = "selectedList=" + JSON.stringify(selectedList);
      this.gotoPage(getUrl + encodeURI(getParams));
    },
    updateSwiper(index) {
      this.swiperIndex = index;
    },
    moveSlide(pageIndex) {
      this.isImgPopup = true;
      this.clickThumb(
        this.factoryFileList[pageIndex].fileUrl,
        this.factoryFileList[pageIndex].sourceFileName,
        pageIndex
      );
    },
    request() {
      this.sendBtnEvent("3");
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck();
        return;
      }
      if (sessionStorage.getItem("compType") != "2") {
        this.showToast(
          this.factoryDetails.factoryName +
            "는 자동으로 관심업체로 등록되며 고객님께 안심 전화번호가 바로 안내됩니다."
        );
      }
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.$route.params.factoryNo,
        "1"
      );
      this.$store.state.meta.estimateFactoryNo = [this.$route.params.factoryNo];
      this.$store.state.meta.estimateMatchingCatg = 2;
      this.gotoPage("/estimate");
    },
    clickThumb(fileUrl, fileName, index) {
      this.regImgUrl = fileUrl;
      this.regImgName = fileName;
      this.updateSwiper(index);
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck();
        return;
      }
      let updateVal = !this.factoryDetails.isLike;
      this.factoryDetails.isLike = updateVal;
      if (this.factoryDetails.isLike) {
        this.factoryDetails.likeCount++;
      } else {
        this.factoryDetails.likeCount--;
      }
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.factoryDetails.factoryNo,
        updateVal ? "1" : "0"
      );
      if (updateVal) {
        this.showToast("관심 공장으로 추가 되었습니다.");
      } else {
        this.showToast("관심 공장이 취소 되었습니다.");
      }
    },
    clickShare(d) {
      this.shareUrl =
        document.location.host + "/search/factorydetail/" + d.factoryNo;
      this.isShare = true;
    },
    copyUrl() {
      // 텍스트를 클립보드에 복사
      navigator.clipboard
        .writeText(this.shareUrl)
        .then(() => {
          // 복사 성공 시 로직 추가 가능
          console.log("Text copied to clipboard:", this.shareUrl);
          this.isToast("URL 링크가 복사되었습니다.");
        })
        .catch((err) => {
          // 복사 실패 시 로직 추가 가능
          console.error("Unable to copy to clipboard:", err);
        });
      this.isShare = false;
      // this.isCopy = true;
    },
    initDetail(factoryNo) {
      this.$API.search
        .factoryDetailV3(sessionStorage.getItem("userId"), factoryNo)
        .then((res) => {
          let d = res.data.body;
          console.log(d);

          // console.log(d.latitude, d.longitude);

          this.factoryFileList = d?.factoryFileList;
          if (this.factoryFileList) {
            this.mainImgUrl = this.factoryFileList[0]?.fileUrl;
          }

          d.compDesc = d.compDesc?.replace(/\n/g, "<br>");

          d.autoCount = 0;
          if (d.compNum || d.factoryCompNum) d.autoCount += 1;
          if (d.certVisit === "1") d.autoCount += 1;
          if (d.contractFlag === "1") d.autoCount += 1;

          d.isLike = d.likeFlag === "1" ? 1 : 0;
          // d.likeCount = Number(d.likeCount) + Number(d.isLike ? -1 : 0);

          this.cntMax = d.cnt1 + d.cnt2 + d.cnt3 + d.cnt4 + d.cnt5;

          //소개글 URL 하이퍼링크
          d.compDesc = d.compDesc
            ? this.replaceURLWithHTMLLinks(d.compDesc)
            : "";

          this.factoryDetails = d;

          this.groupedByUprCommCd(d);
        });
    },
    allCheck() {
      this.isPaymentCheck[0] = !this.isPaymentCheck[0];
      this.isPaymentCheck.forEach((e, i) => {
        this.isPaymentCheck[i] = this.isPaymentCheck[0];
      });
    },
    openCall(factoryNo, telNum) {
      this.isCall = true;
      // callFactory(factoryNo, telNum);
    },
    goBefore() {
      if (this.calculateType == "1") {
        this.paymentStep--;
      } else {
        this.paymentStep = 1;
      }
    },
    goNext() {
      if (this.paymentStep == 1 && !this.paymentType) {
        this.showToast("결제 수단을 선택해주세요.");
      } else if (this.paymentStep == 1 && !this.calculateType) {
        this.showToast("세금계산서 발행 여부를 선택해주세요.");
      } else if (this.paymentStep == 2 && !this.paymentData.compName) {
        this.showToast("사업자 명을 입력해주세요.");
      } else if (this.paymentStep == 2 && !this.paymentData.compNum) {
        this.showToast("사업자등록번호를 입력해주세요.");
      } else if (this.paymentStep == 2 && !this.paymentData.representName) {
        this.showToast("대표자 명을 입력해주세요.");
      } else if (this.paymentStep == 2 && !this.paymentData.bizType) {
        this.showToast("업태를 입력해주세요.");
      } else if (this.paymentStep == 2 && !this.paymentData.bizClass) {
        this.showToast("종목을 입력해주세요.");
      } else if (this.paymentStep == 2 && !this.paymentData.compAddress) {
        this.showToast("사업장 주소를 입력해주세요.");
      } else if (this.paymentStep == 2 && !this.paymentData.taxEmail) {
        this.showToast("계산서 이메일을 입력해주세요.");
      } else {
        if (this.calculateType == "1") {
          this.paymentStep++;
        } else {
          this.paymentStep = 3;
        }
      }
    },
    goPayment() {
      if (!this.userId) {
        this.goLogin();
      } else if (!(this.paymentData.paymentAmount > 0)) {
        this.showToast("결제 금액을 입력해주세요.");
      } else {
        if (
          this.isPaymentCheck[1] &&
          this.isPaymentCheck[2] &&
          this.isPaymentCheck[3]
        ) {
          console.log(this.designerInfo);

          if (this.calculateType == "1") {
            this.identityNum = this.paymentData.receiptTelNum;
          } else if (this.calculateType == "2") {
            this.identityNum = this.paymentData.receiptCompNum;
          }
          this.$API.payment
            .createPaypleFactory(
              this.userId,
              this.factoryNo,
              "의류 제작",
              this.paymentData.paymentAmount,
              this.paymentType,
              "",
              "1",
              this.calculateType,
              this.calculateType,
              this.identityNum,
              this.paymentData
            )
            .then((res) => {
              console.log(res);
              if (res.data.resultCode == "10000") {
                let d = res.data.body;
                this.paypleData = d;
                console.log(d);
                let obj = {};
                // obj.clientKey = "bXFwRzgxVzJiTUdVeHViYnNId2xrUT09";
                obj.PCD_BUYER_NAME = sessionStorage.getItem("userName");
                obj.PCD_PAY_TYPE = "card";
                obj.PCD_PAY_WORK = "PAY";
                obj.PCD_CARD_VER = "02";
                obj.PCD_PAY_GOODS = d.itemName;
                // obj.PCD_PAY_TOTAL = 100;
                obj.PCD_PAY_TOTAL = d.paymentAmount;
                obj.PCD_PAY_URL = d.returnUrl;
                obj.PCD_AUTH_KEY = d.authKey;

                // obj.PCD_RST_URL =
                //   "/custom/orderResult/" + paymentData.orderNum;
                // obj.callbackFunction = this.gotoPage(
                //   "/custom/orderResult/" + paymentData.successUrl
                // );

                obj.callbackFunction = this.getResult;

                window.PaypleCpayAuthCheck(obj);
                // loadTossPayments(d.clientKey).then((tossPayments) => {
                //   tossPayments.requestPayment(this.paymentType, {
                //     // 결제 수단 파라미터
                //     amount: d.paymentAmount,
                //     orderId: d.orderId,
                //     orderName: d.itemName,
                //     customerName: sessionStorage.getItem("userName"),
                //     successUrl: d.successUrl,
                //     failUrl: d.failUrl,
                //   });
                // });
              } else {
                this.setMsgPopup(
                  true,
                  "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                );
              }
            });
        }
      }
    },
    getResult(res) {
      console.log(res);
      console.log(this.paypleData);
      if (res.PCD_PAY_RST === "success") {
        const payResult = res;

        this.gotoUrl(this.paypleData.successUrl);
      } else {
        // 결제 실패일 경우 알림 메시지
        window.alert(res.PCD_PAY_MSG);
      }
    },
    goLogin() {
      this.$emit("openLogin");
    },
    goFindId() {
      this.$emit("openFindId");
    },
    // resize 이벤트 핸들러
    handleResize(event) {
      const windowWidth = event.target.innerWidth;
      console.log(windowWidth);

      if (this.$store.state.meta.windowWidth <= 480) {
        var square = document.querySelectorAll(".body-img-wrap");
        console.log(square);
        console.log(square.offsetWidth);
        square.forEach((e) => {
          e.style.height = e.offsetWidth + "px";
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/factory.scss";
</style>
