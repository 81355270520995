<template>
  <div class="mall-item-list" v-if="isMobile == 'false'">
    <div class="mall-item-list-swiper">
      <div class="mall-item-list-title">
        <p>{{ title }}</p>
      </div>
      <div class="swiper-container card">
        <swiper
          id="materialCard"
          :slidesPerView="4"
          :modules="modules"
          :spaceBetween="12"
          :navigation="isMobile == 'false' ? true : false"
          class="swiper-wrapper material-card"
        >
          <swiper-slide
            class="swiper-slide items"
            v-for="(x, i) in mallList"
            :key="i"
            @click="goDetail(`${x.mallNo}`, `${x.itemName}`)"
            ><div class="items-top">
              <div class="items-top-img">
                <img :src="x.imgUrl" />
              </div>
            </div>
            <div class="items-body">
              <div class="item-name">
                <p>{{ x.itemName }}</p>
              </div>
              <div class="item-length">
                <p>{{ x.itemUnitValue }}</p>
              </div>
              <div class="sale">
                <p>할인가</p>
                <p>{{ comma(x.itemExposeCost) }}원</p>
              </div>
              <div class="price">
                <p>{{ comma(x.itemExposeCost) }}원</p>
                <p class="unit" v-if="x.itemLengthValue > 0">
                  ({{ x.itemLengthName }}, {{ comma(x.itemLengthValue) }}원)
                </p>
              </div>
              <div class="delivery" v-if="x.deliveryPrice == 0">
                <span class="material-symbols-outlined"> local_shipping </span>
                무료배송
              </div>
            </div>
          </swiper-slide>
          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->
        </swiper>
      </div>
    </div>
  </div>
  <div v-else class="mall-item-mobile">
    <div v-if="mallList.length > 0">
      <div class="mall-item-mobile-title">
        <p>{{ title }}</p>
      </div>
      <div class="mall-item-mobile-wrap">
        <div
          class="card"
          v-for="(x, i) in mallList"
          :key="i"
          @click="goDetail(`${x.mallNo}`, `${x.itemName}`)"
        >
          <div class="card-left">
            <div class="card-left-img">
              <img :src="x.imgUrl" />
            </div>
          </div>
          <div class="card-right">
            <div class="item-name">
              <p>{{ x.itemName }}</p>
            </div>
            <div class="item-length" v-if="x.itemUnitValue">
              <p>{{ x.itemUnitValue }}</p>
            </div>
            <div class="sale" v-if="x.itemNormalCost">
              <p>할인가</p>
              <p class="exist">{{ comma(x.itemNormalCost) }}원</p>
            </div>
            <div class="price">
              <p>{{ comma(x.itemExposeCost) }}원</p>
              <p class="unit" v-if="x.itemLengthValue > 0">
                ({{ x.itemLengthName }}, {{ comma(x.itemLengthValue) }}원)
              </p>
            </div>
            <div class="delivery" v-if="x.deliveryPrice == 0">
              <span class="material-symbols-outlined"> local_shipping </span>
              무료배송
            </div>
          </div>
        </div>
      </div>
      <!-- <CompMaterialCard
        @openLogin="openLogin"
        :listData="mallList"
      ></CompMaterialCard> -->
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import CompMaterialCard from "./CompMaterialCard.vue";

export default {
  name: "CompDesignItemSwiper",
  components: {
    Swiper,
    SwiperSlide,
    // CompMaterialCard,
  },
  props: {
    title: {
      type: String,
    },
    mallList: {
      type: Object,
    },
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  activated() {
    console.log("bbb");
  },
  data() {
    return {
      d: {},
      i: [],
      mallNo: "",
      regImgUrl: "",
      regImgName: "",
      detailData: {},
      addItem: [],
      options: [],
      sumPrice: 0,
      paymentPrice: 0,
      sumCount: 0,
      minCount: 1,
      color: [],
      size: [],
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
      get getBasket() {
        return sessionStorage.getItem("basketItem");
      },
      basketCount: 0,
      optionDatas: [],
      selectItem: [],
      selectIndex: 0,
      selectOptionName: "",
      isOrder: false,
    };
  },
  unmounted() {
    this.$store.state.meta.detailTitle = "";
  },
  mounted() {},
  methods: {
    updateSwiper() {
      this.swiperIndex = document.getElementById("imgSwiper").swiper.realIndex;
    },
    goDetail(mallNo, itemName) {
      this.$store.state.meta.detailTitle = itemName;
      console.log(this.$store.state.meta.detailTitle);
      console.log(mallNo);
      if (this.userId) {
        this.gotoUrl(`/mall/materialdetail/${mallNo}`);
      } else {
        this.$emit("openLogin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
}
.mall-item-mobile {
  margin: 20px 0 40px;
  padding: 20px;
  border-radius: 8px;
  background-color: $neutral-0;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
  &-title {
    padding: 2px 0;
    display: flex;
    p {
      height: 26px;
      border-bottom: 1px solid $primary-50;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &-wrap {
    .card {
      cursor: pointer;
      display: flex;
      gap: 12px;
      padding: 20px 0;
      border-bottom: 1px solid $neutral-10;
      @media (max-width: 960px) {
        gap: 16px;
        &:last-child {
          border-bottom: 0;
        }
      }
      &-left {
        max-width: 78px;
        max-height: 78px;
        position: relative;
        width: 100%;
        &:hover {
          border-color: $primary-20;
        }
        &-img {
          border: 1px solid $neutral-10;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
          width: 100%;
          padding-bottom: 100%; /* 가로 세로 비율을 맞추기 위한 패딩 값 */
          img {
            position: absolute;
            height: 100%;
          }
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        .item-name {
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;
          display: flex;
          gap: 8px;
        }
        .item-length {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
        }
        .sale {
          margin-top: 8px;
          display: flex;
          gap: 8px;
          font-size: 13px;
          font-weight: 400;
          line-height: 140%; /* 18.2px */
          color: $primary-60;
          .exist {
            color: $neutral-40;
            text-decoration: line-through;
          }
        }
        .price {
          margin-top: 2px;
          display: flex;
          gap: 4px;
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          color: $primary-60;
          .unit {
            font-size: 12px;
          }
        }
        .unit-cost {
          font-size: 16px;
          font-weight: 500;
          line-height: 140%;
          @media (max-width: 800px) {
            font-size: 15px;
          }
        }
        .delivery {
          margin-top: 8px;
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          line-height: 140%;
          color: $primary-80;
          span {
            font-size: 12px;
          }
        }
      }
      &:hover {
        border-color: $primary-50;
      }
    }
  }
}
.mall-item-list {
  margin: 20px 0 40px;
  padding: 24px;
  border-radius: 8px;
  background-color: $neutral-0;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
  &-title {
    padding: 2px 0;
    display: flex;
    p {
      height: 26px;
      border-bottom: 1px solid $primary-50;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &-swiper {
    .card {
      margin-top: 30px;
      overflow-x: auto;
      :deep(.swiper) {
        .swiper-wrapper {
          @media (max-width: 520px) {
            margin-left: 20px;
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          position: absolute;
          border-radius: 4px;
          border: 1px solid $neutral-20;
          background-color: $neutral-0;
          opacity: 0.5;
          top: 168px;
          width: 20px;
          height: 40px;
          &::after {
            font-size: 10px;
            color: $neutral-60;
          }
        }
        .swiper-button-prev {
          left: 0;
        }
        .swiper-button-next {
          right: 0;
        }
      }
      .material-card {
        .items {
          padding: 8px;
          max-width: 226px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media (max-width: 960px) {
            gap: 10px;
          }
          &-top {
            position: relative;
            width: 100%;
            &:hover {
              border-color: $primary-20;
            }
            &-img {
              border: 1px solid $neutral-10;
              border-radius: 4px;
              position: relative;
              overflow: hidden;
              width: 100%;
              padding-bottom: 100%; /* 가로 세로 비율을 맞추기 위한 패딩 값 */
              img {
                position: absolute;
                height: 100%;
              }
            }
          }
          &-body {
            display: flex;
            flex-direction: column;
            .item-name {
              font-size: 14px;
              font-weight: 500;
              line-height: 140%;
              display: flex;
              gap: 8px;
            }
            .item-length {
              margin-top: 4px;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%;
            }
            .sale {
              margin-top: 8px;
              display: flex;
              gap: 8px;
              font-size: 13px;
              font-weight: 400;
              line-height: 140%; /* 18.2px */
              color: $primary-60;
              :last-child {
                color: $neutral-40;
                text-decoration: line-through;
              }
            }
            .price {
              margin-top: 2px;
              display: flex;
              gap: 4px;
              font-size: 15px;
              font-weight: 500;
              line-height: 140%;
              color: $primary-60;
              .unit {
                font-size: 12px;
              }
            }
            .unit-cost {
              font-size: 16px;
              font-weight: 500;
              line-height: 140%;
              @media (max-width: 800px) {
                font-size: 15px;
              }
            }
            .delivery {
              margin-top: 4px;
              display: flex;
              align-items: center;
              gap: 4px;
              font-size: 12px;
              line-height: 140%;
              color: $primary-80;
              span {
                font-size: 12px;
              }
            }
          }
          &:hover {
            border-color: $primary-50;
          }
        }
      }
    }
  }
}
</style>
