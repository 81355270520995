<template>
  <div class="payment-card">
    <div class="payment-card-wrap">
      <div class="payment-card-wrap-top">
        <div class="space-between">
          <div class="flex-start flex1" :class="Number(cardData.contractStatus) <= 3 ? 'ing' : (cardData.contractStatus == 4 ? 'complete' : 'finish')">
            <p v-if="Number(cardData.contractStatus) <= 3" class="state">진행중</p>
            <p v-else-if="cardData.contractStatus == 4" class="state">계약완료</p>
            <p v-else-if="cardData.contractStatus == 5" class="state">종료</p>
            <p class="info name ellipse">{{cardData.contractName}}</p>
          </div>
          <p class="info date">{{ cardData.createdDate }}</p>
        </div>
      </div>
      <div class="payment-card-wrap-body flex1">
        <div class="flex-start">
          <p class="info-title">스타일명</p>
          <p class="info">{{ cardData.styleName }}</p>
        </div>
        <div class="flex-start mt-12">
          <p class="info-title">계약일</p>
          <p class="info">{{ cardData.factorySignDate }}</p>
        </div>
        <div class="flex-start mt-12">
          <p class="info-title">납품일</p>
          <p class="info">{{ cardData.deliveryDateFin }}</p>
        </div>
        <div class="flex mt-12">
          <div class="flex-start flex1">
            <p class="info-title">총 수량</p>
            <p class="info">{{ comma(cardData.quantity) }}</p>
          </div>
          <div class="flex-start flex1">
            <p class="info-title">총 금액</p>
            <p class="info">{{ comma(cardData.styleTotalCostVat) }}</p>
          </div>
        </div>
          <!-- <div class="flex space-between mt-20">
            <p class="info status" :class="contractStatus[cardData.contractStatus].class">{{ contractStatus[cardData.contractStatus].txt }}</p>
            <div v-if="cardData.contractStatus == '1' || cardData.contractStatus == '2'" class="flex">
              <p class="matchup">매치업된 공장 <span>{{cardData.matchUpFactory}}</span></p>
              <p class="slash">/</p>
              <p class="part-count">참여 공장 <span :class="cardData.readingFlag == '0' && cardData.partCount != '0' ? 'active' : ''">{{cardData.partCount}}</span></p>
            </div>
            <div v-if="cardData.contractStatus == '4'" class="space-between flex1">
              <p class="need-complete">의뢰를 완료해주세요!</p>
              <img class="pointer" src="@/assets/icon/ic-trash.svg">
            </div>
          </div> -->

        <!-- 다지이너 -->
        <div v-if="compType == 1 && cardData.contractStatus != 4 && cardData.contractStatus" class="payment-card-wrap-body-content">
          <p v-if="cardData.contractStatus == 1" class="green">{{$store.state.meta.loginInfo.showUserName}}님!<br>디지털 계약서를 작성/완료해주세요</p>
          <p v-else-if="cardData.contractStatus == 2">공장이 계약서를 확인하고 사인할 때까지 기다려주세요.</p>
          <p v-else-if="cardData.contractStatus == 3" class="green">디지털 계약서가 완료되었습니다.<br>결제해주세요!</p>
          <p v-else-if="cardData.contractStatus == 5">기한 만료로 계약이 종료되었습니다.</p>
        </div>
        <!-- 공장 -->
        <div v-else-if="compType == 2 && cardData.contractStatus != 4 && cardData.contractStatus" class="payment-card-wrap-body-content">
          <p v-if="cardData.contractStatus == 1">디자이너가 계약서를 확인하고 사인할 때까지 기다려주세요.</p>
          <p v-else-if="cardData.contractStatus == 2" class="green">계약서 확인 후 사인해주세요.</p>
          <p v-else-if="cardData.contractStatus == 3">디자이너 확인 후 결제하면 계약이 완료됩니다.</p>
          <p v-else-if="cardData.contractStatus == 5">기한 만료로 계약이 종료되었습니다.</p>
        </div>
        <!-- <div class="payment-card-wrap-body-content" :class="$route.query.type == 'load' ? 'remove-line' : ''"> -->
          <!-- <div class="row">
            <p class="info">계약서 확인 후 사인해주세요.</p>
            <p class="info">{{ cardData.catgName1 + ' > ' + cardData.catgName2 + ' > ' + cardData.catgName3 }}</p>
          </div> -->
          <!-- <div v-if="cardData.workProcess == '봉제'">
            <div class="space-between mt-16">
              <p>컬러수<span>{{cardData.colorCount || '-'}}</span></p>
              <p>사이즈수<span>{{cardData.colorCount || '-'}}</span></p>
              <p>총수량<span>{{cardData.quantity || '-'}}</span></p>
            </div>
            <p class="mt-16">희망단가<span>{{`${Number(cardData.minUnitCost).toLocaleString('ko-KR')} ~ ${Number(cardData.maxUnitCost).toLocaleString('ko-KR')}`}}</span></p>
          </div>
          <div v-else-if="cardData.workProcess == '패턴/샘플'">
            <div class="infos mt-16">
              <p>작업
                <span v-if="!cardData.processType">{{'-'}}</span>
                <span v-else-if="cardData.processType == '11'">{{'패턴'}}</span>
                <span v-else-if="cardData.processType == '12'">{{'샘플'}}</span>
                <span v-else-if="cardData.processType == '13'">{{'패턴/샘플'}}</span>
              </p>
              <p>참고샘플<span>{{cardData.sampleFlag == '1' ? '보유' : '미보유'}}</span></p>
            </div>
          </div>
          <div v-else-if="cardData.workProcess == '자수/나염/전사'">
            <div class="infos mt-16">
              <p>작업<span>{{cardData.workType ? (cardData.workType == '3' ? '재단물' : '완제품') : '-'}}</span></p>
              <p>원단<span>{{cardData.fabric == '1' ? '결정' : '미결정'}}</span></p>
            </div>
            <p class="mt-16">총수량<span>{{cardData.quantity || '-'}}</span></p>
          </div> -->
        <!-- </div> -->
      </div>
      <div class="payment-card-wrap-bottom">
        <!-- <div v-if="$route.query.type !== 'load'" class="bottom-btns">
          <div class="bottom-btn">참여 공장(<p :class="cardData.partCount !== 0 ? 'active' : ''">{{ cardData.partCount }}</p>)</div>
          <div class="bottom-btn" :class="cardData.contractStatus === '1' ? 'active' : ''">{{ contractStatus[cardData.contractStatus] }}</div>
        </div> -->
        <p v-if="cardData.contractStatus == 3 && compType == 1" class="pay-time pink semibold">결제 가능 시간 : {{ remainingTime(cardData.timeDiffcs) }} 남음</p>
        <div class="bottom-btns flex">
          <p v-if="cardData.contractStatus == 2 && compType == 2" class="bottom-btn pay" @click="gotoPage(`/contract/${cardData.matchingNo}/${cardData.contractNo}`)">계약서 확인 및 사인하기</p>
          <!-- <p v-else-if="cardData.contractStatus == 3 && compType == 1" class="bottom-btn pay" @click="gotoPage(`/contract/${cardData.matchingNo}`)">계약서 확인 및 결제하기</p> -->
          <p v-else class="bottom-btn" @click="gotoPage(`/contract/${cardData.matchingNo}/${cardData.contractNo}`)">계약서 보기</p>
          <p v-if="cardData.contractStatus == 3 && compType == 1" class="bottom-btn pay" @click="gotoPage(`/payment/${cardData.contractNo}`)">결제하기</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'paymentView',
  props: {
    cardData: {
      type: Object,
      description: "Card data"
    }
  },
  data () {
    return {
      contractStatus: {
        '0': { txt: '매칭 준비중', class: 'green' },
        '1': { txt: '매칭중', class: 'yellow' },
        '2': { txt: '매칭중', class: 'yellow' },
        '3': { txt: '매칭종료', class: 'end' },
        '4': { txt: '매칭 정보 작성중', class: 'ing' },
      }
    }
  },
  computed: {
    compType () {
      return sessionStorage.getItem('compType')
    }
  },  
  methods: {
    // selectPayment (paymentNo) {
    //   console.log(sessionStorage.getItem('userId'));
    //   console.log(paymentNo);
    //   this.$API.payment.paymentDetails(sessionStorage.getItem('userId'), paymentNo)
    //   .then((res) => {
    //     this.paymentDetails = res.data.body.paymentDetails
    //   })
    // },
    moveService (isProgress) {
      if (!sessionStorage.getItem('userId')) { // 로그인 안되어있을때 로그인 화면으로
        this.loginCheck()
      } else if (this.$route.query.type === 'load') {
        this.moveConfirm()
      } else if (isProgress) {
        this.gotoPage(`/paymentconfirm`)
        this.showToast('의뢰내역을 불러왔습니다.')
      } else if (sessionStorage.getItem('compType') == '1') {
        this.gotoPage(`/paymentdetails/${this.cardData.paymentNo}`)
      } else {
        this.gotoPage(`/paymentproposal/${this.cardData.paymentNo}`)
      }
    },
    moveConfirm () {
      this.$API.payment.paymentStyleCopy(sessionStorage.getItem('userId'), this.cardData.paymentNo)
        .then(() => {
          this.gotoPage(`/paymentconfirm`)
          this.showToast('의뢰내역을 불러왔습니다.')
        })
    },
    remainingTime (date) {
      try {
        // let dayOfWeek = new Date(date).getDay()
        // let alpha = 2
        // if (dayOfWeek == 5 || dayOfWeek == 6) alpha = 4
        // if (dayOfWeek == 0) alpha = 3
        // let remainingMin = (24*60*alpha) - ((new Date() - new Date(date))/1000/60)
        // return `${Math.floor(remainingMin/60)}시간 ${Math.floor(remainingMin%60)}분`
        return `${date.split(":")[0]}시간 ${date.split(":")[1]}분`
      } catch {
        return '0분'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .payment-card {
    @include mobile {
      margin-top: rem(12px);
      flex: 1;
    }
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    line-height: 20px;
    .info-title {
      color: $neutral-50;
      white-space: nowrap;
    }
    .info {
      @include ellipse();
      font-weight: 500;
      color: $neutral-60;
      margin-left: 12px;
    }
    &-wrap {
      background-color: $neutral-0;
      flex-grow: 1;
      border-radius: rem(12px);
      border: 1px solid $neutral-10;
      height: 100%;
      display: flex;
      flex-direction: column;
      @include mobile {
        margin: 0 rem(16px);
      }
      &-top {
        border-bottom: 1px solid $neutral-10;
        padding: rem(16px);
        .state {
          font-weight: 500;
          font-size: 12px;
          border-radius: 4px;
          padding: rem(2px) rem(8px);
        }
        .name {
          font-weight: 700;
          color: $neutral-80;
        }
        .date {
          font-size: 14px;
          color: $neutral-50;
        }

        .ing {
          .state {
            border: 1px solid $primary-50;
            color: $primary-50;
          }
          .name {
            color: $primary-50;
          }
        }
        .complete {
          .state {
            background-color: $neutral-80;
            color: $neutral-0;
          }
          .name {
            color: $neutral-80;
          }
        }
        .finish {
          .state {
            border: 1px solid $neutral-40;
            color: $neutral-40;
          }
          .name {
            color: $neutral-40;
          }
        }
      }
      &-body {
        padding: rem(24px) rem(16px) 0;
        &-content {
          margin-top: 24px;
          padding: 16px 0;
          border-top: 1px solid $neutral-10;
          border-bottom: 1px solid $neutral-10;
          color: $neutral-50;
          font-weight: 500;
          line-height: 23px;
        }
      }

      &-bottom {
        padding: rem(16px);
        .pay-time {
          margin-bottom: 16px;
        }
        .bottom-btns {
          gap: rem(8px);
          color: $neutral-70;
        }
        .bottom-btn {
          flex: 1;
          font-weight: 500;
          padding: rem(12px);
          cursor: pointer;
          display: flex;
          justify-content: center;
          border: 1px solid $neutral-10;
          border-radius: 7px;
          &.pay {
            background-color: $neutral-50;
            color: $neutral-0;
            border-color: $neutral-50;
          }
        }
      }

    }
  }
</style>