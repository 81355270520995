<template>
  <div class="notice-detail" v-if="noticeDetail">
    <div class="notice-detail-top" v-if="isMobile == 'false'">
      <p class="square"></p>
      <p class="title">공지사항</p>
    </div>
    <div class="notice-detail-body">
      <div class="head">
        <p class="title">{{ noticeDetail.noticeTitle }}</p>
        <p class="date">{{ noticeDetail.createdDate }}</p>
      </div>
    </div>
    <div class="notice-detail-bottom">
      <p>{{ noticeDetail.noticeContents }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeListComp",
  props: {
    noticeDetail: {
      type: Object,
      description: "Contract Data",
    },
  },
  data() {
    return {
      filters: {
        read: [
          { name: "전체", cd: "" },
          { name: "읽지않음", cd: "0" },
        ],
        state: [
          { name: "전체", cd: "" },
          { name: "매칭", cd: "1" },
          { name: "메시지", cd: "2" },
          { name: "발주", cd: "3" },
        ],
      },
      sortOrder: 0,
      readingFlag: "",
      noticeStatus: "",
      searchText: "",
      noticeData: [],
      pageNow: 0,
      pageList: 20,
      isLoading: true,
      totalCount: 0,
      isMobile: sessionStorage.getItem("isMobile"),
    };
  },
  mounted() {
    this.updateNoticeList(true);
    this.setPopup(false);
    document.addEventListener("scroll", this.infinityCheck);
  },
  methods: {
    readAll() {
      this.$API.mypage
        .noticeReadAll(sessionStorage.getItem("userId"))
        .then((res) => {
          this.updateNoticeList(true);
        });
    },
    updateNoticeList(isReset) {
      console.log(this.searchText);
      this.isLoading = true;
      if (isReset) {
        this.pageNow = 0;
      }
      this.pageNow++;
      this.$API.mypage
        .getNoticeList(
          sessionStorage.getItem("userId"),
          this.noticeStatus,
          this.readingFlag,
          this.searchText,
          this.pageNow,
          this.pageList
        )
        .then((res) => {
          console.log(res);
          if (res.data.resultCode == "10000") {
            if (isReset) {
              this.noticeData = res.data.body.resnoticeList;
              this.totalCount = res.data.body.totalCount;
            } else {
              res.data.body.resNoticeList.forEach((e) => {
                this.noticeData.push(e);
              });
            }
          } else {
            if (isReset) {
              this.noticeData = [];
              this.totalCount = 0;
            }
          }
          this.isLoading = false;
        });
    },

    selectData(data, type) {
      if (type === "state") {
        this.noticeStatus = data.cd;
      } else if (type === "reading") {
        this.readingFlag = data.cd;
      }
      this.updateNoticeList(true);
    },
    infinityCheck() {
      if (this.isLoading) return;
      if (Math.ceil(this.totalCount / this.pageList) <= this.pageNow) return;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.updateNoticeList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-detail {
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  &-top {
    margin: 0 24px;
    padding: 40px 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .title {
      margin-left: 8px;
      color: $primary-80;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
    }
    .square {
      width: 8px;
      height: 8px;
      background-color: $primary-50;
    }
  }
  &-body {
    @media (max-width: 520px) {
      margin: 40px 20px 0;
    }
    margin: 0 28px;
    padding-bottom: 20px;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: $neutral-80;
      }
      .date {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $neutral-60;
      }
    }
  }
  &-bottom {
    margin: 20px 28px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $neutral-80;
  }
}
</style>
