import { initializeApp } from "firebase/app";
import {
  collectionGroup,
  getFirestore,
  getDoc,
  getDocs,
  setDoc,
  doc,
  onSnapshot,
  collection,
  query,
  where,
  orderBy,
  limit,
  serverTimestamp,
  writeBatch,
  updateDoc,
  querySnapshot,
} from "firebase/firestore";
import {
  DEFAULT_FIREBASE,
  CHAT_ROOM_FIELD,
  CHAT_ROOM_SETTINGS,
  LIVE_CHAT_ROOM_ACTIVATE,
  LIVE_CHAT_ROOM_INIT,
  READ_FLAG,
} from "@/constants/chat";

class FireBaseManeger {
  constructor() {
    let config = {};
    if (
      location.hostname === "osle.co.kr" ||
      location.hostname === "www.osle.co.kr"
    ) {
      config = {
        apiKey: "AIzaSyBwNPlNoyYANKhjsi74MSAYNaxq3MNPy3U",
        authDomain: "osle-front.firebaseapp.com",
        projectId: "osle-front",
        storageBucket: "osle-front.appspot.com",
        messagingSenderId: "253601030273",
        appId: "1:253601030273:web:f33c31b4bdec6abcc86d80",
      };
    } else {
      config = {
        apiKey: "AIzaSyCwo4ZOn9agw4904VmQnRm9VO1wX7yscWI",
        authDomain: "osle-front-dev.firebaseapp.com",
        databaseURL: "https://osle-front-dev-default-rtdb.firebaseio.com",
        projectId: "osle-front-dev",
        storageBucket: "osle-front-dev.appspot.com",
        messagingSenderId: "692576262564",
        appId: "1:692576262564:web:f45eddb1f528ab09610931",
      };
      // config = {
      //   apiKey: "AIzaSyDVersR1V5pLZXG0eXpaOzGWn5U-FMXZEs",
      //   authDomain: "osle-dev.firebaseapp.com",
      //   projectId: "osle-dev",
      //   storageBucket: "osle-dev.appspot.com",
      //   messagingSenderId: "379741926521",
      //   appId: "1:379741926521:web:61057767b5694d94ef10a8",
      // };
    }

    this.app = initializeApp(config);
    this.collectionRef = null;
    this.messageCollectionRef = null;
    this.chatrooms = [];
    this.chatroom = [];
    this.messages = [];
    this.users = [];
    this.init();
  }

  init() {
    this.collectionRef = collection(this.getStore(), DEFAULT_FIREBASE.CHAT);
  }

  // 방 리스트
  subscribeUsers() {
    let users = [];
    let userId = sessionStorage.getItem("userId");
    let comopType = sessionStorage.getItem("compType");

    const q = query(
      this.collectionRef,
      where(
        comopType == "1" ? CHAT_ROOM_FIELD.DESINGER : CHAT_ROOM_FIELD.FACTORY,
        "==",
        userId
      ),
      orderBy("updatedDate", "desc")
    );

    this.unsubcribeListener = onSnapshot(q, async (snapshot) => {
      // for (const change of snapshot.docChanges()) {
      snapshot.docChanges().forEach(async (change) => {
        this.users.push(change.doc.id);
        users.push(change.doc.id);
      });
      // }
    });
    return users;
  }

  // 방 리스트
  async subscribeChatRooms() {
    if (this.unsubcribeListener) {
      this.unsubcribeListener();
    }

    this.chatrooms = [];
    let userId = sessionStorage.getItem("userId");
    let comopType = sessionStorage.getItem("compType");

    const q = query(
      this.collectionRef,
      where(
        comopType == "1" ? CHAT_ROOM_FIELD.DESINGER : CHAT_ROOM_FIELD.FACTORY,
        "==",
        userId
      ),
      orderBy("updatedDate", "desc")
    );

    this.unsubcribeListener = onSnapshot(q, async (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        switch (change.type) {
          case "added": {
            const sender = change.doc.id.replace(userId, "").replace(":", "");
            let [setting, live] = await this.getChatRoomOptions(
              change,
              userId,
              sender
            );
            let result = {
              id: change.doc.id,
              data: change.doc.data(),
            };
            let messages = await this.getChatRoomReadFlag(
              change,
              sender,
              change.doc.id
            );
            result["setting"] = setting.value.data();
            result["live"] = live.value.data();
            result["messages"] = messages;
            result["isShowModal"] = false;

            this.chatrooms.push(result);
            this.chatroom = result;
            break;
          }
          case "modified":
            this.chatrooms.forEach((room) => {
              let docId = change.doc.id;
              if (room.id === docId) {
                let { message } = change.doc.data();

                room.data.message = message;
              }
            });
            break;
          case "removed":
            break;
        }
      });
    });
  }

  // 방 초기화
  subscribeChatRoom(designerId, factoryId) {
    console.log(designerId);
    console.log(factoryId);
    if (this.unsubcribeListener) {
      this.unsubcribeListener();
    }

    let userId = sessionStorage.getItem("userId");
    let compType = sessionStorage.getItem("compType");

    // const roomName = designerId + ":" + factoryId;
    // let docRef = doc(this.collectionRef, roomName);
    // const docSnapshot = getDoc(docRef);
    // console.log(docSnapshot.data());
    // let result = {
    //   id: roomName,
    //   data: docSnapshot.data(),
    // };
    // let [setting, live] = this.getChatRoomOptions(docSnapshot.data(), userId);
    // console.log(setting);
    // console.log(live);
    let result = [];

    const q = query(
      this.collectionRef,
      where(CHAT_ROOM_FIELD.DESINGER, "==", designerId),
      where(CHAT_ROOM_FIELD.FACTORY, "==", factoryId),
      orderBy("updatedDate", "desc")
    );

    this.unsubcribeListener = onSnapshot(q, async (snapshot) => {
      for (const change of snapshot.docChanges()) {
        const sender = change.doc.id.replace(userId, "").replace(":", "");
        let [setting, live] = await this.getChatRoomOptions(change, userId);
        result = {
          id: change.doc.id,
          data: change.doc.data(),
        };
        let messages = await this.getChatRoomReadFlag(
          change,
          sender,
          change.doc.id
        );
        console.log(messages);
        result["messages"] = messages;
        result["setting"] = setting.value.data();
        result["live"] = live.value.data();
        result["isShowModal"] = false;

        this.chatroom = result;
      }
    });
    return result;
  }

  // 메세지 실시간 업데이트
  subscribeMessage(messageCollectionRef) {
    if (this.unsubcribeMessageListener) {
      this.unsubcribeMessageListener();
    }

    this.messages = [];

    const snapshotQuery = query(
      messageCollectionRef,
      orderBy("createDate", "asc")
    );

    this.unsubcribeMessageListener = onSnapshot(snapshotQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        switch (change.type) {
          case "added":
            this.messages.push({
              id: change.doc.id,
              data: change.doc.data(),
            });

            break;
          case "modified":
            this.messages.forEach((message) => {
              let docId = change.doc.id;

              if (message.id === docId) {
                let { readFlag } = change.doc.data();
                message.data.readFlag = readFlag;
              }
            });
            break;
          case "removed":
            break;
        }
      });
    });
  }

  // 1:1 채팅방 입장
  async initChatRoom(docId) {
    this.messageCollectionRef = collection(
      this.getStore(),
      DEFAULT_FIREBASE.CHAT,
      docId,
      DEFAULT_FIREBASE.MESSAGES
    );
    const messageCollectionRef = this.messageCollectionRef;

    await this.messagesByReadFlagUpdate(docId);
    await this.subscribeMessage(messageCollectionRef);
  }

  async messagesByReadFlagUpdate(docId) {
    let userId = sessionStorage.getItem("userId");

    const docsQuery = query(
      this.messageCollectionRef,
      where("sender", "!=", userId)
    );

    const wBatch = writeBatch(this.getStore());
    const docs = await getDocs(docsQuery);

    docs.forEach((doc) => {
      wBatch.update(doc.ref, {
        readFlag: READ_FLAG.READ,
      });
    });

    // 상위 문서에도 업데이트 되는 코드 짜야함
    let docRef = doc(this.collectionRef, docId);
    const docSnap = await getDoc(docRef);
    const existingMessageData = docSnap.data().message;

    await updateDoc(doc(this.collectionRef, docId), {
      message: { ...existingMessageData, readFlag: 1 },
    });

    await wBatch.commit();
  }

  // 대화방 생성
  async createChatRoom(args) {
    const roomName = args.designerId + ":" + args.factoryId;
    let docRef = doc(this.collectionRef, roomName);

    const existingDoc = await getDoc(docRef);

    const liveCollectionRef = collection(docRef, DEFAULT_FIREBASE.LIVE);
    let userId = sessionStorage.getItem("userId");

    if (existingDoc.exists()) {
      if (sessionStorage.getItem("compType") == 1) {
        await updateDoc(doc(liveCollectionRef, args.designerId), {
          activate: LIVE_CHAT_ROOM_ACTIVATE.SHOW,
          // entrance:
          //   userId === args["designerId"]
          //     ? LIVE_CHAT_ROOM_INIT.TRUE
          //     : LIVE_CHAT_ROOM_INIT.FALSE,
        });
      } else {
        await updateDoc(doc(liveCollectionRef, args.factoryId), {
          activate: LIVE_CHAT_ROOM_ACTIVATE.SHOW,
          // entrance:
          //   userId === args["designerId"]
          //     ? LIVE_CHAT_ROOM_INIT.TRUE
          //     : LIVE_CHAT_ROOM_INIT.FALSE,
        });
      }
      return roomName;
    }

    args["createdDate"] = serverTimestamp();
    args["message"] = {};

    await setDoc(docRef, args);

    const messagesCollectionRef = collection(docRef, DEFAULT_FIREBASE.MESSAGES);
    await setDoc(doc(messagesCollectionRef), {});

    // setting
    let settingOption = {
      pin: CHAT_ROOM_SETTINGS.PIN.DEACTIVATE,
      alarm: CHAT_ROOM_SETTINGS.ALARM.DEACTIVATE,
    };

    const settingsDesignerCollectionRef = collection(
      docRef,
      `${DEFAULT_FIREBASE.SETTINGS}:${args["designerId"]}`
    );
    await setDoc(doc(settingsDesignerCollectionRef, "chatroom"), settingOption);

    const settingsFactoryCollectionRef = collection(
      docRef,
      `${DEFAULT_FIREBASE.SETTINGS}:${args["factoryId"]}`
    );
    await setDoc(doc(settingsFactoryCollectionRef, "chatroom"), settingOption);

    // live

    await setDoc(doc(liveCollectionRef, "chatroom"), {
      constructor: userId,
      participants:
        userId === args["designerId"] ? args["factoryId"] : args["designerId"],
    });

    await setDoc(doc(liveCollectionRef, args["designerId"]), {
      activate: LIVE_CHAT_ROOM_ACTIVATE.SHOW,
      entrance:
        userId === args["designerId"]
          ? LIVE_CHAT_ROOM_INIT.TRUE
          : LIVE_CHAT_ROOM_INIT.FALSE,
    });

    await setDoc(doc(liveCollectionRef, args["factoryId"]), {
      activate: LIVE_CHAT_ROOM_ACTIVATE.SHOW,
      entrance:
        userId === args["factoryId"]
          ? LIVE_CHAT_ROOM_INIT.TRUE
          : LIVE_CHAT_ROOM_INIT.FALSE,
    });

    return roomName;
  }

  // 메세지 저장
  async sendMessage(args, roomName) {
    this.messageCollectionRef = collection(
      this.getStore(),
      DEFAULT_FIREBASE.CHAT,
      roomName,
      DEFAULT_FIREBASE.MESSAGES
    );

    try {
      let docRef = doc(this.messageCollectionRef);
      await setDoc(docRef, args);

      await this.setLastMessage(args, roomName);
    } catch (error) {
      console.error("Error adding field to testB document:", error);
    }
  }

  async setLastMessage(args, roomName) {
    let docRef = doc(this.collectionRef, roomName);
    await updateDoc(docRef, {
      message: args,
      updatedDate: serverTimestamp(),
    });
  }

  async setLiveEntrance(roomName, entrance) {
    let userId = sessionStorage.getItem("userId");

    let docRef = doc(this.collectionRef, roomName);

    const liveCollectionRef = collection(docRef, DEFAULT_FIREBASE.LIVE);

    await updateDoc(doc(liveCollectionRef, userId), {
      entrance: entrance,
    });
  }

  async setLiveActivate(roomName, act) {
    let userId = sessionStorage.getItem("userId");

    let docRef = doc(this.collectionRef, roomName);

    const liveCollectionRef = collection(docRef, DEFAULT_FIREBASE.LIVE);

    await updateDoc(doc(liveCollectionRef, userId), {
      activate: act,
    });

    const { activate } = await this.getLiveOptions(roomName, userId);

    this.chatrooms.forEach((room) => {
      if (room.id === roomName) {
        room["live"].activate = activate;
      }
    });
  }

  async getLiveEntrance(roomName, userId) {
    let docRef = doc(this.collectionRef, roomName);

    const liveCollectionRef = collection(docRef, DEFAULT_FIREBASE.LIVE);

    const docSnapshot = await getDoc(doc(liveCollectionRef, userId));

    return docSnapshot.data();
  }

  // 옵션 조회
  async getLiveOptions(roomName, userId) {
    let docRef = doc(this.collectionRef, roomName);

    const liveCollectionRef = collection(docRef, DEFAULT_FIREBASE.LIVE);

    const docSnapshot = await getDoc(doc(liveCollectionRef, userId));

    return docSnapshot.data();
  }

  // 접속 유저 조회
  async getLiveChatRoom(roomName) {
    let docRef = doc(this.collectionRef, roomName);

    const liveCollectionRef = collection(docRef, DEFAULT_FIREBASE.LIVE);

    const docSnapshot = await getDoc(doc(liveCollectionRef, "chatroom"));

    return docSnapshot.data();
  }

  // 상단 고정 세팅
  async setSettingPin(roomName, id, updatePin) {
    let docRef = doc(this.collectionRef, roomName);

    const settingsollectionRef = collection(
      docRef,
      `${DEFAULT_FIREBASE.SETTINGS}:${id}`
    );

    await updateDoc(doc(settingsollectionRef, "chatroom"), {
      pin: updatePin,
    });

    const { pin } = await this.getSetting(roomName, id);

    this.chatrooms.forEach((room) => {
      if (room.id === roomName) {
        room["setting"].pin = pin;
      }
    });
  }

  // 메모
  async setSettingMemo(roomName, id, memo) {
    let docRef = doc(this.collectionRef, roomName);

    const settingsollectionRef = collection(
      docRef,
      `${DEFAULT_FIREBASE.SETTINGS}:${id}`
    );

    await updateDoc(doc(settingsollectionRef, "chatroom"), {
      memo: memo,
    });
  }

  async getSetting(roomName, id) {
    let docRef = doc(this.collectionRef, roomName);

    const settingsollectionRef = collection(
      docRef,
      `${DEFAULT_FIREBASE.SETTINGS}:${id}`
    );

    const snapshot = await getDoc(doc(settingsollectionRef, "chatroom"));

    return snapshot.data();
  }

  async getSettingMemo(roomName, id) {
    let docRef = doc(this.collectionRef, roomName);

    const settingsollectionRef = collection(
      docRef,
      `${DEFAULT_FIREBASE.SETTINGS}:${id}`
    );

    const snapshot = await getDoc(doc(settingsollectionRef, "chatroom"));

    return snapshot.data();
  }

  async getChatRoomOptions(change, id, sender) {
    return await Promise.allSettled([
      getDoc(
        doc(
          collection(change.doc.ref, `${DEFAULT_FIREBASE.SETTINGS}:${id}`),
          "chatroom"
        )
      ),
      getDoc(doc(collection(change.doc.ref, DEFAULT_FIREBASE.LIVE), `${id}`)),
    ]);
  }

  async getChatRoomReadFlag(change, sender, docId) {
    this.messageCollectionRef = collection(
      this.getStore(),
      DEFAULT_FIREBASE.CHAT,
      docId,
      DEFAULT_FIREBASE.MESSAGES
    );

    const docsQuery = query(
      this.messageCollectionRef,
      where("sender", "==", sender),
      orderBy("createDate", "desc"),
      limit(1)
    );

    const docs = await getDocs(docsQuery);
    let result = {};
    docs.forEach((doc) => {
      result = doc.data();
    });

    return result;
  }

  getChatRoomOption(roomName) {
    const room = this.chatrooms.find((room) => room.id === roomName);

    // if (!room) return JSON.parse(localStorage.getItem("roomName"));
    if (!room) return localStorage.getItem("roomName");

    localStorage.setItem("room", JSON.stringify(room));

    return room;
  }

  async getCreateChatroom(roomName) {
    let docRef = doc(this.collectionRef, roomName);
    const docSnapshot = await getDoc(docRef);

    return docSnapshot.data().createdDate.seconds;
  }

  getStore() {
    return getFirestore(this.app);
  }
}

const firebaseManager = new FireBaseManeger();

export { firebaseManager };
