<template>
  <div class="factory">
    <swiper
      id="factoryCard"
      class="factory-swiper"
      :autoplay="{ delay: 30000, disableOnInteraction: false }"
      :slidesPerView="
        $store.state.meta.windowWidth > 1240
          ? 3
          : $store.state.meta.windowWidth > 760
          ? 2
          : 1
      "
      :grid="{
        rows: 2,
        fill: 'row',
      }"
      :pagination="{ clickable: true }"
      :rewind="true"
      :modules="modules"
      :spaceBetween="isMobile == 'true' && userId ? 30 : 20"
    >
      <swiper-slide
        class="card pointer"
        v-for="(x, i) in listData"
        :key="i"
        @click="this.gotoPage(`/search/factorydetail/${x.factoryNo}`)"
      >
        <div class="card-top">
          <div class="card-top-top">
            <div class="img-area">
              <img v-if="x.imgFileUrl1" :src="x.imgFileUrl1" />
              <img
                v-else
                :src="require('@/assets/img/img-factory-empty.png')"
              />
            </div>
            <div class="info">
              <div class="row">
                <p class="work-process">{{ x.workProcess }}</p>
              </div>
              <div class="row mt-6 gap8">
                <p class="info-title">{{ x.factoryName }}</p>
                <img
                  v-if="x.calculateFlag == '2'"
                  :src="require(`@/assets/icon/ic-credit-card-orange.svg`)"
                />
              </div>
              <div class="row mt-8">
                <p class="row-info">{{ x.area }}</p>
                <p class="row-info">
                  {{
                    "" +
                    Number(x.minOrderCount).toLocaleString("ko-KR") +
                    "벌 이상"
                  }}
                </p>
              </div>
              <div class="row mt-4">
                <p class="row-info">
                  {{ x.fabricList.map((e) => e.commName).join(" ") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-bottom">
          <div class="category">
            <p
              v-for="(y, i) in x.tagList"
              :key="i"
              v-html="y.commName.replace('#', '')"
            ></p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import SvgImg from "../SvgImg.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay, Grid } from "swiper/modules";
import "swiper/css/grid";

export default {
  name: "DesignOrderMainPcCard",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    listData: {
      type: Object,
      description: "Card data",
    },
  },
  setup() {
    return {
      modules: [Pagination, Autoplay, Grid],
    };
  },
  data() {
    return {
      d: {},
      isMore: false,
      isShowMsg: false,
      index: "",
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
    };
  },
  mounted() {
    console.log(this.listData);
    this.d = this.listData;
    this.d.isLike = this.d.like;
  },
  methods: {
    openStatusPopup(index) {
      this.index = index;
      this.isShowMsg = true;
    },
    designOrderEnd() {
      this.$emit("toggleStatus", this.index);
      this.isShowMsg = false;
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    bookmark(index, designOrderNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo);
    },
    factoryPin(index, designOrderNo, factoryNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo, factoryNo);
    },
    goDetail(designOrderNo, userId, styleNameValue) {
      console.log(styleNameValue);
      if (this.userId) {
        this.$store.state.meta.detailTitle = styleNameValue;
        if (userId == this.userId) {
          this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
        } else {
          this.gotoPage(`/designorder/detail/order/${designOrderNo}`);
        }
      } else {
        this.$emit("openLogin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.factory {
  &-swiper {
    position: initial;
    margin-bottom: 28px;
    max-width: 1240px;
    @media (max-width: 1240px) {
      width: calc(100vw - 40px);
    }
    :deep(.swiper-pagination) {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      bottom: 0;
      &-bullet {
        background-color: #fff4db;
        width: 8px;
        height: 8px;
        opacity: 1;
        margin: 0;
        border-radius: 4px;
        &-active {
          background-color: $primary-50;
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  .card {
    padding: 16px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: $neutral-0;
    display: flex;
    flex-direction: column;
    background-color: $neutral-0;
    border-radius: 4px;
    &-top {
      // padding: 16px 20px 12px;
      padding-bottom: 12px;
      &-top {
        display: flex;
        gap: 16px;
        .img-area {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 100px;
          max-width: 100px;
          height: 100px;
          border: 1px solid $neutral-10;
          border-radius: 8px;
          overflow: hidden;
          img {
            // max-width: 100%;
            max-height: 100%;
          }
        }
        .info {
          width: 100%;
          &-title {
            display: flex;
            align-items: center;
            gap: 8px;
            @include ellipse();
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $neutral-80;
          }
          .work-process {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
          }
          .row {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: $neutral-80;
            &-info {
              @include ellipse();
              &:not(:first-child) {
                margin-left: rem(14px);
                padding-left: rem(14px);
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 12px;
                  background-color: $neutral-30;
                }
              }
            }
          }
        }
      }
    }
    &-bottom {
      padding: 0 4px;
      .category {
        height: 54px;
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        color: $neutral-50;
        gap: 4px 8px;
        width: 100%;
        overflow: hidden;
        p {
          color: $primary-60;
          font-size: 12px;
          font-weight: 500;
          padding: 4px 8px;
          height: 25px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-radius: 26px;
          background: var(--Primary_05, #fffbf2);
        }
      }
    }
  }
}
</style>
