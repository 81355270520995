<template>
  <div class="filter-menu">
    <div
      class="filter-menu-value pointer"
      :class="c ? 'active' : ''"
      @click="toggleShowList()"
    >
      <p v-if="selectDataTxt" class="filter-menu-value-txt">
        {{ selectDataTxt }}
      </p>
      <img
        v-if="!showSelectBoxList"
        src="@/assets/icon/ic-selectbox-black.svg"
      />
      <img v-else src="@/assets/icon/ic-selectbox-close.svg" />
    </div>
    <ul class="filter-menu-list" :class="showSelectBoxList ? '' : 'notshow'">
      <li
        v-for="(x, i) in data"
        :key="i"
        class="filter-menu-list-item pointer"
        :class="x.isActive ? 'active' : ''"
        @click="selectDataUpdate(x, i)"
      >
        {{ x.name }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "filterMenuNew",
  props: {
    data: {
      type: Object,
      description: "select box data",
    },
    selectData: {
      type: String,
      description: "selected data",
    },
    placeholderTxt: {
      type: String,
      description: "placeholder txt",
    },
    filterType: {
      type: String,
      description: "type txt",
    },
  },
  watch: {
    selectData(newVal) {
      this.selectDataTxt = newVal;
    },
  },
  mounted() {
    this.selectDataTxt = this.selectData;
  },
  data() {
    return {
      showSelectBoxList: false,
      selectDataTxt: "",
    };
  },
  methods: {
    toggleShowList() {
      console.log(this.showSelectBoxList);
      this.showSelectBoxList = !this.showSelectBoxList;
    },
    selectDataUpdate(d, i) {
      this.selectDataTxt = d.name;
      this.showSelectBoxList = false;
      let data = d;
      d["i"] = i;
      this.$emit("selectData", data, this.filterType);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-menu {
  position: relative;
  &-value {
    border-bottom: 1px solid $neutral-10;
    padding: 12px 4px 12px 8px;
    display: flex;
    align-items: center;
    height: rem(32px);
    width: rem(71px);

    &-txt {
      width: 100%;
      color: $neutral-95;
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      flex: 1;
      &.placeholder {
        color: $neutral-30;
      }
    }
  }
  &-list {
    position: absolute;
    top: 100%;
    width: calc(100% + 3px);
    z-index: 1;
    background-color: $neutral-5;
    overflow: hidden;
    border: solid $neutral-10;
    border-width: 0 rem(1px) rem(1px) rem(1px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    &-item {
      margin: rem(4px);
      padding: rem(8px);
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      background-color: $neutral-0;
      border-radius: 4px;
      &:hover {
        background-color: $neutral-variation-20;
      }
      &.active {
        color: $primary-60;
      }
    }
    &.notshow {
      max-height: 0;
      display: none;
    }
  }
}
</style>
