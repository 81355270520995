<template>
  <div class="basket">
    <div class="basket-top">
      <div class="basket-top-add">
        <div class="item-name">
          <p>{{ detailData.itemName }}</p>
        </div>
        <div class="item-length" v-if="detailData.itemUnitValue">
          <p class="name">판매 단위</p>
          <p class="value" v-if="detailData.unitPrice?.length > 0">
            최소
            {{ detailData.unitPrice[detailData.unitPrice.length - 1].unit }}개
            이상
          </p>
          <p class="value" v-else>
            {{ detailData.itemUnitValue }}
          </p>
        </div>
        <div class="price" v-if="!(detailData.unitPrice?.length > 0)">
          <p class="name">판매가</p>
          <div class="value">
            <p>
              {{ comma(detailData.itemNormalCost) }}원
              <span class="unit" v-if="detailData.itemNormalLengthValue > 0">
                ({{ detailData.itemNormalLengthName }},
                {{ comma(detailData.itemNormalLengthValue) }}원)
              </span>
            </p>
          </div>
        </div>
        <div class="sale" v-if="!(detailData.unitPrice?.length > 0)">
          <p class="name">할인가</p>
          <div class="value">
            <p>
              {{ comma(detailData.itemExposeCost) }}원
              <span class="unit" v-if="detailData.itemLengthValue > 0">
                ({{ detailData.itemLengthName }},
                {{ comma(detailData.itemLengthValue) }}원)
              </span>
            </p>
          </div>
        </div>
        <div class="delivery">
          <p class="name">배송비</p>
          <p class="value" v-if="detailData.deliveryPrice == 0">무료배송</p>
          <p class="value" v-else>{{ comma(detailData.deliveryPrice) }}</p>
        </div>
      </div>
      <div class="line-d10"></div>
      <div class="basket-top-table" v-if="detailData.unitPrice?.length > 0">
        <div class="basket-top-table-content">
          <p class="unit-title">수량</p>
          <p class="unit-title border">단가</p>
        </div>
        <div
          class="basket-top-table-content"
          v-for="(unitPrice, i) in detailData.unitPrice"
          :key="i"
          :style="detailData.unitPrice.length == 1 ? 'width:100%' : ''"
        >
          <p class="unit-title">{{ comma(unitPrice.unit) }} ~</p>
          <p class="price border">{{ comma(unitPrice.price) }}</p>
        </div>
      </div>
      <div class="basket-top-info">
        <div v-for="(option, i) in options" :key="i">
          <div class="column" :class="options.length - 1 == i ? 'last' : ''">
            <p class="column-name">
              {{ option.itemOptionName }}
            </p>
            <div class="option" v-if="option.optionType == '1'">
              <SelectBoxMall
                :data="option.optionList"
                :filterType="'optionType'"
                :index="i"
                @selectData="selectData"
                :selectData="
                  option.optionList.filter((e) => e.isActive)[0]?.name
                    ? option.optionList.filter((e) => e.isActive)[0]?.name
                    : `${addEulReul(selectOptionName)} 선택해주세요.`
                "
                :placeholderTxt="
                  !option.optionList.filter((e) => e.isActive)[0]
                "
              />
            </div>
            <div class="option bg" v-else-if="option.optionType == '2'">
              <p
                class="item-option"
                v-for="(optionList, j) in option.optionList"
                :key="j"
                @click="getMallOption(optionList.cd, option.optionDepth)"
                :class="optionList.isActive ? 'active' : ''"
              >
                {{ optionList.name }}
              </p>
              <p v-if="!(option.optionList.length > 0)" class="option-empty">
                {{ addEulReul(selectOptionName) }} 선택해주세요.
              </p>
            </div>
            <div class="option bg" v-else-if="option.optionType == '3'">
              <p
                class="item-option"
                v-for="(optionList, j) in option.optionList"
                :key="j"
                @click="getMallOption(optionList.cd, option.optionDepth)"
                :class="optionList.isActive ? 'active' : ''"
              >
                <span :style="`background-color:${optionList.color}`"></span>
                {{ optionList.name }}
                <span
                  class="material-symbols-outlined check"
                  v-if="optionList.isActive"
                >
                  check
                </span>
              </p>
              <p v-if="!(option.optionList.length > 0)" class="option-empty">
                {{ addEulReul(selectOptionName) }} 선택해주세요.
              </p>
            </div>
          </div>
        </div>

        <div class="add-item" v-if="addItem.length > 0">
          <div class="add-item-wrap" v-for="(x, i) in addItem" :key="i">
            <div class="add-item-wrap-title">
              <p class="item-name">
                {{ detailData.itemName }}
              </p>
              <p class="option-name">
                <span class="material-symbols-outlined"> check </span
                >{{ x.itemOptionName }}
              </p>
            </div>
            <div class="add-item-wrap-content">
              <div class="updown br-4 width-120px">
                <div class="updown-btn gray" @click="downItem(i)">
                  <img src="@/assets/icon/ic-minus-white.svg" />
                </div>
                <div class="updown-num">
                  <input
                    type="number"
                    v-model="x.count"
                    @keyup="sum()"
                    @focusout="numFocusout(i, x.count)"
                  />
                </div>
                <div class="updown-btn gray" @click="upItem(i)">
                  <img src="@/assets/icon/ic-plus-white.svg" />
                </div>
              </div>
              <div class="price">
                <p>
                  {{
                    comma(
                      unitSum(x.count, detailData.itemUnitCost, x.addUnitCost)
                    )
                  }}원
                </p>
                <span
                  class="material-symbols-outlined close"
                  @click="removeItem(i)"
                >
                  close
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-column gap12">
          <!-- <div class="row">
            <p class="row-name">상품금액</p>
            <p class="row-content">{{ comma(sumPrice) }}원</p>
          </div>
          <div class="row">
            <p class="row-name">배송비</p>
            <p class="row-content" v-if="detailData.deliveryPrice > 0">
              {{ comma(detailData.deliveryPrice) }}원
            </p>
            <p class="row-content" v-else>0원 (무료배송)</p>
          </div> -->

          <div class="column" v-if="paymentPrice > 0">
            <p class="column-name">총 주문금액 : {{ comma(paymentPrice) }}원</p>
            <!-- <div class="row mt-8">
            <p class="row-name">총 주문금액 : {{ comma(paymentPrice) }}원</p>
            <p class="row-content">{{ comma(paymentPrice) }}원</p>
          </div> -->
          </div>
        </div>
      </div>
      <div class="basket-top-btns">
        <p class="basket-top-btns-btn" @click="addBasket()">장바구니 담기</p>
        <p class="basket-top-btns-btn" @click="addBuy()">바로 구매하기</p>
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>
import { loadTossPayments } from "@tosspayments/payment-sdk";
export default {
  name: "CompMaterialAdd",
  props: {},
  data() {
    return {
      d: {},
      i: [],
      mallNo: "",
      regImgUrl: "",
      regImgName: "",
      detailData: {},
      addItem: [],
      options: [],
      sumPrice: 0,
      paymentPrice: 0,
      sumCount: 0,
      prePrice: 0,
      minCount: 1,
      color: [],
      size: [],
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
      get getBasket() {
        return sessionStorage.getItem("basketItem");
      },
      mallList: [],
      basketCount: 0,
      optionDatas: [],
      selectItem: [],
      selectIndex: 0,
      selectOptionName: "",
      isSelect: false,
    };
  },
  which: {},
  mounted() {
    if (JSON.parse(this.getBasket)) {
      this.basketCount = JSON.parse(this.getBasket)?.length;
    }
    this.mallNo = this.$route.params.mallNo;

    this.$API.mall.getMaterialDetail(this.mallNo).then((res) => {
      console.log(res);
      this.detailData = res.data.body;

      this.initOption();
    });

    if (this.compType == "1") {
      this.$API.mypage.getDesignerInfo(this.userId).then((res) => {
        console.log(res.data.body);
        this.userInfo = res.data.body;
      });
    }
    if (this.compType == "2") {
      this.$API.mypage.getFactoryInfo(this.userId).then((res) => {
        this.userInfo = res.data.body;
      });
    }
  },
  methods: {
    initOption() {
      this.options = [];

      this.$API.mall.getMaterialOptionInit(this.mallNo).then((res) => {
        console.log(res);
        const initData = res.data.body;
        // let rowData = {};

        initData.forEach((e) => {
          const rowData = {
            mallNo: e.mallNo,
            optionDepth: e.optionDepth,
            optionType: e.optionType,
            itemOptionName: e.itemOptionName,
            optionList: [],
          };
          this.options.push(rowData);
        });
        this.selectOptionName = this.options[0].itemOptionName;

        this.optionDatas = {
          mallNo: this.mallNo,
          optionDepth: 1,
          itemParentCode: 0,
        };
        console.log(this.optionDatas);
        this.$API.mall.getMaterialOption(this.optionDatas).then((res) => {
          console.log(res);
          const optionItem = res.data.body;
          // this.options = optionItem;
          // if (optionItem.optionType == "1") {
          let selectList = [];
          optionItem.optionList.forEach((e, i) => {
            const optionList = {
              cd: e.itemOptionDtlNo,
              name: e.itemOptionValue,
              color: e.itemOptionColor,
              addUnitCost: e.addUnitCost,
              isActive: false,
            };
            selectList.push(optionList);
          });
          // let selectItem = {
          //   mallNo: this.mallNo,
          //   optionDepth: optionItem.optionDepth,
          //   optionType: optionItem.optionType,
          //   itemOptionName: optionItem.itemOptionName,
          //   optionList: selectList,
          // };
          // this.options.push(selectItem);
          this.options
            .filter((e) => e.optionDepth == 1)
            .map((e) => (e.optionList = selectList));
          console.log(this.options);
        });
      });

      //최소 수량
      if (this.detailData.unitPrice?.length > 0) {
        this.detailData.unitPrice.forEach((e) => {
          this.minCount = e.unit;
          console.log(e);
        });
      }
    },
    selectData(data, type, i) {
      console.log(data);
      console.log(type);
      console.log(i);
      this.selectOptionName = this.options[i].itemOptionName;
      this.options[i].optionList.forEach((e) => {
        e.isActive = false;
        console.log(e.cd);
        console.log(data.cd);
        if (e.cd == data.cd) {
          e.isActive = true;
          const itemParentCode = data.cd;
          const optionDepth = this.options[i].optionDepth;
          this.getMallOption(itemParentCode, optionDepth);
        }
      });
    },
    getMallOption(itemParentCode, optionDepth) {
      //초기화
      this.options.forEach((e, i) => {
        // if (i >= optionDepth) {
        //   this.options.splice(i, 1);
        // }
        if (i + 1 == optionDepth) {
          console.log(i);
          console.log(optionDepth);
          e.optionList.forEach((e2) => {
            e2.isActive = false;
            if (e2.cd == itemParentCode) {
              e2.isActive = true;
            }
            console.log(e2);
          });
        }
        console.log(e);
        if (
          i == optionDepth &&
          !(e.optionList.filter((e2) => e2.isActive).length > 0)
        ) {
          this.selectOptionName = e.itemOptionName;
        }
      });
      this.optionDatas.mallNo = this.mallNo;
      this.optionDatas.itemParentCode = itemParentCode;
      this.optionDatas.optionDepth = Number(optionDepth) + 1;

      let isDuple = false;
      this.addItem.forEach((e) => {
        if (itemParentCode == e.itemOptionDtlNo) {
          isDuple = true;
        }
      });
      if (!isDuple) {
        this.$API.mall.getMaterialOption(this.optionDatas).then((res) => {
          console.log(res);
          const optionItem = res.data.body;
          if (optionItem) {
            if (
              optionItem.optionType == "1" ||
              optionItem.optionType == "2" ||
              optionItem.optionType == "3"
            ) {
              let selectList = [];
              optionItem.optionList.forEach((e, i) => {
                const optionList = {
                  cd: e.itemOptionDtlNo,
                  name: e.itemOptionValue,
                  color: e.itemOptionColor,
                  addUnitCost: e.addUnitCost,
                  isActive: false,
                };
                selectList.push(optionList);
              });
              console.log(this.options);
              this.options
                .filter((e) => e.optionDepth == this.optionDatas.optionDepth)
                .map((e) => (e.optionList = selectList));
              console.log(this.options);
            }
          } else {
            let addUnitCost = 0;
            let itemName = this.detailData.itemName;
            let itemOptionName = "";
            console.log(this.options);
            this.options.forEach((e, i) => {
              const thisCost = e.optionList.filter((e) => e.isActive)[0]
                .addUnitCost;
              const optionName = e.optionList.filter((e) => e.isActive)[0].name;
              console.log(optionName);
              addUnitCost += thisCost ? Number(thisCost) : 0;
              itemName += " " + optionName;
              if (i > 0) {
                itemOptionName += "  │  ";
              }
              itemOptionName += optionName;
              console.log(addUnitCost);
              console.log(itemName);
            });

            this.addItem.push({
              optionDepth: optionDepth,
              itemOptionDtlNo: itemParentCode,
              itemName: itemName,
              itemOptionName: itemOptionName,
              minCount: this.minCount,
              count: this.minCount,
              itemUnitCost: this.detailData.itemUnitCost,
              addUnitCost: addUnitCost,
              detailData: this.detailData,
            });
            this.sum();
            this.initOption();
          }
        });
      } else {
        //증복체크
        this.initOption();
      }
    },
    addBasket() {
      if (this.addItem.length > 0) {
        let basketItem = [];
        if (sessionStorage.getItem("basketItem")) {
          basketItem = JSON.parse(sessionStorage.getItem("basketItem"));
        }
        basketItem.push({
          deliveryPrice: this.detailData.deliveryPrice,
          itemName: this.detailData.itemName,
          item: this.addItem,
          mallNo: this.detailData.mallNo,
          tabCatg: this.detailData.tabCatg,
        });
        sessionStorage.setItem("basketItem", JSON.stringify(basketItem));
        this.$emit("updateBasket", basketItem);
        this.$emit("addBasketCount");
        this.$emit("moveBasket");
      } else {
        this.$emit("moveBuy");
      }
    },
    addBuy() {
      if (!(this.addItem.length > 0)) {
        this.$emit("moveBuy");
      } else {
        this.addBasket();
        let basketItem = {
          deliveryPrice: this.detailData.deliveryPrice,
          itemName: this.detailData.itemName,
          item: this.addItem,
          mallNo: this.detailData.mallNo,
        };
        console.log(basketItem);
        this.$store.state.meta.mall.materialBuyItem.push(basketItem);
        localStorage.removeItem("buyItem");
        localStorage.setItem(
          "buyItem",
          JSON.stringify(this.$store.state.meta.mall.materialBuyItem)
        );
        console.log(localStorage.getItem("buyItem"));
        if (this.$store.state.meta.mall.materialBuyItem.length > 0) {
          this.gotoPage("/mall/materialbuy");
        } else {
          this.showToast("구매하실 상품을 선택해주세요.");
        }
      }
    },
    getAddress() {
      new window.daum.Postcode({
        oncomplete: (res) => {
          this.userInfo.compAddress = res.roadAddress;
        },
      }).open();
    },
    upItem(index) {
      // console.log(this.basketItem);
      // this.addItem = this.basketItem;
      this.addItem[index].count++;
      this.sum();
      this.$emit("updateBasket", this.addItem);
    },
    downItem(index) {
      // console.log(this.basketItem);
      // this.addItem = this.basketItem;
      console.log(this.addItem[index].count);
      console.log(this.minCount);
      if (this.addItem[index].count <= this.minCount) {
        // this.removeItem(index);
      } else {
        this.addItem[index].count--;
      }
      this.sum();
      this.$emit("updateBasket", this.addItem);
    },
    removeItem(index) {
      // this.addItem = this.basketItem;
      this.addItem.splice(index, 1);
      this.sum();
      this.$emit("updateBasket", this.addItem);
    },
    numFocusout(index, count) {
      console.log(count);
      if (count < this.minCount) {
        // this.removeItem(index);
        this.addItem[index].count = this.minCount;
      }
    },
    sum() {
      let sumPrice = 0;
      this.addItem.forEach((e) => {
        if (this.detailData.unitPrice?.length > 0) {
          let itemUnitCost = 0;
          for (const unitPrice of this.detailData.unitPrice) {
            if (unitPrice.unit <= e.count) {
              itemUnitCost = unitPrice.price;
              e.itemUnitCost = itemUnitCost;
              break;
            } else {
              itemUnitCost = this.detailData.itemUnitCost;
            }
          }
          sumPrice += e.count * (Number(itemUnitCost) + Number(e.addUnitCost));
        } else {
          sumPrice +=
            e.count *
            (Number(this.detailData.itemUnitCost) + Number(e.addUnitCost));
        }
      });
      console.log(this.addItem);
      this.sumPrice = sumPrice;
      this.totalPrice = sumPrice + this.deliveryPrice;
      this.paymentPrice = sumPrice + Number(this.detailData.deliveryPrice);
    },
    unitSum(count, itemUnitCost, addUnitCost) {
      let sumPrice = 0;
      console.log(this.detailData.unitPrice);
      if (this.detailData.unitPrice?.length > 0) {
        for (const unitPrice of this.detailData.unitPrice) {
          console.log(unitPrice.unit);
          if (unitPrice.unit <= count) {
            itemUnitCost = unitPrice.price;
            console.log(itemUnitCost);
            break;
          }
        }
        console.log(itemUnitCost);
        sumPrice += count * (Number(itemUnitCost) + Number(addUnitCost));
      } else {
        sumPrice += count * (Number(itemUnitCost) + Number(addUnitCost));
      }
      return sumPrice;
    },
    payment() {
      if (this.totalPrice > 0) {
        let itemDetail = "";
        let itemDetailInfo = [];
        this.addItem.forEach((e, i) => {
          if (i > 0) itemDetail += " / ";
          itemDetail += e.itemOptionName + ":" + e.count + "개";

          let itemInfo = {
            itemOptionName: e.itemOptionName,
            itemColorCode: e.cd,
            quantity: e.count,
            addUnitCost: e.addUnitCost,
            paymentAmount:
              Number(this.detailData.itemUnitCost) + Number(e.addUnitCost),
          };
          itemDetailInfo.push(itemInfo);
        });
        console.log(this.detailData);
        this.itemDetailInfo = itemDetailInfo;
        this.$API.payment
          .createTossMall(
            this.userId,
            this.mallNo,
            this.detailData.itemName,
            this.totalPrice,
            this.paymentType,
            itemDetail,
            this.userInfo.userName,
            this.userInfo.telNum,
            this.userInfo.compAddress,
            this.userInfo.compAddressDetail,
            this.userInfo.deliveryMemo,
            this.itemDetailInfo
          )
          .then((res) => {
            console.log(res);
            if (res.data.resultCode == "10000") {
              let d = res.data.body;
              console.log(d);
              loadTossPayments(d.clientKey).then((tossPayments) => {
                tossPayments.requestPayment(this.paymentType, {
                  // 결제 수단 파라미터
                  amount: d.paymentAmount,
                  orderId: d.orderId,
                  orderName: d.itemName,
                  customerName: sessionStorage.getItem("userName"),
                  successUrl: d.successUrl,
                  failUrl: d.failUrl,
                });
              });
            } else {
              this.setMsgPopup(
                true,
                "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
              );
            }
          });
      } else {
        this.showToast("구매하실 상품을 선택해주세요.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .material {
//   border-right: 1px solid $neutral-10;
//   border-left: 1px solid $neutral-10;
//   padding: 40px 24px 24px;
//   @media (max-width: 520px) {
//     padding: 40px 20px;
// }
.basket {
  width: 100%;
  &-top {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 520px) {
      margin: 0 20px;
    }
    &-add {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      position: relative;
      .name {
        font-size: 15px;
        font-weight: 500;
        line-height: 140%; /* 21px */
      }
      .value {
        position: absolute;
        left: 150px;
        font-size: 15px;
        font-weight: 400;
        line-height: 140%; /* 21px */
      }
      .item-name {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid $neutral-10;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        display: flex;
        gap: 8px;
      }
      .item-length {
        display: flex;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
      }
      .sale {
        margin-top: 12px;
        display: flex;
        gap: 8px;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%; /* 21px */
        .value {
          color: $primary-60;
        }
      }
      .price {
        margin-top: 12px;
        display: flex;
        gap: 4px;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%; /* 21px */
        .value {
          text-decoration: line-through;
          color: $neutral-40;
        }
      }
      .delivery {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%; /* 21px */
      }
    }

    &-table {
      display: flex;
      width: 100%;
      padding-bottom: 28px;
      border-bottom: 1px solid $neutral-10;
      &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: solid $neutral-20;
        border-width: 1px 0px 1px 1px;

        &:last-child {
          border-right-width: 1px;
        }
        .unit-title {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          background: $neutral-3;
        }
        .price {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;
          background: $neutral-0;
        }
        .border {
          border-top: 1px solid $neutral-20;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .row {
        display: flex;
        align-items: center;
        &-name {
          flex: 1;
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          position: absolute;
        }
        &-content {
          flex: 3;
          color: $neutral-60;
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
          margin-left: 110px;
          @media (max-width: 520px) {
            margin-left: 110px;
          }

          &.yellow {
            color: $primary-60;
          }
        }
        .option {
          margin-left: 110px;
        }
      }

      .column {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &.last {
          border-bottom: 1px solid $neutral-10;
        }
        &-name {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
        }
        &-content {
          color: $neutral-60;
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
        }
      }
      .option {
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        height: 61px;
        border-radius: 4px;
        &.bg {
          background: $primary-5;
          margin-bottom: 20px;
        }
        .item-option {
          position: relative;
          cursor: pointer;
          padding: 8px;
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          border: 1px solid $neutral-10;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          background: $neutral-0;
          span {
            border: 1px solid $neutral-5;
            width: 16px;
            height: 16px;
          }
          &.active {
            border: 1px solid $primary-50;
          }
          .check {
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            bottom: 0;
            position: absolute;
            border: 1px solid $primary-50;
            width: 10px;
            height: 10px;
            border-radius: 2px;
            font-size: 10px;
            color: $neutral-0;
            background-color: $primary-50;
          }
        }
        .option-empty {
          display: flex;
          align-items: center;
          padding: 0 12px;
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
          color: $neutral-30;
        }
      }

      .add-item {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $neutral-10;
        border-top: 1px solid $neutral-10;
        padding: 20px 0;
        gap: 20px;
        &-wrap {
          display: flex;
          position: relative;
          align-items: flex-start;
          justify-content: space-between;
          gap: 8px;
          &-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            gap: 8px;
            margin-right: 20px;
            .item {
              color: $primary-60;
              font-size: 14px;
              font-weight: 500;
              line-height: 140%;
            }
            .point {
              display: flex;
              align-items: center;
              width: 4px;
              height: 4px;
              border: 2px solid #737373;
              border-radius: 2px;
              margin: 8px 0;
            }
            .color {
              color: $neutral-60;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%;
            }
            img {
              width: 16px;
            }
            .item-name {
              font-size: 14px;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            }
            .option-name {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
              span {
                color: $neutral-60;
                font-size: 16px;
              }
            }
          }
          &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 12px;
            width: 100%;
            .updown {
              // position: absolute;
              // right: 160px;
              // top: 50%;
              // transform: translateY(-50%);
            }
            .price {
              // position: absolute;
              // top: 50%;
              // transform: translateY(-50%);
              // right: 0;
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 15px;
              font-weight: 500;
              line-height: 140%;
              &-sale {
                position: absolute;
                bottom: 20px;
                text-decoration: line-through;
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;
                color: $neutral-40;
              }
            }
            .close {
              cursor: pointer;
              font-size: 16px;
              color: $neutral-30;
            }
          }
        }
      }
    }
    &-btns {
      display: flex;
      justify-content: center;
      &-btn {
        cursor: pointer;
        flex: 1;
        height: 40px;
        color: $neutral-0;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $neutral-60;
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px 4px 4px 0px;
          background-color: $primary-50;
        }
        &.one {
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
