<template>
  <div class="contract-wrap">
    <div class="top">
      <p class="square"></p>
      <p class="title">발주 관리</p>
    </div>
    <div class="contract-step-tab">
      <p
        class="issue-btn"
        v-for="(x, i) in filters.status"
        :key="i"
        :class="step == i ? 'active' : ''"
        @click="clickStep(i)"
      >
        {{ x.name }}
      </p>
    </div>
    <!-- <div class="contract-step-tab flex" :class="{ fixed: isFixed }">
      <div class="pointer" @click="clickStep(0)">
        <p>계약자</p>
        <p
          class="contract-step-tab-border"
          :class="step == 0 ? 'active' : 'none'"
        ></p>
      </div>
      <img src="@/assets/icon/ic-chevron-right-gray.svg" />
      <div class="pointer" @click="clickStep(1)">
        <p>작업 정보</p>
        <p
          class="contract-step-tab-border"
          :class="step == 1 ? 'active' : 'none'"
        ></p>
      </div>
      <img src="@/assets/icon/ic-chevron-right-gray.svg" />
      <div class="pointer" @click="clickStep(2)">
        <p>정산</p>
        <p
          class="contract-step-tab-border"
          :class="step == 2 ? 'active' : 'none'"
        ></p>
      </div>
    </div> -->

    <div v-if="step == 0" class="contract-step-page">
      <div class="contract-step-page-section" v-if="!isModify">
        <div class="middle">
          <div class="flex space-between">
            <p class="middle-title">발주자</p>
            <!-- <a
              :href="contractData.designerCompFileUrl"
              target="_blacnk"
              class="comp"
              >사업자 등록증</a
            > -->
          </div>
          <div class="field-list">
            <div class="field">
              <p class="name">사업자 명</p>
              <p class="info">{{ contractData.designerCompName }}</p>
            </div>
            <div class="field">
              <p class="name">사업자등록번호</p>
              <p class="info">{{ contractData.designerCompNum }}</p>
            </div>
            <div class="field">
              <p class="name">대표자 명</p>
              <p class="info">{{ contractData.designerRepresentName }}</p>
            </div>
            <div class="field">
              <p class="name">전화번호</p>
              <p class="info">{{ contractData.designerTelNum }}</p>
            </div>
            <div class="field">
              <p class="name">주소</p>
              <p class="info">{{ contractData.designerAddress }}</p>
            </div>
            <div class="field">
              <p class="name">상세 주소</p>
              <p class="info">{{ contractData.designerAddressDetail }}</p>
            </div>
          </div>
        </div>

        <div class="middle">
          <div class="flex space-between">
            <p class="middle-title">생산자</p>
            <!-- <a
              :href="contractData.factoryCompFileUrl"
              target="_blacnk"
              class="comp"
              >사업자 등록증</a
            > -->
          </div>
          <div class="field-list">
            <div class="field">
              <p class="name">사업자 명</p>
              <p class="info">{{ contractData.factoryCompName }}</p>
            </div>
            <div class="field">
              <p class="name">사업자등록번호</p>
              <p class="info">{{ contractData.factoryCompNum }}</p>
            </div>
            <div class="field">
              <p class="name">대표자 명</p>
              <p class="info">{{ contractData.factoryRepresentName }}</p>
            </div>
            <div class="field">
              <p class="name">전화번호</p>
              <p class="info">{{ contractData.factoryTelNum }}</p>
            </div>
            <div class="field">
              <p class="name">주소</p>
              <p class="info">{{ contractData.factoryAddress }}</p>
            </div>
            <div class="field">
              <p class="name">상세 주소</p>
              <p class="info">{{ contractData.factoryAddressDetail }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="contract-step-page-section" v-else>
        <div class="middle">
          <div class="flex space-between">
            <p class="middle-title">발주자</p>
            <input
              class="hidden"
              type="file"
              id="file"
              accept=".gif, .jpg, .jpeg, .png, .svg, .pdf"
              @change="fileUpload"
            />
            <div>
              <label for="file" class="comp">사업자 등록증</label>
            </div>
          </div>
          <div class="field-list">
            <div class="field">
              <p class="name">사업자 명</p>
              <div class="info">
                <input
                  type="text"
                  class="txt"
                  :class="!compData.designerCompName ? 'error' : ''"
                  v-model="compData.designerCompName"
                />
                <p v-if="!compData.designerCompName" class="error-msg">
                  사업자 명을 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">사업자등록번호</p>
              <div class="info">
                <input
                  type="text"
                  class="txt"
                  :class="!compData.designerCompNum ? 'error' : ''"
                  v-model="compData.designerCompNum"
                />
                <p v-if="!compData.designerCompNum" class="error-msg">
                  사업자 등록번호를 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">대표자 명</p>
              <div class="info">
                <input
                  type="text"
                  class="txt"
                  :class="!compData.designerRepresentName ? 'error' : ''"
                  v-model="compData.designerRepresentName"
                />
                <p v-if="!compData.designerRepresentName" class="error-msg">
                  대표자 명을 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">전화번호</p>
              <div class="info">
                <input
                  type="text"
                  class="txt"
                  :class="!compData.designerTelNum ? 'error' : ''"
                  v-model="compData.designerTelNum"
                />
                <p v-if="!compData.designerTelNum" class="error-msg">
                  전화번호를 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">주소</p>
              <div class="info">
                <input
                  type="text"
                  class="txt"
                  :class="!compData.designerAddress ? 'error' : ''"
                  v-model="compData.designerAddress"
                />
                <p v-if="!compData.designerAddress" class="error-msg">
                  주소를 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">상세 주소</p>
              <div class="info">
                <input
                  type="text"
                  class="txt"
                  :class="!compData.designerAddressDetail ? 'error' : ''"
                  v-model="compData.designerAddressDetail"
                />
                <p v-if="!compData.designerAddressDetail" class="error-msg">
                  상세 주소를 입력해주세요.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step == 1" class="contract-step-page">
      <div class="contract-step-page-section">
        <div
          class="middle"
          v-for="(x, i) in contractData.contractStyleList"
          :key="i"
        >
          <div class="flex space-between">
            <p class="middle-title flex gap12">
              스타일 {{ x.styleNo }}.<img
                v-if="i > 0"
                class="pointer"
                @click="removeStyle(i)"
                src="@/assets/icon/ic-x-square-black.svg"
              />
            </p>
            <div v-if="x.styleNo > 1">
              <input
                class="hidden"
                type="file"
                :id="`file${i}`"
                accept=".gif, .jpg, .jpeg, .png, .ai, .psd, .xlsx, .svg, .pdf"
                @change="fileUpload(x.fileStatus, x.maxLength, $event, i)"
                multiple
              />
              <label :for="`file${i}`" class="comp pointer">파일 첨부</label>
            </div>
          </div>
          <div class="field-list">
            <div class="field">
              <p class="name">스타일 명</p>
              <p class="info" v-if="x.styleNo == 1">{{ x.styleName }}</p>
              <div class="info" v-else>
                <input
                  v-model="x.styleName"
                  class="txt"
                  :class="!x.styleName && isStyleError ? 'error' : ''"
                  type="text"
                  placeholder="스타일 명을 입력해주세요."
                />
                <p v-if="!x.styleName && isStyleError" class="error-msg">
                  스타일 명을 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">컬러 수</p>
              <p class="info" v-if="x.styleNo == 1">{{ x.colorCount }}</p>
              <div class="info" v-else>
                <input
                  v-model="x.colorCount"
                  class="txt"
                  :class="!x.colorCount && isStyleError ? 'error' : ''"
                  type="number"
                  placeholder="컬러 수를 입력해주세요."
                />
                <p v-if="!x.colorCount && isStyleError" class="error-msg">
                  컬러 수를 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">사이즈 수</p>
              <p class="info" v-if="x.styleNo == 1">{{ x.sizeCount }}</p>
              <div class="info" v-else>
                <input
                  v-model="x.sizeCount"
                  class="txt"
                  :class="!x.sizeCount && isStyleError ? 'error' : ''"
                  type="number"
                  placeholder="사이즈 수를 입력해주세요."
                />
                <p v-if="!x.sizeCount && isStyleError" class="error-msg">
                  사이즈 수를 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">총 수량</p>
              <p class="info" v-if="x.styleNo == 1">{{ x.quantity }}</p>
              <div class="info" v-else>
                <input
                  v-model="x.quantity"
                  class="txt"
                  :class="!x.quantity && isStyleError ? 'error' : ''"
                  type="number"
                  placeholder="총 수량을 입력해주세요."
                  @change="getSupplyCost()"
                />
                <p v-if="!x.quantity && isStyleError" class="error-msg">
                  총 수량을 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name red">단가</p>
              <div class="info">
                <input
                  v-model="x.unitCost"
                  class="txt"
                  :class="!x.unitCost && isStyleError ? 'error' : ''"
                  type="number"
                  placeholder="단가를 입력해주세요."
                  @change="getSupplyCost()"
                />
                <p v-if="!x.unitCost && isStyleError" class="error-msg">
                  단가를 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field column">
              <p class="name start">첨부 파일</p>
              <div v-if="x.styleNo == 1" class="info img-name">
                <!-- <p v-for="(y, i) in x.imgList" :key="i">
                  {{ y.sourceFileName }}
                </p> -->
                <a
                  v-for="(y, i) in x.imgList"
                  :key="i"
                  :href="y.fileUrl"
                  download="download"
                  target="_blank"
                >
                  <img
                    v-if="y.sourceFileName.indexOf('.ai') != -1"
                    src="@/assets/img/thumbnail-ai.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.psd') != -1"
                    src="@/assets/img/thumbnail-psd.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.xlsx') != -1"
                    src="@/assets/img/thumbnail-xlsx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.pptx') != -1"
                    src="@/assets/img/thumbnail-pptx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.docx') != -1"
                    src="@/assets/img/thumbnail-docx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.pdf') != -1"
                    src="@/assets/img/thumbnail-pdf.png"
                  />
                  <img v-else :src="y.fileUrl" />
                </a>
              </div>
              <div v-else-if="x.imgList.length > 0" class="info img-name">
                <!-- <p v-for="(y, i) in x.imgList" :key="i"> -->
                <!-- {{ y.sourceFileName }} -->
                <a
                  v-for="(y, i) in x.imgList"
                  :key="i"
                  :href="y.fileUrl"
                  download="download"
                  target="_blank"
                >
                  <img
                    v-if="y.sourceFileName.indexOf('.ai') != -1"
                    src="@/assets/img/thumbnail-ai.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.psd') != -1"
                    src="@/assets/img/thumbnail-psd.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.xlsx') != -1"
                    src="@/assets/img/thumbnail-xlsx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.pptx') != -1"
                    src="@/assets/img/thumbnail-pptx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.docx') != -1"
                    src="@/assets/img/thumbnail-docx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.pdf') != -1"
                    src="@/assets/img/thumbnail-pdf.png"
                  />
                  <img v-else :src="y.fileUrl" />
                </a>
                <!-- </p> -->
              </div>
              <div v-else class="info img-name red">
                <p>작업 지시서 또는 참고 사진, 이미지 등을 첨부해주세요.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isShowStyleAdd" class="style-add" @click="styleAdd()">
        <p>스타일 추가</p>
      </div>
    </div>

    <div v-if="step == 2" class="contract-step-page">
      <div class="contract-step-page-section">
        <div class="middle">
          <p class="middle-title">납품</p>
          <div class="field-list">
            <div class="field">
              <p class="name">납품 방식</p>
              <div class="onoff flex">
                <p
                  class="onoff-txt"
                  :class="contractData.deliveryFlag != '1' ? 'active' : ''"
                >
                  분할
                </p>
                <div
                  class="onoff-btn"
                  :class="contractData.deliveryFlag == '1' ? 'active' : ''"
                  @click="toggleData('deliveryFlag')"
                ></div>
                <p
                  class="onoff-txt"
                  :class="contractData.deliveryFlag == '1' ? 'active' : ''"
                >
                  일괄
                </p>
              </div>
            </div>
            <div v-if="contractData.deliveryFlag == '2'" class="deposit">
              <div class="field">
                <p class="name">1차 납품일</p>
                <div class="info">
                  <input
                    class="txt"
                    type="date"
                    max="9999-12-31"
                    v-model="contractData.deliveryDate1"
                  />
                </div>
              </div>
              <div v-if="deliveryCount > 1" class="field">
                <p class="name">2차 납품일</p>
                <div class="info">
                  <input
                    class="txt"
                    type="date"
                    max="9999-12-31"
                    v-model="contractData.deliveryDate2"
                  />
                </div>
              </div>
              <div v-if="deliveryCount > 2" class="field">
                <p class="name">3차 납품일</p>
                <div class="info">
                  <input
                    class="txt"
                    type="date"
                    max="9999-12-31"
                    v-model="contractData.deliveryDate3"
                  />
                </div>
              </div>

              <div v-if="deliveryCount < 3" class="field">
                <p class="name"></p>
                <div class="info flex-row-center">
                  <p class="img" @click="deliveryCount++">
                    <img src="@/assets/icon/ic-plus-circle-gray.svg" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field">
              <p class="name">최종 납품일 <span>*</span></p>
              <div class="info">
                <input
                  class="txt"
                  :class="
                    !contractData.deliveryDateFin && isOrderError ? 'error' : ''
                  "
                  type="date"
                  max="9999-12-31"
                  v-model="contractData.deliveryDateFin"
                />
                <p
                  v-if="!contractData.deliveryDateFin && isOrderError"
                  class="error-msg"
                >
                  최종 납품일을 입력해주세요.
                </p>
              </div>
            </div>
            <div class="field">
              <p class="name">검수 기한</p>
              <p class="info flex-row-center">
                제품 수령 후,
                <FilterMenuNew
                  :data="testDate"
                  :selectData="'14'"
                  @selectData="selectData"
                  filterType="testDate"
                />일 이내
              </p>
            </div>
          </div>
        </div>

        <div class="middle">
          <p class="middle-title">금액</p>
          <div class="price-list">
            <div
              class="price"
              v-for="(x, i) in contractData.contractStyleList"
              :key="i"
            >
              <p class="name">{{ i + 1 }}. {{ x.styleName }}</p>
              <p class="info end">{{ comma(x.unitCost * x.quantity) }} 원</p>
            </div>
            <div class="price mt-8">
              <p class="name">공급가액</p>
              <p class="info end">{{ comma(contractData.supplyCost) }} 원</p>
            </div>
            <div class="price mt-4">
              <p class="name">부가세 (VAT)</p>
              <p class="info end">
                {{ comma((contractData.supplyCost * 0.1).toFixed(0)) }} 원
              </p>
            </div>
            <div class="price mt-4">
              <p class="name">총 금액</p>
              <p class="info end">{{ comma(contractData.totalCost) }} 원</p>
            </div>
          </div>
        </div>

        <div class="middle">
          <p class="middle-title">결제</p>
          <div class="field-list">
            <div class="field">
              <p class="name">계약금 유무</p>
              <div class="onoff flex">
                <p
                  class="onoff-txt"
                  :class="contractData.depositFlag != '1' ? 'active' : ''"
                >
                  없음
                </p>
                <div
                  class="onoff-btn"
                  :class="contractData.depositFlag == '1' ? 'active' : ''"
                  @click="toggleData('depositFlag')"
                ></div>
                <p
                  class="onoff-txt"
                  :class="contractData.depositFlag == '1' ? 'active' : ''"
                >
                  있음
                </p>
              </div>
            </div>
            <div class="deposit" v-if="contractData.depositFlag == '1'">
              <div class="field">
                <p class="name">계약금 결제일</p>
                <div class="info">
                  <input
                    class="txt"
                    :class="
                      !contractData.depositDate && isOrderError ? 'error' : ''
                    "
                    type="date"
                    max="9999-12-31"
                    v-model="contractData.depositDate"
                  />
                  <p
                    v-if="!contractData.depositDate && isOrderError"
                    class="error-msg"
                  >
                    계약금 결제일을 입력해주세요.
                  </p>
                </div>
              </div>
              <div class="field" v-if="contractData.depositFlag == '1'">
                <p class="name">금액 (VAT 포함)</p>
                <div class="info">
                  <div class="flex flex-row-center wd100">
                    <input
                      class="txt mr-8"
                      :class="
                        !contractData.depositCost && isOrderError ? 'error' : ''
                      "
                      type="text"
                      v-model="contractData.depositCost"
                      placeholder="계약금을 입력해주세요."
                    />원
                  </div>
                  <p
                    v-if="!contractData.depositCost && isOrderError"
                    class="error-msg"
                  >
                    금액을 입력해주세요.
                  </p>
                </div>
              </div>
            </div>
            <div class="field">
              <p class="name">최종 결제일</p>
              <div class="info">
                <input
                  class="txt"
                  :class="
                    !contractData.paymentDate && isOrderError ? 'error' : ''
                  "
                  type="date"
                  max="9999-12-31"
                  v-model="contractData.paymentDate"
                />
                <p
                  v-if="!contractData.paymentDate && isOrderError"
                  class="error-msg"
                >
                  최종 결제일을 입력해주세요.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contract-step-page-section">
        <div class="middle">
          <p class="middle-title">발주서 서명/사인</p>
          <div class="info">
            <div
              class="sign mt-20"
              :class="!this.isSignError && isOrderError ? 'error' : ''"
            >
              <VueSignaturePad
                v-if="!this.contractData.designerSignFileName"
                width="100%"
                height="100%"
                ref="signaturePad"
                class=""
                :options="{ onBegin, onEnd }"
              />
              <img
                class="sign-img"
                v-else
                :src="this.contractData.designerSignFileUrl"
              />
              <input
                class="hidden"
                type="file"
                id="file"
                accept=".gif, .jpg, .jpeg, .png, .ai, ,psd, .xlsx, .svg"
                @change="loadSign($event)"
              />
            </div>
            <p v-if="!isSignError && isOrderError" class="error-msg">
              서명/사인을 입력해주세요.
            </p>
          </div>
          <!-- <img v-if="y.sourceFileName.indexOf('.ai') != -1" class="img-etc" src="@/assets/img/thumbnail-ai.png"> -->
          <!-- <label for="file" class="imgs-img flex active pointer">+</label> -->
          <label class="load-sign" for="file">서명/사인 불러오기</label>
        </div>
        <div v-if="Number(this.contractData.contractStatus) >= 3" class="mt-20">
          <p class="middle-title">디지털 계약서 공장 사인</p>
          <div class="sign mt-40">
            <img :src="this.contractData.factorySignFileUrl" />
          </div>
        </div>
        <div
          v-if="
            (Number(this.contractData.contractStatus) >= 2 && compType == 1) ||
            Number(this.contractData.contractStatus) >= 3
          "
          class="mt-48"
        >
          <p class="middle-title">디지털 발주서 디자이너 사인</p>
          <div class="sign mt-40">
            <img :src="this.contractData.designerSignFileUrl" />
          </div>
        </div>

        <!-- <div class="mt-40" v-if="Number(contractData.contractStatus) <= 2"> -->
        <div class="middle last">
          <p class="fieldtitle">동의함 (필수)</p>
          <div class="agree">
            <p
              class="agree-title pointer"
              @click="agree = !agree"
              :class="agree ? 'active' : ''"
            >
              주의 안내 사항에 동의합니다.
            </p>
            <div class="agree-content">
              <p>
                - 본 발주서의 내용은 패션제조산업의 거래에서 발생하는 의류 제작
                및 생산에 대한 표준 발주서 형식을 기초로 작성 되었으며, 발주자와
                생산자의 작업 및 거래 발주 내용에 대한 이해를 돕고, 법률적인
                가이드를 제시하기 위한 목적 으로 제작되었으며, 이를
                확인·인지하고 작성하였음을 확인하였습니다.
              </p>
              <p>
                - 본 발주서는 법률적인 자문이나 해석을 위하여 제공된 것이 아님을
                확인하였습니다.
              </p>
              <p>
                - 오슬은 발주자와 생산자 간, 거래 및 작업 관계에는 일체 관여하지
                않으며, 납기 및 품질, 거래, 결제 등으로 발생하는 분쟁 및 기타
                손해에 대하여 “주식회사 위아더”는 법적인 책임을 지지 않으며,
                관련 책임은 당사자 간에 있음을 확인 하였습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom">
    <div
      v-if="step == 0"
      :class="isMobile == 'true' ? 'bottom-btns' : 'bottom-btns-web'"
    >
      <!-- <p class="bottom-btn" @click="click = modifyComp()" v-if="isModify">
        수정 완료
      </p>
      <p
        class="bottom-btn gray"
        @click="click = clickStep('modify')"
        v-if="!isModify"
      >
        수정
      </p> -->
      <p
        class="bottom-btn"
        @click="click = clickStep(Number(step) + 1)"
        v-if="!isModify"
      >
        다음
      </p>
    </div>
    <div
      class="bottom-btns"
      v-else-if="step != 2"
      :class="isMobile == 'true' ? 'bottom-btns' : 'bottom-btns-web'"
    >
      <p class="bottom-btn" @click="clickStep(Number(step) + 1)">다음</p>
    </div>
    <div
      class="bottom-btns"
      v-else-if="step == 2"
      :class="isMobile == 'true' ? 'bottom-btns' : 'bottom-btns-web'"
    >
      <p class="bottom-btn" @click="registContract()">완료</p>
    </div>
    <div
      class="bottom-btns"
      v-else
      :class="isMobile == 'true' ? 'bottom-btns' : 'bottom-btns-web'"
    >
      <p class="bottom-btn" @click="this.$router.go(-1)">확인</p>
    </div>
  </div>

  <div v-if="showModal && showModalId == 'send'" class="modal">
    <!-- <div class="modal"> -->
    <div class="modal-background"></div>
    <div class="modal-contract">
      <div class="modal-contract-body">
        <!-- <p class="title">{{ contractData.styleName }}</p>
          <p class="catg mt-20">{{ contractData.itemCatgName }}</p> -->
        <p class="title">
          <span>{{ contractData.factoryCompName }}</span> 에게<br />
          서명/사인을 요청하시겠습니까?
        </p>
        <p class="quest mt-36">요청 후에는 수정하실 수 없습니다. (삭제 가능)</p>
      </div>
      <div class="quest-btns">
        <p class="quest-btns-left pointer" @click="setModal(false)">취소</p>
        <p
          class="quest-btns-right pointer"
          @click="
            isProgress ? sendContract() : '';
            sendComplete = true;
          "
        >
          발주
        </p>
      </div>
    </div>
  </div>

  <div v-if="showModal && showModalId == 'sendComplete'" class="modal">
    <div class="modal-background" @click="setModal(false)"></div>
    <div class="modal-contract">
      <div class="modal-contract-body">
        <p class="title">
          <span>{{ contractData.factoryCompName }}</span> 에게<br />
          서명/사인 요청되었습니다.
        </p>
      </div>
      <div class="contract-btns">
        <p
          class="btns-btn"
          @click="
            setModal(false);
            goToChatRoom();
          "
        >
          완료
        </p>
      </div>
    </div>
  </div>
  <!-- <div v-if="showModal && showModalId == 'sendComplete'" class="modal">
    <div class="modal-background" @click="setModal(false)"></div>
    <div class="modal-wrap">
      <p>디지털 계약서 작성 완료</p>
      <p>공장의 계약서 확인까지<br />잠시만 기다려주세요.</p>
      <div class="btns">
        <p
          class="btns-btn"
          @click="
            setModal(false);
            goList();
          "
        >
          확인
        </p>
      </div>
    </div>
  </div> -->
</template>

<script>
import { inject } from "vue";
import { mapState } from "vuex";
import { MESSAGE_TYPE, CONTRACT_TYPE } from "@/constants/chat";
import { useMessage } from "@/composables/useMessage";
import { serverTimestamp } from "firebase/firestore";

export default {
  name: "ContractCom",
  props: {
    designOrderNo: {
      type: String,
    },
    factoryNo: {
      type: String,
    },
  },
  computed: {
    ...mapState("meta", ["contract"]),
  },
  mounted() {
    const { koreanTime } = useMessage();
    this.koreanTime = koreanTime;
    this.manager = inject("$firebaseManager");
    // this.docId = localStorage.getItem("roomName");

    if (this.$route.query.page) this.step = this.$route.query.page;

    let designOrderNo = this.designOrderNo
      ? this.designOrderNo
      : this.$route.params.designOrderNo;
    let factoryNo = this.factoryNo
      ? this.factoryNo
      : this.$route.params.factoryNo;

    this.$API.designOrder
      .getContract(this.userId, designOrderNo, factoryNo)
      .then((res) => {
        this.contractData = res.data.body;

        this.compData.contractNo = this.$route.params.contractNo;
        this.compData.designerUserId = this.contractData.designerUserId;
        this.compData.designerCompName = this.contractData.designerCompName;
        this.compData.designerCompNum = this.contractData.designerCompNum;
        this.compData.designerCompFileUrl =
          this.contractData.designerCompFileUrl;
        this.compData.designerRepresentName =
          this.contractData.designerRepresentName;
        this.compData.designerTelNum = this.contractData.designerTelNum;
        this.compData.designerAddress = this.contractData.designerAddress;
        this.compData.designerAddressDetail =
          this.contractData.designerAddressDetail;
        this.contractData.page = 1;

        this.contractData.testDate = 14;
        this.contractData.deliveryFlag = 1;
        this.contractData.depositFlag = 0;

        this.factoryId = this.contractData.factoryUserId;
      });

    this.roomName = `${this.compData.designerUserId}:${this.factoryId}`;
    localStorage.setItem("roomName", this.roomName);
  },
  data() {
    return {
      filters: {
        status: [
          { name: "대상자", cd: "0", isActive: true },
          { name: "작업 정보", cd: "1", isActive: false },
          { name: "납품·정산", cd: "2", isActive: false },
        ],
      },
      contractData: {},
      compData: {},
      roomName: "",
      factoryId: "",
      step: 0,
      inputDeactive: true,
      v: "",
      agree: true,
      showModal: false,
      showModalId: "",
      isShowStyleAdd: true,
      isModify: false,
      isStyleError: false,
      isOrderError: false,
      isSignError: false,
      isProgress: true,
      koreanTime: null,
      userId: this.$route.query.userId || sessionStorage.getItem("userId"),
      compType:
        this.$route.query.compType || sessionStorage.getItem("compType"),
      testDate: [
        { index: "0", name: "7", cd: "1", isActive: false },
        { index: "1", name: "14", cd: "2", isActive: true },
        { index: "2", name: "21", cd: "3", isActive: false },
        { index: "3", name: "28", cd: "4", isActive: false },
      ],
      signImg: {},
      errorData: {},
      deliveryCount: 1,
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  methods: {
    removeStyle(index) {
      this.contractData.contractStyleList.splice(index, 1);
    },
    clickStep(step) {
      if (typeof step == "number") {
        if (step !== 3) {
          this.$route.query.page = step;
          this.step = step;
          if (step == 2) {
            let errCnt = 0;
            this.contractData.contractStyleList.forEach((e) => {
              !e.styleName ? errCnt++ : errCnt;
              !e.colorCount ? errCnt++ : errCnt;
              !e.sizeCount ? errCnt++ : errCnt;
              !e.quantity ? errCnt++ : errCnt;
              !e.unitCost ? errCnt++ : errCnt;
              // !e.colorCount? errCnt++ : errCnt
              !e.imgList.length > 0 ? errCnt++ : errCnt;
            });
            if (errCnt) {
              this.isStyleError = true;
              this.$route.query.page = step - 1;
              this.step = step - 1;
            }
          }
        }
      } else {
        if (step == "modify") {
          this.isModify = true;
        } else if (step == "modified") {
          //update comp info
          this.isModify = false;
        }
      }
    },
    fileUpload(fileStatus, maxLength, event, index) {
      this.errorData.imgList = false;
      let files = [];

      // let c =
      //   maxLength -
      //   this.contractData.contractStyleList[index].imgList.filter(
      //     (e) => e.fileStatus == fileStatus
      //   ).length;

      let msg = "";
      Array.from(event.target.files).forEach((e) => {
        if (
          (e.name.indexOf(".ai") == -1 && e.size >= 1024000 * 10) ||
          (e.name.indexOf(".ai") != -1 && e.size >= 1024000 * 100)
        ) {
          // 파일 용량 제한
          msg = "파일 용량이 초과되었습니다.";
          return;
        }
        // if (c < 1) {
        //   msg = "최대 개수를 초과했습니다.";
        //   return;
        // }
        files.push(e);
        // c = c - 1;
      });
      this.$API.comm
        .fileUploadMultiple(sessionStorage.getItem("userId"), "", "", "", files)
        .then((res) => {
          console.log(res);
          console.log(index);
          if (msg) this.showToast(msg);
          res.data.body.forEach((e, i) => {
            let d = e["imgFile" + (i + 1)]; // api 수정시 변경
            this.contractData.contractStyleList[index].imgList.push({
              fileNo: d.fileNo,
              fileSize: d.fileSize,
              fileStatus: d.fileStatus,
              fileType: d.fileType,
              fileUrl: d.previewUrl,
              sourceFileName: d.sourceFileName,
            });
          });
        });
    },
    removeImg() {
      this.contractData.imgFileName = "";
      this.contractData.imgFileNo = "";
      this.contractData.imgFileUrl = "";
    },
    addSpecial() {
      this.contractData.specialContent.push("");
    },
    removeSpecial(i) {
      this.contractData.specialContent.splice(i, 1);
      if (this.contractData.specialContent.length === 0)
        this.contractData.specialContent.push("");
    },
    changeData(d, name, val) {
      if (this.inputDeactive) return;
      this[d][name] = val;
    },
    getAddress(d, name) {
      new window.daum.Postcode({
        oncomplete: (res) => {
          this[d][name] = res.jibunAddress;
          // this.d[type].areaCd = res.sigunguCode
          // this.d[type].area = res.sigungu
        },
      }).open();
    },
    setModal(isShow, modalId) {
      this.showModal = isShow;
      this.showModalId = modalId || "";
      this.backgroundScroll(isShow);
    },
    onBegin() {
      this.isSignError = true;
    },
    clearSign() {
      this.$refs.signaturePad.clearSignature();
    },
    async loadSign(event) {
      await this.$API.comm
        .fileUpload(sessionStorage.getItem("userId"), event.target.files[0])
        .then((res) => {
          this.contractData.designerSignFileNo = res.data.body.img.fileNo;
          this.contractData.designerSignFileName =
            res.data.body.img.sourceFileName;
          this.contractData.designerSignFileUrl = res.data.body.img.previewUrl;
        });
    },
    modifyComp() {
      // this.$API.designOrder.modifyContractDesigner(this.compData);
      this.contractData.designerCompName = this.compData.designerCompName;
      this.contractData.designerCompNum = this.compData.designerCompNum;
      this.contractData.designerRepresentName =
        this.compData.designerRepresentName;
      this.contractData.designerTelNum = this.compData.designerTelNum;
      this.contractData.designerAddress = this.compData.designerAddress;
      this.contractData.designerAddressDetail =
        this.compData.designerAddressDetail;
      this.isModify = false;
    },
    styleAdd() {
      const listLength = this.contractData.contractStyleList.length;
      if (listLength < 4) {
        this.contractData.contractStyleList.push({
          styleNo: listLength + 1,
          styleName: "",
          colorCount: "",
          sizeCount: "",
          quantity: "",
          unitCost: "",
          imgList: [],
        });
      }
      if (listLength == 3) {
        this.isShowStyleAdd = false;
      }
    },
    selectData(data, type) {
      this[type].filter((e) => (e.isActive = false));
      this[type]
        .filter((e) => e.index === data.index)
        .map((e) => (e.isActive = true));
      this.contractData.testDate = data.name;
    },
    toggleData(flag) {
      if (flag == "deliveryFlag") {
        if (this.contractData.deliveryFlag == "2") {
          this.contractData.deliveryFlag = "1";
        } else {
          this.contractData.deliveryFlag = "2";
        }
      } else if (flag == "depositFlag") {
        if (this.contractData.depositFlag) {
          this.contractData.depositFlag =
            this.contractData.depositFlag == "0" ? "1" : "0";
        } else {
          this.contractData.depositFlag = "0";
        }
      }
    },
    getSupplyCost() {
      let sucost = 0;
      this.contractData.contractStyleList.forEach(
        (e) => (sucost += e.quantity * e.unitCost)
      );
      // sucost = this.contractData.supplyCost;
      console.log(sucost);
      this.contractData.supplyCost = sucost;
      this.contractData.totalCost = (
        this.contractData.supplyCost * 1.1
      ).toFixed(0);
    },
    registContract() {
      if (
        this.contractData.deliveryDateFin &&
        this.contractData.paymentDate &&
        this.isSignError
      ) {
        if (!this.contractData.designerSignFileNo) {
          const { isEmpty, data } =
            this.$refs.signaturePad.saveSignature("image/png");
          this.$API.comm
            .fileUpload(this.userId, this.base64toFile(data, "sign.png"))
            .then((res) => {
              if (res.data.resultCode == "10000") {
                this.contractData.designerSignFileNo = res.data.body.img.fileNo;
                this.contractData.designerSignFileName =
                  res.data.body.img.sourceFileName;
                this.contractData.designerSignFileUrl =
                  res.data.body.img.previewUrl;
                this.setModal(true, "send");
              } else {
                this.setMsgPopup(
                  true,
                  "서버가 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                );
              }
            });
        } else {
          this.setModal(true, "send");
        }
      } else {
        this.isOrderError = true;
      }
    },
    sendContract() {
      this.roomName = `${this.compData.designerUserId}:${this.factoryId}`;
      console.log(this.roomName);

      let designOrderNo = this.designOrderNo
        ? this.designOrderNo
        : this.$route.params.designOrderNo;
      let factoryNo = this.factoryNo
        ? this.factoryNo
        : this.$route.params.factoryNo;
      this.isProgress = false;
      this.isOrderError = false;
      this.contractData.designOrderNo = designOrderNo;
      this.contractData.factoryNo = factoryNo;
      this.contractData.userId = this.userId;
      this.contractData.contractStyleListJson = JSON.stringify(
        this.contractData.contractStyleList
      );
      this.contractData.recruitStatus = "2";
      this.contractData.depositCost = this.contractData.depositCost
        ? this.contractData.depositCost
        : "0";
      this.$API.designOrder.registContractNew(this.contractData).then((res) => {
        const contractNo = res.data.body;
        if (res) {
          // 계약 성공
          console.log(res);
          this.showToast("발주서가 발송되었습니다.");
          this.saveData(this.contractData.contractStyleList[0], contractNo);
        }
      });
    },
    async saveData(x, contractNo) {
      const roomName = await this.manager.createChatRoom({
        designerId: this.compData.designerUserId,
        factoryId: this.factoryId,
        updatedDate: serverTimestamp(),
      });

      // 스타일 이름, 금액, 보낸 시간, 계약서 번호, 보낸 사람, 파일 타입
      let args = {
        contractNo: contractNo,
        contractType: CONTRACT_TYPE.SUCCESS,
        designOrderNo: this.contractData.designOrderNo,
        factoryNo: this.contractData.factoryNo,
        styleName: x.styleName,
        totalCost: this.contractData.totalCost,
        type: MESSAGE_TYPE.CONTRACT,
        createDate: this.koreanTime,
        sender: this.userId,
        readFlag: 0,
      };

      console.log(args);
      console.log(roomName);
      this.manager.sendMessage(args, roomName);
      this.goToChatRoom();
    },

    async goToChatRoom() {
      const docId = await this.manager.createChatRoom({
        designerId: sessionStorage.getItem("userId"),
        factoryId: this.factoryId,
        updatedDate: serverTimestamp(),
      });
      console.log(docId);
      console.log(this.isMobile);

      if (this.isMobile == "true") {
        // location.href = `/chats/${docId}`;
        this.$router.push(`/chats/${docId}`);
      } else {
        this.$router.push(`/webchats/chat/${docId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-wrap {
  @include set-width();
  background-color: $neutral-0;
  .top {
    border-bottom: 1px solid $primary-20;
    padding: 48px 0 20px;
    margin: 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .square {
      width: 8px;
      height: 8px;
      background-color: $primary-50;
    }
    .title {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: $primary-80;
    }
  }
  .contract-step {
    gap: 8px;
    position: relative;
    justify-content: space-between;
    &-tab {
      padding: 0 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $neutral-60;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      .issue-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 36px;
        border-style: solid;
        border-color: $neutral-20;
        &:nth-child(1) {
          border-radius: 4px 0 0 4px;
          border-width: 1px 0 1px 1px;
        }
        &:nth-child(2) {
          border-width: 1px 0 1px 0;
        }
        &:nth-child(3) {
          border-radius: 0 4px 4px 0;
          border-width: 1px 1px 1px 0;
        }
        &.active {
          border-radius: 4px;
          color: $neutral-0;
          border-width: 20px;
          border-style: solid;
          background-color: $primary-50;
          border-color: $primary-50;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: calc(100% - 32px);
      height: 1px;
      background-color: $neutral-40;
      z-index: 1;
    }

    &-page {
      padding-bottom: 32px;
      &-section {
        // padding: 40px 16px;
        .middle {
          padding: 20px;
          border-bottom: 1px solid $neutral-10;
          &-title {
            color: $primary-60;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
          }
          &.last {
            border-bottom: 0px;
          }
        }
        .comp {
          width: 100px;
          height: 32px;
          border: 1px solid $neutral-20;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          color: $neutral-80;
        }
        .field-list {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        .price-list {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .field {
          display: flex;
          &.column {
            flex-direction: column;
            gap: 12px;
          }
        }
        .price {
          display: flex;
          justify-content: space-between;
          .name {
            flex: 4;
          }
        }
        .deposit {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 16px 20px;
          background-color: $neutral-3;
          border-radius: 4px;
          .name {
            flex: 1.5;
          }
        }
        .name {
          display: flex;
          align-items: center;
          flex: 1;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          color: $neutral-70;
          &.red {
            color: $primary-60;
          }
          &.start {
            align-items: flex-start;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          // align-items: center;
          align-items: flex-start;
          flex: 2;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: $neutral-60;
          &.end {
            align-items: flex-end !important;
          }
          .error-msg {
            margin: 4px 8px 0;
            color: $secondary-60;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
          }
          .filter-menu {
            margin: 0 8px;
            width: 76px;
            &-value-txt {
              text-align: center;
              font-weight: 400;
            }
          }
          &img {
            width: 20px;
            height: 20px;
          }
        }

        .img-name {
          display: grid;
          // flex-direction: column;
          gap: 20px;
          align-items: flex-start;
          flex-wrap: wrap;
          grid-template-columns: repeat(auto-fill, 100px);
          &.red {
            color: $secondary-60;
            p {
              position: absolute;
            }
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            border: 1px solid $neutral-10;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
        .txt {
          padding: 8px;
          background: $neutral-0;
          border-width: 0px 0px 1px 1px;
          border-style: solid;
          border-color: $neutral-20;
          border-radius: 4px;
          width: 100%;
          height: 32px;
          &.error {
            border-color: $secondary-60;
          }
        }
        .img {
          padding: 8px;
          background: $neutral-0;
          border-width: 0px 0px 1px 0px;
          border-style: solid;
          border-color: $neutral-20;
          border-radius: 4px;
          width: 100%;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .uncertified {
          border-radius: 20px;
          background-color: $neutral-3;
          padding: rem(52px) rem(24px) rem(62px);
          font-weight: 500;
          text-align: center;
          .ex {
            line-height: 24px;
            color: $neutral-90;
          }
          .ex-tel {
            color: $neutral-50;
          }
          .ex-btn {
            border-radius: 7px;
            background-color: $primary-50;
            padding: 10px;
            font-weight: bold;
            color: $neutral-0;
            cursor: pointer;
          }
        }
        .delivery {
          border: 1px solid $neutral-10;
          border-radius: 12px;
          &-row {
            overflow: hidden;
            &:first-child {
              border-radius: 12px 12px 0 0;
            }
            &:last-child {
              border-radius: 0 0 12px 12px;
            }
            & + .delivery-row {
              border-top: 1px solid $neutral-10;
            }
            &-title {
              padding: 16px 24px;
              font-weight: 500;
              color: $neutral-60;
              background-color: $neutral-3;
            }
            .date {
              margin-right: 16px;
            }
          }
        }
      }
      .style-add {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin: 32px 20px;
        border: 1px solid $primary-30;
        border-radius: 4px;
        cursor: pointer;
        p {
          color: $primary-60;
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
  .radio-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      color: $neutral-40;
      cursor: pointer;
      & + .btn-radio {
        margin-left: 20px;
      }
      &::before {
        content: "";
        background-image: url("@/assets/icon/ic-radio-off.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: rem(20px);
        height: rem(20px);
        margin-right: rem(6px);
      }
      &.active {
        color: $neutral-70;
        &::before {
          background-image: url("@/assets/icon/ic-radio-on.svg");
        }
      }
    }
  }
  .agree {
    margin-top: rem(13px);
    border: 1px solid $neutral-20;
    border-radius: 4px;
    border-width: 0 1px 1px 1px;
    position: relative;
    &-title {
      border: 1px solid $neutral-20;
      border-radius: 4px;
      position: relative;
      padding: rem(13px) rem(20px);
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $neutral-60;
      position: absolute;
      width: calc(100% + 2px);
      left: -1px;
      top: 0;
      background-color: $neutral-0;
      &::after {
        content: "";
        background-image: url("@/assets/icon/ic-check-off.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: rem(18px);
        height: rem(18px);
        position: absolute;
        right: rem(20px);
        top: 50%;
        border-color: $primary-50;
        transform: translateY(-50%);
      }
      &.active {
        color: $primary-60;
        border: 1px solid $primary-50;
        border-radius: 4px;
        &::after {
          background-image: url("@/assets/icon/ic-check-on.svg");
        }
      }
    }
    &-content {
      font-size: 12px;
      line-height: 18px;
      color: $neutral-70;
      padding: rem(20px) rem(16px);
      padding-top: 70px;
      p {
        margin-bottom: 6px;
      }
    }
  }
  .payment-ex {
    &-title {
      font-weight: 500;
      color: $neutral-50;
    }
    &-box {
      font-size: 12px;
      color: $neutral-70;
      line-height: 22px;
      border: 1px solid $neutral-10;
      border-width: 1px 0 1px 0;
      &-bg {
        background-color: $neutral-5;
        padding: 16px;
        margin: 12px 0;
      }
    }
  }
  .imgs {
    @include set-grid(100px, 10px, 10px);
    &-img {
      width: 100px;
      height: 100px;
      border: 1px dashed #c4c4c4;
      position: relative;
      .wording {
        margin-top: 8px;
        line-height: 20px;
        color: #474747;
      }
      img {
        height: 100%;
      }
      &.active {
        border: 1px solid $primary-50;
        background-color: $primary-50;
        font-size: 32px;
        color: $neutral-0;
      }
      &-pop {
        position: absolute;
        top: 0;
        right: 0;
        width: rem(34px);
        height: rem(34px);
        border-radius: 17px;
        background-color: rgba(113, 98, 79, 0.4);
        p {
          font-size: 16px;
          color: $neutral-0;
        }
      }
      &-filename {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 40px;
        padding: rem(6px);
        background-color: rgba(0, 0, 0, 0.4);
        p {
          @include ellipse(2);
          font-size: 12px;
          color: $neutral-0;
          text-align: center;
          word-break: break-all;
        }
      }
    }
  }
  .sign {
    border: 1px solid $neutral-20;
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 240px;
    overflow: hidden;
    // padding-bottom: 40%;
    border: 1px solid $neutral-20;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.error {
      border-color: $secondary-60;
    }
    canvas {
      // position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("@/assets/icon/ic-contract-sign.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    &-img {
      height: 100%;
    }
    .resign {
      position: absolute;
      margin: 0 5%;
      padding: 12px;
      left: 0;
      bottom: 12px;
      width: 90%;
      text-align: center;
      font-weight: 700;
      color: $neutral-0;
      background-color: $primary-50;
      border-radius: 7px;
      cursor: pointer;
    }
  }
  .load-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 20px;
    color: $primary-50;
    border: 1px solid $primary-30;
    border-radius: 4px;
  }
}
.modal {
  &-contract {
    background-color: $neutral-0;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: fixed;
    z-index: 1001 !important;
    left: 50%;
    top: 50%;
    width: calc(100% - 48px);
    max-width: 320px;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $neutral-100;
    &-body {
      padding: 32px 20px;
      display: flex;
      flex-direction: column;
      .title {
        color: $neutral-100;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        br {
          content: "";
          display: block;
          height: 12px;
        }
        span {
          color: $primary-60;
        }
      }
      .quest {
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        span {
          color: $primary-60;
        }
      }
    }
    .quest-btns {
      display: flex;
      height: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $neutral-0;
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-radius: 0px 0px 8px 8px;
      }
      &-left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $neutral-60;
        border-bottom-left-radius: 8px;
      }
      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.bottom {
  // @include set-width(true);
  z-index: 1;
  position: fixed;
  width: 100%;
  // left: 0;
  @include set-bottom();
  @include tablet {
    @include set-bottom(20px);
  }
  &-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    height: 40px;
    color: $neutral-0;
    background-color: $primary-50;
    border: 1px solid $primary-50;
    cursor: pointer;
    &.exit {
      color: $primary-50;
      background-color: $neutral-0;
      border: 1px solid $primary-50;
    }
    &.gray {
      background-color: $neutral-60;
      border: 1px solid $neutral-60;
    }
  }

  .bottom-btns-web {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 520px;
    bottom: 0;
  }
}
</style>
