<template>
  <div class="custom-main">
    <div class="bg"></div>
    <div class="custom-main-top" v-if="$store.state.meta.windowWidth > 960">
      <div class="custom-main-top-left">
        <div class="top-title">
          <p><span>디자인</span>이 쉬워집니다.</p>
          <p><span>제작</span>이 간편해집니다.</p>
        </div>
        <div class="top-subtitle">
          <p>쉽고 간편하게 세상에 단 하나뿐인</p>
          <p>우리 만의 상품을 디자인하고 만들어보세요.</p>
        </div>
        <div class="top-btn" @click="openCustomAddPopup(userId)">
          <p>커스텀몰 이용하기</p>
        </div>
      </div>
      <div class="custom-main-top-right">
        <div class="custom-main-top-right-first">
          <img src="@/assets/img/img-custom-main-top1.png" /><img
            src="@/assets/img/img-custom-main-top2.png"
          />
        </div>
        <div class="custom-main-top-right-second">
          <img src="@/assets/img/img-custom-main-top3.png" /><img
            src="@/assets/img/img-custom-main-top4.png"
          />
        </div>
      </div>
    </div>
    <div class="custom-main-top" v-else>
      <div class="custom-main-top-up">
        <div class="top-subtitle">
          <p>쉽고 간편하게 세상에 단 하나뿐인</p>
          <p>우리 만의 상품을 디자인하고 만들어보세요.</p>
        </div>
      </div>
      <div class="custom-main-top-down">
        <div class="custom-main-top-down-first">
          <img src="@/assets/img/img-custom-main-top1.png" /><img
            src="@/assets/img/img-custom-main-top2.png"
          />
        </div>
        <div class="custom-main-top-down-second">
          <img src="@/assets/img/img-custom-main-top3.png" /><img
            src="@/assets/img/img-custom-main-top4.png"
          />
        </div>
      </div>
      <div class="top-btn" @click="openCustomAddPopup(userId)">
        <p>커스텀몰 이용하기</p>
      </div>
    </div>
    <div class="custom-main-banner">
      <p>
        <span>우리 만의 의류</span>를<br
          v-if="$store.state.meta.windowWidth < 650"
        />
        만들고 싶으신가요 !?
      </p>
      <p>
        아직도 <span>기성품에 단순히 프린팅</span>만<br
          v-if="$store.state.meta.windowWidth < 650"
        />
        하고 계신가요 !?
      </p>
      <p>
        <span>디자인부터 생산 관리까지 복잡함</span>에<br
          v-if="$store.state.meta.windowWidth < 650"
        />
        골머리를 앓고 계신가요 !?
      </p>
      <p>
        <span>우리 만의 번뜩이는 디자인</span>으로<br
          v-if="$store.state.meta.windowWidth < 650"
        />
        시장을 사로잡고 싶으신가요 !?
      </p>
    </div>
    <div class="custom-main-body">
      <div class="custom-main-body-title">
        <p>원단 선택부터 디테일, 핏, 사양, 프린팅까지</p>
        <p>
          온라인에서 쉽고 간편하게 디자인하고, 생산까지 한 방에
          해결해드립니다.<span v-if="isMobile == 'false'"></span>
        </p>
      </div>
      <div class="custom-main-body-content">
        <div class="process">
          <!-- <div class="process" v-if="$store.state.meta.windowWidth > 940"> -->
          <div class="process-title"><p>PROCESS</p></div>
          <div class="process-content">
            <div class="box">
              <div class="info">
                <div
                  class="info-text"
                  v-if="$store.state.meta.windowWidth > 940"
                >
                  약 7일 ~ 10일 이내
                </div>
                <div class="info-text" v-else>7일 ~ 10일</div>
                <div class="info-line"></div>
              </div>
              <div class="box-text">디자인 빌더</div>
              <div class="arrow">
                <img
                  src="@/assets/icon/ic-custom-main-vector.svg"
                  v-if="$store.state.meta.windowWidth > 940"
                />
                <img
                  src="@/assets/icon/ic-custom-main-vector-down.svg"
                  v-else
                />
              </div>
              <div class="box-text">주문 / 결제</div>
              <div class="arrow">
                <img
                  src="@/assets/icon/ic-custom-main-vector.svg"
                  v-if="$store.state.meta.windowWidth > 940"
                />
                <img
                  src="@/assets/icon/ic-custom-main-vector-down.svg"
                  v-else
                />
              </div>
              <div class="box-text">시안 확인</div>
              <div class="arrow">
                <img
                  src="@/assets/icon/ic-custom-main-vector.svg"
                  v-if="$store.state.meta.windowWidth > 940"
                />
                <img
                  src="@/assets/icon/ic-custom-main-vector-down.svg"
                  v-else
                />
              </div>
              <div class="box-text">생산</div>
              <div class="arrow">
                <img
                  src="@/assets/icon/ic-custom-main-vector.svg"
                  v-if="$store.state.meta.windowWidth > 940"
                />
                <img
                  src="@/assets/icon/ic-custom-main-vector-down.svg"
                  v-else
                />
              </div>
              <div class="box-text">배송</div>
            </div>
          </div>
        </div>
        <!-- <div class="process" v-else>
          <div class="process-title"><p>PROCESS</p></div>
          <div class="process-content">
            <div class="box">
              <div class="info">
                <div class="info-text">약 7일 ~ 10일 이내</div>
                <div class="info-line"></div>
              </div>
              <div class="box-text">디자인 빌더</div>
              <div class="arrow">
                <img src="@/assets/icon/ic-custom-main-vector.svg" />
              </div>
              <div class="box-text">주문 / 결제</div>
              <div class="arrow">
                <img src="@/assets/icon/ic-custom-main-vector.svg" />
              </div>
              <div class="box-text">시안 확인</div>
              <div class="arrow">
                <img src="@/assets/icon/ic-custom-main-vector.svg" />
              </div>
              <div class="box-text">생산</div>
              <div class="arrow">
                <img src="@/assets/icon/ic-custom-main-vector.svg" />
              </div>
              <div class="box-text">배송</div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="custom-main-body-content">
        <div class="desc">
          <div class="desc-text">
            <div class="text-title">
              <p>신뢰할 수 있는 고품질의 원단</p>
              <p>트렌디하고 경쟁력있는 다양한 컬러</p>
            </div>
            <div class="text-content mt-32">
              <p>값 싸고 품질 낮은 원단이 아닙니다.</p>
              <p>
                믿을 수 있는 섬유기업의 트렌디하고 검증된 원단 만을 제공합니다.
              </p>
            </div>
          </div>
          <div class="desc-img">
            <img src="@/assets/img/img-custom-main-fabric1.png" />
            <img src="@/assets/img/img-custom-main-fabric2.png" />
          </div>
        </div>
      </div>
      <div class="custom-main-body-content">
        <div class="desc">
          <div class="desc-text">
            <div class="text-title">
              <p>쉽고 간편하게 원하는 핏 추천</p>
              <p>자연스러운 핏감의 사이즈 스펙 제공</p>
            </div>
            <div class="text-content mt-32">
              <p>트렌디하면서 자연스러운 핏감의 사이즈 스펙을 제공합니다.</p>
              <p>
                오슬의 방대한 패턴 Data와 사이즈 정보, 10,000style 이상의 상품
                사이즈 정보를 수집하고 분석하였습니다.
              </p>
            </div>
          </div>
          <div class="desc-img">
            <img src="@/assets/img/img-custom-main-register1.png" />
            <img src="@/assets/img/img-custom-main-register2.png" />
          </div>
        </div>
      </div>
      <div class="custom-main-body-content">
        <div class="desc">
          <div class="desc-text">
            <div class="text-title">
              <p>단 ‘5분’이면 원하는 디자인과 디테일, 봉제 사양 완성</p>
              <p>디테일한 선택이 가능한 고퀄리티 프린팅</p>
            </div>
            <div class="text-content mt-32">
              <p>초보자부터 전문가까지 누구나 가능합니다.</p>
              <p>
                네크라인부터 스티치, 포켓, 밑단, 마감, 프린팅 등 실제 작업지시서
                내용을 쉽고 간편하게 선택하여 만들어보세요.
              </p>
            </div>
          </div>
          <div class="desc-img">
            <img src="@/assets/img/img-custom-main-sizespec1.png" />
            <img src="@/assets/img/img-custom-main-sizespec2.png" />
          </div>
        </div>
      </div>
      <div
        class="custom-main-body-desc"
        v-if="$store.state.meta.windowWidth > 940"
      >
        <div class="desc-wrap">
          <div class="desc-wrap-row">
            <p>✅ 온라인에서 디자인이 가능합니다.</p>
            <p>
              ✔ 27만 스타일 이상의 디자인이 가능한 '디자인 빌더'를 통해 쉽고
              간편하게 디자인하세요.
            </p>
          </div>
          <div class="desc-wrap-row">
            <p>✅ 1장도 제작 가능합니다.</p>
            <p>
              ✔ 단 1장의 샘플부터 1,000장, 10,000장의 대량 주문까지 모두 제작
              가능합니다.
            </p>
          </div>
          <div class="desc-wrap-row">
            <p>✅ 신뢰할 수 있는 원단 만을 선별합니다.</p>
            <p>
              ✔ 30년 이상의 업력을 지닌 믿을 수 있는 섬유기업에서 생산하는 국내
              원단 만을 제공하고 있습니다.
            </p>
          </div>
          <div class="desc-wrap-row">
            <p>✅ 자연스럽고 트렌디한 핏을 기본 제공합니다.</p>
            <p>
              ✔ 1만 개 이상의 Data를 기반으로 트렌디하면서 자연스러운 핏감의
              사이즈 스펙을 제공하고 있습니다.
            </p>
          </div>
          <div class="desc-wrap-row">
            <p>✅ 프리미엄 퀄리티를 느껴보세요.</p>
            <p>
              ✔ '오슬'은 1,000여 개 이상의 기업이나 패션 브랜드, 단체, 굿즈 등을
              제작하였고, 제작 중입니다.
            </p>
          </div>
          <div class="desc-wrap-row">
            <p>✅ 10분이면 가능합니다.</p>
            <p>
              ✔ 간단한 선택과 BEST 디자인, 추천 디자인 등 전문 지식이 없으셔도,
              쉽고 간편하게 원하는 디자인이 가능합니다.
            </p>
          </div>
          <div class="desc-wrap-row">
            <p>✅ 합리적인 단가로 제공합니다.</p>
            <p>
              ✔ '오슬' 만의 방대한 생산 인프라와 특별한 생산 시스템을 통해,
              경쟁력있는 합리적인 단가로 제작이 가능합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="custom-main-infra">
      <div>
        <p>국·내외 4,000개 이상의 생산 인프라 보유</p>
      </div>
      <div><img src="@/assets/img/img-custom-main-infra.png" /></div>
    </div> -->

    <div class="custom-main-bottom">
      <img src="@/assets/img/img-custom-main-bttom.png" />
      <div class="bottom-title">
        <p>
          지금 바로 ‘커스텀 몰’에서<br
            v-if="$store.state.meta.windowWidth < 500"
          />
          만들어보세요.
        </p>
      </div>
      <div class="bottom-btn" @click="openCustomAddPopup(userId)">
        <SvgImg
          :size="20"
          :d="$store.state.meta.svg.customAdd"
          :color="$store.state.meta.svg.color.neutral0"
        />
        <p>디자인 빌더</p>
      </div>
    </div>
    <div class="custom-main-slogan">
      <div class="slogan-title">
        <p>“Design your idea, Design your product”</p>
      </div>
      <div class="slogan-content">
        <p>
          아이디어와 상상력을 쉽고 간편하게 구현할 수 있는<br
            v-if="$store.state.meta.windowWidth < 650"
          />
          <span> 100% 커스터마이징</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomMain",
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      isMobile: sessionStorage.getItem("isMobile"),
    };
  },

  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.custom-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: var(
      --Angular_5,
      conic-gradient(
        from 180deg at 49.66% 51.85%,
        #f9f9f9 0deg,
        #fffbf2 72.0000010728836deg,
        #f9f9f9 144.0000021457672deg,
        #fffbf2 216.00000858306885deg,
        #f9f9f9 288.0000042915344deg
      )
    );
  }
  &-top {
    margin: 60px 0;
    display: flex;
    gap: 12px;
    width: 940px;
    @media (max-width: 960px) {
      width: auto;
      margin: 40px 0;
      flex-direction: column;
      align-items: center;
    }
    .top-btn {
      margin-top: 98px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 29px;
      border-radius: 4px;
      width: 180px;
      color: $neutral-0;
      background: $primary-50;
      font-size: 14px;
      font-weight: 500;
      @media (max-width: 960px) {
        margin: 40px 0 20px;
      }
    }
    &-left {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      .top-title {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 24px;
        font-weight: 500;
        span {
          color: $primary-60;
        }
      }
      .top-subtitle {
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 450px;
      height: 280px;
      &-first {
        display: flex;
        gap: 4px;
      }
      &-second {
        margin-left: 204px;
        display: flex;
        gap: 4px;
      }
    }
    &-up {
      width: 100%;
      .top-subtitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
      }
    }
    &-down {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      &-first {
        display: flex;
        gap: 4px;
        img {
          max-width: 104px;
          max-height: 104px;
        }
      }
      &-second {
        margin-left: 104px;
        display: flex;
        justify-content: flex-end;
        gap: 4px;
        img {
          max-width: 104px;
          max-height: 104px;
        }
      }
    }
  }
  &-banner {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-top: 1px solid $neutral-10;
    border-bottom: 1px solid $neutral-10;
    background: $neutral-80;
    width: 100vw;
    p {
      color: $neutral-0;
      font-size: 22px;
      font-weight: 400;
      @media (max-width: 650px) {
        font-size: 16px;
        text-align: center;
      }
      span {
        color: $primary-20;
      }
    }
  }
  &-body {
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 940px;
    @media (max-width: 940px) {
      width: 100%;
    }
    &-title {
      margin-bottom: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $primary-60;
      font-size: 24px;
      font-weight: 700;
      line-height: 200%; /* 48px */
      @media (max-width: 940px) {
        margin-bottom: 0;
        font-size: 16px;
        text-align: center;
        /* border: 1px solid black; */
        word-wrap: break-word;
        word-break: break-all;
      }
      span {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 715px;
        height: 8px;
        opacity: 0.2;
        background: $primary-50;
      }
    }
    &-desc {
      .desc-wrap {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 20px 86px 0;
        &-row {
          display: flex;
          flex-direction: column;
          gap: 12px;
          :first-child {
            font-size: 16px;
            font-weight: 500;
          }
          :last-child {
            padding-left: 24px;
            font-size: 16px;
            font-weight: 400;
            color: $neutral-60;
          }
        }
      }
    }
    &-content {
      margin: 0 20px;
      border-radius: 8px;
      border: 1px solid $neutral-5;
      background: $neutral-0;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
      .process {
        margin: 40px;
        display: flex;
        flex-direction: column;
        gap: 80px;
        @media (max-width: 940px) {
          margin: 20px;
          gap: 32px;
        }
        &-title {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          @media (max-width: 940px) {
            gap: 16px;
          }
        }
        &-content {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          @media (max-width: 940px) {
            justify-content: flex-start;
          }
          .info {
            &-text {
              position: absolute;
              top: -80px;
              left: 376px;
              color: $primary-60;
              font-size: 16px;
              font-weight: 400;
              @media (max-width: 940px) {
                top: 130px;
                left: 208px;
                font-size: 14px;
              }
            }
            &-line {
              position: absolute;
              top: -36px;
              left: 70px;
              width: 720px;
              height: 72px;
              border-radius: 20px;
              border: 1px dashed $primary-50;
              border-bottom: 0;
              @media (max-width: 940px) {
                top: 20px;
                left: 60px;
                width: 120px;
                height: 240px;
                border: 1px dashed $primary-50;
                border-left: 0;
              }
            }
          }
          .arrow {
            display: flex;
            align-items: center;
            position: relative;
            height: 80px;
            @media (max-width: 940px) {
              height: 20px;
            }
            p {
              position: absolute;
              top: 4px;
              left: 50%;
              transform: translateX(-50%);
              color: $primary-60;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
            }
          }
          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 940px) {
              flex-direction: column;
            }
            &-text {
              z-index: 1;
              display: flex;
              width: 140px;
              height: 80px;
              padding: 12px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              border: 1px solid $primary-20;
              background: $primary-5;
              /* Basic_4480_4 */
              box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
              text-align: center;
              font-size: 16px;
              font-weight: 500;
              line-height: 140%; /* 25.2px */
              @media (max-width: 940px) {
                width: 120px;
                height: 40px;
              }
            }
          }
        }
      }
      .desc {
        position: relative;
        margin: 40px;
        @media (max-width: 940px) {
          margin: 20px;
        }
        &-img {
          margin-top: 32px;
          display: flex;
          gap: 20px;
          max-width: 860px;
          max-height: 410px;
          @media (max-width: 940px) {
            gap: 12px;
            flex-direction: column;
            max-height: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        &-text {
          margin-top: 32px;
          .text-title {
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: $primary-60;
            font-size: 24px;
            font-weight: 700;
            @media (max-width: 940px) {
              gap: 4px;
              font-size: 16px;
            }
          }
          .text-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            font-size: 16px;
            font-weight: 500;
            @media (max-width: 940px) {
              gap: 4px;
              font-size: 14px;
            }
          }
        }
        .cnk-logo {
          position: absolute;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 82px;
          height: 40px;
          border-radius: 4px;
          background: var(--Neutral_03, #f9f9f9);
          img {
            width: 58px;
            height: 20px;
          }
        }
      }
    }
  }
  &-infra {
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    border-top: 1px solid $neutral-10;
    border-bottom: 1px solid $neutral-10;
    background: $neutral-80;

    width: 100vw;
    height: 667px;
    p {
      color: $neutral-0;
      font-size: 28px;
      font-weight: 700;
    }
  }
  &-slogan {
    padding: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100vw;
    @media (max-width: 940px) {
      padding: 40px;
      font-size: 16px;
    }
    .slogan-title {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      @media (max-width: 940px) {
        font-size: 16px;
      }
    }
    .slogan-content {
      color: $neutral-60;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      @media (max-width: 940px) {
        font-size: 14px;
        text-align: center;
      }
      span {
        color: $primary-60;
      }
    }
  }
  &-bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100vw;
    height: 243px;
    @media (max-width: 500px) {
      height: 200px;
    }
    img {
      filter: brightness(20%);
      opacity: 0.8;
    }
    .bottom-title {
      position: absolute;
      top: 60px;
      color: $neutral-0;
      font-size: 28px;
      font-weight: 500;
      text-align: center;
      @media (max-width: 500px) {
        top: 40px;
        font-size: 20px;
      }
    }
    .bottom-btn {
      cursor: pointer;
      position: absolute;
      bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 180px;
      height: 44px;
      padding: 20px;
      color: $neutral-0;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
      background: $primary-50;
      @media (max-width: 500px) {
        bottom: 40px;
      }
    }
  }
}
</style>
