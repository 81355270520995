<template>
  <div class="basket">
    <div class="basket-top">
      <div class="top" v-if="isMobile == 'false'">
        <p class="title">
          <span class="material-symbols-outlined"> edit_square </span>주문/결제
        </p>
      </div>
      <div class="basket-body" v-if="isMobile == 'false'">
        <div class="block-title"><p>받는 사람 정보</p></div>
        <div class="delivery-info">
          <div class="row">
            <div class="row-wrap">
              <div class="row-column">
                <p class="row-column-name">받는 사람</p>
                <p class="row-column-input">
                  <input type="text" v-model="userInfo.userName" />
                </p>
              </div>
              <div class="row-column">
                <p class="row-column-name">연락처</p>
                <p class="row-column-input">
                  <input type="text" v-model="userInfo.telNum" />
                </p>
              </div>
            </div>
            <div class="row-wrap">
              <div class="row-column">
                <p class="row-column-name">배송 주소</p>
                <p class="row-column-input">
                  <input
                    type="text"
                    v-model="userInfo.compAddress"
                    readonly
                    @click="getAddress('address')"
                  />
                </p>
              </div>
              <div class="row-column">
                <p class="row-column-name">배송 상세주소</p>
                <p class="row-column-input">
                  <input type="text" />
                </p>
              </div>
            </div>
          </div>
          <div class="colomn mt-20">
            <p class="colomn-name">배송 메모</p>
            <p class="colomn-input">
              <input type="text" v-model="userInfo.deliveryMemo" />
            </p>
          </div>
        </div>
      </div>
      <div class="basket-body" v-else>
        <div class="block-title"><p>받는 사람 정보</p></div>
        <div class="delivery-info mb">
          <div class="colomn-mb">
            <p class="colomn-mb-name">받는 사람</p>
            <p class="colomn-mb-input">
              <input type="text" v-model="userInfo.userName" />
            </p>
          </div>
          <div class="colomn-mb">
            <p class="colomn-mb-name">연락처</p>
            <p class="colomn-mb-input">
              <input type="text" v-model="userInfo.telNum" />
            </p>
          </div>
          <div class="colomn-mb">
            <p class="colomn-mb-name">배송 주소</p>
            <p class="colomn-mb-input">
              <input
                type="text"
                v-model="userInfo.compAddress"
                readonly
                @click="getAddress('address')"
              />
            </p>
          </div>
          <div class="colomn-mb">
            <p class="colomn-mb-name">배송 상세주소</p>
            <p class="colomn-mb-input">
              <input type="text" />
            </p>
          </div>
          <div class="colomn-mb">
            <p class="colomn-mb-name">배송 메모</p>
            <p class="colomn-mb-input">
              <input type="text" v-model="userInfo.deliveryMemo" />
            </p>
          </div>
        </div>
      </div>
      <div class="basket-order">
        <div class="block-title"><p>주문 정보</p></div>
        <div v-for="(x, i) in buyItem" :key="i">
          <div class="check-content">
            <div class="check-content-body">
              <div class="check-content-body-list">
                <div class="list">
                  <div class="list-wrap" v-for="(y, j) in x.item" :key="j">
                    <div class="item-name">
                      <label class="checkbox-normal">
                        <input
                          type="checkbox"
                          v-model="isChkItem[i][j]"
                          @click="totalSum(i, j)"
                        /><span
                          class="checkmark"
                          :class="{ on: isChkItem[i][j] }"
                        ></span>
                      </label>
                      <img
                        class="img"
                        :src="y?.detailData?.itemImgList[0].fileUrl"
                      />
                      <div class="name">
                        <p>
                          {{ x.itemName }}
                        </p>
                        <p class="option-name">
                          <span class="material-symbols-outlined"> check </span
                          >{{ y.itemOptionName }}
                        </p>
                      </div>
                    </div>
                    <div class="check-updown">
                      <div class="updown br-4 width-110px">
                        <div class="updown-btn gray" @click="downItem(i, j)">
                          <img src="@/assets/icon/ic-minus-white.svg" />
                        </div>
                        <div class="updown-num">
                          <input
                            type="number"
                            v-model="y.count"
                            @keyup="countItem(i, j, y.count)"
                            @focusout="numFocusout(i, j, y.count)"
                          />
                        </div>
                        <div class="updown-btn gray" @click="upItem(i, j)">
                          <img src="@/assets/icon/ic-plus-white.svg" />
                        </div>
                      </div>
                      <p>
                        <span
                          class="material-symbols-outlined"
                          @click="removeBasket(i)"
                        >
                          close
                        </span>
                      </p>
                    </div>
                    <div class="check-price">
                      <p class="price">{{ rowSum(y) }}</p>
                    </div>
                  </div>
                </div>

                <div class="check-delivery">
                  <p class="price">
                    <span v-if="x.deliveryPrice > 0">
                      {{ comma(x.deliveryPrice) }}원</span
                    >
                    <span v-else> 무료 배송</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-info" v-if="isMobile == 'false' && sumPrice > 0">
          <div class="row">
            <p class="row-name">상품가격</p>
            <p class="row-content">{{ comma(sumPrice) }}원</p>
          </div>
          <p class="circle">
            <span class="material-symbols-outlined"> add </span>
          </p>
          <div class="row">
            <p class="row-name">배송비</p>
            <p class="row-content">{{ comma(deliveryPrice) }}원</p>
          </div>
          <p class="circle">
            <span class="material-symbols-outlined"> equal </span>
          </p>
          <div class="row">
            <p class="row-name">주문금액</p>
            <p class="row-content yellow">{{ comma(totalPrice) }}원</p>
          </div>
        </div>

        <div class="item-info-mobile" v-if="isMobile == 'true' && sumPrice > 0">
          <div class="row">
            <p class="row-name">상품가격</p>
            <p class="row-content">{{ comma(sumPrice) }}원</p>
          </div>
          <div class="row">
            <p class="row-name">배송비</p>
            <p class="row-content">{{ comma(deliveryPrice) }}원</p>
          </div>
          <div class="line-a10"></div>
          <div class="row">
            <p class="row-name">주문금액</p>
            <p class="row-content yellow">{{ comma(totalPrice) }}원</p>
          </div>
        </div>

        <div class="no-item" v-if="sumPrice == 0">
          <p>주문 상품이 없습니다.</p>
        </div>
      </div>
      <div class="basket-payment">
        <div class="block-title"><p>결제 방법</p></div>
        <div class="paymethod">
          <!-- <label
            class="checkbox-normal"
            v-for="(x, i) in isChkPayment"
            :key="i"
          >
            <input
              type="checkbox"
              v-model="x.isActive"
              @click="checkPayment(x.cd)"
            /><span class="checkmark" :class="x.isActive ? 'on' : ''"></span>
            {{ x.name }}
          </label> -->
          <div
            v-for="(x, i) in isChkPayment"
            :key="i"
            @click="checkPayment(x.cd)"
            class="select-btn-box"
            :class="x.isActive ? 'active' : ''"
          >
            {{ x.name }}
          </div>

          <!-- <div
            @click="paymentType = 'CARD'"
            class="select-btn-box"
            :class="paymentType == 'CARD' ? 'active' : ''"
          >
            신용카드
          </div>
          <div
            @click="paymentType = 'DIRECT'"
            class="select-btn-box"
            :class="paymentType == 'DIRECT' ? 'active' : ''"
          >
            계좌 이체
          </div>
          <div
            @click="paymentType = 'TOSSPAY'"
            class="select-btn-box"
            :class="paymentType == 'TOSSPAY' ? 'active' : ''"
          >
            토스 페이
          </div> -->
        </div>
      </div>
    </div>
    <div class="basket-bottom">
      <p class="agree" v-if="isMobile == 'false'">
        위 주문 내용을 확인하였으며, 회원 본인은 개인정보 이용 및 제공 및 결제에
        동의합니다.
      </p>
      <div class="basket-bottom-btns" @click="paymentPayple()">
        <p class="basket-bottom-btns-btn">결제하기</p>
      </div>
    </div>
  </div>
  <div class="popup-direct" v-if="isPopup">
    <div class="popup-direct-background"></div>
    <div class="popup-direct-wrap">
      <div class="close" @click="isPopup = false">
        <img src="@/assets/icon/ic-popup-close.svg" />
      </div>
      <div class="top">
        <p class="square"></p>
        <p class="title">계좌이체</p>
      </div>
      <div class="info">
        <div class="info-wrap">
          <div class="input">
            <div class="space-between">
              <p class="input-item">계좌번호</p>
            </div>
            <div class="input-bank">
              <input
                type="text"
                class="txt"
                value="기업은행   213-104908-04-010"
                readonly="true"
              />
            </div>
          </div>
          <div class="input">
            <p class="input-item">입금 예금주 명 <span>*</span></p>
            <div class="input-wrap">
              <input
                type="text"
                v-model="direct.bankUserName"
                placeholder="예금주 명을 입력해주세요."
              />
            </div>
          </div>
          <div class="input">
            <p class="input-item">세금계산서 <span>*</span></p>
            <div class="">
              <SelectBoxRadius
                :data="calculateType"
                :filterType="'calculateType'"
                @selectData="selectData"
                :selectData="'발행 안함'"
              />
              <!-- <input type="text" v-model="designerInfo.representName" /> -->
            </div>
          </div>
          <div class="add-info">
            <p>계산서 발행 요청 시, 아래 내용을 입력해주세요.</p>
          </div>
          <div class="input">
            <p class="input-item">사업자 명</p>
            <div
              class="input-wrap"
              :class="
                calculateType.filter((e) => e.isActive)[0].cd == '0'
                  ? 'disabled'
                  : ''
              "
            >
              <input
                type="text"
                v-model="direct.compName"
                :disabled="calculateType.filter((e) => e.isActive)[0].cd == '0'"
                placeholder="사업자 명을 입력해주세요."
              />
            </div>
          </div>
          <div class="input">
            <p class="input-item">사업자등록번호</p>
            <div
              class="input-wrap"
              :class="
                calculateType.filter((e) => e.isActive)[0].cd == '0'
                  ? 'disabled'
                  : ''
              "
            >
              <input
                type="text"
                v-model="direct.compNum"
                :disabled="calculateType.filter((e) => e.isActive)[0].cd == '0'"
                placeholder="사업자등록번호를 입력해주세요."
              />
            </div>
          </div>
          <div class="input">
            <p class="input-item">대표자 명</p>
            <div
              class="input-wrap"
              :class="
                calculateType.filter((e) => e.isActive)[0].cd == '0'
                  ? 'disabled'
                  : ''
              "
            >
              <input
                type="text"
                v-model="direct.representName"
                :disabled="calculateType.filter((e) => e.isActive)[0].cd == '0'"
                placeholder="대표자 명을 입력해주세요."
              />
            </div>
          </div>
          <div class="input">
            <p class="input-item">업태</p>
            <div
              class="input-wrap"
              :class="
                calculateType.filter((e) => e.isActive)[0].cd == '0'
                  ? 'disabled'
                  : ''
              "
            >
              <input
                type="text"
                v-model="direct.bizType"
                :disabled="calculateType.filter((e) => e.isActive)[0].cd == '0'"
                placeholder="업태를 입력해주세요."
              />
            </div>
          </div>
          <div class="input">
            <p class="input-item">종목</p>
            <div
              class="input-wrap"
              :class="
                calculateType.filter((e) => e.isActive)[0].cd == '0'
                  ? 'disabled'
                  : ''
              "
            >
              <input
                type="text"
                v-model="direct.bizClass"
                :disabled="calculateType.filter((e) => e.isActive)[0].cd == '0'"
                placeholder="종목을 입력해주세요."
              />
            </div>
          </div>
          <div class="input">
            <p class="input-item">사업장 주소</p>
            <div
              class="input-wrap"
              :class="
                calculateType.filter((e) => e.isActive)[0].cd == '0'
                  ? 'disabled'
                  : ''
              "
            >
              <input
                type="text"
                v-model="direct.compAddress"
                :disabled="calculateType.filter((e) => e.isActive)[0].cd == '0'"
                placeholder="사업장 주소를 입력해주세요."
              />
            </div>
          </div>
          <!-- <div class="input">
            <p class="input-item">상세 주소</p>
            <div
              class="input-wrap"
              :class="
                calculateType.filter((e) => e.isActive)[0].cd == '0'
                  ? 'disabled'
                  : ''
              "
            >
              <input
                type="text"
                v-model="direct.compAddressDetail"
                :disabled="calculateType.filter((e) => e.isActive)[0].cd == '0'"
              />
            </div>
          </div> -->
          <div class="input">
            <p class="input-item">계산서 이메일</p>
            <div
              class="input-wrap"
              :class="
                calculateType.filter((e) => e.isActive)[0].cd == '0'
                  ? 'disabled'
                  : ''
              "
            >
              <input
                type="text"
                v-model="direct.taxEmail"
                :disabled="calculateType.filter((e) => e.isActive)[0].cd == '0'"
                placeholder="계산서 이메일을 입력해주세요."
              />
            </div>
          </div>
        </div>
      </div>
      <div class="calc-bottom">
        <div class="calc-bottom-btns">
          <p class="calc-bottom-btns-btn active" @click="paymentDirect()">
            주문하기
          </p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="this.isComplete" class="modal">
    <div class="modal-background"></div>
    <div class="modal-complete">
      <div class="modal-complete-body">
        <p class="title">주문이 완료되었습니다.</p>
        <p class="quest mt-20">
          결제 확인은 1~2일이 소요되며,<br />
          확인 완료 시, 알림톡 또는 문자로 연락 드립니다.
        </p>
      </div>
      <div class="modal-complete-bottom">
        <p class="btns-one pointer" @click="gotoPage('/mall/buylist')">확인</p>
      </div>
    </div>
  </div>
</template>
<script>
import { loadTossPayments } from "@tosspayments/payment-sdk";
export default {
  name: "CompDesignmaterialBuy",
  props: {
    basketItem: {
      type: Object,
    },
  },
  data() {
    return {
      d: {},
      i: [],
      mallNo: "",
      regImgUrl: "",
      regImgName: "",
      detailData: {},
      addItem: [],
      sumPrice: 0,
      sumCount: 0,
      deliveryPrice: 0,
      totalPrice: 0,
      paymentType: "",
      userInfo: {},
      itemDetailInfo: [],
      paymentData: {},
      isChkAll: false,
      isChkItem: [],
      color: [
        {
          index: 0,
          cd: "",
          name: "[필수] 1절 이상 주문 가능합니다.",
          isActive: true,
        },
      ],
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      // get getBuyItem() {
      //   return this.$store.state.meta.mall.materialBuyItem;
      // },
      get isMobile() {
        return localStorage.getItem("isMobile");
      },
      getBuyItem: this.$store.state.meta.mall.materialBuyItem,
      buyItem: [],
      isPopup: false,
      isComplete: false,
      direct: {},
      calculateType: [
        { cd: "0", name: "발행 안함", isActive: true },
        { cd: "1", name: "발행 요청", isActive: false },
      ],
      isChkPayment: [
        // { cd: "1", value: "TOSSPAY", name: "토스페이", isActive: false },
        { cd: "2", value: "DIRECT", name: "계좌이체", isActive: false },
        { cd: "3", value: "CARD", name: "신용/체크카드", isActive: false },
      ],
      checkCount: 0,
      itemInfoData: [],
    };
  },
  which: {
    basketItem(newVal) {
      console.log(newVal);
    },
    addItem(newVal) {
      console.log(newVal);
    },
  },
  beforeUnmount() {
    // this.$store.state.meta.mall.materialBuyItem = [];
  },
  unmounted() {
    // this.$store.state.meta.mall.materialBuyItem = [];
    // window.removeEventListener("beforeunload", this.handleLeavePage);
    localStorage.removeItem("buyItem");
  },
  mounted() {
    // window.addEventListener("beforeunload", this.handleLeavePage);
    this.buyItem = this.getBuyItem;
    this.addItem = this.buyItem;
    this.checkAll();
    // this.$store.state.meta.mall.materialBuyItem = [];
    if (this.compType == "1") {
      this.$API.mypage.getDesignerInfo(this.userId).then((res) => {
        this.userInfo = res.data.body;
      });
    }
    if (this.compType == "2") {
      this.$API.mypage.getFactoryInfo(this.userId).then((res) => {
        this.userInfo = res.data.body;
      });
    }
    this.direct.calculateType = "0";
    this.loadPaypleScript();
    // this.removeBasketItem();
  },
  methods: {
    isPageRefresh() {
      // 새로고침 감지 로직
      // 예: sessionStorage를 사용하여 새로고침 여부 확인
      return true;
    },
    handleLeavePage(event) {
      event.preventDefault();
      event.returnValue = "";
      if (!this.isPageRefresh()) {
        // 새로고침을 제외한 페이지 이탈 시 실행할 코드
        this.$store.state.meta.mall.materialBuyItem = [];
      } else {
        console.log("새로고침");
        console.log(this.$store.state.meta.mall.materialBuyItem);
      }
    },
    checkAll() {
      this.isChkAll = !this.isChkAll;
      this.addItem = this.buyItem;
      for (let i = 0; i < this.addItem.length; i++) {
        let isChkItem = [];
        for (let j = 0; j < this.addItem[i].item?.length; j++) {
          isChkItem.push(false);
        }
        this.isChkItem[i] = isChkItem;
      }

      for (let i = 0; i < this.addItem.length; i++) {
        for (let j = 0; j < this.addItem[i].item?.length; j++) {
          this.totalSum(i, j);
        }
      }
    },
    checkPayment(cd) {
      this.isChkPayment.forEach((e) => {
        e.isActive = false;
        if (e.cd == cd) {
          e.isActive = true;
        }
      });
    },
    getAddress() {
      new window.daum.Postcode({
        oncomplete: (res) => {
          this.userInfo.compAddress = res.roadAddress;
        },
      }).open();
    },
    numFocusout(index, itemIndex, count) {
      if (count < this.addItem[index].item[itemIndex].minCount) {
        this.addItem[index].item[itemIndex].count =
          this.addItem[index].item[itemIndex].minCount;
      }
      this.inputItem(
        index,
        itemIndex,
        this.addItem[index].item[itemIndex].count
      );
    },
    upItem(index, itemIndex) {
      this.addItem = this.buyItem;
      this.addItem[index].item[itemIndex].count++;

      if (
        this.addItem[index].item[itemIndex].detailData.unitPrice?.length > 0
      ) {
        let itemUnitCost = 0;
        for (const unitPrice of this.addItem[index].item[itemIndex].detailData
          .unitPrice) {
          if (unitPrice.unit <= this.addItem[index].item[itemIndex].count) {
            itemUnitCost = unitPrice.price;
            this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
            break;
          } else {
            itemUnitCost =
              this.addItem[index].item[itemIndex].detailData.itemUnitCost;
          }
        }
        this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
      }

      this.sum();
      this.checkSum(index, itemIndex);
    },
    inputItem(index, itemIndex, count) {
      this.addItem = this.buyItem;
      this.addItem[index].item[itemIndex].count = count;

      if (
        this.addItem[index].item[itemIndex].detailData.unitPrice?.length > 0
      ) {
        let itemUnitCost = 0;
        for (const unitPrice of this.addItem[index].item[itemIndex].detailData
          .unitPrice) {
          if (unitPrice.unit <= this.addItem[index].item[itemIndex].count) {
            itemUnitCost = unitPrice.price;
            this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
            break;
          } else {
            itemUnitCost =
              this.addItem[index].item[itemIndex].detailData.itemUnitCost;
          }
        }
        this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
      }

      this.sum();
      this.checkSum(index, itemIndex);
    },
    downItem(index, itemIndex) {
      if (this.addItem[index].item[itemIndex].count > 0) {
        this.addItem = this.buyItem;
        if (
          this.addItem[index].item[itemIndex].count <=
          this.addItem[index].item[itemIndex].minCount
        ) {
          // this.removeItem(index, itemIndex);
        } else {
          this.addItem[index].item[itemIndex].count--;
        }

        if (
          this.addItem[index].item[itemIndex].detailData.unitPrice?.length > 0
        ) {
          let itemUnitCost = 0;
          for (const unitPrice of this.addItem[index].item[itemIndex].detailData
            .unitPrice) {
            if (unitPrice.unit <= this.addItem[index].item[itemIndex].count) {
              itemUnitCost = unitPrice.price;
              this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
              break;
            } else {
              itemUnitCost =
                this.addItem[index].item[itemIndex].detailData.itemUnitCost;
            }
          }
          this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
        }

        this.sum();
        this.checkSum(index, itemIndex);
      }
    },
    removeItem(index, itemIndex) {
      this.addItem = this.basketItem;
      this.addItem[index].item.splice(itemIndex, 1);
      this.sum();
    },
    removeBasket(index) {
      this.addItem = this.buyItem;
      this.addItem.splice(index, 1);
      this.isChkItem.splice(index, 1);
      this.checkCount--;
      this.sum();
    },
    selectData(data, type) {
      this.calculateType.forEach((e) => {
        e.isActive = false;
        if (e.cd == data.cd) e.isActive = true;
      });
      this.direct.calculateType = data.cd;
    },
    sum() {
      let sumPrice = 0;
      let deliveryPrice = 0;
      this.addItem.forEach((e, i) => {
        sumPrice +=
          e.count *
          (Number(this.detailData.itemUnitCost) + Number(e.addUnitCost));
        // deliveryPrice += e.deliveryPrice;
      });
      this.sumPrice = sumPrice;
      // this.deliveryPrice = deliveryPrice;
      this.totalPrice = sumPrice + this.deliveryPrice;
    },
    rowSum(rowData) {
      let rowSum = 0;
      // rowData.item.forEach((e) => {
      //   rowSum += e.count * (e.itemUnitCost * 1 + e.addUnitCost * 1);
      // });
      rowSum +=
        rowData.count * (rowData.itemUnitCost * 1 + rowData.addUnitCost * 1);
      return this.comma(rowSum);
    },
    totalSum(index, itemIndex) {
      this.isChkItem[index][itemIndex] = !this.isChkItem[index][itemIndex];
      if (!this.isChkItem[index][itemIndex]) {
        this.isChkAll = false;
      }
      this.checkSum(index, itemIndex);
      let checkCount = 0;
      this.isChkItem.forEach((e) => {
        e ? checkCount++ : "";
      });
      this.checkCount = checkCount;
    },
    checkSum(index, itemIndex) {
      let sumPrice = 0;
      let deliveryPrice = 0;
      this.addItem.forEach((e, i) => {
        if (this.isChkItem[i].filter((c) => c).length > 0) {
          e.item.forEach((ei, j) => {
            if (this.isChkItem[i][j]) {
              sumPrice +=
                ei.count * (Number(ei.itemUnitCost) + Number(ei.addUnitCost));
            }
          });
          deliveryPrice += Number(this.addItem[i].deliveryPrice);
        }
      });
      this.sumPrice = sumPrice;
      this.deliveryPrice = deliveryPrice;
      this.totalPrice = sumPrice + deliveryPrice;
    },
    payment() {
      console.log(this.isChkPayment);
      if (!this.userInfo.userName) {
        this.showToast("받는 사람을 입력해주세요.");
        return;
      } else if (!this.userInfo.telNum) {
        this.showToast("연락처를 입력해주세요.");
        return;
      } else if (!this.userInfo.compAddress) {
        this.showToast("배송 주소를 입력해주세요.");
        return;
      } else if (this.isChkPayment.filter((e) => e.isActive).length > 0) {
        this.paymentType = this.isChkPayment.filter((e) => e.isActive)[0].value;
      } else {
        this.showToast("결제방법을 선택해주세요.");
        return;
      }

      if (this.sumPrice > 0) {
        let itemDetail = "";
        let itemInfoData = [];
        this.addItem.forEach((e, i) => {
          let itemDetailInfo = [];
          let paymentAmount = 0;
          let totPrice = 0;
          let sumPrice = 0;
          let deliveryPrice = 0;
          itemDetail = "";
          if (this.isChkItem[i]) {
            e.item.forEach((ei, j) => {
              if (ei.count > 0) {
                if (j > 0 && itemDetail != "") itemDetail += " / ";
                itemDetail += ei.itemOptionName + ":" + ei.count + "개";
                paymentAmount =
                  Number(ei.itemUnitCost) + Number(ei.addUnitCost);
                totPrice = ei.count * paymentAmount;
                sumPrice += totPrice;
                itemDetailInfo.push({
                  quantity: ei.count,
                  addUnitCost: ei.addUnitCost,
                  paymentAmount: paymentAmount,
                  totPrice: totPrice,
                  itemName: ei.itemName,
                  itemOptionName: ei.itemOptionName,
                  itemColorCode: ei.cd,
                });
              }
            });
            if (sumPrice > 0) {
              deliveryPrice += Number(e.deliveryPrice);

              let itemInfo = {
                mallNo: e.mallNo,
                itemName: e.itemName,
                itemDetail: itemDetail,
                paymentAmount: sumPrice + deliveryPrice,
                itemDetailInfo: itemDetailInfo,
              };
              itemInfoData.push(itemInfo);
            }
          }
        });

        this.itemInfoData = itemInfoData;

        if (this.paymentType == "DIRECT") {
          this.isPopup = true;
        } else if (this.paymentType == "") {
          this.showToast("결제 방법을 선택해주세요.");
        } else {
          this.$API.payment
            .createTossMallBasket(
              this.userId,
              this.mallNo,
              this.detailData.itemName,
              this.totalPrice,
              this.paymentType,
              this.itemInfoData,
              this.userInfo.userName,
              this.userInfo.telNum,
              this.userInfo.compAddress,
              this.userInfo.compAddressDetail,
              this.userInfo.deliveryMemo
            )
            .then((res) => {
              if (res.data.resultCode == "10000") {
                let d = res.data.body;
                loadTossPayments(d.clientKey)
                  .then((tossPayments) => {
                    tossPayments.requestPayment(this.paymentType, {
                      // 결제 수단 파라미터
                      amount: d.paymentAmount,
                      orderId: d.orderId,
                      orderName: d.itemName,
                      customerName: sessionStorage.getItem("userName"),
                      successUrl: d.successUrl,
                      failUrl: d.failUrl,
                    });
                  })
                  .then(() => {
                    this.removeBasketItem();
                  });
              } else {
                this.setMsgPopup(
                  true,
                  "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                );
              }
            });
        }
      } else {
        this.showToast("구매하실 상품을 선택해주세요.");
      }
    },
    paymentPayple() {
      console.log(this.isChkPayment);
      if (!this.userInfo.userName) {
        this.showToast("받는 사람을 입력해주세요.");
        return;
      } else if (!this.userInfo.telNum) {
        this.showToast("연락처를 입력해주세요.");
        return;
      } else if (!this.userInfo.compAddress) {
        this.showToast("배송 주소를 입력해주세요.");
        return;
      } else if (this.isChkPayment.filter((e) => e.isActive).length > 0) {
        this.paymentType = this.isChkPayment.filter((e) => e.isActive)[0].value;
      } else {
        this.showToast("결제방법을 선택해주세요.");
        return;
      }

      if (this.sumPrice > 0) {
        let itemDetail = "";
        let itemInfoData = [];
        this.addItem.forEach((e, i) => {
          let itemDetailInfo = [];
          let paymentAmount = 0;
          let totPrice = 0;
          let sumPrice = 0;
          let deliveryPrice = 0;
          itemDetail = "";
          if (this.isChkItem[i]) {
            e.item.forEach((ei, j) => {
              if (ei.count > 0) {
                if (j > 0 && itemDetail != "") itemDetail += " / ";
                itemDetail += ei.itemOptionName + ":" + ei.count + "개";
                paymentAmount =
                  Number(ei.itemUnitCost) + Number(ei.addUnitCost);
                totPrice = ei.count * paymentAmount;
                sumPrice += totPrice;
                itemDetailInfo.push({
                  quantity: ei.count,
                  addUnitCost: ei.addUnitCost,
                  paymentAmount: paymentAmount,
                  totPrice: totPrice,
                  itemName: ei.itemName,
                  itemOptionName: ei.itemOptionName,
                  itemColorCode: ei.cd,
                });
              }
            });
            if (sumPrice > 0) {
              deliveryPrice += Number(e.deliveryPrice);

              let itemInfo = {
                mallNo: e.mallNo,
                itemName: e.itemName,
                itemDetail: itemDetail,
                paymentAmount: sumPrice + deliveryPrice,
                itemDetailInfo: itemDetailInfo,
              };
              itemInfoData.push(itemInfo);
            }
          }
        });

        this.itemInfoData = itemInfoData;

        if (this.paymentType == "DIRECT") {
          this.isPopup = true;
        } else if (this.paymentType == "") {
          this.showToast("결제 방법을 선택해주세요.");
        } else {
          this.$API.payment
            .createPaypleMallBasket(
              this.userId,
              this.mallNo,
              this.detailData.itemName,
              this.totalPrice,
              this.paymentType,
              this.itemInfoData,
              this.userInfo.userName,
              this.userInfo.telNum,
              this.userInfo.compAddress,
              this.userInfo.compAddressDetail,
              this.userInfo.deliveryMemo
            )
            .then((res) => {
              console.log(res);
              if (res.data.resultCode == "10000") {
                let d = res.data.body;
                this.paymentData = d;
                let obj = {};
                // obj.clientKey = "bXFwRzgxVzJiTUdVeHViYnNId2xrUT09";
                console.log(this.userInfo.userName);
                obj.PCD_BUYER_NAME = this.userInfo.userName;
                obj.PCD_PAY_TYPE = "card";
                obj.PCD_PAY_WORK = "PAY";
                obj.PCD_CARD_VER = "02";
                obj.PCD_PAY_GOODS = d.itemName;
                // obj.PCD_PAY_TOTAL = 100;
                obj.PCD_PAY_TOTAL = d.paymentAmount;
                obj.PCD_PAY_URL = d.returnUrl;
                obj.PCD_AUTH_KEY = d.authKey;

                // obj.PCD_RST_URL =
                //   "/custom/orderResult/" + paymentData.orderNum;
                // obj.callbackFunction = this.gotoPage(
                //   "/custom/orderResult/" + paymentData.successUrl
                // );

                obj.callbackFunction = this.getResult;

                window.PaypleCpayAuthCheck(obj);
                // loadTossPayments(d.clientKey)
                //   .then((tossPayments) => {
                //     tossPayments.requestPayment(this.paymentType, {
                //       // 결제 수단 파라미터
                //       amount: d.paymentAmount,
                //       orderId: d.orderId,
                //       orderName: d.itemName,
                //       customerName: sessionStorage.getItem("userName"),
                //       successUrl: d.successUrl,
                //       failUrl: d.failUrl,
                //     });
                //   })
                //   .then(() => {
                //     this.removeBasketItem();
                //   });
              } else {
                this.setMsgPopup(
                  true,
                  "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                );
              }
            });
        }
      } else {
        this.showToast("구매하실 상품을 선택해주세요.");
      }
    },
    getResult(res) {
      console.log(res);
      console.log(this.paymentData);
      if (res.PCD_PAY_RST === "success") {
        const payResult = res;

        this.gotoUrl(this.paymentData.successUrl);
      } else {
        // 결제 실패일 경우 알림 메시지
        window.alert(res.PCD_PAY_MSG);
      }
    },
    paymentDirect() {
      if (this.totalPrice > 0) {
        this.$API.payment
          .createTossMallBasketDirect(
            this.userId,
            this.mallNo,
            this.detailData.itemName,
            this.totalPrice,
            this.paymentType,
            this.itemInfoData,
            this.userInfo.userName,
            this.userInfo.telNum,
            this.userInfo.compAddress,
            this.userInfo.compAddressDetail,
            this.userInfo.deliveryMemo,
            this.direct
          )
          .then((res) => {
            if (res.data.resultCode == "10000") {
              let d = res.data.body;

              this.removeBasketItem();
              this.isComplete = true;
            } else {
              this.setMsgPopup(
                true,
                "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
              );
            }
          });
      } else {
        this.showToast("구매하실 상품을 선택해주세요.");
      }
    },
    removeBasketItem() {
      let removeData = [];
      this.addItem.forEach((e, i) => {
        if (this.isChkItem[i]) {
          let itemOptionDtlNo = [];
          e.item.forEach((ei, j) => {
            itemOptionDtlNo.push(ei.itemOptionDtlNo);
          });
          removeData.push({
            mallNo: e.mallNo,
            itemOptionDtlNo: itemOptionDtlNo,
          });
        }
      });

      let basketItem = JSON.parse(sessionStorage.getItem("basketItem"));
      for (var i = basketItem.length - 1; i >= 0; i--) {
        const removeList = removeData.filter(
          (rd) => basketItem[i].mallNo == rd.mallNo
        );
        if (removeList.length > 0) {
          for (var j = basketItem[i].item.length - 1; j >= 0; j--) {
            if (
              removeList[0].itemOptionDtlNo.indexOf(
                basketItem[i].item[j].itemOptionDtlNo
              ) > -1
            ) {
              basketItem[i].item.splice(j, 1);
            }
          }
        }

        if (basketItem[i].item.length == 0) {
          basketItem.splice(i, 1);
        }
      }
      sessionStorage.setItem("basketItem", JSON.stringify(basketItem));
    },
  },
};
</script>

<style lang="scss" scoped>
// .material {
//   border-right: 1px solid $neutral-10;
//   border-left: 1px solid $neutral-10;
//   padding: 40px 24px 24px;
//   @media (max-width: 520px) {
//     padding: 40px 20px;
// }
.basket {
  padding: 40px 0 60px;
  max-width: 900px;
  width: 100%;
  // padding: 40px;
  display: flex;
  flex-direction: column;
  background: var(
    --Angular_5,
    conic-gradient(
      from 180deg at 49.66% 51.85%,
      #f9f9f9 0deg,
      #fffbf2 72.0000010728836deg,
      #f9f9f9 144.0000021457672deg,
      #fffbf2 216.00000858306885deg,
      #f9f9f9 288.0000042915344deg
    )
  );
  @media (max-width: 520px) {
    padding: 40px 20px 40px;
  }
  .top {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      span {
        font-size: 20px;
      }
    }
  }
  .block-title {
    padding: 2px 0;
    display: flex;
    p {
      height: 26px;
      border-bottom: 1px solid $primary-50;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &-top {
    .check-all {
      padding-bottom: 20px;
      display: flex;
      gap: 8px;
      border-bottom: 1px solid $neutral-10;
      color: $neutral-60;
      font-size: 15px;
      font-weight: 400;
      line-height: 140%;
    }
    .check-content {
      padding: 40px 0;
      border-bottom: 1px solid $neutral-10;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &-body {
        display: flex;
        flex-direction: column;
        gap: 40px;
        &-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .list {
            display: flex;
            flex-direction: column;
            gap: 40px;
            border-right: 1px solid $neutral-10;
            &-wrap {
              display: flex;
              align-items: center;
              .item-name {
                display: flex;
                align-items: center;
                gap: 20px;
                width: 426px;
                font-size: 14px;
                font-weight: 500;
                line-height: 140%;
                .img {
                  width: 60px;
                  height: 60px;
                  border-radius: 4px;
                }
                .name {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 140%; /* 19.6px */
                  .option-name {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                  }
                }
                p {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                }
                span {
                  cursor: pointer;
                  font-size: 16px;
                  color: $neutral-30;
                }
              }
              .check-updown {
                display: flex;
                align-items: center;
                gap: 40px;
                width: 186px;
                height: 60px;
                border-right: 1px solid $neutral-10;
                span {
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  color: $neutral-30;
                }
              }
              .check-price {
                display: flex;
                justify-content: center;
                width: 120px;
              }
            }
          }
          .check-delivery {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 149px;
          }
        }
      }
    }
    .item-info {
      padding: 24px 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      width: 100%;
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $neutral-30;
        color: $neutral-0;

        span {
          font-size: 16px;
        }
      }
      .row {
        display: flex;
        align-items: center;
        gap: 8px;
        &-name {
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
        }
        &-content {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          &.yellow {
            color: $primary-60;
          }
        }
      }
    }
    .item-info-mobile {
      padding: 40px 60px 0 60px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $neutral-30;
        color: $neutral-0;

        span {
          font-size: 16px;
        }
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        &-name {
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
        }
        &-content {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          &.yellow {
            color: $primary-60;
          }
        }
      }
    }
    .no-item {
      padding: 40px 0;
      color: $neutral-60;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  &-body {
    padding: 24px;
    background-color: $neutral-0;
    border-radius: 8px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    @media (max-width: 520px) {
      padding: 20px;
    }
    .delivery-info {
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      &.mb {
        gap: 20px;
      }
      .colomn {
        display: flex;
        align-items: center;
        position: relative;
        height: 33px;
        &-name {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          span {
            color: $primary-60;
          }
        }
        &-input {
          position: absolute;
          left: 130px;
          width: calc(100% - 130px);
          padding: 6px 12px;
          border-radius: 4px;
          border-bottom: 1px solid $neutral-20;
          border-left: 1px solid $neutral-20;
          input {
            width: 100%;
          }
        }
      }
      .row {
        display: flex;
        &-wrap {
          position: relative;
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 20px;
          &:last-child {
            padding-left: 40px;
            border-left: 1px solid $primary-20;
            .row-column-input {
              left: 160px;
            }
          }
        }
        &-column {
          display: flex;
          align-items: center;
          height: 33px;

          &-name {
            font-size: 15px;
            font-weight: 500;
            line-height: 140%;
            span {
              color: $primary-60;
            }
          }
          &-input {
            position: absolute;
            left: 130px;
            width: calc(100% - 160px);
            // width: 415px;
            padding: 6px 12px;
            border-radius: 4px;
            border-bottom: 1px solid $neutral-20;
            border-left: 1px solid $neutral-20;
            input {
              width: 100%;
            }
          }
        }
      }

      .colomn-mb {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &-name {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          span {
            color: $primary-60;
          }
        }
        &-input {
          width: 100%;
          padding: 6px 12px;
          border-radius: 4px;
          border-bottom: 1px solid $neutral-20;
          border-left: 1px solid $neutral-20;
          input {
            width: 100%;
          }
        }
      }
    }
  }

  &-order {
    margin-top: 20px;
    padding: 24px;
    border-radius: 8px;
    background-color: $neutral-0;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
  }
  &-payment {
    margin-top: 20px;
    padding: 24px;
    border-radius: 8px;
    background-color: $neutral-0;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    .paymethod {
      margin-top: 28px;
      display: flex;
      // flex-wrap: wrap;
      // grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      width: 100%;

      @media (max-width: 520px) {
        flex-direction: column;
      }
      .select-btn-box {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 60px;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: $neutral-80;
        border: 1px solid $neutral-20;
        border-radius: 4px;
        span {
          font-weight: 400;
          color: $neutral-60;
        }
        &.active {
          color: $primary-50;
          border: 1px solid $primary-50;
          span {
            color: $primary-50;
          }
        }
      }
    }
  }
  .agree {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px 0 60px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  &-bottom {
    @media (max-width: 520px) {
      margin-top: 40px;
    }
    &-btns {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $neutral-0;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        background-color: $primary-50;
      }
    }
  }
}
.popup {
  position: relative;
  &-direct {
    &-background {
      background: rgba(0, 0, 0, 0.35);
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 500;
    }
    .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .top {
      padding: 40px 20px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .square {
        width: 8px;
        height: 8px;
        background-color: $primary-50;
      }
      .title {
        margin-left: 8px;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: $primary-80;
      }
    }
    &-wrap {
      position: fixed;
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      max-height: calc(100vh - 170px);
      overflow-y: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      background-color: $neutral-0;
      background: $neutral-0;
      z-index: 530;
      border-radius: 8px;
      .info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;
        padding: 0 20px;
        .load {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: $neutral-30;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
        &-tab {
          margin-top: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 180px;
            height: 36px;
            border-style: solid;
            border-color: $neutral-20;
            &:first-child {
              border-radius: 4px 0 0 4px;
              border-width: 1px 0 1px 1px;
            }
            &:last-child {
              border-radius: 0 4px 4px 0;
              border-width: 1px 1px 1px 0;
            }
            &.active {
              border-radius: 4px;
              color: $neutral-0;
              border-width: 20px;
              border-style: solid;
              background-color: $primary-50;
              border-color: $primary-50;
            }
          }
        }
        &-wrap {
          .add-info {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $primary-20;
            font-size: 15px;
            font-weight: 500;
            line-height: 140%;
          }
          .input {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            input {
              width: 100%;
            }
            &-item {
              font-size: 15px;
              font-weight: 500;
              line-height: 140%;
              span {
                color: $primary-60;
              }
            }
            &-reset {
              padding: 1px 12px;
              color: $neutral-60;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
              border-radius: 4px;
              border: 1px solid $neutral-20;
            }
            &-bank {
              padding: 6px 12px;
              border-radius: 4px;
              border: 1px solid $neutral-20;
              font-size: 15px;
              font-weight: 400;
              line-height: 140%;
            }
            &-wrap {
              display: flex;
              align-items: center;
              padding: 4px 8px;
              background: $neutral-0;
              border-width: 0px 0px 1px 1px;
              border-style: solid;
              border-color: $neutral-20;
              border-radius: 4px;
              height: 32px;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              &.disabled {
                background-color: $neutral-3;
              }
              input {
                width: 100%;
                height: 100%;
                background: $neutral-0;
                font-size: 15px;
                font-weight: 400;
                line-height: 23px;
                &:-webkit-autofill {
                  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
                }
                &:hover {
                  background: #fffbf2;
                }
                &:disabled {
                  background-color: $neutral-3;
                }
              }
            }
          }
        }
      }
      .back-gary {
        background: $neutral-3;
      }
      .calc-bottom {
        position: sticky;
        bottom: 0;
        .terms {
          margin: 0 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 40px;
          padding: 20px 0 40px;
        }
        &-btns {
          display: flex;
          align-items: center;
          background-color: $neutral-50;
          border-radius: 0px 0px 8px 8px;
          color: $neutral-0;
          width: 100%;
          &-btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 40px;
            cursor: pointer;
            &:not(:first-child) {
              margin-left: rem(8px);
            }
            &.active {
              background-color: $primary-50;
              border-radius: 0px 0px 8px 8px;
              &:not(:first-child) {
                border-radius: 0px 0px 8px 0px;
              }
            }
            &.deactive {
              background: $neutral-30;
            }
          }
        }
      }
    }
  }
}
.modal {
  &-complete {
    background-color: $neutral-3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: fixed;
    z-index: 1001;
    left: 50%;
    top: 146px;
    width: calc(100% - 48px);
    max-width: 320px;
    transform: translate(-50%, 0);
    text-align: center;
    color: $neutral-100;
    &-body {
      padding: 32px 20px;
      display: flex;
      flex-direction: column;
      .title {
        @include ellipse();
        color: $neutral-100;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
      .catg {
        color: $neutral-80;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }
      .quest {
        color: $neutral-60;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        span {
          color: $primary-60;
        }
      }
    }
    &-bottom {
      display: flex;
      height: 40px;
      font-weight: 500;
      font-size: 15px;
      line-height: 23px;
      color: $neutral-0;
      .btns-left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $neutral-60;
        border-bottom-left-radius: 8px;
      }
      .btns-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-bottom-right-radius: 8px;
      }
      .btns-one {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}
</style>
