<template>
  <div
    class="card pointer"
    @click="gotoPage(`/search/factorydetail/${d.factoryNo}`)"
  >
    <div class="card-top">
      <div class="card-top-left">
        <!-- <img :src="cardData.bgImg"> -->
        <div
          class="img-area"
          :style="`background-image: url('${d.bgImg}');`"
        ></div>
      </div>
      <div class="card-top-right">
        <div class="card-top-right-title">
          <p class="title bold">{{ d.title }}</p>
          <img
            @click.stop="clickLike()"
            class="pointer"
            :src="
              require(`@/assets/icon/ic-heart-${
                d.isLike ? 'pink' : 'grey'
              }.svg`)
            "
          />
        </div>
        <div class="card-top-right-content Osle_KR_Body_5">
          <div class="row rating-wrap">
            <RatingView
              :val="d.totalAvg ? d.totalAvg?.toFixed(1) : 0"
              :readOnly="true"
            />
            <p class="rating-val">{{ d.totalAvg?.toFixed(1) }}</p>
          </div>
          <div class="row">
            <p class="info">{{ d.process }}</p>
            <p class="info">{{ d.minOrderCount }}</p>
          </div>
          <p class="info">{{ d.fabric }}</p>
          <p class="info">{{ d.address }}</p>
          <p class="info">{{ d.product }}</p>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="represent">
        <span>{{ setRepresentCatg(d) }}</span>
      </div>
      <div class="category">
        <p v-for="(y, i) in d.tagList" :key="i">{{ y.commName }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "factoryView",
  props: {
    cardData: {
      type: Object,
      description: "Card data",
    },
  },
  data() {
    return {
      d: {},
    };
  },
  mounted() {
    this.d = this.cardData;
    this.d.isLike = this.d.like;
  },
  methods: {
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    setRepresentCatg(x) {
      let arr = [];
      if (x.representCatg1) arr.push(x.representCatg1);
      if (x.representCatg2) arr.push(x.representCatg2);
      if (x.representCatg3) arr.push(x.representCatg3);
      if (x.representCatg4) arr.push(x.representCatg4);
      let set = new Set(arr);
      let uniqueArr = [...set];
      return uniqueArr.join(" / ");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: rem(8px);
  background-color: $neutral-0;
  &-top {
    padding: rem(20px) rem(20px) rem(16px);
    display: flex;
    flex-direction: row;
    &-left {
      flex: 1;
      .img-area {
        width: 100%;
        min-width: rem(118px);
        height: 100%;
        border-radius: 7px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &-right {
      flex: 2;
      margin-left: rem(16px);
      &-title {
        display: flex;
        font-size: 18px;
        .title {
          flex: 1;
          @include ellipse();
        }
      }
      &-content {
        margin-top: rem(5px);
        // line-height: 20px;
        .info {
          @include ellipse();
        }
        .row {
          display: flex;
          align-items: center;
          justify-content: center;
          .info {
            flex: 1;
            position: relative;
            &:nth-child(1n) {
              padding-right: rem(12px);
            }
            &:nth-child(2n) {
              padding-left: rem(12px);
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 14px;
                background-color: #c1c1c1;
              }
            }
          }
        }
      }
      .rating-wrap {
        justify-content: flex-start;
        :deep(.rating) {
          img {
            width: 14px;
            height: 14px;
          }
        }
        .rating-val {
          font-weight: 700;
          font-size: 18px;
          color: $neutral-80;
          margin-left: rem(12px);
        }
      }
    }
  }
  &-bottom {
    padding: rem(16px) rem(24px);
    background-color: rgba(242, 242, 242, 0.3);
    font-size: 12px;
    color: $neutral-50;
    flex: 1;
    .represent {
      margin-bottom: 12px;
      font-size: 14px;
      color: $neutral-80;
    }
    .category {
      display: flex;
      flex-wrap: wrap;
      gap: 5px 9px;
    }
  }
}
</style>
