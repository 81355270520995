<template>
  <div
    class="material"
    id="materialCard"
    v-if="isMobile == 'false' && listData?.length > 0"
    :class="{ main: isMain }"
  >
    <div
      class="card-wrap"
      v-for="(x, i) in listData"
      :key="i"
      @click="goDetail(`${x.mallNo}`, `${x.itemName}`)"
    >
      <div class="card">
        <div class="card-top">
          <div class="card-top-img">
            <img :src="x.imgUrl" />
          </div>
        </div>
        <div class="card-body">
          <div class="item-name">
            <p>{{ x.itemName }}</p>
          </div>
          <div class="item-length" v-if="x.itemUnitValue">
            <p>{{ x.itemUnitValue }}</p>
          </div>
          <div class="sale" v-if="x.itemNormalCost">
            <p>할인가</p>
            <p class="exist">{{ comma(x.itemNormalCost) }}원</p>
          </div>
          <div class="price">
            <p>{{ comma(x.itemExposeCost) }}원</p>
            <p class="unit" v-if="x.itemLengthValue > 0">
              ({{ x.itemLengthName }}, {{ comma(x.itemLengthValue) }}원)
            </p>
          </div>
          <div class="delivery" v-if="x.deliveryPrice == 0">
            <span class="material-symbols-outlined"> local_shipping </span>
            무료배송
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- :autoplay="{ delay: 3000, disableOnInteraction: false }" -->
  <!-- :slidesPerView="1.5" -->
  <swiper
    class="material"
    id="materialCard"
    v-else-if="listData?.length > 0"
    :slidesPerView="($store.state.meta.windowWidth - 40) / 226"
    :pagination="{ clickable: true }"
    :loop="true"
    :rewind="true"
    :modules="modules"
    :spaceBetween="20"
  >
    <swiper-slide
      class="card-wrap-swiper"
      v-for="(x, i) in listData"
      :key="i"
      @click="goDetail(`${x.mallNo}`, `${x.itemName}`)"
    >
      <div class="card">
        <div class="card-top">
          <div class="card-top-img">
            <img :src="x.imgUrl" />
          </div>
        </div>
        <div class="card-body">
          <div class="item-name">
            <p>{{ x.itemName }}</p>
          </div>
          <div class="item-length" v-if="x.itemUnitValue">
            <p>{{ x.itemUnitValue }}</p>
          </div>
          <div class="sale" v-if="x.itemNormalCost">
            <p>할인가</p>
            <p class="exist">{{ comma(x.itemNormalCost) }}원</p>
          </div>
          <div class="price">
            <p>{{ comma(x.itemExposeCost) }}원</p>
            <p class="unit" v-if="x.itemLengthValue > 0">
              ({{ x.itemLengthName }}, {{ comma(x.itemLengthValue) }}원)
            </p>
          </div>
          <div class="delivery" v-if="x.deliveryPrice == 0">
            <span class="material-symbols-outlined"> local_shipping </span>
            무료배송
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
  <div v-else-if="!(listData?.length > 0)" class="no-list">
    구매 내역이 없습니다.
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
export default {
  name: "MaterialListView",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    listData: {
      type: Object,
      description: "Card data",
    },
    isMain: {
      type: Boolean,
      description: "main bool",
    },
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  data() {
    return {
      d: {},
      isShowMsg: false,
      index: "",
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  mounted() {
    console.log(this.listData);
    this.d = this.listData;
    this.d.isLike = this.d.like;
  },
  methods: {
    openStatusPopup(index) {
      this.index = index;
      this.isShowMsg = true;
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    bookmark(index, designOrderNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo);
    },
    factoryPin(index, designOrderNo, factoryNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo, factoryNo);
    },
    goDetail(mallNo, itemName) {
      this.$store.state.meta.detailTitle = itemName;
      console.log(this.$store.state.meta.detailTitle);
      console.log(mallNo);
      if (this.userId) {
        this.gotoPage(`/mall/materialdetail/${mallNo}`);
      } else {
        this.$emit("openLogin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.material {
  display: flex;
  // flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
  // background-color: $neutral-3;
  .card-wrap {
    cursor: pointer;
    background-color: $neutral-0;
    &-swiper {
      background-color: $neutral-0;
      position: initial;
      width: 232px;
      margin-bottom: 28px;
      :deep(.swiper-pagination) {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        bottom: 0;
        &-bullet {
          background-color: #fff4db;
          width: 8px;
          height: 8px;
          opacity: 1;
          margin: 0;
          border-radius: 4px;
          &-active {
            background-color: $primary-50;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
  .card {
    padding: 0 8px 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 370px;
    width: 232px !important;
    border-radius: 8px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    @media (max-width: 960px) {
      gap: 10px;
      background-color: $neutral-0;
    }
    &:hover {
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    }
    &-top {
      // max-width: 225px;
      // max-height: 225px;
      position: relative;
      width: 100%;
      &-img {
        display: flex;
        justify-content: center;
        border: 1px solid $neutral-10;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-bottom: 100%; /* 가로 세로 비율을 맞추기 위한 패딩 값 */
        img {
          position: absolute;
          height: 100%;
        }
      }
    }
    &-body {
      padding: 8px;
      display: flex;
      flex-direction: column;
      .item-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        display: flex;
        gap: 8px;
      }
      .item-length {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
      }
      .sale {
        margin-top: 8px;
        display: flex;
        gap: 8px;
        font-size: 13px;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
        color: $primary-60;
        .exist {
          text-decoration: line-through;
          color: $neutral-40;
        }
      }
      .price {
        margin-top: 2px;
        display: flex;
        gap: 4px;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
        color: $primary-60;
        .unit {
          font-size: 12px;
        }
      }
      .unit-cost {
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        @media (max-width: 800px) {
          font-size: 15px;
        }
      }
      .delivery {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        line-height: 140%;
        color: $primary-80;
        span {
          font-size: 12px;
        }
      }
    }
    &:hover {
      border-color: $primary-50;
    }
  }
}

.no-list {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}
</style>
