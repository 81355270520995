<template>
  <div
    class="payment-card"
    v-for="(cardData, i) in paymentList"
    :key="i"
    :class="paymentNo === cardData.paymentNo ? 'selected' : ''"
  >
    <!-- @click="
      isMobile == 'true'
        ? gotoPage(`/payment/list/${cardData.paymentNo}`)
        : paymentDetail(cardData.paymentNo)
    " -->
    <div class="payment-card-wrap">
      <div class="payment-card-wrap">
        <p class="title">
          {{ cardData.factoryName }}
        </p>
        <div class="info">
          <p class="name">결제일</p>
          <p class="value">
            {{ cardData.paymentDate }}
          </p>
        </div>
        <div class="info-wrap">
          <div class="info">
            <p class="name">결제 방법</p>
            <p class="value">
              {{
                cardData.paymethod == "CARD"
                  ? "신용카드"
                  : cardData.paymethod == "TOSSPAY"
                  ? "토스페이"
                  : "계좌이체"
              }}
            </p>
          </div>
          <p class="price">￦ {{ comma(cardData.paymentAmount) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentListCom",
  props: {
    paymentList: {
      type: String,
      description: "Contract Data",
    },
  },
  mounted() {},
  data() {
    return {
      compType: sessionStorage.getItem("compType"),
      isMobile: sessionStorage.getItem("isMobile"),
      paymentNo: "",
    };
  },
  methods: {
    paymentDetail(paymentNo) {
      this.paymentNo = paymentNo;
      this.$emit("paymentDetail", paymentNo);
    },
    moveService(isProgress) {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로
        this.loginCheck();
      } else if (this.$route.query.type === "load") {
        this.moveConfirm();
      } else if (isProgress) {
        this.gotoPage(`/paymentconfirm`);
        this.showToast("의뢰내역을 불러왔습니다.");
      } else if (this.compType == "1") {
        this.gotoPage(`/paymentdetails/${this.cardData.paymentNo}`);
      } else {
        this.gotoPage(`/paymentproposal/${this.cardData.paymentNo}`);
      }
    },
    moveConfirm() {
      this.$API.payment
        .paymentStyleCopy(
          sessionStorage.getItem("userId"),
          this.cardData.paymentNo
        )
        .then(() => {
          this.gotoPage(`/paymentconfirm`);
          this.showToast("의뢰내역을 불러왔습니다.");
        });
    },
    remainingTime(date) {
      try {
        // let dayOfWeek = new Date(date).getDay()
        // let alpha = 2
        // if (dayOfWeek == 5 || dayOfWeek == 6) alpha = 4
        // if (dayOfWeek == 0) alpha = 3
        // let remainingMin = (24*60*alpha) - ((new Date() - new Date(date))/1000/60)
        // return `${Math.floor(remainingMin/60)}시간 ${Math.floor(remainingMin%60)}분`
        return `${date.split(":")[0]}시간 ${date.split(":")[1]}분`;
      } catch {
        return "0분";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-card {
  padding: 0 20px;
  margin-top: 4px;
  &.selected {
    background-color: $neutral-3;
  }
  &-wrap {
    cursor: pointer;
    padding: 16px 0;
    gap: 8px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid $neutral-10;
    &:hover {
      // border-color: $primary-50;
    }
    .title {
      font-size: 15px;
      font-weight: 500;
      line-height: 23px;
      margin-bottom: 12px;
    }
    .price {
      color: $primary-60;
      font-size: 15px;
      font-weight: 500;
      line-height: 23px;
    }
    .info-wrap {
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .info {
      position: relative;
      display: flex;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $neutral-80;
      .name {
        color: $neutral-60;
        font-size: 15px;
        font-weight: 500;
        line-height: 23px;
      }
      .value {
        position: absolute;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        width: 100%;
        left: 120px;
      }
    }
  }
}
.bottom {
  background-color: $neutral-3;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $neutral-80;
}
</style>
