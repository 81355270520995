export const DEFAULT_FIREBASE = {
  CHAT: "ChatRooms",
  MESSAGES: "messages",
  SETTINGS: "settings",
  LIVE: "live",
};

export const CHAT_ROOM_FIELD = {
  DESINGER: "designerId",
  FACTORY: "factoryId",
};

export const READ_FLAG = {
  NOT: 0,
  READ: 1,
};

export const MESSAGE_TYPE = {
  MESSAGE: "message",
  IMAGE: "image",
  FILE: "file",
  ORDER: "order",
  CONTRACT: "contract",
  DESIGNORDER: "designorder",
  PAYMENT: "payment",
};

export const LIVE_CHAT_ROOM_ACTIVATE = {
  CLOSE: 0,
  SHOW: 1,
};

export const LIVE_CHAT_ROOM_INIT = {
  FALSE: 0,
  TRUE: 1,
};

export const CHAT_ROOM_SETTINGS = {
  PIN: {
    DEACTIVATE: 0,
    ACTIVATE: 1,
  },
  ALARM: {
    DEACTIVATE: 0,
    ACTIVATE: 1,
  },
};

export const CONTRACT_TYPE = {
  SUCCESS: "success",
  CANCEL: "cancel",
  FAIL: "fail",
};

export const DESIGN_ORDER_TYPE = {
  PART: "part",
};

export const PAYMENT_TYPE = {
  PAYMENT: "payment",
};
