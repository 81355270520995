<template>
  <div class="size">
    <div class="size-wrap">
      <div class="size-wrap-flex">
        <p class="assort">Assort</p>
        <p class="field" v-for="(x, i) in size" :key="i">
          {{ x.sizeName }}
        </p>
        <p class="sum">합계</p>
      </div>
    </div>
    <div class="size-wrap" v-for="(x, i) in color" :key="i">
      <div class="size-wrap-flex">
        <div class="color">
          <p class="color-img"><img :src="x.fileUrl" /></p>
          <p class="color-name">
            <span>{{ x.colorCode }}</span
            ><span>{{ x.colorName }}</span>
          </p>
        </div>
        <div
          class="input-wrap"
          v-for="(y, j) in colorLength > 0 ? size : ''"
          :key="j"
        >
          <div
            class="check-box"
            v-if="inputType == 'radio'"
            @click="clickCheck(i, j)"
          >
            <SvgImg
              :size="16"
              :d="
                sampleCheck[i][j]
                  ? $store.state.meta.svg.radioOn
                  : $store.state.meta.svg.radioOff
              "
              :color="
                sampleCheck[i][j]
                  ? $store.state.meta.svg.color.primary50
                  : $store.state.meta.svg.color.neutral20
              "
            />
          </div>
          <div
            class="input-box"
            v-else-if="inputType == 'none'"
            :class="{ active: quantity[i][j] > 0 }"
          >
            {{ quantity[i][j] }}
          </div>
          <input
            class="input-box"
            v-else
            v-model="quantity[i][j]"
            placeholder="0"
            @keyup="inputQuantity"
            @focusout="outInput(i, j)"
            :class="{ active: quantity[i][j] > 0 }"
          />
        </div>
        <p class="quantity">{{ sizeQuantity[i] ? sizeQuantity[i] : 0 }}</p>
      </div>
    </div>
    <div class="size-wrap">
      <div class="size-wrap-flex">
        <p class="sum">합계</p>
        <p class="quantity flex1" v-for="(y, j) in size" :key="j">
          {{ colorQuantity[j] ? colorQuantity[j] : 0 }}
        </p>
        <p class="quantity" :class="{ empty: !(totalQuantity > 0) }">
          {{ totalQuantity ? totalQuantity : 0 }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "filterMenuNew",
  props: {
    color: {
      type: Object,
      description: "color list",
    },
    inputType: {
      type: String,
      description: "input",
    },
    value: {
      type: Object,
      description: "input",
    },
  },
  data() {
    return {
      size: [
        { index: 0, cd: "01", sizeName: "XS" },
        { index: 1, cd: "02", sizeName: "S" },
        { index: 2, cd: "03", sizeName: "M" },
        { index: 3, cd: "04", sizeName: "L" },
        { index: 4, cd: "05", sizeName: "XL" },
        { index: 5, cd: "06", sizeName: "2XL" },
        { index: 6, cd: "07", sizeName: "3XL" },
      ],
      showSelectBoxList: false,
      selectDataTxt: "",
      imgList: [],
      imgUrl: "",
      imgDesc: "",
      isAdd: false,
      quantity: [],
      sizeQuantity: [],
      colorQuantity: [],
      totalQuantity: "",
      sampleCheck: [],
      sizeLength: 7,
      colorLength: 0,
      datas: [],
      valData: [],
    };
  },
  watch: {
    color(newVal) {
      console.log(newVal);
      if (this.color?.length > 0) {
        this.colorLength = this.color?.length;
        for (var i = 0; i < this.colorLength; i++) {
          this.quantity.push(["", "", "", "", "", "", ""]);
          this.sampleCheck.push([
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ]);
        }
      }
    },
    value(newVal) {
      console.log(newVal);
      this.valData = newVal;
      this.getValue();
      // <p v-for="(x, i) in value" :key="i">
      //   {{ x.orderSizeNo }},{{ x.orderNo }},{{ x.size }},{{ x.color }},{{
      //     x.quantity
      //   }}
      // </p>
    },
  },
  mounted() {
    // this.sizeLength = this.size.length;
    if (this.color?.length > 0) {
      this.colorLength = this.color?.length;
      for (var i = 0; i < this.colorLength; i++) {
        this.quantity.push(["", "", "", "", "", "", ""]);
        this.sampleCheck.push([
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ]);
      }
    }
    if (this.value) {
      this.valData = this.value;
    }
    if (this.valData) {
      this.getValue();
    }
  },
  methods: {
    getValue() {
      this.valData.forEach((item) => {
        console.log(this.color);
        console.log(item.quantity);
        console.log(item.sampleFlag);

        const sizeIndex = this.size.findIndex((e) => e.cd == item.size);
        const colorIndex = this.color.findIndex(
          (e) => e.fabricColorNo == item.color
        );
        console.log("======");
        console.log(sizeIndex);
        console.log(colorIndex);
        console.log(item.quantity);
        console.log(this.quantity[colorIndex][sizeIndex]);
        if (item.quantity > 0) {
          this.quantity[colorIndex][sizeIndex] = item.quantity;
        }
        if (item.sampleFlag > 0) {
          this.quantity[colorIndex][sizeIndex] = item.sampleFlag;
        }

        this.inputQuantity();
      });
    },
    outInput(i, j) {
      console.log(this.quantity[i][j]);
      this.quantity[i][j] = this.quantity[i][j] > 0 ? this.quantity[i][j] : "";
      console.log(this.quantity[i][j]);
      this.getValue();
      this.inputQuantity();
    },
    inputQuantity() {
      this.sizeQuantity = [];
      this.colorQuantity = [];
      this.datas = [];
      let totalQuantity = 0;
      //컬러 합계 계산
      for (let i = 0; i < this.sizeLength; i++) {
        let colorSum = 0;
        for (let j = 0; j < this.colorLength; j++) {
          if (this.quantity[j][i]) {
            colorSum += Number(this.quantity[j][i]);
          }
        }
        this.colorQuantity.push(colorSum ? colorSum : "");
      }
      //사이즈 합계 계산
      for (let i = 0; i < this.colorLength; i++) {
        let sizeSum = 0;
        for (let j = 0; j < this.sizeLength; j++) {
          if (this.quantity[i][j]) {
            sizeSum += Number(this.quantity[i][j]);
            this.datas.push({
              color: this.color[i].fabricColorNo,
              size: this.size[j].cd,
              quantity: this.quantity[i][j],
            });
          }
        }
        this.sizeQuantity.push(sizeSum ? sizeSum : "");
        totalQuantity += sizeSum;
      }
      this.totalQuantity = totalQuantity ? totalQuantity : "";
      this.$emit("setOrder", this.datas, "order");
    },
    clickCheck(i, j) {
      this.sampleCheck[i][j] = !this.sampleCheck[i][j];

      this.sizeQuantity = [];
      this.colorQuantity = [];
      this.datas = [];
      let totalQuantity = 0;
      //컬러 합계 계산
      for (let i = 0; i < this.sizeLength; i++) {
        let colorSum = 0;
        for (let j = 0; j < this.colorLength; j++) {
          if (this.sampleCheck[j][i]) {
            colorSum += 1;
          }
        }
        this.colorQuantity.push(colorSum ? colorSum : "");
      }
      //사이즈 합계 계산
      for (let i = 0; i < this.colorLength; i++) {
        let sizeSum = 0;
        for (let j = 0; j < this.sizeLength; j++) {
          if (this.sampleCheck[i][j]) {
            sizeSum += 1;
            this.datas.push({
              color: this.color[i].fabricColorNo,
              size: this.size[j].cd,
              quantity: 1,
            });
          }
        }
        this.sizeQuantity.push(sizeSum ? sizeSum : "");
        totalQuantity += sizeSum;
      }
      this.totalQuantity = totalQuantity ? totalQuantity : "";
      console.log(this.colorQuantity);
      console.log(this.sizeQuantity);
      this.$emit("setOrder", this.datas, "sample");
    },
  },
};
</script>

<style lang="scss" scoped>
.size {
  position: relative;
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @media (max-width: 900px) {
    overflow: scroll;
    width: 610px;
  }
  &-wrap {
    width: 792px;
    @media (max-width: 900px) {
      width: 610px;
    }
    &-flex {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 8px;
      font-size: 13px;
      font-weight: 500;
      .assort {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $neutral-0;
        width: 100px;
        height: 36px;
        border-radius: 4px;
        background: $neutral-60;
      }
      .color {
        display: flex;
        gap: 8px;
        width: 100px;
        font-size: 12px;
        font-weight: 400;
        border-radius: 0px 4px 4px 0px;
        border-right: 1px solid $neutral-10;
        &-img {
          width: 20px;
          height: 36px;
          overflow: hidden;
          border-radius: 4px;
          border: 1px solid $neutral-5;
          img {
            height: 36px;
          }
        }
        &-name {
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
      .sum {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $neutral-0;
        width: 100px;
        height: 36px;
        border-radius: 4px;
        background: $primary-50;
      }
      .input-wrap {
        flex: 1;
        display: flex;
        justify-content: center;
        .input-box {
          padding: 10px 20px;
          text-align: end;
          height: 36px;
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          border: 1px solid $neutral-10;
          &.active {
            border: 1px solid $primary-50;
          }
          &:focus {
            border: 1px solid $primary-50;
          }
        }
        .check-box {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          width: 100%;
          border-radius: 4px;
          border: 1px solid var(--Neutral_10, #e6e6e6);
          background: var(--Neutral_00, #fff);
        }
      }
      .check-wrap {
        border: 1px solid $neutral-20;
        border-radius: 4px;
        width: 130px;
      }
      .unit {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        width: 40px;
        height: 36px;
        // color: $neutral-0;
        // background: $neutral-60;
        border: 1px solid $neutral-20;
        border-radius: 4px;
        &-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          border-radius: 14px;
          border: 1px solid $neutral-10;
          &-color {
            width: 24px;
            height: 24px;
            border-radius: 12px;
          }
        }
      }
      .field {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
        height: 36px;
        // color: $neutral-0;
        font-size: 13px;
        font-weight: 500;
        border: 1px solid $neutral-10;
        border-radius: 4px;
        background: $neutral-3;
        @media (max-width: 900px) {
          width: 36px;
        }
      }
      .quantity {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100px;
        height: 36px;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid $primary-20;
        border-radius: 4px;
        &.empty {
          border: 1px solid $secondary-60;
        }
      }
      input {
        color: $neutral-80 !important;
        background: $neutral-0 !important;
      }
    }
    input {
      padding: 8px;
      // background: #ffffff;
      border-width: 0px 0px 1px 1px;
      border-style: solid;
      border-color: $neutral-30;
      border-radius: 4px;
      // width: 100%;
      height: 32px;

      // &:focus {
      //   border-color: $primary-50;
      // }
      // &:hover {
      //   background: #fffbf2;
      // }
    }
    &.row {
      flex-direction: row !important;
    }
    .input-explain {
      font-size: 14px;
      color: $neutral-60;
    }
    &.error input {
      border-color: $secondary-60;
    }

    textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid $neutral-20;
      border-radius: 4px;
      height: 120px;
      resize: none;
      font-size: 14px;
      &::placeholder {
        color: $neutral-30;
      }
    }
  }
}
</style>
