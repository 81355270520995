<template>
  <div class="card elevation-card" @click="gotoPage(`/search/factorydetails/${cardData.factoryNo}`)">
    <div class="card-top"
      :style="{ 'background-image': 'url(' + cardData.bgImg + ')' }"
    >
      <img v-if="cardData.popularType" class="crown" :src="require(`@/assets/icon/ic-crown-${cardData.popularType}.svg`)">
    </div>
    <div class="card-bottom">
      <div class="card-bottom-title">
        <p class="Osle_KR_Heading_6 title bold">{{ cardData.title }}</p>
        <!-- <img @click.stop="updateLike(false)" v-if="like" class="pointer" src="@/assets/icon/ic-heart-pink.svg">
        <img @click.stop="updateLike(true)" v-else class="pointer" src="@/assets/icon/ic-heart-grey.svg"> -->
        <img @click.stop="clickLike()" class="pointer" :src="require(`@/assets/icon/ic-heart-${d.isLike ? 'pink' : 'grey'}.svg`)">
      </div>
      <div class="card-bottom-content Osle_KR_Body_4">
        <div class="row">
          <p class="info">{{ cardData.process }}</p>
          <p class="info">{{ cardData.minOrderCount }}</p>
        </div>
        <div class="row">
          <p class="info">{{ cardData.address }}</p>
          <p class="info">{{ cardData.fabric }}</p>
        </div>
        <p class="info">{{ cardData.product }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cardView',
  props: {
    cardData: {
      type: Object,
      description: "Card data"
    }
  },
  data () {
    return {
      d: {},
    }
  },
  mounted () {
    this.d = this.cardData
    this.d.isLike = this.d.like
  },
  methods: {
    clickLike () {
      if (!sessionStorage.getItem('userId')) { // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back)
        return
      }
      let updateVal = !this.d.isLike
      this.d.isLike = updateVal
      this.$API.search.like(sessionStorage.getItem('userId'), this.d.factoryNo, updateVal ? '1' : '0')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 7px;
    &-top {
      height: rem(184px);
      border-radius: 7px 7px 0 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .crown {
        position: relative;
        top: rem(20px);
        left: rem(17px);
      }
    }
    &-bottom {
      border-radius: 0 0 7px 7px;
      padding: rem(16px) rem(16px) rem(20px);
      &-title {
        display: flex;
        .title {
          flex: 1;
          @include ellipse();
        }
      }
      &-content {
        margin-top: rem(5px);
        .info { @include ellipse(); }
        .row {
          display: flex;
          align-items: center;
          justify-content: center;
          .info {
            flex: 1;
            position: relative;
            &:nth-child(1n) {
              padding-right: rem(12px);
            }
            &:nth-child(2n) {
              padding-left: rem(12px);
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 14px;
                background-color: #C1C1C1;
              }
            }
          }
        }
      }
    }
  }
</style>