<template>
  <div class="payment-detail">
    <div class="payment-detail-top">
      <p class="title">
        {{ paymentData.factoryName }}
      </p>
      <p class="status active" v-if="paymentData.paymentStatus == '2'">결제</p>
      <p class="status" v-if="paymentData.paymentStatus == '3'">취소 중</p>
      <p class="status" v-if="paymentData.paymentStatus == '4'">취소</p>
      <p class="status" v-if="paymentData.paymentStatus == '5'">발행</p>
    </div>
    <div class="payment-detail-content">
      <div class="row">
        <p class="name">결제 금액</p>
        <p class="info">{{ comma(paymentData.paymentAmount) }}원</p>
      </div>
      <div class="row">
        <p class="name">결제일</p>
        <p class="info">{{ paymentData.paymentDate }}</p>
      </div>
      <div class="row">
        <p class="name">결제 방법</p>
        <p class="info">{{ paymentData.paymethod }}</p>
      </div>
      <div class="row">
        <p class="name">지출 증빙</p>
        <p class="info" v-if="paymentData.calculateType == '1'">세금 계산서</p>
        <p class="info" v-if="paymentData.calculateType == '2'">현금 영수증</p>
      </div>
    </div>
  </div>
  <div class="payment-detail-bottom">
    <div class="info" v-if="paymentData.paymentStatus == '2'">
      <div class="title">안내 사항</div>
      <div class="content">
        <span>※</span>
        <p>결제가 완료되고, 영업일 기준 14일 후, 생산자에게 정산됩니다.</p>
      </div>
      <div class="content">
        <span>※</span>
        <p>
          양 사간 거래를 정상적으로 완료될 수 없다고 판단될 경우, 당사자 간
          합의에 따라 결제를 취소할 수 있으며, 결제 후 7일 이내에 신청이
          가능합니다.
        </p>
      </div>
      <div class="content">
        <span>※</span>
        <p>결제 취소는 전액만 가능하며, 부분 취소는 불가합니다.</p>
      </div>
      <div class="content">
        <span>※</span>
        <p>
          결제 취소 시, 공급자(공장)에게 취소 정보가 전달되며, 발주자는 24시간
          내에 취소 회수가 가능합니다.
        </p>
      </div>
      <div class="content">
        <span>※</span>
        <p>결제 취소일시 기준, 24시간 후 자동 결제 취소됩니다.</p>
      </div>
      <div class="content">
        <span>※</span>
        <p>
          취소 금액 환불은 결제 수단에 따라 영업일 기준으로 3일에서 최대 7일까지
          소요될 수 있습니다.
        </p>
      </div>
      <div class="content">
        <span>※</span>
        <p>
          결제 취소 시, 공급자(공장)에게 취소 정보가 전달되며, 발주자는 24시간
          내에 취소 회수가 가능합니다.
        </p>
      </div>
    </div>
    <div class="info" v-if="paymentData.paymentStatus == '3'">
      <div class="title">안내 사항</div>
      <div class="content">
        <span>※</span>
        <p>
          결제 취소 시, 공급자(공장)에게 취소 정보가 전달되며, 발주자는 24시간
          내에 취소 회수가 가능합니다.
        </p>
      </div>
      <div class="content">
        <span>※</span>
        <p>결제 취소일시 기준, 24시간 후 자동 결제 취소됩니다.</p>
      </div>
      <div class="content">
        <span>※</span>
        <p>
          취소 금액 환불은 결제 수단에 따라 영업일 기준으로 3일에서 최대 7일까지
          소요될 수 있습니다.
        </p>
      </div>
      <div class="content">
        <span>※</span>
        <p>
          결제가 취소되면 사용하신 쿠폰은 모두 반환되지만, 취소된 시점에
          유효기간이 만료된 경우, 반환이 불가합니다.
        </p>
      </div>
    </div>
    <div
      class="bottom-btn"
      @click="openCancel()"
      v-if="paymentData.paymentStatus == '2'"
    >
      <p>결제 취소</p>
    </div>
    <div
      class="bottom-btn"
      @click="openRePayment()"
      v-if="paymentData.paymentStatus == '3'"
    >
      <p>결제 취소 회수</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentDetailCom",
  props: {
    // paymentData: {
    //   type: String,
    // },
    paymentNo: {
      type: String,
    },
  },
  watch: {
    paymentNo(oldVal) {
      this.$API.payment.getPaymentDetail(this.userId, oldVal).then((res) => {
        console.log(res);
        this.paymentData = res.data.body;
      });
    },
  },
  mounted() {
    // const paymentNo = this.$route.params.paymentNo
    //   ? this.$route.params.paymentNo
    //   : this.paymentNo;
    // this.$API.designOrder
    //   .getCalculateDetail(this.userId, paymentNo)
    //   .then((res) => {
    //     console.log(res);
    //     this.paymentData = res.data.body;
    //     this.step = 0;
    //   });
    this.$API.payment
      .getPaymentDetail(this.userId, this.paymentNo)
      .then((res) => {
        console.log(res);
        this.paymentData = res.data.body;
      });
  },
  data() {
    return {
      paymentData: {},
      detailData: {},
      step: 0,
      inputDeactive: true,
      agree: true,
      showModal: false,
      showModalId: "",
      isShowStyleAdd: true,
      isModify: false,
      isStyleError: false,
      isOrderError: false,
      isSignError: false,
      isProgress: true,
      koreanTime: null,
      userId: this.$route.query.userId || sessionStorage.getItem("userId"),
      compType:
        this.$route.query.compType || sessionStorage.getItem("compType"),
      signImg: {},
      errorData: {},
      deliveryCount: 1,
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  methods: {
    openCancel() {
      this.$emit("openCancel");
    },
    openRePayment() {
      this.$emit("openRePayment");
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-detail {
  padding: 0 28px;
  &-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid $primary-20;
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    .status {
      color: $neutral-30;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      &.active {
        color: $primary-60;
      }
    }
  }
  &-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .row {
      position: relative;
      display: flex;
      .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
      .info {
        position: absolute;
        left: 150px;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  &-btn {
    cursor: pointer;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid $neutral-20;
    background: $neutral-0;
    color: $neutral-60;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  &-bottom {
    .info {
      margin-top: 40px;
      padding: 0 28px;
      color: $neutral-60;
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      .title {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $neutral-80;
      }
      .content {
        display: flex;
        gap: 8px;
      }
    }
    .bottom-btn {
      margin: 40px 28px;
      p {
        cursor: pointer;
        // position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $neutral-0;
        width: 100%;
        height: 40px;
        background-color: $primary-50;
        border-radius: 4px;
      }
    }
  }
}
</style>
