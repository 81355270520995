<template>
  <div class="material" id="material">
    <div class="material-nav">
      <div class="material-nav-left">
        <p v-if="isMobile == 'false'" @click="gotoPage('/')">오슬 홈</p>
        <span v-if="isMobile == 'false'" class="material-symbols-outlined">
          chevron_right
        </span>
        <p v-if="isMobile == 'false'" @click="gotoPage('/mall/materiallist')">
          오슬상사
        </p>
        <span v-if="isMobile == 'false'" class="material-symbols-outlined">
          chevron_right
        </span>
        <p
          @click="gotoPage('/mall/materiallist')"
          v-if="
            detailData.tabCatg &&
            (detailData.tabCatg.indexOf('심지') > -1 ||
              detailData.tabCatg.indexOf('트리코트') > -1)
          "
        >
          심지
        </p>
        <p
          @click="gotoPage('/mall/materiallist')"
          v-else-if="
            detailData.tabCatg &&
            (detailData.tabCatg.indexOf('186TC') > -1 ||
              detailData.tabCatg.indexOf('안감') > -1)
          "
        >
          안감
        </p>
        <p
          @click="gotoPage('/mall/materiallist')"
          v-else-if="
            detailData.tabCatg && detailData.tabCatg.indexOf('솜') > -1
          "
        >
          솜
        </p>
        <p
          @click="gotoPage('/mall/materiallist')"
          v-else-if="
            detailData.tabCatg && detailData.tabCatg.indexOf('단추') > -1
          "
        >
          단추
        </p>
        <p @click="gotoPage('/mall/materiallist')" v-else>기타</p>
        <span class="material-symbols-outlined"> chevron_right </span>
        <p @click="gotoPage('/mall/materiallist')">{{ detailData.tabCatg }}</p>
      </div>
      <div class="material-nav-right" v-if="isMobile == 'true'">
        <div class="filter-btn" @click="gotoPage('/mall/basket')">
          <span class="material-symbols-outlined"> shopping_cart </span>
          <p class="filter-profile">장바구니 ({{ basketCount }})</p>
        </div>
      </div>
    </div>
    <div class="material-mobile" v-if="isMobile == 'true'">
      <div class="material-mobile-title">{{ detailData.itemName }}</div>
      <div class="material-mobile-content">
        <div class="row">
          <p class="row-name">· 판매 단위</p>
          <p class="row-content" v-if="detailData.unitPrice?.length > 0">
            최소
            {{ detailData.unitPrice[detailData.unitPrice.length - 1].unit }}개
            이상
          </p>
          <p class="row-content" v-else>
            {{ detailData.itemUnitValue }}
          </p>
        </div>
        <div class="row" v-if="!(detailData.unitPrice?.length > 0)">
          <p class="row-name">· 판매가</p>
          <p class="row-content cancel">
            {{ comma(detailData.itemNormalCost) }}원
            <span class="unit" v-if="detailData.itemNormalLengthValue > 0">
              ({{ detailData.itemNormalLengthName }},
              {{ comma(detailData.itemNormalLengthValue) }}원)
            </span>
          </p>
        </div>
        <div class="row" v-if="!(detailData.unitPrice?.length > 0)">
          <p class="row-name">· 할인가</p>
          <p class="row-content yellow">
            {{ comma(detailData.itemExposeCost) }}원
            <span class="unit" v-if="detailData.itemLengthValue > 0">
              ({{ detailData.itemLengthName }},
              {{ comma(detailData.itemLengthValue) }}원)
            </span>
          </p>
        </div>
        <div class="row">
          <p class="row-name">· 배송비</p>
          <p class="row-content" v-if="detailData.deliveryPrice == 0">
            무료배송
          </p>
          <p class="row-content" v-else>
            {{ comma(detailData.deliveryPrice) }}
          </p>
        </div>
      </div>
      <div id="imgFileClass" class="material-top-left-imgs">
        <div v-if="detailData.itemImgList">
          <div
            class="fileupload-wrap"
            v-if="detailData.itemImgList?.length != 0"
          >
            <div class="preview">
              <img
                v-if="regImgUrl"
                @click="showImg(regImgUrl, regImgName)"
                :src="regImgUrl"
              />
            </div>
            <div class="thumbnails">
              <div class="thumbnails-list mt-12">
                <div
                  v-for="(y, j) in detailData.itemImgList"
                  :key="j"
                  class="thumbnails-list-area pointer"
                  :class="y.sourceFileName.split('.')[1]"
                  @click="clickThumb(y.fileUrl, y.sourceFileName)"
                >
                  <img
                    v-if="
                      ['.psd', '.ai', '.pdf', '.xlsx'].some(
                        (e) => y.sourceFileName.indexOf(e) != -1
                      )
                    "
                    :src="
                      require(`@/assets/img/thumbnail-${
                        y.sourceFileName.split('.')[1]
                      }.png`)
                    "
                  />
                  <img v-else :src="y.fileUrl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="material-mobile-table"
      v-if="isMobile == 'true' && detailData.unitPrice?.length > 0"
    >
      <div class="material-mobile-table-content">
        <p class="unit-title">수량</p>
        <p class="unit-title border">단가</p>
      </div>
      <div
        class="material-mobile-table-content"
        v-for="(unitPrice, i) in detailData.unitPrice"
        :key="i"
      >
        <p class="unit-title">{{ comma(unitPrice.unit) }} ~</p>
        <p class="price border">{{ comma(unitPrice.price) }}</p>
      </div>
    </div>
    <div class="material-top" v-if="isMobile == 'false'">
      <div class="material-top-left">
        <div id="imgFileClass" class="material-top-left-imgs">
          <div v-if="detailData.itemImgList">
            <div
              class="fileupload-wrap"
              v-if="detailData.itemImgList?.length != 0"
            >
              <div class="preview">
                <img
                  v-if="regImgUrl"
                  @click="showImg(regImgUrl, regImgName)"
                  :src="regImgUrl"
                />
              </div>
              <div class="thumbnails">
                <div class="thumbnails-list mt-12">
                  <div
                    v-for="(y, j) in detailData.itemImgList"
                    :key="j"
                    class="thumbnails-list-area pointer"
                    :class="y.sourceFileName.split('.')[1]"
                    @click="clickThumb(y.fileUrl, y.sourceFileName)"
                  >
                    <img
                      v-if="
                        ['.psd', '.ai', '.pdf', '.xlsx'].some(
                          (e) => y.sourceFileName.indexOf(e) != -1
                        )
                      "
                      :src="
                        require(`@/assets/img/thumbnail-${
                          y.sourceFileName.split('.')[1]
                        }.png`)
                      "
                    />
                    <img v-else :src="y.fileUrl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="material-top-right">
        <CompMaterialAdd
          @openLogin="openLogin"
          :mallNo="mallNo"
          @updateBasket="updateBasket"
          @addBasketCount="addBasketCount"
          @moveBasket="moveBasket"
          @moveBuy="moveBuy"
        />
      </div>
    </div>

    <div class="material-body">
      <div class="material-title">
        <p>상세 설명</p>
      </div>
      <div class="material-body-detail">
        <div
          v-if="isMobile == 'false' && detailData.detailImg"
          v-html="detailData.detailImg"
        ></div>
        <div
          v-if="isMobile == 'true' && detailData.detailImgMo"
          v-html="detailData.detailImgMo"
        ></div>
        <div
          v-for="(y, j) in !detailData.detailImg ? detailData.descImgList : ''"
          :key="j"
          class="material-body-detail-imgs"
        >
          <img
            v-if="
              ['.psd', '.ai', '.pdf', '.xlsx'].some(
                (e) => y.sourceFileName.indexOf(e) != -1
              )
            "
            :src="
              require(`@/assets/img/thumbnail-${
                y.sourceFileName.split('.')[1]
              }.png`)
            "
          />
          <img v-else :src="y.fileUrl" />
        </div>
      </div>
      <div class="item">
        <div class="item-info" v-if="!detailData.detailImg">
          <p class="item-info-title">배송 안내</p>
          <p class="item-info-content">
            <span>※</span>평일 오후 5시 이전 주문 건은 익일 발송을 원칙으로
            합니다. 단, 주문량이 많을 경우, 순차 배송으로 지연될 수 있습니다.
          </p>
          <p class="item-info-content">
            <span>※</span>배송일은 발송일 기준, 1~3일이 소요되며, 제주도 및
            도서산간지역은 추가 운임료가 부과됩니다.
          </p>
        </div>
        <div class="item-info" v-if="!detailData.detailImg">
          <p class="item-info-title">교환 및 반품</p>
          <p class="item-info-content">
            <span>※</span>교환 및 반품은 제품 수령일로부터 3일 이내 처리
            가능합니다. 단, 단순 변심으로 인한 교환 및 반품의 왕복 배송비는
            고객님 부담입니다.
          </p>
          <p class="item-info-content">
            <span>※</span>제품을 개봉하거나 사용 또는 훼손 등으로 재판매가
            불가능한 경우 교환 및 반품이 불가합니다.
          </p>
          <p class="item-info-content">
            <span>※</span>제품 수령 후 바로 원단 상태와 수량을 확인해주세요.
            세탁이나 재단 시, 오배송이나 하자의 경우라도 교환 및 반품이
            불가합니다.
          </p>
          <p class="item-info-content">
            <span>※</span>원단의 경우, 재단 후에는 교환 및 반품, 변경이
            불가함으로 신중히 구매해주시기 바랍니다.
          </p>
        </div>
        <div class="item-info" v-if="!detailData.detailImg">
          <p class="item-info-title">구매 시, 유의 사항</p>
          <p class="item-info-content">
            <span>※</span>모니터 환경에 따라 모니터에 보이는 색상과 실제 색상의
            차이가 있을 수 있습니다.
          </p>
          <p class="item-info-content">
            <span>※</span>원단은 롯트 단위로 새롭게 염색하기 때문에 출시 때마다
            생지의 색상, 습도 등의 환경 차이에 의해 탕 차이 (색상의 차이)가 생길
            수 있습니다.
          </p>
          <p class="item-info-content">
            <span>※</span>제품 수령 후 바로 원단 상태와 수량을 확인해주세요.
            세탁이나 재단 시, 오배송이나 하자의 경우라도 교환 및 반품이
            불가합니다.
          </p>
          <p class="item-info-content">
            <span>※</span>원단의 경우, 재단 후에는 교환 및 반품, 변경이
            불가함으로 신중히 구매해주시기 바랍니다.
          </p>
        </div>
      </div>
    </div>

    <div class="material-bottom">
      <div class="material-title"><p>상품정보 제공고시</p></div>
      <div class="notice">
        <div class="notice-info">
          <p class="notice-info-title">· 품명</p>
          <p class="notice-info-content">{{ detailData.itemName }}</p>
        </div>
        <div class="notice-info">
          <p class="notice-info-title">· 제조사</p>
          <p class="notice-info-content">{{ detailData.compName }}</p>
        </div>
        <div class="notice-info">
          <p class="notice-info-title">· 제조국</p>
          <p class="notice-info-content">{{ detailData.country }}</p>
        </div>
        <div class="notice-info">
          <p class="notice-info-title">· A/S</p>
          <p class="notice-info-content">{{ detailData.asInfo }}</p>
        </div>
        <div class="notice-info">
          <p class="notice-info-title">· 품질보증기준</p>
          <p class="notice-info-content">
            {{ detailData.qualityStandard }}
          </p>
        </div>
      </div>
    </div>
    <CompMaterialItemSwiper
      :title="'연관 상품'"
      :mallList="mallList"
    ></CompMaterialItemSwiper>
    <div
      class="material-order"
      id="materialOrder"
      v-show="isMobile == 'true' && isOrder"
    >
      <div class="material-order-close" @click="clickOrder(false)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path d="M8 12L0 4H16L8 12Z" fill="white" />
        </svg>
      </div>

      <div class="material-order-wrap">
        <div
          class="option-wrap"
          v-for="(option, i) in this.options"
          :key="i"
          :class="options.length - 1 == i ? 'last' : ''"
        >
          <div class="column" :class="i == 0 ? 'mt-40' : ''">
            <p class="column-name">
              {{ option.itemOptionName }}
            </p>
            <div
              :class="isMobile == 'false' ? 'row-content' : 'option'"
              v-if="option.optionType == '1'"
            >
              <SelectBoxMall
                :data="option.optionList"
                :filterType="'optionType'"
                :index="i"
                @selectData="selectData"
                :selectData="
                  option.optionList.filter((e) => e.isActive)[0]?.name
                    ? option.optionList.filter((e) => e.isActive)[0]?.name
                    : `${addEulReul(selectOptionName)} 선택해주세요.`
                "
                :placeholderTxt="
                  !option.optionList.filter((e) => e.isActive)[0]
                "
              />
            </div>
            <div class="option bg" v-else-if="option.optionType == '2'">
              <p
                class="item-option"
                v-for="(optionList, j) in option.optionList"
                :key="j"
                @click="getMallOption(optionList.cd, option.optionDepth)"
                :class="optionList.isActive ? 'active' : ''"
              >
                <!-- <span
                  :style="`background-color:${sizeColor.itemColorHex}`"
                ></span> -->
                {{ optionList.name }}
              </p>
              <p v-if="!(option.optionList.length > 0)" class="option-empty">
                {{ addEulReul(selectOptionName) }} 선택해주세요.
              </p>
            </div>
            <div class="option bg" v-else-if="option.optionType == '3'">
              <p
                class="item-option"
                v-for="(optionList, j) in option.optionList"
                :key="j"
                @click="getMallOption(optionList.cd, option.optionDepth)"
                :class="optionList.isActive ? 'active' : ''"
              >
                <span :style="`background-color:${optionList.color}`"></span>
                {{ optionList.name }}
              </p>
              <p v-if="!(option.optionList.length > 0)" class="option-empty">
                {{ addEulReul(selectOptionName) }} 선택해주세요.
              </p>
            </div>
          </div>
        </div>
        <div class="add-item" v-if="addItem.length > 0">
          <div class="add-item-wrap" v-for="(x, i) in addItem" :key="i">
            <div class="add-item-wrap-title">
              <p class="item">
                {{ detailData.itemName }}
              </p>
              <p class="option-name">
                <span class="material-symbols-outlined"> check </span>
                {{ x.itemOptionName }}
              </p>
            </div>
            <div class="add-item-wrap-content">
              <div class="updown br-4 width-110px">
                <div class="updown-btn gray" @click="downItem(i)">
                  <img src="@/assets/icon/ic-minus-white.svg" />
                </div>
                <div class="updown-num">
                  <input
                    type="number"
                    v-model="x.count"
                    @keyup="sum()"
                    @focusout="numFocusout(i, x.count)"
                  />
                </div>
                <div class="updown-btn gray" @click="upItem(i)">
                  <img src="@/assets/icon/ic-plus-white.svg" />
                </div>
              </div>
              <div class="price">
                <p>
                  {{
                    comma(
                      unitSum(x.count, detailData.itemUnitCost, x.addUnitCost)
                    )
                  }}원
                </p>
                <span
                  class="material-symbols-outlined close"
                  @click="removeItem(i)"
                >
                  close
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-column gap12">
          <div class="column pt-40 pb-20">
            <p class="column-name">총 주문금액 : {{ comma(paymentPrice) }}원</p>
          </div>
        </div>
        <div class="basket-btns">
          <p class="basket-btns-btn" @click="addBasket()">장바구니 담기</p>
          <p class="basket-btns-btn" @click="addBuy()">바로 구매하기</p>
        </div>
      </div>
    </div>
    <div class="floating" id="floating" v-show="isMobile == 'true' && !isOrder">
      <p class="floating-btn" @click="clickOrder(true)">
        주문 하기<svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M6.3335 11.6667L10.5002 7.5L14.6668 11.6667H6.3335Z"
            fill="white"
          />
        </svg>
      </p>
    </div>
  </div>

  <div v-if="isBasket" class="modal">
    <div class="modal-background" @click="isBasket = false"></div>
    <div class="modal-basket">
      <p>장바구니로 이동하시겠습니까?</p>
      <div class="modal-basket-btns">
        <p class="btns-btn" @click="isBasket = false">계속 쇼핑하기</p>
        <p class="btns-btn active" @click="gotoPage('/mall/basket')">
          장바구니 이동
        </p>
      </div>
    </div>
  </div>
  <div v-if="isSelect" class="modal">
    <div class="modal-background" @click="isSelect = false"></div>
    <div class="modal-basket">
      <p>상품 옵션을 선택해주세요.</p>
      <div class="modal-basket-btns">
        <p class="btns-btn active" @click="isSelect = false">확인</p>
      </div>
    </div>
  </div>
</template>
<script>
import SelectBoxMall from "../SelectBoxMall.vue";

export default {
  name: "CompDesignmaterialDetail",
  components: {
    SelectBoxMall,
  },
  props: {
    // basketItem: {
    //   type: Object,
    // },
  },
  activated() {
    console.log("bbb");
  },
  data() {
    return {
      d: {},
      i: [],
      mallNo: "",
      regImgUrl: "",
      regImgName: "",
      detailData: {},
      addItem: [],
      options: [],
      sumPrice: 0,
      paymentPrice: 0,
      sumCount: 0,
      minCount: 1,
      color: [],
      size: [],
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
      get getBasket() {
        return sessionStorage.getItem("basketItem");
      },
      mallList: [],
      basketCount: 0,
      optionDatas: [],
      selectItem: [],
      selectIndex: 0,
      selectOptionName: "",
      isOrder: false,
      isBasket: false,
      isSelect: false,
    };
  },
  unmounted() {
    this.$store.state.meta.detailTitle = "";
  },
  mounted() {
    if (JSON.parse(this.getBasket)) {
      this.basketCount = JSON.parse(this.getBasket)?.length;
    }

    console.log(this.$store.state.meta.detailTitle);

    this.mallNo = this.$route.params.mallNo;

    this.$API.mall.getMaterialDetail(this.mallNo).then((res) => {
      console.log(res);
      this.detailData = res.data.body;
      this.regImgUrl = this.detailData.itemImgList[0].fileUrl;
      this.regImgName = this.detailData.itemImgList[0].sourceFileName;

      this.initOption();

      console.log(this.detailData.tabCatg);
      const filters = {
        userId: sessionStorage.getItem("userId"),
        tabCatg: this.detailData.tabCatg,
      };
      this.$API.mall.getMaterialList(filters).then((res) => {
        console.log(res.data.body);
        this.mallList = res.data.body;
      });
    });

    // mallList
  },
  methods: {
    initOption() {
      this.options = [];

      this.$API.mall.getMaterialOptionInit(this.mallNo).then((res) => {
        console.log(res);
        const initData = res.data.body;
        // let rowData = {};

        initData.forEach((e) => {
          const rowData = {
            mallNo: e.mallNo,
            optionDepth: e.optionDepth,
            optionType: e.optionType,
            itemOptionName: e.itemOptionName,
            optionList: [],
          };
          this.options.push(rowData);
        });
        this.selectOptionName = this.options[0].itemOptionName;

        this.optionDatas = {
          mallNo: this.mallNo,
          optionDepth: 1,
          itemParentCode: 0,
        };
        console.log(this.optionDatas);
        this.$API.mall.getMaterialOption(this.optionDatas).then((res) => {
          console.log(res);
          const optionItem = res.data.body;
          // this.options = optionItem;
          // if (optionItem.optionType == "1") {
          let selectList = [];
          optionItem.optionList.forEach((e, i) => {
            const optionList = {
              cd: e.itemOptionDtlNo,
              name: e.itemOptionValue,
              color: e.itemOptionColor,
              addUnitCost: e.addUnitCost,
              isActive: false,
            };
            selectList.push(optionList);
          });
          // let selectItem = {
          //   mallNo: this.mallNo,
          //   optionDepth: optionItem.optionDepth,
          //   optionType: optionItem.optionType,
          //   itemOptionName: optionItem.itemOptionName,
          //   optionList: selectList,
          // };
          // this.options.push(selectItem);
          this.options
            .filter((e) => e.optionDepth == 1)
            .map((e) => (e.optionList = selectList));
          console.log(this.options);
        });
      });

      //최소 수량
      if (this.detailData.unitPrice?.length > 0) {
        this.detailData.unitPrice.forEach((e) => {
          this.minCount = e.unit;
          console.log(e);
        });
      }
    },
    selectData(data, type, i) {
      console.log(data);
      console.log(type);
      console.log(i);
      this.selectOptionName = this.options[i].itemOptionName;
      this.options[i].optionList.forEach((e) => {
        e.isActive = false;
        console.log(e.cd);
        console.log(data.cd);
        if (e.cd == data.cd) {
          e.isActive = true;
          const itemParentCode = data.cd;
          const optionDepth = this.options[i].optionDepth;
          this.getMallOption(itemParentCode, optionDepth);
        }
      });
    },
    getMallOption(itemParentCode, optionDepth) {
      //초기화
      this.options.forEach((e, i) => {
        // if (i >= optionDepth) {
        //   this.options.splice(i, 1);
        // }
        if (i + 1 == optionDepth) {
          console.log(i);
          console.log(optionDepth);
          e.optionList.forEach((e2) => {
            e2.isActive = false;
            if (e2.cd == itemParentCode) {
              e2.isActive = true;
            }
            console.log(e2);
          });
        }
        console.log(e);
        if (
          i == optionDepth &&
          !(e.optionList.filter((e2) => e2.isActive).length > 0)
        ) {
          this.selectOptionName = e.itemOptionName;
        }
      });
      this.optionDatas.mallNo = this.mallNo;
      this.optionDatas.itemParentCode = itemParentCode;
      this.optionDatas.optionDepth = Number(optionDepth) + 1;
      let isDuple = false;
      this.addItem.forEach((e) => {
        if (itemParentCode == e.itemOptionDtlNo) {
          isDuple = true;
        }
      });
      if (!isDuple) {
        this.$API.mall.getMaterialOption(this.optionDatas).then((res) => {
          console.log(res);
          const optionItem = res.data.body;
          if (optionItem) {
            if (
              optionItem.optionType == "1" ||
              optionItem.optionType == "2" ||
              optionItem.optionType == "3"
            ) {
              let selectList = [];
              optionItem.optionList.forEach((e, i) => {
                const optionList = {
                  cd: e.itemOptionDtlNo,
                  name: e.itemOptionValue,
                  color: e.itemOptionColor,
                  addUnitCost: e.addUnitCost,
                  isActive: false,
                };
                selectList.push(optionList);
              });
              console.log(this.options);
              this.options
                .filter((e) => e.optionDepth == this.optionDatas.optionDepth)
                .map((e) => (e.optionList = selectList));
              console.log(this.options);
            }
          } else {
            let addUnitCost = 0;
            let itemName = this.detailData.itemName;
            let itemOptionName = "";
            console.log(this.options);
            this.options.forEach((e, i) => {
              const thisCost = e.optionList.filter((e) => e.isActive)[0]
                .addUnitCost;
              const optionName = e.optionList.filter((e) => e.isActive)[0].name;
              console.log(optionName);
              addUnitCost += thisCost ? Number(thisCost) : 0;
              itemName += " " + optionName;
              if (i > 0) {
                itemOptionName += "  │  ";
              }
              itemOptionName += optionName;
              console.log(addUnitCost);
              console.log(itemName);
            });

            this.addItem.push({
              optionDepth: optionDepth,
              itemOptionDtlNo: itemParentCode,
              itemName: itemName,
              itemOptionName: itemOptionName,
              minCount: this.minCount,
              count: this.minCount,
              itemUnitCost: this.detailData.itemUnitCost,
              addUnitCost: addUnitCost,
              detailData: this.detailData,
            });
            this.sum();
            this.initOption();
          }
        });
      } else {
        //증복체크
        this.initOption();
      }
    },
    addBasket() {
      if (this.addItem.length > 0) {
        let basketItem = [];
        if (sessionStorage.getItem("basketItem")) {
          basketItem = JSON.parse(sessionStorage.getItem("basketItem"));
        }
        basketItem.push({
          deliveryPrice: this.detailData.deliveryPrice,
          itemName: this.detailData.itemName,
          item: this.addItem,
          mallNo: this.detailData.mallNo,
          tabCatg: this.detailData.tabCatg,
        });
        sessionStorage.setItem("basketItem", JSON.stringify(basketItem));
        this.$emit("updateBasket", basketItem);
        this.basketCount++;
      }
      this.isBasket = true;
    },
    addBasketCount() {
      this.basketCount++;
      // this.isBasket = true;
    },
    moveBasket() {
      // this.basketCount++;
      this.isBasket = true;
    },
    moveBuy() {
      console.log(this.isSelect);
      // this.basketCount++;
      this.isSelect = true;
      console.log(this.isSelect);
    },
    addBuy() {
      if (!(this.addItem.length > 0)) {
        this.isSelect = true;
      } else {
        this.addBasket();
        let basketItem = {
          deliveryPrice: this.detailData.deliveryPrice,
          itemName: this.detailData.itemName,
          item: this.addItem,
          mallNo: this.detailData.mallNo,
        };
        this.$store.state.meta.mall.materialBuyItem.push(basketItem);
        localStorage.removeItem("buyItem");
        localStorage.setItem(
          "buyItem",
          JSON.stringify(this.$store.state.meta.mall.materialBuyItem)
        );
        console.log(this.$store.state.meta.mall.materialBuyItem.length);
        if (this.$store.state.meta.mall.materialBuyItem.length > 0) {
          this.gotoPage("/mall/materialbuy");
        } else {
          this.showToast("구매하실 상품을 선택해주세요.");
        }
      }
    },
    upItem(index) {
      this.addItem[index].count++;
      this.sum();
    },
    downItem(index) {
      if (this.addItem[index].count <= this.minCount) {
        // this.removeItem(index);
      } else {
        this.addItem[index].count--;
      }
      if (this.addItem[index].count == 0) {
        this.removeItem(index);
      }
      this.sum();
    },
    removeItem(index) {
      // const cd1 = this.addItem[index].cd1;
      // const cd2 = this.addItem[index].cd2;
      this.addItem.splice(index, 1);
      this.sum();
    },
    numFocusout(index, count) {
      console.log(count);
      if (count < this.minCount) {
        // this.removeItem(index);
        this.addItem[index].count = this.minCount;
      }
    },
    sum() {
      let sumPrice = 0;
      let sumCount = 0;
      this.addItem.forEach((e) => {
        if (this.detailData.unitPrice?.length > 0) {
          let itemUnitCost = 0;
          for (const unitPrice of this.detailData.unitPrice) {
            console.log(unitPrice.unit);
            if (unitPrice.unit <= e.count) {
              itemUnitCost = unitPrice.price;
              console.log(itemUnitCost);
              break;
            } else {
              itemUnitCost = this.detailData.itemUnitCost;
            }
          }
          console.log(itemUnitCost);
          e.itemUnitCost = itemUnitCost;
          sumPrice += e.count * (Number(itemUnitCost) + Number(e.addUnitCost));
        } else {
          sumPrice +=
            e.count *
            (Number(this.detailData.itemUnitCost) + Number(e.addUnitCost));
        }
      });

      console.log(this.addItem);
      this.sumPrice = sumPrice;
      this.sumCount = sumCount;
      this.paymentPrice = sumPrice + Number(this.detailData.deliveryPrice);
    },
    unitSum(count, itemUnitCost, addUnitCost) {
      let sumPrice = 0;
      console.log(this.detailData.unitPrice);
      if (this.detailData.unitPrice?.length > 0) {
        for (const unitPrice of this.detailData.unitPrice) {
          console.log(unitPrice.unit);
          if (unitPrice.unit <= count) {
            itemUnitCost = unitPrice.price;
            console.log(itemUnitCost);
            break;
          }
        }
        console.log(itemUnitCost);
        sumPrice += count * (Number(itemUnitCost) + Number(addUnitCost));
      } else {
        sumPrice += count * (Number(itemUnitCost) + Number(addUnitCost));
      }
      return sumPrice;
    },
    updateSwiper() {
      this.swiperIndex = document.getElementById("imgSwiper").swiper.realIndex;
    },
    clickThumb(fileUrl, fileName) {
      this.regImgUrl = fileUrl;
      this.regImgName = fileName;
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    clickOrder(isOrder) {
      this.isOrder = isOrder;
      let paddingHeight = 20;

      setTimeout(() => {
        let order = document.getElementById("materialOrder");
        let floating = document.getElementById("floating");
        if (this.isOrder) {
          paddingHeight += order.offsetHeight;
        } else {
          paddingHeight += floating.offsetHeight;
        }
        console.log(order.offsetHeight);
        console.log(floating.offsetHeight);
        const el = document.getElementById("material");
        el.style.paddingBottom = paddingHeight + "px";
      }, 100);
    },
    bookmark(index, designOrderNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo);
    },
    downloadImg(imageUrl, sourceFileName) {
      imageUrl =
        "http://cdn.osle.co.kr/upload/20190507/28771_20190507192106.jpg";
      console.log(imageUrl, sourceFileName);
      // download(imageUrl, sourceFileName);
    },
    goDetail(mallNo, itemName) {
      this.$store.state.meta.detailTitle = itemName;
      console.log(this.$store.state.meta.detailTitle);
      console.log(mallNo);
      if (this.userId) {
        this.gotoUrl(`/mall/materialdetail/${mallNo}`);
      } else {
        this.$emit("openLogin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  width: 100%;
}
.material {
  padding: 0;
  @media (max-width: 1290px) {
    padding: 40px 20px;
  }
  &-title {
    padding: 2px 0;
    display: flex;
    p {
      height: 26px;
      border-bottom: 1px solid $primary-50;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &-nav {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      p {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        color: $neutral-60;
      }
      span {
        font-size: 16px;
        color: $neutral-60;
      }
    }
    &-right {
      .filter-btn {
        cursor: pointer;
        height: 20px;
        display: flex;
        align-items: center;
        gap: 8px;
        // border: 1px solid $neutral-10;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        img {
          width: 16px;
        }
        &:hover {
          border-bottom: 1px solid;
        }
        &.active {
          border-color: $primary-50;
          color: $primary-60;
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
  &-mobile {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: $neutral-0;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    &-title {
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%; /* 25.2px */
      border-bottom: 1px solid $neutral-10;
    }
    &-content {
      padding: 20px 0;
      border-bottom: 1px solid $neutral-10;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .row {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &-name {
          font-size: 15px;
          font-weight: 500;
        }
        &-content {
          margin-left: 11px;
          color: $neutral-60;
          font-size: 15px;
          font-weight: 400;
          &.cancel {
            color: $neutral-40;
            text-decoration: line-through;
          }
          &.yellow {
            color: $primary-60;
          }
        }
      }
    }

    &-table {
      padding: 20px 0;
      display: flex;
      border-bottom: 1px solid $neutral-10;
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        border: solid $neutral-20;
        border-width: 1px 0px 1px 1px;
        &:last-child {
          border-right-width: 1px;
        }
        .unit-title {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          font-size: 13px;
          font-weight: 400;
          line-height: 140%;
          background: $neutral-3;
        }
        .price {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          font-size: 13px;
          font-weight: 500;
          line-height: 140%;
          background: $neutral-0;
        }
        .border {
          border-top: 1px solid $neutral-20;
        }
      }
    }
  }
  &-top {
    margin-top: 20px;
    padding: 24px;
    display: flex;
    gap: 48px;
    border-radius: 8px;
    background-color: $neutral-0;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    @media (max-width: 1290px) {
      padding: 20px 0;
    }
    &-right {
      width: 100%;
    }
    &-left {
      @media (max-width: 520px) {
        width: 100%;
      }
      &-imgs {
        .preview {
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 402px;
          border-radius: 4px;
          border: 1px solid $neutral-10;
          @media (max-width: 520px) {
            width: 100%;
            height: 100%;
          }
          img {
            width: 402px;
          }
        }
        .file-name {
          margin: 12px 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $neutral-60;
        }
        .thumbnails {
          display: flex;
          flex-direction: column;
          &-list {
            gap: 8px;
            display: grid;
            justify-content: flex-start;
            flex-wrap: wrap;
            grid-template-columns: repeat(auto-fill, 74px);
            &-area {
              width: 74px;
              height: 74px;
              border: 1px solid $neutral-10;
              border-radius: 4px;
              &:hover {
                border: 1px solid $primary-50;
              }
              &.psd {
                border-color: #60b2ff;
              }
              &.ai {
                border-color: $primary-50;
              }
              &.psd,
              &.ai,
              &.xlsx {
                img {
                  padding: 10px;
                }
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
  &-body {
    margin-top: 20px;
    padding: 24px;
    background-color: $neutral-0;
    border-radius: 8px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    &-detail {
      width: 100%;
      padding: 28px 0;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      // &-imgs {
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      img {
        width: 100%;
      }
      // }
    }
    .item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      &-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &-title {
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;
          color: $neutral-80;
        }
        &-content {
          display: flex;
          gap: 8px;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
        }
      }
    }
  }
  &-bottom {
    margin-top: 20px;
    padding: 24px;
    background-color: $neutral-0;
    border-radius: 8px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    @media (max-width: 520px) {
      margin-top: 20px;
      padding: 20px;
    }
    display: flex;
    flex-direction: column;
    gap: 30px;
    .notice {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &-info {
        display: flex;
        &-title {
          flex: 1;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;
          position: absolute;
        }
        &-content {
          flex: 3;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          margin-left: 150px;
        }
      }
    }

    &-btns {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      &-btn {
        cursor: pointer;
        width: 100%;
        height: 40px;
        color: $neutral-0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: $primary-50;
      }
    }
  }

  .floating {
    position: fixed;
    bottom: 57px;
    max-width: 520px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    justify-content: center;
    &-btn {
      cursor: pointer;
      width: 100%;
      height: 40px;
      color: $neutral-0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background: $primary-50;
      font-size: 15px;
      font-weight: 500;
    }
  }
  &-order {
    position: fixed;
    bottom: 55px;
    max-width: 520px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1;
    background: $neutral-0;
    &-close {
      border-radius: 8px 8px 0px 0px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      padding: 12px 0;
      border-bottom: 1px solid $neutral-10;
      color: $neutral-10;
      position: fixed;
      width: 100%;
      background-color: $primary-50;
      z-index: 2;
    }

    &-wrap {
      overflow: auto;
      max-height: calc(65vh - 40px);
      padding: 20px 20px 40px;
      display: flex;
      flex-direction: column;
      // gap: 20px;
      .last {
        border-bottom: 1px solid $neutral-10;
      }
      // .option-wrap {
      //   &:first-child {
      //     border-top: 1px solid $neutral-10;
      //   }
      //   &:last-child {
      //     border-top: 1px solid $neutral-10;
      //   }
      // }
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 12px;
      &-name {
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
      }
      &-content {
        color: $neutral-60;
        font-size: 15px;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .option {
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      height: 61px;
      border-radius: 4px;

      &.bg {
        background: $primary-5;
        margin-bottom: 20px;
      }
      .item-option {
        cursor: pointer;
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid $neutral-10;
        background: $neutral-0;
        font-size: 15px;
        font-weight: 400;
        line-height: 140%;
        span {
          border: 1px solid $neutral-5;
          width: 16px;
          height: 16px;
        }
        &.active {
          border: 1px solid $primary-50;
        }
      }
      .option-empty {
        display: flex;
        align-items: center;
        padding: 0 12px;
        font-size: 15px;
        font-weight: 400;
        line-height: 140%;
        color: $neutral-30;
      }
    }

    .add-item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $neutral-10;
      padding: 28px 0;
      gap: 20px;
      &-wrap {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        &-title {
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin-right: 20px;
          .item {
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
          }
          .point {
            display: flex;
            align-items: center;
            width: 4px;
            height: 4px;
            border: 2px solid #737373;
            border-radius: 2px;
            margin: 8px 0;
          }
          .option-name {
            display: flex;
            gap: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            span {
              color: $neutral-60;
              font-size: 16px;
            }
          }
          img {
            width: 16px;
          }
        }
        &-content {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 12px;
          .price {
            display: flex;
            align-items: center;
            gap: 8px;
            position: relative;
            font-size: 15px;
            font-weight: 500;
            line-height: 140%;
            &-sale {
              position: absolute;
              bottom: 20px;
              text-decoration: line-through;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%;
              color: $neutral-40;
            }
          }
          .close {
            cursor: pointer;
            font-size: 16px;
            color: $neutral-30;
          }
        }
      }
    }
    .basket-btns {
      display: flex;
      justify-content: center;
      &-btn {
        cursor: pointer;
        flex: 1;
        height: 40px;
        color: $neutral-0;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $neutral-60;
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px 4px 4px 0px;
          background-color: $primary-50;
        }
        &.one {
          border-radius: 4px;
        }
      }
    }
  }
}

.modal-basket {
  position: fixed;
  z-index: 1001;
  left: 50%;
  top: 242px;
  width: calc(100% - 48px);
  max-width: 360px;
  height: 210px;
  transform: translate(-50%, 0);
  background-color: $neutral-0;
  border-radius: 8px;
  padding: 74px 0;
  text-align: center;
  color: $neutral-100;
  & > p:first-child:not(.title) {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .sub {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $neutral-60;
  }
  &-btns {
    // margin-top: 32px;
    bottom: 0;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    background-color: $neutral-50;
    border-radius: 0px 0px 8px 8px;
    color: $neutral-0;
    .btns-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: rem(12px) 0;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: rem(8px);
      }
      &.active {
        background-color: $primary-50;
        border-radius: 0px 0px 8px 8px;
        &:not(:first-child) {
          border-radius: 0px 0px 8px 0px;
        }
      }
    }
  }
}
</style>
