<template>
  <div class="desc-add">
    <div class="desc-add-btn" v-if="!isAdd" @click="isAdd = !isAdd">
      참고 사항 입력<img src="@/assets/icon/ic-chevron-up.svg" />
    </div>
    <div class="desc-add-btn" v-else @click="isAdd = !isAdd">
      참고 첨부<img src="@/assets/icon/ic-chevron-down.svg" />
    </div>
    <div class="desc-add-wrap" v-if="isAdd">
      <div class="row">
        <div class="content-title">
          참고 사진 또는 이미지를 첨부해주세요. (최대 8개)
        </div>
        <div class="imgs-wrap">
          <div class="imgs">
            <input
              class="hidden"
              type="file"
              :id="`${imgListName}${i + 1}`"
              accept=".gif, .jpg, .jpeg, .png"
              @change="fileUpload(`${imgListName}`, $event)"
              multiple
            />
            <div class="imgs mt-12">
              <label
                :for="`${imgListName}${i + 1}`"
                class="imgs-img flex active pointer"
                ><img
                  class="plus"
                  :src="require(`@/assets/icon/ic-plus-circle-light-gray.svg`)"
                />추가</label
              >
              <div
                class="imgs-img view pointer"
                v-for="(y, j) in imgList"
                :key="j"
                @click="showImg(y.fileUrl, y.fileName)"
              >
                <img
                  v-if="y.fileName.indexOf('.ai') != -1"
                  class="img-etc"
                  src="@/assets/img/thumbnail-ai.png"
                />
                <img
                  v-else-if="y.fileName.indexOf('.psd') != -1"
                  class="img-etc"
                  src="@/assets/img/thumbnail-psd.png"
                />
                <img
                  v-else-if="y.fileName.indexOf('.xlsx') != -1"
                  class="img-etc"
                  src="@/assets/img/thumbnail-xlsx.png"
                />
                <img
                  v-else-if="y.fileName.indexOf('.pptx') != -1"
                  class="img-etc"
                  src="@/assets/img/thumbnail-pptx.png"
                />
                <img
                  v-else-if="y.fileName.indexOf('.docx') != -1"
                  class="img-etc"
                  src="@/assets/img/thumbnail-docx.png"
                />
                <img
                  v-else-if="y.fileName.indexOf('.pdf') != -1"
                  class="img-etc"
                  src="@/assets/img/thumbnail-pdf.png"
                />
                <img v-else :src="y.fileUrl" />
                <div
                  class="imgs-img-pop flex"
                  @click.stop="removeImg(`${imgListName}`, y.fileNo)"
                >
                  <img src="@/assets/icon/ic-x-square.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="content-title-div">
          <p class="content-title">참고 URL을 첨부해주세요. (최대 5개)</p>
        </div>
        <div class="input-wrap">
          <input placeholder="참고 URL을 첨부해주세요." v-model="imgUrl" />
        </div>
      </div>

      <div class="row">
        <div class="content-title-div">
          <p class="content-title">참고 사항</p>
        </div>
        <div class="input-wrap">
          <textarea
            placeholder="참고해야 하는 사항이나 요청 사항, URL 등을 기재해주세요."
            v-model="imgDesc"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "filterMenuNew",
  props: {
    imgListName: {
      type: Object,
      description: "img list name",
    },
  },
  data() {
    return {
      showSelectBoxList: false,
      selectDataTxt: "",
      imgList: [],
      imgUrl: "",
      imgDesc: "",
      isAdd: false,
    };
  },
  methods: {
    fileUpload(type, event) {
      console.log(event);
      console.log(this[type]);
      for (var i = 0; i < event.target.files.length; i++) {
        this.$API.comm
          .fileUpload(this.userId, event.target.files[i])
          .then((res) => {
            let imgList = [];
            imgList.fileName = event.target.files[0].name;
            imgList.fileNo = res.data.body.img.fileNo;
            imgList.fileUrl = res.data.body.img.previewUrl;
            this.imgList.push(imgList);
          });
      }
      console.log(this.imgList);
    },
  },
};
</script>

<style lang="scss" scoped>
.desc-add {
  border-top: 1px solid $primary-20;
  padding-top: 20px;
  &-btn {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $neutral-30;
    img {
      width: 16px;
    }
  }
  .etc-desc {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $neutral-60;
  }
  .content-title-div {
    flex: 1;
    display: flex;
  }
  .content-title {
    font-weight: 500;
    font-size: 14px;
    color: $neutral-variation-80;
    span {
      color: $primary-60;
    }
  }

  &-wrap {
    // margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    .row {
      position: relative;
    }
    .imgs-wrap {
      margin-top: 12px;
      display: grid;
      gap: 16px;
      .imgs {
        // @include set-grid(104px, 12px, 16px);
        display: flex;
        flex-wrap: wrap;
        gap: 12px 15px;
        &-img {
          display: flex;
          flex-direction: column;
          width: 104px;
          height: 104px;
          border: 1px dashed $neutral-10;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .plus {
            width: 20px;
            height: 20px;
          }
          &.active {
            border: 1px dash $neutral-20;
            border-radius: 4px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: $neutral-30;
          }
          &.error {
            color: $secondary-60;
            border-color: $secondary-60 !important;
          }
          &.view {
            border: 1px solid $neutral-10;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            .img-etc {
              width: 40px;
              height: 40px;
            }
            &.active {
              border-radius: 4px;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: $neutral-0;
              background-color: $primary-50;
            }
            &:not(.active) {
              &:hover {
                border: 1px solid $primary-50;
                border-radius: 4px;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: $primary-60;
              }
            }
          }
          &.design {
            border: 1px solid $neutral-10;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            .img-etc {
              width: 40px;
              height: 40px;
            }
            &.active {
              border: 1px solid $primary-50;
            }
            &:not(.active) {
              &:hover {
                border: 1px solid $neutral-60;
              }
            }
          }
          &-pop {
            position: absolute;
            top: 1px;
            right: 1px;
            width: rem(12px);
            height: rem(12px);
          }
          &-filename {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            min-height: 40px;
            padding: rem(6px);
            background-color: rgba(0, 0, 0, 0.4);
            p {
              @include ellipse(2);
              font-size: 12px;
              color: $neutral-0;
              text-align: center;
              word-break: break-all;
            }
          }
        }
        &-title {
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          p {
            display: flex;
            justify-content: center;
          }
          &.active {
            color: $primary-60;
          }
        }
      }
    }
    .input-wrap {
      margin-top: 12px;
      position: relative;
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      &-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 8px;
        .unit {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          width: 72px;
          height: 38px;
          background: $neutral-3;
          border: 1px solid $neutral-20;
          border-radius: 4px;
          &-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            border-radius: 14px;
            border: 1px solid $neutral-10;
            &-color {
              width: 24px;
              height: 24px;
              border-radius: 12px;
            }
          }
        }
        .size {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 8px;
          input {
            background: $neutral-0;
          }
        }
        .input {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 38px;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: $neutral-30;
          background: $neutral-3;
          border: 1px solid $neutral-20;
          border-radius: 4px;
        }
      }
      input {
        padding: 8px;
        background: #ffffff;
        border-width: 0px 0px 1px 1px;
        border-style: solid;
        border-color: $neutral-30;
        border-radius: 4px;
        width: 100%;
        height: 32px;

        &:focus {
          border-color: $primary-50;
        }
        &:hover {
          background: #fffbf2;
        }
      }
      &.row {
        flex-direction: row !important;
      }
      .input-explain {
        font-size: 14px;
        color: $neutral-60;
      }
      &.error input {
        border-color: $secondary-60;
      }

      textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid $neutral-20;
        border-radius: 4px;
        height: 120px;
        resize: none;
        font-size: 14px;
        &::placeholder {
          color: $neutral-30;
        }
      }
    }
  }
}
</style>
