<template>
  <div class="contract-content">
    <div class="top">
      <p class="square"></p>
      <p class="title">발주 관리</p>
    </div>
    <div class="contract-step-tab">
      <p
        class="issue-btn"
        v-for="(x, i) in filters.status"
        :key="i"
        :class="step == i ? 'active' : ''"
        @click="clickStep(i)"
      >
        {{ x.name }}
      </p>
    </div>

    <div v-if="step == 0" class="contract-step-page">
      <div class="contract-step-page-section" v-if="!isModify">
        <div class="middle">
          <div class="flex space-between">
            <p class="middle-title">발주자</p>
          </div>
          <div class="field-list">
            <div class="field">
              <p class="name">사업자 명</p>
              <p class="info">{{ contractData.designerCompName }}</p>
            </div>
            <div class="field">
              <p class="name">사업자등록번호</p>
              <p class="info">{{ contractData.designerCompNum }}</p>
            </div>
            <div class="field">
              <p class="name">대표자 명</p>
              <p class="info">{{ contractData.designerRepresentName }}</p>
            </div>
            <div class="field">
              <p class="name">전화번호</p>
              <p class="info">{{ contractData.designerTelNum }}</p>
            </div>
            <div class="field">
              <p class="name">주소</p>
              <p class="info">{{ contractData.designerAddress }}</p>
            </div>
            <div class="field">
              <p class="name">상세 주소</p>
              <p class="info">{{ contractData.designerAddressDetail }}</p>
            </div>
          </div>
        </div>

        <div class="middle">
          <div class="flex space-between">
            <p class="middle-title">공급자</p>
          </div>
          <div class="field-list">
            <div class="field">
              <p class="name">사업자 명</p>
              <p class="info">{{ contractData.factoryCompName }}</p>
            </div>
            <div class="field">
              <p class="name">사업자등록번호</p>
              <p class="info">{{ contractData.factoryCompNum }}</p>
            </div>
            <div class="field">
              <p class="name">대표자 명</p>
              <p class="info">{{ contractData.factoryRepresentName }}</p>
            </div>
            <div class="field">
              <p class="name">전화번호</p>
              <p class="info">{{ contractData.factoryTelNum }}</p>
            </div>
            <div class="field">
              <p class="name">주소</p>
              <p class="info">{{ contractData.factoryAddress }}</p>
            </div>
            <div class="field">
              <p class="name">상세 주소</p>
              <p class="info">{{ contractData.factoryAddressDetail }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step == 1" class="contract-step-page">
      <div class="contract-step-page-section">
        <div
          class="middle"
          v-for="(x, i) in contractData.contractStyleList"
          :key="i"
        >
          <div class="flex space-between">
            <p class="middle-title">스타일 {{ i + 1 }}.</p>
          </div>
          <div class="field-list">
            <div class="field">
              <p class="name">스타일 명</p>
              <p class="info">{{ x.styleName }}</p>
            </div>
            <div class="field">
              <p class="name">컬러 수</p>
              <p class="info">{{ x.colorCount }}</p>
            </div>
            <div class="field">
              <p class="name">사이즈 수</p>
              <p class="info">{{ x.sizeCount }}</p>
            </div>
            <div class="field">
              <p class="name">총 수량</p>
              <p class="info">{{ x.quantity }}</p>
            </div>
            <div class="field">
              <p class="name">단가</p>
              <p class="info">{{ x.unitCost }}</p>
            </div>
            <div class="field column">
              <p class="name start">첨부 파일</p>
              <div class="info img-name">
                <a
                  v-for="(y, i) in x.imgList"
                  :key="i"
                  :href="y.fileUrl"
                  download="download"
                  target="_blank"
                >
                  <img
                    v-if="y.sourceFileName.indexOf('.ai') != -1"
                    src="@/assets/img/thumbnail-ai.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.psd') != -1"
                    src="@/assets/img/thumbnail-psd.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.xlsx') != -1"
                    src="@/assets/img/thumbnail-xlsx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.pptx') != -1"
                    src="@/assets/img/thumbnail-pptx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.docx') != -1"
                    src="@/assets/img/thumbnail-docx.png"
                  />
                  <img
                    v-else-if="y.sourceFileName.indexOf('.pdf') != -1"
                    src="@/assets/img/thumbnail-pdf.png"
                  />
                  <img v-else :src="y.fileUrl" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step == 2" class="contract-step-page">
      <div class="contract-step-page-section">
        <div class="middle">
          <p class="middle-title">납품</p>
          <div class="field-list">
            <div class="field">
              <p class="name">납품 방식</p>
              <p v-if="contractData.deliveryFlag == '1'" class="info">일괄</p>
              <p v-else class="info">분할</p>
            </div>
            <div class="field">
              <p class="name">최종 납품일</p>
              <p class="info">{{ contractData.deliveryDateFin }}</p>
            </div>
            <div class="field">
              <p class="name">검수 기한</p>
              <p class="info flex-row-start">
                제품 수령 후,
                <span class="ml-32 mr-12">{{ contractData.testDate }}</span>
                일 이내
              </p>
            </div>
          </div>
        </div>

        <div class="middle">
          <p class="middle-title">금액</p>
          <div class="price-list">
            <div
              class="price"
              v-for="(x, i) in contractData.contractStyleList"
              :key="i"
            >
              <p class="name gap8">
                <span>{{ i + 1 }}.</span> {{ x.styleName }}
              </p>
              <p class="info end">{{ comma(x.unitCost * x.quantity) }} 원</p>
            </div>
            <div class="line"></div>
            <div class="price mt-8 ml-20">
              <p class="name">공급가액</p>
              <p class="info end">{{ comma(contractData.supplyCost) }} 원</p>
            </div>
            <div class="price mt-4 ml-20">
              <p class="name">부가세 (VAT)</p>
              <p class="info end">
                {{ comma((contractData.supplyCost * 0.1).toFixed(0)) }} 원
              </p>
            </div>
            <div class="line"></div>
            <div class="price mt-4 ml-20">
              <p class="name">총 금액</p>
              <p class="info red end">
                {{ comma((contractData.supplyCost * 1.1).toFixed(0)) }} 원
              </p>
            </div>
          </div>
        </div>

        <div class="middle">
          <p class="middle-title">결제</p>
          <div class="field-list" v-if="contractData.depositFlag == '1'">
            <!-- <div class="field">
              <p class="name">계약금 유무</p>
              <p v-if="contractData.depositFlag == '1'" class="info">있음</p>
              <p v-else class="info">없음</p>
            </div> -->
            <div class="field">
              <p class="name">계약금 <span>(VAT 포함)</span></p>
              <p class="info">{{ comma(contractData.depositCost) }}</p>
            </div>
            <div class="field" v-if="contractData.depositFlag == '1'">
              <p class="name">계약금 결제일</p>
              <p class="info">{{ contractData.depositDate }}</p>
            </div>
            <div class="field">
              <p class="name">잔금 <span>(VAT 포함)</span></p>
              <p class="info">
                {{
                  comma(contractData.totalCostVat - contractData.depositCost)
                }}
              </p>
            </div>
            <div class="field">
              <p class="name">잔금 결제일</p>
              <p class="info">{{ contractData.paymentDate }}</p>
            </div>
          </div>
          <div class="field-list" v-else>
            <div class="field">
              <p class="name">금액 <span>(VAT 포함)</span></p>
              <p class="info">
                {{
                  comma(contractData.totalCostVat - contractData.depositCost)
                }}
              </p>
            </div>
            <div class="field">
              <p class="name">금액 결제일</p>
              <p class="info">{{ contractData.paymentDate }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="middle"
        :class="!this.contractData.factorySignFileUrl ? 'last' : ''"
      >
        <p class="middle-title mt-20 ml-20">발주자 서명/사인</p>
        <div class="sign mt-20">
          <img :src="this.contractData.designerSignFileUrl" />
        </div>

        <p class="middle-title mt-20 ml-20">생산자 서명/사인</p>
        <div v-if="compType == '1'" class="sign mt-20">
          <img
            v-if="this.contractData.factorySignFileUrl"
            :src="this.contractData.factorySignFileUrl"
          />
          <p v-else class="sign-comment">
            <span>{{ this.contractData.factoryName }}</span> 의 서명을 기다리는
            중 입니다.
          </p>
        </div>

        <div v-else-if="compType == '2'" class="info">
          <div v-if="this.contractData.factorySignFileUrl" class="sign mt-20">
            <img :src="this.contractData.factorySignFileUrl" />
          </div>
          <div
            v-else
            class="sign mt-20"
            :class="this.isSignError ? 'error' : ''"
          >
            <VueSignaturePad
              v-if="!this.contractData.factorySignFileName"
              width="100%"
              height="100%"
              ref="signaturePad"
              class=""
              :options="{ onBegin, onEnd }"
            />
            <img
              class="sign-img"
              v-else
              :src="this.contractData.factorySignFileUrl"
            />
            <input
              class="hidden"
              type="file"
              id="file"
              accept=".gif, .jpg, .jpeg, .png, .ai, ,psd, .xlsx, .svg"
              @change="loadSign($event)"
            />
          </div>
          <p
            v-if="isSignError && !this.contractData.factorySignFileUrl"
            class="error-msg"
          >
            서명/사인을 입력해주세요.
          </p>
        </div>
      </div>

      <!-- <div class="mt-40" v-if="Number(contractData.contractStatus) <= 2"> -->
      <div
        v-if="compType == '2' && !this.contractData.factorySignFileUrl"
        class="middle last"
      >
        <p class="fieldtitle mt-20 ml-20">동의함 (필수)</p>
        <div class="agree">
          <p
            class="agree-title pointer"
            @click="agree = !agree"
            :class="agree ? 'active' : ''"
          >
            주의 안내 사항에 동의합니다.
          </p>
          <div class="agree-content">
            <p>
              - 본 발주서의 내용은 패션제조산업의 거래에서 발생하는 의류 제작 및
              생산에 대한 표준 발주서 형식을 기초로 작성 되었으며, 발주자와
              생산자의 작업 및 거래 발주 내용에 대한 이해를 돕고, 법률적인
              가이드를 제시하기 위한 목적 으로 제작되었으며, 이를 확인·인지하고
              작성하였음을 확인하였습니다.
            </p>
            <p>
              - 본 발주서는 법률적인 자문이나 해석을 위하여 제공된 것이 아님을
              확인하였습니다.
            </p>
            <p>
              - 오슬은 발주자와 생산자 간, 거래 및 작업 관계에는 일체 관여하지
              않으며, 납기 및 품질, 거래, 결제 등으로 발생하는 분쟁 및 기타
              손해에 대하여 “주식회사 위아더”는 법적인 책임을 지지 않으며, 관련
              책임은 당사자 간에 있음을 확인 하였습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-btns">
        <p
          class="bottom-btn"
          v-if="compType == '1'"
          @click="contractCancel(contractData.contractNo)"
        >
          발주 취소
        </p>
        <p
          v-if="step < 2"
          class="bottom-btn active"
          @click="clickStep(Number(step) + 1)"
        >
          다음
        </p>
        <!-- <p class="bottom-btn active" @click="gotoUrl('/contract/list')">확인</p> -->
        <p
          class="bottom-btn active"
          v-else-if="step == 2 && !(this.contractData.factorySignFileNo > 0)"
          @click="updaateContract()"
        >
          완료
        </p>
        <!-- <p
          class="bottom-btn active"
          v-else-if="this.contractData.factorySignFileNo > 0"
          @click="gotoPage('/contract/list')"
        >
          확인
        </p> -->
      </div>
    </div>
  </div>

  <div v-if="showModal && showModalId == 'send'" class="modal">
    <!-- <div class="modal"> -->
    <div class="modal-background" @click="setModal(false)"></div>
    <div class="modal-contract">
      <div class="modal-contract-body">
        <!-- <p class="title">{{ contractData.styleName }}</p>
          <p class="catg mt-20">{{ contractData.itemCatgName }}</p> -->
        <p class="title">
          <span>{{ contractData.designerCompName }}</span> 에게<br />
          서명/사인 하시겠습니까?
        </p>
        <p class="quest mt-36">서명 후에는 수정하실 수 없습니다.</p>
      </div>
      <div class="quest-btns">
        <p class="quest-btns-left" @click="setModal(false)">취소</p>
        <p class="quest-btns-right" @click="sendContract()">서명/사인</p>
      </div>
    </div>
  </div>

  <div v-if="showModal && showModalId == 'sendComplete'" class="modal">
    <div class="modal-background" @click="setModal(false)"></div>
    <div class="modal-contract">
      <div class="modal-contract-body">
        <p class="title">
          <span>{{ contractData.designerCompName }}</span> 와<br />
          계약서에 서명되었습니다.
        </p>
      </div>
      <div class="quest-btns">
        <p
          class="quest-btns-btn"
          @click="
            setModal(false);
            goChatRoom();
          "
        >
          완료
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { mapState } from "vuex";
import { MESSAGE_TYPE, CONTRACT_TYPE } from "@/constants/chat";
import { useMessage } from "@/composables/useMessage";
import { serverTimestamp } from "firebase/firestore";

export default {
  name: "ContractCom",
  props: {
    // contractData: {
    //   type: Object,
    // },
    contractNo: {
      type: String,
    },
  },
  computed: {
    ...mapState("meta", ["contract"]),
  },
  watch: {
    contractNo(contractNo) {
      this.$API.designOrder
        .getContractDetail(this.userId, contractNo)
        .then((res) => {
          console.log(res);
          this.contractData = res.data.body;
          this.step = 0;
          console.log(this.contractData);
        });
    },
  },
  mounted() {
    const { koreanTime } = useMessage();
    this.koreanTime = koreanTime;
    this.manager = inject("$firebaseManager");
    this.docId = localStorage.getItem("roomName");
    console.log(this.contractData);
    this.step = 0;
    // this.detailData = this.contractData;

    const contractNo = this.$route.params.contractNo
      ? this.$route.params.contractNo
      : this.contractNo;
    console.log(contractNo);
    this.$API.designOrder
      .getContractDetail(this.userId, contractNo)
      .then(async (res) => {
        console.log(res);
        this.contractData = res.data.body;
        this.step = 0;

        const docId = await this.manager.createChatRoom({
          designerId: this.contractData.designerUserId,
          factoryId: this.contractData.factoryUserId,
          updatedDate: serverTimestamp(),
        });

        this.manager.subscribeChatRoom(
          this.contractData.designerUserId,
          this.contractData.factoryUserId
        );
        this.docId = docId;
      });
  },
  data() {
    return {
      filters: {
        status: [
          { name: "대상자", cd: "0", isActive: true },
          { name: "작업 정보", cd: "1", isActive: false },
          { name: "납품·정산", cd: "2", isActive: false },
        ],
      },
      detailData: {},
      contractData: {},
      docId: "",
      step: 0,
      inputDeactive: true,
      v: "",
      agree: true,
      showModal: false,
      showModalId: "",
      isShowStyleAdd: true,
      isModify: false,
      isStyleError: false,
      isOrderError: false,
      isSignError: true,
      isProgress: true,
      koreanTime: null,
      userId: this.$route.query.userId || sessionStorage.getItem("userId"),
      compType:
        this.$route.query.compType || sessionStorage.getItem("compType"),
      testDate: [
        { index: "0", name: "7", cd: "1", isActive: false },
        { index: "1", name: "14", cd: "2", isActive: true },
        { index: "2", name: "21", cd: "3", isActive: false },
        { index: "3", name: "28", cd: "4", isActive: false },
      ],
      signImg: {},
      errorData: {},
      deliveryCount: 1,
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  methods: {
    contractCancel(contractNo) {
      this.$API.designOrder
        .cancelContract(sessionStorage.getItem("userId"), contractNo)
        .then((res) => {
          console.log(res);
          // this.contractData.factorySignFileNo = res.data.body.img.fileNo;
          // this.contractData.factorySignFileName =
          //   res.data.body.img.sourceFileName;
          // this.contractData.factorySignFileUrl = res.data.body.img.previewUrl;
          this.saveDataCancel(
            this.contractData.contractStyleList[0],
            contractNo
          );
          location.reload();
        });
    },
    clickStep(step) {
      if (typeof step == "number") {
        if (step !== 3) {
          this.$route.query.page = step;
          this.step = step;
          if (step == 2) {
            let errCnt = 0;
            this.contractData.contractStyleList.forEach((e) => {
              !e.styleName ? errCnt++ : errCnt;
              !e.colorCount ? errCnt++ : errCnt;
              !e.sizeCount ? errCnt++ : errCnt;
              !e.quantity ? errCnt++ : errCnt;
              !e.unitCost ? errCnt++ : errCnt;
              // !e.colorCount? errCnt++ : errCnt
            });
            if (errCnt) {
              this.isStyleError = true;
              this.$route.query.page = step - 1;
              this.step = step - 1;
            }
          }
        }
      } else {
        if (step == "modify") {
          this.isModify = true;
        } else if (step == "modified") {
          //update comp info
          this.isModify = false;
        }
      }
    },
    setModal(isShow, modalId) {
      this.showModal = isShow;
      this.showModalId = modalId || "";
      this.backgroundScroll(isShow);
    },
    onBegin() {
      this.isSignError = true;
    },
    onEnd() {
      this.isSignError = false;
    },
    clearSign() {
      this.$refs.signaturePad.clearSignature();
    },
    loadSign(event) {
      this.$API.comm
        .fileUpload(sessionStorage.getItem("userId"), event.target.files[0])
        .then((res) => {
          console.log(res);
          this.contractData.factorySignFileNo = res.data.body.img.fileNo;
          this.contractData.factorySignFileName =
            res.data.body.img.sourceFileName;
          this.contractData.factorySignFileUrl = res.data.body.img.previewUrl;
        });
    },
    updaateContract() {
      if (!this.isSignError) {
        console.log(this.contractData.factorySignFileNo);
        if (!(this.contractData.factorySignFileNo > 0)) {
          const { isEmpty, data } =
            this.$refs.signaturePad.saveSignature("image/png");
          this.$API.comm
            .fileUpload(this.userId, this.base64toFile(data, "sign.png"))
            .then((res) => {
              if (res.data.resultCode == "10000") {
                this.detailData.factorySignFileNo = res.data.body.img.fileNo;
                this.detailData.factorySignFileName =
                  res.data.body.img.sourceFileName;
                this.detailData.factorySignFileUrl =
                  res.data.body.img.previewUrl;
                console.log(this.detailData);
                this.setModal(true, "send");
              } else {
                this.setMsgPopup(
                  true,
                  "서버가 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
                );
              }
            });
        } else {
          this.setModal(true, "send");
        }
      } else {
        this.isSignError = true;
        var element = document.querySelector(".contract-detail");
        element.scrollTo(0, element.scrollHeight);
      }
    },
    sendContract() {
      let designOrderNo = this.designOrderNo
        ? this.designOrderNo
        : this.$route.params.designOrderNo;
      let factoryNo = this.factoryNo
        ? this.factoryNo
        : this.$route.params.factoryNo;
      this.isProgress = false;
      this.isOrderError = false;
      this.detailData.contractNo = this.contractData.contractNo;
      this.detailData.designOrderNo = this.contractData.designOrderNo;
      this.detailData.factoryNo = this.contractData.factoryNo;
      this.detailData.userId = this.userId;
      this.detailData.recruitStatus = "3";
      console.log(this.detailData);
      this.$API.designOrder
        .modifyContractFactorySign(this.detailData)
        .then((res) => {
          if (res.data.resultCode == "10000") {
            this.setModal(true, "sendComplete");
          }
          const contractNo = res.data.body;
          if (res) {
            // 계약 성공
            this.goToChatRoom();
            this.saveData(this.contractData.contractStyleList[0], contractNo);
            // console.log(this.contractData.contractStyleList[0]);
          }
        });

      // this.goToChatRoom();
    },
    async saveData(x, contractNo) {
      const roomName = this.docId;
      console.log(roomName);

      // 스타일 이름, 금액, 보낸 시간, 계약서 번호, 보낸 사람, 파일 타입
      let args = {
        contractNo: contractNo,
        contractType: CONTRACT_TYPE.SUCCESS,
        designOrderNo: this.contractData.designOrderNo,
        factoryNo: this.contractData.factoryNo,
        styleName: x.styleName,
        totalCost: this.comma(
          this.contractData.totalCostVat - this.contractData.depositCost
        ),
        type: MESSAGE_TYPE.CONTRACT,
        createDate: this.koreanTime,
        sender: this.userId,
        readFlag: 0,
      };

      console.log(args);
      this.manager.sendMessage(args, roomName);
      this.goToChatRoom();
    },
    async saveDataCancel(x, contractNo) {
      const roomName = this.docId;
      console.log(roomName);
      console.log(x);
      console.log(contractNo);

      // 스타일 이름, 금액, 보낸 시간, 계약서 번호, 보낸 사람, 파일 타입
      let args = {
        contractNo: contractNo,
        contractType: CONTRACT_TYPE.CANCEL,
        designOrderNo: this.contractData.designOrderNo,
        factoryNo: this.contractData.factoryNo,
        styleName: x.styleName,
        totalCost: this.comma(
          this.contractData.totalCostVat - this.contractData.depositCost
        ),
        type: MESSAGE_TYPE.CONTRACT,
        createDate: this.koreanTime,
        sender: this.userId,
        readFlag: 0,
      };

      console.log(args);
      this.manager.sendMessage(args, roomName);
      // this.goToChatRoom();
    },
    // goChatRoom() {
    //   // this.$router.push(`/chats/${this.docId}`);
    //   if (this.isMobile == "true") {
    //     this.$router.push({
    //       path: `/chats/${this.docId}`,
    //     });
    //   } else {
    //     this.$router.push({
    //       path: `/webchats/chat/${this.docId}`,
    //     });
    //   }
    // },

    async goToChatRoom() {
      const docId = await this.manager.createChatRoom({
        designerId: this.contractData.designerUserId,
        factoryId: this.userId,
        updatedDate: serverTimestamp(),
      });

      if (this.isMobile == "true") {
        this.$router.push(`/chats/${docId}`);
      } else {
        this.$router.push(`/webchats/chat/${docId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-content {
  background-color: $neutral-0;
  .line {
    border-bottom: 1px solid #e6e6e6;
  }

  .top {
    border-bottom: 1px solid $primary-20;
    padding: 48px 0 20px;
    margin: 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .square {
      width: 8px;
      height: 8px;
      background-color: $primary-50;
    }
    .title {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: $primary-80;
    }
  }
  .contract-step {
    gap: 8px;
    position: relative;
    justify-content: space-between;
    &-tab {
      padding: 0 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $neutral-60;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      .issue-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        border-style: solid;
        border-color: $neutral-20;
        &:nth-child(1) {
          border-radius: 4px 0 0 4px;
          border-width: 1px 0 1px 1px;
        }
        &:nth-child(2) {
          border-width: 1px 0 1px 0;
        }
        &:nth-child(3) {
          border-radius: 0 4px 4px 0;
          border-width: 1px 1px 1px 0;
        }
        &.active {
          border-radius: 4px;
          color: $neutral-0;
          border-width: 20px;
          border-style: solid;
          background-color: $primary-50;
          border-color: $primary-50;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: calc(100% - 32px);
      height: 1px;
      background-color: $neutral-40;
      z-index: 1;
    }

    &-page {
      margin-top: 20px;
      padding-bottom: 32px;
      &-section {
        // padding: 40px 16px;
        .middle {
          padding: 20px 20px;
          &-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
          }
          &.last {
            border-bottom: 0px;
          }
        }
        .comp {
          width: 100px;
          height: 32px;
          border: 1px solid $neutral-20;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
        .field-list {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .price-list {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .field {
          display: flex;
          &.column {
            flex-direction: column;
            gap: 12px;
          }
        }
        .price {
          display: flex;
          justify-content: space-between;
          .name {
            flex: 4;
          }
        }
        .deposit {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 16px 20px;
          background-color: $neutral-3;
          border-radius: 4px;
          .name {
            flex: 1.5;
          }
        }
        .name {
          display: flex;
          align-items: center;
          flex: 1;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-70;
          &.red {
            color: $primary-60;
          }
          &.start {
            align-items: flex-start;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          // align-items: center;
          align-items: flex-start;
          flex: 2;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-60;
          &.red {
            color: $primary-60;
          }
          &.end {
            align-items: flex-end !important;
          }
          .error-msg {
            margin: 4px 8px 0;
            color: $secondary-60;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
          }
          .filter-menu {
            margin: 0 8px;
            width: 76px;
            &-value-txt {
              text-align: center;
              font-weight: 400;
            }
          }
          &img {
            width: 20px;
            height: 20px;
          }
        }
        .img-name {
          display: grid;
          // flex-direction: column;
          gap: 20px;
          align-items: flex-start;
          flex-wrap: wrap;
          grid-template-columns: repeat(auto-fill, 100px);
          &.red {
            color: $secondary-60;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            border: 1px solid $neutral-10;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
        .txt {
          padding: 8px;
          background: $neutral-0;
          border-width: 0px 0px 1px 1px;
          border-style: solid;
          border-color: $neutral-20;
          border-radius: 4px;
          width: 100%;
          height: 32px;
          &.error {
            border-color: $secondary-60;
          }
        }
        .img {
          padding: 8px;
          background: $neutral-0;
          border-width: 0px 0px 1px 0px;
          border-style: solid;
          border-color: $neutral-20;
          border-radius: 4px;
          width: 100%;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .uncertified {
          border-radius: 20px;
          background-color: $neutral-3;
          padding: rem(52px) rem(24px) rem(62px);
          font-weight: 500;
          text-align: center;
          .ex {
            line-height: 24px;
            color: $neutral-90;
          }
          .ex-tel {
            color: $neutral-50;
          }
          .ex-btn {
            border-radius: 7px;
            background-color: $primary-50;
            padding: 10px;
            font-weight: bold;
            color: $neutral-0;
            cursor: pointer;
          }
        }
        .delivery {
          border: 1px solid $neutral-10;
          border-radius: 12px;
          &-row {
            overflow: hidden;
            &:first-child {
              border-radius: 12px 12px 0 0;
            }
            &:last-child {
              border-radius: 0 0 12px 12px;
            }
            & + .delivery-row {
              border-top: 1px solid $neutral-10;
            }
            &-title {
              padding: 16px 24px;
              font-weight: 500;
              color: $neutral-60;
              background-color: $neutral-3;
            }
            .date {
              margin-right: 16px;
            }
          }
        }
      }
      .style-add {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin: 32px 20px;
        border: 1px solid $primary-30;
        border-radius: 4px;
        p {
          color: $primary-60;
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
  .radio-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      color: $neutral-40;
      cursor: pointer;
      & + .btn-radio {
        margin-left: 20px;
      }
      &::before {
        content: "";
        background-image: url("@/assets/icon/ic-radio-off.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: rem(20px);
        height: rem(20px);
        margin-right: rem(6px);
      }
      &.active {
        color: $neutral-70;
        &::before {
          background-image: url("@/assets/icon/ic-radio-on.svg");
        }
      }
    }
  }
  .agree {
    margin: 20px;
    border: 1px solid $neutral-20;
    border-radius: 4px;
    border-width: 0 1px 1px 1px;
    position: relative;
    &-title {
      border: 1px solid $neutral-20;
      border-radius: 4px;
      position: relative;
      padding: rem(13px) rem(20px);
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $neutral-60;
      position: absolute;
      width: calc(100% + 2px);
      left: -1px;
      top: 0;
      background-color: $neutral-0;
      &::after {
        content: "";
        background-image: url("@/assets/icon/ic-check-off.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: rem(18px);
        height: rem(18px);
        position: absolute;
        right: rem(20px);
        top: 50%;
        border-color: $primary-50;
        transform: translateY(-50%);
      }
      &.active {
        color: $primary-60;
        border: 1px solid $primary-50;
        border-radius: 4px;
        &::after {
          background-image: url("@/assets/icon/ic-check-on.svg");
        }
      }
    }
    &-content {
      font-size: 12px;
      line-height: 18px;
      color: $neutral-70;
      padding: rem(20px) rem(16px);
      padding-top: 70px;
      p {
        margin-bottom: 6px;
      }
    }
  }
  .payment-ex {
    &-title {
      font-weight: 500;
      color: $neutral-50;
    }
    &-box {
      font-size: 12px;
      color: $neutral-70;
      line-height: 22px;
      border: 1px solid $neutral-10;
      border-width: 1px 0 1px 0;
      &-bg {
        background-color: $neutral-5;
        padding: 16px;
        margin: 12px 0;
      }
    }
  }
  .imgs {
    @include set-grid(100px, 10px, 10px);
    &-img {
      width: 100px;
      height: 100px;
      border: 1px dashed #c4c4c4;
      position: relative;
      .wording {
        margin-top: 8px;
        line-height: 20px;
        color: #474747;
      }
      img {
        height: 100%;
      }
      &.active {
        border: 1px solid $primary-50;
        background-color: $primary-50;
        font-size: 32px;
        color: $neutral-0;
      }
      &-pop {
        position: absolute;
        top: 0;
        right: 0;
        width: rem(34px);
        height: rem(34px);
        border-radius: 17px;
        background-color: rgba(113, 98, 79, 0.4);
        p {
          font-size: 16px;
          color: $neutral-0;
        }
      }
      &-filename {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 40px;
        padding: rem(6px);
        background-color: rgba(0, 0, 0, 0.4);
        p {
          @include ellipse(2);
          font-size: 12px;
          color: $neutral-0;
          text-align: center;
          word-break: break-all;
        }
      }
    }
  }
  .sign {
    margin: 0 20px;
    border: 1px solid $neutral-20;
    position: relative;
    // margin: 0 auto;
    // width: 100%;
    height: 240px;
    overflow: hidden;
    // padding-bottom: 40%;
    border: 1px solid $neutral-20;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 240px;
    }
    &.error {
      border-color: $secondary-60;
    }
    canvas {
      // position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("@/assets/icon/ic-contract-sign.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    &-img {
      height: 100%;
    }
    .resign {
      position: absolute;
      margin: 0 5%;
      padding: 12px;
      left: 0;
      bottom: 12px;
      width: 90%;
      text-align: center;
      font-weight: 700;
      color: $neutral-0;
      background-color: $primary-50;
      border-radius: 7px;
      cursor: pointer;
    }
  }

  .error-msg {
    margin: 4px 20px 0;
    color: $secondary-60;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
  .load-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 20px;
    color: $primary-50;
    border: 1px solid $primary-30;
    border-radius: 4px;
  }
}
.modal {
  &-contract {
    background-color: $neutral-0;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: fixed;
    z-index: 1001 !important;
    left: 50%;
    top: 50%;
    width: calc(100% - 48px);
    max-width: 320px;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $neutral-100;
    &-body {
      padding: 32px 20px;
      display: flex;
      flex-direction: column;
      .title {
        color: $neutral-100;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        br {
          content: "";
          display: block;
          height: 12px;
        }
        span {
          color: $primary-60;
        }
      }
      .quest {
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        span {
          color: $primary-60;
        }
      }
    }
    .quest-btns {
      display: flex;
      height: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: $neutral-0;
      &-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-radius: 0px 0px 8px 8px;
      }
      &-left {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $neutral-60;
        border-bottom-left-radius: 8px;
      }
      &-right {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.bottom {
  // @include set-width(true);
  @media (max-width: 520px) {
    position: absolute;
  }
  z-index: 1;
  bottom: 0;
  position: fixed;
  max-width: 520px;
  width: 100%;
  &-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    height: 40px;
    color: $neutral-0;
    background-color: $neutral-50;
    border: 1px solid $neutral-50;
    cursor: pointer;
    &.active {
      background-color: $primary-50;
      border: 1px solid $primary-50;
    }
    &.exit {
      color: $primary-50;
      background-color: $neutral-0;
      border: 1px solid $primary-50;
    }
    &.gray {
      background-color: $neutral-60;
      border: 1px solid $neutral-60;
    }
  }
}
</style>
