<template>
  <div class="order">
    <div class="order-wrap">
      <div class="order-wrap-top">
        <div class="status-end" v-if="detailData.recruitStatus > 3">마감</div>
        <div class="work-process">
          <p v-if="detailData.workProcess == '01031'" class="process1">봉제</p>
          <p v-if="detailData.workProcess == '01033'" class="process2">
            패턴/샘플
          </p>
          <p v-if="detailData.workProcess == '01034'" class="process3">자수</p>
          <p v-if="detailData.workProcess == '01035'" class="process4">
            나염/전사
          </p>
          <p v-if="detailData.workProcess == '01038'" class="process5">
            완사입
          </p>
        </div>
        <p class="style-name" v-html="detailData.styleName"></p>
      </div>
      <div class="tags" v-if="detailData.hashTag">
        <p
          class="tags-tag"
          v-for="(x, i) in detailData?.hashTag
            ?.split(',')
            .filter((e) => e != ' ' && e != '')"
          :key="i"
        >
          {{ x.replace("#", "") }}
        </p>
      </div>
    </div>
    <div class="order-wrap">
      <div class="title"><p>작업 조건</p></div>
      <div class="info">
        <div
          class="row"
          v-if="detailData.styleType"
          :class="{ mb: isMobile == 'true' }"
        >
          <p class="name">· 스타일</p>
          <p class="value" :class="{ 'ml-10': isMobile == 'true' }">
            {{ detailData.styleType }}
          </p>
        </div>
        <div
          class="row"
          v-if="detailData.itemList?.length > 0"
          :class="{ mb: isMobile == 'true' }"
        >
          <p class="name">· 품목</p>
          <div
            class="value item"
            v-if="detailData.itemCatgCd"
            :class="{ 'ml-10': isMobile == 'true' }"
          >
            <p
              v-for="(y, j) in detailData.itemList.length > 3
                ? detailData.itemList.filter((item, index) => index !== 2)
                : detailData.itemList"
              :key="j"
              class="flex gap4"
            >
              <SvgImg
                v-if="j > 0"
                :size="20"
                :d="$store.state.meta.svg.rightArrowBig"
                :color="$store.state.meta.svg.color.neutral30"
              />
              <span
                :style="
                  detailData.itemList.length - 1 == j ? 'color: #F49301;' : ''
                "
                >{{ y }}</span
              >
            </p>
          </div>
          <div class="value" v-else>
            <p v-for="(y, j) in detailData.itemList" :key="j" class="flex gap4">
              <span
                :style="
                  detailData.itemList.length - 1 == j ? 'color: #F49301;' : ''
                "
                >{{ y }}</span
              >
            </p>
          </div>
        </div>
        <div
          class="row"
          v-else-if="detailData.catg"
          :class="{ mb: isMobile == 'true' }"
        >
          <p class="name">· 품목</p>
          <div class="value" :class="{ 'ml-10': isMobile == 'true' }">
            <p>{{ detailData.catg }}</p>
            <img
              src="@/assets/icon/ic-chevron-right-60.svg"
              v-if="detailData.fabric"
            />
            <p v-if="detailData.fabric">{{ detailData.fabric }}</p>
          </div>
        </div>
        <div
          class="row"
          v-else-if="detailData.itemCatgName"
          :class="{ mb: isMobile == 'true' }"
        >
          <p class="name">· 품목</p>
          <div class="value" :class="{ 'ml-10': isMobile == 'true' }">
            <p>{{ detailData.itemCatgName }}</p>
          </div>
        </div>
        <div
          class="row"
          :class="{ mb: isMobile == 'true' }"
          v-if="
            detailData.workProcess == '01033' ||
            ((detailData.workProcess == '01034' ||
              detailData.workProcess == '01035') &&
              (detailData.productFlag == '1' || detailData.cuttingFlag == '1'))
          "
        >
          <p class="name">· 작업</p>
          <div
            class="value gap12"
            v-if="detailData.workProcess == '01033'"
            :class="{ 'ml-10': isMobile == 'true' }"
          >
            <p v-if="detailData.workType == '1' || detailData.workType == '3'">
              패턴
            </p>
            <p v-if="detailData.workType == '2' || detailData.workType == '3'">
              샘플
            </p>
          </div>
          <div
            class="value gap12"
            v-if="
              detailData.workProcess == '01034' ||
              detailData.workProcess == '01035'
            "
            :class="{ 'ml-10': isMobile == 'true' }"
          >
            <p v-if="detailData.productFlag == '1'">완제품</p>
            <p v-if="detailData.cuttingFlag == '1'">재단물</p>
          </div>
        </div>
        <div
          class="row"
          v-if="detailData.workProcess == '01031'"
          :class="{ mb: isMobile == 'true' }"
        >
          <p class="name">· 총 수량</p>
          <p class="value" :class="{ 'ml-10': isMobile == 'true' }">
            {{ detailData.quantity }}
          </p>
        </div>
        <div class="row" :class="{ mb: isMobile == 'true' }">
          <p class="name">· 납품 희망일</p>
          <p
            class="value"
            v-if="detailData.deliveryDate"
            :class="{ 'ml-10': isMobile == 'true' }"
          >
            {{ detailData.deliveryDate }}
          </p>
          <p class="value" v-else :class="{ 'ml-10': isMobile == 'true' }">
            {{ detailData.startDate }} ~ {{ detailData.endDate }} 이내
          </p>
        </div>
      </div>
    </div>
    <div class="order-wrap">
      <div class="title"><p>이미지</p></div>
      <div
        v-if="
          userId == detailData.userId || (compType == '2' && registFlag == '2')
        "
      >
        <div class="fileupload-wrap" v-if="imgList?.length != 0">
          <div class="preview">
            <p class="img-index">{{ currImg }} / {{ imgList.length }}</p>
            <!-- [{{ imgList }}] -->
            <img
              v-if="regImgUrl"
              @click="showImg(regImgUrl, regImgName)"
              :src="regImgUrl"
            />
          </div>
          <div class="thumbnails">
            <Swiper
              class="thumbnails-list mt-12"
              :slidesPerView="isMobile == 'true' ? 4 : 5"
              :modules="modules"
              :spaceBetween="8"
            >
              <SwiperSlide
                v-for="(y, j) in imgList"
                :key="j"
                class="thumbnails-list-area pointer"
                :class="y.sourceFileName.split('.')[1]"
                @click="clickThumb(y.fileUrl, y.sourceFileName, j)"
              >
                <!-- @click="moveSlide(j)" -->
                <img
                  v-if="
                    ['.psd', '.ai', '.pdf', '.xlsx'].some(
                      (e) => y.sourceFileName.indexOf(e) != -1
                    )
                  "
                  :src="
                    require(`@/assets/img/thumbnail-${
                      y.sourceFileName.split('.')[1]
                    }.png`)
                  "
                />
                <img v-else :src="y.fileUrl" />
                <!-- <a @click="downloadImg(y.fileUrl, y.sourceFileName)" download=""
                  ><img :src="y.fileUrl"
                /></a> -->
                <!-- <a :href="y.fileUrl" download><img :src="y.fileUrl" /></a> -->
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div v-else class="empty-content">
        사업자 인증이 완료된
        <br v-if="$store.state.meta.windowWidth < 380" />‘공장 회원’에게만
        공개됩니다.
      </div>
    </div>
    <div class="order-wrap" v-if="detailData.workDesc">
      <div class="title"><p>부가 설명</p></div>
      <div
        v-if="
          userId == detailData.userId || (compType == '2' && registFlag == '2')
        "
        v-html="detailData.workDesc?.split('\n').join('<br />')"
      ></div>
      <div v-else class="empty-content">
        사업자 인증이 완료된
        <br v-if="$store.state.meta.windowWidth < 380" />‘공장 회원’에게만
        공개됩니다.
      </div>
    </div>
    <div class="order-wrap">
      <div class="title">
        <p>발주자 정보</p>
        <p
          v-if="userId == detailData.userId"
          class="modify-btn"
          @click="gotoPage('/mypage/profile')"
        >
          정보 수정
        </p>
      </div>
      <div
        v-if="
          userId == detailData.userId || (compType == '2' && registFlag == '2')
        "
      >
        <div class="info">
          <div class="row">
            <p class="name">· 보유 브랜드</p>
            <div class="value">
              <p v-if="designerInfo?.brandName1">
                {{ designerInfo?.brandName1 }}
              </p>
              <p v-if="designerInfo?.brandName2">
                {{ designerInfo?.brandName2 }}
              </p>
              <p v-if="designerInfo?.brandName3">
                {{ designerInfo?.brandName3 }}
              </p>
              <p v-if="designerInfo?.brandName4">
                {{ designerInfo?.brandName4 }}
              </p>
              <p v-if="designerInfo?.brandName5">
                {{ designerInfo?.brandName5 }}
              </p>
              <p v-if="designerInfo?.brandName6">
                {{ designerInfo?.brandName6 }}
              </p>
            </div>
          </div>
          <div class="row">
            <p class="name">· 주요 유통 채널</p>
            <div class="value">
              <p v-if="designerInfo?.channelName1">
                {{ designerInfo?.channelName1 }}
              </p>
              <p v-if="designerInfo?.channelName2">
                {{ designerInfo?.channelName2 }}
              </p>
              <p v-if="designerInfo?.channelName3">
                {{ designerInfo?.channelName3 }}
              </p>
              <p v-if="designerInfo?.channelName4">
                {{ designerInfo?.channelName4 }}
              </p>
              <p v-if="designerInfo?.channelName5">
                {{ designerInfo?.channelName5 }}
              </p>
              <p v-if="designerInfo?.channelName6">
                {{ designerInfo?.channelName6 }}
              </p>
            </div>
          </div>
          <div class="row">
            <p class="name">· 주요 매출처</p>
            <div class="value">
              <p v-for="(result, i) in designerInfo?.dealerList" :key="i">
                {{ result.commName }}
              </p>
            </div>
          </div>
          <div class="row">
            <p class="name">· 대표 사이트</p>
            <div class="value column">
              <p v-if="designerInfo?.siteUrl">
                {{ designerInfo?.siteUrl }}
              </p>
              <p v-if="designerInfo?.siteUrl1">
                {{ designerInfo?.siteUrl1 }}
              </p>
              <p v-if="designerInfo?.siteUrl2">
                {{ designerInfo?.siteUrl2 }}
              </p>
              <p v-if="designerInfo?.siteUrl3">
                {{ designerInfo?.siteUrl3 }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty-content">
        사업자 인증이 완료된
        <br v-if="$store.state.meta.windowWidth < 380" />‘공장 회원’에게만
        공개됩니다.
      </div>
    </div>

    <div
      class="order-btn"
      @click="$router.go(-1)"
      v-if="isMobile == 'false' && $route.path.indexOf('/detail/order/') >= 0"
    >
      <SvgImg
        :size="20"
        :d="$store.state.meta.svg.list"
        :color="$store.state.meta.svg.color.neutral0"
      />
      <p>목록</p>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
export default {
  name: "CompDesignOrderDetail",
  props: {
    detailData: {
      type: Object,
      description: "Card data",
    },
    designerInfo: {
      type: Object,
      description: "Card data",
    },
    imgData: {
      type: Object,
      description: "Image data",
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      d: {},
      i: [],
      imgList: [],
      regImgUrl: "",
      regImgName: "",
      swiperIndex: 0,
      currImg: 1,
      tagList: [],
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get registFlag() {
        return sessionStorage.getItem("registFlag");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  watch: {
    imgData(newVal) {
      this.i = newVal;
      this.imgList = [];
      const regImg = this.imgData.filter((e) => e.fileStatus == "35")[0];
      if (regImg) this.imgList.push(regImg);
      this.i.forEach((e) => {
        if (e.fileStatus != "35") {
          this.imgList.push(e);
        }
      });
      this.regImgUrl = this.imgList[0].fileUrl;
      this.regImgName = this.imgList[0].sourceFileName;
    },
  },
  mounted() {
    this.d = this.detailData;
    if (this.imgData.length > 0) {
      const regImg = this.imgData.filter((e) => e.fileStatus == "35")[0];
      if (regImg) this.imgList.push(regImg);
      this.imgData.forEach((e) => {
        if (e.fileStatus != "35") {
          this.imgList.push(e);
        }
      });
    }

    // if (!this.userId) {
    //   setTimeout(() => {
    //     this.blurPage();
    //   }, 100);
    // }
  },
  methods: {
    updateSwiper() {
      this.swiperIndex = document.getElementById("imgSwiper").swiper.realIndex;
    },
    clickThumb(fileUrl, fileName, index) {
      this.regImgUrl = fileUrl;
      this.regImgName = fileName;
      this.currImg = index + 1;
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    bookmark(index, designOrderNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo);
    },
    downloadImg(imageUrl, sourceFileName) {
      imageUrl =
        "http://cdn.osle.co.kr/upload/20190507/28771_20190507192106.jpg";
      console.log(imageUrl, sourceFileName);
      // download(imageUrl, sourceFileName);
    },
    blurPage() {
      const pTag = document.querySelectorAll("p");
      const imgTag = document.getElementById("imgFileClass");
      console.log(imgTag);
      pTag.forEach((e) => {
        console.log(e.className);
        console.log(e.className.indexOf("name"));
        console.log(e.className.indexOf("title"));
        if (
          e.className.indexOf("name") == -1 &&
          e.className.indexOf("title") == -1
        ) {
          e.classList.add("mosaic");
        }
      });
      imgTag.classList.add("mosaic");
      // imgTag.forEach((e) => {
      //   imgTag.classList.add("mosaic");
      // });

      // mosaic;
    },
    moveSlide(pageIndex) {
      document
        .getElementById("imgSwiper")
        .swiper.slideTo(pageIndex, 500, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  width: 100%;
  padding: 20px 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: 520px) {
    padding: 40px 20px;
  }
  &-wrap {
    width: 100%;
    padding: 24px;
    background-color: $neutral-0;
    border-radius: 8px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    @media (max-width: 520px) {
      padding: 20px;
    }
    &-top {
      display: flex;
      align-items: center;
      gap: 16px;
      .status-end {
        display: flex;
        padding: 4px 12px;
        font-size: 14px;
        font-weight: 500;
        color: $neutral-0;
        border-radius: 4px;
        height: 28px;
        background-color: $neutral-60;
      }
      .work-process {
        p {
          display: flex;
          padding: 4px 12px;
          font-size: 14px;
          font-weight: 500;
          color: $neutral-0;
          border-radius: 4px;
          height: 28px;
        }
        .process1 {
          background-color: #04bb6a;
        }
        .process2 {
          background-color: #8c29cc;
          width: 100%;
          max-width: 82px;
        }
        .process3 {
          background-color: #ff6433;
        }
        .process4 {
          background-color: #07bbdf;
          width: 100%;
          max-width: 82px;
        }
      }
      .style-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .tags {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      overflow: hidden;
      p {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 26px;
        background: $primary-5;
        color: $primary-60;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .title {
      padding: 2px 0;
      display: flex;
      justify-content: space-between;
      p {
        height: 26px;
        border-bottom: 1px solid $primary-50;
        font-size: 16px;
        font-weight: 500;
      }
      .modify-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26px;
        padding: 8px;
        border-radius: 4px;
        background: $primary-50;
        color: $neutral-0;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .info {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 28px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-60;
      .row {
        position: relative;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        &.mb {
          flex-direction: column;
          gap: 4px;
        }
        .name {
          font-weight: 500;
        }
        .value {
          position: sticky;
          display: flex;
          align-items: center;
          gap: 4px;
          font-weight: 400;
          left: 136px;
          overflow: hidden;
          white-space: nowrap;
          :nth-child(n) {
            display: flex;
            align-items: center;
          }
          &.item {
            p:last-child {
              color: $primary-60;
            }
          }
          @include mobile {
            left: 46px;
          }
          &.column {
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
          }
        }
      }
    }
    .fileupload-wrap {
      margin-top: 28px;
      .img-swiper {
        :deep(.swiper) {
          border: 1px solid $neutral-20;
          border-radius: 4px;
          width: 100%;
          height: 348px;
          .swiper-button-prev,
          .swiper-button-next {
            color: rgba(255, 255, 255, 0.7);
            font-weight: bold;
          }
          .swiper-slide {
            background-color: $neutral-0;
            // flex-direction: column;
            .swiper-img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
              &.file {
                padding: 120px;
              }
            }
          }
        }
      }
      .file-name {
        margin: 12px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $neutral-60;
      }
      .preview {
        position: relative;
        overflow: hidden;
        display: flex;
        width: 100%;
        aspect-ratio: 1 / 1; /* 최신 브라우저에서 지원하는 속성 */
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid $neutral-10;
        .img-index {
          top: 20px;
          position: absolute;
          color: $neutral-60;
          font-size: 14px;
          font-weight: 700;
        }
        img {
          height: 100%;
        }
      }
      .thumbnails {
        display: flex;
        flex-direction: column;
        width: 100%;
        &-list {
          gap: 8px 7px;
          display: flex;
          justify-content: flex-start;
          width: 100%;
          &-area {
            aspect-ratio: 1 / 1; /* 최신 브라우저에서 지원하는 속성 */
            // height: 92px;
            border: 1px solid $neutral-10;
            border-radius: 4px;
            // @media (min-width: 480) {
            //   width: 92px;
            // }
            &:hover {
              border: 1px solid $primary-50;
            }
            &.psd {
              border-color: #60b2ff;
            }
            &.ai {
              border-color: $primary-50;
            }
            &.psd,
            &.ai,
            &.xlsx {
              img {
                padding: 10px;
              }
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    .text-area {
      padding: 20px;
      border: 1px solid $neutral-20;
      // background-color: $neutral-3;
      border-radius: 4px;
      overflow-wrap: break-word;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-80;
    }
    .empty-content {
      color: $neutral-60;
      margin-top: 28px;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      color: $secondary-60;
      @media (max-width: 520px) {
        margin-top: 24px;
      }
    }
  }
  &-btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $neutral-0;
    background-color: $primary-50;
    width: 360px;
    height: 40px;
    border-radius: 4px;
  }
}
.mosaic {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
</style>
