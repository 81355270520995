<template>
  <div class="notice-list">
    <div class="notice-list-filter" v-if="isMobile == 'false'">
      <p>공지사항 내역</p>
    </div>
    <div class="notice-list-view">
      <div v-if="!noticeData" class="no-notice">
        <p>받은 알림이 없습니다.</p>
      </div>
      <div
        v-else
        v-for="(x, i) in noticeData"
        :key="i"
        class="notice-list-view-item pointer"
        :class="noticeNo == x.noticeNo ? 'active' : ''"
        @click="goNoticeDetail(i, x.noticeNo)"
      >
        <div class="notice-list-view-item-wrap">
          <p class="date">
            {{ x.createdDate }}
          </p>
          <p>
            {{ x.noticeTitle }}
          </p>
        </div>
      </div>
      <div class="loader" v-if="isLoading">
        <FadeLoader :loading="true" height="10px" width="10px"></FadeLoader>
      </div>
    </div>
  </div>
</template>

<script>
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
export default {
  name: "NoticeListComp",
  components: { FadeLoader },
  data() {
    return {
      filters: {
        read: [
          { name: "전체", cd: "" },
          { name: "읽지않음", cd: "0" },
        ],
        state: [
          { name: "전체", cd: "", isActive: true, index: 0 },
          { name: "공지", cd: "1", isActive: false, index: 1 },
          { name: "알림", cd: "2", isActive: false, index: 2 },
        ],
      },
      sortOrder: 0,
      readingFlag: "",
      noticeStatus: "",
      searchText: "",
      noticeData: [],
      noticeDetail: {},
      noticeNo: "",
      pageNow: 0,
      pageList: 20,
      isLoading: true,
      totalCount: 0,
      isMobile: sessionStorage.getItem("isMobile"),
    };
  },
  mounted() {
    this.updateNoticeList(true);
    this.setPopup(false);
    document.addEventListener("scroll", this.infinityCheck);
  },
  methods: {
    readAll() {
      this.$API.mypage
        .noticeReadAll(sessionStorage.getItem("userId"))
        .then((res) => {
          this.updateNoticeList(true);
        });
    },
    updateNoticeList(isReset) {
      console.log(this.searchText);
      this.isLoading = true;
      if (isReset) {
        this.pageNow = 0;
      }
      this.pageNow++;
      this.$API.mypage
        .getNoticeList(
          sessionStorage.getItem("userId"),
          this.noticeStatus,
          this.readingFlag,
          this.searchText,
          this.pageNow,
          this.pageList
        )
        .then((res) => {
          console.log(res);
          if (res.data.resultCode == "10000") {
            if (isReset) {
              this.noticeData = res.data.body.resNoticeList;
              this.totalCount = res.data.body.totalCount;
            } else {
              res.data.body.resNoticeList.forEach((e) => {
                this.noticeData.push(e);
              });
            }
          } else {
            if (isReset) {
              this.noticeData = [];
              this.totalCount = 0;
            }
          }
          this.isLoading = false;
        });
    },
    noticeRead(i) {
      if (this.noticeData[i].readingFlag != "1") {
        this.noticeData[i].readingFlag = "1";
        this.$API.mypage
          .postNoticeRead(
            sessionStorage.getItem("userId"),
            this.noticeData[i].noticeNo
          )
          .then(() => {
            this.$API.mypage
              .noticeCheck(sessionStorage.getItem("userId"))
              .then((res) => {
                console.log(res);
              });
          });
      }
      console.log(this.noticeData[i].noticeType);
      console.log(this.noticeData[i].noticeUrl);
      if (this.noticeData[i].noticeType == "1") {
        this.$emit(
          "goNoticeDetail",
          this.noticeData[i].noticeType,
          this.noticeData[i].title,
          this.noticeData[i].content,
          this.noticeData[i].createdDate
        );
      } else {
        this.gotoPage(this.noticeData[i].noticeUrl);
      }
    },
    goNoticeDetail(i, noticeNo) {
      this.noticeNo = noticeNo;
      this.$API.mypage
        .getNoticeDetail(sessionStorage.getItem("userId"), noticeNo)
        .then((res) => {
          let d = res.data.body;
          console.log(d);
          this.$emit(
            "goNoticeDetail",
            d.noticeTitle,
            d.noticeContents,
            d.createdDate,
            d.noticeNo
          );
        });
    },
    selectData(data, type) {
      if (type === "state") {
        this.noticeStatus = data.cd;
      } else if (type === "reading") {
        this.readingFlag = data.cd;
      }
      this.filters[type].filter((e) => (e.isActive = false));
      this.filters[type]
        .filter((e) => e.index === data.index)
        .map((e) => (e.isActive = true));
      this.updateNoticeList(true);
    },
    infinityCheck() {
      if (this.isLoading) return;
      if (Math.ceil(this.totalCount / this.pageList) <= this.pageNow) return;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.updateNoticeList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-list {
  width: 100%;
  max-width: 500px;
  // @include set-height();
  display: flex;
  flex-direction: column;
  .title {
    margin: 40px 20px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
  &-filter {
    margin: 68px 20px 40px;
    color: $primary-80;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
  &-view {
    @media (max-width: 520px) {
      margin-top: 40px;
    }
    background-color: $neutral-0;
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .line {
      height: rem(8px);
      background-color: $neutral-3;
    }
    &-item {
      // padding: 0 rem(16px);
      align-items: flex-start;
      justify-content: center;
      border-top: 1px solid $neutral-10;
      border-bottom: 1px solid $neutral-10;
      padding: rem(16px) rem(20px);
      &-wrap {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        .date {
          display: flex;
          align-items: center;
          color: $neutral-60;
          font-weight: 400;
        }
      }
      &.active {
        background: #f9f9f9;
      }
    }
    .no-notice {
      font-weight: 500;
      color: $neutral-40;
    }
  }
}
</style>
