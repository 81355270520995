<template>
  <div id="left">
    <div id="left-wrap">
      <div class="profile">
        <div class="profile-top" :class="!userId ? 'signup' : ''">
          <div class="img-area">
            <img
              :src="
                userInfo.profileFileNo > 0
                  ? profileImg
                  : require('@/assets/img/basic-profile.png')
              "
            />
          </div>
          <!-- <p v-if="compType == '1'" class="mt-20">디자이너</p>
          <p v-else-if="compType == '2'" class="mt-20">공장</p> -->
          <p v-if="!userId" class="mt-12">비 회원</p>
          <p class="mt-12 mb-20" v-if="userInfo.mailId">
            {{ this.userInfo.mailId }}@{{ this.userInfo.mailName }}
          </p>
          <p class="mt-12 mb-20" v-else-if="userInfo.factoryName">
            {{ this.userInfo.factoryName }}
          </p>
          <p class="mt-12 mb-20" v-else-if="userInfo.compName">
            {{ this.userInfo.compName }}
          </p>
          <p class="mt-12 mb-20" v-else-if="userInfo.userName">
            {{ this.userInfo.userName }}
          </p>
        </div>
        <!-- @click="gotoPage('/designorder/add')" -->
        <!-- @click="$emit('matchingPopup', true)" -->
        <div
          v-if="compType == '1'"
          class="profile-bottom"
          @click="$emit('matchingPopup', true)"
        >
          <div class="profile-bottom-btn">
            <img src="@/assets/icon/ic-plus-square.svg" />
            <p>매칭 등록</p>
          </div>
        </div>
        <div
          v-else-if="compType == '2'"
          class="profile-bottom"
          @click="
            userInfo.calculateFlag !== '2'
              ? gotoPage('/mypage/factory/auth/1')
              : ''
          "
        >
          <div
            v-if="userInfo.calculateFlag == '1'"
            class="profile-bottom-btn deactive"
          >
            <p>확인 중</p>
          </div>
          <div
            v-else-if="userInfo.calculateFlag !== '2'"
            class="profile-bottom-btn"
            @click="gotoPage('/mypage/factory/auth/1')"
          >
            <img src="@/assets/icon/ic-credit-card.svg" />
            <p>정산 서비스 신청</p>
          </div>
          <div
            v-else
            class="profile-bottom-btn"
            @click="copyToClipboard(userInfo.factoryNo)"
          >
            <p>
              결제 URL
              <input ref="inputText" class="hidden" v-model="factoryPayUrl" />
            </p>
            <img src="@/assets/icon/ic-content-copy.svg" />
          </div>
        </div>
        <div
          v-else
          class="profile-bottom signup"
          @click="this.$emit('openLogin')"
        >
          <p class="profile-bottom-btn">로그인</p>
        </div>
      </div>
      <div class="dashboard" v-if="userId">
        <!-- <p>활동 정보</p> -->

        <!-- <div class="dashboard-info">
          <p>활동 정보</p>
        </div> -->
        <div class="wd100" @click="clickList('isAlarm')">
          <div class="dashboard-menu">
            <div class="flex gap8">
              <img src="@/assets/icon/ic-notifications.svg" />
              <p>알림 ({{ dashboardData.alarmCount }})</p>
            </div>
            <img
              :src="
                require(`@/assets/icon/ic-arrowdrop${
                  isAlarm ? '-up' : '-down'
                }.svg`)
              "
            />
          </div>
          <div v-if="isAlarm" class="info-list">
            <div
              class="info-list-item pointer"
              v-for="(alarmList, i) in dashboardData.alarmList"
              :key="i"
            >
              <div @click="alarmRead(i)">
                <p class="title">[{{ alarmList.alarmTypeName }}]&nbsp;&nbsp;</p>
                <p class="content">
                  {{ alarmList.title }}
                </p>
              </div>
              <p>{{ alarmList.timer }}</p>
            </div>
            <div v-if="dashboardData.alarmCount == '0'" class="no-list"></div>
          </div>
          <div
            v-if="isAlarm"
            class="view"
            @click="gotoPage('/mypage/alarmlist')"
          >
            <img src="@/assets/icon/ic-list.svg" />
            <p>전체 보기</p>
          </div>
        </div>
        <div
          v-if="compType == '1'"
          class="wd100"
          @click="clickList('isDesignOrder')"
        >
          <div class="dashboard-menu">
            <div class="flex gap8">
              <img src="@/assets/icon/ic-list-black.svg" />
              <p>매칭 ({{ dashboardData.designOrderCount }})</p>
            </div>
            <img
              :src="
                require(`@/assets/icon/ic-arrowdrop${
                  isDesignOrder ? '-up' : '-down'
                }.svg`)
              "
            />
          </div>
          <div v-if="isDesignOrder" class="info-list">
            <div
              class="info-list-item pointer"
              v-for="(designOrderList, i) in dashboardData.designOrderList"
              :key="i"
              @click="goDesignOrderDetail(designOrderList.designOrderNo)"
            >
              <div>
                <p class="content">{{ designOrderList.styleName }}</p>
              </div>
              <p>{{ designOrderList.timer }}</p>
            </div>
            <div
              v-if="dashboardData.designOrderCount == '0'"
              class="no-list"
            ></div>
          </div>
          <div
            v-if="isDesignOrder"
            class="view"
            @click="gotoPage('/designorder/list')"
          >
            <img src="@/assets/icon/ic-list.svg" />
            <p>전체 보기</p>
          </div>
        </div>
        <div
          v-if="compType == '2'"
          class="wd100"
          @click="clickList('isCalculate')"
        >
          <div class="dashboard-menu">
            <div class="flex gap8">
              <img src="@/assets/icon/ic-bill.svg" />
              <p>
                계산서
                {{
                  `${
                    dashboardData.calculateCount > 0
                      ? "(" + dashboardData.calculateCount + ")"
                      : ""
                  }`
                }}
              </p>
            </div>
            <img
              :src="
                require(`@/assets/icon/ic-arrowdrop${
                  isCalculate ? '-up' : '-down'
                }.svg`)
              "
            />
          </div>
          <div v-if="isCalculate" class="info-list">
            <div
              class="info-list-item pointer"
              v-for="(calc, i) in dashboardData.calculateList"
              :key="i"
              @click="gotoPage(`/calculate/list/${calc.paymentDtlNo}`)"
            >
              <div>
                <p class="content">
                  {{
                    calc.compName
                      ? calc.compName
                      : calc.userName
                      ? calc.userName
                      : "비회원 결제"
                  }}
                </p>
              </div>
              <p>{{ calc.timer }}</p>
            </div>
            <div
              v-if="dashboardData.calculateCount == '0'"
              class="no-list"
            ></div>
          </div>
          <div
            v-if="isCalculate"
            class="view"
            @click="gotoPage('/calculate/list')"
          >
            <img src="@/assets/icon/ic-list.svg" />
            <p>전체 보기</p>
          </div>
        </div>
        <div
          v-if="compType == '2'"
          class="wd100"
          @click="clickList('isManage')"
        >
          <div class="dashboard-menu">
            <div class="flex gap8">
              <img src="@/assets/icon/ic-payment.svg" />
              <p>
                정산
                {{
                  `${
                    dashboardData.manageCount > 0
                      ? "(" + dashboardData.manageCount + ")"
                      : ""
                  }`
                }}
              </p>
            </div>
            <img
              :src="
                require(`@/assets/icon/ic-arrowdrop${
                  isManage ? '-up' : '-down'
                }.svg`)
              "
            />
          </div>
          <div v-if="isManage" class="info-list">
            <!-- @click="goDesignOrderDetail(payment.designOrderNo)" -->
            <div
              class="info-list-item pointer"
              v-for="(calc, i) in dashboardData.manageList"
              :key="i"
            >
              <div>
                <p class="content">
                  {{
                    calc.compName
                      ? calc.compName
                      : calc.userName
                      ? calc.userName
                      : "비회원 결제"
                  }}
                </p>
              </div>
              <p>{{ calc.timer }}</p>
            </div>
            <div v-if="dashboardData.manageCount == '0'" class="no-list"></div>
          </div>
          <div v-if="isManage" @click="gotoPage('/manage/list')" class="view">
            <img src="@/assets/icon/ic-list.svg" />
            <p>전체 보기</p>
          </div>
        </div>
        <div
          v-if="compType == '1'"
          class="wd100"
          @click="clickList('isPayment')"
        >
          <div class="dashboard-menu">
            <div class="flex gap8">
              <img src="@/assets/icon/ic-payment.svg" />
              <p>
                결제
                {{
                  `${
                    dashboardData.paymentCount > 0
                      ? "(" + dashboardData.paymentCount + ")"
                      : ""
                  }`
                }}
              </p>
            </div>
            <img
              :src="
                require(`@/assets/icon/ic-arrowdrop${
                  isPayment ? '-up' : '-down'
                }.svg`)
              "
            />
          </div>
          <div v-if="isPayment" class="info-list">
            <div
              class="info-list-item pointer"
              v-for="(payment, i) in dashboardData.paymentList"
              :key="i"
              @click="goPaymentDetail(payment.paymentNo)"
            >
              <div>
                <p class="content">{{ payment.factoryName }}</p>
              </div>
              <p>{{ payment.timer }}</p>
            </div>
            <div v-if="dashboardData.paymentCount == '0'" class="no-list"></div>
          </div>
          <div v-if="isPayment" class="view" @click="gotoPage('/payment/list')">
            <img src="@/assets/icon/ic-list.svg" />
            <p>전체 보기</p>
          </div>
        </div>
        <!-- <div class="wd100" @click="clickList('isContract')">
          <div class="dashboard-menu">
            <div class="flex gap8">
              <img src="@/assets/icon/ic-clipboard-list.svg" />
              <p>발주 ({{ dashboardData.contractCount }})</p>
            </div>
            <img
              :src="
                require(`@/assets/icon/ic-arrowdrop${
                  isContract ? '-up' : '-down'
                }.svg`)
              "
            />
          </div>
          <div v-if="isContract" class="info-list">
            <div
              class="info-list-item pointer"
              v-for="(contractList, i) in dashboardData.contractList"
              :key="i"
              @click="goContractDetail(contractList.contractNo)"
            >
              <div>
                <p v-if="compType == '1'">{{ contractList.factoryCompName }}</p>
                <p v-if="compType == '2'">
                  {{ contractList.designerCompName }}
                </p>
              </div>
              <p>{{ contractList.timer }}</p>
            </div>
            <div
              v-if="dashboardData.contractCount == '0'"
              class="no-list"
            ></div>
          </div>
          <div
            v-if="isContract"
            class="view"
            @click="gotoPage('/contract/list')"
          >
            <img src="@/assets/icon/ic-list.svg" />
            <p>전체 보기</p>
          </div>
        </div> -->
        <!-- <div class="dashboard-menu" @click="gotoPage('/webchats')">
          <div class="flex gap8">
            <img src="@/assets/icon/ic-message-circle-black.svg" />
            <p>메시지</p>
          </div>
          <img
            :src="
              require(`@/assets/icon/ic-arrowdrop${
                isMessage ? '-up' : '-down'
              }.svg`)
            "
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftDashboard",
  data() {
    return {
      showFirstName: "",
      isModalShow: false,
      sortOrder: "",
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get socialType() {
        return sessionStorage.getItem("socialType");
      },
      get mailId() {
        return sessionStorage.getItem("mailId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
      dashboardData: {},
      userInfo: {},
      profileImg: "",
      isAlarm: false,
      isDesignOrder: false,
      isCalculate: false,
      isPayment: false,
      isContract: false,
      isMessage: false,
      isManage: false,
      factoryPayUrl: "",
    };
  },
  mounted() {
    if (this.compType == "1") {
      this.$API.mypage
        .getDesignerInfo(sessionStorage.getItem("userId"))
        .then((res) => {
          this.userInfo = res.data.body;
          this.profileImg = this.userInfo.profileFileUrl;
        });
    }
    if (this.compType == "2") {
      this.$API.mypage
        .getFactoryInfo(sessionStorage.getItem("userId"))
        .then((res) => {
          this.userInfo = res.data.body;
          this.profileImg = this.userInfo.profileFileUrl;
          console.log(this.userInfo);
        });
    }

    if (this.compType && sessionStorage.getItem("userId")) {
      this.$API.comm
        .commData(sessionStorage.getItem("userId"), this.compType)
        .then((res) => {
          this.dashboardData = res.data.body;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {
    clickList(btnName) {
      const isBtn = this[btnName];
      this.isAlarm = false;
      this.isDesignOrder = false;
      this.isCalculate = false;
      this.isPayment = false;
      this.isContract = false;
      this.isManage = false;
      this[btnName] = !isBtn;
    },
    alarmRead(i) {
      if (this.dashboardData.alarmList[i].readingFlag != "1") {
        this.dashboardData.alarmList[i].readingFlag = "1";
        this.$API.mypage
          .postAlarmRead(
            sessionStorage.getItem("userId"),
            this.dashboardData.alarmList[i].alarmNo
          )
          .then(() => {
            this.$API.mypage
              .alarmCheck(sessionStorage.getItem("userId"))
              .then((res) => {
                console.log(res);
              });
          });
      }
      console.log(this.dashboardData.alarmList[i].alarmType);
      console.log(this.dashboardData.alarmList[i].alarmUrl);
      if (this.dashboardData.alarmList[i].alarmType == "1") {
        this.gotoPage("/mypage/alarmlist");
      } else {
        this.gotoUrl(this.dashboardData.alarmList[i].alarmUrl);
      }
    },
    goDesignOrderDetail(designOrderNo) {
      if (this.compType == "1") {
        location.href = "/designorder/detail/factory/" + designOrderNo;
        // this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
      } else {
        location.href = "/designorder/detail/order/" + designOrderNo;
        // this.gotoPage(`/designorder/detail/order/${designOrderNo}`);
      }
    },
    goPaymentDetail(paymentNo) {
      if (this.compType == "1") {
        location.href = "/payment/list/" + paymentNo;
        // this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
      } else {
        location.href = "/payment/list/" + paymentNo;
        // this.gotoPage(`/designorder/detail/order/${designOrderNo}`);
      }
    },
    goContractDetail(contractNo) {
      if (this.compType == "1") {
        location.href = "/contract/list/" + contractNo;
        // this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
      } else {
        location.href = "/contract/list/" + contractNo;
        // this.gotoPage(`/designorder/detail/order/${designOrderNo}`);
      }
    },
    backClick() {
      if (this.$store.state.meta.popupInfo.isShow) {
        this.setPopup(false);
      } else {
        // this.$store.state.meta.returnLoginPath = ''
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#left {
  width: 270px;
  margin-top: 100px;
  background: $neutral-0;
  &-wrap {
    .profile {
      &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $primary-20;
        &.signup {
          padding-bottom: 20px;
        }
        .img-area {
          display: flex;
          align-items: center;
          width: 100px;
          height: 100px;
          overflow: hidden;
          border: 1px solid $neutral-10;
          border-radius: 80px;
          img {
            width: 100%;
            max-height: 100%;
            // object-fit: cover;
            // clip-path: circle(50%);
          }
        }
      }
      &-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        cursor: pointer;
        &-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-weight: 500;
          font-size: 15px;
          line-height: 23px;
          width: 230px;
          height: 40px;
          border: 1px solid $neutral-20;
          border-radius: 4px;
          &.signup :hover {
            border-color: $primary-20;
          }
          &:hover {
            border-color: $primary-20;
          }
          &.login {
            border-color: $primary-50;
            &:hover {
              border-color: $primary-20;
            }
          }
          &.active {
            background-color: $primary-50;
          }
          &.deactive {
            color: $neutral-0;
            background-color: $neutral-30;
          }
        }
      }
    }
    .dashboard {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      gap: 20px;
      &-info {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
      }
      &-menu {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 8px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: $neutral-80;
        border-bottom: 1px solid $neutral-0;
        img {
          width: 16px;
        }
        &:hover {
          border-bottom: 1px solid $primary-20;
        }
      }
      .info-list {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        height: 100%;
        max-height: 160px;
        padding: 12px 12px;
        border: 1px solid $neutral-10;
        border-top: 1px solid $primary-50;
        overflow: hidden;
        .no-list {
          height: 20px;
        }
        &-item {
          display: flex;
          justify-content: space-between;
          div {
            display: flex;
            max-width: 200px;
            .title {
            }
            .content {
              @include ellipse(1);
            }
          }
        }
      }
      .view {
        border: 1px solid $neutral-10;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        height: 32px;
        gap: 8px;
        border-width: 0 1px 1px 1px;
        border-color: $neutral-10;
        border-style: solid;
        border-radius: 0px 0px 4px 4px;
        color: $neutral-60;
        cursor: pointer;
        img {
          width: 16px;
        }
        &:hover {
          background: $neutral-3;
        }
      }
    }
  }
}
</style>
