<template>
  <div class="search-box">
    <img
      class="ic-search"
      src="@/assets/icon/ic-search.svg"
      @click="sendEvent()"
    />
    <input
      :class="isShowInput ? 'txtbox' : 'hidden'"
      id="search-word"
      v-model="searchTxt"
      @input="textChange"
      @keyup.enter="sendEvent()"
      @blur="focusOut()"
    />
    <p
      :class="isShowInput ? 'hidden' : 'txtbox'"
      @click="showInputUpdate()"
      v-html="
        txt ? txt : '원하는 <b>조건</b>이나 <b>공장</b>을 검색해 보세요!!'
      "
    ></p>
  </div>
</template>
<script>
export default {
  name: "searchBox",
  data() {
    return {
      isShowInput: 0,
      searchTxt: "",
    };
  },
  props: {
    txt: {
      type: String,
      description: "txt",
    },
  },
  methods: {
    showInputUpdate() {
      this.isShowInput = 1;
      document.getElementById("search-word").focus();
      this.$emit("firstClick");
    },
    textChange(e) {
      this.$emit("changeTxt", e.target.value);
    },
    sendEvent() {
      this.$emit("search", this.searchTxt);
    },
    focusOut() {
      if (this.searchTxt === "") this.isShowInput = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  height: 40px;
  background: $neutral-0;
  border: 1px solid $neutral-10;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 12px;
  input {
    &.txtbox {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-80;
    }
  }
  .ic-search {
    width: rem(16px);
    height: rem(16px);
  }
  p {
    &.txtbox {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $neutral-30;
    }
  }
  .placeholder {
    cursor: text;
  }
}
</style>
