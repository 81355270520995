<template>
  <div class="matching-card">
    <div class="matching-card-wrap pointer" @click="moveService(cardData.matchingStatus == '4' ? true : false)">
      <div class="matching-card-wrap-top">
        <div class="space-between">
          <div class="flex">
            <p class="info">{{ cardData.workProcess }}</p>
            <p v-if="cardData.matchingCatg == '2'" class="info bold">1:1 매칭</p>
            <p v-else class="info bold">{{ cardData.matchingType }}</p>
          </div>
          <p class="info date">{{ cardData.createdDate }}</p>
        </div>
      </div>
      <div class="matching-card-wrap-body flex1">
        <div class="matching-card-wrap-body-top">
          <div class="row">
            <p class="info no">{{ `No.${cardData.matchingNo}`  }}</p>
            <p class="info title">{{ cardData.matchingName }}</p>건
          </div>
          <div class="flex space-between mt-20">
            <p class="info status" :class="matchingStatus[cardData.matchingStatus].class">{{ matchingStatus[cardData.matchingStatus].txt }}</p>
            <div v-if="cardData.matchingStatus == '1' || cardData.matchingStatus == '2'" class="flex">
              <p class="matchup">매치업된 공장 <span>{{cardData.matchUpFactory}}</span></p>
              <p class="slash">/</p>
              <p class="part-count">참여 공장 <span :class="cardData.readingFlag == '0' && cardData.partCount != '0' ? 'active' : ''">{{cardData.partCount}}</span></p>
            </div>
            <div v-if="cardData.matchingStatus == '4'" class="space-between flex1">
              <p class="need-complete">의뢰를 완료해주세요!</p>
              <img class="pointer" src="@/assets/icon/ic-trash.svg" @click.stop="removeMatching(cardData.matchingNo)">
            </div>
          </div>
        </div>
        <div class="matching-card-wrap-body-content" :class="$route.query.type == 'load' ? 'remove-line' : ''">
          <div class="row">
            <p class="info">{{ cardData.catgName1 + ' > ' + cardData.catgName2 + ' > ' + cardData.catgName3 }}</p>
          </div>
          <div v-if="cardData.workProcess == '봉제'">
            <div class="space-between mt-16">
              <p>컬러수<span>{{cardData.colorCount || '-'}}</span></p>
              <p>사이즈수<span>{{cardData.colorCount || '-'}}</span></p>
              <p>총수량<span>{{cardData.quantity || '-'}}</span></p>
            </div>
            <p class="mt-16">희망단가<span>{{`${Number(cardData.minUnitCost).toLocaleString('ko-KR')} ~ ${Number(cardData.maxUnitCost).toLocaleString('ko-KR')}`}}</span></p>
          </div>
          <div v-else-if="cardData.workProcess == '패턴/샘플'">
            <div class="infos mt-16">
              <p>작업
                <span v-if="!cardData.processType">{{'-'}}</span>
                <span v-else-if="cardData.processType == '11'">{{'패턴'}}</span>
                <span v-else-if="cardData.processType == '12'">{{'샘플'}}</span>
                <span v-else-if="cardData.processType == '13'">{{'패턴/샘플'}}</span>
              </p>
              <p>참고샘플<span>{{cardData.sampleFlag == '1' ? '보유' : '미보유'}}</span></p>
            </div>
          </div>
          <div v-else-if="cardData.workProcess == '자수/나염/전사'">
            <div class="infos mt-16">
              <!-- <p>작업<span>{{cardData.workType ? (cardData.workType == '3' ? '재단물' : '완제품') : '-'}}</span></p> -->
              <p v-if="cardData.processType.indexOf('41')">작업<span>자수</span></p>
              <p v-if="cardData.processType.indexOf('42')">작업<span>나염</span></p>
              <p v-if="cardData.processType.indexOf('43')">작업<span>전사</span></p>
              <p v-if="cardData.processType == ''">작업<span>-</span></p>
              <p>원단<span>{{cardData.fabric == '1' ? '결정' : '미결정'}}</span></p>
            </div>
            <p class="mt-16">총수량<span>{{cardData.quantity || '-'}}</span></p>
          </div>
        </div>
      </div>
      <div class="matching-card-wrap-bottom" v-if="$route.query.type == 'load'">
        <!-- <div v-if="$route.query.type !== 'load'" class="bottom-btns">
          <div class="bottom-btn">참여 공장(<p :class="cardData.partCount !== 0 ? 'active' : ''">{{ cardData.partCount }}</p>)</div>
          <div class="bottom-btn" :class="cardData.matchingStatus === '1' ? 'active' : ''">{{ matchingStatus[cardData.matchingStatus] }}</div>
        </div> -->
        <div class="bottom-btns">
          <div class="bottom-btn load">의뢰내역 불러오기</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'matchingView',
  props: {
    cardData: {
      type: Object,
      description: "Card data"
    }
  },
  data () {
    return {
      matchingStatus: {
        '0': { txt: '매칭 준비중', class: 'green' },
        '1': { txt: '매칭중', class: 'yellow' },
        '2': { txt: '매칭중', class: 'yellow' },
        '3': { txt: '매칭종료', class: 'end' },
        '4': { txt: '매칭 정보 작성중', class: 'ing' },
      }
    }
  },
  methods: {
    // selectMatching (matchingNo) {
    //   console.log(sessionStorage.getItem('userId'));
    //   console.log(matchingNo);
    //   this.$API.matching.matchingDetails(sessionStorage.getItem('userId'), matchingNo)
    //   .then((res) => {
    //     this.matchingDetails = res.data.body.matchingDetails
    //   })
    // },
    moveService (isProgress) {
      if (!sessionStorage.getItem('userId')) { // 로그인 안되어있을때 로그인 화면으로
        this.loginCheck()
      } else if (this.$route.query.type === 'load') {
        this.moveConfirm()
      } else if (isProgress) {
        this.gotoPage(`/matchingconfirm`)
        this.showToast('의뢰내역을 불러왔습니다.')
      } else if (sessionStorage.getItem('compType') == '1') {
        this.gotoPage(`/matchingdetails/${this.cardData.matchingNo}`)
      } else {
        this.gotoPage(`/matchingproposal/${this.cardData.matchingNo}`)
      }
    },
    moveConfirm () {
      this.$API.matching.matchingStyleCopy(sessionStorage.getItem('userId'), this.cardData.matchingNo)
        .then(() => {
          this.gotoPage(`/matchingconfirm`)
          this.showToast('의뢰내역을 불러왔습니다.')
        })
    },
    removeMatching (matchingNo) {
      this.$emit('removeMatching', matchingNo)
    }
  },
}
</script>

<style lang="scss" scoped>
  .matching-card {
    @include mobile {
      margin-top: rem(12px);
      flex: 1;
    }
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    .ing {
      color: $neutral-80;
    }
    .end {
      color: $neutral-60;
    }
    .info {
      @include ellipse();
    }
    &-wrap {
      background-color: $neutral-0;
      flex-grow: 1;
      border-radius: rem(16px);
      border: 1px solid $neutral-10;
      height: 100%;
      display: flex;
      flex-direction: column;
      @include mobile {
        margin: 0 rem(16px);
      }
      &-top {
        border-bottom: 1px solid $neutral-10;
        padding: rem(16px) rem(20px);
        .info {
          position: relative;
        }
        .info + .info {
          margin-left: rem(8px);
          padding-left: rem(8px);
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 14px;
            background-color: #C1C1C1;
          }
        }
        .date {
          font-size: 14px;
          color: $neutral-50;
        }
      }
      &-body {
        padding: rem(24px) rem(20px);
        .row {
          display: flex;
        }
        .no {
          padding-right: 0.5rem;
        }
        .title {
          font-weight: 700;
          color: $indication-50;
          padding-right: 0.2rem;
        }
        .status {
          font-weight: 700;
          font-size: 18px;
        }
        .matchup {
          font-weight: 500;
          font-size: 14px;
          color: $neutral-50;
          span {
            font-weight: 700;
            font-size: 18px;
          }
        }
        .slash {
          padding: 0 2px;
        }
        .part-count {
          font-weight: 500;
          font-size: 14px;
          color: $neutral-80;
          span {
            font-weight: 700;
            font-size: 18px;
            position: relative;
            &.active {
              color: $primary-50;
              &::after {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                background-color: $secondary-60;
                border-radius: 2px;
              }
            }
          }
        }
        .need-complete {
          margin-left: 4px;
          font-size: 12px;
          color: $neutral-60;
        }
        &-content {
          margin-top: 16px;
          padding-top: 24px;
          border-top: 1px solid $neutral-10;
          color: $neutral-50;
          font-size: 16px;
          span {
            font-weight: 500;
            color: $neutral-60;
            margin-left: 4px;
          }
          .infos {
            display: flex;
            p:not(:first-child) {
              padding-left: 24px;
            }
          }
        }
        .remove-line {
          border-top: none;
          padding-top: 0;
        }
      }

      &-bottom {
        border-top: 1px solid $neutral-10;
        .bottom-btns {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #8C8C8C;
        }
        .bottom-btn {
          flex: 1;
          font-weight: 500;
          line-height: 25px;
          text-align: center;
          padding: rem(12px);
          cursor: pointer;
          display: flex;
          justify-content: center;
          &:nth-child(2n) {
            border-left: 1px solid $neutral-10;
          }
          &.active {
            color: $primary-50;
          }
          .active {
            color: $primary-50;
          }
          &.load {
            font-weight: 500;
            font-size: 16px;
            color: $neutral-80;
          }
        }
      }

    }
  }
</style>