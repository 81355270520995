<template>
  <div class="top-banner">
    <swiper
      id="bannerSwiper"
      :slidesPerView="1"
      :loop="true"
      :rewind="true"
      :autoplay="{ delay: 5500, disableOnInteraction: false }"
      :modules="modules"
      class="banner-wrap-swiper"
    >
      <swiper-slide class="search-banner-slider" v-if="isMobile == 'false'">
        <img
          v-if="isMobile == 'true'"
          @click="gotoPage('/mall/materiallist')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-mobile-mall.png"
        />
        <img
          v-else
          @click="gotoPage('/mall/materiallist')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-pc-mall.png"
        />
      </swiper-slide>
      <swiper-slide class="search-banner-slider" v-if="isMobile == 'false'">
        <img
          v-if="isMobile == 'true'"
          @click="gotoPage('/search/factorydetail/1424')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-mobile-how2png.png"
        />
        <img
          v-else
          @click="gotoPage('/search/factorydetail/1424')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-pc-how2png.png"
        />
      </swiper-slide>
      <swiper-slide class="search-banner-slider" v-if="isMobile == 'false'">
        <img
          v-if="isMobile == 'true'"
          @click="gotoPage('/search/factorydetail/5943')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-mobile-daeyung.png"
        />
        <img
          v-else
          @click="gotoPage('/search/factorydetail/5943')"
          class="pointer"
          src="https://cdn.osle.co.kr:8443/banner/banner-search-pc-daeyung.png"
        />
      </swiper-slide>
      <swiper-slide class="search-banner-slider">
        <img
          v-if="isMobile == 'true'"
          src="@/assets/img/banner-short-img.png"
        />
        <img v-else src="@/assets/img/banner-long-img.png" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
export default {
  name: "TopBanner",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Autoplay],
    };
  },
  data() {
    return {
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.top-banner {
  margin-top: 40px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 520px;
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  @include desktop {
    margin-top: 40px;
    max-width: 100%;
  }
  .banner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    &-swiper {
      width: 100%;
      height: 140px;
      position: initial;
      border-radius: 4px;
      .swiper-slide-duplicate-next {
        background-color: gray !important;
      }
      img {
        width: 100%;
      }
    }

    :deep(.swiper) {
      .swiper-button-next {
        right: 0;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        background-color: $neutral-0;
        &::after {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
          width: 20px;
          height: 20px;
          color: $neutral-30;
        }
      }
      .swiper-button-prev {
        left: 0;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        background-color: $neutral-0;
        &::after {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: 700;
          width: 20px;
          height: 20px;
          color: $neutral-30;
        }
      }
    }
  }
}
</style>
