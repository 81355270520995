<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
    fill="none"
  >
    <path :d="d" :fill="`${color}`" />
  </svg>
</template>
<script>
export default {
  name: "SvgImg",
  props: {
    color: {
      type: String,
      description: "btn text ex) #FFFFFF",
    },
    d: {
      type: String,
      description: "btn type ex) s, m, l, f",
    },
    size: {
      type: Number,
      description: "btn disabled",
    },
  },
  data() {
    return {};
  },
};
</script>
