<template>
  <div class="doughnut">
    <p class="doughnut-rate">{{ labels }}</p>
    <canvas :id="id" class="doughnutChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "DoughnutRate",
  props: {
    id: {
      type: Object,
    },
    labels: {
      type: Object,
    },
    datas: {
      type: Object,
    },
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            borderWidth: 0,
            backgroundColor: [
              "#07BBDF",
              "#0452E9",
              "#E24F73",
              "#FF9E0D",
              "#04BB6A",
            ], //reverse: 좌우 반전
            // data: [20, 30, 10, 15, 15].reverse(), //reverse: 좌우 반전
            data: this.datas, //reverse: 좌우 반전
          },
        ],
      },
      chartOptions: {
        cutout: "50%", // 도넛 차트의 중심부 크기를 반지름의 50%로 설정
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false, // 범례 숨기기
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById(this.id).getContext("2d");
    const doughnutChart = new Chart(ctx, {
      type: "doughnut", // 차트 타입: 'line', 'bar', 'pie' 등
      data: this.chartData,
      options: this.chartOptions,
    });
  },
};
</script>
<style lang="scss" scoped>
.doughnut {
  position: relative;
  max-width: 154px;
  max-height: 154px;
  &-rate {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }
}
</style>
