<template>
  <div class="size-wrap" :class="{ big: tableWidth == 'big' }">
    <div class="size-wrap-flex mb-4">
      <p class="unit mr-4">(단위 : cm)</p>
      <p
        class="unit"
        v-for="(x, i) in sizeList.filter((e) => e.isActive)"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ x.name }}
      </p>
    </div>
    <div class="size-wrap-flex" v-for="(column, i) in sizeColumn" :key="i">
      <div class="spec">
        <p class="spec-row" :class="{ required: column.isRequired }">
          {{ column.row }}
        </p>
        <p class="spec-name" :class="{ required: column.isRequired }">
          {{ column.name }}
          <SvgImg
            v-if="column.isRequired"
            :size="8"
            :d="$store.state.meta.svg.smallStarIcon"
            :color="$store.state.meta.svg.color.primary20"
          />
        </p>
      </div>
      <p class="empty" v-if="column.isDisabled">
        디자인 / 디테일 선택 후, 별도 산출
      </p>
      <p
        v-else
        class="unit column"
        :class="{ disabled: disabled }"
        v-for="(x, j) in sizeList.length"
        :key="j"
      >
        <input
          type="number"
          v-if="!disabled"
          class="size"
          v-model="sizeValue[i][j]"
          placeholder="00.0"
          @focus="focusSize(i, j)"
          @keyup="keyUpSize(i, j)"
          @focusout="focusOutSize(i, j)"
          :class="{ active: sizeActive[i][j] }"
          :disabled="!sizeActive[i][j]"
        />
        <span v-else>{{ Number(sizeValue[i][j]).toFixed(1) }}</span>
      </p>
    </div>

    <!-- <div class="size-wrap-flex">
      <p class="unit">목 너비</p>
      <p class="empty" v-if="!sizeList[0]?.spec?.newSize">
        디자인 / 디테일 선택 후, 별도 산출
      </p>
      <p
        v-else
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.newSize }}
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">목 파임</p>
      <p class="empty" v-if="!sizeList[0]?.spec?.newSize">
        디자인 / 디테일 선택 후, 별도 산출
      </p>
      <p
        v-else
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.nedSize }}
      </p>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "filterMenuNew",
  props: {
    size: {
      type: Object,
      description: "img list name",
    },
    gender: {
      type: Object,
      description: "img list name",
    },
    fit: {
      type: Object,
      description: "img list name",
    },
    disabled: {
      type: Boolean,
      description: "img list name",
    },
    tableWidth: {
      type: String,
      description: "img list name",
    },
    sizeVal: {
      type: String,
      description: "img list name",
    },
  },
  data() {
    return {
      showSelectBoxList: false,
      selectDataTxt: "",
      imgList: [],
      imgUrl: "",
      imgDesc: "",
      isAdd: false,
      sizeNo: "",
      sizeColumn: [],
      items: [],
      sizeList: [],
      sizeValue: [[], [], [], [], [], [], [], [], [], [], []],
      sizeActive: [],
      sizing: [
        {
          gender: 1,
          fit: 1,
          tolDvtn: 1.5,
          shsDvtn: 2,
          chsDvtn: 2,
          hesDvtn: 2,
          whsDvtn: 2,
          sllDvtn: [0.5, 0.5, 0.5, 0.8, 1],
          ahlDvtn: 0.5,
          scsDvtn: [0.4, 0.4, 0.3, 0.3, 0.3],
          sbsDvtn: 0.5,
          newDvtn: 0.5,
          nedDvtn: 0.3,
        },
        {
          gender: 1,
          fit: 2,
          tolDvtn: 1.5,
          shsDvtn: 2,
          chsDvtn: 2,
          hesDvtn: 2,
          whsDvtn: 2,
          sllDvtn: [0.5, 0.5, 0.5, 0.8, 1],
          ahlDvtn: 0.7,
          scsDvtn: [0.5, 0.5, 0.4, 0.4, 0.3],
          sbsDvtn: 0.7,
          newDvtn: 0.5,
          nedDvtn: 0.3,
        },
        {
          gender: 1,
          fit: 4,
          tolDvtn: 1.5,
          shsDvtn: 2,
          chsDvtn: 2,
          hesDvtn: 2,
          whsDvtn: 2,
          sllDvtn: [0.5, 0.5, 0.5, 0.8, 1],
          ahlDvtn: 0.7,
          scsDvtn: [0.6, 0.6, 0.4, 0.4, 0.4],
          sbsDvtn: 0.7,
          newDvtn: 0.5,
          nedDvtn: 0.3,
        },
        {
          gender: 2,
          fit: 1,
          tolDvtn: 1.5,
          shsDvtn: 2.5,
          chsDvtn: 2.5,
          hesDvtn: 2.5,
          whsDvtn: 2.5,
          sllDvtn: [0.5, 0.5, 0.5, 0.8, 1],
          ahlDvtn: 0.7,
          scsDvtn: [0.6, 0.6, 0.5, 0.7, 0.4],
          sbsDvtn: 0.7,
          newDvtn: 0.5,
          nedDvtn: 0.3,
        },
        {
          gender: 2,
          fit: 2,
          tolDvtn: 1.5,
          shsDvtn: 2.5,
          chsDvtn: 2.5,
          hesDvtn: 2.5,
          whsDvtn: 2.5,
          sllDvtn: [0.5, 0.5, 0.5, 0.8, 1],
          ahlDvtn: 0.7,
          scsDvtn: [0.5, 0.5, 0.4, 0.4, 0.4],
          sbsDvtn: 0.7,
          newDvtn: 0.5,
          nedDvtn: 0.3,
        },
        {
          gender: 2,
          fit: 4,
          tolDvtn: 1.5,
          shsDvtn: 2.5,
          chsDvtn: 2.5,
          hesDvtn: 2.5,
          whsDvtn: 2.5,
          sllDvtn: [0.5, 0.5, 0.5, 0.8, 1],
          ahlDvtn: 0.7,
          scsDvtn: [0.6, 0.6, 0.5, 0.5, 0.4],
          sbsDvtn: 0.7,
          newDvtn: 0.5,
          nedDvtn: 0.3,
        },
      ],
    };
  },
  watch: {
    size(newVal) {
      console.log("size(newVal)");
      this.sizeList = newVal;
      this.init();
    },
    gender(newVal) {
      console.log("gender(newVal)");
      console.log(newVal);
      let sizeSpec = [];
      if (!(Object.values(this.gender).length > 0)) {
        // this.init();
      }
      this.$API.custom
        .customSizeSepc(
          this.gender.cd,
          this.fit.cd,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          this.items.filter((e) => e.isActive)[0]?.cd
        )
        .then((res) => {
          console.log(res);
          sizeSpec = res.data.body;
          //편차 셋팅
          this.sizeNo;
          this.sizeColumn.forEach((sc, i) => {
            this.sizeList.forEach((sl, j) => {
              // i == 0 1.5
              const sizeVal = this.sizeValue[i][this.sizeNo];
              const sizing = this.sizing.filter(
                (e) => e.gender == this.gender.cd && e.fit == this.fit.cd
              )[0];
              //값이 입력된 경우
              if (i == 0) {
                //총 기장
                this.sizeValue[i][j] =
                  Number(sizeVal) + Number(j - this.sizeNo) * sizing.tolDvtn;
              } else if (i == 1 || i == 2 || i == 4 || i == 5) {
                //어깨 / 가슴 / 밑단 단면
                if (i == 4 && !(sizeVal > 0)) {
                  //가슴 단면
                  if (
                    this.gender.cd == "1" &&
                    (this.fit.cd == "1" || this.fit.cd == "2")
                  ) {
                    this.sizeValue[i][j] = Number(this.sizeValue[1][j] - 8);
                  } else if (
                    this.gender.cd == "2" &&
                    (this.fit.cd == "1" || this.fit.cd == "2")
                  ) {
                    this.sizeValue[i][j] = Number(this.sizeValue[1][j] - 6);
                  } else {
                    this.sizeValue[i][j] = Number(this.sizeValue[1][j]);
                  }
                } else if (i == 5 && !(sizeVal > 0)) {
                  //허리 둘레
                  if (this.gender.cd == "1" && this.fit.cd == "1") {
                    this.sizeValue[i][j] = Number(this.sizeValue[1][j] - 2);
                  } else {
                    this.sizeValue[i][j] = Number(this.sizeValue[1][j]);
                  }
                } else {
                  this.sizeValue[i][j] =
                    Number(sizeVal) + Number(j - this.sizeNo) * sizing.shsDvtn;
                }
              } else if (i == 3) {
                //소매단 기장
                if (sizeVal < 35) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) +
                    Number(j - this.sizeNo) * sizing.sllDvtn[0];
                } else if (sizeVal >= 35 && sizeVal < 40) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) +
                    Number(j - this.sizeNo) * sizing.sllDvtn[1];
                } else if (sizeVal >= 40 && sizeVal < 45) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) +
                    Number(j - this.sizeNo) * sizing.sllDvtn[2];
                } else if (sizeVal >= 45 && sizeVal < 50) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) +
                    Number(j - this.sizeNo) * sizing.sllDvtn[3];
                } else if (sizeVal >= 50) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) +
                    Number(j - this.sizeNo) * sizing.sllDvtn[4];
                }
              } else if (i == 6) {
                //암홀(소매통+1.5)
                if (sizeVal > 0) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) + Number(j - this.sizeNo) * sizing.ahlDvtn;
                } else if (this.sizeValue[8][j] > 0) {
                  this.sizeValue[i][j] = Number(this.sizeValue[8][j]) + 1.5;
                } else {
                  this.sizeValue[i][j] = sizeSpec[j].ahlSize;
                }
              } else if (i == 7) {
                //소매단 단면(소매통 둘레 (단면) - 1cm	)
                if (this.sizeValue[i][j] > 0) {
                  if (this.sizeValue[3][j] < 35) {
                    this.sizeValue[i][j] =
                      Number(sizeVal) +
                      Number(j - this.sizeNo) * sizing.scsDvtn[0];
                  } else if (
                    this.sizeValue[3][j] >= 35 &&
                    this.sizeValue[3][j] < 40
                  ) {
                    this.sizeValue[i][j] =
                      Number(sizeVal) +
                      Number(j - this.sizeNo) * sizing.scsDvtn[1];
                  } else if (
                    this.sizeValue[3][j] >= 40 &&
                    this.sizeValue[3][j] >= 45
                  ) {
                    this.sizeValue[i][j] =
                      Number(sizeVal) +
                      Number(j - this.sizeNo) * sizing.scsDvtn[2];
                  } else if (
                    this.sizeValue[3][j] >= 45 &&
                    this.sizeValue[3][j] >= 50
                  ) {
                    this.sizeValue[i][j] =
                      Number(sizeVal) +
                      Number(j - this.sizeNo) * sizing.scsDvtn[3];
                  } else if (this.sizeValue[3][j] >= 50) {
                    this.sizeValue[i][j] =
                      Number(sizeVal) +
                      Number(j - this.sizeNo) * sizing.scsDvtn[4];
                  }
                } else {
                  this.sizeValue[i][j] = 0;
                }
              } else if (i == 8) {
                //소매통(암홀 (직선) - 1.5cm	)
                //반팔 긴팔 정의 필요!!!!!!!!!!!!!!!!!!!!!!!!
                if (this.sizeValue[i][j] > 0) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) + Number(j - this.sizeNo) * sizing.sbsDvtn;
                } else {
                  this.sizeValue[i][j] = Number(this.sizeValue[6][j] - 1.5);
                }
                if (this.sizeValue[7][j] == 0) {
                  this.sizeValue[7][j] = Number(this.sizeValue[i][j]) - 1;
                }
              } else if (i == 9) {
                //목 너비
                if (this.sizeValue[i].filter((e) => e > 0).length > 0) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) + Number(j - this.sizeNo) * sizing.newDvtn;
                } else {
                  this.sizeColumn.filter(
                    (e) =>
                      e.index == i &&
                      this.sizeValue[i].filter((e) => e > 0).length == 0
                  )[0].isDisabled = true;
                }
              } else if (i == 10) {
                //목 파임
                if (this.sizeValue[i].filter((e) => e > 0).length > 0) {
                  this.sizeValue[i][j] =
                    Number(sizeVal) + Number(j - this.sizeNo) * sizing.nedDvtn;
                } else {
                  this.sizeColumn.filter(
                    (e) =>
                      e.index == i &&
                      this.sizeValue[i].filter((e) => e > 0).length == 0
                  )[0].isDisabled = true;
                }
                console.log(this.sizeValue[i][j]);
                console.log(this.sizeColumn.filter((e) => e.index == i)[0]);
              }
            });
          });

          console.log(this.sizeColumn);
          console.log(this.sizeValue);
          this.sizeColumn.forEach((sc, i) => {
            this.sizeList.forEach((sl, j) => {
              this.sizeValue[i][j] = Number(this.sizeValue[i][j]).toFixed(1);
              // Math.round(this.sizeValue[i][j] * 100) / 100;
            });
            this.$emit("setSizeValeu", this.sizeValue[i], i);
          });
        });
    },
  },
  mounted() {
    this.items = this.$store.state.customPrint.items;
    this.sizeList = this.size;
    this.init();
  },
  methods: {
    init() {
      this.sizeColumn = [
        {
          index: 0,
          cd: 1,
          row: "A",
          name: "총 기장",
          isActive: false,
          isDisabled: false,
          isRequired: true,
        },
        {
          index: 1,
          cd: 2,
          row: "B",
          name: "가슴 단면",
          isActive: false,
          isDisabled: false,
          isRequired: true,
        },
        {
          index: 2,
          cd: 3,
          row: "C",
          name: "밑단 단면",
          isActive: false,
          isDisabled: false,
          isRequired: true,
        },
        {
          index: 3,
          cd: 4,
          row: "D",
          name: "소매 기장",
          isActive: false,
          isDisabled: false,
          isRequired: true,
        },
        {
          index: 4,
          cd: 5,
          row: "E",
          name: "어깨 단면",
          isActive: false,
          isDisabled: false,
          isRequired: false,
        },
        {
          index: 5,
          cd: 6,
          row: "F",
          name: "허리 단면",
          isActive: false,
          isDisabled: false,
          isRequired: false,
        },
        {
          index: 6,
          cd: 7,
          row: "G",
          name: "암홀 (직선)",
          isActive: false,
          isDisabled: false,
          isRequired: false,
        },
        {
          index: 7,
          cd: 8,
          row: "H",
          name: "소매단 단면",
          isActive: false,
          isDisabled: false,
          isRequired: false,
        },
        {
          index: 8,
          cd: 9,
          row: "I",
          name: "소매통 단면",
          isActive: false,
          isDisabled: false,
          isRequired: false,
        },
        {
          index: 9,
          cd: 10,
          row: "J",
          name: "목 너비",
          isActive: false,
          isDisabled: false,
          isRequired: false,
        },
        {
          index: 10,
          cd: 11,
          row: "K",
          name: "목 파임",
          isActive: false,
          isDisabled: false,
          isRequired: false,
        },
      ];
      this.sizeActive = [
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
        [true, true, true, true, true, true, true],
      ];
      this.sizeColumn.forEach((sc, i) => {
        this.sizeList.forEach((sl, j) => {
          this.sizeValue[i][j] = "";
        });
      });
    },
    focusSize(ci, si) {
      this.sizeColumn.forEach((sc, i) => {
        this.sizeList.forEach((sl, j) => {
          this.sizeActive[i][j] = false;
          if (j == si) {
            this.sizeActive[i][j] = true;
          }
        });
      });
    },
    keyUpSize(ci, si) {
      let value = 0;
      let required = 0;
      this.sizeNo = si;

      //사이즈 입력이 있는지 확인
      this.sizeColumn.forEach((sc, i) => {
        this.sizeList.forEach((sl, j) => {
          value += Number(this.sizeValue[i][j]);
        });
      });

      //사이즈 입력이 없는 경우 전체 활성화
      if (!(value > 0)) {
        this.sizeColumn.forEach((sc, i) => {
          this.sizeList.forEach((sl, j) => {
            this.sizeActive[i][j] = true;
          });
        });
      } else {
        this.sizeColumn.forEach((sc, i) => {
          if (sc.isRequired && this.sizeValue[i][si] > 0) {
            required++;
          }
        });
        if (this.sizeColumn.filter((e) => e.isRequired).length == required) {
          //필수 사이즈가 다 입력된경우
          this.$emit("onSizeSpec", true);
        } else {
          this.$emit("onSizeSpec", false);
        }
      }
    },
    focusOutSize(ci, si) {
      let value = 0;
      let required = 0;

      //사이즈 입력이 있는지 확인
      this.sizeColumn.forEach((sc, i) => {
        this.sizeList.forEach((sl, j) => {
          value += Number(this.sizeValue[i][j]);
          //사이즈 소수점 자동 입력
          if (this.sizeValue[i][j] > 0) {
            this.sizeValue[i][j] = Number(this.sizeValue[i][j]).toFixed(1);
          }
        });
      });

      //사이즈 입력이 없는 경우 전체 활성화
      if (!(value > 0)) {
        this.sizeColumn.forEach((sc, i) => {
          this.sizeList.forEach((sl, j) => {
            this.sizeActive[i][j] = true;
          });
        });
      } else {
        this.sizeColumn.forEach((sc, i) => {
          if (sc.isRequired && this.sizeValue[i][si] > 0) {
            required++;
          }
        });
        if (this.sizeColumn.filter((e) => e.isRequired).length == required) {
          //필수 사이즈가 다 입력된경우
          this.$emit("onSizeSpec", true);
        } else {
          this.$emit("onSizeSpec", false);
        }
      }

      let sizeName = "";
      if (si == 0) sizeName = "XS";
      if (si == 1) sizeName = "S";
      if (si == 2) sizeName = "M";
      if (si == 3) sizeName = "L";
      if (si == 4) sizeName = "XL";
      if (si == 5) sizeName = "2XL";
      if (si == 6) sizeName = "3XL";

      this.$emit("setSizeInput", ci + 1, sizeName, this.sizeValue[ci][si]);
    },
  },
};
</script>

<style lang="scss" scoped>
.size-wrap {
  position: relative;
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px !important;
  &.big {
    width: 692px;
  }
  &-flex {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    .spec {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      font-weight: 400;
      font-size: 12px;
      margin-right: 4px;
      &-row {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 36px;
        border-radius: 4px;
        color: $neutral-0;
        background: $neutral-60;
      }
      &-name {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 76px;
        height: 36px;
        border-radius: 4px;
        color: $neutral-0;
        background: $neutral-60;
      }
      .required {
        color: $neutral-80;
        background: $neutral-0;
        border: 1px solid $primary-20;
      }
    }
    .unit {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      height: 36px;
      border-radius: 4px;
      color: $neutral-0;
      background: $neutral-60;
      &:first-child {
        min-width: 108px;
        max-width: 108px;
      }
      &.disabled {
        border: 1px solid $neutral-10;
      }
      &.column {
        color: $neutral-80;
        background: $neutral-0;
        // border: 1px solid $neutral-10;
      }
      &.required {
        border: 1px solid $primary-50;
      }
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid $neutral-10;
        &-color {
          width: 24px;
          height: 24px;
          border-radius: 12px;
        }
      }
      .required {
        position: absolute;
        top: 2px;
        right: 2px;
      }
    }
    .empty {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      height: 38px;
      border-radius: 4px;
      color: $neutral-20;
      border: 1px solid $neutral-10;
      background: $neutral-5;
    }
    .deviation {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      min-width: 40px;
      height: 38px;
      background: $neutral-3;
      border: 1px solid $neutral-10;
      border-radius: 4px;
    }
    .size {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: $neutral-0;
      border: 1px solid $neutral-10;
      border-radius: 4px;
      background: $neutral-60;
    }
    input {
      text-align: center;
      color: $neutral-80 !important;
      background: $neutral-5 !important;
      &.active {
        background: $neutral-0 !important;
      }
    }
  }
  input {
    padding: 8px 0;
    // background: #ffffff;
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: $neutral-10;
    border-radius: 4px;
    width: 100%;
    height: 32px;

    &::placeholder {
      color: $neutral-10;
    }
    // &:focus {
    //   border-color: $primary-50;
    // }
    // &:hover {
    //   background: #fffbf2;
    // }
  }
  &.row {
    flex-direction: row !important;
  }
  .input-explain {
    font-size: 14px;
    color: $neutral-60;
  }
  &.error input {
    border-color: $secondary-60;
  }

  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid $neutral-10;
    border-radius: 4px;
    height: 120px;
    resize: none;
    font-size: 14px;
    &::placeholder {
      color: $neutral-30;
    }
  }
}
</style>
