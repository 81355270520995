import { createWebHistory, createRouter } from "vue-router";
// import store from './store/index'
// function showToast (msg) {
//   VueToast.open({
//     message: msg,
//     type: 'default',
//     position: 'bottom',
//     duration: 3000,
//     dismissible: true
//   })
// }
let isMobile = /(iphone|android|blackberry|webos)/i.test(navigator.userAgent);
if (
  navigator.userAgent.match(
    /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
  ) != null ||
  navigator.userAgent.match(/LG|SAMSUNG|Samsung/) != null ||
  (navigator.userAgent.match(/Windows NT/) != null &&
    window.matchMedia("only screen and (max-width: 768px)").matches)
) {
  isMobile = true;
} else {
  isMobile = false;
}
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Main"),
    // beforeEnter: (to, from, next) => {
    //   if (sessionStorage.getItem("userId")) {
    //     next("/dashboard");
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: "/mate/main",
    name: "MateMain",
    component: () => import("@/pages/comm/mate/MateMain"),
  },
  {
    path: "/mate/regist",
    name: "MateRegist",
    component: () => import("@/pages/comm/mate/MateRegist"),
  },
  {
    path: "/custom/list",
    name: "CustomListWeb",
    component: () =>
      isMobile
        ? import("@/pages/comm/custom/CustomList")
        : import("@/pages/web/custom/CustomList"),
  },
  // {
  //   path: "/custom/detail/:title/:imgUrl/:price",
  //   name: "CustomDetailWeb",
  //   component: () => import("@/pages/web/custom/CustomDetail"),
  // },
  {
    path: "/custom/builder/:customNo",
    name: "CustomBuilder",
    component: () => import("@/pages/web/custom/CustomBuilder"),
  },
  {
    path: "/custom/builder/print",
    name: "CustomBuilderPrint",
    component: () => import("@/pages/web/custom/CustomBuilderPrint"),
  },
  {
    path: "/custom/builder/board",
    name: "CustomBuilderBoard",
    component: () => import("@/pages/web/custom/CustomBuilderBoard"),
  },
  {
    path: "/custom/add/:customNo",
    name: "CustomAdd",
    component: () => import("@/pages/web/custom/CustomAdd"),
  },
  {
    path: "/custom/detail/:customNo",
    name: "CustomDetail",
    component: () => import("@/pages/web/custom/CustomDetail"),
  },
  {
    path: "/custom/order/:customNo",
    name: "CustomOrder",
    component: () => import("@/pages/web/custom/CustomOrder"),
  },
  {
    path: "/custom/order/list/:customNo",
    name: "CustomOrderList",
    component: () => import("@/pages/web/custom/CustomOrderList"),
  },
  {
    path: "/custom/order/complete/:state/:orderNo",
    name: "CustomOrderComplete",
    component: () => import("@/pages/web/custom/CustomOrderComplete"),
  },
  {
    path: "/custom/orderResult/:oid",
    name: "CustomOrderResult",
    component: () => import("@/pages/web/custom/CustomOrderResult"),
  },
  {
    path: "/mobile/info/custom",
    name: "CustomMobileInfo",
    component: () => import("@/pages/web/custom/CustomMobileInfo"),
  },

  // {
  //   path: "/webchats",
  //   name: "ChatWeb",
  //   component: () => import("@/pages/web/chat/ChatWeb"),
  //   props: true,
  //   children: [
  //     {
  //       path: "chat/:id",
  //       name: "ChatWebRoom",
  //       component: () => import("@/pages/comm/chatroom/ChatRoom"),
  //       props: true,
  //     },
  //     {
  //       path: "chat/:id/:compNo",
  //       name: "MatchingLoad",
  //       component: () => import("@/pages/comm/chatroom/ChatRoom"),
  //       props: true,
  //     },
  //     {
  //       path: "order-list/:room/:id",
  //       name: "ChatWebDesignOrderList",
  //       component: () =>
  //         import("@/pages/comm/chatroom/moblie/MoblieDesignOrderList"),
  //       props: true,
  //     },
  //     {
  //       path: "order-contract/:room/:id",
  //       name: "ChatWebDesignOrderContract",
  //       component: () =>
  //         import("@/pages/comm/chatroom/moblie/MoblieDesignOrderContract"),
  //       props: true,
  //     },
  //     {
  //       path: "contract/:designOrderNo/:factoryNo",
  //       name: "WebCompContract",
  //       component: () => import("@/components/contract/CompContract"),
  //       props: true,
  //     },
  //     {
  //       path: "order-payment/:factoryNo/:step",
  //       name: "WebDesignOrderPayment",
  //       component: () =>
  //         import("@/pages/comm/chatroom/moblie/MobileDesignOrderPayment"),
  //       props: true,
  //     },
  //   ],
  // },
  // {
  //   path: "/chats",
  //   name: "Chat",
  //   component: () =>
  //     isMobile
  //       ? import("@/pages/comm/chatroom/")
  //       : import("@/pages/web/chat/ChatWeb"),
  //   props: true,
  // },
  // {
  //   path: "/chats/:id",
  //   name: "ChatRoom",
  //   component: () => import("@/pages/comm/chatroom/ChatRoom"),
  //   props: true,
  //   meta: {},
  // },
  // {
  //   // 불러오기
  //   path: "/designOrderList/:room/:id",
  //   name: "MoblieDesignOrderList",
  //   component: () =>
  //     import("@/pages/comm/chatroom/moblie/MoblieDesignOrderList"),
  //   props: true,
  // },
  // {
  //   // 계약하기
  //   path: "/designOrderContract/:room/:id",
  //   name: "MoblieDesignOrderContract",
  //   component: () =>
  //     import("@/pages/comm/chatroom/moblie/MoblieDesignOrderContract"),
  //   props: true,
  // },
  // {
  //   // 계약 작성
  //   path: "/CompContract/:designOrderNo/:factoryNo",
  //   name: "CompContract",
  //   component: () => import("@/components/contract/CompContract"),
  //   props: true,
  // },
  {
    // 결제
    path: "/orderpayment/:factoryNo/:step",
    name: "MobileDesignOrderPayment",
    component: () => import("@/pages/comm/payment/PaymentDetail"),
    props: true,
  },
  {
    path: "/matchingintro",
    name: "MatchingIntro",
    component: () => import("@/pages/MatchingIntro"),
  },
  {
    path: "/osleintro",
    name: "OsleIntro",
    component: () => import("@/pages/OsleIntro"),
  },
  {
    path: "/subsidy",
    name: "Subsidy",
    component: () => import("@/pages/Subsidy"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/pages/comm/dashboard/DashboardMain"),
  },
  {
    path: "/matching/:target/:type/:clothes",
    name: "Matching",
    component: () => import("@/pages/comm/matching/Matching"),
  },
  {
    path: "/matchingconfirm",
    name: "MatchingConfirm",
    component: () => import("@/pages/comm/matching/MatchingConfirm"),
  },
  {
    path: "/matchinglist",
    name: "MatchingList",
    component: () => import("@/pages/comm/matching/MatchingList"),
  },
  {
    path: "/matchingdetails/:matchingNo",
    name: "MatchingDetails",
    component: () => import("@/pages/comm/matching/MatchingDetails"),
  },
  {
    path: "/matchingproposal/:matchingNo",
    name: "MatchingProposal",
    component: () => import("@/pages/comm/matching/MatchingProposal"),
  },
  {
    path: "/matchingrequeinfo/:matchingNo",
    name: "MatchingRequeinfo",
    component: () => import("@/pages/comm/matching/MatchingRequeinfo"),
  },
  {
    path: "/matchingrating/:matchingNo/:factoryNo",
    name: "matchingRating",
    component: () => import("@/pages/comm/matching/MatchingRating"),
  },
  {
    path: "/designorder/add",
    name: "DesignOrderAdd",
    component: () => import("@/pages/comm/designorder/DesignOrderRegister"),
  },
  // {
  //   path: "/designorder/list",
  //   name: "DesignOrderList",
  //   component: () => import("@/pages/comm/designorder/DesignOrderList"),
  // },
  {
    path: "/designorder/list",
    name: "DesignOrderList",
    component: () =>
      isMobile
        ? import("@/pages/comm/designorder/DesignOrderList")
        : import("@/pages/web/designorder/DesignOrderList"),
  },
  {
    path: "/designorder/list/:listNo",
    name: "DesignOrderListMy",
    component: () =>
      isMobile
        ? import("@/pages/comm/designorder/DesignOrderList")
        : import("@/pages/web/designorder/DesignOrderList"),
  },
  // {
  //   path: "/designorder/mylist",
  //   name: "DesignOrderMyList",
  //   component: () => import("@/pages/comm/designorder/DesignOrderMyList"),
  // },
  {
    path: "/designorder/detail/:designOrderNo",
    name: "DesignOrderDetail",
    component: () => import("@/pages/comm/designorder/DesignOrderDetail"),
  },
  {
    path: "/designorder/detail/factory/:designOrderNo",
    name: "DesignOrderDetailFactory",
    // component: () =>
    //   import("@/pages/comm/designorder/DesignOrderDetailFactory"),
    component: () =>
      isMobile
        ? import("@/pages/comm/designorder/DesignOrderDetailFactory")
        : import("@/pages/web/designorder/DesignOrderDetailFactory"),
  },
  {
    path: "/designorder/detail/order/:designOrderNo",
    name: "DesignOrderDetailOrder",
    component: () =>
      isMobile
        ? import("@/pages/comm/designorder/DesignOrderDetailOrder")
        : import("@/pages/web/designorder/DesignOrderDetailOrder"),
  },
  {
    path: "/designorder/detail/designer/:designOrderNo",
    name: "DesignOrderDetailDesigner",
    component: () =>
      import("@/pages/comm/designorder/DesignOrderDetailDesigner"),
  },
  {
    path: "/designorder/contract/:designOrderNo/:contractNo",
    name: "DesignOrderContract",
    component: () => import("@/pages/comm/designorder/DesignOrderContract"),
  },
  {
    path: "/mall/materiallist",
    name: "MaterialList",
    component: () =>
      isMobile
        ? import("@/pages/comm/mall/MaterialList")
        : import("@/pages/web/mall/MaterialList"),
  },
  {
    path: "/mall/materialdetail/:mallNo",
    name: "MaterialDetail",
    component: () =>
      isMobile
        ? import("@/pages/comm/mall/MaterialDetail")
        : import("@/pages/web/mall/MaterialDetail"),
  },
  {
    path: "/mall/materialorder/:mallNo",
    name: "MaterialOrder",
    component: () => import("@/pages/comm/mall/MaterialOrder"),
  },
  {
    path: "/mall/materialbuy",
    name: "MaterialBuy",
    component: () =>
      isMobile
        ? import("@/pages/comm/mall/MaterialBuy")
        : import("@/pages/web/mall/MaterialBuy"),
  },
  {
    path: "/mall/buylist",
    name: "BuyList",
    component: () =>
      isMobile
        ? import("@/pages/comm/mall/BuyList")
        : import("@/pages/web/mall/BuyList"),
  },
  {
    path: "/mall/buyinfo",
    name: "BuyInfo",
    component: () => import("@/pages/comm/mall/BuyInfo"),
  },
  {
    path: "/mall/basket",
    name: "Basket",
    component: () =>
      isMobile
        ? import("@/pages/comm/mall/Basket")
        : import("@/pages/web/mall/Basket"),
  },
  {
    path: "/mall/buydetail/:orderNum",
    name: "BuyDetail",
    component: () =>
      isMobile
        ? import("@/pages/comm/mall/BuyDetail")
        : import("@/pages/web/mall/BuyDetail"),
  },
  {
    path: "/mall/buyrefund/:paymentDtlNo",
    name: "BuyRefund",
    component: () =>
      isMobile
        ? import("@/pages/comm/mall/BuyRefund")
        : import("@/pages/web/mall/BuyRefund"),
  },
  {
    path: "/mall/refund/:paymentNo",
    name: "Refund",
    component: () =>
      isMobile
        ? import("@/pages/comm/mall/Refund")
        : import("@/pages/web/mall/Refund"),
  },
  {
    path: "/search",
    name: "Search",
    component: () =>
      isMobile
        ? import("@/pages/comm/search/SearchMain")
        : import("@/pages/web/search/SearchMain"),
    // component: () => import("@/pages/comm/search/SearchMain"),
    children: [
      { path: "", redirect: "/search/factorylist" },
      // { path: 'factorylist',  name: 'FactoryList', component: () => import('@/pages/comm/search/FactoryList') },
      // {
      //   path: "factorylist",
      //   name: "FactoryList",
      //   component: () => import("@/pages/comm/search/FactoryList"),
      // },
      {
        path: "factorylist",
        name: "FactoryList",
        component: () =>
          isMobile
            ? import("@/pages/comm/search/FactoryList")
            : import("@/pages/web/search/FactoryList"),
      },
    ],
  },
  {
    path: "/search/factorydetail/:factoryNo",
    name: "FactoryDetail",
    component: () =>
      isMobile
        ? import("@/pages/comm/search/FactoryDetail")
        : import("@/pages/web/search/FactoryDetail"),
  },
  {
    path: "/search/factorydetails/:factoryNo",
    name: "FactoryDetails",
    component: () =>
      isMobile
        ? import("@/pages/comm/search/FactoryDetail")
        : import("@/pages/web/search/FactoryDetail"),
  },
  {
    path: "/search/factoryMap/:factoryNo",
    name: "FactoryMap",
    component: () => import("@/pages/comm/search/FactoryMap"),
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/pages/comm/account/AccountMain"),
    children: [
      {
        path: "/",
        name: "AccountMain",
        component: () => import("@/pages/comm/account/AccountMain"),
      },
      {
        path: "login",
        name: "Login",
        component: () => import("@/pages/comm/account/Login"),
      },
      {
        path: "register/type",
        name: "RegisterType",
        component: () => import("@/pages/comm/account/RegisterType"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/pages/comm/account/Register"),
      },
      {
        path: "find-id",
        name: "FindId",
        component: () => import("@/pages/comm/account/FindId"),
      },
      {
        path: "find-pw",
        name: "FindPw",
        component: () => import("@/pages/comm/account/FindPw"),
      },
      {
        path: "newpw",
        name: "NewPw",
        component: () => import("@/pages/comm/account/NewPw"),
      },
    ],
  },
  {
    path: "/login/naver",
    name: "NaverLogin",
    component: () => import("@/pages/comm/account/NaverLogin"),
  },
  {
    path: "/register/naver",
    name: "NaverRegister",
    component: () => import("@/pages/comm/account/NaverRegister"),
  },
  {
    path: "/mypage",
    name: "mypage",
    component: () => import("@/pages/comm/mypage/MypageMain"),
    children: [
      { path: "", redirect: "/mypage/menu" },
      {
        path: "menu",
        name: "Menu",
        component: () => import("@/pages/comm/mypage/Menu"),
      },
      {
        path: "myinfo",
        name: "Myinfo",
        component: () => import("@/pages/comm/mypage/Myinfo"),
      },
      {
        path: "new-pw",
        name: "MyNewPw",
        component: () => import("@/pages/comm/mypage/MyNewPw"),
      },
      {
        path: "withdrawal",
        name: "withdrawal",
        component: () => import("@/pages/comm/mypage/Withdrawal"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("@/pages/comm/mypage/ProfileMain"),
      },
      {
        path: "profile/complete",
        name: "ProfileComplete",
        component: () => import("@/pages/comm/mypage/ProfileComplete"),
      },
      {
        path: "profile/user",
        name: "ProfileUser",
        component: () => import("@/pages/comm/mypage/ProfileUser"),
      },
      {
        path: "coupon",
        name: "Coupon",
        component: () =>
          isMobile
            ? import("@/pages/comm/mypage/Coupon")
            : import("@/pages/web/mypage/Coupon"),
      },
      {
        path: "factorylist",
        name: "FavoriteFactoryList",
        component: () => import("@/pages/comm/mypage/FavoriteFactoryList"),
        // beforeEnter: (to, from, next) => {
        //   if (sessionStorage.getItem('userId')) {
        //     next()
        //   } else {
        //     // showToast('qwe')
        //     next('/account/login')
        //   }
        // }
      },
      {
        path: "factory/auth/:step",
        name: "FactoryAuth",
        component: () => import("@/pages/comm/mypage/FactoryAuth"),
      },
      {
        path: "designer/auth",
        name: "DesignerAuth",
        component: () => import("@/pages/comm/mypage/DesignerAuth"),
      },
      {
        path: "noticelist",
        name: "NoticeList",
        component: () =>
          isMobile
            ? import("@/pages/comm/mypage/NoticeList")
            : import("@/pages/web/mypage/NoticeList"),
      },
      {
        path: "noticedetail/:noticeNo",
        name: "NoticeDetail",
        component: () => import("@/pages/comm/mypage/NoticeDetail"),
      },
      {
        path: "alarmlist",
        name: "AlarmList",
        component: () =>
          isMobile
            ? import("@/pages/comm/mypage/AlarmList")
            : import("@/pages/web/mypage/AlarmList"),
      },
      {
        path: "messagelist",
        name: "MessageList",
        component: () => import("@/pages/comm/mypage/MessageList"),
      },
      {
        path: "inquirelist",
        name: "InquireList",
        component: () => import("@/pages/comm/mypage/InquireList"),
      },
      {
        path: "faq",
        name: "FaqList",
        component: () => import("@/pages/comm/mypage/Faq"),
      },
      {
        path: "inquire",
        name: "InquireReg",
        component: () => import("@/pages/comm/mypage/Inquire"),
      },
      {
        path: "factorymatchinglist",
        name: "FactoryMatchingList",
        component: () => import("@/pages/comm/mypage/FactoryMatchingList"),
      },
      {
        path: "factoryboard/:factoryNo",
        name: "FacDashBoard",
        component: () => import("@/pages/comm/mypage/FacDashBoard"),
      },
    ],
  },
  {
    path: "/nice/auth",
    name: "NiceAuth",
    component: () => import("@/pages/comm/nice/NiceAuth"),
  },
  {
    path: "/nice/:state",
    name: "NiceState",
    component: () => import("@/pages/comm/nice/NiceState"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/pages/comm/terms/TermsMain"),
    children: [
      { path: "", redirect: "/terms/termsofservice" },
      {
        path: "termsofservice",
        name: "TermsOfService",
        component: () => import("@/pages/comm/terms/TermsOfService"),
      },
      {
        path: "privacypolicy",
        name: "PrivacyPolicy",
        component: () => import("@/pages/comm/terms/PrivacyPolicy"),
      },
    ],
  },
  {
    path: "/calculate/list",
    name: "CalculateList",
    component: () =>
      isMobile
        ? import("@/pages/comm/calculate/CalculateList")
        : import("@/pages/web/calculate/CalculateList"),
  },
  {
    path: "/calculate/list/:paymentDtlNo",
    name: "CalculateListNo",
    component: () =>
      isMobile
        ? import("@/pages/comm/calculate/CalculateList")
        : import("@/pages/web/calculate/CalculateList"),
  },
  {
    path: "/calculate/detail/:paymentDtlNo",
    name: "CalculateDetail",
    component: () => import("@/pages/comm/calculate/CalculateDetail"),
  },
  {
    path: "/manage/list",
    name: "ManageList",
    component: () =>
      isMobile
        ? import("@/pages/comm/calculate/ManageList")
        : import("@/pages/web/calculate/ManageList"),
  },
  {
    path: "/contract/list",
    name: "ContractList",
    component: () =>
      isMobile
        ? import("@/pages/comm/contract/ContractList")
        : import("@/pages/web/contract/ContractList"),
  },
  {
    path: "/contract/list/:contractNo",
    name: "ContractListNo",
    component: () =>
      isMobile
        ? import("@/pages/comm/contract/ContractList")
        : import("@/pages/web/contract/ContractList"),
  },
  {
    path: "/contract/:designOrderNo/:factoryNo",
    name: "Contrac",
    component: () => import("@/pages/comm/contract/Contract"),
  },
  {
    path: "/contract/detail/:contractNo",
    name: "ContractDetail",
    component: () => import("@/pages/comm/contract/ContractDetail"),
  },
  {
    path: "/payment",
    name: "Payment",
    component: () =>
      isMobile
        ? import("@/pages/comm/payment/PaymentMain")
        : import("@/pages/web/payment/PaymentMain"),
    children: [
      {
        path: "list",
        name: "PaymentList",
        component: () =>
          isMobile
            ? import("@/pages/comm/payment/PaymentList")
            : import("@/pages/web/payment/PaymentList"),
      },
      {
        path: "list/:paymentNo",
        name: "PaymentDetail",
        component: () =>
          isMobile
            ? import("@/pages/comm/payment/PaymentList")
            : import("@/pages/web/payment/PaymentList"),
      },
      {
        path: "toss/:state",
        name: "PaymentState",
        component: () => import("@/pages/comm/payment/PaymentComplete"),
      },
    ],
  },
  {
    path: "/payment/toss/:state",
    name: "PaymentState",
    component: () => import("@/pages/comm/payment/PaymentComplete"),
  },
  {
    path: "/payment/payple/:state",
    name: "PaymentState",
    component: () => import("@/pages/comm/payment/PaymentComplete"),
  },
  {
    path: "/payment/:factoryNo/:step",
    name: "MobileDesignOrderPayment",
    component: () => import("@/pages/comm/payment/Payment"),
    props: true,
  },
  {
    path: "/payment/external/:factoryNo/:step",
    name: "PaymentExternal",
    component: () => import("@/pages/comm/payment/PaymentExternal"),
  },
  {
    path: "/ticket/list",
    name: "TicketList",
    component: () =>
      isMobile
        ? import("@/pages/comm/ticket/TicketList")
        : import("@/pages/web/ticket/TicketList"),
  },
  {
    path: "/ticket/ticketbuy",
    name: "TicketBuy",
    component: () => import("@/pages/comm/ticket/TicketBuy"),
  },
  {
    path: "/ticket/select/:itemId",
    name: "TicketSelect",
    component: () => import("@/pages/comm/ticket/PaymentSelect"),
  },
  {
    path: "/payment/complete",
    name: "TicketComplete",
    component: () => import("@/pages/comm/payment/PaymentComplete"),
  },
  {
    path: "/ticket/result/:id",
    name: "OrderResult",
    component: () => import("@/pages/comm/ticket/OrderResult"),
  },
  {
    path: "/adminlogin",
    name: "adminLogin",
    component: () => import("@/pages/AdminLogin"),
  },
  {
    path: "/template",
    name: "Template",
    component: () => import("@/pages/Template"),
  },
  {
    path: "/sim",
    name: "Sim",
    component: () => import("@/pages/Sim"),
  },
  {
    path: "/tel",
    name: "Tel",
    component: () => import("@/pages/Tel"),
  },
  {
    path: "/external/profile/factory/:factoryNo",
    name: "ProfileFactory",
    component: () => import("@/pages/external/ProfileFactory"),
  },
  // {
  //   path: "/external/profile/factory/input/:factoryNo",
  //   name: "ProfileFactory",
  //   component: () => import("@/pages/external/ProfileFactory"),
  // },
  {
    path: "/404",
    name: "404",
    component: () => import("@/pages/404"),
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "/404",
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
