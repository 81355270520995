<template>
  <div class="custom-add" ref="customAdd">
    <div class="custom-add-body">
      <div class="body-title" v-show="step == 0">· 원단을 선택해주세요.</div>
      <div class="content-wrap" v-show="step == 0">
        <div class="content-wrap-tab">
          <div
            class="tab-wrap"
            v-for="(x, i) in fabric"
            :key="i"
            :class="{ active: x[0].fabricCatgCd == fabricCatgCd }"
            @click="toggleFabricTab(x)"
          >
            <p>{{ x[0].fabricCatg }}</p>
          </div>
        </div>
        <div class="row pd-0 bs-0">
          <div class="fabric">
            <div
              class="fabric-wrap"
              v-for="(x, j) in fabric[fabricCatgCd]"
              :key="j"
              :class="x.isActive && isFabricColor ? 'active' : ''"
            >
              <div
                class="fabric-wrap-info"
                :class="x.isActive && isFabricColor ? 'active' : ''"
                @click="toggleFabric(x.fabricCatgCd, j)"
              >
                <div class="badge best" v-if="x.badge == '1'">
                  <SvgImg
                    :size="12"
                    :d="$store.state.meta.svg.starIcon"
                    :color="$store.state.meta.svg.color.neutral0"
                  />
                  <p>BEST</p>
                </div>
                <div class="badge sale" v-if="x.badge == '2'">
                  <SvgImg
                    :size="12"
                    :d="$store.state.meta.svg.starIcon"
                    :color="$store.state.meta.svg.color.neutral0"
                  />
                  <p>SALE</p>
                </div>
                <div class="badge new" v-if="x.badge == '3'">
                  <SvgImg
                    :size="12"
                    :d="$store.state.meta.svg.starIcon"
                    :color="$store.state.meta.svg.color.neutral0"
                  />
                  <p>NEW</p>
                </div>
                <div class="fabric-wrap-info-img">
                  <img :src="x.thumbnailUrl" />
                </div>
                <div class="fabric-wrap-info-content">
                  <div class="fabric-name">
                    <p>
                      {{ x.fabricName }}
                      <span>({{ x.fabricWeight }})</span>
                    </p>
                    <p>
                      <SvgImg
                        :size="20"
                        :d="$store.state.meta.svg.rightArrowBig"
                        :color="
                          x.isActive
                            ? $store.state.meta.svg.color.primary50
                            : $store.state.meta.svg.color.neutral40
                        "
                      />
                    </p>
                  </div>
                  <div class="mix-rate">{{ x.mixRate }}</div>
                  <div class="tags">
                    <p
                      class="tag"
                      v-for="(tag, k) in x.hashTag?.split(',')"
                      :key="k"
                    >
                      {{ tag }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="fabric-wrap-color" v-if="x.isActive && isFabricColor">
                <div class="color-list">
                  <div
                    class="color-list-area"
                    v-for="(color, i) in x.colorList"
                    :key="i"
                    @click.stop="toggleColor(x.fabricCatgCd, j, i)"
                    :class="color.isActive ? 'active' : ''"
                  >
                    <div class="color-img">
                      <img :src="color.fileUrl" />
                    </div>
                    <div class="color-content">
                      <p>{{ color.colorCode }}</p>
                      <p>{{ color.colorName }}</p>
                    </div>
                  </div>
                </div>
                <div class="color-btns">
                  <div
                    class="color-btns-btn select"
                    @click.stop="selectColor(x.fabricCatgCd, j)"
                  >
                    <p>컬러 선택</p>
                  </div>
                </div>
              </div>
              <div
                class="fabric-wrap-bottom"
                @click="toggleFabric(x.fabricCatgCd, j)"
              >
                <p>스왓치 컬러 ({{ x.colorList.length }}종)</p>
                <SvgImg
                  :size="20"
                  :d="
                    x.isActive && isFabricColor
                      ? $store.state.meta.svg.dropUp
                      : $store.state.meta.svg.dropDown
                  "
                  :color="$store.state.meta.svg.color.neutral40"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-title" v-show="step == 1">
        · 사이즈 기준을 선택해주세요.
      </div>
      <div class="content-wrap" v-show="step == 1">
        <div class="row">
          <div class="content-title">· 사이즈 기준</div>
          <div class="list-wrap">
            <div class="content">
              <span>※</span>
              <p>
                사이즈 기준의 경우, 브랜드나 원단 및 봉제 사양, 디테일, 스타일
                등 다양한 변수에 따라 다를 수 있습니다.
              </p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                ‘사이즈 기준’을 선택하신 후, ‘사이즈 스펙 생성’을 클릭하시면,
                선택하신 기준에 따라 ‘오슬’ 기준의 기본 사이즈 스펙으로
                생성됩니다.
              </p>
            </div>
          </div>
          <div class="content-title-div mt-20">
            <p class="content-title">1. 성별 <span>*</span></p>
          </div>
          <div class="btns-wrap">
            <p
              class="btns-wrap-btn"
              v-for="(x, i) in gender"
              :key="i"
              @click="toggleComm('gender', i)"
              :class="x.isActive ? 'active' : ''"
            >
              {{ x.name }}
            </p>
          </div>
          <div class="content-title-div mt-20">
            <p class="content-title">2. 핏 <span>*</span></p>
          </div>
          <div class="btns-wrap">
            <p
              class="btns-wrap-btn"
              v-for="(x, i) in fit"
              :key="i"
              @click="toggleComm('fit', i)"
              :class="x.isActive ? 'active' : ''"
            >
              {{ x.name }}
            </p>
          </div>
          <div class="content-title-div mt-20">
            <p class="content-title">3. 총 기장 <span>*</span></p>
          </div>
          <div class="btns-wrap">
            <p
              class="btns-wrap-btn"
              v-for="(x, i) in totalLength"
              :key="i"
              @click="toggleComm('totalLength', i)"
              :class="x.isActive ? 'active' : ''"
            >
              {{ x.name }}
            </p>
          </div>
          <div class="content-title-div mt-20">
            <p class="content-title">4. 소매 기장 <span>*</span></p>
          </div>
          <div class="btns-wrap">
            <p
              class="btns-wrap-btn"
              v-for="(x, i) in sleeveLength"
              :key="i"
              @click="toggleComm('sleeveLength', i)"
              :class="x.isActive ? 'active' : ''"
            >
              {{ x.name }}
            </p>
          </div>
        </div>

        <div class="mt-20 mb-20">
          <div class="validation" v-if="step == 1">
            <p class="active">(필수)</p>

            <p :class="{ active: gender.filter((e) => e.isActive).length > 0 }">
              성별
            </p>
            <p :class="{ active: fit.filter((e) => e.isActive).length > 0 }">
              핏
            </p>
            <p
              :class="{
                active: totalLength.filter((e) => e.isActive).length > 0,
              }"
            >
              총 기장
            </p>
            <p
              :class="{
                active: sleeveLength.filter((e) => e.isActive).length > 0,
              }"
            >
              소매 기장
            </p>
            <!-- <p :class="{ active: sideSlit.filter((e) => e.isActive).length > 0 }">사이즈</p> -->
          </div>
          <div
            class="size-btn mt-12"
            :class="{ active: isSizeConfirm }"
            @click="isSizeConfirm ? getSizeSpec() : ''"
          >
            <p>사이즈 스펙 확인</p>
          </div>
        </div>

        <div class="row">
          <div class="content-title">· 사이즈 스펙</div>
          <div class="list-wrap">
            <div class="content">
              <span>※</span>
              <p>
                상세 사이즈는 측정 방법과 위치에 따라 1~3cm의 오차가 발생할 수
                있습니다.
              </p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                해당 사이즈 스펙과 실측과의 1~3cm 이내의 오차는 불량 처리 및
                교환, 환불 사유가 될 수 없습니다.
              </p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                사이즈 측정은 상품을 바닥에 펼친 후, 끝선과 끝선까지 측정해야
                하며, 신축성이 있는 원단일 경우, 잡아당기지 않고 바닥에 그대로
                펼쳐진 상태에서 측정해야 합니다.
              </p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                오차 범위 이외의 잘못된 측정으로 인한 불량 처리 및 교환, 환불은
                어려운 점 양해 부탁드립니다.
              </p>
            </div>
          </div>
          <SizeTable :size="size" :disabled="isSizeDisable" />
        </div>
      </div>

      <div class="body-title" v-show="step == 2">
        · 디자인 및 디테일을 선택해주세요..
      </div>
      <div class="content-wrap" v-show="step == 2">
        <div class="row">
          <div class="content-title">· 네크라인 (Neckline)</div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div
                v-for="(x, i) in neckLine"
                :key="i"
                @click="toggleComm('neckLine', i)"
                class="imgs-btn"
                :class="x.isActive ? 'active' : ''"
              >
                <div class="imgs-img design">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <div class="imgs-title" :class="x.isActive ? 'active' : ''">
                  <p>{{ x.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          :class="{
            disabled: !(neckLine.filter((e) => e.isActive).length > 0),
          }"
        >
          <div class="content-title">· 네크라인 디테일 (Neckline Detail)</div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div
                v-for="(x, i) in neckDetail"
                :key="i"
                @click="
                  neckLine.filter((e) => e.isActive).length > 0
                    ? toggleComm('neckDetail', i)
                    : ''
                "
                class="imgs-btn"
                :class="x.isActive ? 'active' : ''"
              >
                <div class="imgs-img design">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <div class="imgs-title" :class="x.isActive ? 'active' : ''">
                  <p>{{ x.name }}</p>
                  <p class="sub">{{ x.subName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          :class="{
            disabled: !(neckDetail.filter((e) => e.isActive).length > 0),
          }"
        >
          <div class="content-title">· 네크라인 마감</div>
          <div class="content-desc">
            해리의 경우, 제 원단 (몸판 원단)을 기본으로 합니다.
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div
                v-for="(x, i) in neckEnd"
                :key="i"
                @click="
                  neckDetail.filter((e) => e.isActive).length > 0
                    ? toggleComm('neckEnd', i)
                    : ''
                "
                class="imgs-btn"
                :class="x.isActive ? 'active' : ''"
              >
                <div class="imgs-img design">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <div class="imgs-title" :class="x.isActive ? 'active' : ''">
                  <p>{{ x.name }}</p>
                  <p class="sub">{{ x.subName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          :class="{
            disabled: !(neckEnd.filter((e) => e.isActive).length > 0),
          }"
        >
          <div class="content-title">· 스티치 (Stitch)</div>
          <div class="content-desc">
            스티치의 경우, 네크라인과 어깨선을 기본으로 합니다.
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div
                v-for="(x, i) in stitch"
                :key="i"
                @click="
                  neckEnd.filter((e) => e.isActive).length > 0
                    ? toggleComm('stitch', i)
                    : ''
                "
                class="imgs-btn"
                :class="x.isActive ? 'active' : ''"
              >
                <div class="imgs-img design">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <div class="imgs-title" :class="x.isActive ? 'active' : ''">
                  <p>{{ x.name }}</p>
                  <p class="sub">{{ x.subName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          :class="{
            disabled: !(stitch.filter((e) => e.isActive).length > 0),
          }"
        >
          <div class="content-title">· 포켓 (Pocket)</div>
          <div class="content-desc">
            포켓의 경우, 왼쪽 가슴에 10cm × 12cm 사이즈를 기본으로 합니다.
          </div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div
                v-for="(x, i) in pocket"
                :key="i"
                @click="
                  stitch.filter((e) => e.isActive).length > 0
                    ? toggleComm('pocket', i)
                    : ''
                "
                class="imgs-btn"
                :class="x.isActive ? 'active' : ''"
              >
                <div class="imgs-img design">
                  <img
                    v-if="x.fileUrl"
                    :src="require(`@/assets/img/${x.fileUrl}`)"
                  />
                </div>
                <div class="imgs-title" :class="x.isActive ? 'active' : ''">
                  <p>{{ x.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          :class="{
            disabled: !(pocket.filter((e) => e.isActive).length > 0),
          }"
        >
          <div class="content-title">· 소매 밑단</div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div
                v-for="(x, i) in sleeve"
                :key="i"
                @click="
                  pocket.filter((e) => e.isActive).length > 0
                    ? toggleComm('sleeve', i)
                    : ''
                "
                class="imgs-btn"
                :class="x.isActive ? 'active' : ''"
              >
                <div class="imgs-img design">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <div class="imgs-title" :class="x.isActive ? 'active' : ''">
                  <p>{{ x.name }}</p>
                  <p class="sub">{{ x.subName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          :class="{
            disabled: !(sleeve.filter((e) => e.isActive).length > 0),
          }"
        >
          <div class="content-title">· 옆 트임</div>
          <div class="imgs-wrap">
            <div class="imgs">
              <div
                v-for="(x, i) in sideSlit"
                :key="i"
                @click="
                  sleeve.filter((e) => e.isActive).length > 0
                    ? toggleComm('sideSlit', i)
                    : ''
                "
                class="imgs-btn"
                :class="x.isActive ? 'active' : ''"
              >
                <div class="imgs-img design">
                  <img :src="require(`@/assets/img/${x.fileUrl}`)" />
                </div>
                <div class="imgs-title" :class="x.isActive ? 'active' : ''">
                  <p>{{ x.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="body-title" v-show="step == 3">
        · 프린팅이나 자수가 있을 경우, 해당 위치를 선택하여 이미지를
        첨부해주세요.
      </div>
      <div class="content-wrap" v-show="step == 3">
        <div class="row">
          <div class="content-title">· 인쇄</div>
          <div class="list-wrap">
            <div class="content">
              <span>※</span>
              <p>ai, png, jpg 형식의 고화질 이미지를 첨부해주세요.</p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                이미지 파일의 해상도는 가로 또는 세로 중 긴축 기준으로 2,500px
                이상, 해상도 1,500dpi 이상으로 지정해주세요.
              </p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                작은 이미지를 임의로 크게 확대하여 인쇄할 경우, 화질이 깨질 수
                있습니다.
              </p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                모니터나 핸드폰 화면과 실제 인쇄 색상은 다르게 보일 수 있습니다.
              </p>
            </div>
            <div class="content">
              <span>※</span>
              <p>인쇄 위치에 따라 크기의 제한이 있을 수 있습니다.</p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                ‘자수’를 원하시는 경우, 디자인 검토 후 작업 가능 여부를
                알려드리며, 추가 요금이 발생할 수 있습니다.
              </p>
            </div>
            <div class="content">
              <span>※</span>
              <p>
                상표 및 디자인 도용, 저작권이 있는 디자인의 제작 의뢰를 절대
                금지하며, 고객님이 판매 목적으로 이를 어길 시, 저작권 위반으로
                발생되는 모든 사항에 대하여 ‘오슬’에서는 책임지지 않습니다.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="content-title">· 인쇄 이미지 (Front)</div>
          <div class="content-desc">
            인쇄를 원하시는 경우, 인쇄하실 부위 선택 후, 이미지를 첨부해주세요.
          </div>
          <div class="print-wrap">
            <img :src="require(`@/assets/img/print-front-img.png`)" />
            <div
              class="print-wrap-img front"
              v-for="(printData, i) in printFrontImgs"
              :key="i"
              :class="printData.img.fileUrl ? 'active' : ''"
            >
              <!-- <input
                class="hidden"
                type="file"
                :id="`frontFile${i + 1}`"
                accept=".gif, .jpg, .jpeg, .png"
                @change="fileUpload('frontList', $event)"
                multiple
              /> -->
              <div
                class="file-area"
                @click="openPrint('front', i)"
                v-if="printData.img.fileUrl"
              >
                <img
                  v-if="
                    ['.psd', '.ai', '.pdf', '.xlsx'].some(
                      (e) => printData.img.sourceFileName.indexOf(e) != -1
                    )
                  "
                  :src="
                    require(`@/assets/img/thumbnail-${
                      printData.img.sourceFileName.split('.')[1]
                    }.png`)
                  "
                />
                <img v-else :src="printData.img.fileUrl" />
              </div>
              <div
                class="file-area"
                v-html="printData.name.replace(' ', '<br/>')"
                @click="openPrint('front', i)"
                v-else
              ></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="content-title">· 인쇄 이미지 (Back)</div>
          <div class="content-desc">
            인쇄를 원하시는 경우, 인쇄하실 부위 선택 후, 이미지를 첨부해주세요.
          </div>
          <div class="print-wrap">
            <img :src="require(`@/assets/img/print-back-img.png`)" />
            <div
              class="print-wrap-img back"
              v-for="(printData, i) in printBackImgs"
              :key="i"
              :class="printData.img.fileUrl ? 'active' : ''"
            >
              <div
                class="file-area"
                @click="openPrint('back', i)"
                v-if="printData.img.fileUrl"
              >
                <img :src="printData.img.fileUrl" />
              </div>
              <div
                class="file-area"
                v-html="printData.name.replace(' ', '<br/>')"
                @click="openPrint('back', i)"
                v-else
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-wrap" v-show="step == 4"></div>
    </div>

    <div class="custom-add-bottom">
      <div class="validation" v-if="step == 0">
        <p class="active">(필수)</p>
        <p
          :class="{
            active: this.fabricCatgCd != '',
          }"
        >
          원단
        </p>
        <p :class="{ active: fabricColorList.length > 0 }">컬러</p>
      </div>
      <div class="validation" v-if="step == 2">
        <p class="active">(필수)</p>

        <p :class="{ active: neckLine.filter((e) => e.isActive).length > 0 }">
          네크라인
        </p>
        <p :class="{ active: neckDetail.filter((e) => e.isActive).length > 0 }">
          네크라인 디테일
        </p>
        <p :class="{ active: neckEnd.filter((e) => e.isActive).length > 0 }">
          네크라인 마감
        </p>
        <p :class="{ active: stitch.filter((e) => e.isActive).length > 0 }">
          스티치
        </p>
        <p :class="{ active: pocket.filter((e) => e.isActive).length > 0 }">
          포켓
        </p>
        <p :class="{ active: sleeve.filter((e) => e.isActive).length > 0 }">
          소매 밑단
        </p>
        <p :class="{ active: sideSlit.filter((e) => e.isActive).length > 0 }">
          옆 트임
        </p>
      </div>

      <div class="btns">
        <p v-if="step == 3" class="btns-btn active" @click="goModify()">
          디자인 확인
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.rightArrowBig"
            :color="$store.state.meta.svg.color.neutral0"
          />
        </p>
        <p
          v-else-if="
            (step == 0 && isValidation[0] == false) ||
            (step == 1 && isValidation[1] == false) ||
            (step == 2 && isValidation[2] == false)
          "
          class="btns-btn"
        >
          다음
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.rightArrowBig"
            :color="$store.state.meta.svg.color.neutral0"
          />
        </p>
        <p
          v-else-if="step < 3"
          class="btns-btn active"
          @click="toggleTab(Number(step * 1 + 1))"
        >
          다음
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.rightArrowBig"
            :color="$store.state.meta.svg.color.neutral0"
          />
        </p>
      </div>
    </div>
  </div>
  <div v-if="isPrintPopup" class="popup-print">
    <div class="popup-background" @click="closePrint()"></div>
    <div class="popup-print-wrap">
      <div class="popup-print-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.customAdd"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>이미지 인쇄</p>
        </div>
        <SvgImg
          class="pointer"
          :size="20"
          :d="$store.state.meta.svg.closePopup"
          :color="$store.state.meta.svg.color.neutral0"
          @click="closePrint()"
        />
      </div>

      <input
        class="hidden"
        type="file"
        id="imgFile"
        ref="imgFile"
        accept=".gif, .jpg, .jpeg, .png, .ai, .psd"
        @change="fileUpload('81', 1, $event)"
      />
      <div class="popup-print-wrap-body">
        <div class="body-left">
          <div class="body-left-wrap" v-if="printType == 'front'">
            <p>Front</p>
            <img :src="require(`@/assets/img/print-${printType}-img.png`)" />
            <div
              class="body-left-wrap-img front"
              v-for="(img, i) in printFrontImgs"
              :key="i"
              :class="i == printIndex ? 'on' : ''"
            >
              <img
                class="file-area"
                :src="
                  require(`@/assets/img/img-custom-print-${printType}${
                    i + 1
                  }.png`)
                "
              />
            </div>
          </div>
          <div class="body-left-wrap" v-if="printType == 'back'">
            <p>Back</p>
            <img :src="require(`@/assets/img/print-${printType}-img.png`)" />
            <div
              class="body-left-wrap-img back"
              v-for="(img, i) in printBackImgs"
              :class="i == printIndex ? 'on' : ''"
              :key="i"
            >
              <img
                class="file-area"
                :src="
                  require(`@/assets/img/img-custom-print-${printType}${
                    i + 1
                  }.png`)
                "
              />
            </div>
          </div>
          <div class="imgs" v-if="img.fileNo != null">
            <div
              class="imgs-img view pointer"
              @click="showImg(img.fileUrl, img.sourceFileName)"
            >
              <!-- <img :src="img.fileUrl" /> -->

              <img
                v-if="
                  ['.psd', '.ai', '.pdf', '.xlsx'].some(
                    (e) => img.sourceFileName.indexOf(e) != -1
                  )
                "
                :src="
                  require(`@/assets/img/thumbnail-${
                    img.sourceFileName.split('.')[1]
                  }.png`)
                "
              />
              <img v-else :src="img.fileUrl" />
              <div
                class="imgs-img-pop flex"
                @click.stop="removeImg(img.fileNo)"
              >
                <img src="@/assets/icon/ic-x-square.svg" />
              </div>
            </div>
          </div>
          <label
            v-else
            class="body-left-file drop-area"
            for="imgFile"
            @dragover.prevent="handleDragOver"
            @drop.prevent="handleDrop"
          >
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.fileupload"
              :color="$store.state.meta.svg.color.neutral20"
            />
            <p>파일 추가 또는 파일을 여기로 드래그</p>
          </label>

          <!-- <SvgImg
              :size="20"
              :d="$store.state.meta.svg.fileupload"
              :color="$store.state.meta.svg.color.neutral20"
            />
            <p>파일 추가 또는 파일을 여기로 드래그</p> -->
        </div>
        <div class="body-right">
          <div class="body-right-wrap">
            <p class="title">
              · 이미지의 가로와 세로 중 긴 방향을 선택하고, 길이를 입력해주세요.
              <span>*</span>
            </p>
            <div class="line-type">
              <SelectBoxRadius
                class="select-box"
                :data="lineType"
                :filterType="'lineType'"
                @selectData="selectData"
                :selectData="lineType.filter((e) => e.isActive)[0].name"
              ></SelectBoxRadius>
              <input
                type="number"
                v-model="longLength"
                placeholder="긴 방향의 길이를 입력해주세요."
                @keyup="checkPrint"
              />
              cm
            </div>
          </div>
          <div class="body-right-wrap" v-if="printType == 'front'">
            <div
              class="column"
              v-for="(data, i) in this.$store.state.customPrint.frontDesc[
                printIndex
              ].name"
              :key="i"
            >
              <p class="title">
                <span class="num">{{ i + 1 }}</span> {{ data }}
              </p>
              <div class="line-type">
                <input
                  type="number"
                  v-model="printLength[i]"
                  placeholder="이격 길이를 입력해주세요."
                  @keyup="checkPrint"
                />
                cm
              </div>
            </div>
          </div>
          <div class="body-right-wrap" v-if="printType == 'back'">
            <div
              class="column"
              v-for="(data, i) in this.$store.state.customPrint.backDesc[
                printIndex
              ].name"
              :key="i"
            >
              <p class="title">
                <span class="num">{{ i + 1 }}</span> {{ data }}
              </p>
              <div class="line-type">
                <input
                  type="number"
                  v-model="printLength[i]"
                  placeholder="이격 길이를 입력해주세요."
                  @keyup="checkPrint"
                />
                cm
              </div>
            </div>
          </div>
          <div class="body-right-wrap">
            <div class="column gap8">
              <CheckBox
                :name="'‘자수’ 요청'"
                :checked="agree[0]"
                :hoverOff="true"
                @click="agree[0] = !agree[0]"
              />
              <div class="check-desc">
                검토 후 작업 가능 여부를 알려드리며, 금액은 별도로 청구됩니다.
              </div>
            </div>
            <div class="column gap8">
              <CheckBox
                :name="'상품에 어울리는 위치로 세부 조정해드립니다.'"
                :checked="agree[1]"
                :hoverOff="true"
                @click="agree[1] = !agree[1]"
              />
              <div class="check-desc">
                위치 세부 조정 요청 시, 이미지의 크기는 수정하지 않고, 위치를
                세부 조정하며, 그로 인한 교환 및 환불, 반품은 불가합니다.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="popup-print-wrap-bottom">
        <div class="popup-print-wrap-bottom-btn pointer">
          <p
            class=""
            @click="isPrint ? addPrint() : ''"
            :class="isPrint ? 'active' : 'disable'"
          >
            확인
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgImg from "../SvgImg.vue";

export default {
  name: "CustomAdd",
  props: {
    moveStep: {
      type: String,
      description: "btn text",
    },
    fabricColor: {
      type: Object,
      description: "fabricColor List",
    },
  },
  data() {
    return {
      unitPrice: 0,
      isPrint: false,
      isFabricColor: false,
      isSizeConfirm: false,
      isSizeDisable: true,
      isValidation: [false, false, false],
      isPrintPopup: false,
      step: 0,
      agree: [false, false],
      lineType: [
        { cd: "1", name: "가로", isActive: true },
        { cd: "2", name: "세로", isActive: false },
      ],
      size: [
        { index: 0, isActive: true, cd: "01", name: "XS" },
        { index: 0, isActive: true, cd: "02", name: "S" },
        { index: 1, isActive: true, cd: "03", name: "M" },
        { index: 2, isActive: true, cd: "04", name: "L" },
        { index: 3, isActive: true, cd: "05", name: "XL" },
        { index: 4, isActive: true, cd: "06", name: "2XL" },
        { index: 4, isActive: true, cd: "07", name: "3XL" },
      ],
      color: [
        { index: 0, isActive: false, cd: "01", name: "화이트" },
        { index: 1, isActive: false, cd: "02", name: "블랙" },
        { index: 2, isActive: false, cd: "03", name: "멜란지" },
        { index: 3, isActive: false, cd: "04", name: "네이비" },
        { index: 4, isActive: false, cd: "05", name: "차콜" },
        { index: 5, isActive: false, cd: "06", name: "베이지" },
      ],
      fabric: [],
      fabricCatgCd: "1",
      gender: [
        { index: 0, cd: "1", isActive: false, name: "여성" },
        { index: 1, cd: "2", isActive: false, name: "남성(유니섹스)" },
        // { index: 2, cd: "3", isActive: false, name: "유니섹스" },
      ],
      fit: [
        { index: 0, cd: "1", isActive: false, name: "슬림/머슬 핏" },
        { index: 1, cd: "2", isActive: false, name: "레귤러 핏" },
        // { index: 2, cd: "3", isActive: false, name: "루즈 핏" },
        { index: 2, cd: "4", isActive: false, name: "오버 핏" },
      ],
      totalLength: [
        // { index: 0, cd: "1", isActive: false, name: "숏/크롭 기장" },
        { index: 0, cd: "1", isActive: false, name: "레귤러 기장" },
        { index: 1, cd: "2", isActive: false, name: "롱 기장" },
      ],
      sleeveLength: [
        { index: 0, cd: "1", isActive: false, name: "반팔" },
        // { index: 1, cd: "2", isActive: false, name: "5부" },
        // { index: 2, cd: "3", isActive: false, name: "7부" },
        { index: 1, cd: "4", isActive: false, name: "긴팔" },
      ],
      neckLine: [
        {
          index: 0,
          cd: 1,
          isActive: false,
          name: "라운드 넥",
          subName: "",
          fileUrl: "neckline-img1.png",
          addAmount: 2000,
        },
        {
          index: 1,
          cd: 2,
          isActive: false,
          name: "V 넥",
          subName: "",
          fileUrl: "neckline-img2.png",
          addAmount: 2000,
        },
        // {
        //   index: 2,
        //   cd: 3,
        //   isActive: false,
        //   name: "U 넥",
        //   subName: "",
        //   fileUrl: "neckline-img3.png",
        //   addAmount: 120,
        // },
      ],
      neckDetail: [
        {
          index: 0,
          cd: 1,
          isActive: false,
          name: "립",
          subName: "2cm (기본)",
          fileUrl: "neckdtl-img1.png",
          addAmount: 650,
        },
        {
          index: 1,
          cd: 2,
          isActive: false,
          name: "바인딩",
          subName: "1/2 inch (기본)",
          fileUrl: "neckdtl-img2.png",
          addAmount: 0,
          addRate: 0.1,
        },
      ],
      neckEnd: [
        {
          index: 0,
          cd: 1,
          isActive: false,
          name: "오바로크",
          subName: "",
          fileUrl: "neckfin-img1.png",
          addAmount: "-",
        },
        {
          index: 1,
          cd: 2,
          isActive: false,
          name: "해리",
          subName: "1cm (기본)",
          fileUrl: "neckfin-img2.png",
          addAmount: 0,
          addRate: 0.05,
        },
      ],
      stitch: [
        {
          index: 0,
          cd: 1,
          isActive: false,
          name: "없음",
          subName: "",
          fileUrl: "stitch-img1.png",
          addAmount: "-",
        },
        {
          index: 1,
          cd: 2,
          isActive: false,
          name: "본봉",
          subName: "2mm (기본)",
          fileUrl: "stitch-img2.png",
          addAmount: "-",
        },
        {
          index: 2,
          cd: 3,
          isActive: false,
          name: "갈라삼봉",
          subName: "2mm (기본)",
          fileUrl: "stitch-img3.png",
          addAmount: 200,
        },
        {
          index: 3,
          cd: 4,
          isActive: false,
          name: "체인",
          subName: "2mm (기본)",
          fileUrl: "stitch-img4.png",
          addAmount: 300,
        },
      ],
      pocket: [
        {
          index: 0,
          cd: 1,
          isActive: false,
          name: "없음",
          subName: "",
          fileUrl: "",
          addAmount: "-",
        },
        {
          index: 1,
          cd: 2,
          isActive: false,
          name: "사각",
          subName: "",
          fileUrl: "pocket-img1.png",
          addAmount: 600,
        },
        {
          index: 2,
          cd: 3,
          isActive: false,
          name: "오각",
          subName: "",
          fileUrl: "pocket-img2.png",
          addAmount: 600,
        },
        {
          index: 3,
          cd: 4,
          isActive: false,
          name: "육각",
          subName: "",
          fileUrl: "pocket-img3.png",
          addAmount: 600,
        },
      ],
      sleeve: [
        {
          index: 0,
          cd: 1,
          isActive: false,
          name: "기본 (삼봉)",
          subName: "",
          fileUrl: "sleeve-img1.png",
          addAmount: "-",
        },
        {
          index: 1,
          cd: 2,
          isActive: false,
          name: "립",
          subName: "반팔2cm (기본) 긴팔 5cm (기본)",
          fileUrl: "sleeve-img2.png",
          addAmount: 330,
        },
        {
          index: 2,
          cd: 3,
          isActive: false,
          name: "바인딩",
          subName: "1/2inch (기본)",
          fileUrl: "sleeve-img3.png",
          addAmount: 0,
          addRate: 0.05,
        },
      ],
      sideSlit: [
        {
          index: 0,
          cd: 1,
          isActive: false,
          name: "없음",
          subName: "",
          fileUrl: "sideslit-img1.png",
          addAmount: "-",
        },
        {
          index: 1,
          cd: 2,
          isActive: false,
          name: "있음",
          subName: "5cm (기본)",
          fileUrl: "sideslit-img2.png",
          addAmount: 700,
        },
      ],
      printFrontImgs: [
        {
          index: 0,
          cd: "01",
          name: "오른쪽 어깨",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 1,
          cd: "02",
          name: "오른쪽 가슴",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 2,
          cd: "03",
          name: "중앙 가슴",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 3,
          cd: "04",
          name: "왼쪽 가슴",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 4,
          cd: "05",
          name: "왼쪽 어깨",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 5,
          cd: "06",
          name: "오른쪽 소매단",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 6,
          cd: "07",
          name: "오른쪽 밑단",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 7,
          cd: "08",
          name: "중앙 밑단",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 8,
          cd: "09",
          name: "왼쪽 밑단",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 9,
          cd: "10",
          name: "왼쪽 소매단",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
      ],

      printBackImgs: [
        {
          index: 0,
          cd: "11",
          name: "왼쪽 등 위",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 1,
          cd: "12",
          name: "중앙 등 위",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 2,
          cd: "13",
          name: "오른쪽 등 위",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 3,
          cd: "14",
          name: "왼족 밑단",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 4,
          cd: "15",
          name: "중앙 밑단",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
        {
          index: 5,
          cd: "16",
          name: "오른쪽 밑단",
          img: {},
          lineType: "",
          longLength: "",
          printLength: [],
        },
      ],
      selectedColor: [],
      careLabelList: [],
      mainLabelList: [],
      descList: [],
      printList: [],
      frontList: [],
      backList: [],
      imgList: [],
      img: {},
      apiData: {},
      customData: {},
      errorData: {},
      sampleFlag: "",
      styleName: "",
      customNo: "",
      printType: "back",
      printIndex: "",
      longLength: "",
      printLength: [],
      userId: sessionStorage.getItem("userId"),
      fabricColorList: [],
      fabricCatg: [],
      fabricCost: 0,
      marking: 1,
    };
  },
  watch: {
    moveStep(newVal) {
      console.log(newVal);
      this.step = newVal;
    },
    // fabricColor(newVal) {
    //   console.log(newVal);
    //   this.fabricColorList = newVal;

    //   if (this.fabricCatgCd != "" && this.fabricColorList.length > 0) {
    //     this.isValidation[0] = true;
    //   } else {
    //     this.isValidation[0] = false;
    //   }
    //   // let fabricColorNo = this.fabricColorList.map(function (item) {
    //   //   return item.fabricColorNo;
    //   // });
    //   // console.log(fabricColorNo);
    // },
  },
  mounted() {
    console.log(this.$route.params.customNo);
    console.log(this.$route.query.styleName);
    console.log(this.$route.query.step);
    this.customNo = this.$route.params.customNo;
    this.styleName = this.$route.query.styleName;
    this.step = Number(this.$route.query.step);

    this.$API.custom.customDetail(this.userId, this.customNo).then((res) => {
      this.apiData = res.data.body;
    });
    this.$API.custom.customFabric().then((res) => {
      console.log(res.data.body);
      const fabric = res.data.body;

      const grouped = {};
      fabric.forEach((item, i) => {
        const groupKey = item.fabricCatgCd;
        if (!grouped[groupKey]) {
          grouped[groupKey] = [];
          this.fabricCatg.push(groupKey);
        }
        // console.log(i);
        // console.log(item);
        // console.log(groupKey);
        // console.log(groupKey);
        grouped[groupKey].push(item);
      });
      this.fabric = grouped;
    });
    // this.apiData =
  },
  methods: {
    closeColor(index) {
      console.log(index);
      this.isFabricColor = false;
      this.fabric[index].filter((e) => e.isActive)[0].isActive = false;
      // this.fabricColorList.forEach((e) => {
      //   if (e.isActive) {
      //     e.isActive = false;
      //     this.$emit("selectFabricColor", e);
      //   }
      // });
      this.fabricColorList = [];
    },
    openColor(index) {
      this.isFabricColor = true;
      this.fabric[index].filter((e) => e.isActive)[0].isActive = true;
    },
    selectColor(catgCd, index) {
      console.log(index);
      console.log(this.fabricColorList);
      console.log(this.fabric);
      console.log(this.fabric[catgCd]);
      console.log(this.fabric[catgCd][index]);
      const fabricNo = this.fabric[catgCd][index].fabricNo;
      this.apiData.fabric = this.fabric[catgCd][index].fabricNo;
      this.fabricCost = this.fabric[catgCd][index].unitCost;
      // this.$emit("selectFabricColor", this.fabricColorList);
      this.fabric[catgCd].forEach((e, i) => {
        if (i == index) {
          this.fabricColorList = e.colorList.filter((e2) => e2.isActive);
        } else {
          e.colorList.map((e2) => (e2.isActive = false));
        }
      });
      (e) => e.isActive;
      console.log(this.fabricColorList);
      this.$emit(
        "openDesc",
        this.fabricColorList,
        this.fabric[catgCd][index].descList,
        this.fabric[catgCd][index].fileList
      );

      if (this.fabricCatgCd != "" && this.fabricColorList.length > 0) {
        this.isValidation[0] = true;
      } else {
        this.isValidation[0] = false;
      }

      const container = this.$refs.customAdd;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth", // 스무스 스크롤 옵션
      });
    },
    toggleColor(catgCd, index, colorIndex) {
      // this.fabricColorList[][index].isActive =
      //   !this.fabricColorList[index].isActive;

      this.fabric[catgCd][index].colorList[colorIndex].isActive =
        !this.fabric[catgCd][index].colorList[colorIndex].isActive;

      // this.$emit(
      //   "selectFabricColor",
      //   this.fabricColorList.filter((e) => e.isActive)
      // );
    },
    toggleFabricTab(datas) {
      this.fabricCatgCd = datas[0].fabricCatgCd;
    },
    toggleFabric(catgCd, j) {
      console.log("=====");
      this.fabricCatgCd = catgCd;
      this.fabricCatg.forEach((e) => {
        this.fabric[e].forEach((e2) => {
          e2.isActive = false;
        });
      });

      console.log(this.fabric[catgCd][j].isActive);
      // this.fabric[catgCd][j].isActive = !this.fabric[catgCd][j].isActive;
      this.fabric[catgCd][j].isActive = !this.fabric[catgCd][j].isActive;
      this.isFabricColor = !this.isFabricColor;
      this.$emit(
        "openDesc",
        "",
        this.fabric[catgCd][j].descList,
        this.fabric[catgCd][j].fileList
      );
      this.$emit("setFabricName", this.fabric[catgCd][j]);
      this.$emit(
        "addUnitPrice",
        Math.ceil(this.fabric[catgCd][j].unitCost / 10) * 10
      );
    },
    getSizeSpec() {
      //성별 + 핏 + 총 기장 + 소매 기장 + 네크라인 + 네크라인 디테일
      this.$API.custom
        .customSizeSepc(
          this.gender.filter((e) => e.isActive)[0].cd,
          this.fit.filter((e) => e.isActive)[0].cd,
          this.totalLength.filter((e) => e.isActive)[0].cd,
          this.sleeveLength.filter((e) => e.isActive)[0].cd,
          this.neckLine.filter((e) => e.isActive)[0]?.cd,
          this.neckDetail.filter((e) => e.isActive)[0]?.cd
        )
        .then((res) => {
          const sizeSpeclist = res.data.body;
          this.size = [
            {
              index: 0,
              isActive: true,
              cd: "01",
              name: "XS",
              spec: sizeSpeclist[0],
            },
            {
              index: 0,
              isActive: true,
              cd: "02",
              name: "S",
              spec: sizeSpeclist[1],
            },
            {
              index: 1,
              isActive: true,
              cd: "03",
              name: "M",
              spec: sizeSpeclist[2],
            },
            {
              index: 2,
              isActive: true,
              cd: "04",
              name: "L",
              spec: sizeSpeclist[3],
            },
            {
              index: 3,
              isActive: true,
              cd: "05",
              name: "XL",
              spec: sizeSpeclist[4],
            },
            {
              index: 4,
              isActive: true,
              cd: "06",
              name: "2XL",
              spec: sizeSpeclist[5],
            },
            {
              index: 4,
              isActive: true,
              cd: "07",
              name: "3XL",
              spec: sizeSpeclist[6],
            },
          ];
        });
      //성별 + 핏 + 총 기장 + 소매 기장 + 원단 폭
      this.$API.custom
        .customMarking(
          this.gender.filter((e) => e.isActive)[0].cd,
          this.fit.filter((e) => e.isActive)[0].cd,
          this.totalLength.filter((e) => e.isActive)[0].cd,
          this.sleeveLength.filter((e) => e.isActive)[0].cd,
          this.fabric[this.fabricCatgCd].filter((e) => e.isActive)[0]
            ?.fabricWidth
        )
        .then((res) => {
          const datas = res.data.body;
          this.marking = datas.marking;
          if (
            this.step == 1 &&
            this.gender.filter((e) => e.isActive).length > 0 &&
            this.fit.filter((e) => e.isActive).length > 0 &&
            this.totalLength.filter((e) => e.isActive).length > 0 &&
            this.sleeveLength.filter((e) => e.isActive).length > 0
          ) {
            this.isSizeDisable = false;
            this.isValidation[1] = true;

            //단가 계산
            this.sumUnitCost();

            const container = this.$refs.customAdd;
            container.scrollTo({
              top: container.scrollHeight,
              behavior: "smooth", // 스무스 스크롤 옵션
            });
          }
        });
    },
    openPrint(type, i) {
      this.printType = type;
      this.printIndex = i;
      this.isPrintPopup = true;

      let printData = {};
      if (
        this.printType == "front" &&
        this.printFrontImgs[this.printIndex].img.fileNo != null
      ) {
        printData = this.printFrontImgs[this.printIndex];
      }
      if (
        this.printType == "back" &&
        this.printBackImgs[this.printIndex].img.fileNo != null
      ) {
        this.printBackImgs[this.printIndex].img;
        printData = this.printBackImgs[this.printIndex];
      }

      console.log(printData);
      if (printData.img?.fileNo != null) {
        this.selectData(
          this.lineType.filter((e) => e.cd == printData.lineType)[0]
        );
        this.longLength = printData.longLength;
        this.printLength[0] = printData.printLength[0];
        if (printData.printLength.length > 1)
          this.printLength[1] = printData.printLength[1];
        this.img = printData.img;
      } else {
        this.initPrint();
      }
      this.checkPrint();
    },
    checkPrint() {
      console.log();
      if (this.printLength.length == 1) {
        if (this.img?.fileNo > 0 && this.longLength && this.printLength[0]) {
          this.isPrint = true;
        } else {
          this.isPrint = false;
        }
      } else if (this.printLength.length == 2) {
        if (this.printLength.length == 2) {
          if (
            this.img?.fileNo > 0 &&
            this.longLength &&
            this.printLength[0] &&
            this.printLength[1]
          ) {
            this.isPrint = true;
          } else {
            this.isPrint = false;
          }
        }
      }
      // if (this.img?.fileNo > 0 && this.longLength && this.printLength[0]) {
      //   if (this.printLength.length == 1) {
      //     this.isPrint = true;
      //   } else if (this.printLength.length == 2 && this.printLength[1]) {
      //     this.isPrint = true;
      //   } else {
      //     this.isPrint = false;
      //   }
      // } else {
      //   this.isPrint = false;
      // }
    },
    addPrint() {
      let printCdoe = "";
      if (this.printType == "front") {
        printCdoe = Number(this.printIndex + 1);
        if (printCdoe < 10) {
          printCdoe = "0" + printCdoe;
        }
      }
      if (this.printType == "back") {
        printCdoe = "1" + Number(this.printIndex + 1);
      }
      console.log(printCdoe);
      const lineType = this.lineType.filter((e) => e.isActive)[0].cd;
      const length1 = this.printLength[0];
      const length2 = this.printLength[1];
      const datas = {
        userId: this.userId,
        customNo: this.customNo,
        fileNo: this.img.fileNo,
        printCode: printCdoe,
        lineType: lineType,
        longLength: this.longLength,
        length1: length1,
        length2: length2,
        jasuFlag: this.agree[0] ? "1" : "0",
        positionFlag: this.agree[1] ? "1" : "0",
      };
      this.printList.push(datas);
      this.$API.custom.printAdd(datas).then((res) => {
        console.log(res.data.body);
        console.log(this.printLength);

        if (this.printType == "front") {
          // this.printIndex;
          this.printFrontImgs[this.printIndex].img = this.img;
          this.printFrontImgs[this.printIndex].lineType = lineType;
          this.printFrontImgs[this.printIndex].longLength = this.longLength;
          this.printFrontImgs[this.printIndex].printLength = this.printLength;
        }
        if (this.printType == "back") {
          this.printBackImgs[this.printIndex].img = this.img;
          this.printBackImgs[this.printIndex].lineType = lineType;
          this.printBackImgs[this.printIndex].longLength = this.longLength;
          this.printBackImgs[this.printIndex].printLength = this.printLength;
        }
        this.initPrint();
        this.sumUnitCost();
        this.isPrintPopup = false;
        this.isPrint = false;
      });
    },
    closePrint() {
      this.initPrint();
      this.isPrintPopup = false;
    },
    initPrint() {
      this.lineType[0].isActive = true;
      this.lineType[1].isActive = false;
      this.longLength = "";
      this.printLength = [];
      this.img = {};
      this.agree = [false, false];
      this.isPrint = false;
    },
    goModify() {
      console.log(this.fabricColorList);
      this.apiData.styleName = this.styleName;
      this.apiData.unitPrice = this.unitPrice;
      this.apiData.fabricNo = this.fabric[this.fabricCatgCd].filter(
        (e) => e.isActive
      )[0]?.fabricNo;
      this.apiData.fabricColorNo = this.fabricColorList.map(function (item) {
        return item.fabricColorNo;
      });
      // this.apiData.fabricColor = this.color.filter(e=>e.isActive)[0].name;
      const neckLine = this.neckLine.filter((e) => e.isActive)[0];
      const neckDetail = this.neckDetail.filter((e) => e.isActive)[0];
      const neckEnd = this.neckEnd.filter((e) => e.isActive)[0];
      const stitch = this.stitch.filter((e) => e.isActive)[0];
      const pocket = this.pocket.filter((e) => e.isActive)[0];
      const sleeve = this.sleeve.filter((e) => e.isActive)[0];
      const sideSlit = this.sideSlit.filter((e) => e.isActive)[0];

      const gender = this.gender.filter((e) => e.isActive)[0];
      const fit = this.fit.filter((e) => e.isActive)[0];
      const totalLength = this.totalLength.filter((e) => e.isActive)[0];
      const sleeveLength = this.sleeveLength.filter((e) => e.isActive)[0];

      this.apiData.neckLine = neckLine?.cd;
      this.apiData.neckDetail = neckDetail?.cd;
      this.apiData.neckEnd = neckEnd?.cd;
      this.apiData.stitch = stitch?.cd;
      this.apiData.pocket = pocket?.cd;
      this.apiData.sleeve = sleeve?.cd;
      this.apiData.sideSlit = sideSlit?.cd;
      this.apiData.gender = gender?.cd;
      this.apiData.fit = fit?.cd;
      this.apiData.totalLength = totalLength?.cd;
      this.apiData.sleeveLength = sleeveLength?.cd;
      this.apiData.sizeList = [];
      this.apiData.colorList = [];

      this.size.forEach((e) => {
        if (e.isActive) {
          this.apiData.sizeList.push(e.cd);
        }
      });
      this.color.forEach((e) => {
        if (e.isActive) {
          this.apiData.colorList.push(e.cd);
        }
      });

      this.$API.custom.customModify(this.apiData).then((res) => {
        console.log(res.data.body);
        this.$emit("goConfirm");
        // this.gotoPage("/custom/order/" + this.customNo);
      });
    },
    toggleSize(index) {
      this.size[index].isActive = !this.size[index].isActive;
    },

    // toggleColor(index, listIndex) {
    //   if (listIndex >= 0) {
    //     // this.color[index].list.forEach((e) => (e.isActive = false));
    //     this.color[index].list[listIndex].isActive =
    //       !this.color[index].list[listIndex].isActive;
    //     if (this.color[index].list[listIndex].isActive) {
    //       //컬러 선택시 추가
    //       let selectColor = {};
    //       selectColor.cd = this.color[index].list[listIndex].cd;
    //       selectColor.name = this.color[index].list[listIndex].name;
    //       this.selectedColor.push(selectColor);
    //     } else {
    //       //컬러 제거시 삭제
    //       this.selectedColor = this.selectedColor.filter(
    //         (e) => e.cd != this.color[index].list[listIndex].cd
    //       );
    //     }
    //   } else {
    //     this.color.forEach((e) => (e.isActive = false));
    //     this.color[index].isActive = true;
    //   }
    // },
    toggleColorHover(index, listIndex) {
      if (listIndex >= 0) {
        this.color[index].list[listIndex].isHover = true;
      } else if (index >= 0) {
        this.color[index].isHover = true;
      } else {
        this.color.forEach((e) => (e.isHover = false));
        this.color.forEach((e) => e.list.forEach((e2) => (e2.isHover = false)));
      }
    },
    toggleTab(index) {
      this.$emit("clickStep", index);
    },
    sumUnitCost() {
      console.log(this.fabricCost);
      const fabricCost = this.fabricCost;
      const neckLine = this.neckLine.filter((e) => e.isActive)[0];
      const neckDetail = this.neckDetail.filter((e) => e.isActive)[0];
      const neckEnd = this.neckEnd.filter((e) => e.isActive)[0];
      const stitch = this.stitch.filter((e) => e.isActive)[0];
      const pocket = this.pocket.filter((e) => e.isActive)[0];
      const sleeve = this.sleeve.filter((e) => e.isActive)[0];
      const sideSlit = this.sideSlit.filter((e) => e.isActive)[0];
      console.log(this.isSizeDisable);
      let unitCost = Number(fabricCost);
      if (neckLine) unitCost += neckLine.addAmount;
      if (neckDetail)
        unitCost +=
          neckDetail.addAmount > 0
            ? neckDetail.addAmount
            : fabricCost * neckDetail.addRate;
      if (neckEnd) unitCost += neckEnd.addRate == 0 ? fabricCost * neckEnd : 0;
      if (stitch) unitCost += stitch.addAmount > 0 ? stitch.addAmount : 0;
      if (pocket) unitCost += pocket.addAmount > 0 ? pocket.addAmount : 0;
      if (sleeve)
        unitCost +=
          sleeve.addAmount == 0
            ? fabricCost * sleeve.addRate
            : sleeve.addAmount > 0
            ? sleeve.addAmount
            : 0;
      if (sideSlit) unitCost += sideSlit.addAmount > 0 ? sideSlit.addAmount : 0;
      if (this.isValidation[2]) {
        unitCost += 3500;
        //원단 금액을 요척을 곱하여 계산한 값
        unitCost += this.fabricCost * (this.marking - 1);
      }
      if (this.printList.length > 0) {
        console.log(unitCost);
        this.printList.forEach((e) => {
          unitCost += 2000 + e.longLength * e.longLength * 6;
        });
        console.log(unitCost);
      }
      this.unitPrice = Math.ceil(unitCost / 10) * 10;
      this.$emit("addUnitPrice", this.comma(this.unitPrice));
    },
    toggleComm(type, index) {
      console.log(this[type]);
      this[type].forEach((e) => (e.isActive = false));
      this[type][index].isActive = true;
      console.log(this[type]);
      this.apiData[type] = this[type][index].cd;

      if (
        this.step == 1 &&
        this.gender.filter((e) => e.isActive).length > 0 &&
        this.fit.filter((e) => e.isActive).length > 0 &&
        this.totalLength.filter((e) => e.isActive).length > 0 &&
        this.sleeveLength.filter((e) => e.isActive).length > 0
      ) {
        if (this.isSizeConfirm) {
          this.size = [
            { index: 0, isActive: true, cd: "01", name: "XS" },
            { index: 0, isActive: true, cd: "02", name: "S" },
            { index: 1, isActive: true, cd: "03", name: "M" },
            { index: 2, isActive: true, cd: "04", name: "L" },
            { index: 3, isActive: true, cd: "05", name: "XL" },
            { index: 4, isActive: true, cd: "06", name: "2XL" },
            { index: 4, isActive: true, cd: "07", name: "3XL" },
          ];
        }
        this.isSizeConfirm = true;
      }
      if (
        this.step == 2 &&
        this.neckLine.filter((e) => e.isActive).length > 0 &&
        this.neckDetail.filter((e) => e.isActive).length > 0 &&
        this.neckEnd.filter((e) => e.isActive).length > 0 &&
        this.stitch.filter((e) => e.isActive).length > 0 &&
        this.pocket.filter((e) => e.isActive).length > 0 &&
        this.sleeve.filter((e) => e.isActive).length > 0 &&
        this.sideSlit.filter((e) => e.isActive).length > 0
      ) {
        this.isValidation[2] = true;
      }
      this.sumUnitCost();
    },
    handleDrop(event) {
      console.log(1);
      // if (!(this.d.profileFileNo > 0)) {
      //   const files = event.dataTransfer.files;
      //   this.fileUpload('34', 12, event)
      // }
      this.fileUpload("81", 1, event);
    },
    fileUpload(fileStatus, maxLength, event) {
      // let files = [];
      // if (event.target.files[0]) {
      //   const reader = new FileReader();
      //   reader.onload = (e) => {
      //     // AI 파일을 이미지로 변환
      //     const img = new Image();
      //     img.src = event.target.files[0];
      //     img.onload = () => {
      //       // 이미지를 Canvas에 그려서 데이터 URI로 변환
      //       const canvas = document.createElement("canvas");
      //       canvas.width = img.width;
      //       canvas.height = img.height;
      //       const ctx = canvas.getContext("2d");
      //       ctx.drawImage(img, 0, 0);
      //       const dataURI = canvas.toDataURL("image/png");
      //       // 변환된 이미지를 Vue 데이터에 설정
      //       const image = dataURI;
      //       console.log(image);
      //       files = [];
      //       files.push(image);
      //     };
      //   };
      //   files.push(event.target.files[0]);
      //   reader.readAsDataURL(event.target.files[0]);
      // } else {
      //   console.error("No file selected.");
      // }
      console.log(fileStatus);
      console.log(maxLength);
      console.log(event);
      this.errorData.imgList = false;
      let files = [];
      let c = maxLength - this.imgList.length;

      let msg = "";

      let fileList;
      if (event.dataTransfer?.files.length > 0) {
        fileList = event.dataTransfer.files;
      } else if (event.target) {
        fileList = event.target.files;
      }
      Array.from(fileList).forEach((e) => {
        if (
          (e.name.indexOf(".ai") == -1 && e.size >= 1024000 * 10) ||
          (e.name.indexOf(".ai") != -1 && e.size >= 1024000 * 100)
        ) {
          // 파일 용량 제한
          msg = "파일 용량이 초과되었습니다.";
          if (msg) this.showToast(msg);
          return;
        }
        files.push(e);
        c = c - 1;
      });

      this.$API.comm
        .fileUploadMultiple(
          sessionStorage.getItem("userId"),
          this.customNo,
          fileStatus,
          "8",
          files
        )
        .then((res) => {
          res.data.body.forEach((e, i) => {
            let d = e["imgFile" + (i + 1)]; // api 수정시 변경
            this.imgList.push({
              fileNo: d.fileNo,
              fileSize: d.fileSize,
              fileStatus: d.fileStatus,
              fileType: d.fileType,
              fileUrl: d.previewUrl,
              sourceFileName: d.sourceFileName,
            });
            this.img = {
              fileNo: d.fileNo,
              fileSize: d.fileSize,
              fileStatus: d.fileStatus,
              fileType: d.fileType,
              fileUrl: d.previewUrl,
              sourceFileName: d.sourceFileName,
            };
            this.checkPrint();
          });
        });
    },
    removeImg(fileNo) {
      this.imgList = this.imgList.filter((e) => e.fileNo != fileNo);
      this.img = {};
      // input element에 접근
      const imgFile = this.$refs.imgFile;
      // 초기화
      imgFile.value = "";
      this.checkPrint();
    },
    selectData(data, type) {
      this.lineType.forEach((e) => {
        e.isActive = false;
        if (e.cd == data.cd) e.isActive = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/custom.scss";
</style>
