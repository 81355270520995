<template>
  <div class="basket">
    <div
      class="basket-top"
      v-if="basketItem && basketItem.length > 0 && isMobile == 'false'"
    >
      <div class="title"><p>상품 내역</p></div>
      <div class="head">
        <div class="check-all">
          <label class="checkbox-normal">
            <input
              type="checkbox"
              v-model="isChkAll"
              @click="checkAll()"
            /><span class="checkmark" :class="{ on: isChkAll }"></span>전체 선택
          </label>
        </div>
        <p class="head-info">상품 정보</p>
        <p class="head-price">상품 금액</p>
        <p class="head-delivery">배송비</p>
      </div>
      <div v-for="(x, i) in basketItem" :key="i">
        <div class="check-content">
          <div class="check-content-body">
            <div class="check-content-body-list">
              <div class="list">
                <div class="list-wrap" v-for="(y, j) in x.item" :key="j">
                  <div class="item-name">
                    <label class="checkbox-normal">
                      <input
                        type="checkbox"
                        v-model="isChkItem[i][j]"
                        @click="totalSum(i, j)"
                      /><span
                        class="checkmark"
                        :class="{ on: isChkItem[i][j] }"
                      ></span>
                    </label>
                    <img
                      class="img"
                      :src="y?.detailData?.itemImgList[0].fileUrl"
                    />
                    <div class="name">
                      <p>
                        {{ x.itemName }}
                      </p>
                      <p class="option-name">
                        <span class="material-symbols-outlined"> check </span
                        >{{ y.itemOptionName }}
                      </p>
                    </div>
                  </div>
                  <div class="check-updown">
                    <div class="updown br-4 width-110px">
                      <div class="updown-btn gray" @click="downItem(i, j)">
                        <img src="@/assets/icon/ic-minus-white.svg" />
                      </div>
                      <div class="updown-num">
                        <input
                          type="number"
                          v-model="y.count"
                          @keyup="countItem(i, j, y.count)"
                          @focusout="numFocusout(i, j, y.count)"
                        />
                      </div>
                      <div class="updown-btn gray" @click="upItem(i, j)">
                        <img src="@/assets/icon/ic-plus-white.svg" />
                      </div>
                    </div>
                    <p class="pointer">
                      <span
                        class="material-symbols-outlined"
                        @click="removeItem(i, j)"
                      >
                        close
                      </span>
                    </p>
                  </div>
                  <div class="check-price">
                    <p class="price">{{ rowSum(y) }} 원</p>
                  </div>
                </div>
              </div>

              <div class="check-delivery">
                <p class="price">
                  <span v-if="x.deliveryPrice > 0">
                    {{ comma(x.deliveryPrice) }} 원</span
                  >
                  <span v-else> 무료 배송</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-info" v-if="sumPrice > 0">
        <div class="row">
          <p class="row-name">상품가격</p>
          <p class="row-content">{{ comma(sumPrice) }}원</p>
        </div>
        <p class="circle">
          <span class="material-symbols-outlined"> add </span>
        </p>
        <div class="row">
          <p class="row-name">배송비</p>
          <p class="row-content">{{ comma(deliveryPrice) }}원</p>
        </div>
        <p class="circle">
          <span class="material-symbols-outlined"> equal </span>
        </p>
        <div class="row">
          <p class="row-name">주문금액</p>
          <p class="row-content yellow">{{ comma(totalPrice) }}원</p>
        </div>
      </div>
    </div>
    <div
      class="basket-mobile"
      v-else-if="basketItem && basketItem.length > 0 && isMobile == 'true'"
    >
      <div class="check-all">
        <label class="checkbox-normal">
          <input type="checkbox" v-model="isChkAll" @click="checkAll()" /><span
            class="checkmark"
            :class="{ on: isChkAll }"
          ></span
          >전체 선택
        </label>
      </div>
      <div class="basket-mobile-wrap">
        <div v-for="(x, i) in basketItem" :key="i">
          <div class="check-content">
            <div class="check-content-body">
              <div class="check-content-body-list">
                <div class="list-wrap" v-for="(y, j) in x.item" :key="j">
                  <div class="item-name">
                    <label class="checkbox-normal">
                      <input
                        type="checkbox"
                        v-model="isChkItem[i][j]"
                        @click="totalSum(i, j)"
                      /><span
                        class="checkmark"
                        :class="{ on: isChkItem[i][j] }"
                      ></span>
                    </label>
                    <!-- <img
                    class="img"
                    :src="x.item[i]?.detailData?.itemImgList[0].fileUrl"
                  /> -->
                    <img
                      class="img"
                      :src="y?.detailData?.itemImgList[0].fileUrl"
                    />
                    <div class="name">
                      <p>
                        {{ x.itemName }}
                      </p>
                      <p class="option-name">
                        <span class="material-symbols-outlined"> check </span
                        >{{ y.itemOptionName }}
                      </p>
                    </div>
                  </div>
                  <div class="item-count">
                    <div class="check-updown">
                      <div class="updown br-4 width-120px">
                        <div class="updown-btn gray" @click="downItem(i, j)">
                          <img src="@/assets/icon/ic-minus-white.svg" />
                        </div>
                        <div class="updown-num">
                          <input
                            type="number"
                            v-model="y.count"
                            @keyup="countItem(i, j, y.count)"
                            @focusout="numFocusout(i, j, y.count)"
                          />
                        </div>
                        <div class="updown-btn gray" @click="upItem(i, j)">
                          <img src="@/assets/icon/ic-plus-white.svg" />
                        </div>
                      </div>
                      <p class="pointer">
                        <span
                          class="material-symbols-outlined"
                          @click="removeBasket(i)"
                        >
                          close
                        </span>
                      </p>
                    </div>
                    <div class="check-price">
                      <p class="price">{{ rowSum(y) }} 원</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="check-content-bottom">
              <p>배송비</p>
              <p>{{ comma(x.deliveryPrice) }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="item-info" v-if="sumPrice > 0">
        <div class="row">
          <p class="row-name">상품가격</p>
          <p class="row-content">{{ comma(sumPrice) }}원</p>
        </div>
        <div class="row">
          <p class="row-name">배송비</p>
          <p class="row-content">{{ comma(deliveryPrice) }}원</p>
        </div>
        <div class="line-a10"></div>
        <div class="row">
          <p class="row-name">주문금액</p>
          <p class="row-content yellow">{{ comma(totalPrice) }}원</p>
        </div>
      </div>
    </div>
    <div v-else class="basket-empty">
      <p>장바구니에 담은 상품이 없습니다.</p>
    </div>
    <div class="basket-bottom" v-if="basketItem && basketItem.length > 0">
      <div class="basket-bottom-btn" @click="gotoPage('/mall/materiallist')">
        계속 쇼핑하기
      </div>
      <div class="basket-bottom-btn" @click="goBasket()">장바구니 구매</div>
    </div>
    <CompMaterialItemSwiper
      :title="'다른 상품 보러 가기'"
      :mallList="mallList"
    ></CompMaterialItemSwiper>
  </div>
</template>
<script>
import { loadTossPayments } from "@tosspayments/payment-sdk";
export default {
  name: "CompDesignmaterialBasket",
  props: {
    itemLength: {
      type: Object,
    },
  },
  data() {
    return {
      d: {},
      i: [],
      mallNo: "",
      regImgUrl: "",
      regImgName: "",
      detailData: {},
      addItem: [],
      sumPrice: 0,
      sumCount: 0,
      deliveryPrice: 0,
      totalPrice: 0,
      paymentType: "",
      userInfo: {},
      itemDetailInfo: [],
      paymentFlag: 0,
      isChkAll: false,
      isChkItem: [],
      isChkItemArr: [],
      mallList: [],
      color: [
        {
          index: 0,
          cd: "",
          name: "[필수] 1절 이상 주문 가능합니다.",
          isActive: true,
        },
      ],
      basketItem: [],
      get getBasket() {
        return sessionStorage.getItem("basketItem");
      },
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  watch: {
    itemLength(newVal) {
      console.log(newVal);
      this.basketItem = JSON.parse(this.getBasket);
      this.addItem = this.basketItem;
    },
  },
  mounted() {
    console.log(this.getBasket);
    this.basketItem = JSON.parse(this.getBasket);
    this.addItem = this.basketItem;
    console.log("mounted");
    console.log(this.basketItem);
    console.log(this.addItem);
    if (this.addItem) {
      //체크박스 초기화
      for (let i = 0; i < this.addItem?.length; i++) {
        // this.isChkItem[i] = {false, false};
        console.log("addItem:" + this.addItem[i].item);

        let isChkItem = [];
        for (let j = 0; j < this.addItem[i].item?.length; j++) {
          isChkItem.push(false);
        }
        console.log(isChkItem);
        this.isChkItem[i] = isChkItem;
        console.log("this.isChkItem:" + this.isChkItem[i]);
      }
      console.log("this.isChkItem:" + this.isChkItem);
    }

    if (this.compType == "1") {
      this.$API.mypage.getDesignerInfo(this.userId).then((res) => {
        console.log(res.data.body);
        this.userInfo = res.data.body;
      });
    }
    if (this.compType == "2") {
      this.$API.mypage.getFactoryInfo(this.userId).then((res) => {
        this.userInfo = res.data.body;
      });
    }

    console.log(this.basketItem);
    console.log(this.addItem);
    let datas = {};
    if (this.addItem != null) {
      const tabCatgList = this.addItem.map((e) => e.tabCatg);
      console.log(tabCatgList);
      datas = { tabCatgList: tabCatgList };
    }

    this.$API.mall.getMaterialList(datas).then((res) => {
      this.mallList = res.data.body;
    });
    this.checkAll();
  },
  methods: {
    checkAll() {
      this.isChkAll = !this.isChkAll;
      this.addItem = this.basketItem;
      for (let i = 0; i < this.addItem.length; i++) {
        let isChkItem = [];
        for (let j = 0; j < this.addItem[i].item?.length; j++) {
          console.log(this.isChkAll);
          if (this.isChkAll) {
            isChkItem.push(true);
          } else {
            isChkItem.push(false);
          }
          this.totalSum(i, j);
        }
        this.isChkItem[i] = isChkItem;
        console.log(this.isChkItem[i]);
      }
      console.log(this.isChkItem);
    },
    getAddress() {
      new window.daum.Postcode({
        oncomplete: (res) => {
          this.userInfo.compAddress = res.roadAddress;
        },
      }).open();
    },
    numFocusout(index, itemIndex, count) {
      console.log(count);
      if (count < this.addItem[index].item[itemIndex].minCount) {
        // this.removeItem(index, itemIndex);
      }
    },
    countItem(index, itemIndex, count) {
      console.log(this.addItem[index].item[itemIndex].minCount);
      console.log(count);
      console.log(count >= this.addItem[index].item[itemIndex].minCount);
      this.addItem = this.basketItem;
      if (count >= this.addItem[index].item[itemIndex].minCount) {
        this.addItem[index].item[itemIndex].count = count;
        if (
          this.addItem[index].item[itemIndex].detailData.unitPrice?.length > 0
        ) {
          let itemUnitCost = 0;
          for (const unitPrice of this.addItem[index].item[itemIndex].detailData
            .unitPrice) {
            if (unitPrice.unit <= this.addItem[index].item[itemIndex].count) {
              itemUnitCost = unitPrice.price;
              this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
              break;
            } else {
              itemUnitCost =
                this.addItem[index].item[itemIndex].detailData.itemUnitCost;
            }
          }
          this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
        }

        this.sum();
        this.checkSum(index, itemIndex);
        // this.$emit("updateBasket", this.addItem);
        sessionStorage.setItem("basketItem", JSON.stringify(this.addItem));
      } else {
        this.addItem[index].item[itemIndex].count =
          this.addItem[index].item[itemIndex].minCount;
      }
    },
    upItem(index, itemIndex) {
      console.log(this.basketItem);
      this.addItem = this.basketItem;
      this.addItem[index].item[itemIndex].count++;

      if (
        this.addItem[index].item[itemIndex].detailData.unitPrice?.length > 0
      ) {
        let itemUnitCost = 0;
        for (const unitPrice of this.addItem[index].item[itemIndex].detailData
          .unitPrice) {
          if (unitPrice.unit <= this.addItem[index].item[itemIndex].count) {
            itemUnitCost = unitPrice.price;
            this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
            break;
          } else {
            itemUnitCost =
              this.addItem[index].item[itemIndex].detailData.itemUnitCost;
          }
        }
        this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
      }

      this.sum();
      this.checkSum(index, itemIndex);
      // this.$emit("updateBasket", this.addItem);
      sessionStorage.setItem("basketItem", JSON.stringify(this.addItem));
    },
    downItem(index, itemIndex) {
      console.log(this.basketItem);
      this.addItem = this.basketItem;

      if (
        this.addItem[index].item[itemIndex].count <=
        this.addItem[index].item[itemIndex].minCount
      ) {
        // this.removeItem(index, itemIndex);
      } else {
        this.addItem[index].item[itemIndex].count--;
      }

      if (
        this.addItem[index].item[itemIndex].detailData.unitPrice?.length > 0
      ) {
        let itemUnitCost = 0;
        for (const unitPrice of this.addItem[index].item[itemIndex].detailData
          .unitPrice) {
          if (unitPrice.unit <= this.addItem[index].item[itemIndex].count) {
            itemUnitCost = unitPrice.price;
            this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
            break;
          } else {
            itemUnitCost =
              this.addItem[index].item[itemIndex].detailData.itemUnitCost;
          }
        }
        this.addItem[index].item[itemIndex].itemUnitCost = itemUnitCost;
      }

      this.sum();
      this.checkSum(index, itemIndex);
      // this.$emit("updateBasket", this.addItem);
      sessionStorage.setItem("basketItem", JSON.stringify(this.addItem));
    },
    removeItem(index, itemIndex) {
      this.addItem = this.basketItem;
      this.addItem[index].item.splice(itemIndex, 1);
      this.sum();
      // this.$emit("updateBasket", this.addItem);
      sessionStorage.setItem("basketItem", JSON.stringify(this.addItem));

      //아이템 수량이 0인 경우 장바구니에서 제거
      let count = 0;
      this.addItem[index].item.forEach((e) => {
        count += e.count;
      });
      if (count == 0) {
        this.removeBasket(index);
      }
    },
    removeBasket(index) {
      this.addItem = this.basketItem;
      this.addItem.splice(index, 1);
      this.sum();
      // this.$emit("updateBasket", this.addItem);
      sessionStorage.setItem("basketItem", JSON.stringify(this.addItem));
    },
    sum() {
      let sumPrice = 0;
      this.addItem.forEach((e) => {
        console.log(e);
        sumPrice += e.count * (Number(e.itemUnitCost) + Number(e.addUnitCost));
      });
      this.sumPrice = sumPrice;
      this.totalPrice = sumPrice + this.deliveryPrice;
    },
    rowSum(rowData) {
      console.log(rowData);
      let rowSum = 0;
      // rowData.forEach((e) => {
      //   rowSum += e.count * (e.itemUnitCost * 1 + e.addUnitCost * 1);
      // });
      rowSum +=
        rowData.count * (rowData.itemUnitCost * 1 + rowData.addUnitCost * 1);
      return this.comma(rowSum);
    },
    totalSum(i, j) {
      this.isChkItem[i][j] = !this.isChkItem[i][j];
      // if (!this.isChkItem[index]) {
      //   this.isChkAll = false;
      // }
      this.checkSum(i, j);
    },
    checkSum(i, j) {
      let sumPrice = 0;
      let deliveryPrice = 0;
      this.addItem.forEach((e, i) => {
        e.item.forEach((ei, j) => {
          if (this.isChkItem[i][j]) {
            sumPrice +=
              ei.count * (Number(ei.itemUnitCost) + Number(ei.addUnitCost));
          }
        });
        deliveryPrice += Number(e.deliveryPrice);
      });
      this.sumPrice = sumPrice;
      this.deliveryPrice = deliveryPrice;
      this.totalPrice = sumPrice + deliveryPrice;
    },
    goBasket() {
      this.$store.state.meta.mall.materialBuyItem = [];
      let materialBuyItem = [];
      this.isChkItem.forEach((e, i) => {
        console.log(e);
        let basketItem = {};
        console.log(e.filter((ef) => ef).length);
        if (e.filter((ef) => ef).length > 0) {
          let item = [];
          e.forEach((e2, j) => {
            if (e2) {
              item.push(this.basketItem[i].item[j]);
              console.log(this.basketItem);
            }
          });
          // basketItem.push({
          //   deliveryPrice: this.basketItem[i].deliveryPrice,
          //   itemName: this.basketItem[i].itemName,
          //   item: item,
          //   mallNo: this.basketItem[i].mallNo,
          //   tabCatg: this.basketItem[i].tabCatg,
          // });
          basketItem = {
            deliveryPrice: this.basketItem[i].deliveryPrice,
            itemName: this.basketItem[i].itemName,
            item: item,
            mallNo: this.basketItem[i].mallNo,
            tabCatg: this.basketItem[i].tabCatg,
          };
        }
        if (Object.entries(basketItem).length > 0) {
          materialBuyItem.push(basketItem);
        }

        // e.item.forEach((ei, j) => {
        //   if (ei) {
        //     this.$store.state.meta.mall.materialBuyItem.push(
        //       this.basketItem[i][j]
        //     );
        //   }
        // });
      });
      this.$store.state.meta.mall.materialBuyItem = materialBuyItem;
      console.log(this.$store.state.meta.mall.materialBuyItem.length);
      console.log(this.$store.state.meta.mall.materialBuyItem);
      localStorage.setItem(
        "buyItem",
        JSON.stringify(this.$store.state.meta.mall.materialBuyItem)
      );
      if (this.$store.state.meta.mall.materialBuyItem.length > 0) {
        this.gotoPage("/mall/materialbuy");
      } else {
        this.showToast("구매하실 상품을 선택해주세요.");
      }
    },
    payment() {
      console.log(this.paymentType);
      if (this.totalPrice > 0) {
        let itemDetail = "";
        let itemDetailInfo = [];
        this.addItem.forEach((e, i) => {
          if (i > 0) itemDetail += " / ";
          itemDetail += e.itemOptionName + ":" + e.count + "개";

          let itemInfo = {
            itemOptionName: e.itemOptionName,
            itemColorCode: e.cd,
            quantity: e.count,
            addUnitCost: e.addUnitCost,
            paymentAmount:
              Number(this.detailData.itemUnitCost) + Number(e.addUnitCost),
          };
          itemDetailInfo.push(itemInfo);
        });
        this.itemDetailInfo = itemDetailInfo;
        ``;
        this.$API.payment
          .createTossMall(
            this.userId,
            this.mallNo,
            this.detailData.itemName,
            this.totalPrice,
            this.paymentType,
            itemDetail,
            this.userInfo.userName,
            this.userInfo.telNum,
            this.userInfo.compAddress,
            this.userInfo.compAddressDetail,
            this.userInfo.deliveryMemo,
            this.itemDetailInfo
          )
          .then((res) => {
            console.log(res);
            if (res.data.resultCode == "10000") {
              let d = res.data.body;
              console.log(d);
              loadTossPayments(d.clientKey).then((tossPayments) => {
                tossPayments.requestPayment(this.paymentType, {
                  // 결제 수단 파라미터
                  amount: d.paymentAmount,
                  orderId: d.orderId,
                  orderName: d.itemName,
                  customerName: sessionStorage.getItem("userName"),
                  successUrl: d.successUrl,
                  failUrl: d.failUrl,
                });
              });
            } else {
              this.setMsgPopup(
                true,
                "서버 접속이 원활하지 않습니다.<br>잠시 후 다시 시도해주세요."
              );
            }
          });
      } else {
        this.showToast("구매하실 상품을 선택해주세요.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.basket {
  width: 100%;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .square {
      width: 8px;
      height: 8px;
      background-color: $primary-50;
    }
    .title {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: $primary-80;
    }
  }
  &-top {
    padding: 24px;
    background-color: $neutral-0;
    border-radius: 8px;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    .title {
      padding: 2px 0;
      display: flex;
      p {
        height: 26px;
        border-bottom: 1px solid $primary-50;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .head {
      margin-top: 28px;
      display: flex;
      border-top: 1px solid $neutral-10;
      border-bottom: 1px solid $neutral-10;
      padding: 20px 0 20px;
      font-size: 15px;
      font-weight: 500;
      line-height: 140%;
      text-align: center;
      .check-all {
        margin-left: 20px;
        display: flex;
        gap: 8px;
        color: $neutral-60;
        width: 87px;
      }
      &-info {
        margin-left: 176px;
        display: flex;
        padding: 0 12px;
        width: 83px;
      }
      &-price {
        margin-left: 264px;
        display: flex;
        padding: 0 12px;
        width: 83px;
      }
      &-delivery {
        margin-left: 46px;
        margin-right: 27px;
        display: flex;
        padding: 0 12px;
        width: 66px;
      }
    }

    .check-content {
      padding: 40px 0;
      border-bottom: 1px solid $neutral-10;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &-body {
        display: flex;
        flex-direction: column;
        gap: 40px;
        &-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .list {
            display: flex;
            flex-direction: column;
            gap: 40px;
            &-wrap {
              display: flex;
              align-items: center;
              .item-name {
                display: flex;
                align-items: center;
                gap: 20px;
                width: 426px;
                font-size: 14px;
                font-weight: 500;
                line-height: 140%;
                .img {
                  width: 60px;
                  height: 60px;
                  border-radius: 4px;
                }
                .name {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 140%; /* 19.6px */
                  .option-name {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                  }
                }
                p {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                }
                span {
                  cursor: pointer;
                  font-size: 16px;
                  color: $neutral-30;
                }
              }
              .check-updown {
                display: flex;
                align-items: center;
                gap: 40px;
                width: 186px;
                height: 60px;
                border-right: 1px solid $neutral-10;
                span {
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  color: $neutral-30;
                }
              }
              .check-price {
                margin: 0 24px;
                display: flex;
                justify-content: center;
                width: 72px;
              }
            }
          }
          .check-delivery {
            margin: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 79px;
          }
        }
      }
    }
    .item-info {
      padding: 40px 0 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      width: 100%;
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $neutral-30;
        color: $neutral-0;

        span {
          font-size: 16px;
        }
      }
      .row {
        display: flex;
        align-items: center;
        gap: 8px;
        &-name {
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
        }
        &-content {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          &.yellow {
            color: $primary-60;
          }
        }
      }
    }
    .no-item {
      padding: 40px 0 0;
      color: $neutral-60;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  &-mobile {
    padding-top: 20px;

    .check-all {
      padding: 20px;
      display: flex;
      gap: 8px;
      color: $neutral-60;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .check-content {
        padding: 20px;
        margin: 0 20px;
        border-radius: 8px;
        background: var(--Neutral_00, #fff);
        /* Basic_4480_4 */
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
        &-body {
          padding: 0 0 20px;
          border-bottom: 1px solid $neutral-10;
          &-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
            width: 100%;
            .list-wrap {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 20px;
              width: 100%;
              .item-name {
                display: flex;
                align-items: center;
                gap: 12px;
                font-size: 14px;
                font-weight: 500;
                line-height: 140%;
                width: 100%;
                .img {
                  width: 60px;
                  height: 60px;
                  border-radius: 4px;
                }
                .name {
                  margin-left: 4px;
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 140%; /* 19.6px */
                  .option-name {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 140%; /* 19.6px */
                  }
                }
                p {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                }
                span {
                  cursor: pointer;
                  font-size: 16px;
                  color: $neutral-30;
                }
              }
              .item-count {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                width: 100%;
                padding-left: 28px;
                .check-updown {
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  span {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: $neutral-30;
                  }
                }
                .check-price {
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
        &-bottom {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
        }
      }
    }

    .item-info {
      padding: 40px 40px 0;
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $neutral-30;
        color: $neutral-0;

        span {
          font-size: 16px;
        }
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        &-name {
          font-size: 15px;
          font-weight: 400;
          line-height: 140%;
        }
        &-content {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          &.yellow {
            color: $primary-60;
          }
        }
      }
    }
    .no-item {
      padding: 40px 0 0;
      color: $neutral-60;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
    }
  }
  &-body {
    .delivery-info {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 28px;
      .colomn {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &-name {
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          span {
            color: $primary-60;
          }
        }
        &-input {
          padding: 6px 12px;
          border-radius: 4px;
          border-bottom: 1px solid $neutral-20;
          border-left: 1px solid $neutral-20;
        }
      }
    }
  }
  &-bottom {
    margin: 40px 20px;
    display: flex;
    justify-content: center;
    &-btn {
      width: 180px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $neutral-0;
      font-size: 15px;
      font-weight: 500;
      line-height: 140%;
      height: 40px;
      background-color: $primary-50;
      &:first-child {
        background-color: $neutral-60;
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 212px;
    border-bottom: 1px solid $neutral-10;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    @media (min-width: 801px) {
      margin-top: 40px;
      height: 422px;
      border-top: 1px solid $neutral-10;
    }
  }
}
</style>
