<template>
  [{{ position }}]
  <div class="image-editor">
    <input type="file" class="hidden" @change="onFileChange" />
    <div
      v-if="imageUrl"
      class="image-container"
      :style="containerStyle"
      @mousedown="onMouseDown($event, 'move')"
    >
      <div
        class="resize-handle"
        @mousedown.stop="onMouseDown($event, 'resize')"
      ></div>
      <div class="position">
        <p>[{{ size }}]</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageEditor",
  props: {
    files: {
      type: Object,
      description: "file data",
    },
  },
  data() {
    return {
      imageUrl: null,
      position: { x: 0, y: 0 },
      size: { width: 200, height: 200 },
      aspectRatio: 1,
      dragging: false,
      resizing: false,
      dragStart: { x: 0, y: 0 },
      resizeStart: { width: 0, height: 0, x: 0, y: 0 },
    };
  },
  computed: {
    containerStyle() {
      return {
        backgroundImage: `url(${this.imageUrl})`,
        width: `${this.size.width}px`,
        height: `${this.size.height}px`,
        top: `${this.position.y}px`,
        left: `${this.position.x}px`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "absolute",
      };
    },
  },
  watch: {
    files(data) {
      console.log(data);
      console.log(this.files);
      const files = this.files;
      if (files.length > 0) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageUrl = event.target.result;
          // 이미지가 로드된 후 원본 비율 계산
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            this.aspectRatio = img.width / img.height;
            this.size.width = 200;
            this.size.height = 200 / this.aspectRatio;
          };
        };
        reader.readAsDataURL(files[0]);
      }
    },
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files;
      if (files.length > 0) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageUrl = event.target.result;
          // 이미지가 로드된 후 원본 비율 계산
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            this.aspectRatio = img.width / img.height;
            this.size.width = 200;
            this.size.height = 200 / this.aspectRatio;
          };
        };
        reader.readAsDataURL(files[0]);
      }
    },
    onMouseDown(e, action) {
      e.preventDefault();
      if (action === "move") {
        this.dragging = true;
        this.dragStart.x = e.clientX - this.position.x;
        this.dragStart.y = e.clientY - this.position.y;
      } else if (action === "resize") {
        this.resizing = true;
        this.resizeStart.width = this.size.width;
        this.resizeStart.height = this.size.height;
        this.resizeStart.x = e.clientX;
        this.resizeStart.y = e.clientY;
      }
      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.onMouseUp);
    },
    onMouseMove(e) {
      if (this.dragging) {
        this.position.x = e.clientX - this.dragStart.x;
        this.position.y = e.clientY - this.dragStart.y;
      } else if (this.resizing) {
        const dx = e.clientX - this.resizeStart.x;
        const newWidth = this.resizeStart.width + dx;
        const newHeight = newWidth / this.aspectRatio;

        // 최소 크기 제한
        if (newWidth > 20 && newHeight > 20) {
          this.size.width = newWidth;
          this.size.height = newHeight;
        }
      }
      this.$emit("imgSizePosition", this.size, this.position);
    },
    onMouseUp() {
      this.dragging = false;
      this.resizing = false;
      document.removeEventListener("mousemove", this.onMouseMove);
      document.removeEventListener("mouseup", this.onMouseUp);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-editor {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid $neutral-40;
  background-image: url("@/assets/img/print-front-img.png");
  background-repeat: no-repeat;
}
.image-container {
  position: absolute;
  background-size: cover;
  background-position: center;
  cursor: grab;
  border: 1px solid #ccc;
}
.resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: #000;
  cursor: nwse-resize;
}
.position {
  position: absolute;
  bottom: -50px;
  right: -20px;
}
</style>
