<template>
  <div
    class="material"
    id="materialCard"
    :class="isMobile == 'true' ? 'mb' : ''"
  >
    <!-- @click="goDetail(`${x.mallNo}`, `${x.itemName}`)" -->

    <div
      class="card"
      :class="isMobile == 'true' ? 'mb' : ''"
      v-for="(orderList, i) in listData"
      :key="i"
    >
      <div class="card-top">
        <p class="date">주문일자 : {{ orderList.createdDate }}</p>
        <div
          class="move-detail"
          v-if="$route.path.indexOf('buydetail') == -1"
          @click="goDetail(orderList.orderNum, orderList.itemName)"
        >
          <p>주문 상세보기</p>
          <img src="@/assets/icon/ic-chevron-right-60.svg" />
        </div>
      </div>
      <div class="card-body" v-for="(x, j) in orderList.buyList" :key="j">
        <div class="card-body-info">
          <div v-for="(y, k) in x.paymentDtl" :key="k">
            <div
              class="item"
              :class="isMobile == 'true' ? 'flex-column pr-0 pl-0' : ''"
            >
              <div class="wrap">
                <div class="content">
                  <div class="state" v-if="y.paymentDtlStatus == '1'">
                    <img src="@/assets/icon/ic-mall-payments-yellow.svg" />
                    <p>미결제</p>
                  </div>
                  <div class="state" v-if="y.paymentDtlStatus == '2'">
                    <img src="@/assets/icon/ic-mall-payments-yellow.svg" />
                    <p>결제 완료</p>
                  </div>
                  <div class="state cancel" v-if="y.paymentDtlStatus == '3'">
                    <img src="@/assets/icon/ic-check-gray.svg" />
                    <p>교환/반품 신청</p>
                  </div>
                  <div class="state cancel" v-if="y.paymentDtlStatus == '4'">
                    <img src="@/assets/icon/ic-check-gray.svg" />
                    <p>교환/반품 완료</p>
                  </div>
                  <div class="state" v-if="y.paymentDtlStatus == '7'">
                    <img src="@/assets/icon/ic-mall-package-yellow.svg" />
                    <p>상품 준비 중</p>
                  </div>
                  <div class="state" v-if="y.paymentDtlStatus == '8'">
                    <img src="@/assets/icon/ic-mall-delivery-yellow.svg" />
                    <p>배송 중</p>
                  </div>
                  <div class="state" v-if="y.paymentDtlStatus == '9'">
                    <img src="@/assets/icon/ic-check-yellow.svg" />
                    <p>배송 완료</p>
                  </div>
                  <div class="content-wrap">
                    <div class="content-wrap-left">
                      <img :src="x.imgUrl" />
                    </div>
                    <div class="content-wrap-right">
                      <div class="content-wrap-right-info">
                        <p class="item-name">{{ x.itemName }}</p>
                        <div class="item-info">
                          <p>
                            <span class="material-symbols-outlined">
                              check
                            </span>
                            {{ y.itemOptionName }}
                          </p>
                          <!-- <img src="@/assets/icon/ic-minus.svg" /> -->
                        </div>
                        <div class="item-price">
                          <p>{{ comma(y.paymentAmount) }} 원</p>
                          <img src="@/assets/icon/ic-minus.svg" />
                          <p>{{ comma(y.quantity) }} 개</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body-btns" v-if="isMobile == 'true'">
                <p
                  @click="
                    y.paymentDtlStatus <= 2
                      ? gotoPage(`/mall/buyrefund/${y.paymentDtlNo}`)
                      : ''
                  "
                  :class="y.paymentDtlStatus <= 2 ? '' : 'disabled'"
                >
                  교환/반품 신청
                </p>
                <!-- <p @click="gotoPage(`/mall/materialdetail/${x.mallNo}`)">
                  재 주문하기
                </p> -->
                <p @click="addBasket(x.mallNo, y)">장바구니 담기</p>
              </div>
              <div class="item-btns" v-else>
                <!-- <p @click="gotoPage(`/mall/materialdetail/${x.mallNo}`)">
                  재 주문하기
                </p> -->
                <p @click="addBasket(x.mallNo, y)">장바구니 담기</p>
                <p
                  @click="
                    y.paymentDtlStatus <= 2
                      ? gotoPage(`/mall/buyrefund/${y.paymentDtlNo}`)
                      : ''
                  "
                  :class="y.paymentDtlStatus <= 2 ? '' : 'disabled'"
                >
                  교환/반품 신청
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card-bottom" v-if="isMobile == 'true'">
        <p @click="gotoPage(`/mall/materialdetail/${x.mallNo}`)">재 주문하기</p>
        <p @click="gotoPage(`/mall/buyrefund/${x.paymentNo}`)">
          교환/반품 신청
        </p>
      </div> -->
    </div>
  </div>
  <div
    v-if="!(listData?.length > 0)"
    class="no-list"
    :class="isMobile == 'true' ? 'mb' : ''"
  >
    주문 내역이 없습니다.
  </div>

  <div v-if="isBasket" class="modal">
    <div class="modal-background" @click="isBasket = false"></div>
    <div class="modal-basket">
      <p>장바구니로 이동하시겠습니까?</p>
      <div class="modal-basket-btns">
        <p class="btns-btn" @click="isBasket = false">계속 쇼핑하기</p>
        <p class="btns-btn active" @click="gotoPage('/mall/basket')">
          장바구니 이동
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MaterialListView",
  props: {
    listData: {
      type: Object,
      description: "Card data",
    },
  },
  data() {
    return {
      d: {},
      isShowMsg: false,
      isBasket: false,
      index: "",
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  mounted() {
    console.log(this.listData);
    this.d = this.listData;
    this.d.isLike = this.d.like;
  },
  methods: {
    addBasket(mallNo, item) {
      console.log(mallNo);
      console.log(item);
      let detailData = {};
      let addItem = [];
      this.$API.mall.getMaterialDetail(mallNo).then((res) => {
        console.log(res);
        detailData = res.data.body;
        addItem.push({
          itemName: item.itemName,
          itemOptionName: item.itemOptionName,
          minCount: "",
          count: item.quantity,
          itemUnitCost: detailData.itemUnitCost,
          addUnitCost: item.addUnitCost,
          detailData: detailData,
        });

        console.log(detailData);
        console.log(addItem);
        let basketItem = [];
        if (sessionStorage.getItem("basketItem")) {
          basketItem = JSON.parse(sessionStorage.getItem("basketItem"));
        }
        basketItem.push({
          deliveryPrice: detailData.deliveryPrice,
          itemName: detailData.itemName,
          item: addItem,
          mallNo: detailData.mallNo,
          tabCatg: detailData.tabCatg,
        });
        console.log(basketItem);
        sessionStorage.setItem("basketItem", JSON.stringify(basketItem));
        console.log(sessionStorage.getItem("basketItem"));
        this.isBasket = true;
      });
    },
    openStatusPopup(index) {
      this.index = index;
      this.isShowMsg = true;
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    bookmark(index, designOrderNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo);
    },
    factoryPin(index, designOrderNo, factoryNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo, factoryNo);
    },
    goDetail(orderNum, itemName) {
      this.$store.state.meta.detailTitle = itemName;
      console.log(this.$store.state.meta.detailTitle);
      console.log(orderNum);
      if (this.userId) {
        this.gotoPage(`/mall/buydetail/${orderNum}`);
      } else {
        this.$emit("openLogin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  background: $neutral-5 !important;
  color: $neutral-60 !important;
}
.material {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // margin-top: 40px;
  &.mb {
    @media (min-width: 801px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .card {
    cursor: pointer;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid $neutral-10;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    &.mb {
      border-radius: 0;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    }
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid $primary-20;
      .date {
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
      }
      .move-detail {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        img {
          width: 16px;
        }
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width: 520px) {
        margin-top: 16px;
      }
      &:first-child {
        margin-top: 20px;
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
      &-info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .item {
          display: flex;
          justify-content: space-between;
          padding: 20px;
          border-top: 1px solid $primary-20;
          border-bottom: 1px solid $primary-20;
          // &:last-child {
          //   padding-bottom: 0;
          //   border-bottom: 0;
          // }

          .wrap {
            display: flex;
            gap: 20px;
            .content {
              display: flex;
              flex-direction: column;
              gap: 12px;
              .state {
                display: flex;
                gap: 8px;
                color: $primary-60;
                font-size: 15px;
                font-weight: 500;
                line-height: 140%;
                &.cancel {
                  color: $neutral-80;
                  span {
                    color: $neutral-60;
                  }
                }
              }
              &-wrap {
                display: flex;
                gap: 20px;
                &-left {
                  img {
                    max-width: 80px;
                    max-height: 60px;
                    border-radius: 4px;
                  }
                }
                &-right {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  &-info {
                    display: flex;
                    flex-direction: column;
                    .item-name {
                      color: $neutral-60;
                      font-size: 15px;
                      font-weight: 500;
                      line-height: 140%;
                    }
                    .item-info {
                      margin-top: 4px;
                      display: flex;
                      gap: 4px;
                      color: $neutral-60;
                      font-size: 15px;
                      font-weight: 400;
                      line-height: 140%;
                      p {
                        display: flex;
                        gap: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        // max-width: 100px;
                      }
                      span {
                        font-size: 16px;
                      }
                      .point {
                        display: flex;
                        align-items: center;
                        width: 4px;
                        height: 4px;
                        border: 2px solid #737373;
                        border-radius: 2px;
                        margin: 8px 0;
                      }
                    }
                    .item-price {
                      margin-top: 12px;
                      margin-top: 4px;
                      display: flex;
                      gap: 4px;
                      color: $neutral-60;
                      font-size: 15px;
                      font-weight: 400;
                      line-height: 140%;
                      p {
                        display: flex;
                        gap: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100px;
                      }
                    }
                  }
                }
              }
            }
          }
          &-btns {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-left: 20px;
            border-left: 1px solid $neutral-10;
            height: 100px;
            p {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              width: 160px;
              height: 40px;
              color: $neutral-0;
              font-size: 15px;
              font-weight: 500;
              line-height: 140%;
              border-radius: 4px;
              &:first-child {
                background: $primary-50;
              }
              &:last-child {
                flex: 1;
                background: $neutral-60;
              }
              &.one {
                border-radius: 4px;
                background: $primary-50;
              }
            }
          }
        }
      }
      &-btns {
        display: flex;
        margin-top: 20px;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          height: 40px;
          color: $neutral-0;
          font-size: 15px;
          font-weight: 500;
          line-height: 140%;
          &:first-child {
            border-radius: 4px 0px 0px 4px;
            background: $neutral-60;
          }
          &:last-child {
            flex: 1;
            border-radius: 0px 4px 4px 0px;
            background: $primary-50;
          }
          &.one {
            border-radius: 4px;
            background: $primary-50;
          }
        }
      }
    }

    &-bottom {
      display: flex;
      margin-top: 20px;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 40px;
        color: $neutral-0;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
        &:first-child {
          border-radius: 4px 0px 0px 4px;
          background: $neutral-60;
        }
        &:last-child {
          flex: 1;
          border-radius: 0px 4px 4px 0px;
          background: $primary-50;
        }
        &.one {
          border-radius: 4px;
          background: $primary-50;
        }
      }
    }
  }
}
.no-list {
  padding-top: 200px;
  border-top: 1px solid $neutral-10;
  display: flex;
  justify-content: center;
  height: 422px;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  &.mb {
    padding-top: 100px;
  }
}

.modal-basket {
  position: fixed;
  z-index: 1001;
  left: 50%;
  top: 242px;
  width: calc(100% - 48px);
  max-width: 360px;
  height: 210px;
  transform: translate(-50%, 0);
  background-color: $neutral-0;
  border-radius: 8px;
  padding: 74px 0;
  text-align: center;
  color: $neutral-100;
  & > p:first-child:not(.title) {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .sub {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $neutral-60;
  }
  &-btns {
    // margin-top: 32px;
    bottom: 0;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    background-color: $neutral-50;
    border-radius: 0px 0px 8px 8px;
    color: $neutral-0;
    .btns-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: rem(12px) 0;
      cursor: pointer;
      &:not(:first-child) {
        margin-left: rem(8px);
      }
      &.active {
        background-color: $primary-50;
        border-radius: 0px 0px 8px 8px;
        &:not(:first-child) {
          border-radius: 0px 0px 8px 0px;
        }
      }
    }
  }
}
</style>
