import { createApp } from "vue";
import { router } from "./router";
import store from "./store";
import settings from "./settings/index";
import directives from "./directives";
import jquery from "jquery";
import sha256 from "crypto-js/sha256";
import Hotjar from "vue-hotjar-next";
import { firebaseManager } from "@/firebase";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import VueSignaturePad from "vue-signature-pad";

import App from "./App.vue";
import api from "./apis/";

import { Swiper, SwiperSlide, Autoplay } from "swiper/vue";
import VueKonva from "vue-konva";
import "@/scss/index.scss";

import mitt from "mitt";

const emitter = mitt();

window.$ = jquery;
window.JQuery = jquery;

const app = createApp(App);
// app.config.globalProperties.$db = db;
app.config.globalProperties.$emitter = emitter;
app.config.globalProperties.$API = api;
app.use(router);
app.use(directives);
app.use(store);
app.use(sha256);
app.use(settings);
app.use(VueToast);
app.use(VueSignaturePad);

app.config.productionTip = false;
app.use(VueKonva);

let isProduct =
  document.location.host == "www.osle.co.kr" ||
  document.location.host == "osle.co.kr";

app.use(Hotjar, {
  id: 2763606, // Hotjar Site ID
  isProduction: isProduct,
  snippetVersion: 6,
});
let member_type = "";
if (sessionStorage.getItem("compType") == "1") {
  member_type = "designer";
} else if (sessionStorage.getItem("compType") == "2") {
  member_type = "factory";
}
const userId = sessionStorage.getItem("userId") || null; // Replace your_user_id with your own if available.
if (window.hj) {
  window.hj("identify", userId, {
    member_type: member_type,
  });
}

// firebase
app.provide("$firebaseManager", firebaseManager);

app.mixin({
  methods: {
    async copyToClipboard(factoryNo) {
      console.log(1);
      console.log(factoryNo);
      console.log(this.userInfo);
      if (
        location.hostname == "www.osle.co.kr" ||
        location.hostname == "osle.co.kr"
      ) {
        this.factoryPayUrl = `https://osle.co.kr/paymentExternal.html?factoryNo=${factoryNo}`;
      } else if (
        location.hostname == "www.dev.osle.co.kr" ||
        location.hostname == "dev.osle.co.kr"
      ) {
        this.factoryPayUrl = `https://dev.osle.co.kr/paymentExternal.html?factoryNo=${factoryNo}`;
      } else {
        // ORIGIN_ADDRESS = "https://api.osle.co.kr";
        this.factoryPayUrl = `http://localhost:8080/paymentExternal.html?factoryNo=${factoryNo}`;
      }

      if (!navigator.clipboard) {
        this.fallbackCopyToClipboard();
        return;
      }

      try {
        await navigator.clipboard.writeText(this.factoryPayUrl);
        this.showToast("결제 URL이 클립보드에 복사되었습니다.");
      } catch (err) {
        console.log("복사에 실패했습니다: " + err);
      }
    },
    fallbackCopyToClipboard() {
      const input = this.$refs.inputText;
      input.select();
      input.setSelectionRange(0, 99999);

      if (document.execCommand("copy")) {
        this.showToast("결제 URL이 클립보드에 복사되었습니다.");
      } else {
        console.log("브라우저에서 클립보드 복사가 지원되지 않습니다.");
      }
    },
    gotoBanner(bannerUrl, bannerName) {
      this.$API.comm.bannerEvent(bannerUrl, bannerName);
      this.gotoUrl(bannerUrl, 1);
    },
    sendBtnEvent(btnType, resultCode) {
      this.$API.comm.btnEvent(btnType, resultCode);
    },
    sendVisit() {
      this.$API.comm.visit();
    },
    replaceTag(val) {
      return val.replace("#", "");
    },
    masking(val) {
      if (val) {
        return val.substr(0, 2) + "*****";
      } else {
        return val;
      }
    },
    emailCheck(email) {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(email);
    },
    maxLengthCheck(id) {
      const el = document.getElementById(id);
      if (el.value.length > el.maxLength) {
        el.value = el.value.slice(0, el.maxLength);
      }
    },
    isNumber(event) {
      const keyCode = event.keyCode;
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode < 37 || keyCode > 40) &&
        keyCode != 9 &&
        keyCode != 8 &&
        keyCode != 46
      ) {
        event.preventDefault();
      }
    },
    comma(val) {
      if (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return val;
      }
    },
    hp(val) {
      if (!val) return "";
      // 숫자만 남기고 나머지는 제거합니다.
      let cleaned = val.replace(/\D/g, "");
      // 전화번호 형식에 맞게 '-'를 삽입합니다.
      if (cleaned.length === 7) {
        return cleaned.replace(/(\d{3})(\d{4})/, "$1-$2");
      } else if (cleaned.length >= 8 && cleaned.length <= 12) {
        return cleaned.replace(/(\d{3})(\d{3,4})(\d{0,4})/, "$1-$2-$3");
      } else {
        // 기타 경우에는 그대로 반환합니다.
        return cleaned;
      }
    },
    addEulReul(val) {
      // 마지막 글자를 가져옵니다
      const lastChar = val.charAt(val.length - 1);
      // 유니코드 상에서 한글의 범위는 0xAC00(가)에서 0xD7A3(힣)입니다.
      // 이 범위 안에 있는 문자는 모두 한글이므로, 한글인지 확인합니다.
      const isHangul = lastChar >= "가" && lastChar <= "힣";

      // 한글이 아닌 경우 처리가 필요하다면 추가 작업을 할 수 있습니다.
      if (!isHangul) {
        return val; // 한글이 아니면 그대로 반환
      }

      // 한글 문자에서 종성의 유무에 따라 '을' 또는 '를'을 결정할 수 있습니다.
      // 문자를 유니코드 숫자로 변환합니다.
      const uniChar = lastChar.charCodeAt(0);
      // '가'에서부터의 거리를 계산합니다.
      const index = uniChar - 0xac00;
      // 종성이 있는지 확인합니다. (총 28개의 종성, 없을 때를 포함하여 0-27의 값을 가짐)
      const jongseongIndex = index % 28;

      // 종성이 없으면 '를', 있으면 '을'을 붙입니다.
      return jongseongIndex === 0 ? val + "를" : val + "을";
    },
    gotoPage(path, query) {
      if (!query) {
        this.$router.push(path);
      } else {
        this.$router.push({ path: path, query: query });
      }
      window.scrollTo(0, 0);
    },
    reload() {
      window.location.reload();
    },
    scrollElement(ref) {
      this.$nextTick(() => {
        this.$refs[ref].scrollIntoView({ behavior: "smooth" });
      });
    },
    gotoUrl(link, isNewPage = 0) {
      if (link) {
        let target = "_self";
        if (isNewPage) target = "_blank";
        window.open(link, target);
      }
    },
    updateMetaTag(content, name, attribute) {
      let element = document.head.querySelector(`meta[${attribute}="${name}"]`);

      if (element) {
        element.setAttribute("content", content);
      } else {
        element = document.createElement("meta");
        element.setAttribute(attribute, name);
        element.setAttribute("content", content);
        document.head.appendChild(element);
      }
    },
    handleLocalStorageChange(event) {
      console.log(event);
      console.log(event.key);
      console.log(localStorage.getItem("isFindId"));
      if (event.key === "isComplete") {
        this.isComplete = localStorage.getItem("isComplete");
        if (this.isComplete) {
          location.reload();
        }
      }
      if (event.key === "isFindId") {
        console.log(111111111);
        console.log(localStorage.getItem("isFindId"));
        this.$store.state.meta.loginInfo.isShowFindId =
          localStorage.getItem("isFindId");
        const telNum = localStorage.getItem("telNum");
        const userName = localStorage.getItem("userName");
        this.$API.account.dupleIdCheck(userName, telNum).then((res) => {
          console.log(res);
          if (res.data.resultCode === "10000") {
            this.userIds = res.data.body;
            this.$store.state.meta.loginInfo.userIds = this.userIds;
            this.userIds.forEach((element) => {
              if (element.socialType == "normal") {
                this.$store.state.meta.loginInfo.normalUserId = element;
                this.$store.state.meta.loginInfo.userIdText = element.userId;
              } else if (element.socialType == "kakao") {
                this.userIdText = "카카오로그인 간편 회원입니다.";
                this.$store.state.meta.loginInfo.kakaoUserId = element;
              } else if (element.socialType == "naver") {
                this.userIdText = "네이버로그인 간편 회원입니다.";
                this.$store.state.meta.loginInfo.naverUserId = element;
              }
            });
          }
        });
      }
    },
    callLogin(userId, userPw, isPwEncoding, afterFunc) {
      if (!userId || !userPw) return;
      let pw = isPwEncoding ? sha256(userPw) : userPw;
      let isSuccess = false;
      let resultMsg = "";
      let resultCode = "";
      this.$API.account
        .login(userId, pw)
        .then((res) => {
          if (res.data.resultCode === "10000") {
            // this.$API.account.session(this.userId, sha256(this.userPw))
            // alert(res.data.body.di);
            console.log(res.data.body);
            this.setCookie("socialType", res.data.body.socialType, 365);
            sessionStorage.setItem("compType", res.data.body.compType);
            if (!res.data.body.compType) {
              localStorage.setItem("userId", userId);
              localStorage.setItem("userPw", userPw);
              localStorage.setItem("loginType", "auth");
              // localStorage.setItem("socialType", res.data.body.socialType);
              localStorage.setItem("compType", res.data.body.compType);
              this.gotoPage("/account/register");
            } else {
              sessionStorage.setItem("userId", userId);
              sessionStorage.setItem("userPw", pw);

              if (sessionStorage.getItem("deviceToken")) {
                this.$API.comm.tokenSave(
                  userId,
                  sessionStorage.getItem("deviceToken")
                );
              }
              this.loginInfoSet(true, res.data.body);
              isSuccess = true;
            }
            // } else if (res.data.body.di) {
            //   sessionStorage.setItem("userId", userId);
            //   sessionStorage.setItem("userPw", pw);

            //   if (sessionStorage.getItem("deviceToken")) {
            //     this.$API.comm.tokenSave(
            //       userId,
            //       sessionStorage.getItem("deviceToken")
            //     );
            //   }
            //   this.loginInfoSet(true, res.data.body);
            //   isSuccess = true;
            // } else {
            //   localStorage.setItem("userId", userId);
            //   localStorage.setItem("userPw", userPw);
            //   localStorage.setItem("loginType", "auth");
            //   // localStorage.setItem("socialType", res.data.body.socialType);
            //   localStorage.setItem("compType", res.data.body.compType);
            //   this.gotoPage("/account/register");
            // }
          } else {
            this.loginInfoSet(false);
            resultMsg = res.data.resultMsg;
            resultCode = res.data.resultCode;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          if (afterFunc) {
            afterFunc(isSuccess, resultMsg, resultCode);
          }
        });
    },
    loginInfoSet(isLogined, data) {
      if (isLogined) {
        for (const [key, value] of Object.entries(data)) {
          sessionStorage.setItem(key, value);
        }
        // sessionStorage.setItem('showUserName', data.userName ? data.userName : data.mailId)
        let showUserName = "";
        let userName = data.userName;
        // if (userName) userName += '님'
        if (data.compType == 1) {
          // 디자이너
          showUserName =
            data.brandName ||
            data.compName ||
            data.clientName ||
            userName ||
            data.mailId;
        } else {
          // 공장
          showUserName =
            data.factoryName ||
            data.compName ||
            data.clientName ||
            userName ||
            data.mailId;
        }
        this.$store.state.meta.loginInfo.showUserName = showUserName;
        this.$store.state.meta.loginInfo.email =
          data.mailId + "@" + data.mailName;
        this.$store.state.meta.loginInfo.userName = data.userName;
        this.$store.state.meta.loginInfo.imgUrl = data.imgUrl;
      } else {
        sessionStorage.clear();
      }
      this.sendVisit();
    },
    loginCheck(reffer, msg) {
      this.$store.state.meta.returnLoginPath = this.$route.path;
      this.$store.state.meta.returnRefferPath = reffer;
      if (this.$route.path.indexOf("login") <= 0) {
        this.gotoPage("/account/login");
        this.showToast(
          msg || "로그인이 필요한 서비스 입니다. 로그인 후 이용해 주세요!"
        );
      } else {
        this.gotoPage(reffer);
      }
    },
    logout() {
      this.loginInfoSet(false);
      localStorage.setItem("autoLogin", "");
      localStorage.setItem("userId", "");
      localStorage.setItem("userPw", "");
      localStorage.setItem("compType", "");
      sessionStorage.setItem("userId", "");
      this.gotoUrl("/");
    },
    setPopup(isShow, id, title) {
      this.$store.state.meta.popupInfo.id = isShow ? id : "";
      this.$store.state.meta.popupInfo.title = isShow ? title : "";
      this.$store.state.meta.popupInfo.isShow = isShow;
      this.backgroundScroll(isShow);
    },
    setMsgPopup(isShow, msg, func) {
      if (isShow) {
        this.$store.state.meta.msgPopupInfo.msg = msg;
        this.$store.state.meta.msgPopupInfo.func = func ? func : null;
      } else {
        this.$store.state.meta.msgPopupInfo.msg = "";
        this.$store.state.meta.msgPopupInfo.func = null;
      }
      this.backgroundScroll(isShow);
    },
    showImg(url, fileName) {
      if ([".ai", ".psd", ".pdf", ".xlsx"].some((e) => url.indexOf(e) != -1)) {
        window.location = url;
      } else {
        this.$store.state.meta.popupInfo.id = "showImg";
        this.$store.state.meta.popupInfo.url = url;
        this.$store.state.meta.popupInfo.title = fileName || "이미지 보기";
        this.$store.state.meta.popupInfo.isShow = true;
      }
    },
    setCookie(key, value, day) {
      var date = new Date();
      date.setTime(date.getTime() + day * 60 * 60 * 24 * 1000);
      document.cookie =
        key + "=" + value + ";expires=" + date.toUTCString() + ";path=/";
    },
    getCookie(key) {
      var value = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
      return value ? value[2] : null;
    },
    deleteCookie(key) {
      var date = new Date();
      document.cookie =
        key + "= " + "; expires=" + date.toUTCString() + "; path=/";
    },
    showToast(msg, type = "default") {
      this.$toast.open({
        message: msg,
        type: type,
        position: "bottom",
        duration: 3000,
        dismissible: true,
      });
    },
    isToast(msg, type = "default") {
      this.$store.state.meta.toastMsg = msg;
      setTimeout(() => {
        this.$store.state.meta.toastMsg = "";
      }, 2000);
    },
    copyCilpboard(value, msg) {
      var tempElem = document.getElementById("copyText");
      tempElem.value = value;
      tempElem.select();
      document.execCommand("copy");
      this.isToast(msg);
    },
    callFactory(factoryNo, telNum) {
      if (sessionStorage.getItem("isMobile") == "true") {
        location.href = "tel:" + telNum;
      } else {
        this.copyCilpboard(telNum, "전화번호가 복사되었습니다.");
      }
      this.$API.comm.btnFacEvent(factoryNo, "1");
    },
    inputFocusout(refName) {
      const inputElement = this.$refs[refName];
      console.log(inputElement);
      if (inputElement) {
        inputElement.blur();
      }
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    // constructor() {
    //   this.loadScript();
    // },
    loadScript() {
      // var w = window;
      // if (w.ChannelIO) {
      //   return (window.console.error || window.console.log || function () {})(
      //     "ChannelIO script included twice."
      //   );
      // }
      // var ch = function () {
      //   ch.c(arguments);
      // };
      // ch.q = [];
      // ch.c = function (args) {
      //   ch.q.push(args);
      // };
      // w.ChannelIO = ch;
      // function l() {
      //   if (w.ChannelIOInitialized) {
      //     return;
      //   }
      //   w.ChannelIOInitialized = true;
      //   var s = document.createElement("script");
      //   s.type = "text/javascript";
      //   s.async = true;
      //   s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
      //   s.charset = "UTF-8";
      //   var x = document.getElementsByTagName("script")[0];
      //   x.parentNode.insertBefore(s, x);
      // }
      // if (document.readyState === "complete") {
      //   l();
      // } else if (window.attachEvent) {
      //   window.attachEvent("onload", l);
      // } else {
      //   window.addEventListener("DOMContentLoaded", l, false);
      //   window.addEventListener("load", l, false);
      // }
      // window.ChannelIO("boot", {
      //   pluginKey: "9aa923a7-c4f2-4678-9e03-0806b1d8e23e",
      //   customLauncherSelector: "#channeltalk",
      //   hideDefaultLauncher: this.$store.state.meta.windowWidth <= 800,
      //   zIndex: 990,
      // });
    },
    boot() {
      window.ChannelIO("boot", {
        pluginKey: "9aa923a7-c4f2-4678-9e03-0806b1d8e23e",
        customLauncherSelector: "#channeltalk",
        hideDefaultLauncher: this.$store.state.meta.windowWidth <= 800,
        zIndex: 990,
      });
    },
    shutdown() {
      window.ChannelIO("shutdown");
    },
    backgroundScroll(isShow) {
      if (isShow) {
        document.body.style = "overflow: hidden";
      } else {
        document.body.style = "";
      }
    },
    replaceURLWithHTMLLinks(text) {
      let result = text;
      let link = new RegExp(/(((https|http):\/\/|www\.)(\w+(\.?))+)/, "gm");
      if (text.match(link)) {
        for (let i = 0; i < text.match(link).length; i++) {
          result = text
            .replace(link, '<a href="$1" target="_blank">$1</a>')
            .replace('href="www.', 'href="http://');
        }
      }
      return result;
    },
    replaceLink(text) {
      let result = text;
      let link = new RegExp(/(((https|http):\/\/|www\.)(\w+(\.?))+)/, "gm");
      if (text.match(link)) {
        for (let i = 0; i < text.match(link).length; i++) {
          result = text
            .replace("www.", "")
            .replace("https://", "")
            .replace("http://", "");
          result = "http://" + result;
        }
      }
      return result;
    },
    showFileSize(size) {
      let kb = Number(size) / 1024;
      if (kb > 1000) {
        return Math.round(size / 1024) + "MB";
      } else {
        return kb.toFixed(3) + "KB";
      }
    },
    factoryFilterReset() {
      sessionStorage.setItem("factoryFilter", null);
    },
    base64toFile(data, fileName) {
      let arr = data.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], fileName, { type: mime });
    },
    changeFontSize() {
      var elements = document.querySelectorAll("p");
      // Iterate through the selected elements
      for (var i = 0; i < elements.length; i++) {
        // Get the current font size of each element
        var currentFontSize = parseFloat(
          window.getComputedStyle(elements[i]).fontSize
        );
        // Increase the font size by 2
        var newFontSize = currentFontSize + 2;
        // Set the new font size to the current element
        elements[i].style.fontSize = newFontSize + "px";
      }
    },
    arraysHaveSameValues(arr1, arr2) {
      const set1 = new Set(arr1);
      const set2 = new Set(arr2);

      if (set1.size !== set2.size) return false;

      for (const item of set1) {
        if (!set2.has(item)) return false;
      }

      return true;
    },
    initCustomPrint() {
      this.$store.state.customPrint.items.map((e) => (e.isActive = false));
      this.$store.state.customPrint.lineType.map((e) => (e.isActive = false));
      this.$store.state.customPrint.lineType[0].isActive = true;
      this.$store.state.customPrint.size.map((e) => (e.isActive = true));
      this.$store.state.customPrint.color.map((e) => (e.isActive = false));
      this.$store.state.customPrint.checkSize.map((e) => (e.isActive = false));
      this.$store.state.customPrint.checkPrintImg.map(
        (e) => (e.isActive = false)
      );
      this.$store.state.customPrint.gender.map((e) => (e.isActive = false));
      this.$store.state.customPrint.fit.map((e) => (e.isActive = false));
      this.$store.state.customPrint.totalLength.map(
        (e) => (e.isActive = false)
      );
      this.$store.state.customPrint.sleeveLength.map(
        (e) => (e.isActive = false)
      );
      this.$store.state.customPrint.sleeveStyle.map(
        (e) => (e.isActive = false)
      );
      this.$store.state.customPrint.neckLine.map((e) => (e.isActive = false));
      this.$store.state.customPrint.neckDetail.map((e) => (e.isActive = false));
      this.$store.state.customPrint.neckEnd.map((e) => (e.isActive = false));
      this.$store.state.customPrint.stitch.map((e) => (e.isActive = false));
      this.$store.state.customPrint.pocket.map((e) => (e.isActive = false));
      this.$store.state.customPrint.sleeve.map((e) => (e.isActive = false));
      this.$store.state.customPrint.sideSlit.map((e) => (e.isActive = false));
      this.$store.state.customPrint.sleeveEnd.map((e) => (e.isActive = false));
      this.$store.state.customPrint.hemEnd.map((e) => (e.isActive = false));

      this.$store.state.customPrint.printFrontImgs.map((e) => {
        e.img = {};
        e.lineType = "";
        e.longLength = "";
        e.printLength = [];
      });
      this.$store.state.customPrint.printBackImgs.map((e) => {
        e.img = {};
        e.lineType = "";
        e.longLength = "";
        e.printLength = [];
      });
    },
    getDayOfWeek(dateString) {
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];

      // 입력받은 문자열을 Date 객체로 변환
      const date = new Date(dateString);

      // 요일 번호 (0: 일요일, 1: 월요일, ..., 6: 토요일)
      const dayIndex = date.getDay();

      // 요일 이름 반환
      return daysOfWeek[dayIndex];
    },
    openCustomAddPopup(userId) {
      if (!userId) {
        this.$emit("openLogin");
      } else {
        this.$emit("customAddPopup", true);
      }
    },
    loadPaypleScript() {
      const script = document.createElement("script");
      if (
        location.hostname == "www.osle.co.kr" ||
        location.hostname == "osle.co.kr"
      ) {
        script.src = "https://cpay.payple.kr/js/v1/payment.js";
      } else {
        script.src = "https://democpay.payple.kr/js/v1/payment.js";
      }
      script.onload = () => {
        this.paypleLoaded = true;
      };
      script.onerror = () => {
        this.errorMessage = "Failed to load Payple script.";
      };
      document.head.appendChild(script);
    },
  },
});

app.directive("outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

app.mount("#app");
