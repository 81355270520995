<template>
  <div class="line"></div>
  <div class="footer" v-if="isMobile == 'false'">
    <div class="footer-background"></div>
    <div class="footer-wrap">
      <div class="footer-top">
        <div class="company">
          <div class="company-div">
            <p class="bold">주식회사 위아더</p>
            <p class="mt-12">
              주소 : 서울특별시 중구 마른내로 136, 2층 (쌍림동 146-4) ㅣ 대표자
              : 조형일
            </p>
            <div class="flex-start">
              <p>
                개인정보보호책임자 : 강상구 ㅣ 사업자등록번호 : 862-81-01314
              </p>
            </div>
            <p>
              통신판매업신고 : 제 2019-서울중구-1003호 ㅣ 고객센터 : 02-765-1556
              ㅣ cs@wearethe.co.kr
            </p>
          </div>
          <div class="terms">
            <p class="pointer bold" @click="gotoPage('/terms/termsofservice')">
              이용 약관
            </p>
            <p class="pointer bold" @click="gotoPage('/terms/privacypolicy')">
              개인정보 취급방법
            </p>

            <p
              class="pointer bold"
              @click="
                gotoUrl(
                  'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=8628101314',
                  1
                )
              "
            >
              사업자정보 확인
            </p>
          </div>
        </div>
        <div class="move-page">
          <div class="move-page-row">
            <p
              class="pointer"
              @click="gotoUrl('https://www.instagram.com/osle_official', 1)"
            >
              <img src="@/assets/img/ic-footer-insta-gray.png" />
            </p>
            <p
              class="pointer"
              @click="gotoUrl('https://www.facebook.com/oslepage', 1)"
            >
              <img src="@/assets/img/ic-footer-facebook-gray.png" />
            </p>
            <p
              class="pointer"
              @click="gotoUrl('http://pf.kakao.com/_MvqtT', 1)"
            >
              <img src="@/assets/img/ic-footer-kakao-gray.png" />
            </p>
            <p class="pointer" @click="gotoUrl('http://naver.me/5Wck48tw', 1)">
              <img src="@/assets/img/ic-footer-naver-gray.png" />
            </p>
          </div>
          <div class="move-page-column">
            <p class="title">Contact us</p>
            <p class="tel">02-765-1556</p>
            <p class="time">상담 가능 시간 : 평일 10:00 ~ 18:00</p>
            <p class="email">cs@wearethe.co.kr</p>
            <p class="inquire" @click="gotoPage('/mypage/inquire')">문의하기</p>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>
          주식회사 위아더는 통신판매중개자이며, 통신판매의 당사자가 아닙니다.
          거래에 관한 의무와 책임은 양 당사자에게 있습니다. <br />오슬 사이트의
          회원 정보, 중개 서비스, 거래 정보, 작업 정보, 컨텐츠, UI 등에 대한
          무단복제, 전송, 배포, 스크래핑 등의 행위는 저작권법, 콘텐츠산업 진흥법
          등 관련법령에 의하여 엄격히 금지됩니다.
        </p>
        <p class="copyright">ⓒ 2023 WE ARE THE. All rights reserved.</p>
      </div>
    </div>
  </div>
  <div class="mobile-footer" v-else>
    <div class="mobile-footer-wrap">
      <div class="move-sns">
        <p
          class="pointer"
          @click="gotoUrl('https://www.instagram.com/osle_official', 1)"
        >
          <img src="@/assets/img/ic-footer-insta-gray.png" />
        </p>
        <p
          class="pointer"
          @click="gotoUrl('https://www.facebook.com/oslepage', 1)"
        >
          <img src="@/assets/img/ic-footer-facebook-gray.png" />
        </p>
        <p class="pointer" @click="gotoUrl('http://pf.kakao.com/_MvqtT', 1)">
          <img src="@/assets/img/ic-footer-kakao-gray.png" />
        </p>
        <p class="pointer" @click="gotoUrl('http://naver.me/5Wck48tw', 1)">
          <img src="@/assets/img/ic-footer-naver-gray.png" />
        </p>
      </div>
      <div class="company">
        <p class="company-info">주식회사 위아더</p>
        <div class="detail">
          <!-- <p class="address">
          </p> -->
          <p class="info">
            주소 : 서울특별시 중구 마른내로 136, 2층 (쌍림동 146-4)<br />
            대표자 : 조형일 ㅣ 개인정보보호책임자 : 강상구<br />
            사업자등록번호 : 862-81-01314<br />
            통신판매업신고 : 제2019-서울중구-1003호<br />
            고객센터 : 02-765-1556ㅣcs@wearethe.co.kr
          </p>
        </div>
      </div>
      <div class="move-page">
        <p class="pointer" @click="gotoUrl('/terms/termsofservice', 1)">
          이용 약관
        </p>
        <p class="pointer" @click="gotoUrl('/terms/privacypolicy', 1)">
          개인정보 취급방법
        </p>
        <p
          class="pointer"
          @click="
            gotoUrl('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=8628101314', 1)
          "
        >
          사업자정보 확인
        </p>
      </div>
      <div class="info">
        <p>
          주식회사 위아더는 통신판매중개자이며, 통신판매의 당사자가 아닙니다.
          거래에 관한 의무와 책임은 양 당사자에게 있습니다. 오슬 사이트의 회원
          정보, 중개 서비스, 거래 정보, 작업 정보, 컨텐츠, UI 등에 대한
          무단복제, 전송, 배포, 스크래핑 등의 행위는 저작권법, 콘텐츠산업 진흥법
          등 관련법령에 의하여 엄격히 금지됩니다.
        </p>
      </div>
      <div class="copyright">
        <p>ⓒ 2023 WE ARE THE. All rights reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomFooter",
  data() {
    return {
      sortOrder: "",
      stackData: {},
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.meta.windowWidth;
    },
  },
  mounted() {},
  methods: {
    backClick() {
      if (this.$store.state.meta.popupInfo.isShow) {
        this.setPopup(false);
      } else {
        // this.$store.state.meta.returnLoginPath = ''
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.line {
  position: absolute;
  width: 100vw;
  left: 50%;
  transform: translate(-50%, 0);
  border-bottom: 1px solid $primary-20;
  z-index: 1;
  @media (max-width: 1250px) {
    width: calc(100% - 40px);
  }
}
.footer {
  max-width: 1250px;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $neutral-0;
  position: relative;
  &-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 100vw;
    height: 100%;
    z-index: -1;
    background: $neutral-0;
  }
  .footer-wrap {
    border-top: 1px solid $primary-20;
    margin: auto;
    max-width: 1120px;
    width: 100%;
    justify-content: center;
    padding: 40px;
    display: flex;
    flex-direction: column;
    .footer-top {
      display: flex;
      justify-content: space-between;
      .company {
        .bold {
          font-weight: 500;
          font-size: 14px;
        }
        p {
          color: $neutral-60;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
        }
        .terms {
          margin: 40px 0 20px;
          display: flex;
          gap: 20px;
          .bold {
            color: $neutral-60;
            font-weight: 500;
            font-size: 12px;
          }
        }
      }

      .move-page {
        display: flex;
        align-items: flex-start;
        gap: 40px;
        &-row {
          display: flex;
          gap: 8px;
        }
        &-column {
          display: flex;
          flex-direction: column;
          width: 210px;
          font-size: 14px;
          font-weight: 400;
          .title {
            font-weight: 500;
            font-size: 14px;
          }
          .tel {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 500;
          }
          .time {
            margin-top: 8px;
          }
          .email {
            margin-top: 4px;
          }
          .inquire {
            cursor: pointer;
            margin-top: 12px;
            width: 67px;
            padding: 8px 12px;
            border-radius: 4px;
            background: $neutral-60;
            color: $neutral-0;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
    .footer-bottom {
      p {
        color: $neutral-60;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
      }
      .copyright {
        margin-top: 16px;
      }
    }
  }
}

.mobile-footer {
  padding: 20px;
  bottom: 0;
  border-top: 1px solid $primary-20;
  background-color: $neutral-0;
  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .move-sns {
      display: flex;
      justify-content: center;
      gap: 20px;
      p {
        display: flex;
        justify-content: center;
      }
    }
    .company {
      &-info {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .detail {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: $neutral-60;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        p {
          text-align: left;
        }
      }
    }

    .move-page {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-bottom: 20px;
      p {
        color: $neutral-60;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        margin-right: 8px;
      }
    }
    .info {
      text-align: center !important;
      color: $neutral-60;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .copyright {
      margin: 28px 0 20px;
      color: $neutral-60;
      font-weight: 400;
      font-size: 12px;
      text-align: center;
    }
  }
}
</style>
