<template>
  <div
    class="contract-card"
    :class="contractNo == cardData.contractNo ? 'selected' : ''"
    v-for="(cardData, i) in contractList"
    :key="i"
    @click="
      cardData.contractStatus != '6'
        ? isMobile == 'true'
          ? gotoPage(`/contract/detail/${cardData.contractNo}`)
          : this.$emit('contractDetail', cardData.contractNo)
        : ''
    "
  >
    <div class="contract-card-wrap pointer">
      <div class="contract-card-wrap-top">
        <p v-if="compType == '1'">{{ cardData.factoryCompName }}</p>
        <p v-if="compType == '2'">{{ cardData.designerCompName }}</p>
        <p
          v-if="
            cardData.contractStatus == '1' || cardData.contractStatus == '2'
          "
        >
          대기
        </p>
        <p
          v-if="
            cardData.contractStatus == '3' || cardData.contractStatus == '4'
          "
        >
          완료
        </p>
        <p v-if="cardData.contractStatus == '6'">취소</p>
      </div>
      <div class="contract-card-wrap-body">
        <p class="style-name">
          {{ cardData.contractStyleList[0].styleName
          }}{{
            cardData.contractStyleList.length > 1
              ? " 외 " + cardData.contractStyleList.length + "종"
              : ""
          }}
        </p>
      </div>
      <div class="contract-card-wrap-bottom">
        <p class="date">
          요청일<span>{{ cardData.createdDate }}</span>
        </p>
        <p class="price">￦ &nbsp;{{ comma(cardData.totalCostVat) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompContractDetail",
  props: {
    contractList: {
      type: String,
      description: "Contract Data",
    },
    contractNo: {
      type: String,
      description: "Contract Data",
    },
  },
  mounted() {},
  data() {
    return {
      compType: sessionStorage.getItem("compType"),
      isMobile: sessionStorage.getItem("isMobile"),
    };
  },
  methods: {
    contractDetail(contractNo) {
      console.log(contractNo);
      this.$emit("contractDetail", contractNo);
    },
    moveService(isProgress) {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로
        this.loginCheck();
      } else if (this.$route.query.type === "load") {
        this.moveConfirm();
      } else if (isProgress) {
        this.gotoPage(`/paymentconfirm`);
        this.showToast("의뢰내역을 불러왔습니다.");
      } else if (this.compType == "1") {
        this.gotoPage(`/paymentdetails/${this.cardData.paymentNo}`);
      } else {
        this.gotoPage(`/paymentproposal/${this.cardData.paymentNo}`);
      }
    },
    moveConfirm() {
      this.$API.payment
        .paymentStyleCopy(
          sessionStorage.getItem("userId"),
          this.cardData.paymentNo
        )
        .then(() => {
          this.gotoPage(`/paymentconfirm`);
          this.showToast("의뢰내역을 불러왔습니다.");
        });
    },
    remainingTime(date) {
      try {
        // let dayOfWeek = new Date(date).getDay()
        // let alpha = 2
        // if (dayOfWeek == 5 || dayOfWeek == 6) alpha = 4
        // if (dayOfWeek == 0) alpha = 3
        // let remainingMin = (24*60*alpha) - ((new Date() - new Date(date))/1000/60)
        // return `${Math.floor(remainingMin/60)}시간 ${Math.floor(remainingMin%60)}분`
        return `${date.split(":")[0]}시간 ${date.split(":")[1]}분`;
      } catch {
        return "0분";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-card {
  border-width: 0px 0 1px 0;
  border-style: solid;
  border-color: $neutral-10;
  &:first-child {
    border-top: 1px solid $neutral-10;
  }
  &.selected {
    background-color: $neutral-3;
  }
  &-wrap {
    padding: 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      .status {
        color: $neutral-30;
      }
    }
    &-body {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 8px;
      color: $neutral-60;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      .date {
        display: flex;
        align-items: center;
        gap: 12px;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        span {
          font-weight: 500;
        }
      }
    }
  }
}
.bottom {
  background-color: $neutral-3;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $neutral-80;
}
</style>
