<template>
  <div class="order-list" id="orderList">
    <div
      class="order"
      v-for="(x, i) in listData"
      :key="i"
      :class="x.partFlag == '1' ? 'active' : ''"
      @click="goDetail(x.designOrderNo, x.styleNameValue)"
    >
      <div class="order-content">
        <div class="order-content-body">
          <div class="thumbnail">
            <img :src="x.imgList ? x.imgList[0].fileUrl : ''" />
          </div>
          <div class="order-content-body-info">
            <div class="flex-start gap20">
              <img
                class="bookmark"
                @click.stop="bookmark(i, x.designOrderNo)"
                :src="
                  require(`@/assets/icon/ic-pin${
                    x.factoryLikeFlag == 1 ? '-yellow' : ''
                  }.svg`)
                "
              />
              <p v-if="x.recruitStatus == '1'" class="tag c1">진행 중</p>
              <p v-else-if="x.recruitStatus == '2'" class="tag c2">발주</p>
              <p v-else-if="x.recruitStatus == '3'" class="tag c3">마감</p>
            </div>
            <p
              class="order-title"
              v-html="userId ? x.styleName : masking(x.styleName)"
            ></p>
            <p class="order-catg" v-html="x.itemCatgName"></p>

            <div class="order-info">
              <p>기한 : {{ x.clientOrderDate }} ~ {{ x.deliveryDate }}</p>
              <p>수량 : {{ x.quantity }} pcs</p>
            </div>
          </div>
        </div>

        <div class="order-content-right">
          <div class="order-content-right-info">
            <div v-if="x.workProcess == '01031'" class="tag bongjae">
              <p></p>
              봉제
            </div>
            <div
              v-if="
                x.workProcess == '01033' &&
                (x.workType == '1' || x.workType == '3')
              "
              class="tag pattern"
            >
              <p></p>
              패턴
            </div>
            <div
              v-if="
                x.workProcess == '01033' &&
                (x.workType == '2' || x.workType == '3')
              "
              class="tag sample"
            >
              <p></p>
              샘플
            </div>
          </div>
          <div class="order-content-right-info">
            <div class="count">
              참여 공장
              <p>{{ x.partCount }}</p>
            </div>
          </div>
          <div class="order-content-right-info">
            <p class="date">{{ x.alarmStartDate }} 등록</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "designOrderMListView",
  props: {
    listData: {
      type: Object,
      description: "Card data",
    },
  },
  data() {
    return {
      d: {},
    };
  },
  mounted() {
    console.log(this.listData);
    // this.listData.forEach((e) => {
    //   console.log(e.imgList.length);
    // });
    this.d = this.listData;
    this.d.isLike = this.d.like;
  },
  methods: {
    goDetail(designOrderNo, styleNameValue) {
      console.log();
      if (!sessionStorage.getItem("userId")) {
        this.openLogin();
      } else {
        this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
        this.$store.state.meta.detailTitle = styleNameValue;
      }
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    bookmark(index, designOrderNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-list {
  flex: 1;
  padding-top: 40px;
  // padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .order {
    border: 1px solid $neutral-10;
    border-radius: 8px;
    cursor: pointer;
    background-color: $neutral-0;
    padding: 16px 20px;
    position: relative;
    background-image: url("@/assets/bg/bg-designorder-list.svg");
    background-size: 186px;
    background-repeat: no-repeat;
    background-position: right bottom;
    &.active {
      border-color: $primary-50;
    }
    &-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &-body {
        display: flex;
        gap: 40px;
        .thumbnail {
          width: 130px;
          height: 130px;
          border: 1px solid $neutral-10;
          img {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }
        &-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .bookmark {
            width: 20px;
            height: 20px;
            // margin-left: 20px;
          }
          p.tag {
            text-align: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $neutral-80;
          }

          .order-title {
            margin-top: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $neutral-80;
          }
          .order-catg {
            margin-top: 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $neutral-80;
          }
          .order-info {
            display: flex;
            gap: 80px;
            margin-top: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $neutral-60;
          }
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        &-info {
          display: flex;
          justify-content: flex-end;
          div.tag {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            &.bongjae {
              color: #a740e2;
            }
            &.pattern {
              color: #359cef;
            }
            &.sample {
              color: #26c296;
            }
          }
          .count {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: $neutral-60;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            gap: 12px;
            p {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .date {
            color: $neutral-40;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            position: absolute;
            right: 20px;
            bottom: 20px;
            color: $neutral-40;
          }
        }
      }
      .factory-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $primary-60;
      }
    }
  }
}
.no-order-list {
  margin-top: 40px;
  margin-bottom: 80px;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
</style>
