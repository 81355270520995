<template>
  <div class="calculate-dtl" :class="isMobile == 'true' ? 'mt-40' : ''">
    <div class="calculate-dtl-top">
      <p class="title">
        {{ calculateData.invoiceeCorpName }}
      </p>
      <p class="status">
        {{ calculateData.issueStatus == "1" ? "발행" : "미 발행" }}
      </p>
    </div>
    <div class="calculate-dtl-content">
      <div class="row">
        <p class="name fs15">결제 금액</p>
        <p class="info fs15">{{ comma(calculateData.paymentAmount) }}</p>
      </div>
      <div class="row">
        <p class="name">결제일</p>
        <p class="info">{{ calculateData.paymentDate }}</p>
      </div>
      <div class="row">
        <p class="name">증빙 형태</p>
        <p v-if="calculateData.calculateType == '1'" class="info">
          세금 계산서
        </p>
        <p v-if="calculateData.calculateType == '2'" class="info">
          현금 영수증
        </p>
      </div>
      <div class="row">
        <p class="name">정산 예정일</p>
        <p class="info">{{ calculateData.calculateDay }}</p>
      </div>
    </div>

    <div
      class="calculate-dtl-btn"
      @click="openCalc(calculateData.calculateType)"
    >
      <p v-if="calculateData.calculateType == '1'">세금계산서 발행</p>
      <p v-if="calculateData.calculateType == '2'">현금영수증 발행</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculateCom",
  props: {
    calculateData: {
      type: String,
    },
  },
  mounted() {
    // const calculateNo = this.$route.params.calculateNo
    //   ? this.$route.params.calculateNo
    //   : this.calculateNo;
    // this.$API.designOrder
    //   .getCalculateDetail(this.userId, calculateNo)
    //   .then((res) => {
    //     console.log(res);
    //     this.calculateData = res.data.body;
    //     this.step = 0;
    //   });
  },
  data() {
    return {
      detailData: {},
      step: 0,
      inputDeactive: true,
      agree: true,
      showModal: false,
      showModalId: "",
      isShowStyleAdd: true,
      isModify: false,
      isStyleError: false,
      isOrderError: false,
      isSignError: false,
      isProgress: true,
      koreanTime: null,
      userId: this.$route.query.userId || sessionStorage.getItem("userId"),
      compType:
        this.$route.query.compType || sessionStorage.getItem("compType"),
      signImg: {},
      errorData: {},
      deliveryCount: 1,
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  methods: {
    openCalc(calculateType) {
      this.$emit("openCalc", calculateType);
    },
  },
};
</script>

<style lang="scss" scoped>
.calculate-dtl {
  padding: 0 28px;
  &-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid $primary-20;
    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
    }
    .status {
      color: $neutral-30;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  }
  &-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .row {
      position: relative;
      display: flex;
      .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
      .info {
        position: absolute;
        left: 150px;
        color: $neutral-60;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
      .fs15 {
        font-size: 15px;
      }
    }
  }
  &-btn {
    cursor: pointer;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background: $primary-50;
    color: $neutral-0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  &-bottom {
    display: flex;
    flex-direction: column;
    border: 1px solid $neutral-30;
    .back-gary {
      background: $neutral-3;
    }
    .table-top {
      display: flex;
      width: 100%;
      &-info {
        flex: 1;
        .tax-name {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          background: $neutral-3;
          height: 36px;
          border: 1px solid $neutral-30;
        }
        .auth-num {
          text-align: center;
          display: flex;
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          height: 36px;
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            flex: 3;
            background: $neutral-0;
            border: 1px solid $neutral-30;
            &:first-child {
              flex: 1;
              font-weight: 400;
              background: $neutral-3;
            }
          }
        }
        .info-wrap {
          flex: 1;
        }
      }
    }
  }
}
</style>
