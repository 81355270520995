<template>
  <div class="popup-add">
    <div class="popup-background" @click="closePopup()"></div>
    <div class="popup-add-wrap">
      <SvgImg
        class="close"
        :size="20"
        :d="$store.state.meta.svg.closePopup"
        :color="$store.state.meta.svg.color.neutral0"
        @click="closePopup()"
      />
      <div class="popup-add-wrap-top">
        <div class="title">
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.matchingAdd"
            :color="$store.state.meta.svg.color.neutral0"
          />
          <p>매칭 등록</p>
        </div>
      </div>
      <div class="popup-add-wrap-body">
        <div class="load" v-if="step == 0">
          <div class="load-title">· 이 전 매칭 등록내용을 불러올까요!?</div>
          <div class="load-list">
            <div class="load-list-head">
              <p class="column">선택</p>
              <p class="column">작업 공정</p>
              <p class="column">스타일 명</p>
              <p class="column">품목</p>
              <p class="column">등록일</p>
            </div>
            <div
              class="load-list-body"
              v-for="(loadDatas, i) in loadList"
              :key="i"
            >
              <label
                :for="loadDatas.designOrderNo"
                :class="isChecked[i] ? 'active' : ''"
              >
                <div class="column">
                  <input
                    type="radio"
                    :id="loadDatas.designOrderNo"
                    name="loadList"
                    @click="clickChecked(i, loadDatas.designOrderNo)"
                    class="hidden"
                  />
                  <div class="check-box" :class="[{ active: isChecked[i] }]">
                    <p class="svg">
                      <SvgImg
                        v-if="isChecked[i]"
                        :size="16"
                        :d="$store.state.meta.svg.radioOn"
                        :color="$store.state.meta.svg.color.primary50"
                      />
                      <SvgImg
                        v-else
                        :size="16"
                        :d="$store.state.meta.svg.radioOff"
                        :color="$store.state.meta.svg.color.neutral40"
                      />
                    </p>
                  </div>
                </div>
                <p class="column" v-if="loadDatas.workProcess == '01031'">
                  봉제
                </p>
                <p class="column" v-else-if="loadDatas.workProcess == '01033'">
                  패턴/샘플
                </p>
                <p class="column" v-else-if="loadDatas.workProcess == '01034'">
                  자수
                </p>
                <p class="column" v-else-if="loadDatas.workProcess == '01035'">
                  나염/전사
                </p>
                <p class="column">{{ loadDatas.styleName }}</p>
                <div
                  class="column item-catg"
                  v-if="loadDatas.itemList?.length > 0 && loadDatas.itemCatgCd"
                >
                  <p v-for="(y, j) in loadDatas.itemList" :key="j">
                    <SvgImg
                      v-if="j > 0"
                      :size="20"
                      :d="$store.state.meta.svg.rightArrowBig"
                      :color="$store.state.meta.svg.color.neutral30"
                    />
                    <span
                      :style="
                        j == 0
                          ? 'font-weight: 500;'
                          : loadDatas.itemList.length - 1 == j
                          ? 'color: #F49301;'
                          : ''
                      "
                      >{{ y }}</span
                    >
                  </p>
                </div>
                <div
                  class="column item-catg"
                  v-else-if="
                    loadDatas.itemList?.length > 0 && !loadDatas.itemCatgCd
                  "
                >
                  <p v-for="(y, j) in loadDatas.itemList" :key="j">
                    <span>{{ y }}</span>
                  </p>
                </div>
                <div class="column item-catg" v-else-if="loadDatas.catg">
                  <p>{{ loadDatas.catg }}</p>
                  <img
                    src="@/assets/icon/ic-chevron-right-60.svg"
                    v-if="loadDatas.fabric"
                  />
                  <p v-if="loadDatas.fabric">{{ loadDatas.fabric }}</p>
                </div>
                <div
                  class="column item-catg"
                  v-else-if="loadDatas.itemCatgName"
                >
                  <p>{{ loadDatas.itemCatgName }}</p>
                </div>
                <!-- <p>{{ loadDatas.item }}</p> -->
                <p class="column">{{ loadDatas.alarmStartDate }}</p>
              </label>
            </div>
          </div>
        </div>
        <div class="step1" v-if="step == 1">
          <div class="work-process">
            <div class="work-process-name">· 어떤 공장을 찾으시나요!?</div>
            <div class="work-process-items">
              <!-- paymentType -->
              <div
                class="item"
                :class="[{ active: wp.isActive }]"
                @click="selectWorkProcess(wp)"
                v-for="(wp, i) in workProcess"
                :key="i"
              >
                <img :src="require(`@/assets/img/${wp.imgSrc}`)" />
                <p>{{ wp.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="step-wrap" v-else-if="step > 1">
          <div class="step-wrap-left">
            <div class="step" v-for="(sn, i) in stepNavi" :key="i">
              <p class="step-circle" :class="[{ active: sn.isActive }]">
                {{ sn.cd }}
              </p>
              <p class="step-name" :class="[{ active: sn.isActive }]">
                {{ sn.name }}
              </p>
            </div>
          </div>
          <div class="step-wrap-right">
            <div class="step2" v-if="step == 2">
              <div class="input-row">
                <p class="name">· 작업 명</p>
                <input
                  class="input"
                  type="text"
                  v-model="styleName"
                  placeholder="작업명 또는 제목을 입력해주세요."
                />
              </div>
              <div class="input-row">
                <p class="name">· 스타일</p>
                <SelectBoxRadius
                  :height="228"
                  :data="selectStyle"
                  :filterType="'style'"
                  @selectData="selectData"
                  :selectData="styleType"
                  :placeholderTxt="'스타일을 선택해주세요.'"
                />
              </div>
              <div class="input-row mt-12">
                <p class="name">· 품목 명</p>
                <div>
                  <input
                    class="input"
                    :value="itemName"
                    placeholder="품목 명을 입력해주세요. Ex)티셔츠"
                    @input="intervalSearchFactory"
                    @focusin="intervalSearchFactory"
                  />
                  <div class="item-panel" id="panel" v-show="isPanel">
                    <div class="panel">
                      <div
                        class="panel-wrap"
                        v-for="(keyword, i) in selectedWordList"
                        :key="i"
                      >
                        <div
                          class="panel-wrap-body"
                          v-if="i > 0"
                          @click="selectKeyword(keyword)"
                        >
                          <p>
                            {{ keyword.catgName1 }}
                          </p>
                          <SvgImg
                            :size="16"
                            :d="$store.state.meta.svg.rightArrow"
                            :color="$store.state.meta.svg.color.neutral40"
                          />
                          <p>
                            {{ keyword.catgName2 }}
                          </p>
                          <SvgImg
                            :size="16"
                            :d="$store.state.meta.svg.rightArrow"
                            :color="$store.state.meta.svg.color.neutral40"
                          />
                          <p>
                            {{ keyword.catgName3 }}
                          </p>
                          <SvgImg
                            :size="16"
                            :d="$store.state.meta.svg.rightArrow"
                            :color="$store.state.meta.svg.color.neutral40"
                          />
                          <p>
                            {{ keyword.catgName }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="panel-bottom">
                      <p @click="this.isPanel = false">
                        패널 닫기
                        <SvgImg
                          :size="16"
                          :d="$store.state.meta.svg.closeIcon"
                          :color="$store.state.meta.svg.color.neutral40"
                          class="pointer"
                        />
                      </p>
                    </div>
                  </div>
                  <div class="item-selected">
                    <div class="selected-item">
                      <!-- <div
                        class="selected-item-wrap"
                        v-for="(result, i) in selectedList"
                        :key="i"
                      >
                        <p v-html="result.name"></p>
                        <SvgImg
                          :size="16"
                          :d="$store.state.meta.svg.closeIcon"
                          :color="$store.state.meta.svg.color.primary50"
                          class="pointer"
                          @click="toggleIsActive(result.listName, result.cd)"
                        />
                      </div> -->
                      <div
                        class="selectcategory"
                        v-if="selectCatgCdList.catgName"
                      >
                        <div class="selectcategory-list">
                          <div class="catg-list-wrap">
                            <div class="catg-title">
                              <p>
                                {{ selectCatgCdList.catgName1 }}
                              </p>
                              <SvgImg
                                :size="20"
                                :d="$store.state.meta.svg.rightArrowBig"
                                :color="$store.state.meta.svg.color.neutral30"
                              />
                              <p>
                                {{ selectCatgCdList.catgName2 }}
                              </p>
                              <SvgImg
                                :size="20"
                                :d="$store.state.meta.svg.rightArrowBig"
                                :color="$store.state.meta.svg.color.neutral30"
                              />
                              <p v-if="selectCatgCdList.catgName3">
                                {{ selectCatgCdList.catgName3 }}
                              </p>
                              <SvgImg
                                v-if="selectCatgCdList.catgName3"
                                :size="20"
                                :d="$store.state.meta.svg.rightArrowBig"
                                :color="$store.state.meta.svg.color.neutral30"
                              />
                              <p>
                                {{ selectCatgCdList.catgName }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clear" @click="selectKeyword()">
                      <p>초기화</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="step3" v-else-if="step == 3">
              <div class="input-wrap">
                <div class="input-row" v-if="workProcessCd == '01031'">
                  <p class="name">· 총 수량</p>
                  <input
                    class="input"
                    type="number"
                    v-model="apiData.quantity"
                    placeholder="총 수량을 입력해주세요. (숫자만 기입)"
                  />
                </div>
                <div class="input-row" v-if="workProcessCd == '01033'">
                  <p class="name">· 작업 <span>(중복 가능)</span></p>
                  <div class="btns">
                    <p
                      @click="isPattern = !isPattern"
                      :class="isPattern ? 'active' : ''"
                    >
                      패턴
                    </p>
                    <p
                      @click="isSample = !isSample"
                      :class="isSample ? 'active' : ''"
                    >
                      샘플
                    </p>
                  </div>
                </div>
                <div
                  class="input-row"
                  v-if="workProcessCd == '01034' || workProcessCd == '01035'"
                >
                  <p class="name">· 작업 <span>(중복 가능)</span></p>
                  <div class="btns">
                    <p
                      @click="isProduct = !isProduct"
                      :class="isProduct ? 'active' : ''"
                    >
                      완제품
                    </p>
                    <p
                      @click="isCutting = !isCutting"
                      :class="isCutting ? 'active' : ''"
                    >
                      재단물
                    </p>
                  </div>
                </div>
                <div class="input-row">
                  <p class="name">· 납품 희망일</p>
                  <div class="unit">
                    <div class="period">
                      <input
                        type="date"
                        class="period-date pointer"
                        v-model="apiData.startDate"
                        max="9999-12-31"
                      />
                      ~
                      <input
                        type="date"
                        class="period-date pointer"
                        v-model="apiData.endDate"
                        max="9999-12-31"
                      />
                    </div>
                    <p>이내</p>
                  </div>
                </div>
                <div class="input-row">
                  <p class="name">· 매칭 태그 <span>(최대 6개)</span></p>
                  <input
                    class="input"
                    type="text"
                    placeholder="아이템 명이나 특징, 중요 사양 등의 조건 단어를 입력해주세요."
                    v-model="tagInputValue"
                    @keyup.enter="addTag()"
                    @keyup.space="addTag()"
                    @focusout="addTag()"
                    :disabled="tag.length > 5"
                  />
                </div>
                <div class="tag">
                  <div class="tag-wrap">
                    <div class="tag-list">
                      <p v-for="(t, i) in tag" :key="i">
                        {{ t }}
                        <SvgImg
                          class="pointer"
                          @click="removeTag(i)"
                          :size="16"
                          :d="$store.state.meta.svg.closeBox"
                          :color="$store.state.meta.svg.color.primary50"
                        />
                      </p>
                    </div>
                    <div class="reset" @click="tag = []">초기화</div>
                  </div>
                </div>
                <!-- <div class="input-row">
                  <p class="name">· 판매 채널</p>
                  <input
                    class="input"
                    type="text"
                    v-model="apiData.channel"
                    placeholder="판매 채널 또는 용도를 입력해주세요."
                  />
                </div> -->
                <!-- <div class="input-row">
                  <p class="name">· 패턴</p>
                  <div class="onoff flex-start">
                    <p class="onoff-txt" :class="patternFlag ? '' : 'active'">
                      미 보유
                    </p>
                    <div
                      class="onoff-btn"
                      :class="patternFlag ? 'active' : ''"
                      @click="toggleData('patternFlag')"
                    ></div>
                    <p class="onoff-txt" :class="patternFlag ? 'active' : ''">
                      보유
                    </p>
                  </div>
                </div>
                <div class="input-row">
                  <p class="name">· 참고 샘플</p>
                  <div class="onoff flex-start">
                    <p class="onoff-txt" :class="sampleFlag ? '' : 'active'">
                      미 보유
                    </p>
                    <div
                      class="onoff-btn"
                      :class="sampleFlag ? 'active' : ''"
                      @click="toggleData('sampleFlag')"
                    ></div>
                    <p class="onoff-txt" :class="sampleFlag ? 'active' : ''">
                      보유
                    </p>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="step4" v-else-if="step == 4">
              <div class="info-wrap">
                <div class="work-file">
                  <p class="name">
                    · 작업지시서 및 참고 이미지를 첨부해주세요.
                    <span>(최대 12개)</span>
                  </p>
                  <p class="sub-name">
                    <span>※</span>첨부 가능 파일 형식 : jpg, jpeg, png, gif
                  </p>
                  <label
                    class="drop-area"
                    :class="fileNo > 0 ? 'isDragging' : ''"
                    for="imgFile"
                    @dragover.prevent="handleDragOver"
                    @drop.prevent="handleDrop"
                  >
                    <SvgImg
                      :size="20"
                      :d="$store.state.meta.svg.fileupload"
                      :color="$store.state.meta.svg.color.neutral30"
                    />
                    <p>파일 추가 또는 파일을 여기로 드래그</p>
                  </label>
                  <input
                    v-if="!(fileNo > 0)"
                    class="hidden"
                    type="file"
                    id="imgFile"
                    accept=".gif, .jpg, .jpeg, .png"
                    @change="fileUpload('34', 12, $event)"
                    multiple
                  />
                  <div class="imgs" v-if="apiData.imgList?.length > 0">
                    <div
                      class="imgs-img view pointer"
                      v-for="(y, j) in apiData.imgList"
                      :key="j"
                      @click="showImg(y.fileUrl, y.sourceFileName)"
                    >
                      <img :src="y.fileUrl" />
                      <div
                        class="imgs-img-pop flex"
                        @click.stop="removeImg(y.fileNo)"
                      >
                        <img src="@/assets/icon/ic-x-square.svg" />
                      </div>
                    </div>
                  </div>
                  <div v-else class="mt-12"></div>
                </div>
              </div>
              <!-- <div class="input-wrap">
                <div class="input-row mt-12 mb-12">
                  <p class="name">· 결제 방법</p>
                  <div class="btns">
                    <p
                      @click="paymentCd = '1'"
                      :class="[{ active: paymentCd == '1' }]"
                    >
                      계좌이체
                    </p>
                    <p
                      @click="paymentCd = '2'"
                      :class="[{ active: paymentCd == '2' }]"
                    >
                      신용카드
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="step5" v-else-if="step == 5">
              <div class="info-wrap">
                <p class="name">· 부가 설명</p>
                <textarea
                  class="textarea"
                  v-model="apiData.workDesc"
                  placeholder="디자인이나 디테일, 컬러 수, 사이즈 수, 납기, 포장 방법, 선호 지역, 타겟 단가 등
기타 참고 사항과 설명을 입력해주세요.

공장에서 적극적인 참여 가능하도록 상세하게 작성을 부탁드립니다."
                ></textarea>
              </div>
              <div class="info-wrap">
                <p class="name">· 이용 약관 <span>(필수)</span></p>
                <div class="mt-4">
                  <CheckBox
                    :name="'제출하신 매칭 요청에 응답하기 위한 목적으로 요청자의 정보 (성명, 휴대 전화번호, 소개글 등)을 공장에게 제공하는 것에 동의합니다.'"
                    :checked="agree[0]"
                    :type="'radio'"
                    :hoverOff="true"
                    @click="agree[0] = !agree[0]"
                  />
                  <CheckBox
                    class="mt-12"
                    :name="'오슬은 정보에 따른 ‘매칭’ 만을 진행하며, 요청자와 생산자 간의 개별 거래 관계에 일체 관여하지 않습니다. 이에 서비스 품질 및 거래에 대한 책임은 요청자와 생산자에게 있음을 확인하였습니다.'"
                    :checked="agree[1]"
                    :type="'radio'"
                    :hoverOff="true"
                    @click="agree[1] = !agree[1]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-add-wrap-bottom">
        <div class="popup-add-wrap-bottom-btns">
          <p v-if="step == 0" class="add-btn new" @click="goNext()">
            새로 등록
          </p>
          <p v-if="step == 0" class="add-btn next" @click="goLoad()">
            불러오기
          </p>
          <p v-if="step > 1" class="add-btn before" @click="goBefore()">이전</p>
          <p v-if="step < 5 && step > 0" class="add-btn next" @click="goNext()">
            다음
          </p>
          <p
            v-if="step == 5 && !isRegist"
            class="add-btn next"
            @click="designOrderAdd()"
          >
            등록
          </p>
          <p v-else-if="step == 5 && isRegist" class="add-btn new">등록 중..</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBox from "../CheckBox.vue";
import SelectBoxRadius from "../SelectBoxRadius.vue";
import SvgImg from "../SvgImg.vue";

export default {
  name: "DesignOrderAdd",
  data() {
    return {
      isPopup: true,
      isCatg: false,
      isPanel: false,
      isSample: false,
      isPattern: false,
      isCutting: false,
      isProduct: false,
      isRegist: false,
      isChecked: [],
      agree: [true, true],
      step: 0,
      userId: sessionStorage.getItem("userId"),
      stepNavi: [
        { cd: 1, name: "스타일/품목", isActive: false },
        { cd: 2, name: "작업 조건", isActive: false },
        { cd: 3, name: "파일 첨부", isActive: false },
        { cd: 4, name: "부가 설명", isActive: false },
      ],
      workProcess: [
        {
          index: 0,
          cd: "01031",
          name: "봉제",
          imgSrc: "img-matching-regist-sewing.png",
          isActive: false,
        },
        {
          index: 0,
          cd: "01033",
          name: "패턴/샘플",
          imgSrc: "img-matching-regist-pattern.png",
          isActive: false,
        },
        {
          index: 0,
          cd: "01034",
          name: "자수",
          imgSrc: "img-matching-regist-jasu.png",
          isActive: false,
        },
        {
          index: 0,
          cd: "01035",
          name: "나염/전사",
          imgSrc: "img-matching-regist-print.png",
          isActive: false,
        },
      ],
      selectStyle: [
        { cd: 1, name: "캐주얼웨어", isActive: false },
        { cd: 2, name: "스포츠웨어", isActive: false },
        { cd: 3, name: "스트릿웨어", isActive: false },
        { cd: 4, name: "짐웨어/에슬레저", isActive: false },
        { cd: 5, name: "골프웨어", isActive: false },
        { cd: 6, name: "아웃도어", isActive: false },
        { cd: 7, name: "홈웨어", isActive: false },
        { cd: 8, name: "언더웨어", isActive: false },
        { cd: 9, name: "근무복/유니폼", isActive: false },
        { cd: 10, name: "단체복/판촉", isActive: false },
        { cd: 11, name: "굿즈/PB", isActive: false },
        { cd: 12, name: "친환경", isActive: false },
        { cd: 13, name: "기타", isActive: false },
      ],
      errorData: {
        styleName: false,
        quantity: false,
        imgList: false,
        deliveryDate: false,
      },
      apiData: {},
      designOrderNo: "",
      workProcessCd: "",
      catgCd: "",
      styleType: "",
      styleName: "",
      itemName: "",
      imgList: [],
      fabric: "",
      categoryList: [],
      printList: [],
      jasuList: [],
      categoryData: [],
      selectCode: [],
      selectCatgCdList: {},
      patternFlag: "",
      sampleFlag: "",
      deliveryDate: "",
      paymentCd: "",
      tagInputValue: "",
      tag: [],
      selectedList: [],
      selectedWordList: [],
      timer: null,
      loadList: [],
      workDesc: "",
    };
  },
  mounted() {
    this.$API.mypage.catg("", 1).then((res) => {
      this.categoryList = res.data.body;
      console.log(this.categoryList);
    });

    let filters = {};
    filters.listFlag = 2;
    this.$API.designOrder.getLoadList(this.userId, filters).then((res) => {
      this.loadList = res.data.body;
      console.log(this.loadList);
      if (this.loadList.length > 0) {
        this.loadList.forEach((e, i) => {
          this.isChecked[i] = false;
        });
      } else {
        this.step = 1;
      }
    });

    // 전역 클릭 이벤트 핸들러 등록
    document.addEventListener("click", this.handleOutsideClick);
  },
  unmounted() {
    // 컴포넌트 파기 전 전역 클릭 이벤트 핸들러 제거
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(event) {
      if (this.step == 2) {
        console.log(event);
        // item-panel isPanel
        // 클릭된 요소가 myDiv인지 확인
        var myDiv = document.getElementById("panel");
        console.log(myDiv);
        var isClickInsideDiv = myDiv.contains(event.target);

        console.log(isClickInsideDiv);
        // 클릭된 요소가 myDiv 이외의 요소인 경우
        if (!isClickInsideDiv) {
          this.isPanel = false;
        }
      }
    },
    clickChecked(index, designOrderNo) {
      console.log("clickChecked");
      console.log(index);
      console.log(designOrderNo);
      this.isChecked.forEach((e, i) => {
        this.isChecked[i] = false;
      });
      this.isChecked[index] = true;
      this.designOrderNo = designOrderNo;
    },
    selectKeyword(data) {
      console.log(data);
      if (data) {
        this.itemName = "";
        if (data.keyword) {
          this.selectedList.push({
            cd: 1,
            name: data.keyword,
            listName: "keyword",
          });
        } else {
          this.selectCatgCdList = {
            catgName: data.catgName,
            catgName1: data.catgName1,
            catgName2: data.catgName2,
            catgName3: data.catgName3,
          };
          this.catgCd = data.catgCd;
        }
        this.isCatg = true;
      } else {
        this.selectCatgCdList = "";
        this.catgCd = "";
        this.isCatg = false;
      }
      this.isPanel = false;
    },
    intervalSearchFactory(e) {
      console.log(e);
      this.itemName = e.target.value;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.selectedWordList = [];
        if (this.itemName.length > 0) {
          this.isPanel = true;
          this.selectedWordList.push({ keyword: this.itemName });
          this.$API.search.keyword(this.itemName).then((res) => {
            res.data.body.forEach((e) => {
              this.selectedWordList.push(e);
            });
          });
        } else {
          this.isPanel = false;
        }
      }, 100);
    },
    handleDrop(event) {
      console.log(1);
      // if (!(this.d.profileFileNo > 0)) {
      //   const files = event.dataTransfer.files;
      //   this.fileUpload('34', 12, event)
      // }
      this.fileUpload("34", 6, event);
    },
    fileUpload(fileStatus, maxLength, event) {
      this.errorData.imgList = false;
      let files = [];
      let c = maxLength - this.apiData.imgList.length;

      let msg = "";

      let fileList;
      if (event.dataTransfer?.files.length > 0) {
        fileList = event.dataTransfer.files;
      } else if (event.target) {
        fileList = event.target.files;
      }
      Array.from(fileList).forEach((e) => {
        if (
          (e.name.indexOf(".ai") == -1 && e.size >= 1024000 * 10) ||
          (e.name.indexOf(".ai") != -1 && e.size >= 1024000 * 100)
        ) {
          // 파일 용량 제한
          msg = "파일 용량이 초과되었습니다.";
          if (msg) this.showToast(msg);
          return;
        }
        if (c < 1) {
          msg = "최대 개수를 초과했습니다.";
          if (msg) this.showToast(msg);
          return;
        }
        files.push(e);
        c = c - 1;
      });

      this.$API.comm
        .fileUploadMultiple(
          sessionStorage.getItem("userId"),
          this.apiData.designOrderNo,
          fileStatus,
          "3",
          files
        )
        .then((res) => {
          res.data.body.forEach((e, i) => {
            let d = e["imgFile" + (i + 1)]; // api 수정시 변경
            this.apiData.imgList.push({
              fileNo: d.fileNo,
              fileSize: d.fileSize,
              fileStatus: d.fileStatus,
              fileType: d.fileType,
              fileUrl: d.previewUrl,
              sourceFileName: d.sourceFileName,
            });
            document.querySelector(".swiper-wrapper").style.height = 100 + "%";
          });
        });
    },
    removeImg(fileNo) {
      this.apiData.imgList = this.apiData.imgList.filter(
        (e) => e.fileNo != fileNo
      );
      document.querySelector(".swiper-wrapper").style.height = 100 + "%";
    },
    addTag() {
      if (this.tagInputValue) {
        this.tag.push(this.tagInputValue.trim());
        this.resetTagInputValue();
      }
    },
    removeTag(i) {
      this.tag.splice(i, 1);
    },
    resetTagInputValue() {
      this.tagInputValue = "";
    },
    toggleData(key, value) {
      if (value) {
        this[key] = value;
      } else {
        this[key] = this[key] ? 0 : 1;
      }
    },
    toggleIsActive(datas, index, type) {
      if (type == "jasu") {
        this.jasuList.forEach((e) => {
          if (e.commCd == datas.commCd) {
            e.isActive = true;
          } else {
            e.isActive = false;
          }
        });
      } else if (type == "print") {
        this.printList.forEach((e) => {
          if (e.commCd == datas.commCd) {
            e.isActive = true;
          } else {
            e.isActive = false;
          }
        });
      }
    },
    goLoad() {
      //불러오기 셋팅
      console.log(this.loadList);
      console.log(this.designOrderNo);
      const loadData = this.loadList.filter(
        (e) => e.designOrderNo == this.designOrderNo
      )[0];
      console.log(loadData);
      this.workProcessCd = loadData.workProcess;
      this.workProcess.filter(
        (e) => e.cd == loadData.workProcess
      )[0].isActive = true;
      if (loadData.workProcess == "01033") {
        if (loadData.sampleFlag == "1") {
          this.isSample = true;
        }
        if (loadData.pattternFlag == "1") {
          this.isPattern = true;
        }
      }
      if (loadData.workProcess == "01034" || loadData.workProcess == "01035") {
        if (loadData.cuttingFlag == "1") {
          this.isCutting = true;
        }
        if (loadData.productFlag == "1") {
          this.isProduct = true;
        }
      }
      this.styleName = loadData.styleName;
      this.styleType = loadData.styleType;

      //카테고리 셋팅
      let catgName = "";
      let catgName1 = "";
      let catgName2 = "";
      let catgName3 = "";
      loadData.itemList.forEach((e, i) => {
        if (i == 0) catgName = e;
        if (i == 1) catgName1 = e;
        if (i == 2) catgName2 = e;
        if (i == 3) catgName3 = e;
      });
      this.selectCatgCdList = {
        catgName: catgName,
        catgName1: catgName1,
        catgName2: catgName2,
        catgName3: catgName3,
      };
      this.catgCd = loadData.itemCatgCd;
      if (this.catgCd) {
        this.isCatg = true;
      }

      //태그 셋팅
      if (loadData.hashTag) {
        const hashTag = loadData.hashTag.split(",");
        hashTag.forEach((e) => {
          this.tag.push(e.trim());
        });
      }
      this.step = 1;
    },
    goBefore() {
      this.step--;
      this.stepNavi.map((e) => (e.isActive = false));
      this.stepNavi.filter((e) => e.cd == this.step - 1)[0].isActive = true;
    },
    goNext() {
      console.log(this.apiData);
      if (this.step == 0) {
        this.designOrderNo = "";
      }
      if (this.step == 1 && this.workProcessCd == "") {
        this.showToast("작업 공정을 선택해주세요.");
      } else if (this.step == 2 && this.styleType == "") {
        this.showToast("스타일을 선택해주세요.");
      } else if (this.step == 2 && !this.isCatg) {
        this.showToast("품목을 선택해주세요.");
      } else if (this.step == 2 && this.apiData.styleName == "") {
        this.showToast("작업 명을 입력해주세요.");
      } else if (this.step == 3 && this.apiData.quantity == "") {
        this.showToast("총 수량을 입력해주세요.");
      } else {
        if (this.step == 2) {
          console.log(this.selectCatgCdList);
          const item =
            this.selectCatgCdList.catgName +
            "," +
            this.selectCatgCdList.catgName1 +
            "," +
            this.selectCatgCdList.catgName2 +
            "," +
            this.selectCatgCdList.catgName3;
          this.$API.designOrder
            .registV3(
              this.userId,
              this.catgCd,
              this.workProcessCd,
              this.styleName,
              this.styleType,
              item
            )
            .then(() => {
              this.$API.designOrder.load(this.userId).then((res) => {
                this.apiData = res.data.body;
                console.log(this.apiData);

                //저장 데이터 셋팅
                const loadData = this.loadList.filter(
                  (e) => e.designOrderNo == this.designOrderNo
                )[0];
                if (loadData) {
                  this.apiData.quantity = loadData.quantity;
                  console.log(loadData.deliveryDate);
                  console.log(loadData.startDate);
                  console.log(loadData.endDate);
                  if (loadData.deliveryDate) {
                    this.apiData.endDate = loadData.deliveryDate;
                  } else {
                    this.apiData.startDate = loadData.startDate;
                    this.apiData.endDate = loadData.endDate;
                  }
                  console.log(loadData.imgList);
                  this.apiData.workDesc = loadData.workDesc;
                  if (loadData.imgList) {
                    // this.apiData.imgList = loadData.imgList;
                    let fileNoList = [];
                    loadData.imgList.forEach((e, i) => {
                      // this.$API.comm
                      //   .fileDataUpload(e, sessionStorage.getItem("userId"))
                      //   .then((res) => {
                      // const fileData = res.data.body;
                      console.log(e);
                      // let d = fileData["imgFile" + (i + 1)]; // api 수정시 변경
                      this.apiData.imgList.push({
                        fileNo: e.fileNo,
                        fileSize: e.fileSize,
                        fileStatus: e.fileStatus,
                        fileType: e.fileType,
                        fileUrl: e.fileUrl,
                        sourceFileName: e.sourceFileName,
                      });
                      console.log(this.apiData.imgList);
                      // document.querySelector(
                      //   ".swiper-wrapper"
                      // ).style.height = 100 + "%";
                      fileNoList.push(e.fileNo);
                      console.log(i);
                      console.log(loadData.imgList.length);
                      //마지막 인서트 후 호출
                      console.log(fileNoList.imgList?.length);
                      console.log(fileNoList.imgList);
                      console.log(this.apiData.imgList.length);
                    });
                    this.$nextTick(() => {
                      // Vue가 DOM 업데이트를 처리한 후 실행될 로직
                      // if (
                      //   loadData.imgList.length == this.apiData.imgList.length
                      // ) {
                      console.log("fileNoList");
                      console.log(fileNoList);
                      this.$API.comm
                        .fileDataUploadMultiple(
                          sessionStorage.getItem("userId"),
                          this.apiData.designOrderNo,
                          "34",
                          "3",
                          fileNoList
                        )
                        .then((res) => {
                          console.log(res);
                        });
                      // }
                    });
                    // });
                  }
                }
              });
            });
        }
        this.step++;
        this.stepNavi.map((e) => (e.isActive = false));
        this.stepNavi.filter((e) => e.cd == this.step - 1)[0].isActive = true;
      }
    },
    designOrderAdd() {
      console.log(this.apiData.imgList);
      this.isRegist = true;
      if (this.apiData.imgList.length > 0) {
        if (!this.agree[0] || !this.agree[1]) {
          this.showToast("이용 약관에 대한 동의가 필요합니다.");
        } else {
          this.apiData.hashTag = this.tag;
          this.apiData.styleType = this.styleType;
          if (this.isPattern && this.isSample) {
            this.apiData.workType = "3";
          } else if (this.isPattern) {
            this.apiData.workType = "1";
          } else if (this.isSample) {
            this.apiData.workType = "2";
          }
          this.apiData.cuttingFlag = this.isCutting ? 1 : 0;
          this.apiData.productFlag = this.isProduct ? 1 : 0;
          this.apiData.itemCatgCd =
            this.selectCatgCdList.refBfCatg + this.selectCatgCdList.catgCd;
          this.$API.designOrder.modify(this.apiData).then(() => {
            this.$API.designOrder
              .registFinal(this.userId, this.apiData.designOrderNo)
              .then((res) => {
                if (res.data.resultCode == "10000") {
                  // location.reload();
                  // this.closePopup();
                  this.$emit("matchingFin", true);
                } else {
                  this.showToast(true, res.data.resultMsg);
                }
              });
          });
        }
      } else {
        this.showToast("작업지시서 및 참고 이미지를 첨부해주세요.");
      }
    },
    selectWorkProcess(datas) {
      this.workProcess.map((e) => {
        e.isActive = false;
      });
      this.workProcess.filter((e) => e.cd == datas.cd)[0].isActive = true;
      this.workProcessCd = datas.cd;
    },
    selectData(datas, type) {
      console.log(datas);
      console.log(type);
      if (type == "style") {
        this.styleType = datas.name;
      } else if (type == "catg") {
        this.categoryList.map((e) => (e.isActive = false));
        this.categoryList.filter(
          (e) => e.catgCd == datas.catgCd
        )[0].isActive = true;
        this.fabric = datas.catgName;
        if (datas.catgDepth == "1") {
          this.selectBoxCategory1(datas);
        }
      }
    },
    blockTabKey(event) {
      if (event.key === "Tab") {
        event.preventDefault();
      }
    },
    closePopup() {
      console.log(1111);
      this.$emit("matchingPopup", false);
    },
  },
  components: { SvgImg, SelectBoxRadius },
};
</script>

<style lang="scss" scoped>
.popup-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 500;
}
.popup-add {
  &-wrap {
    position: fixed;
    z-index: 550;
    width: 100%;
    overflow-y: auto;
    background-color: $neutral-0;
    max-width: 900px;
    max-height: 600px;
    height: calc(100vh - 150px);
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    overflow-y: scroll;
    @media (max-width: 900px) {
      max-height: 100vh;
      height: 100%;
      z-index: 1000;
    }
    @include hidden-scroll();
    .close {
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 24px;
    }
    &-top {
      padding: 0 24px;
      height: 52px;
      background-color: $primary-50;
      display: flex;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        font-weight: 500;
        color: $neutral-0;
      }
    }
    &-body {
      height: 468px;
      display: flex;
      justify-content: center;
      @media (max-width: 900px) {
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 0 0;
        height: calc(100vh - 92px);
      }
      .load {
        padding: 60px 40px 46px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 40px;
        &-title {
          margin-top: 4px;
          font-size: 16px;
          font-weight: 500;
        }
        &-list {
          max-height: 312px;
          border: 1px solid $neutral-10;
          overflow: auto;
          .column:nth-child(1) {
            width: 26px;
            text-align: center;
          }
          .column:nth-child(2) {
            width: 65px;
            text-align: center;
          }
          .column:nth-child(3) {
            width: 169px;
            @include ellipse(2);
          }
          .column:nth-child(4) {
            width: 326px;
          }
          .column:nth-child(5) {
            width: 70px;
            text-align: center;
          }
          &-head {
            padding: 0 32px;
            display: flex;
            align-items: center;
            gap: 40px;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            height: 52px;
          }
          &-body {
            label {
              overflow: hidden;
              cursor: pointer;
              padding: 0 32px;
              display: flex;
              align-items: center;
              gap: 40px;
              font-size: 14px;
              font-weight: 400;
              border-top: 1px solid $neutral-10;
              height: 52px;
              &.active {
                border: 1px solid $primary-50;
              }
              .item-catg {
                display: flex;
                align-items: center;
                gap: 4px;
                font-weight: 400;
                overflow: hidden;
                white-space: nowrap;
                :nth-child(n) {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
      .step1 {
        padding: 100px 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;
        width: 100%;
        height: 100%;
        .work-process {
          display: flex;
          flex-direction: column;
          gap: 40px;
          &-name {
            font-size: 16px;
            font-weight: 500;
            line-height: 140%;
          }
          &-items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 12px;
            .item {
              cursor: pointer;
              position: relative;
              aspect-ratio: 1 / 1; /* 최신 브라우저에서 지원하는 속성 */
              overflow: hidden;
              border: 2px solid transparent;
              border-radius: 4px;
              &:hover {
                filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.04));
              }
              p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $neutral-10;
                font-size: 16px;
                font-weight: 500;
                width: 100%;
                text-align: center;
              }
              img {
                position: absolute;
                width: 100%;
                filter: brightness(50%);
              }
              &.active {
                border: 2px solid $primary-50;
                p {
                  color: $primary-50;
                }
              }
            }
          }
        }
      }
      .step-wrap {
        padding: 44px 0;
        height: 468px;
        display: flex;
        justify-content: center;
        background-image: url("@/assets/img/img-matching-add-bg.png"); /* 배경 이미지 설정 */
        background-repeat: no-repeat; /* 이미지 반복 제거 */
        background-size: 280px 220px; /* 이미지를 컨테이너에 맞게 조정 */
        background-position: 80px 200px; /* 이미지를 가운데 상단에 위치시킴 */
        @media (max-width: 900px) {
          flex-direction: column;
          justify-content: flex-start;
          padding: 20px 0 0;
          height: calc(100vh - 92px);
        }
        &-left {
          padding: 40px;
          width: 220px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 32px;
          border-right: 1px solid $primary-20;
          .step {
            display: flex;
            align-items: center;
            gap: 12px;
            &-circle {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px;
              width: 28px;
              height: 28px;
              border-radius: 32px;
              border: 1px solid $primary-50;
              color: $primary-60;
              font-size: 14px;
              font-weight: 500;
              &.active {
                background: $primary-50;
                color: $neutral-0;
                border: 0;
              }
              &.disabled {
                background: $neutral-0;
                color: $neutral-20;
                border: 1px solid $neutral-20;
              }
            }
            &-name {
              font-size: 14px;
              &.active {
                color: $primary-60;
              }
              &.disabled {
                color: $neutral-20;
              }
            }
          }
        }
        &-right {
          padding: 36px 40px;
          width: 680px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .select-box {
            width: 440px;
            height: 36px;
          }

          .step2 {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .item-panel {
              position: absolute;
              width: 440px;
              border-radius: 0px 0px 4px 4px;
              border-right: 1px solid $neutral-10;
              border-left: 1px solid $neutral-10;
              border-bottom: 1px solid $neutral-10;
              background: $primary-5;
              z-index: 2;
              .panel {
                background: $neutral-0;
                max-height: 235px;
                overflow: auto;
                &-wrap {
                  &-head {
                    cursor: pointer;
                    :hover {
                      background: $primary-5;
                    }
                    p {
                      padding: 8px 12px;
                      color: $primary-60;
                      font-size: 14px;
                      font-weight: 500;
                    }
                  }
                  &-body {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding: 8px 12px;
                    &:hover {
                      background: $primary-5;
                    }
                    p {
                      font-size: 14px;
                      &:last-child {
                        color: $primary-60;
                      }
                    }
                  }
                }
                &-bottom {
                  padding: 8px 12px;
                  background: $neutral-5 !important;
                  display: flex;
                  justify-content: flex-end;
                  p {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: $neutral-60;
                    font-size: 12px;
                    cursor: pointer;
                  }
                }
              }
            }
            .item-selected {
              margin: 20px 0 40px;
              padding: 16px;
              display: flex;
              justify-content: space-between;
              border-radius: 4px;
              border: 1px solid $neutral-10;
              .selected-item {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                &-wrap {
                  display: flex;
                  padding: 4px 8px 4px 12px;
                  align-items: center;
                  gap: 8px;
                  border-radius: 28px;
                  background: $primary-10;
                  p {
                    color: $primary-60;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    span {
                      color: $primary-50;
                      font-size: 16px;
                    }
                  }
                }
              }
              .clear {
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
                color: $primary-60;
              }
            }

            .selectcategory {
              display: flex;
              justify-content: flex-end;
              &-list {
                display: flex;
                // width: 440px;
                .catg-list-wrap {
                  display: flex;
                  flex-direction: column;
                  gap: 12px;
                }
                .catg-title {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  font-size: 14px;
                  font-weight: 500;
                  color: $neutral-80;
                  p {
                    font-size: 14px;
                    font-weight: 400;
                    &:first-child {
                      font-weight: 500;
                    }
                    &:last-child {
                      color: $primary-60;
                    }
                  }
                }
              }
            }
          }
          .step3 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 32px;
            height: 100%;
            .input-wrap {
              display: flex;
              flex-direction: column;
              gap: 32px;
            }
          }
          // .step4 {
          //   display: flex;
          //   flex-direction: column;
          //   .input-wrap {
          //     display: flex;
          //     flex-direction: column;
          //     gap: 20px;
          //   }
          // }
          .step4,
          .step5 {
            display: flex;
            flex-direction: column;
            gap: 40px;
            .info-wrap {
              display: flex;
              flex-direction: column;
              gap: 16px;
              .name {
                font-size: 16px;
                font-weight: 500;
                span {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
              .sub-name {
                display: flex;
                gap: 8px;
                padding: 8px 12px 16px;
                font-size: 14px;
                font-weight: 400;
                color: $neutral-60;
              }

              .imgs {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                &-img {
                  display: flex;
                  flex-direction: row;
                  gap: 4px;
                  width: 90px;
                  height: 90px;
                  border: 1px dashed $neutral-10;
                  position: relative;
                  overflow: hidden;
                  cursor: pointer;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }

                  &.active {
                    border: 1px dashed $neutral-10;
                    // background-color: $neutral-10;
                    border-radius: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: $neutral-30;
                  }
                  &.error {
                    color: $secondary-60;
                    border-color: $secondary-60 !important;
                  }
                  &.view {
                    border: 1px solid $neutral-10;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .img-etc {
                      width: 40px;
                      height: 40px;
                    }
                  }
                  &-pop {
                    position: absolute;
                    top: 1px;
                    right: 1px;
                    width: rem(12px);
                    height: rem(12px);
                  }
                  &-filename {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    min-height: 40px;
                    padding: rem(6px);
                    background-color: rgba(0, 0, 0, 0.4);
                    p {
                      @include ellipse(2);
                      font-size: 12px;
                      color: $neutral-0;
                      text-align: center;
                      word-break: break-all;
                    }
                  }
                }
              }
              .textarea {
                padding: 16px;
                height: 112px;
                border-radius: 4px;
                border: 1px solid $neutral-20;
                font-size: 14px;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                resize: none;
                outline: none;
                &::placeholder {
                  color: $neutral-20;
                }
                &:focus {
                  border-color: $primary-50 !important;
                }
              }
            }
          }

          .input-row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .name {
              font-size: 16px;
              font-weight: 500;
              line-height: 36px;
              span {
                font-size: 12px;
                font-weight: 400;
              }
            }
            .btns {
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              justify-content: flex-start;
              width: 440px;
              p {
                cursor: pointer;
                width: 96px;
                padding: 8px 12px;
                border-radius: 4px;
                border: 1px solid $neutral-20;
                color: $neutral-60;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                &.active {
                  border: 1px solid $primary-50;
                  color: $primary-60;
                }
              }
            }
            .input {
              width: 440px;
              padding: 8px 12px;
              border-radius: 4px;
              border-bottom: 1px solid $neutral-20;
              border-left: 1px solid $neutral-20;
              background: $neutral-0;
              font-size: 14px;
              font-weight: 400;
              &:focus {
                border-bottom: 1px solid $primary-50;
                border-left: 1px solid $primary-50;
              }
            }
            .date {
              width: 440px;
              padding: 8px 12px;
              border-radius: 4px;
              border-bottom: 1px solid $neutral-20;
              border-left: 1px solid $neutral-20;
              background: $neutral-0;
              font-size: 14px;
              font-weight: 400;
              &:focus {
                border-bottom: 1px solid $primary-50;
                border-left: 1px solid $primary-50;
              }
              &::-webkit-calendar-picker-indicator {
                padding-left: calc(100% - 100px);
              }
            }
            .unit {
              display: flex;
              align-items: center;
              gap: 12px;
              width: 440px;
              &-input {
                width: 383px;
                padding: 8px 12px;
                border-radius: 4px;
                border-bottom: 1px solid $neutral-20;
                border-left: 1px solid $neutral-20;
                background: $neutral-0;
                font-size: 14px;
                font-weight: 400;
                &:focus {
                  border-bottom: 1px solid $primary-50;
                  border-left: 1px solid $primary-50;
                }
              }
              p {
                padding: 8px 0;
                color: $neutral-60;
                font-size: 14px;
                font-weight: 500;
              }
            }
            .period {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              font-weight: 500;
              color: $neutral-60;
              &-date {
                width: 186px;
                padding: 8px 12px;
                border-radius: 4px;
                border-bottom: 1px solid $neutral-20;
                border-left: 1px solid $neutral-20;
                background: $neutral-0;
                font-size: 14px;
                font-weight: 400;
                &:focus {
                  border-bottom: 1px solid $primary-50;
                  border-left: 1px solid $primary-50;
                }
                &::-webkit-calendar-picker-indicator {
                  padding-left: calc(100% - 100px);
                }
              }
            }
            .onoff {
              width: 440px;
            }
          }
          .tag {
            display: flex;
            justify-content: flex-end;
            &-wrap {
              display: flex;
              justify-content: space-between;
              gap: 20px;
              padding: 16px;
              width: 440px;
              min-height: 60px;
              border-radius: 4px;
              border: 1px solid $neutral-10;
              .reset {
                cursor: pointer;
                display: flex;
                align-items: flex-end;
                padding: 2px 0px;
                color: $primary-60;
                font-size: 12px;
                font-weight: 500;
              }
            }
            &-list {
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
              p {
                display: flex;
                align-items: center;
                gap: 4px;
                height: 24px;
                background-color: $primary-10;
                color: $primary-60;
                border-radius: 28px;
                padding: 4px 12px;
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    &-bottom {
      height: 80px;
      @media (min-width: 901px) {
        padding: 20px 40px;
        border-top: 1px solid $primary-20;
      }
      &-btns {
        display: flex;
        @media (min-width: 901px) {
          justify-content: flex-end;
          gap: 20px;
        }
        .add-btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          font-size: 15px;
          font-weight: 500;
          @media (max-width: 900px) {
            flex: 1;
          }
          @media (min-width: 901px) {
            width: 120px;
            border-radius: 4px;
          }
          &.new {
            background: $neutral-60;
            color: $neutral-0;
          }
          &.before {
            background: $neutral-60;
            color: $neutral-0;
            @media (min-width: 901px) {
              background: $neutral-0;
              color: $neutral-60;
            }
          }
          &.next {
            background: $primary-50;
            color: $neutral-0;
          }
        }
      }
    }
  }
}
</style>
