<template>
  <div class="base-btn pointer"
    :class="[
      {[`type-${type}`]: type},
      {'black': black},
      {'disabled': disabled}
    ]"
  >
    <p class="bold">{{ msg }}</p>
  </div>
</template>
<script>
export default {
  name: 'baseBtn',
  props: {
    msg: {
      type: String,
      description: "btn text"
    },
    type: {
      type: String,
      description: "btn type ex) s, m, l, f"
    },
    black: {
      type: Boolean,
      description: "btn text color"
    },
    disabled: {
      type: Boolean,
      description: "btn disabled"
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-btn {
    background-color: $primary-50;
    text-align: center;
    font-size: 18px;
    border-radius: 7px;
    padding: rem(16px) 0;
    transition: .3s;
    
  }
  
  .base-btn:not(.black) {
    color: $neutral-0;
  }
  .base-btn.disabled {
    background-color: #E6E6E6;
  }
  .base-btn:not(.disabled) {
    &:hover { background-color: #EF7607; }
    &:active { background-color: #FA8700; }
  }
  .type- {
    &s {
      width: rem(164px);
    }
    &m {
      width: rem(246px);
    }
    &l {
      width: rem(328px);
    }
    &f {
      width: 100%;
      border-radius: 0;
    }
  }
</style>