export default {
  namespaced: true,
  state: {
    frontDesc: [
      {
        cd: "1",
        name: ["어깨선 끝 (암홀과 만나는 지점) ~ 이미지 가장 위쪽 기준"],
      },
      {
        cd: "2",
        name: [
          "네크라인 끝 (립 / 바인딩 포함) ~ 이미지 가장 위쪽 기준",
          "중앙선 ~ 중앙선과 가장 가까운 이미지 끝 기준",
        ],
      },
      {
        cd: "3",
        name: [
          "네크라인 중앙 아래 끝 (립 / 바인딩 포함) ~ 이미지 가장 위쪽 기준",
        ],
      },
      {
        cd: "4",
        name: [
          "네크라인 끝 (립 또는 바인딩 포함) ~ 이미지 가장 위쪽 기준",
          "중앙선 ~ 중앙선과 가장 가까운 이미지 끝 기준",
        ],
      },
      {
        cd: "5",
        name: ["어깨선 끝 (암홀과 만나는 지점) ~ 이미지 가장 위쪽 기준"],
      },
      {
        cd: "6",
        name: ["소매 밑단 끝 (중앙) ~ 이미지 가장 아래쪽 기준"],
      },
      {
        cd: "7",
        name: [
          "밑단 끝 ~ 이미지 가장 아래쪽 기준",
          "옆선 (봉제선) ~ 옆선과 가장 가까운 이미지 끝 기준",
        ],
      },
      {
        cd: "8",
        name: ["밑단 끝 ~ 이미지 가장 아래쪽 기준"],
      },
      {
        cd: "9",
        name: [
          "밑단 끝 ~ 이미지 가장 아래쪽 기준",
          "옆선 (봉제선) ~ 옆선과 가장 가까운 이미지 끝 기준",
        ],
      },
      {
        cd: "10",
        name: ["소매 밑단 끝 (중앙) ~ 이미지 가장 아래쪽 기준"],
      },
    ],
    backDesc: [
      {
        cd: "1",
        name: [
          "네크라인 끝 (립 / 바인딩 포함) ~ 이미지 가장 위쪽 기준",
          "중앙선 ~ 중앙선과 가장 가까운 이미지 끝 기준",
        ],
      },
      {
        cd: "2",
        name: [
          "네크라인 중앙 아래 끝 (립 / 바인딩 포함) ~ 이미지 가장 위쪽 기준",
        ],
      },
      {
        cd: "3",
        name: [
          "네크라인 끝 (립 / 바인딩 포함) ~ 이미지 가장 위쪽 기준",
          "중앙선 ~ 중앙선과 가장 가까운 이미지 끝 기준",
        ],
      },
      {
        cd: "4",
        name: [
          "밑단 끝 ~ 이미지 가장 아래쪽 기준",
          "옆선 (봉제선) ~ 옆선과 가장 가까운 이미지 끝 기준",
        ],
      },
      {
        cd: "5",
        name: ["밑단 끝 ~ 이미지 가장 아래쪽 기준"],
      },
      {
        cd: "6",
        name: [
          "밑단 끝 ~ 이미지 가장 아래쪽 기준",
          "옆선 (봉제선) ~ 옆선과 가장 가까운 이미지 끝 기준",
        ],
      },
    ],
    items: [
      {
        index: 0,
        cd: 1,
        name: "티셔츠 (T-shirt)",
        imgUrl: "img-custom-builder-tshirt.png",
        isActive: false,
        disabled: false,
      },
      {
        index: 1,
        cd: 2,
        name: "맨투맨 (Sweatshirt)",
        imgUrl: "img-custom-builder-sweatshirt.png",
        isActive: false,
        disabled: false,
      },
      {
        index: 2,
        cd: 3,
        name: "후드 (Hoodie)",
        imgUrl: "img-custom-builder-hoodie.png",
        isActive: false,
        disabled: true,
      },
      {
        index: 3,
        cd: 4,
        name: "집업 (Zip-up Jersey)",
        imgUrl: "img-custom-builder-zipup.png",
        isActive: false,
        disabled: true,
      },
      {
        index: 4,
        cd: 5,
        name: "팬츠 (Pants)",
        imgUrl: "img-custom-builder-pants.png",
        isActive: false,
        disabled: true,
      },
    ],
    lineType: [
      { cd: "1", name: "가로", isActive: true },
      { cd: "2", name: "세로", isActive: false },
    ],
    size: [
      { index: 0, isActive: true, cd: "01", name: "XS" },
      { index: 0, isActive: true, cd: "02", name: "S" },
      { index: 1, isActive: true, cd: "03", name: "M" },
      { index: 2, isActive: true, cd: "04", name: "L" },
      { index: 3, isActive: true, cd: "05", name: "XL" },
      { index: 4, isActive: true, cd: "06", name: "2XL" },
      { index: 4, isActive: true, cd: "07", name: "3XL" },
    ],
    color: [
      { index: 0, isActive: false, cd: "01", name: "화이트" },
      { index: 1, isActive: false, cd: "02", name: "블랙" },
      { index: 2, isActive: false, cd: "03", name: "멜란지" },
      { index: 3, isActive: false, cd: "04", name: "네이비" },
      { index: 4, isActive: false, cd: "05", name: "차콜" },
      { index: 5, isActive: false, cd: "06", name: "베이지" },
    ],
    checkSize: [
      {
        index: 0,
        cd: "1",
        isActive: false,
        name: "원하는 사이즈 스펙이 있어요.",
        isDisabled: false,
      },
      {
        index: 1,
        cd: "2",
        isActive: false,
        name: "사이즈 스펙을 추천해주세요.",
        isDisabled: false,
      },
    ],
    checkPrintImg: [
      {
        index: 0,
        cd: "1",
        isActive: false,
        name: "프린팅이 필요합니다.",
      },
      {
        index: 1,
        cd: "2",
        isActive: false,
        name: "프린팅이 없습니다. (무지)",
      },
    ],
    gender: [
      { index: 0, cd: "1", isActive: false, name: "여성", customType: [1] },
      {
        index: 1,
        cd: "2",
        isActive: false,
        name: "남성 & 유니섹스",
        customType: [1, 2],
      },
      // { index: 2, cd: "3", isActive: false, name: "유니섹스" },
    ],
    fit: [
      {
        index: 0,
        cd: "1",
        isActive: false,
        name: "슬림/머슬 핏",
        tshirtFlag: 1,
        mtmFlag: 0,
        hoodFlag: 0,
        zipupFlag: 0,
        pantsFlag: 0,
        customType: [1],
      },
      {
        index: 1,
        cd: "2",
        isActive: false,
        name: "레귤러 핏",
        tshirtFlag: 1,
        mtmFlag: 1,
        hoodFlag: 0,
        zipupFlag: 0,
        pantsFlag: 0,
        customType: [1, 2],
      },
      // { index: 2, cd: "3", isActive: false, name: "루즈 핏", tshirtFlag: 1, mtmFlag: 0, hoodFlag: 0, zipupFlag: 0, pantsFlag:0 },
      {
        index: 2,
        cd: "4",
        isActive: false,
        name: "오버 핏",
        tshirtFlag: 1,
        mtmFlag: 1,
        hoodFlag: 0,
        zipupFlag: 0,
        pantsFlag: 0,
        customType: [1, 2],
      },
    ],
    totalLength: [
      {
        index: 0,
        cd: "3",
        isActive: false,
        name: "크롭 기장",
        tshirtFlag: 0,
        mtmFlag: 1,
        hoodFlag: 0,
        zipupFlag: 0,
        pantsFlag: 0,
        customType: [2],
      },
      {
        index: 1,
        cd: "1",
        isActive: false,
        name: "레귤러 기장",
        tshirtFlag: 1,
        mtmFlag: 1,
        hoodFlag: 0,
        zipupFlag: 0,
        pantsFlag: 0,
        customType: [1, 2],
      },
      {
        index: 2,
        cd: "2",
        isActive: false,
        name: "롱 기장",
        tshirtFlag: 1,
        mtmFlag: 1,
        hoodFlag: 0,
        zipupFlag: 0,
        pantsFlag: 0,
        customType: [1, 2],
      },
    ],
    sleeveLength: [
      { index: 0, cd: "1", isActive: false, name: "반팔" },
      // { index: 1, cd: "2", isActive: false, name: "5부" },
      // { index: 2, cd: "3", isActive: false, name: "7부" },
      { index: 1, cd: "4", isActive: false, name: "긴팔" },
    ],
    sleeveStyle: [
      {
        index: 0,
        cd: "1",
        isActive: false,
        name: "레귤러",
        tshirtFlag: 0,
        mtmFlag: 1,
        hoodFlag: 0,
        zipupFlag: 0,
        pantsFlag: 0,
      },
      {
        index: 1,
        cd: "2",
        isActive: false,
        name: "와이드",
        tshirtFlag: 0,
        mtmFlag: 1,
        hoodFlag: 0,
        zipupFlag: 0,
        pantsFlag: 0,
      },
    ],
    neckLine: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "라운드 네크",
        subName: "",
        fileUrl: "neckline-img1.png",
        addAmount: 0,
        customType: [1, 2],
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "V 네크",
        subName: "",
        fileUrl: "neckline-img2.png",
        addAmount: 0,
        customType: [1, 2],
      },
    ],
    neckDetail: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "네크 립",
        subName: "폭 : 2.0cm",
        fileUrl: "neckdtl-img1.png",
        addAmount: 475,
        customType: [1, 2],
        nlCd: [1, 2],
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "네크 바인딩",
        subName: "1/2 inch",
        fileUrl: "neckdtl-img2.png",
        addAmount: 0,
        addRate: 0.1,
        customType: [1],
        nlCd: [1],
      },
      {
        index: 2,
        cd: 3,
        isActive: false,
        name: "V형 가젯 (거셋)",
        subName: "6.5cm × 8cm",
        fileUrl: "neckdtl-img3.png",
        addAmount: 1275,
        customType: [2],
      },
      {
        index: 3,
        cd: 4,
        isActive: false,
        name: "X자 스티치",
        subName: "6.5cm × 8cm + 2cm",
        fileUrl: "neckdtl-img4.png",
        addAmount: 1275,
        customType: [2],
      },
    ],
    vgusset: {
      value: [
        { index: 0, cd: "A", name: "가로", size: 6.5 },
        { index: 1, cd: "B", name: "세로", size: 8.0 },
      ],
      button: [
        {
          index: 0,
          cd: 1,
          isActive: false,
          name: "앞",
        },
        {
          index: 1,
          cd: 2,
          isActive: false,
          name: "뒤",
        },
        {
          index: 2,
          cd: 3,
          isActive: false,
          name: "앞 + 뒤",
        },
      ],
    },
    xstitch: {
      value: [
        { index: 0, cd: "A", name: "내경 가로", size: 7.5 },
        { index: 1, cd: "B", name: "내경 세로", size: 9.0 },
        { index: 2, cd: "C", name: "아래 길이", size: 2.0 },
      ],
    },
    kgrPocket: {
      value: [
        { index: 0, cd: "A", name: "", size: 26.0 },
        { index: 1, cd: "B", name: "", size: 21.0 },
        { index: 2, cd: "C", name: "", size: 36.0 },
        { index: 3, cd: "D", name: "", size: 34.0 },
        { index: 4, cd: "E", name: "", size: 7.5 },
      ],
    },
    neckEnd: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "네크 오바로크",
        subName: "",
        fileUrl: "neckfin-img1.png",
        addAmount: "-",
        isBadge: false,
        customType: [1, 2],
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "네크 해리",
        subName: "10mm 헤링본 테이프",
        fileUrl: "neckfin-img2.png",
        addAmount: 150,
        addRate: 0.05,
        isBadge: true,
        customType: [1, 2],
      },
    ],
    stitch: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "스티치 없음",
        subName: "",
        fileUrl: "stitch-img1.png",
        addAmount: "-",
        isBadge: false,
        ndCd: [1, 2, 3, 4],
        customType: [1, 2],
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "본봉 스티치",
        subName: "2mm",
        fileUrl: "stitch-img2.png",
        addAmount: "-",
        isBadge: false,
        ndCd: [1, 2, 3, 4],
        customType: [1, 2],
      },
      {
        index: 2,
        cd: 3,
        isActive: false,
        name: "갈라삼봉 스티치",
        subName: "2mm",
        fileUrl: "stitch-img3.png",
        addAmount: 600,
        isBadge: true,
        ndCd: [1, 3, 4],
        customType: [1, 2],
      },
      {
        index: 3,
        cd: 4,
        isActive: false,
        name: "체인 스티치",
        subName: "2mm",
        fileUrl: "stitch-img4.png",
        addAmount: 1000,
        isBadge: false,
        ndCd: [1],
        customType: [1],
      },
      {
        index: 4,
        cd: 5,
        isActive: false,
        name: "가이룹바",
        subName: "2mm",
        fileUrl: "stitch-img5.png",
        addAmount: 1000,
        isBadge: false,
        ndCd: [3, 4],
        customType: [2],
      },
    ],
    pocket: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "포켓 없음",
        subName: "",
        fileUrl: "",
        addAmount: "-",
        customType: [1, 2],
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "사각 포켓",
        subName: "",
        fileUrl: "pocket-img1.png",
        addAmount: 600,
        customType: [1],
      },
      {
        index: 2,
        cd: 3,
        isActive: false,
        name: "오각 포켓",
        subName: "",
        fileUrl: "pocket-img2.png",
        addAmount: 600,
        customType: [1],
      },
      {
        index: 3,
        cd: 4,
        isActive: false,
        name: "육각 포켓",
        subName: "",
        fileUrl: "pocket-img3.png",
        addAmount: 600,
        customType: [1],
      },
      {
        index: 4,
        cd: 5,
        isActive: false,
        name: "캥거루 포켓",
        subName: "",
        fileUrl: "pocket-img4.png",
        addRate: 0.1,
        addAmount: 1000,
        customType: [2],
      },
    ],
    sleeve: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "소매 2줄 삼봉",
        subName: "",
        fileUrl: "sleeve-img1.png",
        addAmount: "-",
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "소매 립",
        subName: "반팔 2cm | 긴팔 5cm",
        fileUrl: "sleeve-img2.png",
        addAmount: 475,
      },
      {
        index: 2,
        cd: 3,
        isActive: false,
        name: "소매 바인딩",
        subName: "1/2inch",
        fileUrl: "sleeve-img3.png",
        addAmount: 0,
        addRate: 0.05,
      },
    ],
    sleeveEnd: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "소매 2줄 삼봉",
        subName: "",
        fileUrl: "sleeve-end-img1.png",
        addAmount: "-",
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "소매 시보리",
        subName: "폭 : 6.0cm",
        fileUrl: "sleeve-end-img2.png",
        addAmount: 2100,
      },
    ],
    hemEnd: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "밑단 2줄 삼봉",
        subName: "",
        fileUrl: "hem-end-img1.png",
        addAmount: "-",
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "밑단 시보리",
        subName: "폭 : 6.0cm",
        fileUrl: "hem-end-img2.png",
        addAmount: 2100,
      },
    ],
    sideSlit: [
      {
        index: 0,
        cd: 1,
        isActive: false,
        name: "옆 트임 없음",
        subName: "",
        fileUrl: "sideslit-img1.png",
        addAmount: "-",
      },
      {
        index: 1,
        cd: 2,
        isActive: false,
        name: "옆 트임 있음",
        subName: "5cm",
        fileUrl: "sideslit-img2.png",
        addAmount: 700,
      },
    ],
    printFrontImgs: [
      {
        index: 0,
        cd: "01",
        name: "오른쪽 어깨",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 1,
        cd: "02",
        name: "오른쪽 가슴",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 2,
        cd: "03",
        name: "중앙 가슴",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 3,
        cd: "04",
        name: "왼쪽 가슴",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 4,
        cd: "05",
        name: "왼쪽 어깨",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 5,
        cd: "06",
        name: "오른쪽 소매단",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 6,
        cd: "07",
        name: "오른쪽 밑단",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 7,
        cd: "08",
        name: "중앙 밑단",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 8,
        cd: "09",
        name: "왼쪽 밑단",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 9,
        cd: "10",
        name: "왼쪽 소매단",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
    ],

    printBackImgs: [
      {
        index: 0,
        cd: "11",
        name: "왼쪽 등 위",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 1,
        cd: "12",
        name: "중앙 등 위",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 2,
        cd: "13",
        name: "오른쪽 등 위",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 3,
        cd: "14",
        name: "왼족 밑단",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 4,
        cd: "15",
        name: "중앙 밑단",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
      {
        index: 5,
        cd: "16",
        name: "오른쪽 밑단",
        img: {},
        lineType: "",
        longLength: "",
        printLength: [],
      },
    ],
  },
  // this.$store.state.customPrint.
};
