<template>
  <div class="nomal-wrap">
    <div class="nomal">
      <div class="card-list">
        <div
          class="card pointer"
          v-for="(x, i) in nomalFactoryList"
          :key="i"
          @click="goFactory(x.factoryNo)"
        >
          <div class="card-top normal">
            <div class="card-top-top">
              <!-- <div class="img-area" :class="userId ? 'login' : ''">
                <img v-if="x.imgFileUrl1" :src="x.imgFileUrl1" />
                <img
                  v-else
                  :src="require('@/assets/img/img-factory-empty.png')"
                />
              </div> -->
              <div class="info wd100">
                <div class="row space-between">
                  <p class="work-process">{{ x.workProcess }}</p>
                  <div class="row-info-right" v-if="compType == '1'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      @click.stop="clickShare(x)"
                    >
                      <path
                        :d="$store.state.meta.svg.share"
                        :fill="`${$store.state.meta.svg.color.neutral40}`"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      @click.stop="clickLike(x)"
                    >
                      <path
                        :d="`${
                          x.isLike
                            ? $store.state.meta.svg.favorite
                            : $store.state.meta.svg.favoriteFill
                        }`"
                        :fill="`${
                          x.isLike
                            ? $store.state.meta.svg.color.primary50
                            : $store.state.meta.svg.color.neutral40
                        }`"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex-start gap8 mt-4">
                  <p class="info-title">{{ x.factoryName }}</p>
                  <!-- <div class="icon" v-if="x.calculateFlag == '2'">
                    <img
                      :src="require(`@/assets/icon/ic-credit-card-orange.svg`)"
                    />
                  </div> -->
                  <div class="tooltip" v-if="x.calculateFlag == '2'">
                    <span class="tooltip-text">카드 결제 가능</span>
                    <img
                      :src="require(`@/assets/icon/ic-credit-card-orange.svg`)"
                    />
                  </div>
                </div>
                <div class="content">
                  <div class="content-info gap2">
                    <p class="area">
                      {{ x.area }}
                    </p>
                    <p
                      class="flex gap2"
                      v-if="x.workProcessCd == '01031' && x.minOrderCount > 0"
                    >
                      <span>│</span>{{ comma(x.minOrderCount) }}벌 이상
                    </p>
                  </div>
                  <div
                    class="content-info gray"
                    v-if="
                      x.workProcessCd == '01031' || x.workProcessCd == '01033'
                    "
                  >
                    <!-- <p v-for="(x, i) in x.fabricList" :key="i">
                      {{ x.commName }}
                    </p> -->
                    <div class="fabric">
                      <p
                        v-for="(x, i) in x.newFabricList ? x.newFabricList : ''"
                        :key="i"
                      >
                        {{ x }}
                      </p>
                      <p
                        v-for="(x, i) in !x.newFabricList ? x.fabricList : ''"
                        :key="i"
                      >
                        {{ x.commName }}
                      </p>
                    </div>
                    <span
                      v-if="
                        x.fabricList?.length > 0 &&
                        ((x.workProcessCd == '01031' &&
                          (x.processFlag == '1' || x.buyFlag == '1')) ||
                          (x.workProcessCd == '01033' &&
                            (x.sampleType == '1' ||
                              x.sampleType == '2' ||
                              x.sampleType == '3')))
                      "
                      >│</span
                    >
                    <div
                      class="row-content flex-start gap8"
                      v-if="x.workProcessCd == '01031'"
                    >
                      <p v-if="x.processFlag == '1'">임가공</p>
                      <p v-if="x.buyFlag == '1'">완사입</p>
                    </div>
                    <div
                      class="row-content flex-start gap8"
                      v-if="x.workProcessCd == '01033'"
                    >
                      <p v-if="x.sampleType == '1' || x.sampleType == '3'">
                        패턴
                      </p>
                      <p v-if="x.sampleType == '2' || x.sampleType == '3'">
                        샘플
                      </p>
                    </div>
                  </div>
                  <div
                    class="content-info work-item gray"
                    v-else-if="x.workProcessCd == '01034'"
                  >
                    <p
                      class="work-item-list"
                      v-if="x.jasu?.length > 0"
                      v-html="x.jasu"
                    ></p>
                  </div>
                  <div
                    class="content-info work-item gray"
                    v-else-if="x.workProcessCd == '01035'"
                  >
                    <p
                      class="work-item-list"
                      v-if="x.print?.length > 0"
                      v-html="x.print"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-bottom normal">
            <div class="category">
              <p v-for="(y, i) in x.tagList" :key="i">
                {{ y.commName.replace("#", "") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <PagingView
          pageType="normal"
          :totalCount="normalTotalCount"
          :perPage="perPage"
          @changePage="changePage"
        /> -->
    </div>
  </div>
  <!-- <div
        v-if="isAddPaging && normalTotalCount > nomalFactoryList.length"
        class="add-btn pointer"
        @click="addPaging()"
      >
        <p>더 보기</p>
      </div> -->
  <PagingView
    v-if="isAddPaging && normalTotalCount > nomalFactoryList.length"
    ref="pagingView"
    :totalCount="normalTotalCount"
    :perPage="perPage"
    @changePage="changePage"
  />
</template>
<script>
export default {
  name: "cardView",
  props: {
    nomalFactoryList: {
      type: Object,
      description: "Card data",
    },
  },
  data() {
    return {
      d: {},
      get isMobile() {
        return localStorage.getItem("isMobile");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get userId() {
        return sessionStorage.getItem("userId");
      },
    };
  },
  mounted() {
    this.d = this.nomalFactoryList;
    this.d.isLike = this.d.like;
  },
  methods: {
    clickLike(data) {
      this.$emit("clickLike", data);
    },
    clickShare(data) {
      this.$emit("clickShare", data);
    },
    goFactory(factoryNo) {
      this.gotoPage(`/search/factorydetail/${factoryNo}`);
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  &:hover {
    path {
      fill: $primary-50;
    }
  }
}
.nomal-wrap {
  padding-top: 20px;
  .nomal {
  }
}
.card-list {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 20px;

  @include mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: 1fr;
    margin: 0 20px;
  }
  @media (min-width: 720px) {
    grid-template-columns: 1fr 1fr;
  }
  @include desktop {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &.mb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    grid-template-columns: 1fr;
  }
}
.card {
  padding: 16px;
  // max-width: 470px;
  width: 100%;
  @include desktop {
    width: 300px;
  }
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: $neutral-0;
  border: 1px solid $neutral-0;
  &:hover {
    border: 1px solid $primary-20;
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.04));
  }
  .tooltip {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    &-text {
      width: 100px;
      visibility: hidden;
      background-color: $primary-50;
      color: $neutral-0;
      text-align: center;
      border-radius: 4px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      padding: 4px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 125%;
      margin-left: -62px;
      opacity: 0;
      transition: opacity 0.3s;
      font-weight: 350;
      font-size: 12px;
      line-height: 20px;

      &.on {
        color: $neutral-60;
      }
      &.left {
        margin-left: -88px;
        &::after {
          left: 72%;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $primary-50 transparent transparent transparent;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      }
    }
    &:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }
  &-top {
    height: 100px;
    &-top {
      .img-area {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        max-width: 48px;
        height: 48px;
        border: 1px solid $neutral-10;
        border-radius: 84px;
        overflow: hidden;
        img {
          // max-width: 100%;
          max-height: 100%;
        }
        &.login {
          width: 48px;
          height: 48px;
        }
      }
      .info {
        min-width: 180px;
        width: 100%;
        @media (max-width: 1290px) {
          max-width: 372px;
        }
        .like-flag {
          width: 16px;
          height: 16px;
        }
        &-title {
          @include ellipse();
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $neutral-80;
        }
        .work-process {
          color: $neutral-80;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
        .row {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-80;
          &-info {
            display: flex;
            gap: 8px;
            white-space: normal;
            -webkit-line-clamp: 1;
            overflow: hidden;
            word-break: break-all;
            &:not(:first-child) {
              margin-left: 12px;
              padding-left: 12px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 13px;
                background-color: $neutral-30;
              }
            }
            &-right {
              display: flex;
              gap: 8px;
              .icon {
                display: flex;
                gap: 4px;
                img {
                  width: 16px;
                }
                p {
                  color: $primary-60;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 18px;
                }
              }
            }
          }
        }
        .content {
          margin-top: 4px;
          display: flex;
          flex-direction: column;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-80;
          &-info {
            display: flex;
            gap: 8px;
            overflow: hidden;
            .area {
              position: relative;
              &.bf::before {
                content: "";
                position: absolute;
                top: 50%;
                right: -8px;
                transform: translateY(-50%);
                width: 1px;
                height: 12px;
                background-color: $primary-60;
              }
            }
            span {
              color: $primary-60;
              font-size: 14px;
              line-height: 140%; /* 19.6px */
            }
            .fabric {
              display: flex;
              gap: 8px;
              margin-top: 2px;
              color: $neutral-60;
              max-width: 160px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .work-item {
              margin-top: 2px;
              color: $neutral-60;
              &-list {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      :deep(.rating) {
        img {
          width: 14px;
          height: 14px;
        }
      }
      .rating-val {
        font-weight: 700;
        font-size: 18px;
        color: $neutral-80;
        margin-left: rem(12px);
      }
    }
    .line {
      height: 1px;
      background-color: $neutral-5;
      margin-top: rem(20px);
      margin-bottom: rem(15px);
    }
    .category {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      font-size: 14px;
      color: $neutral-80;
    }
  }

  &-bottom {
    margin-top: 8px;
    height: 78px;
    flex: 1;
    .represent {
      font-size: 14px;
      color: $neutral-80;
      margin-bottom: rem(12px);
    }
    .category {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 12px;
      color: $neutral-50;
      gap: 4px;
      height: 54px;
      overflow: hidden;
      p {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 26px;
        background: $primary-10;
        color: $primary-60;
      }
    }
  }
}
</style>
