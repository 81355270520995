<template>
  <div class="banner">
    <swiper
      :modules="modules"
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 5000, disableOnInteraction: false }"
      :effect="'fade'"
      :rewind="true"
      :fadeEffect="{ crossFade: true }"
      :autoHeight="true"
    >
      <!-- <swiper-slide
        @click="gotoPage('/subsidy')"
        class="banner-slider subsidy pointer"
        :style="{ 'background-image': 'url(' + require(`@/assets/img/banner-subsidy${windowWidth > 800 ? '-pc' : ''}.png`) + ')' }"
      >
        <div class="banner-slider-div">
          <div class="flex-start">
            <img class="subsidy-logo" src="@/assets/logo/osle-logo-banner.svg">
            <p class="subsidy-balloon pretendard center">NEW 패션 브랜드 <br v-if="windowWidth < 360"><b>생산자금 지원</b> 서비스 출시!!</p>
          </div>
          <p class="title cafe24">의류생산 <br v-if="windowWidth <= 800"><span class="highlight cafe24">지원금</span> 받자!</p>
          <p v-if="windowWidth > 800" class="content mt-4">오슬에서 선생산, 후판매 구조로 어려움을 겪는 패션브랜드에<br>새로운 방식의 생산 자금 지원 서비스를 제공하고자 합니다.</p>
          <p class="more">자세히 보기 ></p>
        </div>
      </swiper-slide> -->
      <swiper-slide
        @click="sendBtnEvent('7');gotoUrl('https://drive.google.com/file/d/1yIQRk3qX4uRhUwhLI27y49vlqJgB1Nci/view', true)"
        class="banner-slider doosan pointer"
      >
        <img :src="require(`@/assets/img/banner-doosan${windowWidth > 800 ? '-pc' : ''}.png`)">
      </swiper-slide>
      <swiper-slide
        class="banner-slider"
        :style="{ 'background-image': 'url(' + require(`@/assets/img/banner1${windowWidth > 800 ? '-pc' : ''}.png`) + ')' }"
      >
        <div class="banner-slider-div">
          <p class="title">'SAINT MILL'</p>
          <p class="content mt-4">명유석 + 오슬팩토리</p>
          <p class="more mt-4">제작완료</p>
        </div>
      </swiper-slide>
      <swiper-slide
        class="banner-slider"
        :style="{ 'background-image': 'url(' + require(`@/assets/img/banner2${windowWidth > 800 ? '-pc' : ''}.png`) + ')' }"
      >
        <div class="banner-slider-div">
          <p class="title">22 F/W SEOUL<br>FASHION WEEK</p>
          <p class="content mt-16">명유석 + 오슬팩토리</p>
          <p class="more mt-4">제작완료</p>
        </div>
      </swiper-slide>
      <swiper-slide
        @click="gotoPage('/matchingintro')"
        class="banner-slider pointer"
        :style="{ 'background-image': 'url(' + require(`@/assets/img/banner3${windowWidth > 800 ? '-pc' : ''}.png`) + ')' }"
      >
        <div class="banner-slider-div">
          <p class="title bera">오슬만의 매칭 서비스</p>
          <p class="content mt-8 bera">매칭하기를 통해<br>원하는 옷을 제작해보세요</p>
          <p class="see-more">> 살펴보기</p>
        </div>
      </swiper-slide>
      <swiper-slide
        @click="gotoUrl('https://m.post.naver.com/viewer/postView.naver?volumeNo=34155965&memberNo=47835507&navigationType=push', 1)"
        class="banner-slider pointer"
        :style="{ 'background-image': 'url(' + require(`@/assets/img/banner4${windowWidth > 800 ? '-pc' : ''}.png`) + ')' }"
      >
        <div class="banner-slider-div">
          <p class="title">테이크주름 인터뷰</p>
          <p class="content bold mt-8">O’SLE X Factories<br>공장 인터뷰 프로젝트</p>
          <p class="see-more">> 살펴보기</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay, EffectFade } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";

export default {
  name: 'cardView',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Autoplay, EffectFade],
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.meta.windowWidth
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner {
    color: $neutral-0;
    .swiper {
      border-radius: 0px 0px 30px 30px;
    }
    &-slider {
      padding: rem(36px) rem(32px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      &-div {
        @include set-width();
      }
      &:not(.doosan) {
        height: rem(215px);
      }
      &.doosan {
        padding: 0;
        img {
          width: 100%;
          height: auto;
        }
        @include tablet {
          background-color: $neutral-0;
          text-align: center;
          padding-bottom: 20px;
          img {
            max-width: 800px;
            margin: 0 auto;
          }
        }
        @include desktop {
          img {
            max-width: 1100px;
          }
        }
      }
      .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
      }
      .content {
        font-size: 18px;
      }
      .more {
        font-size: 14px;
      }
      .see-more {
        margin-top: 20px;
      }
      .highlight {
        color: #FFDE36;
      }
      &.subsidy {
        padding: 28px 32px;
        padding-right: 10px;
        .title {
          margin-top: 10px;
        }
        .more {
          margin-top: 4px;
        }
        .subsidy-balloon {
          color: #004CBD;
          background-color: $neutral-0;
          border-radius: 40px;
          padding: 8px 16px;
          margin-left: 8px;
          font-size: 12px;
        }
        @include tablet {
          padding: 40px 32px;
          .title {
            margin-top: 20px;
          }
          .content {
            font-size: 21px;
            font-weight: 500;
            line-height: 29px;
          }
          .more {
            margin-top: 24px;
          }
          .subsidy-logo {
            width: 90px;
          }
          .subsidy-balloon {
            padding: 12px 24px;
            font-size: 21px;
            margin-left: 24px;
          }
        }
      }
    }
  }

  :deep(.swiper-pagination) {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: rem(35px);
    height: rem(12px);
    &-bullet {
      transition: .3s;
      width: rem(8px);
      height: rem(8px);
      background: rgba(255, 255, 255, 0.5);
      opacity: 1;
      margin: 0 rem(2px);
      &-active {
        width: rem(12px);
        height: rem(12px);
        background: $neutral-0;
      }
    }
  }
  
  @include tablet {
    .banner-slider {
      &:not(.doosan) {
        height: 400px;
      }
      .title {
        font-size: 66px;
        line-height: 64px;
        margin-top: 44px;
      }
      .content {
        margin-top: 30px!important;
        font-size: 37px;
      }
      .more {
        font-size: 29px;
      }
      .see-more {
        font-size: 29px;
        margin-top: 32px;
      }
    }
  }
</style>