import dayjs from "dayjs";
import "dayjs/locale/ko";
import { READ_FLAG, MESSAGE_TYPE } from "@/constants/chat";

export const useMessage = () => {
  let userId = sessionStorage.getItem("userId");

  const dateFormat = (timestamp) => {
    // const milliseconds = timestamp * 1000;
    // switch (dayjs(milliseconds).format("A")) {
    //   case "AM":
    //     return `오전 ${dayjs(milliseconds).format("HH:mm")}`;
    //   case "PM":
    //     return `오후 ${dayjs(milliseconds).format("HH:mm")}`;
    // }
    const milliseconds =
      timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);
    return dayjs(milliseconds).format("YY.MM.DD  HH:mm");
  };

  const dateFormatByDay = (date) => {
    const createDate = new Date(date);
    const formattedDate = dayjs(createDate).format("YY.MM.DD");
    return formattedDate;
  };

  const dateFormatByDate = (date) => {
    const createDate = new Date(date);
    const formattedDate = dayjs(createDate).format("YY.MM.DD hh:mm");
    return formattedDate;
  };

  const dateFormatLongByDate = (date) => {
    const createDate = new Date(date);
    const formattedDate = dayjs(createDate).format("YYYY.MM.DD hh:mm");
    return formattedDate;
  };

  const dateFormatFull = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const date = dayjs(milliseconds).format("YYYY.MM.DD HH:mm:ss");
    return date;
  };

  const dateFormatDay = (timestamp) => {
    const milliseconds = timestamp * 1000;
    const date = dayjs(milliseconds)
      .locale("ko")
      .format("YYYY년 MM월 DD일 dddd");
    return date;
  };

  const messageReadMatch = (readFlag) => {
    switch (readFlag) {
      case READ_FLAG.NOT:
        return 1;
      case READ_FLAG.READ:
        return;
    }
  };

  const selfMatchByMessage = (sender) => {
    if (sender === userId) {
      return {
        msgClass: "my-msg",
        dateClass: "my-date",
      };
    }

    return {
      msgClass: "another-msg",
      dateClass: "another-date",
    };
  };

  const selfMatchByImageMessage = (sender) => {
    if (sender === userId) {
      // 보낸 사람이 나
      // isShow가 1이고 imgUrl이 null이 아닐때 == 사진
      return { msgClass: "my-msg img-msg", dateClass: "my-date" };
    } else {
      return { msgClass: "another-msg img-msg", dateClass: "another-date" };
    }
  };

  const selfMatchByFileMessage = (sender) => {
    if (sender === userId) {
      return { msgClass: "my-msg file-msg", dateClass: "my-date" };
    } else {
      return { msgClass: "another-msg file-msg", dateClass: "another-date" };
    }
  };

  const selfMatchByOrderMessage = (sender) => {
    if (sender === userId) {
      return { msgClass: "my-msg ", dateClass: "my-date" };
    } else {
      return { msgClass: "another-msg ", dateClass: "another-date" };
    }
  };

  const selfMatchByPaymentMessage = (sender) => {
    if (sender === userId) {
      return { msgClass: "my-msg ", dateClass: "my-date" };
    } else {
      return { msgClass: "another-msg ", dateClass: "another-date" };
    }
  };

  const selfMatchByContractMessage = (sender) => {
    if (sender === userId) {
      return { msgClass: "my-msg ", dateClass: "my-date" };
    } else {
      return { msgClass: "another-msg ", dateClass: "another-date" };
    }
  };

  const selfMatchByDesignOrderMessage = (sender) => {
    if (sender === userId) {
      return { msgClass: "my-msg ", dateClass: "my-date" };
    } else {
      return { msgClass: "another-msg ", dateClass: "another-date" };
    }
  };

  const selfMatchByCalculateMessage = (sender) => {
    if (sender === userId) {
      return { msgClass: "my-msg ", dateClass: "my-date" };
    } else {
      return { msgClass: "another-msg ", dateClass: "another-date" };
    }
  };

  const now = new Date();
  const koreanTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds()
  );

  return {
    dateFormat,
    dateFormatFull,
    dateFormatDay,
    dateFormatByDay,
    dateFormatByDate,
    dateFormatLongByDate,
    messageReadMatch,
    selfMatchByMessage,
    selfMatchByImageMessage,
    selfMatchByFileMessage,
    selfMatchByOrderMessage,
    selfMatchByPaymentMessage,
    selfMatchByContractMessage,
    selfMatchByDesignOrderMessage,
    koreanTime,
  };
};
