<template>
  <div class="normal-card">
    <swiper
      id="orderCard"
      class="normal-card-swiper"
      :autoplay="{ delay: 3000, disableOnInteraction: false }"
      :slidesPerView="
        $store.state.meta.windowWidth > 1250
          ? 3
          : $store.state.meta.windowWidth > 760
          ? 2
          : 1
      "
      :pagination="{ clickable: true }"
      :loop="true"
      :rewind="true"
      :modules="modules"
      :spaceBetween="isMobile == 'true' && userId ? 30 : 20"
    >
      <swiper-slide
        class="order"
        v-for="(x, i) in listData"
        :key="i"
        @click="
          x.recruitStatus < 4
            ? goDetail(
                x.designOrderNo,
                x.userId,
                x.styleNameValue ? x.styleNameValue : x.styleName
              )
            : ''
        "
      >
        <div class="order-wrap">
          <div class="end-background" v-if="x.recruitStatus > 3">
            매칭이 마감되었습니다
          </div>
          <div class="order-wrap-top">
            <div class="title">
              <div
                v-if="x.workProcess == '01031'"
                class="work-process process1"
              >
                봉제
              </div>
              <div
                v-if="x.workProcess == '01033'"
                class="work-process process2"
              >
                패턴/샘플
              </div>
              <div
                v-if="x.workProcess == '01034'"
                class="work-process process3"
              >
                자수
              </div>
              <div
                v-if="x.workProcess == '01035'"
                class="work-process process4"
              >
                나염/전사
              </div>
              <div v-if="x.workProcess == '01038'" class="work-process">
                완사입
              </div>
              <p v-html="x.styleName" class="style-name"></p>
            </div>
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.rightArrowBig"
              :color="$store.state.meta.svg.color.neutral40"
            />
          </div>
          <div class="order-wrap-body">
            <div class="order-wrap-body-info">
              <div class="row" v-if="x.itemList?.length > 0">
                <p class="name">· 품목</p>
                <div class="value">
                  <p
                    v-for="(y, j) in x.itemList.length > 3
                      ? x.itemList.filter((item, index) => index !== 2)
                      : x.itemList"
                    :key="j"
                  >
                    <SvgImg
                      v-if="j > 0"
                      :size="20"
                      :d="$store.state.meta.svg.rightArrow"
                      :color="$store.state.meta.svg.color.neutral30"
                    />
                    {{ y }}
                  </p>
                </div>
              </div>
              <div class="row" v-else-if="x.catg">
                <p class="name">· 품목</p>
                <div class="value">
                  <p>{{ x.catg }}</p>
                  <img
                    src="@/assets/icon/ic-chevron-right-60.svg"
                    v-if="x.fabric"
                  />
                  <p v-if="x.fabric">{{ x.fabric }}</p>
                </div>
              </div>
              <div class="row" v-else-if="x.itemCatgName">
                <p class="name">· 품목</p>
                <div class="value">
                  <p>{{ x.itemCatgName }}</p>
                </div>
              </div>
              <div class="row" v-if="x.workProcess == '01033'">
                <p class="name">· 작업</p>
                <p class="value" v-if="workType == '1'">패턴</p>
                <p class="value" v-else-if="workType == '2'">샘플</p>
                <p class="value" v-else>패턴 샘플</p>
              </div>
              <div class="row" v-else>
                <p class="name">· 수량</p>
                <p class="value">{{ x.quantity }}</p>
              </div>
              <div class="row">
                <p class="name">· 납기</p>
                <p class="value" v-if="!x.deliveryDate">
                  {{ x.startDate }} ~ {{ x.endDate }} 이내
                </p>
                <p class="value" v-else>{{ x.deliveryDate }}</p>
              </div>
            </div>
            <div class="order-wrap-body-tag">
              <p v-for="(tag, i) in x.hashTag?.split(',')" :key="i">
                {{ tag }}
              </p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import SvgImg from "../SvgImg.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";

export default {
  name: "DesignOrderMainPcCard",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    listData: {
      type: Object,
      description: "Card data",
    },
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  data() {
    return {
      d: {},
      isMore: false,
      isShowMsg: false,
      index: "",
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
    };
  },
  mounted() {
    console.log(this.listData);
    this.d = this.listData;
    this.d.isLike = this.d.like;
  },
  methods: {
    openStatusPopup(index) {
      this.index = index;
      this.isShowMsg = true;
    },
    designOrderEnd() {
      this.$emit("toggleStatus", this.index);
      this.isShowMsg = false;
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    bookmark(index, designOrderNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo);
    },
    factoryPin(index, designOrderNo, factoryNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo, factoryNo);
    },
    goDetail(designOrderNo, userId, styleNameValue) {
      console.log(styleNameValue);
      if (this.userId) {
        this.$store.state.meta.detailTitle = styleNameValue;
        if (userId == this.userId) {
          this.gotoPage(`/designorder/detail/factory/${designOrderNo}`);
        } else {
          this.gotoPage(`/designorder/detail/order/${designOrderNo}`);
        }
      } else {
        this.$emit("openLogin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.normal-card {
  padding-bottom: 20px;
  position: relative;
  @include mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    grid-template-columns: 1fr;
  }
  &-swiper {
    position: initial;
    max-width: 1240px;
    @media (max-width: 1240px) {
      width: calc(100vw - 40px);
    }
    :deep(.swiper-pagination) {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      bottom: 0;
      &-bullet {
        background-color: #fff4db;
        width: 8px;
        height: 8px;
        opacity: 1;
        margin: 0;
        border-radius: 4px;
        &-active {
          background-color: $primary-50;
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  .order {
    cursor: pointer;
    overflow: hidden;
    background: $neutral-0;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    @include mobile {
      // width: 100%;
      width: 320px;
    }
    .status {
      position: absolute;
      z-index: 1;
      top: 16px;
      right: 20px;
      color: $neutral-0;
      font-size: 14px;
      font-weight: 500;
      // padding: 4px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 66px;
      height: 28px;
      border-radius: 28px;
      background: $primary-50;
      &.end {
        background: $neutral-40;
      }
    }
    .status-end {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $neutral-0;
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      height: 100%;
    }
    &-wrap {
      padding: 20px 20px 48px;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 201px;
      background: $neutral-0;
      border-radius: 4px;
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
      .end-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $neutral-100;
        opacity: 0.5;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $neutral-0;
        font-size: 16px;
        font-weight: 400;
      }
      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          display: flex;
          align-items: center;
          gap: 16px;
          width: 100%;
          .work-process {
            display: flex;
            padding: 4px 12px;
            font-size: 14px;
            font-weight: 500;
            color: $neutral-0;
            border-radius: 4px;
            height: 28px;
            &.process1 {
              background-color: #04bb6a;
            }
            &.process2 {
              background-color: #8c29cc;
              width: 100%;
              max-width: 82px;
            }
            &.process3 {
              background-color: #ff6433;
            }
            &.process4 {
              background-color: #07bbdf;
              width: 100%;
              max-width: 82px;
            }
          }
          .style-name {
            font-size: 16px;
            font-weight: 500;
            width: 100%;
            max-width: 330px;
            height: 20px;
            @include ellipse();
          }
        }
      }
      &-body {
        &-info {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 16px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $neutral-60;
          .row {
            position: relative;
            display: flex;
            font-size: 14px;
            .name {
              font-weight: 500;
            }
            .value {
              position: absolute;
              display: flex;
              align-items: center;
              gap: 4px;
              font-weight: 400;
              left: 74px;
              @media (max-width: 500px) {
                left: 54px;
              }
              :nth-child(n) {
                display: flex;
                align-items: center;
              }
              :last-child {
                color: $primary-60;
              }
            }
          }
        }
        &-tag {
          margin-top: 16px;
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          overflow: hidden;
          height: 25px;
          p {
            display: flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 26px;
            background: $primary-5;
            color: $primary-60;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
    &:hover {
      border-color: $primary-50;
    }
  }

  .modal {
    &-status {
      border-radius: 8px;
      background-color: $neutral-3;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      position: fixed;
      z-index: 1001;
      left: 50%;
      top: 146px;
      width: calc(100% - 40px);
      max-width: 360px;
      transform: translate(-50%, 0);
      text-align: center;
      color: $neutral-100;
      &-top {
        border-radius: 8px 8px 0 0;
        padding: 40px;
        border-bottom: 1px solid $neutral-10;
        &-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        &-msg {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
    .status-btns {
      display: flex;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: $neutral-0;
      .btns-left {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $neutral-60;
        border-bottom-left-radius: 8px;
      }
      .btns-right {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-bottom-right-radius: 8px;
      }
      .btns-one {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $primary-50;
        border-radius: 0 0 8px 8px;
      }
    }
  }
}
.no-order-list {
  margin-top: 40px;
  margin-bottom: 80px;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
</style>
