<template>
  <div
    class="material"
    id="materialCard"
    v-if="isMobile == 'false' && listData?.length > 0"
  >
    <div
      class="card-wrap"
      v-for="(x, i) in listData"
      :key="i"
      @click="goDetail(`${x.mallNo}`, `${x.itemName}`)"
    >
      <div class="card">
        <div class="card-top">
          <div class="card-top-img">
            <img :src="x.imgUrl" />
          </div>
        </div>
        <div class="card-body">
          <div class="item-name">
            <p>{{ x.itemName }}</p>
          </div>
          <div class="item-length" v-if="x.itemUnitValue">
            <p>{{ x.itemUnitValue }}</p>
          </div>
          <div class="sale" v-if="x.itemNormalCost">
            <p>할인가</p>
            <p class="exist">{{ comma(x.itemNormalCost) }}원</p>
          </div>
          <div class="price">
            <p>{{ comma(x.itemExposeCost) }}원</p>
            <p class="unit" v-if="x.itemLengthValue > 0">
              ({{ x.itemLengthName }}, {{ comma(x.itemLengthValue) }}원)
            </p>
          </div>
          <div class="delivery" v-if="x.deliveryPrice == 0">
            <span class="material-symbols-outlined"> local_shipping </span>
            무료배송
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="mobile-material"
    id="materialCard"
    v-else-if="listData?.length > 0"
  >
    <div
      class="card"
      v-for="(x, i) in listData"
      :key="i"
      @click="goDetail(`${x.mallNo}`, `${x.itemName}`)"
    >
      <div class="card-left">
        <div class="card-left-img">
          <img :src="x.imgUrl" />
        </div>
      </div>
      <div class="card-right">
        <div class="item-name">
          <p>{{ x.itemName }}</p>
        </div>
        <div class="item-length" v-if="x.itemUnitValue">
          <p>{{ x.itemUnitValue }}</p>
        </div>
        <div class="sale" v-if="x.itemNormalCost">
          <p>할인가</p>
          <p class="exist">{{ comma(x.itemNormalCost) }}원</p>
        </div>
        <div class="price">
          <p>{{ comma(x.itemExposeCost) }}원</p>
          <p class="unit" v-if="x.itemLengthValue > 0">
            ({{ x.itemLengthName }}, {{ comma(x.itemLengthValue) }}원)
          </p>
        </div>
        <div class="delivery" v-if="x.deliveryPrice == 0">
          <span class="material-symbols-outlined"> local_shipping </span>
          무료배송
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="!(listData?.length > 0)" class="no-list">
    구매 내역이 없습니다.
  </div>
</template>
<script>
export default {
  name: "MaterialListView",
  props: {
    listData: {
      type: Object,
      description: "Card data",
    },
  },
  data() {
    return {
      d: {},
      isShowMsg: false,
      index: "",
      get userId() {
        return sessionStorage.getItem("userId");
      },
      get compType() {
        return sessionStorage.getItem("compType");
      },
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  mounted() {
    console.log(this.listData);
    this.d = this.listData;
    this.d.isLike = this.d.like;
  },
  methods: {
    openStatusPopup(index) {
      this.index = index;
      this.isShowMsg = true;
    },
    clickLike() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로]
        this.loginCheck(window.history.state.back);
        return;
      }
      let updateVal = !this.d.isLike;
      this.d.isLike = updateVal;
      this.$API.search.like(
        sessionStorage.getItem("userId"),
        this.d.factoryNo,
        updateVal ? "1" : "0"
      );
    },
    bookmark(index, designOrderNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo);
    },
    factoryPin(index, designOrderNo, factoryNo) {
      console.log(index, designOrderNo);
      this.$emit("bookmark", index, designOrderNo, factoryNo);
    },
    goDetail(mallNo, itemName) {
      this.$store.state.meta.detailTitle = itemName;
      console.log(this.$store.state.meta.detailTitle);
      console.log(mallNo);
      if (this.userId) {
        this.gotoPage(`/mall/materialdetail/${mallNo}`);
      } else {
        this.$emit("openLogin");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.material {
  display: grid;
  // flex-wrap: wrap;
  gap: 12px 0;
  padding: 20px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 960px) {
    gap: 20px 16px;
    grid-template-columns: 1fr 1fr;
  }
  .card-wrap {
    cursor: pointer;
    padding-bottom: 12px;
    padding-right: 12px;
    &:nth-child(4n) {
      padding-right: 0;
    }
    &:nth-child(-n + 4) {
      border-bottom: 1px solid $neutral-10;
    }
  }
  .card {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 365px;
    width: 226px;
    @media (max-width: 960px) {
      gap: 10px;
    }
    &:hover {
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
    }
    &-top {
      // max-width: 225px;
      // max-height: 225px;
      position: relative;
      width: 100%;
      &-img {
        display: flex;
        justify-content: center;
        border: 1px solid $neutral-10;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-bottom: 100%; /* 가로 세로 비율을 맞추기 위한 패딩 값 */
        img {
          position: absolute;
          height: 100%;
        }
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      .item-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        display: flex;
        gap: 8px;
      }
      .item-length {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
      }
      .sale {
        margin-top: 8px;
        display: flex;
        gap: 8px;
        font-size: 13px;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
        color: $primary-60;
        .exist {
          text-decoration: line-through;
          color: $neutral-40;
        }
      }
      .price {
        margin-top: 2px;
        display: flex;
        gap: 4px;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
        color: $primary-60;
        .unit {
          font-size: 12px;
        }
      }
      .unit-cost {
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        @media (max-width: 800px) {
          font-size: 15px;
        }
      }
      .delivery {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        line-height: 140%;
        color: $primary-80;
        span {
          font-size: 12px;
        }
      }
    }
    &:hover {
      border-color: $primary-50;
    }
  }
}
.mobile-material {
  // margin-top: 28px;
  display: flex;
  flex-direction: column;
  .card {
    cursor: pointer;
    display: flex;
    gap: 12px;
    padding: 20px 0;
    border-bottom: 1px solid $neutral-10;
    @media (max-width: 960px) {
      gap: 16px;
      &:last-child {
        border-bottom: 0;
      }
    }
    &-left {
      max-width: 112px;
      max-height: 112px;
      position: relative;
      width: 100%;
      &:hover {
        border-color: $primary-20;
      }
      &-img {
        border: 1px solid $neutral-10;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-bottom: 100%; /* 가로 세로 비율을 맞추기 위한 패딩 값 */
        img {
          position: absolute;
          height: 100%;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      .item-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        display: flex;
        gap: 8px;
      }
      .item-length {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
      }
      .sale {
        margin-top: 8px;
        display: flex;
        gap: 8px;
        font-size: 13px;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
        color: $primary-60;
        .exist {
          color: $neutral-40;
          text-decoration: line-through;
        }
      }
      .price {
        margin-top: 2px;
        display: flex;
        gap: 4px;
        font-size: 15px;
        font-weight: 500;
        line-height: 140%;
        color: $primary-60;
        .unit {
          font-size: 12px;
        }
      }
      .unit-cost {
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        @media (max-width: 800px) {
          font-size: 15px;
        }
      }
      .delivery {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        line-height: 140%;
        color: $primary-80;
        span {
          font-size: 12px;
        }
      }
    }
    &:hover {
      border-color: $primary-50;
    }
  }
}
.no-list {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}
</style>
