<template>
  <div class="designer">
    <div class="space-between gap12">
      <p class="designer-title">발주자 정보</p>
      <div
        class="flex gap12"
        v-if="compType == '1' && userId == designerInfo.userId"
      >
        <div class="onoff-s flex">
          <p class="onoff-s-txt" :class="displayFlag == '1' ? 'select' : ''">
            공개
          </p>
          <div
            class="onoff-s-btn"
            :class="displayFlag == '1' ? '' : 'active'"
            @click="toggleDisplay(displayFlag)"
          ></div>
          <p class="onoff-s-txt" :class="displayFlag != '1' ? 'select' : ''">
            비 공개
          </p>
        </div>
        <p class="save-btn pointer" @click="saveFlag()">저장</p>
      </div>
    </div>
    <div class="designer-body">
      <div>
        <p class="name">보유 브랜드</p>
        <div class="info">
          <p v-if="designerInfo.brandName1">{{ designerInfo.brandName1 }}</p>
          <p v-if="designerInfo.brandName2">{{ designerInfo.brandName2 }}</p>
          <p v-if="designerInfo.brandName3">{{ designerInfo.brandName3 }}</p>
          <p v-if="designerInfo.brandName4">{{ designerInfo.brandName4 }}</p>
          <p v-if="designerInfo.brandName5">{{ designerInfo.brandName5 }}</p>
          <p v-if="designerInfo.brandName6">{{ designerInfo.brandName6 }}</p>
        </div>
      </div>
      <div>
        <p class="name">주요 유통·판매 채널</p>
        <div class="info">
          <p v-for="(x, i) in designerInfo.dealerList" :key="i">
            {{ x.commName }}
          </p>
        </div>
      </div>
      <!-- <div>
          <p class="name">주요 매출처</p>
          <div class="info">
            <p v-for="(x, i) in designerInfo.dealerList" :key="i">
              {{ x.commName }}
            </p>
          </div>
        </div> -->
      <div v-if="designerInfo.siteUrl1">
        <p class="name">대표 사이트</p>
        <div class="info">
          <p v-if="designerInfo.siteUrl1">{{ designerInfo.siteUrl1 }}</p>
          <p v-if="designerInfo.siteUrl2">{{ designerInfo.siteUrl2 }}</p>
          <p v-if="designerInfo.siteUrl3">{{ designerInfo.siteUrl3 }}</p>
        </div>
      </div>
    </div>
    <div v-if="designerInfo.fileList" :class="compType == '1' ? 'mosaic' : ''">
      <div
        class="fileupload-wrap mt-20"
        v-if="designerInfo.fileList?.length != 0"
      >
        <div></div>
        <div class="thumbnails">
          <p class="name">소개 이미지</p>
          <div class="thumbnails-list mt-12">
            <div
              v-for="(y, j) in designerInfo.fileList"
              :key="j"
              class="thumbnails-list-area pointer"
              :class="y.sourceFileName.split('.')[1]"
            >
              <!-- @click="moveSlide(j)" -->
              <img
                v-if="
                  ['.psd', '.ai', '.pdf', '.xlsx'].some(
                    (e) => y.sourceFileName.indexOf(e) != -1
                  )
                "
                :src="
                  require(`@/assets/img/thumbnail-${
                    y.sourceFileName.split('.')[1]
                  }.png`)
                "
              />
              <img v-else :src="y.fileUrl" />
              <!-- <a @click="downloadImg(y.fileUrl, y.sourceFileName)" download=""
                  ><img :src="y.fileUrl"
                /></a> -->
              <!-- <a :href="y.fileUrl" download><img :src="y.fileUrl" /></a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="designer-bottom">
      <p class="name">소개글</p>
      <p
        class="info mt-12 mb-20"
        v-html="designerInfo.compDesc?.split('\n').join('<br />')"
      ></p>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompDesignOrderDetailDesigner",
  props: {
    designerInfo: {
      type: Object,
      description: "Card data",
    },
    detailData: {
      type: Object,
      description: "Card data",
    },
  },
  data() {
    return {
      d: {},
      i: {},
      swiperIndex: 0,
      get userId() {
        return sessionStorage.getItem("userId");
      },
      fileUrl: "",
      displayFlag: "",
    };
  },
  watch: {
    designerInfo(val) {
      console.log(val);
    },
    detailData(val) {
      console.log(val);
      this.d = val;
    },
  },
  mounted() {
    console.log(this.designerInfo);
    if (this.designerInfo) {
      if (this.designerInfo.fileList && this.designerInfo.fileList.length > 0) {
        console.log(this.designerInfo.fileList);
        this.fileUrl = this.designerInfo.fileList[0]?.fileUrl;
      }
    }
    this.displayFlag = this.detailData?.displayFlag;
  },
  methods: {
    toggleDisplay(displayFlag) {
      console.log(displayFlag);
      displayFlag = displayFlag ? 1 : 0;
      this.displayFlag = !displayFlag;
    },
    saveFlag() {
      this.displayFlag = this.displayFlag ? 1 : 0;
      this.$API.designOrder
        .modifyDisplay(
          this.userId,
          this.detailData.designOrderNo,
          this.displayFlag
        )
        .then((e) => {
          this.showToast("발주자 정보 설정이 변경되었습니다.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.designer {
  padding: 20px 28px;
  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $neutral-80;
  }
  .save-btn {
    color: $neutral-60;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4px;
    border: 1px solid $neutral-20;
    background: $neutral-5;
    padding: 2px 12px;
  }
  &-top {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: 1px solid $neutral-20;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $neutral-80;
      gap: 4px;
      .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      .area {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  &-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .name {
      font-size: 15px;
      font-weight: 500;
      line-height: 23px;
    }
    .info {
      display: flex;
      gap: 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      p {
        padding: 4px;
        border-radius: 4px;
        background: $primary-5;
      }
    }
  }
  &-bottom {
    margin-top: 20px;
    .name {
      font-weight: 500;
      font-size: 15px;
      line-height: 23px;
      color: $neutral-80;
    }
    .info {
      padding: 16px;
      min-height: 160px;
      border: 1px solid $neutral-20;
      border-radius: 4px;
    }
  }
}

.img-swiper {
  :deep(.swiper) {
    border: 1px solid $neutral-20;
    .swiper-button-prev,
    .swiper-button-next {
      color: rgba(255, 255, 255, 0.7);
      font-weight: bold;
    }
    .swiper-slide {
      background-color: $neutral-0;
      // flex-direction: column;
      .swiper-img {
        width: 100%;
        height: 240px;
        object-fit: contain;
        &.file {
          padding: 120px;
        }
      }
    }
  }
}

.thumbnails {
  display: flex;
  flex-direction: column;
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  &-list {
    gap: 12px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    // justify-content: center;
    grid-template-columns: repeat(auto-fill, 54px);
    &-area {
      width: 106px;
      height: 106px;
      border-radius: 4px;
      border: 1px solid $neutral-10;
      &.psd {
        border-color: #60b2ff;
      }
      &.ai {
        border-color: $primary-50;
      }
      &.psd,
      &.ai,
      &.xlsx {
        img {
          padding: 10px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.mosaic {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
</style>
