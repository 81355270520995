<template>
  <div class="filter-menu">
    <div class="filter-menu-value pointer" :class="showSelectBoxList ? 'active' : ''" @click="toggleShowList()">
      <p v-if="selectDataTxt" class="filter-menu-value-txt">{{ selectDataTxt }}</p>
      <img src="@/assets/icon/ic-selectbox.svg">
    </div>
    <ul class="filter-menu-list" :class="showSelectBoxList ? '' : 'notshow'">
      <li v-for="(x, i) in data" :key="i" class="filter-menu-list-item pointer" @click="selectDataUpdate(x, i)">{{ x.name }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'filterMenu',
  props: {
    data: {
      type: Object,
      description: 'select box data'
    },
    selectData: {
      type: String,
      description: 'selected data'
    },
    placeholderTxt: {
      type: String,
      description: 'placeholder txt'
    },
    filterType: {
      type: String,
      description: 'type txt'
    },
  },
  watch: {
    selectData(newVal) {
      this.selectDataTxt = newVal
    },
  },
  mounted () {
    this.selectDataTxt = this.selectData
  },
  data () {
    return {
      showSelectBoxList: false,
      selectDataTxt: ''
    }
  },
  methods: {
    toggleShowList () {
      this.showSelectBoxList = !this.showSelectBoxList
    },
    selectDataUpdate (d, i) {
      this.selectDataTxt = d.name
      this.showSelectBoxList = false
      let data = d
      d['i'] = i
      this.$emit('selectData', data, this.filterType)
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-menu {
    position: relative;
    &-value {
      // border: solid #E6E6E6;
      border: solid $neutral-10;
      border-width: 0 0 rem(1px) 0;
      padding-left: rem(16px);
      padding-right: rem(16px);
      display: flex;
      align-items: center;
      height: rem(50px);

      &-txt {
        width: 100%;
        color: $neutral-95;
        font-size: 16px;
        line-height: 22px;
        flex: 1;
        &.placeholder {
          color: $neutral-30;
        }
      }
      &.active {
        // border-color: $primary-50;
      }
    }
    &-list {
      position: absolute;
      top: 100%;
      left: -1px;
      width: calc(100% + 2px);
      padding: rem(8px) 0;
      z-index: 1;
      background-color: $neutral-0;
      overflow: hidden;
      border: solid $neutral-10;
      border-width: 0 rem(1px) rem(1px) rem(1px);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      &-item {
        font-size: 16px;
        line-height: 24px;
        padding: rem(8px) rem(15px);
        &:hover {
          background-color: $neutral-variation-20;
        }
      }
      &.notshow {
        max-height: 0;
        display: none;
      }
    }
  }
</style>