<template>
  <div class="content-wrap" v-show="step == 4">
    <div class="spec-wrap">
      <p
        class="spec-wrap-btn"
        v-for="(x, i) in checkPrintImg"
        :key="i"
        @click="i == 0 ? toggleComm('checkPrintImg', i) : goModifyPrint()"
        :class="x.isActive ? 'active' : ''"
      >
        {{ x.name }}
      </p>
    </div>
    <div
      class="content-title mt-40"
      v-if="checkPrintImg.filter((e) => e.isActive).length > 0"
    >
      프린팅 위치를 선택하여 이미지를 첨부해주세요.
    </div>
    <div class="row" v-if="checkPrintImg.filter((e) => e.isActive).length > 0">
      <div class="list-wrap mt-0">
        <div class="title">
          <p>
            <SvgImg
              :size="20"
              :d="$store.state.meta.svg.warningIcon"
              :color="$store.state.meta.svg.color.neutral80"
            />
          </p>
          <p>안내사항</p>
        </div>
        <div class="content">
          <p>※</p>
          <p>ai, png, jpg 형식의 고화질 이미지를 첨부해주세요.</p>
        </div>
        <div class="content">
          <p>※</p>
          <p>
            이미지 파일의 해상도는 가로 또는 세로 중 긴축 기준으로 2,500px 이상,
            해상도 1,500dpi 이상으로 지정해주세요.
          </p>
        </div>
        <div class="content">
          <p>※</p>
          <p>
            작은 이미지를 임의로 크게 확대하여 인쇄할 경우, 화질이 깨질 수
            있습니다.
          </p>
        </div>
        <div class="content">
          <p>※</p>
          <p>
            모니터나 핸드폰 화면과 실제 인쇄 색상은 다르게 보일 수 있습니다.
          </p>
        </div>
        <div class="content">
          <p>※</p>
          <p>인쇄 위치에 따라 크기의 제한이 있을 수 있습니다.</p>
        </div>
        <div class="content">
          <p>※</p>
          <p>
            ‘자수’를 원하시는 경우, 디자인 검토 후 작업 가능 여부를 알려드리며,
            추가 요금이 발생할 수 있습니다.
          </p>
        </div>
        <div class="content">
          <p>※</p>
          <p>
            상표 및 디자인 도용, 저작권이 있는 디자인의 제작 의뢰를 절대
            금지하며, 고객님이 판매 목적으로 이를 어길 시, 저작권 위반으로
            발생되는 모든 사항에 대하여 ‘오슬’에서는 책임지지 않습니다.
          </p>
        </div>
      </div>
    </div>
    <div
      class="row-flex"
      v-if="checkPrintImg.filter((e) => e.isActive).length > 0"
    >
      <div class="row-flex-btns" v-if="isMobile == 'true'">
        <div
          class="row-flex-btns-btn"
          v-for="(check, i) in checkPrint"
          :key="i"
          :class="{ active: check.isActive }"
          @click="toggleCeck('checkPrint', i)"
        >
          <p>{{ check.name }}</p>
        </div>
      </div>
      <div
        class="row-flex-wrap"
        v-if="
          (isMobile == 'true' &&
            checkPrint.filter((e) => e.isActive)[0].cd == '1') ||
          isMobile == 'false'
        "
      >
        <div class="content-title" v-if="isMobile == 'false'">Front</div>
        <div class="content-desc ml-0">
          해당 부위 선택 후, 이미지를 첨부해주세요.
        </div>
        <div class="print-wrap">
          <img
            :src="require(`@/assets/img/print-front-img.png`)"
            v-if="items.filter((e) => e.isActive)[0].cd == '1'"
          />
          <img
            :src="require(`@/assets/img/print-front-img-mtm.png`)"
            v-if="items.filter((e) => e.isActive)[0].cd == '2'"
          />
          <div
            class="print-wrap-img front"
            v-for="(printData, i) in printFrontImgs"
            :key="i"
            :class="{
              active: printData.img.fileUrl,
              'tshirt-front': items.filter((e) => e.isActive)[0].cd == '1',
              'mtm-front': items.filter((e) => e.isActive)[0].cd == '2',
            }"
          >
            <div
              class="file-area"
              @click="openPrint('front', i)"
              v-if="printData.img.fileUrl"
            >
              <div class="file-area-complete">
                <SvgImg
                  :size="20"
                  :d="$store.state.meta.svg.uploadIcon"
                  :color="$store.state.meta.svg.color.primary50"
                />
                <p v-if="i != 0 && i != 4 && i != 5 && i != 9">
                  업로드 <br />완료
                </p>
              </div>
            </div>
            <div
              class="file-area"
              v-html="printData.name.replace(' ', '<br/>')"
              @click="openPrint('front', i)"
              v-else
            ></div>
          </div>
        </div>
      </div>
      <div
        class="row-flex-wrap"
        v-show="
          (isMobile == 'true' &&
            checkPrint.filter((e) => e.isActive)[0].cd == '2') ||
          isMobile == 'false'
        "
      >
        <div class="content-title" v-if="isMobile == 'false'">Back</div>
        <div class="content-desc ml-0">
          해당 부위 선택 후, 이미지를 첨부해주세요.
        </div>
        <div class="print-wrap">
          <img
            :src="require(`@/assets/img/print-back-img.png`)"
            v-if="items.filter((e) => e.isActive)[0].cd == '1'"
          />
          <img
            :src="require(`@/assets/img/print-back-img-mtm.png`)"
            v-if="items.filter((e) => e.isActive)[0].cd == '2'"
          />
          <div
            class="print-wrap-img"
            v-for="(printData, i) in printBackImgs"
            :key="i"
            :class="{
              active: printData.img.fileUrl,
              'tshirt-back': items.filter((e) => e.isActive)[0].cd == '1',
              'mtm-back': items.filter((e) => e.isActive)[0].cd == '2',
            }"
          >
            <div
              class="file-area"
              @click="openPrint('back', i)"
              v-if="printData.img.fileUrl"
            >
              <div class="file-area-complete">
                <SvgImg
                  :size="20"
                  :d="$store.state.meta.svg.uploadIcon"
                  :color="$store.state.meta.svg.color.primary50"
                />
                <p>업로드 <br />완료</p>
              </div>
            </div>
            <div
              class="file-area"
              v-html="printData.name.replace(' ', '<br/>')"
              @click="openPrint('back', i)"
              v-else
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="content-bottom"
      v-if="checkPrintImg.filter((e) => e.isActive).length > 0"
    >
      <div class="validation mt-11">
        <p class="active">(필수)</p>
        <p :class="{ active: printList.length > 0 }">프린팅</p>
      </div>
      <div class="content-bottom-btns">
        <p
          class="content-bottom-btns-btn gray"
          @click="isPrintRefreshPopup = true"
        >
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.refreshIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          초기화
        </p>
        <p
          class="content-bottom-btns-btn"
          :class="{ active: printList.length > 0 }"
          @click="printList.length > 0 ? goModify() : ''"
        >
          <SvgImg
            :size="20"
            :d="$store.state.meta.svg.checkIcon"
            :color="$store.state.meta.svg.color.neutral0"
          />
          프린팅 선택
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SvgImg from "../SvgImg.vue";

export default {
  name: "CustomAdd",
  props: {
    step: {
      type: String,
    },
    printList: {
      type: Object,
    },
    isValidation: {
      type: Object,
    },
  },
  data() {
    return {
      items: [],
      checkPrintImg: [],
      printFrontImgs: [],
      printBackImgs: [],
      checkPrint: [
        { index: 0, cd: 1, name: "Front", isActive: true },
        { index: 1, cd: 2, name: "Back", isActive: false },
      ],
      isMobile: sessionStorage.getItem("isMobile"),
    };
  },
  mounted() {
    this.items = this.$store.state.customPrint.items;
    this.checkPrintImg = this.$store.state.customPrint.checkPrintImg;
    this.printFrontImgs = this.$store.state.customPrint.printFrontImgs;
    this.printBackImgs = this.$store.state.customPrint.printBackImgs;
  },
  methods: {
    toggleTab(index) {
      this.$emit("toggleTab", index);
    },
    toggleComm(type, index) {
      this.$emit("toggleComm", type, index);
    },
    toggleCeck(type, index) {
      this[type].map((e) => (e.isActive = false));
      this[type][index].isActive = true;
    },
    openPrint(type, index) {
      this.$emit("openPrint", type, index);
    },
    goModify() {
      this.$emit("goModify");
    },
    goModifyPrint() {
      this.$emit("goModifyPrint");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/custom.scss";
</style>
