<template>
  <div
    id="ticket"
    class="ticket"
    v-if="!this.$store.state.meta.popupInfo.isShow"
  >
    <div class="ticket-list" :class="isMobile == 'false' ? 'pc' : 'mb'">
      <div class="ticket-used">
        <p class="square"></p>
        <p class="ticket-used-top">현재 사용 중인 이용권</p>
        <div class="ticket-used-body" v-if="usedTicket">
          <div class="title">
            <p v-if="usedTicket.itemId == 'B10001'">광고 이용권</p>
            <p v-else-if="usedTicket.itemId == 'B10002'">매칭 이용권</p>
            <p v-else-if="usedTicket.itemId == 'B10003'">프리미엄 이용권</p>
            <p v-else-if="usedTicket.itemId == 'B10004'">
              오슬 이용권(봉제 / 패턴 / 샘플)
            </p>
            <p v-else-if="usedTicket.itemId == 'B10005'">프리미엄 이용권</p>
          </div>
          <div class="info">
            <p class="name">시작 일시</p>
            <p class="date">{{ usedTicket.startDate }}</p>
          </div>
          <div class="info mt-8">
            <p class="name">종료 일시</p>
            <p class="date">{{ usedTicket.endDate }}</p>
          </div>
          <div class="info mt-8">
            <p class="name">다음 결제일</p>
            <p class="date">{{ usedTicket.endDate }}</p>
          </div>
        </div>
        <div v-else class="empty">현재 사용 중인 이용권이 없습니다.</div>
      </div>
      <div class="ticket-list-view" v-if="isMobile == 'false'">
        <div class="ticket-list-filter">
          <p class="title">이용권 구매 내역</p>
          <!-- <div class="filter-date mt-20">
            <input
              class="txt"
              type="date"
              v-model="filters.startDate"
              @change="updateData()"
            />~
            <input
              class="txt"
              type="date"
              v-model="filters.endDate"
              @change="updateData()"
            />
          </div> -->
        </div>
        <div v-if="ticketList?.length > 0" class="card">
          <div class="card-ticket" v-for="(x, i) in ticketList" :key="i">
            <div class="title">
              <p class="buyname" v-if="x.itemId == 'B10001'">광고 이용권</p>
              <p class="buyname" v-else-if="x.itemId == 'B10002'">
                매칭 이용권
              </p>
              <p class="buyname" v-else-if="x.itemId == 'B10003'">
                프리미엄 이용권
              </p>
              <p class="buyname" v-else-if="x.itemId == 'B10004'">
                <span>* </span>오슬 이용권 (봉제 / 패턴 / 샘플)
              </p>
              <p class="buyname" v-else-if="x.itemId == 'B10005'">
                프리미엄 이용권
              </p>
              <p class="price"></p>
            </div>
            <div class="info mt-20">
              <p class="name">결제일</p>
              <p class="date">{{ x.startDate }}</p>
            </div>
            <div class="info mt-8">
              <p class="name">이용권 기간</p>
              <p class="date">
                {{ `${x.startDate} ~ ${x.endDate}` }}
              </p>
            </div>
          </div>
        </div>
        <div v-else class="no-list">
          <p class="no-list-txt">현재 사용 중인 이용권이 없습니다.</p>
          <!-- <div class="ticket-btns mt-24">
            <p class="ticket-btns-btn" @click="goPayment('B10004')">
              이용권 구매하기
            </p>
          </div>
          -->
        </div>
      </div>
      <div v-else class="ticket-list-mobile pointer" @click="setPopup(true)">
        <div class="hist-btn">이용권 구매 내역</div>
      </div>
    </div>

    <div class="ticket-buy">
      <div
        v-if="
          factoryInfo.workProcessCd == '01031' ||
          factoryInfo.workProcessCd == '01033'
        "
        class="ticket-buy-wrap"
      >
        <p class="ticket-buy-title">오슬 이용권 (봉제 / 패턴 / 샘플)</p>
        <div class="ticket-buy-top">
          <p class="sale-price">88,000원/월 <span>(VAT 포함)</span></p>
          <p class="price">66,000원/월 <span>(VAT 포함)</span></p>
        </div>
        <div class="ticket-buy-body">
          <div class="flex-column flex1 gap12">
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />공장찾기 상단 노출
            </p>
            <p><img src="@/assets/icon/ic-plus-yellow.svg" />매칭 의뢰 열람</p>

            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />월 자동 정기 결제
            </p>
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />발주, 정산 이용 가능
            </p>
          </div>
          <div class="flex-column flex1 gap12">
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />홈페이지 메인 노출
            </p>
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />검색결과 상단 노출
            </p>
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />실시간 맞춤형 매칭
              알림
            </p>
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />SNS, 뉴스레터 등
              홍보
            </p>
          </div>
        </div>
        <!-- <div v-if="isMobile == 'false'" class="ticket-buy-img">
          <img src="@/assets/img/img-ticket1.png" />
          <img src="@/assets/img/img-ticket2.png" />
          <img src="@/assets/img/img-ticket3.png" />
          <img src="@/assets/img/img-ticket4.png" />
        </div> -->
        <div class="ticket-buy-img">
          <swiper
            :slidesPerView="1"
            :pagination="{ clickable: true }"
            :centeredSlides="true"
            :modules="modules"
          >
            <swiper-slide>
              <img src="@/assets/img/img-ticket1.png" />
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/img/img-ticket2.png" />
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/img/img-ticket3.png" />
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/img/img-ticket4.png" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="ticket-buy-bottom" @click="goPayment('B10004')">
          <p>결제 하기</p>
        </div>
      </div>

      <div
        v-if="
          factoryInfo.workProcessCd == '01034' ||
          factoryInfo.workProcessCd == '01035'
        "
        class="ticket-buy-wrap"
      >
        <p class="ticket-buy-title">
          오슬 이용권 (나염 / 전사 / 자수 / 특종 / 완성)
        </p>
        <div class="ticket-buy-top">
          <p class="sale-price">44,000원/월 (VAT 포함)</p>
          <p class="price">33,000원/월 (VAT 포함)</p>
        </div>
        <div class="ticket-buy-body">
          <div class="flex-column flex1 gap12">
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />공장찾기 상단 노출
            </p>
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />검색결과 상단 노출
            </p>
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />발주, 정산 이용 가능
            </p>
          </div>
          <div class="flex-column flex1 gap12">
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />홈페이지 메인 노출
            </p>
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />월 자동 정기 결제
            </p>
            <p>
              <img src="@/assets/icon/ic-plus-yellow.svg" />SNS, 뉴스레터 등
              홍보
            </p>
          </div>
        </div>
        <div v-if="isMobile == 'false'" class="ticket-buy-img">
          <swiper
            :slidesPerView="1"
            :pagination="{ clickable: true }"
            :centeredSlides="true"
            :modules="modules"
          >
            <swiper-slide>
              <img src="@/assets/img/img-ticket1.png" />
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/img/img-ticket2.png" />
            </swiper-slide>
          </swiper>
        </div>
        <div v-else class="ticket-buy-img"></div>
        <div class="ticket-buy-bottom" @click="goPayment('B10005')">
          <p>결제 하기</p>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-hist" v-if="this.$store.state.meta.popupInfo.isShow">
    <div class="ticket-list-view">
      <div class="filter-date ml-20 mb-20">
        <input
          class="txt"
          type="date"
          v-model="filters.startDate"
          @change="updateData()"
        />~
        <input
          class="txt"
          type="date"
          v-model="filters.endDate"
          @change="updateData()"
        />
      </div>
      <div v-if="ticketList?.length > 0" class="card">
        <div class="card-ticket" v-for="(x, i) in ticketList" :key="i">
          <div class="title">
            <p class="buyname" v-if="x.itemId == 'B10001'">광고 이용권</p>
            <p class="buyname" v-else-if="x.itemId == 'B10002'">매칭 이용권</p>
            <p class="buyname" v-else-if="x.itemId == 'B10003'">
              프리미엄 이용권
            </p>
            <p class="buyname" v-else-if="x.itemId == 'B10004'">
              오슬 이용권 (봉제 / 패턴 / 샘플)
            </p>
            <p class="buyname" v-else-if="x.itemId == 'B10005'">
              프리미엄 이용권
            </p>
            <p class="price"></p>
          </div>
          <div class="info mt-12">
            <p class="name">결제일</p>
            <p class="date">{{ x.startDate }}</p>
          </div>
          <div class="info mt-8">
            <p class="name">이용권 기간</p>
            <p class="date">
              {{ `${x.startDate} ~ ${x.endDate}` }}
            </p>
          </div>
        </div>
      </div>
      <div v-else class="no-list">
        <p class="no-list-txt">현재 사용 중인 이용권이 없습니다.</p>
        <!-- <div class="ticket-btns-btns mt-24">
          <p class="ticket-btns-btn" @click="gotoPage('/ticket/ticketBuy')">
            이용권 구매하기
          </p>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

export default {
  name: "CompTicketList",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  data() {
    return {
      filters: {
        status: [
          { name: "전체", cd: "" },
          { name: "광고 서비스", cd: "1" },
          { name: "매칭 서비스", cd: "2" },
          { name: "프리미엄 서비스", cd: "3" },
        ],
        orderType: [
          { name: "최신순", cd: "0" },
          { name: "과거순", cd: "1" },
        ],
        statusValue: "",
        orderTypeValue: "",
        startDate: "",
        endDate: "",
      },
      ticketList: [],
      usedTicket: {},
      factoryInfo: {},
      get isMobile() {
        return sessionStorage.getItem("isMobile");
      },
    };
  },
  unmounted() {
    this.setPopup(false);
  },
  mounted() {
    const today = new Date();
    let oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    this.filters.startDate =
      oneYearAgo.getFullYear() +
      "-" +
      String(oneYearAgo.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(oneYearAgo.getDate()).padStart(2, "0");
    this.filters.endDate =
      today.getFullYear() +
      "-" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(today.getDate()).padStart(2, "0");

    console.log(this.filters.startDate);

    this.updateData();
    this.$API.mypage
      .getFactoryInfo(sessionStorage.getItem("userId"))
      .then((res) => {
        this.factoryInfo = res.data.body;
        console.log(this.factoryInfo);

        var dynamicHeightElement = document.getElementById("ticket");
        var windowHeight = window.innerHeight;
        var dynamicHeight = windowHeight - 60;
        console.log(dynamicHeight);
        dynamicHeightElement.style.height = dynamicHeight + "px";
      });
  },
  methods: {
    goPayment(itemId) {
      this.setCookie("userId", sessionStorage.getItem("userId"), 1);
      this.setCookie("itemId", itemId, 1);
      this.gotoUrl(`/payment.html`, 0);
      // this.gotoPage(`/payment/buy/${itemId}`);
    },
    moveService() {
      if (!sessionStorage.getItem("userId")) {
        // 로그인 안되어있을때 로그인 화면으로
        this.loginCheck();
      } else {
        this.gotoPage(`/ticketbuy`);
      }
    },
    updateData() {
      this.$API.payment
        .getUsedticket(sessionStorage.getItem("userId"))
        .then((res) => {
          this.usedTicket = res.data.body;
        });
      this.$API.payment
        .ticketList(sessionStorage.getItem("userId"), this.filters)
        .then((res) => {
          this.ticketList = res.data.body;
          console.log(this.ticketList);
        });
    },
    changePage(selectPageIndex) {
      this.pageIndex = selectPageIndex;
      this.updateList();
    },
    selectData(data, type) {
      if (type === "status") {
        this.filters.statusValue = data.cd;
      } else if (type === "orderType") {
        this.filters.orderTypeValue = data.cd;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket {
  @media (min-width: 960px) {
    display: flex;
  }
  gap: 40px;
  &-list {
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    @include hidden-scroll();
    width: 100%;
    max-width: 520px;
    border-width: 0px 1px;
    border-style: solid;
    border-color: $neutral-10;
    &.pc {
      height: calc(100vh - 70px);
    }
    .ticket-used {
      padding: 40px 24px 0;
      @media (max-width: 960px) {
        padding: 40px 20px 0;
      }
      &-top {
        margin-left: 8px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: $primary-80;
      }
      .square {
        width: 8px;
        height: 8px;
        background-color: $primary-50;
      }
      &-body {
        margin-top: 40px;
        padding: 20px;
        border: 1px solid $neutral-20;
        border-radius: 4px;
        .title {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $primary-60;
        }

        .info {
          margin-top: 20px;
          display: flex;
          gap: 8px;
          align-items: center;
          position: relative;
          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: $neutral-60;
          }
          .date {
            position: absolute;
            left: 120px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $neutral-60;
          }
        }
      }
      .empty {
        margin: 40px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 166px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid $neutral-20;
        border-radius: 4px;
      }
    }
    &-filter {
      margin: 20px 24px 0px 24px;
      padding-top: 40px;
      border-top: 1px solid $primary-20;
      .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &-view {
      display: flex;
      flex-direction: column;
      width: 100%;

      .card {
        color: $neutral-80;
        display: flex;
        flex-direction: column;
        &-ticket {
          padding-bottom: 20px;
          margin: 16px 24px;
          border-bottom: 1px solid $neutral-10;
          .info {
            display: flex;
            align-items: center;
            position: relative;
            .name {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              color: $neutral-80;
            }
            .date {
              position: absolute;
              left: 150px;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: $neutral-60;
            }
          }
          .title {
            .buyname {
              font-size: 15px;
              font-weight: 500;
              line-height: 23px;
              color: $neutral-60;
            }
          }
        }
      }
      .no-list {
        margin-top: 70px;
        display: flex;
        justify-content: center;
        &-txt {
          display: flex;
          justify-content: center;
          margin-top: 40px;
          font-weight: 500;
          color: $neutral-40;
        }
      }
    }
    &-mobile {
      margin-bottom: 20px;
      border-bottom: 1px solid $primary-20;
      .hist-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px;
        height: 40px;
        color: $neutral-0;
        background-color: $neutral-30;
        border-radius: 4px;
      }
    }
  }
  &-buy {
    @media (min-width: 960px) {
      width: 400px;
    }
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    @include hidden-scroll();
    position: relative;
    height: calc(100vh - 70px);
    border-width: 0px 1px;
    border-style: solid;
    border-color: $neutral-10;
    &-wrap {
      margin: 40px 20px;
    }
    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $neutral-80;
    }
    &-top {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .sale-price {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $neutral-30;

        position: relative;
        display: inline-block;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 120px;
          height: 1px;
          background-color: $primary-50;
        }
        span {
          font-size: 12px;
        }
      }

      .price {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $primary-60;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    &-body {
      margin: 20px 0;
      display: flex;
      flex-wrap: wrap;
      p {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: $primary-60;
        }

        @media (min-width: 960px) {
          gap: 12px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
        width: 100%;
        display: flex;
        gap: 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        img {
          width: 16px;
        }
      }
    }

    &-img {
      margin-top: 20px;
      padding: 20px 0;
      display: flex;
      gap: 12px 20px;
      border-top: 1px solid $primary-10;
      border-bottom: 1px solid $primary-10;
    }
    .swiper {
      &-slide {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
          @media (max-width: 960px) {
            width: 240px;
            margin: 0 auto;
          }
          width: 240px;
        }
      }
      :deep(.swiper-pagination) {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 20px;
        &-bullet {
          width: rem(8px);
          height: rem(8px);
          background: $neutral-20;
          opacity: 1;
          margin: 0 rem(2px);
          &-active {
            width: 10px;
            height: 10px;
            background: $neutral-60;
          }
        }
      }
    }
    &-bottom {
      margin-top: 40px;
      p {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        background-color: $primary-50;
        border-radius: 4px;
        color: $neutral-0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.filter-date {
  display: flex;
  gap: 20px;
  .txt {
    cursor: pointer;
    width: 136px;
    padding: 0 12px;
    background: $neutral-0;
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: $neutral-20;
    border-radius: 4px;
    height: 32px;
  }
}
.popup-hist {
  position: absolute;
  z-index: 530;
  width: 100%;
  height: 100%;
  // height: calc(100% - 56px);
  overflow-y: auto;
  // padding-bottom: 70px;
  background-color: $neutral-0;
  max-height: 100vh;
  max-width: 960px;
  transform: none !important;
  border: 1px solid $neutral-10;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  right: 0;
  top: 0;
}
</style>
