<template>
  <div class="size-wrap" :class="{ big: tableWidth == 'big' }">
    <div class="size-wrap-flex">
      <p class="unit">(단위 : cm)</p>
      <p
        class="unit"
        v-for="(x, i) in sizeList.filter((e) => e.isActive)"
        :key="i"
      >
        {{ x.name }}
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">총 기장</p>
      <p
        class="unit column"
        :class="{ disabled: disabled }"
        v-for="(x, i) in sizeList.length"
        :key="i"
      >
        {{ sizeList[i].spec?.tolSize }}
        <!-- <input
          v-if="!disabled"
          class="size"
          :value="sizeList[i].spec.tolSize"
          placeholder="00.0"
        /> -->
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">가슴 단면</p>
      <p
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.chsSize }}
        <!-- <input v-if="!disabled" class="size" placeholder="00.0" /> -->
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">밑단 단면</p>
      <p
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.hesSize }}
        <!-- <input v-if="!disabled" class="size" placeholder="00.0" /> -->
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">소매 기장</p>
      <p
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.sllSize }}
        <!-- <input v-if="!disabled" class="size" placeholder="00.0" /> -->
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">어깨 단면</p>
      <p
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.shsSize }}
        <!-- <input v-if="!disabled" class="size" placeholder="00.0" /> -->
      </p>
    </div>
    <!-- [{{ sizeList }}] -->
    <div
      class="size-wrap-flex"
      v-if="sizeList.filter((e) => e.spec?.whsSize)?.length > 0"
    >
      <p class="unit">허리 둘레</p>
      <p
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.whsSize }}
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">암홀 (직선)</p>
      <p
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.ahlSize }}
        <!-- <input v-if="!disabled" class="size" placeholder="00.0" /> -->
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">소매단 단면</p>
      <p
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.scsSize }}
        <!-- <input v-if="!disabled" class="size" placeholder="00.0" /> -->
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">소매통 단면</p>
      <p
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.sbsSize }}
        <!-- <input v-if="!disabled" class="size" placeholder="00.0" /> -->
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">목 너비</p>
      <p class="empty" v-if="!sizeList[0]?.spec?.newSize">
        디자인 / 디테일 선택 후, 별도 산출
      </p>
      <p
        v-else
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.newSize }}
      </p>
    </div>
    <div class="size-wrap-flex">
      <p class="unit">목 파임</p>
      <p class="empty" v-if="!sizeList[0]?.spec?.newSize">
        디자인 / 디테일 선택 후, 별도 산출
      </p>
      <p
        v-else
        class="unit column"
        v-for="(x, i) in sizeList.length"
        :key="i"
        :class="{ disabled: disabled }"
      >
        {{ sizeList[i].spec?.nedSize }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "filterMenuNew",
  props: {
    size: {
      type: Object,
      description: "img list name",
    },
    disabled: {
      type: Boolean,
      description: "img list name",
    },
    tableWidth: {
      type: String,
      description: "img list name",
    },
  },
  data() {
    return {
      showSelectBoxList: false,
      selectDataTxt: "",
      imgList: [],
      imgUrl: "",
      imgDesc: "",
      isAdd: false,
      sizeList: [],
    };
  },
  watch: {
    size(newVal) {
      this.sizeList = newVal;
    },
  },
  mounted() {
    this.sizeList = this.size;
  },
  methods: {
    fileUpload(type, event) {
      console.log(event);
      console.log(this[type]);
      for (var i = 0; i < event.target.files.length; i++) {
        this.$API.comm
          .fileUpload(this.userId, event.target.files[i])
          .then((res) => {
            let imgList = [];
            imgList.fileName = event.target.files[0].name;
            imgList.fileNo = res.data.body.img.fileNo;
            imgList.fileUrl = res.data.body.img.previewUrl;
            this.imgList.push(imgList);
          });
      }
      console.log(this.imgList);
    },
  },
};
</script>

<style lang="scss" scoped>
.size-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  &.big {
    width: 692px;
  }
  &-flex {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    .spec {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      font-weight: 400;
      font-size: 12px;
      &-row {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 36px;
        border-radius: 4px;
        color: $neutral-0;
        background: $neutral-60;
      }
      &-name {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 76px;
        height: 36px;
        border-radius: 4px;
        color: $neutral-0;
        background: $neutral-60;
      }
      .required {
        color: $neutral-80;
        background: $neutral-0;
        border: 1px solid $primary-20;
      }
    }
    .unit {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 13px;
      // min-width: 80px;
      height: 36px;
      // color: $neutral-0;
      // background: $neutral-60;
      border-radius: 4px;
      color: $neutral-0;
      background: $neutral-60;
      &:first-child {
        min-width: 108px;
        max-width: 108px;
      }
      &.disabled {
        background: $neutral-5 !important;
        border: 1px solid $neutral-10 !important;
      }
      &.column {
        color: $neutral-80;
        background: $neutral-0;
        border: 1px solid $neutral-10;
        font-size: 14px;
        font-weight: 400;
      }
      &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid $neutral-10;
        &-color {
          width: 24px;
          height: 24px;
          border-radius: 12px;
        }
      }
    }
    .empty {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      height: 38px;
      border-radius: 4px;
      color: $neutral-20;
      background: $neutral-5;
    }
    .deviation {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      min-width: 40px;
      height: 38px;
      background: $neutral-3;
      border: 1px solid $neutral-20;
      border-radius: 4px;
    }
    .size {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: $neutral-0;
      border: 1px solid $neutral-20;
      border-radius: 4px;
      background: $neutral-60;
    }
    input {
      text-align: center;
      color: $neutral-80 !important;
      background: $neutral-0 !important;
    }
  }
  input {
    padding: 8px;
    // background: #ffffff;
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: $neutral-30;
    border-radius: 4px;
    width: 100%;
    height: 32px;

    // &:focus {
    //   border-color: $primary-50;
    // }
    // &:hover {
    //   background: #fffbf2;
    // }
  }
  &.row {
    flex-direction: row !important;
  }
  .input-explain {
    font-size: 14px;
    color: $neutral-60;
  }
  &.error input {
    border-color: $secondary-60;
  }

  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid $neutral-20;
    border-radius: 4px;
    height: 120px;
    resize: none;
    font-size: 14px;
    &::placeholder {
      color: $neutral-30;
    }
  }
}
</style>
