<template>
  <div class="rating">
    <div v-if="isDisable">
      <img v-for="(x, i) in 5" :key="i" src="@/assets/icon/ic-star-grey.svg">
    </div>
    <div v-else-if="readOnly">
      <img v-for="(x, i) in starFloorValue" :key="i" src="@/assets/icon/ic-star-full.svg">
      <img v-if="starValue % 1 != 0" src="@/assets/icon/ic-star-half.svg">
      <img v-for="(x, i) in 5-starFloorValue - (starValue % 1 != 0 ? 1 : 0)" :key="i" src="@/assets/icon/ic-star-empty.svg">
    </div>
    <div v-else>
      <img v-for="(x, i) in 5" :key="i" :src="require(`@/assets/icon/ic-star-${selectStar > i ? 'full' : 'empty'}.svg`)" @click="updateStar(i+1)" class="pointer">
    </div>
  </div>
</template>
<script>
export default {
  name: 'filterMenu',
  props: {
    val: {
      type: Number,
      description: 'value'
    },
    readOnly: {
      type: Boolean,
      description: 'read only'
    },
    isDisable: {
      type: Boolean,
      description: ''
    },
    type: {
      type: String,
      description: 'type'
    }
  },
  watch: {
    val(newVal) {
      this.starValue = newVal
      this.starFloorValue = Math.floor(newVal)
    },
  },
  mounted () {
    this.starValue = this.val
    this.starFloorValue = Math.floor(this.val)
  },
  data () {
    return {
      selectStar: 0,
      starValue: 0,
      starFloorValue: 0,
    }
  },
  methods: {
    updateStar (i) {
      this.selectStar = i
      this.$emit('selectStar', this.type, i)
    }
  }
}
</script>

<style lang="scss" scoped>
  img:not(:first-child) {
    margin-left: rem(4px);
  }
</style>