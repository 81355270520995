/* eslint-disable vue/multi-word-component-names */
import LoadingSpinner from "@/components/comm/LoadingSpinner";
// import ChatRoomContent from "@/components/ChatRoom/ChatRoomContent";
// import ChatRoomFooter from "@/components/ChatRoom/ChatRoomFooter";
// import WebDesignOrderPayment from "@/components/ChatRoom/Footer/web/WebDesignOrderPayment";
// import WebDesignOrderList from "@/components/ChatRoom/Footer/web/WebDesignOrderList";
// import WebDesignOrderContract from "@/components/ChatRoom/Footer/web/WebDesignOrderContract";
// import ContractLoad from "@/components/ChatRoom/Footer/ContractLoad";
// import PaymentLoad from "@/components/ChatRoom/Footer/PaymentLoad";
// import OrderLoad from "@/components/ChatRoom/Footer/OrderLoad";
// import FileUpload from "@/components/ChatRoom/Footer/FileUpload";
// import ImageUpload from "@/components/ChatRoom/Footer/ImageUpload";
// import CallMessage from "@/components/ChatRoom/Footer/CallMessage";
// import InputMessage from "@/components/ChatRoom/Footer/InputMessage";
// import InputMessageMobile from "@/components/ChatRoom/Footer/InputMessageMobile";
// import Message from "@/components/ChatRoom/MessageType/Message";
// import ImageMessage from "@/components/ChatRoom/MessageType/ImageMessage";
// import OrderMessage from "@/components/ChatRoom/MessageType/OrderMessage";
// import ContractMessage from "@/components/ChatRoom/MessageType/ContractMessage";
// import DesignOrderMessage from "@/components/ChatRoom/MessageType/DesignOrderMessage";
// import PaymentMessage from "@/components/ChatRoom/MessageType/PaymentMessage";
// import FileMessage from "@/components/ChatRoom/MessageType/FileMessage";
// import ChatOptionModal from "@/components/ChatRoom/ChatOptionModal";
// import ChatSearchModal from "@/components/ChatRoom/ChatSearchModal";
// import ChatImageModal from "@/components/ChatRoom/ChatImageModal";
import Tooltip from "@/components/Tooltip";
import BaseBtn from "@/components/BaseBtn";
import CheckBox from "@/components/CheckBox";
import SvgImg from "@/components/SvgImg";
import SearchBox from "@/components/SearchBox";
import SelectBox from "@/components/SelectBox";
import SelectBiz from "@/components/SelectBiz";
import SelectBoxRadius from "@/components/SelectBoxRadius";
import SelectBoxMall from "@/components/SelectBoxMall";

import ImageEditor from "@/components/comm/ImageEditor";
import TopBanner from "@/components/comm/TopBanner";
import SizeTable from "@/components/comm/SizeTable";
import SizeTableMtm from "@/components/comm/SizeTableMtm";
import SizeTableInput from "@/components/comm/SizeTableInput";
import SizeTableInputMtm from "@/components/comm/SizeTableInputMtm";
import SizeColorTable from "@/components/comm/SizeColorTable";

import FilterMenu from "@/components/FilterMenu";
import FilterMenuNew from "@/components/FilterMenuNew";
import FactoryView from "@/components/FactoryView";
import PaymentView from "@/components/PaymentView";
import MatchingView from "@/components/MatchingView";
import PagingView from "@/components/PagingView";
import Card from "@/components/Card";
import Banner from "@/components/Banner";
import PointVariation from "@/components/PointVariation";
import RatingView from "@/components/RatingView";
import DesignOrderPcList from "@/components/designorder/PcList";
import DesignOrderPcCard from "@/components/designorder/PcCard";
import DesignOrderMainPcCard from "@/components/designorder/MainPcCard";
import DesignOrderMyCard from "@/components/designorder/MyCard";
import DesignOrderAddPopup from "@/components/designorder/DesignOrderAddPopup";
import DesignOrderAddPopupMo from "@/components/designorder/DesignOrderAddPopupMo";
import CompMaterialCard from "@/components/mall/CompMaterialCard";
import CompMaterialMainCard from "@/components/mall/CompMaterialMainCard";
import CompMaterialDetail from "@/components/mall/CompMaterialDetail";
import CompMaterialOrder from "@/components/mall/CompMaterialOrder";
import CompMaterialBuy from "@/components/mall/CompMaterialBuy";
import CompMaterialBuyCard from "@/components/mall/CompMaterialBuyCard";
import CompMaterialBuyDetail from "@/components/mall/CompMaterialBuyDetail";
import CompMaterialBasket from "@/components/mall/CompMaterialBasket";
import CompMaterialAdd from "@/components/mall/CompMaterialAdd";
import CompMaterialItemSwiper from "@/components/mall/CompMaterialItemSwiper";

import CompDesignOrderFactoryList from "@/components/designorder/CompDesignOrderFactoryList";
import CompDesignOrderDetail from "@/components/designorder/CompDesignOrderDetail";
import CompDesignOrderDetailDesigner from "@/components/designorder/CompDesignOrderDetailDesigner";
import PcFactoryDetail from "@/components/search/PcFactoryDetail";
import NormalCard from "@/components/search/NormalCard";
import PremiumCard from "@/components/search/PremiumCard";
import SearchMainCard from "@/components/search/SearchMainCard";
import CompContractDetail from "@/components/contract/CompContractDetail";
import CompContract from "@/components/contract/CompContract";
import CompContractList from "@/components/contract/CompContractList";
import CompCalculateList from "@/components/calculate/CompCalculateList";
import CompCalculateDetail from "@/components/calculate/CompCalculateDetail";
import CompManageList from "@/components/calculate/CompManageList";
import CompPaymentList from "@/components/payment/CompPaymentList";
import CompPaymentDetail from "@/components/payment/CompPaymentDetail";
import CompAlarmList from "@/components/mypage/CompAlarmList";
import CompAlarmDetail from "@/components/mypage/CompAlarmDetail";
import CompNoticeList from "@/components/mypage/CompNoticeList";
import CompNoticeDetail from "@/components/mypage/CompNoticeDetail";
import CompTicketList from "@/components/ticket/CompTicketList";
import CompCustomBuilder from "@/components/custom/CompCustomBuilder";
import CompCustomBuilderSize from "@/components/custom/CompCustomBuilderSize";
import CompCustomBuilderDtl from "@/components/custom/CompCustomBuilderDtl";
import CompCustomBuilderPrt from "@/components/custom/CompCustomBuilderPrt";
import CompCustomMain from "@/components/custom/CompCustomMain";
import CompCustomAdd from "@/components/custom/CompCustomAdd";
import CompCustomAddPopup from "@/components/custom/CompCustomAddPopup";
import CompCustomPreview from "@/components/custom/CompCustomPreview";
import CompCustomOrder from "@/components/custom/CompCustomOrder";
import CompCustomOrderDetail from "@/components/custom/CompCustomOrderDetail";
import CompCustomDetail from "@/components/custom/CompCustomDetail";
import CompCustomOrderList from "@/components/custom/CompCustomOrderList";

import DescAdd from "@/components/comm/DescAdd";
import CommFilterMenu from "@/components/comm/FilterMenu";

import BarChart from "@/components/chart/BarChart";
import DoughnutChart from "@/components/chart/DoughnutChart";
import DoughnutRate from "@/components/chart/DoughnutRate";

export default {
  install(Vue) {
    Vue.component("LoadingSpinner", LoadingSpinner);
    // Vue.component("WebDesignOrderPayment", WebDesignOrderPayment);
    // Vue.component("WebDesignOrderList", WebDesignOrderList);
    // Vue.component("WebDesignOrderContract", WebDesignOrderContract);
    // Vue.component("ChatImageModal", ChatImageModal);
    // Vue.component("ChatSearchModal", ChatSearchModal);
    // Vue.component("ChatOptionModal", ChatOptionModal);
    // Vue.component("CallMessage", CallMessage);
    // Vue.component("PaymentLoad", PaymentLoad);
    // Vue.component("ContractLoad", ContractLoad);
    // Vue.component("OrderLoad", OrderLoad);
    // Vue.component("FileUpload", FileUpload);
    // Vue.component("ImageUpload", ImageUpload);
    // Vue.component("ChatRoomContent", ChatRoomContent);
    // Vue.component("ChatRoomFooter", ChatRoomFooter);
    // Vue.component("Message", Message);
    // Vue.component("FileMessage", FileMessage);
    // Vue.component("OrderMessage", OrderMessage);
    // Vue.component("ContractMessage", ContractMessage);
    // Vue.component("DesignOrderMessage", DesignOrderMessage);
    // Vue.component("PaymentMessage", PaymentMessage);
    // Vue.component("ImageMessage", ImageMessage);
    // Vue.component("InputMessage", InputMessage);
    // Vue.component("InputMessageMobile", InputMessageMobile);
    Vue.component("Tooltip", Tooltip);
    Vue.component("BaseBtn", BaseBtn);
    Vue.component("CheckBox", CheckBox);
    Vue.component("SvgImg", SvgImg);
    Vue.component("SearchBox", SearchBox);
    Vue.component("SelectBox", SelectBox);
    Vue.component("SelectBiz", SelectBiz);
    Vue.component("SelectBoxRadius", SelectBoxRadius);
    Vue.component("SelectBoxMall", SelectBoxMall);
    Vue.component("TopBanner", TopBanner);
    Vue.component("ImageEditor", ImageEditor);
    Vue.component("SizeTable", SizeTable);
    Vue.component("SizeTableMtm", SizeTableMtm);
    Vue.component("SizeTableInput", SizeTableInput);
    Vue.component("SizeTableInputMtm", SizeTableInputMtm);
    Vue.component("SizeColorTable", SizeColorTable);
    Vue.component("FilterMenu", FilterMenu);
    Vue.component("FilterMenuNew", FilterMenuNew);
    Vue.component("FactoryView", FactoryView);
    Vue.component("PaymentView", PaymentView);
    Vue.component("MatchingView", MatchingView);
    Vue.component("PagingView", PagingView);
    Vue.component("Card", Card);
    Vue.component("Banner", Banner);
    Vue.component("PointVariation", PointVariation);
    Vue.component("RatingView", RatingView);
    Vue.component("DesignOrderPcList", DesignOrderPcList);
    Vue.component("DesignOrderPcCard", DesignOrderPcCard);
    Vue.component("DesignOrderMainPcCard", DesignOrderMainPcCard);

    Vue.component("DesignOrderMyCard", DesignOrderMyCard);
    Vue.component("DesignOrderAddPopup", DesignOrderAddPopup);
    Vue.component("DesignOrderAddPopupMo", DesignOrderAddPopupMo);
    Vue.component("CompMaterialCard", CompMaterialCard);
    Vue.component("CompMaterialMainCard", CompMaterialMainCard);
    Vue.component("CompMaterialDetail", CompMaterialDetail);
    Vue.component("CompMaterialOrder", CompMaterialOrder);
    Vue.component("CompMaterialBuy", CompMaterialBuy);
    Vue.component("CompMaterialBuyCard", CompMaterialBuyCard);
    Vue.component("CompMaterialBuyDetail", CompMaterialBuyDetail);
    Vue.component("CompMaterialBasket", CompMaterialBasket);
    Vue.component("CompMaterialAdd", CompMaterialAdd);
    Vue.component("CompMaterialItemSwiper", CompMaterialItemSwiper);

    Vue.component("CompDesignOrderFactoryList", CompDesignOrderFactoryList);
    Vue.component("CompDesignOrderDetail", CompDesignOrderDetail);
    Vue.component("FactoryDetail", PcFactoryDetail);
    Vue.component("NormalCard", NormalCard);
    Vue.component("PremiumCard", PremiumCard);
    Vue.component("SearchMainCard", SearchMainCard);
    Vue.component("CompContractDetail", CompContractDetail);
    Vue.component(
      "CompDesignOrderDetailDesigner",
      CompDesignOrderDetailDesigner
    );
    Vue.component("CompContract", CompContract);
    Vue.component("CompContractList", CompContractList);
    Vue.component("CompCalculateList", CompCalculateList);
    Vue.component("CompCalculateDetail", CompCalculateDetail);
    Vue.component("CompManageList", CompManageList);
    Vue.component("CompPaymentList", CompPaymentList);
    Vue.component("CompPaymentDetail", CompPaymentDetail);
    Vue.component("CompAlarmList", CompAlarmList);
    Vue.component("CompAlarmDetail", CompAlarmDetail);
    Vue.component("CompNoticeList", CompNoticeList);
    Vue.component("CompNoticeDetail", CompNoticeDetail);
    Vue.component("CommFilterMenu", CommFilterMenu);
    Vue.component("CompTicketList", CompTicketList);
    Vue.component("CompCustomBuilder", CompCustomBuilder);
    Vue.component("CompCustomBuilderSize", CompCustomBuilderSize);
    Vue.component("CompCustomBuilderDtl", CompCustomBuilderDtl);
    Vue.component("CompCustomBuilderPrt", CompCustomBuilderPrt);
    Vue.component("CompCustomMain", CompCustomMain);
    Vue.component("CompCustomAdd", CompCustomAdd);
    Vue.component("CompCustomAddPopup", CompCustomAddPopup);
    Vue.component("CompCustomPreview", CompCustomPreview);
    Vue.component("CompCustomOrder", CompCustomOrder);
    Vue.component("CompCustomOrderDetail", CompCustomOrderDetail);
    Vue.component("CompCustomDetail", CompCustomDetail);
    Vue.component("CompCustomOrderList", CompCustomOrderList);

    Vue.component("DescAdd", DescAdd);

    Vue.component("BarChart", BarChart);
    Vue.component("DoughnutChart", DoughnutChart);
    Vue.component("DoughnutRate", DoughnutRate);
  },
};
